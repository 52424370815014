import React from 'react';

import { TabNavigator } from '../../../app-components/tab/TabNavigator';
import { getUser } from '../../../AppServices';
import PastAppointment from './PastAppointment';
import UpcomingAppointments from './UpcomingAppointments';

const HistoryTabs = (props) => {
  const user = getUser();
  return (
    <TabNavigator
      {...props}
      tabs={{
        upcomingAppointments: {
          label: 'Upcoming Appointments',
          screen: UpcomingAppointments,
          screenProps: {
            user,
          },
        },
        pastAppointments: {
          label: 'Past Appointments',
          screen: PastAppointment,
          screenProps: {
            user,
          },
        },
      }}
    />
  );
};

export default HistoryTabs;
