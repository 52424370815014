import React from 'react';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
} from '@applane/react-core-components';

export const BaseToast = theme => props => {
  let {
    containerStyle,
    leftIconStyle,
    messageStyle,
    descriptionStyle,
    actionTextStyle,
    actionIconStyle,
  } = theme || {};
  let {leftIcon, message, description, action, hideToast} = props;
  let {text, icon, textStyle, iconStyle, onPress, ...restAction} = action || {};

  return (
    <View style={containerStyle}>
      {leftIcon && <Image source={leftIcon} style={leftIconStyle} />}
      <View style={{flex: 1}}>
        {message && <Text style={messageStyle}>{message}</Text>}
        {description && <Text style={descriptionStyle}>{description}</Text>}
      </View>
      {action && (
        <TouchableOpacity
          style={{
            marginLeft: 10,
            marginRight: 5,
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onPress={e => {
            hideToast && hideToast(e);
            onPress && onPress(e);
          }}>
          <React.Fragment>
            {icon && (
              <Image source={icon} style={iconStyle || actionIconStyle} />
            )}
            {text && <Text style={textStyle || actionTextStyle}>{text}</Text>}
          </React.Fragment>
        </TouchableOpacity>
      )}
    </View>
  );
};
