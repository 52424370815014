import React from 'react';
import { getConfiguration } from '../../AppServices';
import { loginLogo4x } from '../../images';
import vars from '../../theme/vars';

const Card = (props) => {
  const { patientInfo, barcode } = props;
  const configData = getConfiguration();
  return (
    <div style={{
      paddingLeft: 4,
      paddingTop: 8,
      paddingRight: 4,
      width: 214,
      height: 133,
    }}
    >
      <img
        alt="logo"
        src={loginLogo4x}
        width={206}
        height={30}
      />
      <div
        style={{
          marginTop: 4,
          height: 30,
          width: 206,
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 4,
        }}
      >
        <div style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          display: 'flex',
        }}
        >
          <span style={{
            ...vars.headings.h18, fontSize: 8, lineHeight: '10px', whiteSpace: 'nowrap',
          }}
          >
            {`Website: ${configData?.website}`}
          </span>
          <span style={{
            ...vars.headings.h18, fontSize: 8, lineHeight: '10px', whiteSpace: 'nowrap',
          }}
          >
            {`Phone: ${configData?.contactInfo}`}
          </span>
        </div>
        <div
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <span style={{ ...vars.headings.h18, fontSize: 8, lineHeight: '10px' }}>
            {`Email: ${configData?.email}`}
          </span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          height: 55,
        }}
      >
        <div style={{
          width: 130,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          paddingLeft: 4,
        }}
        >
          <div style={{
            ...vars.headings.h14,
            color: vars.colors.secondary.color1,
            whiteSpace: 'nowrap',
            fontSize: 10,
            fontFamily: 'public_sans_regular',
          }}
          >
            {'patient.labels.patientUnique'.getLabel()}
          </div>
          <div>
            <div style={{
              ...vars.headings.h9, color: vars.colors.grey.color1, whiteSpace: 'nowrap', fontSize: 10,
            }}
            >
              {patientInfo?.patientName}
            </div>
            <div style={{
              ...vars.headings.h9, color: vars.colors.grey.color1, whiteSpace: 'nowrap', fontSize: 10,
            }}
            >
              {`UHID-${patientInfo?.UHID}`}
            </div>
          </div>
        </div>
        <div style={{
          width: 80, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end',
        }}
        >
          <img
            src={barcode}
            alt="barcode"
            width={80}
            height={48}
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
