export default {
  labels: {
    assistant: 'Assistant',
    nurse: 'Nurse Name',
    scanner: 'Scanner Available',
    enterUHID: 'Enter UHID',
    uhid: 'UHID',
    name: 'Name',
    age: 'Age',
    department: 'Department',
    speciality: 'Speciality',
    gender: 'Gender',
    doctorName: 'Doctor Name',
    type: 'Type',
    labType: 'LabType',
    testType: 'TestType',
    amount: 'Amount',
    currency: 'Currency',
    patientType: 'PatientType',
    discount: 'Discount',
    billAmount: 'Bill Amount',
    addNewRow: 'Add New Row',
    relation: 'Relation',
    mobile: 'Mobile No.',
    latestOPD: 'Latest Opd Id',
    lastConsultation: 'Latest/Last Consultation',
    itemId: 'Item Id',
    itemName: 'Item Name',
    category: 'Category',
    manufacturer: 'Manufacturer',
    quantity: 'Quantity',
    unitPrice: 'Unit Price',
    stockAvailable: 'Stock Available',
    price: 'Price ',
    suggestions: 'Suggestions',
    documents: 'Upload Prescription',
    patientName: 'Patient Name',
    date: 'Date',
    sno: 'S.no.',
    services: 'Services/Items',
    qty: 'Qty',
    unitCharges: 'Unit Charges',
    total: 'Total',
    grandTotal: 'Grand Total',
    totalNoOfItem: 'Total No. of items',
    status: 'Vaccination Status',
    vaccineDetails: 'Vaccine Details',
    vaccineName: 'Vaccine Name',
    reVaccinationGap: 'Revaccination Gap',
    noOfDose: 'No. of Doses',
    description: 'Description',
    lastVaccinationDate: 'Last Vaccination date',
    paymentType: 'Payment Type',
    paymentStatus: 'Payment Status',
    reVaccinate: 'Revaccinate',
    vaccinationDate: 'Vaccination Date',
    prescription: 'Prescription',
    bill: 'Bill',
    bloodGroup: 'Blood Group',
    doseNo: 'Dose No.',
    serialNo: 'Serial No.',
    reVaccinationType: 'REVACCINATION TYPE',
    vaccineStatus: 'Status',
    vaccineType: 'VACCINE TYPE',
    allDosesOccuring: 'All Doses are recurring at equal intervals',
    vaccinationPeriod: 'Vaccination Period',
    noOfDoses: 'No of Doses',
    sellingPrice: 'Selling Price',
  },
  placeholders: {
    assistant: 'Assistant',
    nurse: 'Nurse Name',
  },
  title: {
    editNurse: 'Are your sure you want to Edit Nurse',
    descriptionNurseEdit: 'This action will change the Nurse',
    endSession: 'Are you sure you want to End Session?',
    endSessionSubWarning: 'This action will end the session. ',
    endSessionWarning: ' patients are waiting in the queue.',
    endSessionCountWarning: ' patient is waiting in the queue.',
    patientDetails: 'Patient Details',
    vaccineList: 'Vaccine List',
  },
  messages: {
    nurseUpdatedSuccessfullySuffix: 'has been assigned successfully ',
    sessionEndSuccessfully: 'Session has been ended successfully',
    enterUHID: 'Please Enter Patient UHID to Generate Lab-Test Bill',
    incorrectUHID: 'Patient Details not found with the given UHID.',
    billingDetailsSavedSuccessfully: 'Billing Details has been saved succesfully.',
    invoiceGenerateSuccessfully: 'Invoice has been generated successfully.',
    loginUserError: 'Superadmins are not allowed to create sessions, Please login with a employee..',
    patientAddedSuccessfully: 'Patient has been added successfully',
    vaccineDoseProvideSuccessfully: 'Vaccine dose has been provided successfully',
    vaccineSingleDose: 'Vaccine has been provided successfully',
  },
  headers: {
    token: 'Token',
    uhid: 'UHID',
    patientName: 'Patient Name',
    status: 'Vaccination Status',
    vaccinationStatus: 'Vaccination Status',
    paymentStatus: 'Payment Status',
    doseStatus: 'Dose Status',
    serialNo: 'Serial No.',
    vaccineName: 'Vaccine Name',
    doseNo: 'Dose No.',
    action: 'Action',
    lastVaccinationDate: 'Last Vaccination date',
    daysBeforeReVaccination: 'Days Before Revaccination',
    doseDuseDate: 'Dose Due Date',
    doseProvidedDate: 'Dose Provided date',
    opdNo: 'OPD NO.',
    crNo: 'CR NO.',
    nurseName: 'Nurse Name',
    noOfVaccine: 'No. Of Vaccine',
    bill: 'Bill',
    prescription: 'Prescription',
    vaccinatedDate: 'Vaccinated Date',
    paymentType: 'Payment Type',
    reVaccinateGap: 'Revaccination Gap',
    noOfDose: 'No. Of Dose',
    dosePending: 'Dose Pending',
    vaccinationDate: 'Vaccination Date',
    mobile: 'Mobile No.',
    vaccineType: 'VACCINE TYPE',
    reVaccinationType: 'REVACCINATION TYPE',
    stockAvailable: 'Stock Available',
    vaccineStatus: 'Status',
    description: 'Description',
    noOfDoses: 'No of Doses',
    sellingPrice: 'Selling Price',
  },
  buttons: {
    completed: 'Completed',
    provideNextDose: 'Provide Next Dose',
    viewVaccinationLogs: 'View Vaccination Logs',
  },
};
