import React, { useState, useEffect } from 'react';

import { fetch, getUser, gpsStore } from '../../../AppServices';
import {
  ActivityIndicator, ScrollView, View, Text,
} from '../../../app-components';
import PatientCard from './PatientCard';
import { h15_Medium } from '../../../theme/fonts';

const UpcomingAppointment = (props) => {
  const [waitingRoomData, setWaitingRoom] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = getUser();

  const appointmentDatas = async () => {
    const { patient } = getUser();
    setLoading(true);

    try {
      const result = await fetch({
        uri: {
          props: {
            query: {
              id: 'appointmentLists',
              addOnFilter: {
                status: 'pending',
                patient_id: {
                  _id: patient?._id,
                },
              },
              sort: { time_slot: { start_time: -1 } },
            },
            model: 'appointments',
          },
        },
      });

      const { data = [] } = result || {};

      if (result?.data) {
        setWaitingRoom(data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    appointmentDatas();
  }, []);

  useEffect(() => {
    gpsStore.subscribeEvent({
      event: `doctor_joined_${user?.patient?._id}`,
      callback: appointmentDatas,
    });
    gpsStore.subscribeEvent({
      event: `Video_Appointment_Completed_${user?.patient?._id}`,
      callback: appointmentDatas,
    });

    return () => {
      gpsStore.unsubscribeEvent({
        event: `doctor_joined_${user?.patient?._id}`,
      });
      gpsStore.unsubscribeEvent({
        event: `Video_Appointment_Completed_${user?.patient?._id}`,
      });
    };
  }, [user]);

  const { selectedTab = '' } = props;

  return (
    <ScrollView
      style={{
        flex: 1,
        height: '70vh',
      }}
    >
      {loading ? (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActivityIndicator size="small" color="#fff" />
        </View>
      )

        : (
          <View
            key={waitingRoomData}
            style={{
              flex: 1,
              height: '78vh',
            }}
          >
            { Array.isArray(waitingRoomData) && waitingRoomData.length ? (
              waitingRoomData.map((item, index) => (
                <View key={index} style={{ flexBasis: 'auto' }} className="appointment-card">
                  <PatientCard
                    item={item}
                    size={waitingRoomData.length}
                    index={index}
                    selectedTab={selectedTab}
                    {...props}
                    key={index.toString()}
                    socketData={{}}
                    appointmentDatas={appointmentDatas}
                  />
                </View>
              ))
            ) : (
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text style={{ ...h15_Medium, color: '#fff' }}>
                  You have no past appointments
                </Text>
              </View>
            )}
          </View>

        )}
    </ScrollView>
  );
};
export default UpcomingAppointment;
