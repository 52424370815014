import React from 'react';
import FloatingInputLabel from './FloatingInputLabel';
const WithFloatingLabel = Component => {
  class FloatingLabel extends React.Component {
    getRef = ref => {
      let {getRef} = this.props;
      this._inputRef = ref;
      getRef && getRef(ref);
    };

    getInputRef = () => this._inputRef;

    render() {
      let {label, labelPosition, ...restProps} = this.props;
      let renderComponent = <Component {...restProps} getRef={this.getRef} />;
      if (!label) {
        return renderComponent;
      }
      return (
        <React.Fragment>
          {renderComponent}
          <FloatingInputLabel
            {...restProps}
            label={label}
            labelPosition={labelPosition}
            getInputRef={this.getInputRef}
          />
        </React.Fragment>
      );
    }
  }
  return FloatingLabel;
};

export default WithFloatingLabel;
