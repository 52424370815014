import React from 'react';
import {View, Text} from '@applane/react-core-components';
import {WithInputWrapper, WithInputError} from '@applane/react-input-wrapper';
import SwitchButton from './SwitchButton';
import {getRenderComponent} from './Utility';
import WithFetch from './WithFetch';

class ToggleSwitch extends React.Component {
  render() {
    let {
      value,
      style,
      offLabelContainerStyle,
      onLabelContainerStyle,
      onLabelStyle,
      offLabelStyle,
      selectedLabelStyle,
      onValue,
      offValue,
      leftValue,
      rightValue,
      onChangeValue,
      defaultValue,
      showOuterLabel,
      renderOffLabel,
      renderOnLabel,
      align,
      valueField,
      ...restProps
    } = this.props;
    if (value === undefined) {
      value = defaultValue;
    }
    let onLabel = 'On';
    let offLabel = 'Off';
    onValue = onValue || rightValue || true;
    offValue = offValue || leftValue || false;

    if (typeof onValue === 'string') {
      onLabel = onValue;
    } else if (typeof onValue === 'object') {
      onLabel = onValue.label;
      onValue = onValue.value || true;
    }
    if (typeof offValue === 'string') {
      offLabel = offValue;
    } else if (typeof offValue === 'object') {
      offLabel = offValue.label;
      offValue = offValue.value || false;
    }
    let isOn = value === onValue;
    if (
      typeof value === 'object' &&
      typeof onValue === 'object' &&
      valueField
    ) {
      isOn = value[valueField] === onValue[valueField];
    }

    let extraStyle = {};
    if (align !== undefined) {
      if (align === 'center') {
        extraStyle.justifyContent = 'center';
      } else if (align === 'right') {
        extraStyle.justifyContent = 'flex-end';
      }
    }

    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          ...style,
          ...extraStyle,
        }}>
        {renderOffLabel ? (
          getRenderComponent(renderOffLabel, {value: offValue, label: offLabel})
        ) : showOuterLabel && offLabel ? (
          <View style={offLabelContainerStyle}>
            <Text
              style={{
                ...offLabelStyle,
                ...(!isOn && selectedLabelStyle),
              }}>
              {offLabel}
            </Text>
          </View>
        ) : (
          void 0
        )}
        <SwitchButton
          {...restProps}
          value={isOn}
          onChangeValue={(_value, e) => {
            onChangeValue && onChangeValue(_value ? onValue : offValue, e);
          }}
        />
        {renderOnLabel ? (
          getRenderComponent(renderOnLabel, {value: onValue, label: onLabel})
        ) : showOuterLabel && onLabel ? (
          <View style={onLabelContainerStyle}>
            <Text
              style={{
                ...onLabelStyle,
                ...(isOn && selectedLabelStyle),
              }}>
              {onLabel}
            </Text>
          </View>
        ) : (
          void 0
        )}
      </View>
    );
  }
}
export default WithFetch(WithInputWrapper(WithInputError(ToggleSwitch)));
