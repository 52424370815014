export default {
  header: {
    requestNumber: 'Request NUMBER',
    dateRaised: 'Date Raised',
    status: 'Status',
    action: 'Actions',
    creationDate: 'CREATION DATE',
    poNumber: 'PS NUMBER',
    soNumber: 'SO NUMBER',
    department: 'Department',
    urgent: 'Urgent',
    tAndCName: 'T&C Name',
    official: 'Official',
    remarks: 'Remarks',
    termsAndCondition: 'Terms  & Conditions',
    type: 'TYPE',
    description: 'Description',
    itemName: 'Item Name',
    daysLimitForApproval: 'Days Limit For Approval',
  },
  labels: {
    dateRaised: 'Date Raised',
    requestNumber: 'Request Number',
    department: 'Department',
    status: 'Status',
    requiredQuantity: 'Required Quantity',
    reasonForPurchase: 'Reason For Purchase',
    itemId: 'Item Id',
    itemName: 'Item Name',
    category: 'Category',
    subCategory: ' Sub Category',
    contractual: 'Contractual',
    unitPrice: 'Unit Price',
    totalPrice: 'Total Price',
    avgMonthlyConsuption: 'Avg. Monthly Consumption',
    lastPurchaseDate: 'Last Purchase Date',
    lastPurchaseQuantity: 'Last Purchase Quantity',
    stockAvailable: 'Stock Available',
    manufacturer: 'Manufacturer',
    supplier: 'Supplier',
    poNumber: 'PS Number',
    date: 'Date',
    urgent: 'Urgent',
    daysLimitForApproval: 'Days Limit For Approval',
    termsAndConditions: 'Terms and Conditions',
    typeTAndC: 'T&C TYPE',
    sendForApprovalTo: 'SEND FOR APPROVAL TO',
    remarks: 'Remarks',
    purchaseQuantity: 'Purchase Quantity',
    tAndCName: 'T&C Name',
    sendTo: 'SEND TO',
    noOfDays: 'NO. OF DAYS',
    remark: 'Remark',
    official: 'Official',
    purchaseRequestNumber: 'Purchase Request Number',
    description: 'Description',
    assetName: 'Asset Name',
    subCategoryName: 'Sub Category Name',
    actions: 'Actions',
    authority: 'Tender Invite Authority',
    workName: 'Name of Work',
    contactNumber: 'Contact Number',
    bidderCompany: 'Name Of Bidder/Binding Firm/Company',
    totalAmountInWords: 'Total Amount in Words',
    totalAmountWithTax: 'Total Amount with Tax',
    totalAmountWithOutTax: 'Total Amount Without Tax',
    units: 'Units',
    estimatedPrice: 'Estimated Price',
    additionDeduction: 'Addition/Deduction',
    additionDeductionValue: 'Addition/Deduction Value',
    currencyConversion: 'Currency Conversion Against Each Item',
    quotedCurrency: 'Quoted Currency In INR/Other Currency',
    basicRateBidder: 'Basic Rate In Figures To Be Entered By The Bidder',
    exciseAmount: 'Excise Duty Amount In INR',
    gstAmount: 'GST Amount In INR',
    freightCharges: 'Freight Charges(Unloading & Stacking)',
    otherTax: 'Any Other Tax',
    otherDuties: 'Any Other Duties/Levies',
    thirdPartyInspectionCharges: 'IIIRD Party I.E DGS&D/ Rites ETC Inspection Charges @0.34%+Service Tax ',
    lessCenvatCredit: 'Less For Cenvat Credits,If Any Respect Of Supplies Under Full Excise Duty Category ',
  },
  placeholders: {
    selectSupplier: 'Select Supplier',
    selectManufacturer: 'Select Manufacturer',
    requiredQuantity: 'Required Quantity',
    itemName: 'Item Name',
    units: 'Units',
    unitPrice: 'Unit Price',
    estimatedPrice: 'Estimated Price',
    additionDeduction: 'Addition/Deduction',
    additionDeductionValue: 'Addition/Deduction Value',
    basicRateBidder: 'Basic Rate In Figures To Be Entered By The Bidder',
    exciseAmount: 'Excise Duty Amount In INR',
    gstAmount: 'GST Amount In INR',
    freightCharges: 'Freight Charges(Unloading & Stacking)',
    otherTax: 'Any Other Tax',
    otherDuties: 'Any Other Duties/Levies',
    thirdPartyInspectionCharges: 'IIIRD Party I.E DGS&D/ Rites ETC Inspection Charges @0.34%+Service Tax ',
    lessCenvatCredit: 'Less For Cenvat Credits,If Any Respect Of Supplies Under Full Excise Duty Category ',
  },
  message: {
    createPurchaseOrder: 'PS has been sent for approval.',
    soCreatedSuccessfully: 'SO has been sent for approval',
    categoryAlreadyExists: 'Category Already Exists',
    categoryUpdatedSuccessfully: 'Category has been Updated successfully.',
    categoryAddedSuccessfully: 'Category has been Added successfully.',
    subCategoryAddedSuccessfully: 'Sub-Category has been Added successfully.',
    subCategoryUpdatedSuccessfully:
    'Sub-Category has been Updated successfully.',
    subCategoryAlreadyExists: 'Sub-Category Already Exists',
    BOQAddedSuccessfully: 'BOQ Created Successfully ',
  },
  button: {
    createAndPrintPO: 'Create PS',
    createAndPrintSO: 'Create SO',
    confirm: 'Confirm',
    addCategory: 'Add Category',
    editCategory: 'Edit Category',
    createSubCategory: 'Create Sub-Category',
    create: 'Create',
    update: 'Update',
  },
  title: {
    purchaseOrder: 'Purchase Sanction',
    supplyOrder: 'Supply Order',
    sendForReview: 'Send for Review',
    rejectPurchaseOrder: 'Reject Purchase Sanction',
    approveAndSendPO: 'Approve and Send PS',
    approve: 'Approve',
    categoryManagement: 'Category Management',
    addSubCategoryDescription: 'Create Sub Category of the Category.',
    editSubCategoryDescription: 'Edit Sub Category of the Category.',
    BOQ: 'BOQ Details',
    itemDetails: 'Item Wise Details',
  },
  errorMessage: {
    selectConditionAndConditionType:
      'Please select terms and conditions.',
    selectManufactureandSupplier: 'Please select supplier and manufacturer',
  },
};
