import vars from './vars';

export const toggleFilterTheme = {
  style: {
    width: 342,
    height: 30,
    flex: 1,
  },
  itemStyle: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 34,
    paddingRight: 34,
    borderWidth: 1,
    borderColor: vars.colors.primary.color4,
    cursor: 'pointer',
    backgroundColor: vars.colors.primary.color4,
  },
  selectedItemStyle: {
    borderRadius: 4,
    borderColor: vars.colors.secondary.color2,
    backgroundColor: vars.colors.secondary.color2,
  },
  optionStyle: {
    ...vars.headings.h13,
    color: vars.colors.grey.color3,
  },
  selectedOptionStyle: {
    ...vars.headings.h13,
    color: vars.colors.white,
  },
};
