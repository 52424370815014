import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, Image} from '@applane/react-core-components';

class FormGroupHeader extends React.PureComponent {
  renderActions = () => {
    let {
      renderAction,
      actions,
      actionDirection = 'right',
      actionContainerStyle,
      actionStyle,
    } = this.props;
    let actionDirectionStyle = {};
    if (actionDirection === 'left') {
      actionDirectionStyle.justifyContent = 'flex-start';
    } else {
      actionDirectionStyle.justifyContent = 'flex-end';
    }
    return (
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          flexDirection: 'row',
          alignItems: 'center',
          ...actionDirectionStyle,
          ...actionContainerStyle,
        }}>
        {actions
          ? actions.map(action => {
              return renderAction
                ? renderAction({action, style: actionStyle})
                : action;
            })
          : []}
      </View>
    );
  };

  render() {
    let {
      className,
      icon,
      label,
      subTitle,
      subTitleStyle,
      error,
      expandComponent,
      style,
      iconStyle,
      labelStyle,
      errorLabelStyle,
    } = this.props;
    return (
      <View
        className={className}
        style={{flexDirection: 'row', alignItems: 'center', ...style}}>
        {icon && <Image style={iconStyle} source={icon} />}
        {label && (
          <Text
            style={{
              ...labelStyle,
              ...((error && errorLabelStyle) || {}),
            }}>
            {label}
          </Text>
        )}
        {subTitle && (
          <Text
            style={{
              ...subTitleStyle,
              ...((error && errorLabelStyle) || {}),
            }}>
            {subTitle}
          </Text>
        )}
        {this.renderActions()}
        {expandComponent}
      </View>
    );
  }
}

FormGroupHeader.defaultProps = {
  style: {
    marginLeft: 24,
    marginRight: 24,
    paddingTop: 4,
    paddingBottom: 4,
  },
  iconStyle: {
    paddingRight: 8,
  },
  labelStyle: {
    color: '#656565',
  },
};

FormGroupHeader.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  actions: PropTypes.array,
  renderAction: PropTypes.func,
  actionDirection: PropTypes.string,
  expandComponent: PropTypes.element,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  actionContainerStyle: PropTypes.object,
};

export default FormGroupHeader;
