export default {
  labels: {
    name: 'Holiday Name',
    date: 'Date',
    status: 'STATUS:',
    action: 'Action',
    description: 'Description',
    NoOfHolidays: 'No. of Holidays',
    file: 'Drag & Drop Your Files Here',
  },
  messages: {
    holidayAddedSuccessfully: 'Holiday has been Added successfully.',
    holidayUpdatedSuccessfully: 'Holiday has been Updated successfully.',
  },
  title: {
    name: 'Holiday',
    description: 'Create and Add Holiday to the Holiday Year',
  },
  buttons: {
    update: 'Update',
    create: 'Create',
  },
  headers: {
    holiday_type: 'Holiday Type',
    status: 'STATUS',
  },
};
