import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Dimensions,
  View,
  Text,
  TouchableWithoutFeedback,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableHighlight,
  FlatList,
  Image,
  Keyboard,
  StatusBarManager,
  Platform,
  StyleSheet,
} from '@applane/react-core-components';
import {withAppKeyboardListenerContext} from '@applane/react-app-keyboard-listener';
import {withModalContext} from '@applane/react-modal-view';
import {getRenderComponent} from './Utility';
import AutoSuggestInputEditor from './AutoSuggestInputEditor';
import AutoSuggestWithSearchInput from './AutoSuggestWithSearchInput';

let STATUS_BAR_HEIGHT = 0;

StatusBarManager.getHeight(statusBarHeight => {
  STATUS_BAR_HEIGHT = statusBarHeight && statusBarHeight.height;
});

const TOUCHABLE_ELEMENTS = [
  'TouchableHighlight',
  'TouchableOpacity',
  'TouchableWithoutFeedback',
  'TouchableNativeFeedback',
];

class AutosuggestInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    valueField: PropTypes.string,
    sugestionField: PropTypes.string,
    minChar: PropTypes.number,

    accessible: PropTypes.bool,
    searching: PropTypes.bool,
    animated: PropTypes.bool,
    showsVerticalScrollIndicator: PropTypes.bool,
    keyboardShouldPersistTaps: PropTypes.string,

    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    dropdownStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    dropdownTextStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    dropdownTextHighlightStyle: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),

    adjustFrame: PropTypes.func,
    renderRow: PropTypes.func,
    renderSeparator: PropTypes.func,
    renderButtonText: PropTypes.func,
    renderNoData: PropTypes.func,
    navigation: PropTypes.object,

    onDropdownWillShow: PropTypes.func,
    onDropdownWillHide: PropTypes.func,
    onItemSelect: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.typing = '';
    this._button = null;
    this._buttonFrame = null;
    this.lastFetchSequence = 0;
    this.state = {};
    this.addKeyboardListener();
  }

  addKeyboardListener = () => {
    let {addKeyboardListener} = this.props;
    if (addKeyboardListener) {
      addKeyboardListener('keyboardDidShow', this._keyboardDidShow);
      addKeyboardListener('keyboardDidHide', this._keyboardDidHide);
    }
  };

  scrollToHoverIndex = hoverRowIndex => {
    let {hoverRowIndex: prevHoverRowIndex, data} = this.state || {};
    this.setState({hoverRowIndex});
    this.show();
    let scrollOption = {block: 'nearest'};
    if (data) {
      if (
        !(
          (prevHoverRowIndex === data.length - 1 &&
            (hoverRowIndex === undefined || hoverRowIndex === 0)) ||
          (hoverRowIndex === data.length - 1 &&
            (prevHoverRowIndex === undefined || prevHoverRowIndex === 0))
        )
      ) {
        scrollOption.behavior = 'smooth';
      }
    }
    const elementToFocus = document.querySelector(
      `.flatListRowFocus${hoverRowIndex}`,
    );
    elementToFocus &&
      elementToFocus.scrollIntoView &&
      elementToFocus.scrollIntoView(scrollOption);
  };

  onKeyDown = e => {
    let {onKeyDown} = this.props;
    let {data, hoverRowIndex} = this.state;
    const dataLength = data ? data.length : 0;
    const {key} = e || {};
    if (key && dataLength) {
      if (key === 'ArrowUp' || key === 'ArrowDown') {
        e && e.preventDefault && e.preventDefault();
        if (key === 'ArrowUp') {
          if (hoverRowIndex === undefined || hoverRowIndex === 0) {
            hoverRowIndex = dataLength - 1;
          } else {
            hoverRowIndex = hoverRowIndex - 1;
          }
        } else if (key === 'ArrowDown')
          if (hoverRowIndex === undefined || hoverRowIndex === dataLength - 1) {
            hoverRowIndex = 0;
          } else {
            hoverRowIndex = hoverRowIndex + 1;
          }
        this.scrollToHoverIndex(hoverRowIndex);
      }
      if (key === 'Enter') {
        e && e.preventDefault && e.preventDefault();
        this.onEnterPressRow(e);
      }
    }
    if (key === 'Escape') {
      this._onRequestClose();
    }
    onKeyDown && onKeyDown(e);
  };

  componentWillUnmount() {
    let {removeKeyboardListener} = this.props;
    if (removeKeyboardListener) {
      removeKeyboardListener('keyboardDidShow', this._keyboardDidShow);
      removeKeyboardListener('keyboardDidHide', this._keyboardDidHide);
    }
  }

  _keyboardDidShow = e => {
    let keyboardHeight = e.endCoordinates.height;
    this.setState({keyboardShown: true, keyboardHeight}, () => {
      this._updatePosition();
    });
  };

  _keyboardDidHide = () => {
    this.setState({keyboardShown: void 0, keyboardHeight: 0});
  };

  updateSuggestions = data => {
    if (this.fetching) {
      this.fetching = false;
    }
    this.setState({data, hoverRowIndex: void 0}, () => {
      this._updatePosition(() => {
        this.show();
      });
    });
  };

  onEnterPressRow = e => {
    let {data, hoverRowIndex} = this.state;
    if (hoverRowIndex !== undefined && data && data.length) {
      this._onRowPress({item: data[hoverRowIndex], index: hoverRowIndex});
    }
  };

  fetchSuggestion = searchValue => {
    let {fetch, minChar} = this.props;
    this.lastFetchSequence++;
    if (minChar && (!searchValue || searchValue.length < minChar)) {
      this.updateSuggestions([]);
      return;
    }
    let sequence = this.lastFetchSequence;
    this.setState({toggled: !this.state.toggled});
    let result;
    if (fetch) {
      this.fetching = true;
      result = fetch({
        searchValue,
      });
    } else {
      console.warn('Fetch Must be defined in AutoSuggestInput');
    }

    if (result && result instanceof Promise) {
      result
        .then(data => {
          if (this.lastFetchSequence === sequence) {
            this.updateSuggestions(data);
          }
        })
        .catch(e => {
          this.fetching = false;
          // console.error('Error in fetching in autosuggest input', e);
        });
    } else if (result) {
      this.updateSuggestions(result);
    }
  };

  _updatePosition = callback => {
    if (this._button && this._button.measure) {
      this._button.measure((fx, fy, width, height, px, py) => {
        this._buttonFrame = {x: px, y: py, w: width, h: height};
        callback && callback();
      });
    }
  };

  show = () => {
    this.props.setModal &&
      this.props.setModal.setModalState({
        renderModal: this._renderModal,
        showModal: true,
        closeModal: this.hide,
      });
    this.setState({modalOpen: true});
    !this.props.showSearchInModal && this.addKeyDownListener();
  };

  hide = () => {
    this.props.setModal &&
      this.props.setModal.setModalState({
        renderModal: null,
        showModal: false,
        replace: true,
      });
    !this.props.showSearchInModal && this.removeKeyDownListener();
    this.setState({modalOpen: false, hoverRowIndex: void 0});
  };

  addKeyDownListener = () => {
    Platform.OS === 'web' && window.addEventListener('keydown', this.onKeyDown);
  };

  removeKeyDownListener = () => {
    Platform.OS === 'web' &&
      window.removeEventListener('keydown', this.onKeyDown);
  };

  setButtonText = displayValue => {
    this.buttonText = displayValue;
  };

  onFocus = e => {
    const {onFocus, minChar} = this.props;
    onFocus && onFocus(e);
    !minChar && this._renderData();
  };

  onBlur = e => {
    const {onBlur} = this.props;
    this.hide();
    onBlur && onBlur(e);
    this.setButtonText(void 0);
    this.typing = '';
    this.lastFetchSequence = 0;
    if (this.fetching) {
      this.fetching = false;
      this.setState({toggled: !this.state.toggled});
    }
  };

  _onButtonPress = event => {
    event && event.preventDefault && event.preventDefault();
    Keyboard && Keyboard.dismiss();
    if (this._inputRef && this._inputRef.focus) {
      this._inputRef && this._inputRef.focus();
    } else {
      this.onFocus(event);
    }
  };
  _renderData = () => {
    const {onDropdownWillShow, renderLoading} = this.props;
    onDropdownWillShow && onDropdownWillShow();
    if (renderLoading) {
      this._updatePosition(() => {
        this.show();
      });
    }
    this.fetchSuggestion();
  };

  _renderModal = () => {
    const {accessible} = this.props;
    if (this._buttonFrame) {
      return (
        <TouchableOpacity
          accessible={accessible}
          activeOpacity={1}
          onPress={this._onRequestClose}
          style={{
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            position: 'absolute',
            backgroundColor: 'transparent',
          }}>
          {this._renderDropdown()}
        </TouchableOpacity>
      );
    }
  };

  _calcPosition() {
    let {dropdownStyle, style, adjustFrame, position} = this.props;
    const {keyboardHeight} = this.state;
    const dimensions = Dimensions.get('window');
    const windowWidth = dimensions.width;
    let windowHeight = dimensions.height;
    if (keyboardHeight) {
      windowHeight -= keyboardHeight;
    }
    if (dropdownStyle) {
      dropdownStyle = StyleSheet.flatten(dropdownStyle);
    }
    if (style) {
      style = StyleSheet.flatten(style);
    }
    let marginBottom =
      (dropdownStyle && dropdownStyle.marginBottom) ||
      (style && style.marginBottom) ||
      0;
    if (!marginBottom) {
      marginBottom =
        (dropdownStyle && dropdownStyle.margin) || (style && style.margin) || 0;
    }
    let marginTop =
      (dropdownStyle && dropdownStyle.marginTop) ||
      (style && style.marginTop) ||
      0;
    if (!marginTop) {
      marginTop =
        (dropdownStyle && dropdownStyle.margin) || (style && style.margin) || 0;
    }
    let topBottomMargin = marginTop + marginBottom;

    const dropdownHeight = (dropdownStyle && dropdownStyle.height) || 0;
    //check whether modal should open in top or bottom
    let availableBottomSpace =
      windowHeight -
      this._buttonFrame.y -
      this._buttonFrame.h -
      STATUS_BAR_HEIGHT;
    let availabelTopSpace =
      this._buttonFrame.y - STATUS_BAR_HEIGHT - topBottomMargin;

    let showInBottom =
      dropdownHeight <= availableBottomSpace ||
      availableBottomSpace >= availabelTopSpace;
    if (
      showInBottom &&
      position === 'top' &&
      dropdownHeight &&
      dropdownHeight <= availabelTopSpace
    ) {
      showInBottom = false;
    }

    let modalHeight = 0;
    let modalTopPosition = 0;
    let modalBottomPosition = 0;
    //here we decide the modal height and modal top position
    if (showInBottom) {
      modalHeight =
        dropdownHeight <= availableBottomSpace
          ? dropdownHeight
          : availableBottomSpace;
      modalTopPosition =
        this._buttonFrame.y +
        this._buttonFrame.h -
        (Platform.OS === 'ios' ? STATUS_BAR_HEIGHT : 0);
    } else {
      //check if  space is sufficient for default given height or not
      modalHeight =
        dropdownHeight <= availabelTopSpace
          ? dropdownHeight
          : availabelTopSpace;
      modalBottomPosition =
        windowHeight - STATUS_BAR_HEIGHT - this._buttonFrame.y;
    }
    const dropdownWidth =
      (dropdownStyle && dropdownStyle.width) ||
      (style && style.width) ||
      this._buttonFrame.w;
    const positionStyle = {
      position: 'absolute',
    };

    positionStyle.width = dropdownWidth;
    if (modalHeight !== undefined) {
      positionStyle.height = modalHeight;
    }
    if (modalTopPosition) {
      positionStyle.top = modalTopPosition;
    }
    if (modalBottomPosition) {
      positionStyle.bottom = modalBottomPosition;
    }

    const rightSpace = windowWidth - this._buttonFrame.x;
    let showInRight = rightSpace >= dropdownWidth;
    if (
      showInRight &&
      position === 'left' &&
      dropdownWidth < this._buttonFrame.x
    ) {
      showInRight = false;
    }

    if (showInRight) {
      positionStyle.left = this._buttonFrame.x;
    } else {
      const dropdownWidth =
        (dropdownStyle && dropdownStyle.width) || (style && style.width) || -1;
      if (dropdownWidth !== -1) {
        positionStyle.width = dropdownWidth;
      }
      positionStyle.right = rightSpace - this._buttonFrame.w;
    }
    // console.warn('position style', positionStyle);
    return adjustFrame ? adjustFrame(positionStyle, this.state) : positionStyle;
  }

  _onRequestClose = () => {
    const {onDropdownWillHide} = this.props;
    if (!onDropdownWillHide || onDropdownWillHide() !== false) {
      this.hide();
    }
    Keyboard && Keyboard.dismiss();
    if (this._inputRef && this._inputRef.blur) {
      this._inputRef.blur();
    } else {
      this.onBlur();
    }
  };

  isSelectAllRow = ({item}) => {
    const {isSelectAllRow} = this.props;
    return isSelectAllRow && isSelectAllRow({item});
  };

  _renderDropdown() {
    const {
      renderSeparator,
      showsVerticalScrollIndicator,
      keyboardShouldPersistTaps = 'always',
      dropdownStyle,
      minChar,
      renderNoData,
      navigation,
      keyExtractor,
      renderLoading,
      showSearchInModal,
      selectAllRow,
    } = this.props;
    const frameStyle = this._calcPosition();
    let dropdownComponent = null;
    if (showSearchInModal) {
      dropdownComponent = (
        <AutoSuggestWithSearchInput
          {...this.props}
          searching
          hideModal={this._onRequestClose}
        />
      );
    } else {
      let {data} = this.state;
      if (!data || !data.length) {
        if (this.fetching) {
          dropdownComponent = renderLoading
            ? renderLoading({
                loading: this.fetching,
                defaultLoadingComponent: this._renderLoading,
                closeModal: this._onRequestClose,
              })
            : null;
        } else if (
          renderNoData &&
          (!minChar || (this.typing && this.typing.length >= minChar))
        ) {
          dropdownComponent = renderNoData({
            navigation,
            searchValue: this.typing,
            closeModal: this._onRequestClose,
          });
        }
      } else {
        if (selectAllRow && !this.typing) {
          data = [selectAllRow, ...data];
        }
        dropdownComponent = (
          <FlatList
            ref={index => (this.FlatList = index)}
            style={{flex: 1}}
            data={data}
            renderItem={this._renderRow}
            ItemSeparatorComponent={renderSeparator || this._renderSeparator}
            keyExtractor={keyExtractor}
            showsHorizontalScrollIndicator={showsVerticalScrollIndicator}
            keyboardShouldPersistTaps={keyboardShouldPersistTaps}
          />
        );
      }
    }

    if (dropdownComponent) {
      dropdownComponent = (
        <TouchableOpacity
          activeOpacity={1}
          style={[dropdownStyle, frameStyle]}
          onPress={() => {}}>
          {dropdownComponent}
        </TouchableOpacity>
      );
    }
    return dropdownComponent;
  }

  _renderRow = ({item, index}) => {
    const {
      renderRow,
      dropdownTextStyle,
      dropdownTextHighlightStyle,
      accessible,
      sugestionField,
      isHighlighted,
    } = this.props;
    let {hoverRowIndex} = this.state;
    let highlighted =
      isHighlighted &&
      isHighlighted({
        item: item,
        index,
        data: this.state.data,
        isSelectAll: this.isSelectAllRow({item}),
      });

    let row = void 0;
    if (renderRow) {
      row = renderRow({
        item,
        index,
        highlighted,
        isHover: hoverRowIndex === index,
        props: this.props,
      });
    } else {
      let rowText =
        typeof item === 'object' && sugestionField
          ? item[sugestionField]
          : item;
      if (typeof rowText === 'object') {
        rowText = JSON.stringify(rowText);
      }
      row = (
        <Text
          style={[
            dropdownTextStyle,
            highlighted && dropdownTextHighlightStyle,
          ]}>
          {rowText}
        </Text>
      );
    }

    let preservedProps;
    if (Platform.OS === 'web') {
      preservedProps = {
        accessible,
        onMouseDown: e => {
          e.preventDefault();
          this._onRowPress({item, index});
        },
      };
    } else {
      preservedProps = {
        accessible,
        onPress: e => {
          this._onRowPress({item, index});
        },
      };
    }
    if (TOUCHABLE_ELEMENTS.find(name => name == row.type.displayName)) {
      const props = {...row.props};
      props.key = preservedProps.key;
      props.onPress = preservedProps.onPress;
      const {children} = row.props;
      switch (row.type.displayName) {
        case 'TouchableHighlight': {
          return <TouchableHighlight {...props}>{children}</TouchableHighlight>;
        }
        case 'TouchableOpacity': {
          return <TouchableOpacity {...props}>{children}</TouchableOpacity>;
        }
        case 'TouchableWithoutFeedback': {
          return (
            <TouchableWithoutFeedback {...props}>
              {children}
            </TouchableWithoutFeedback>
          );
        }
        case 'TouchableNativeFeedback': {
          return (
            <TouchableNativeFeedback {...props}>
              {children}
            </TouchableNativeFeedback>
          );
        }
        default:
          break;
      }
    }
    return (
      <TouchableOpacity
        onMouseMove={e => {
          e && e.preventDefault && e.preventDefault();
          this.state.hoverRowIndex !== index && this.scrollToHoverIndex(index);
        }}
        className={`flatListRowFocus${index}`}
        {...preservedProps}>
        {row}
      </TouchableOpacity>
    );
  };

  _dropdown_search_keypress = e => {
    switch (e.nativeEvent.key) {
      case 'Enter':
        this.props.onEnterPress &&
          this.props.onEnterPress({searchValue: this.typing});
        this.hide();
        break;
    }
  };

  _dropdown_search_textchange = text => {
    this.typing = text;
    let {onChangeText} = this.props;
    onChangeText && onChangeText(text);
    this.setButtonText(text);
    this.fetchSuggestion(text);
  };

  _onRowPress({item, index}) {
    const {onItemSelect, multiSelect} = this.props;
    if (onItemSelect) {
      let displayValue = onItemSelect({
        item,
        index,
        searchValue: this.typing,
        data: this.state.data,
        isSelectAll: this.isSelectAllRow({item}),
      });

      if (displayValue !== undefined) {
        this.setButtonText(displayValue);
        this.setState({});
      }
    }
    if (multiSelect) {
      this.show();
    } else {
      this._onRequestClose();
    }
  }

  _renderSeparator = rowID => {
    let {separatorStyle} = this.props;
    return <View key={`spr_${rowID}`} style={separatorStyle} />;
  };

  clearValue = e => {
    this.props.clearValue && this.props.clearValue(e);
    this.onBlur(e);
  };

  clearText = e => {
    this.setButtonText('');
  };

  renderSelector = () => {
    let {
      showArrow,
      arrowDownIcon,
      inputSelectorContainerStyle,
      inputSelectorIconContainerStyle,
    } = this.props;
    if (!showArrow) {
      return null;
    }
    return (
      <View style={inputSelectorContainerStyle}>
        <View style={inputSelectorIconContainerStyle}>
          <Image
            source={arrowDownIcon}
            style={{
              maxWidth: 16,
              maxHeight: 16,
            }}
          />
        </View>
      </View>
    );
  };

  getInputRef = ref => {
    const {getRef} = this.props;
    this._inputRef = ref;
    getRef && getRef(ref);
  };

  _renderButton() {
    let {
      disabled,
      accessible,
      children,
      value,
      getDisplayValue,
      searching,
      renderSelector,
    } = this.props;
    let buttonComponent = children;
    let displayValue = getDisplayValue ? getDisplayValue(value) : value;
    if (!buttonComponent) {
      if (
        value === this.oldValue &&
        (this.buttonText || this.buttonText === '')
      ) {
        displayValue = this.buttonText;
      } else {
        this.buttonText = void 0;
      }
      this.oldValue = value;

      let extraInputProps = {};
      if (searching) {
        extraInputProps.onKeyPress = this._dropdown_search_keypress;
        extraInputProps.onChangeText = this._dropdown_search_textchange;
      } else {
        extraInputProps.caretHidden = true;
        extraInputProps.showSoftInputOnFocus = false;
        extraInputProps.editable = !disabled;
      }

      let textComponent = (
        <AutoSuggestInputEditor
          {...this.props}
          loading={this.fetching}
          displayValue={displayValue || ''}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          getRef={this.getInputRef}
          {...extraInputProps}
        />
      );
      buttonComponent = (
        <View style={{flexDirection: 'row'}}>
          <View style={{flex: 1, overflow: 'hidden'}}>{textComponent}</View>
          {!disabled &&
            getRenderComponent(renderSelector || this.renderSelector, {
              ...this.props,
              loading: this.fetching,
              modalOpen: this.state.modalOpen,
              searchText: this.typing,
              clearText: this.clearText,
              clearValue: this.clearValue,
            })}
        </View>
      );
    }
    if (!disabled) {
      let touchProps = {};
      if (Platform.OS === 'web') {
        touchProps.onMouseDown = this._onButtonPress;
      } else {
        touchProps.onPress = this._onButtonPress;
      }
      buttonComponent = (
        <TouchableOpacity
          ref={button => (this._button = button)}
          accessible={accessible}
          {...touchProps}>
          {buttonComponent}
        </TouchableOpacity>
      );
    }
    return buttonComponent;
  }

  render() {
    return this._renderButton();
  }
}

AutosuggestInput.defaultProps = {
  disabled: false,
  placeholder: 'Please select...',
  animated: true,
  showsVerticalScrollIndicator: true,
  keyboardShouldPersistTaps: 'always',
  searching: false,
  minChar: 0,
  dropdownStyle: {
    position: 'absolute',
    height: (33 + StyleSheet.hairlineWidth) * 5,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: 'lightgray',
    borderRadius: 2,
    backgroundColor: 'white',
  },
  dropdownTextStyle: {
    paddingHorizontal: 6,
    paddingVertical: 10,
    fontSize: 11,
    color: 'gray',
    backgroundColor: 'white',
    textAlignVertical: 'center',
  },
  dropdownTextHighlightStyle: {
    color: 'black',
  },
  separatorStyle: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: 'lightgray',
  },
};

export default withAppKeyboardListenerContext(
  withModalContext(AutosuggestInput),
);
