import React from 'react';
import { DropDownAction } from '@applane/react-drop-down-v1';
import { moreOptionsIcon } from '../../images';
import { Action } from './Action';
import { detectMob } from '../UtilityFunctions';
import { shadow } from '../../theme/shadows';
import {
  moreActionsTheme,
  moreActionsMobileTheme,
} from '../../theme/moreActionsTheme';

export class MoreActions extends React.Component {
  render() {
    const { actions, ...restProps } = this.props;
    return <DropDownAction options={actions} Action={Action} {...restProps} />;
  }
}

MoreActions.defaultProps = {
  icon: moreOptionsIcon,
  dropdownStyle: {
    width: 200,
    height: 'auto',
    maxHeight: 300,
    backgroundColor: '#fff',
    borderRadius: 4,
    ...shadow,
  },
  iconStyle: {
    height: 24,
    width: 24,
  },
  ...(detectMob() ? moreActionsMobileTheme : moreActionsTheme),
};

export default MoreActions;
