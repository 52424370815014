import {
  CardDateRangeRender,
  CardSelection,
} from '../render-components/CardRenders';

const cardRenderTypes = {
  selection: CardSelection,
  dateRange: CardDateRangeRender,
};
export default cardRenderTypes;
