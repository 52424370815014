import React from 'react';
import {Platform, Text} from '@applane/react-core-components';
import {resolveValue} from './CardUtility';
import {
  titleColor,
  primaryTitleColor,
  secondaryTitleColor,
} from './theme/Colors';

const CardTitle = props => {
  let {field, value, variant, item, numberOfLines = 1, html} = props;
  if (typeof value === 'function') {
    value = value(props);
  } else if (value === undefined && field) {
    value = resolveValue(item, field);
  }
  if (value === undefined || value === null) {
    return null;
  }
  let titleStyle = props[variant ? `${variant}TitleStyle` : titleStyle];
  if (!titleStyle?.numberOfLines) {
    titleStyle = {
      ...titleStyle,
      numberOfLines,
    };
  }

  let titleComponent = void 0;
  if (React.isValidElement(value)) {
    titleComponent = value;
  } else if (html && Platform.OS === 'web') {
    titleComponent = (
      <Text style={titleStyle} dangerouslySetInnerHTML={{__html: value}}></Text>
    );
  } else {
    titleComponent = (
      <Text title={value} style={titleStyle}>
        {value}
      </Text>
    );
  }
  return titleComponent;
};

CardTitle.defaultProps = {
  primaryTitleStyle: {
    fontSize: 19,
    color: primaryTitleColor,
    numberOfLines: 1,
  },
  secondaryTitleStyle: {
    fontSize: 14,
    color: secondaryTitleColor,
    numberOfLines: 1,
  },
  titleStyle: {
    fontSize: 14,
    color: titleColor,
    numberOfLines: 1,
  },
};

export default CardTitle;
