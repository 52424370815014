import React from 'react';
import moment from 'moment';
import {View, Image, Text} from '@applane/react-core-components';

class PrevHandler extends React.Component {
  render() {
    let {onClick, theme} = this.props;
    let {viewStyle, imageStyle, prevIcon: icon, textStyle} = theme;
    return (
      <View style={viewStyle} onClick={onClick}>
        {icon ? (
          <Image style={imageStyle} source={icon} />
        ) : (
          <Text style={textStyle}>{'<'}</Text>
        )}
      </View>
    );
  }
}

class NextHandler extends React.Component {
  render() {
    let {onClick, theme} = this.props;
    let {viewStyle, textStyle, nextIcon: icon, imageStyle} = theme;
    return (
      <View style={viewStyle} onClick={onClick}>
        {icon ? (
          <Image style={imageStyle} source={icon} />
        ) : (
          <Text style={textStyle}>{'>'}</Text>
        )}
      </View>
    );
  }
}

class Week extends React.Component {
  render() {
    let {children, primary, theme, ...rest} = this.props;
    let {viewStyle, primaryViewStyle, textStyle, primaryTextStyle} =
      theme.weekComponentStyle || {};
    let _viewStyle = primary
      ? {...viewStyle, ...primaryViewStyle}
      : {...viewStyle};
    let _textStyle = primary
      ? {...textStyle, ...primaryTextStyle}
      : {...textStyle};
    return (
      <View style={_viewStyle}>
        <Text style={_textStyle}>{children}</Text>
      </View>
    );
  }
}

class DateView extends React.Component {
  onChange = e => {
    e.preventDefault();
    let {onChange, value, year, month, date, hideModal,select} = this.props;
    value = new Date();
    if (!value) {
      value = new Date();
    }
    value.setFullYear(year);
    value.setMonth(month, date);
    select&&select(value)
    onChange && onChange(value);
    hideModal && hideModal();
  };
  render() {
    let {
      children,
      deActive,
      selected,
      onChange,
      date,
      month,
      year,
      renderDate,
      theme,
      ...rest
    } = this.props;
    let {
      viewStyle = {},
      textStyle = {},
      selectedTextStyle = {},
      selectedViewStyle = {},
      deActiveTextStyle = {},
      deActiveViewStyle = {},
    } = theme.dateStyle || {};
    if (renderDate) {
      let renderDateProps = {date, year, month, deActive, selected};
      if (React.isValidElement(renderDate)) {
        renderDate = React.cloneElement(renderDate, renderDateProps);
      } else if (typeof renderDate === 'function') {
        renderDate = renderDate(renderDateProps);
      }
    }
    let _viewStyle = deActive
      ? {...viewStyle, ...deActiveViewStyle}
      : selected
      ? {...viewStyle, ...selectedViewStyle}
      : {...viewStyle};
    let _textStyle = deActive
      ? {...textStyle, ...deActiveTextStyle}
      : selected
      ? {...textStyle, ...selectedTextStyle}
      : {...textStyle};
    return (
      <View style={_viewStyle} onClick={this.onChange}>
        {renderDate || (
          <Text style={{..._textStyle}} {...rest}>
            {children > 9 ? children : `0${children}`}
          </Text>
        )}
      </View>
    );
  }
}

export default class SelectDate extends React.Component {
  constructor(props) {
    super(props);
    let {value, format = 'DD MMM,YY'} = this.props;
    let currentDate = value
      ? moment(value, format).toDate()
      : moment(new Date(), format).toDate();
    this.state = this.getState(currentDate);
  }
  select=(value)=>{
    this.setState({selectedDate:moment(value).format("D")})
  }
  getState(currentDate) {
    return {
      date: currentDate.getDate(),
      month: currentDate.getMonth(),
      year: currentDate.getFullYear(),
      visibleView: 'dateView',
      yearViewBase: currentDate.getFullYear(),
      selectedDate:null
    };
  }
  componentWillReceiveProps({value, format = 'DD MMM,YY'}) {
    if (value && value !== this.oldDate) {
      let currentDate = value
        ? moment(value, format).toDate()
        : moment(new Date(), format).toDate();

      this.setState(this.getState(currentDate));
    }
    this.oldDate = moment(new Date(), format).toDate();
  }
  getDates({date, month, year}) {
    const {onChange, value, renderDate, hideModal, theme} = this.props;
    let dates = [];
    let currMonLstDtObj = new Date(year, month + 1, 0);
    let currMonLstDt = currMonLstDtObj.getDate();
    let currMonLstDay = currMonLstDtObj.getDay();
    let prevMonlstDtObj = new Date(year, month, 0);
    let prevMonLstDt = prevMonlstDtObj.getDate();
    let prevMonLstDay = prevMonlstDtObj.getDay();
    let perRowDate = 0;
    let dateToRender = [];
    while (prevMonLstDay >= 0 && prevMonLstDay < 6) {
      dates.unshift(
        <DateView
          theme={theme}
          deActive
          key={'prev' + prevMonLstDt}
          date={prevMonLstDt}
          renderDate={renderDate}>
          {prevMonLstDt}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.unshift(
          <View
            style={{
              flexDirection: 'row',
              flex: 1,
            }}>
            {dates}
          </View>,
        );
        dates = [];
      }
      prevMonLstDt--;
      prevMonLstDay--;
    }
    let currMonFstDt = 1;
    date=this.state.selectedDate&&Number(this.state.selectedDate)||date
    while (currMonFstDt <= currMonLstDt) {
      dates.push(
        <DateView
          theme={theme}
          key={currMonFstDt}
          selected={date === currMonFstDt}
          date={currMonFstDt}
          month={month}
          year={year}
          value={value}
          onChange={onChange}
          hideModal={hideModal}
          select={this.select}
          renderDate={renderDate}>
          {currMonFstDt}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.push(
          <View
            style={{
              flexDirection: 'row',
            }}>
            {dates}
          </View>,
        );
        dates = [];
      }
      currMonFstDt++;
    }
    currMonLstDay = 7 - currMonLstDay;
    let i = 1;
    while (i < currMonLstDay) {
      dates.push(
        <DateView
          theme={theme}
          deActive
          key={'next' + i}
          date={i}
          renderDate={renderDate}>
          {i}
        </DateView>,
      );
      perRowDate++;
      if (perRowDate === 7) {
        perRowDate = 0;
        dateToRender.push(
          <View
            style={{
              flexDirection: 'row',
            }}>
            {dates}
          </View>,
        );
        dates = [];
      }
      i++;
    }

    return <React.Fragment>{dateToRender}</React.Fragment>;
  }
  onDateChange = params => {
    this.props.onDateChange && this.props.onDateChange(params);
  };

  nextMonth = () => {
    let {month, year} = this.state;
    if (month === 11) {
      month = 0;
      year++;
    } else {
      month++;
    }
    // this.onDateChange({month, year});
    this.setState({month, year});
  };
  prevMonth = () => {
    let {month, year} = this.state;
    if (month === 0) {
      month = 11;
      year--;
    } else {
      month--;
    }
    // this.onDateChange({month, year});
    this.setState({month, year});
  };

  onMonthClick = month => {
    let {year} = this.state;
    // this.onDateChange({month, year});
    this.setState({month, visibleView: 'dateView'});
  };

  onYearClick = year => {
    let {month} = this.state;
    // this.onDateChange({month, year});
    this.setState({year, visibleView: 'dateView'});
  };

  getYearsArray = () => {
    let {
      yearView: {bodyStyle: {yearViewStyle = {}, yearTextStyle = {}} = {}} = {},
    } = this.props.theme || {};

    let yearBase = this.state.yearViewBase;
    let yearArray = [];
    let yearArrayToRender = [];
    for (let i = 0; i < 3; i++) {
      yearArray[i] = [];

      for (let j = 0; j < 3; j++) {
        yearArray[i][j] = yearBase + i * 3 + j;
        yearArrayToRender.push(
          <View
            onClick={() => this.onYearClick(yearArray[i][j])}
            style={yearViewStyle}>
            <Text style={yearTextStyle}>{yearArray[i][j]}</Text>
          </View>,
        );
      }
    }

    return yearArrayToRender;
  };

  render() {
    const {date, month, year, visibleView} = this.state;
    let {weekNames, monthNames, theme} = this.props;
    weekNames = weekNames || ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    monthNames = monthNames || [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    let render = [];

    if (visibleView === 'dateView') {
      let {
        containerStyle = {},
        header: {
          containerStyle: header_containerStyle,
          prev: prevTheme,
          next: nextTheme,
          year: {viewStyle: year_viewStyle, textStyle: year_textStyle} = {},
          month: {viewStyle: month_viewStyle, textStyle: month_textStyle} = {},
        } = {},
        body: {weekContainerStyle} = {},
      } = theme.dataView || {};
      render = (
        <React.Fragment>
          <View style={header_containerStyle}>
            <PrevHandler onClick={this.prevMonth} theme={prevTheme} />
            <View
              style={month_viewStyle}
              onClick={() => this.setState({visibleView: 'monthView'})}>
              <Text style={month_textStyle}>{monthNames[month]}</Text>
            </View>
            <View
              style={year_viewStyle}
              onClick={() => this.setState({visibleView: 'yearView'})}>
              <Text style={year_textStyle}>{year}</Text>
            </View>
            <NextHandler onClick={this.nextMonth} theme={nextTheme} />
          </View>
          <View style={weekContainerStyle}>
            <Week theme={theme} primary>
              {weekNames[0]}
            </Week>
            <Week theme={theme}>{weekNames[1]}</Week>
            <Week theme={theme}>{weekNames[2]}</Week>
            <Week theme={theme}>{weekNames[3]}</Week>
            <Week theme={theme}>{weekNames[4]}</Week>
            <Week theme={theme}>{weekNames[5]}</Week>
            <Week theme={theme}>{weekNames[6]}</Week>
          </View>
          <View style={{cursor: 'pointer'}}>
            {this.getDates({date, month, year})}
          </View>
        </React.Fragment>
      );
      return <View style={containerStyle}> {render}</View>;
    } else if (visibleView === 'monthView') {
      let {
        containerStyle,
        header: {
          containerStyle: header_containerStyle,
          prev: prevTheme,
          next: nextTheme,
          year: {viewStyle: year_viewStyle, textStyle: year_textStyle},
        },
        bodyStyle: {monthRowStyle, monthTextStyle, monthViewStyle},
      } = theme.monthView || {};
      let render = (
        <React.Fragment>
          <View style={header_containerStyle}>
            <PrevHandler
              theme={prevTheme}
              onClick={() =>
                this.setState({year: year - 1, yearViewBase: year - 1})
              }
            />
            <View
              style={year_viewStyle}
              onClick={() => this.setState({visibleView: 'yearView'})}>
              <Text style={year_textStyle}>{year}</Text>
            </View>
            <NextHandler
              theme={nextTheme}
              onClick={() =>
                this.setState({year: year + 1, yearViewBase: year + 1})
              }
            />
          </View>
          <View style={monthRowStyle}>
            <View style={monthViewStyle} onClick={() => this.onMonthClick(0)}>
              <Text style={monthTextStyle}>{'Jan'}</Text>
            </View>
            <View style={monthViewStyle} onClick={() => this.onMonthClick(1)}>
              <Text style={monthTextStyle}>{'Feb'}</Text>
            </View>
            <View style={monthViewStyle} onClick={() => this.onMonthClick(2)}>
              <Text style={monthTextStyle}>{'Mar'}</Text>
            </View>
          </View>
          <View style={monthRowStyle}>
            <View style={monthViewStyle} onClick={() => this.onMonthClick(3)}>
              <Text style={monthTextStyle}>{'Apr'}</Text>
            </View>
            <View style={monthViewStyle} onClick={() => this.onMonthClick(4)}>
              <Text style={monthTextStyle}>{'May'}</Text>
            </View>
            <View style={monthViewStyle} onClick={() => this.onMonthClick(5)}>
              <Text style={monthTextStyle}>{'Jun'}</Text>
            </View>
          </View>
          <View style={monthRowStyle}>
            <View style={monthViewStyle} onClick={() => this.onMonthClick(6)}>
              <Text style={monthTextStyle}>{'July'}</Text>
            </View>
            <View style={monthViewStyle} onClick={() => this.onMonthClick(7)}>
              <Text style={monthTextStyle}>{'Aug'}</Text>
            </View>
            <View style={monthViewStyle} onClick={() => this.onMonthClick(8)}>
              <Text style={monthTextStyle}>{'Sep'}</Text>
            </View>
          </View>
          <View style={monthRowStyle}>
            <View style={monthViewStyle} onClick={() => this.onMonthClick(9)}>
              <Text style={monthTextStyle}>{'Oct'}</Text>
            </View>
            <View style={monthViewStyle} onClick={() => this.onMonthClick(10)}>
              <Text style={monthTextStyle}>{'Nov'}</Text>
            </View>
            <View style={monthViewStyle} onClick={() => this.onMonthClick(11)}>
              <Text style={monthTextStyle}>{'Dec'}</Text>
            </View>
          </View>
        </React.Fragment>
      );

      return <View Style={containerStyle}>{render}</View>;
    } else {
      let {
        containerStyle = {},
        header: {
          containerStyle: header_containerStyle = {},
          prev: prevTheme,
          next: nextTheme,
          year: {
            viewStyle: year_viewStyle = {},
            textStyle: year_textStyle = {},
          },
        } = {},
        bodyStyle: {
          yearRowStyle,
          container: body_container,
          yearTextStyle,
          yearViewStyle,
        } = {},
      } = theme.yearView || {};
      let yearArray = this.getYearsArray();
      let yearArrayItems = [];
      let yearArrayPerRow = [];
      yearArray.map(year => {
        yearArrayItems.push(year);
        if (yearArrayItems.length === 3) {
          yearArrayPerRow.push(
            <View style={yearRowStyle}>{yearArrayItems}</View>,
          );
          yearArrayItems = [];
        }
      });
      let render = (
        <React.Fragment>
          <View style={header_containerStyle}>
            <PrevHandler
              theme={prevTheme}
              onClick={() =>
                this.setState({yearViewBase: this.state.yearViewBase - 9})
              }
            />
            <View style={year_viewStyle}>
              <Text style={year_textStyle}>{'Select Year'}</Text>
            </View>
            <NextHandler
              theme={nextTheme}
              onClick={() =>
                this.setState({yearViewBase: this.state.yearViewBase + 9})
              }
            />
          </View>
          <View style={body_container}>{yearArrayPerRow}</View>
        </React.Fragment>
      );
      return <View Style={containerStyle}>{render}</View>;
    }
  }
}
