import React, { createContext } from 'react';
import {
  notificationCount,
  notificationData,
  getUser,
} from './AppServices';
import withGpsContext from './withGpsContext';
import { Snackbar } from './app-components';

const intialState = {};
const ClassRoomNotificationContext = createContext(intialState);

class NotificationContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationCount: 0,
      notificationData: [],
      markRead: this.markRead,
    };
  }

  componentDidMount() {
    this.notificationSubscription();
    this.getDataCount();
    this.getData();
  }

  componentWillUnmount() {
    this.notificationUnSubscription();
  }

  notificationSubscription = () => {
    const { _id } = getUser() || {};
    const { eventDispatcher } = this.props;

    eventDispatcher.listen('updateNotifications', () => {
      this.getData();
      this.getDataCount();
    });

    if (_id) {
      this.userId = _id;
      const { gpsStore } = this.props;
      const { subscribeNotification } = gpsStore;
      subscribeNotification({ userId: _id, callback: this.callback });
    }
  };

  callback = (data) => {
    const { notification } = data;
    const { notificationData, notificationCount } = this.state;
    const newNotificationData = [notification, ...notificationData];
    const newNotificationcount = notificationCount + 1;
    this.setState({
      notificationData: newNotificationData,
      notificationCount: newNotificationcount,
    });
  };

  notificationUnSubscription = () => {
    const { gpsStore } = this.props;
    if (gpsStore && gpsStore.unsubscribeNotification && this.userId) {
      gpsStore.unsubscribeNotification({ userId: this.userId });
    }
  };

  getDataCount = () => {
    if (getUser()) {
      try {
        notificationCount()
          .then((res) => {
            const { aggregates: { _count } = {} } = res;
            if (_count) {
              this.setState({
                notificationCount: _count,
              });
            }
          })
          .catch((err) => err);
      } catch (err) {
        const message = (err && err.message) || '';
        Snackbar.show({
          text: `${message}`,
          duration: Snackbar.LENGTH_SHORT,
        });
      }
    }
  };

  getData = () => {
    if (getUser()) {
      try {
        notificationData()
          .then((res) => {
            const { data = [] } = res;
            if (data.length !== 0) {
              this.setState({
                notificationData: data,
              });
            }
          })

          .catch((err) => err);
      } catch (err) {
        const message = (err && err.message) || '';
        Snackbar.show({
          text: `${message}`,
          duration: Snackbar.LENGTH_SHORT,
        });
      }
    }
  };

  render() {
    const { children } = this.props;
    return (
      <ClassRoomNotificationContext.Provider value={{ ...this.state }}>
        {children}
      </ClassRoomNotificationContext.Provider>
    );
  }
}

const NotificationContextProviderHoc = withGpsContext(
  NotificationContextProvider,
);
export { NotificationContextProviderHoc };

export default (Component) => (props) => (
  <ClassRoomNotificationContext.Consumer>
    {(value) => <Component classroomStore={value} {...props} />}
  </ClassRoomNotificationContext.Consumer>
);
