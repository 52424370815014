import React from 'react';
import {View, Text} from '@applane/react-core-components';
import CardItem from './CardItem';
import {modifyStyle, resolveVisibleExp} from './CardUtility';

class Separator extends React.Component {
  render() {
    let {
      separator,
      source = 'content',
      separatorStyle,
      separatorTextStyle,
      dotSeparatorStyle,
    } = this.props;
    if (!separatorStyle) {
      separatorStyle =
        this.props[`${source}ItemSeparatorStyle`] ||
        this.props[`itemSeparatorStyle`];
    }
    let separatorComponent = void 0;
    if (React.isValidElement(separator)) {
      separatorComponent = separator;
    } else if (typeof separator === 'string') {
      if (!separatorTextStyle) {
        separatorTextStyle =
          this.props[`${source}ItemSeparatorTextStyle`] ||
          this.props[`itemSeparatorTextStyle`];
      }
      separatorComponent = <Text style={separatorTextStyle}>{separator}</Text>;
    } else if (dotSeparatorStyle) {
      separatorComponent = <View style={dotSeparatorStyle} />;
    }
    if (!separatorComponent) {
      return null;
    }
    return <View style={separatorStyle}>{separatorComponent}</View>;
  }
}
Separator.defaultProps = {
  dotSeparatorStyle: {
    height: 4,
    width: 4,
    borderRadius: 2,
    backgroundColor: '#7a5af1',
  },
  contentItemSeparatorStyle: {
    marginLeft: 8,
    marginRight: 8,
  },
};

const getSeparator = props => {
  let {source = 'content', separator} = props;
  let defaultSeparator = props[`${source}Separator`];
  if (separator === undefined && defaultSeparator) {
    separator = defaultSeparator;
  }
  if (!separator) {
    return null;
  }
  return <Separator {...props} separator={separator} />;
};

const CardItems = props => {
  let {items, parentDirection, recursion, ...restProps} = props;
  if (!items || !items.length) {
    return null;
  }
  let {source} = restProps;
  let newItems = [];
  let itemCount = 0;
  items.forEach(contentItem => {
    if (!contentItem) {
      return;
    }
    let itemContainerStyle =
      (!recursion && props[`${source}ItemContainerStyle`]) || {};
    itemContainerStyle = {...itemContainerStyle};
    let currentDirection = parentDirection === 'row' ? 'column' : 'row';
    itemContainerStyle.flexDirection = currentDirection;
    if (Array.isArray(contentItem)) {
      newItems.push(
        <View style={itemContainerStyle}>
          <CardItems
            items={contentItem}
            parentDirection={currentDirection}
            {...restProps}
          />
        </View>,
      );
    } else {
      let {
        width,
        maxWidth,
        minWidth,
        visible,
        align,
        flex,
        position,
        direction = currentDirection,
        items: innerItems,
        containerStyle,
        separatorStyle,
        separatorTextStyle,
        separator,
        ...restContentItem
      } = contentItem;
      if (!resolveVisibleExp(visible, props)) {
        return null;
      }
      let Component = void 0;
      if (innerItems) {
        Component = (
          <CardItems
            {...restProps}
            items={innerItems}
            parentDirection={direction}
            recursion
          />
        );
      } else {
        let {dotSeparatorStyle, ...restItemProps} = restProps;
        Component = CardItem({
          ...restItemProps,
          column: restContentItem,
        });
        if (Component) {
          if (itemCount > 0) {
            let separatorComponent = getSeparator({
              ...restProps,
              separator,
              separatorStyle,
              separatorTextStyle,
            });
            if (separatorComponent) {
              newItems.push(separatorComponent);
            }
          }
          itemCount = itemCount + 1;
        }
      }
      itemContainerStyle = modifyStyle({
        style: {
          ...itemContainerStyle,
          ...containerStyle,
        },
        direction,
        width,
        maxWidth,
        minWidth,
        flex,
        position,
        align,
        defaultAlign: false,
      });
      newItems.push(<View style={itemContainerStyle}>{Component}</View>);
    }
  });
  return newItems;
};

export default (props = {}) => {
  let {content, direction: defaultDirection, ...restProps} = props;
  if (Array.isArray(content)) {
    content = {direction: defaultDirection, items: content};
  }
  let {items, direction = defaultDirection, ...restContentInfo} = content;
  if (!items || !items.length) {
    return null;
  }
  let {source} = restProps;
  let containerStyle = modifyStyle({
    style: restProps[`${source}ContainerStyle`],
    direction,
    ...restContentInfo,
  });
  return (
    <View style={containerStyle}>
      <CardItems {...restProps} items={items} parentDirection={direction} />
    </View>
  );
};
