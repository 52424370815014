import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
} from '@applane/react-core-components';
import { WithInputWrapper } from '@applane/react-input-wrapper';

class CheckBox extends Component {
  onPress = (e) => {
    const { value, onChangeValue, onChange } = this.props;
    if (onChange) {
      onChange(e);
    }
    if (onChangeValue) {
      onChangeValue && onChangeValue(!value, e);
    }
  };

  render() {
    const {
      label,
      value,
      editable,
      disabled,
      selectedIcon,
      unSelectedIcon,
      labelStyle,
      inputStyle,
      iconStyle,
    } = this.props;
    let renderComponent = (
      <View style={{ alignItems: 'flex-start' }}>
        <TouchableOpacity
          style={{
            cursor: 'pointer', justifyContent: 'center', alignItems: 'center',
          }}
          onPress={this.onPress}
        >
          <Image
            source={value ? selectedIcon : unSelectedIcon}
            resizeMode="contain"
            style={iconStyle}
          />
        </TouchableOpacity>
      </View>
    );
    if (!editable || disabled) {
      renderComponent = (
        <View style={{ alignItems: 'flex-start', cursor: 'not-allowed' }}>
          <Image
            source={value ? selectedIcon : unSelectedIcon}
            resizeMode="contain"
            style={iconStyle}
          />
        </View>
      );
    }
    if (label) {
      renderComponent = (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {renderComponent}
          <View style={{ flex: 1, overflow: 'hidden', marginLeft: 6 }}>
            <Text style={labelStyle}>{label}</Text>
          </View>
        </View>
      );
    }
    return renderComponent;
  }
}
CheckBox = WithInputWrapper(CheckBox);
export default CheckBox;
