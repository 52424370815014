import React from 'react';
import { backCrossIcon, backIcon } from '../../images';
import { ActionButton } from '../buttons/Buttons';
import { cancelButtonTheme } from '../../theme/actionTheme';

export const close = {
  icon: backCrossIcon,
  // visible: ({navigation}) => {
  //   const count = navigation.getRouteCount();
  //   if (count > 1) {
  //     return true;
  //   }
  // },
  type: 'close',
};

export const back = {
  icon: backIcon,
  visible: ({ screenState } = {}) => {
    let selectionCount =
      screenState && screenState.selectedIds && screenState.selectedIds.length;
    if (selectionCount) {
      return false;
    }
    return true;
  },
  type: 'close',
};

export const unSelectAll = {
  icon: backCrossIcon,
  visible: ({ screenState } = {}) => {
    let selectionCount =
      screenState && screenState.selectedIds && screenState.selectedIds.length;
    if (selectionCount) {
      return true;
    }
    return false;
  },
  type: 'unSelectAll',
};
export const cancelButton = ({ id, label, text, ...restProps } = {}) => {
  label = label || text || 'Cancel';
  if (!id && typeof label === 'string') {
    id = label;
  }
  return {
    id: label,
    label,
    type: 'close',
    render: () => {
      return (
        <ActionButton theme={cancelButtonTheme} label={label} {...restProps} />
      );
    },
  };
};

export const closeAction = ({ navigation }) => {
  navigation && navigation.goBack();
};

export const unSelectAllAction = ({setScreenState} = {}) => {
  setScreenState && setScreenState({selectedIds: [], allPageSelected: false});
};
