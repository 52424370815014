import React from 'react';
import {View, FlatList, Platform} from '@applane/react-core-components';
import WithDataLoader from './WithDataLoader';
import {getRenderComponent, getResolvedColumnsAndWidth} from './Utility';

class List extends React.PureComponent {
  constructor(props) {
    super(props);
    const {pageLimit} = props;
    let state = {};
    if (pageLimit) {
      state.limit = pageLimit;
    }
    this.state = state;
  }
  keyExtractor = (item, index) => {
    return (item && item._id) || `${index}`;
  };

  onRowMouseEnter = callback => {
    if (this.lastOnMouseOver && this.lastOnMouseOver !== callback) {
      this.lastOnMouseOver();
    }
    this.lastOnMouseOver = callback;
  };

  onRowSelect = callback => {
    if (this.lastOnRowSelect && this.lastOnRowSelect !== callback) {
      this.lastOnRowSelect();
    }
    this.lastOnRowSelect = callback;
  };

  onRowToggleExpand = callback => {
    if (this.lastOnRowToggleExpand && this.lastOnRowToggleExpand !== callback) {
      this.lastOnRowToggleExpand();
    }
    this.lastOnRowToggleExpand = callback;
  };

  loadMore = () => {
    let {onLoadMore} = this.props;
    onLoadMore && onLoadMore();
  };

  onScroll = e => {
    let {onScroll, data, pageLimit, threshold = 0.5} = this.props;
    let {scrollHeight, scrollTop: currentHeight} = e;
    onScroll && onScroll(e);
    const {limit} = this.state;
    if (
      scrollHeight &&
      currentHeight &&
      currentHeight > scrollHeight * threshold
    ) {
      //if y has passed the 50% mark then we can load more data
      //we can load more records here
      if (limit) {
        let newLimit = limit + pageLimit;
        if (newLimit < data.length + pageLimit) {
          if (newLimit > data.length) {
            this.loadMore();
          }
          this.setState({limit: newLimit});
        }
      } else {
        this.loadMore();
      }
    }
    if (this.headerRef) {
      this.headerRef.scrollLeft = e.scrollLeft;
    }
    if (this.footerRef) {
      this.footerRef.scrollLeft = e.scrollLeft;
    }
  };

  getHeaderRef = ref => {
    this.headerRef = ref;
  };
  getFooterRef = ref => {
    this.footerRef = ref;
  };

  getHeaderRow = props => {
    const {
      renderHeaderRow,
      hideColumnHeader,
      headerRowContainerStyle,
      renderScrollColumn,
    } = this.props;
    let {columns, width} = props;
    if (!renderHeaderRow || !columns || !columns.length || hideColumnHeader) {
      return;
    }
    let scrollColumn = void 0;
    if (renderScrollColumn) {
      let {columnComponent, width: scrollWidth} = renderScrollColumn() || {};
      scrollColumn = columnComponent;
      if (width && scrollWidth) {
        width += scrollWidth;
      }
    }
    return (
      <View
        style={{
          flexDirection: 'row',
          overflow: 'hidden',
        }}
        getRef={this.getHeaderRef}>
        <View
          style={{
            flex: 1,
            width,
            justifyContent: 'center',
            ...headerRowContainerStyle,
          }}>
          <View style={{flexDirection: 'row'}}>
            {renderHeaderRow(props)}
            {scrollColumn}
          </View>
        </View>
      </View>
    );
  };

  getFooterRow = props => {
    const {
      renderFooterRow,
      showColumnFooter,
      footerRowContainerStyle,
      renderScrollColumn,
    } = this.props;
    let {columns, width} = props;
    if (!renderFooterRow || !columns || !columns.length || !showColumnFooter) {
      return;
    }
    let scrollColumn = void 0;
    if (renderScrollColumn) {
      let {columnComponent, width: scrollWidth} = renderScrollColumn() || {};
      scrollColumn = columnComponent;
      if (width && scrollWidth) {
        width += scrollWidth;
      }
    }
    return (
      <View
        style={{
          flexDirection: 'row',
          overflow: 'hidden',
        }}
        getRef={this.getFooterRef}>
        <View
          style={{
            width,
            flex: 1,
            alignItems: 'center',
            flexDirection: 'row',
            ...footerRowContainerStyle,
          }}>
          {renderFooterRow(props)}
          {scrollColumn}
        </View>
      </View>
    );
  };
  renderItem = ({item, index}) => {
    let {
      renderRow,
      renderItem,
      columns,
      width,
      card,
      data,
      onRowSelect,
      onRowMouseEnter,
      onRowToggleExpand,
    } = this.props;
    renderItem = renderItem || renderRow;
    if (!renderItem) {
      return null; // todo
    }
    return renderItem({
      item,
      index,
      isFirst: index === 0,
      isLast: index === data.length - 1,
      card,
      columns,
      rowWidth: width,
      onRowMouseEnter: onRowMouseEnter || this.onRowMouseEnter,
      onRowSelect: onRowSelect || this.onRowSelect,
      onRowToggleExpand: onRowToggleExpand || this.onRowToggleExpand,
    });
  };

  render() {
    let {
      flex,
      card,
      renderRow,
      renderItem,
      containerStyle,
      scrollViewStyle,
      cardContainerStyle,
      cardScrollViewStyle,
      renderLoading,
      renderHeader,
      renderFooter,
      getRef,
      onLayout,
      renderNoData,
      skipHeaderOnNoData,
      skipFooterOnNoData,
      numColumns,
      showsVerticalScrollIndicator,
      showsHorizontalScrollIndicator,
      keyboardShouldPersistTaps,
      bounces,
      removeClippedSubviews,
      columnWrapperStyle,
      minHeight,
      maxHeight,
      pageLimit,
      CustomFlatList,
      extraData,
      ...restProps
    } = this.props;
    if (card) {
      containerStyle = cardContainerStyle;
      scrollViewStyle = cardScrollViewStyle;
    }
    containerStyle = {
      overflow: 'hidden',
      ...containerStyle,
    };
    if (flex === undefined) {
      containerStyle.flex = containerStyle.flex || 1;
    } else {
      containerStyle.flex = flex || void 0;
    }
    if (minHeight) {
      containerStyle.minHeight = minHeight;
    }
    if (maxHeight) {
      containerStyle.maxHeight = maxHeight;
    }

    let {data} = restProps;
    if (pageLimit) {
      if (data && this.state.limit < data.length) {
        data = data.slice(0, this.state.limit);
      }
    }

    let extraProps = {};
    if (Platform.OS !== 'web') {
      if (bounces !== undefined) {
        extraProps.bounces = bounces;
      }
      if (keyboardShouldPersistTaps !== undefined) {
        extraProps.keyboardShouldPersistTaps = keyboardShouldPersistTaps;
      }
      if (removeClippedSubviews !== undefined) {
        extraProps.removeClippedSubviews = removeClippedSubviews;
      }
    }

    // Support for the custom list(custom flstlist), if passed by the user
    const Component = CustomFlatList || FlatList;
    let listComponent = (
      <Component
        {...(Platform.OS === 'web' ? {onLayout, onScroll: this.onScroll} : {})}
        {...extraProps}
        getRef={getRef}
        data={data}
        keyExtractor={this.keyExtractor}
        style={scrollViewStyle}
        renderItem={this.renderItem}
        numColumns={numColumns}
        showsVerticalScrollIndicator={showsVerticalScrollIndicator}
        showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
        columnWrapperStyle={columnWrapperStyle}
        extraData={extraData}
      />
    );
    let showNoData =
      renderNoData && restProps.loading === false && (!data || !data.length);
    return (
      <View style={containerStyle}>
        {getRenderComponent(renderHeader, restProps)}
        {showNoData && skipHeaderOnNoData
          ? void 0
          : this.getHeaderRow(restProps)}
        {showNoData ? getRenderComponent(renderNoData, {}) : listComponent}
        {showNoData && skipFooterOnNoData
          ? void 0
          : this.getFooterRow(restProps)}
        {getRenderComponent(renderFooter, restProps)}
        {getRenderComponent(renderLoading, restProps)}
      </View>
    );
  }
}

export class ReactTable extends React.PureComponent {
  state = {};

  static getDerivedStateFromProps(props, state) {
    let {columns} = props;
    if (state.columns !== columns) {
      let {width, columns: resolvedColumns} = getResolvedColumnsAndWidth({
        ...props,
        clientWidth: state.clientWidth,
      });
      return {
        columns,
        width,
        resolvedColumns,
      };
    }
    return null;
  }

  renderScrollColumn = () => {
    let {verticalScrollWidth, verticalScrollRequired} = this.state;
    if (verticalScrollRequired) {
      return {
        columnComponent: <View style={{width: verticalScrollWidth}} />,
        width: verticalScrollWidth,
      };
    }
  };

  onBodyLayout = () => {
    if (this.bodyRef) {
      const {
        offsetHeight,
        offsetWidth,
        scrollHeight,
        scrollWidth,
        clientHeight,
        clientWidth,
      } = this.bodyRef;

      const {
        offsetHeight: stateOffsetHeight,
        offsetWidth: stateOffsetWidth,
        scrollHeight: stateScrollHeight,
        scrollWidth: stateScrollWidth,
        clientWidth: stateClientWidth,
        clientHeight: stateClientHeight,
      } = this.state;

      if (
        offsetHeight !== stateOffsetHeight ||
        offsetWidth !== stateOffsetWidth ||
        scrollHeight !== stateScrollHeight ||
        scrollWidth !== stateScrollWidth ||
        clientWidth !== stateClientWidth ||
        clientHeight !== stateClientHeight
      ) {
        let verticalScrollRequired = offsetWidth - clientWidth > 0;
        let verticalScrollWidth = offsetWidth - clientWidth;
        let stateToSet = {
          offsetHeight,
          offsetWidth,
          scrollHeight,
          scrollWidth,
          clientWidth,
          clientHeight,
          verticalScrollRequired,
          verticalScrollWidth,
        };
        if (clientWidth !== stateClientWidth) {
          const {width, columns: resolvedColumns} = getResolvedColumnsAndWidth({
            clientWidth,
            ...this.props,
          });
          Object.assign(stateToSet, {
            width,
            resolvedColumns,
          });
        }
        this.setState(stateToSet);
      }
    }
  };

  getBodyRef = ref => {
    if (ref) {
      this.bodyRef = ref;
    }
  };

  render() {
    const {width, resolvedColumns} = this.state;
    return (
      <List
        {...this.props}
        width={width}
        columns={resolvedColumns}
        getRef={this.getBodyRef}
        onLayout={this.onBodyLayout}
        renderScrollColumn={this.renderScrollColumn}
      />
    );
  }
}

ReactTable.defaultProps = {
  scrollViewStyle: {
    flex: 1,
  },
  cardScrollViewStyle: {
    flex: 1,
  },
};

ReactTable = WithDataLoader(ReactTable);

export default ReactTable;
