import { isMobile } from '../UtilityFunctions';

export default (props = {}) => {
  let {
    navigation,
    screenState,
    setScreenState,
    action: { confirmModalProps, confirmProps, ...restAction },
    item,
    uid,
    fetchUriEvent,
  } = props;
  if (!navigation) {
    return;
  }
  if (confirmProps && typeof confirmProps === 'function') {
    confirmProps = confirmProps(props);
  }
  setTimeout(
    () =>
      navigation.push({
        view: 'confirm',
        params: {
          confirmProps: {
            uid,
            item,
            action: restAction,
            screenState,
            setScreenState,
            fetchUriEvent,
            ...confirmProps,
          },
        },
        modal: true,
        modalProps: {
          minHeight: 180,
          backgroundColor: 'transparent',
          maxWidth: isMobile ? 350 : 412,
          position: 'screenCenter',
          ...confirmModalProps,
        },
      }),
    100,
  );
};
