import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Text,
  TouchableOpacity,
  View,
  Image,
  ScrollView,
  isMobile,
} from '../../../../app-components';
import {
  h14_Medium,
  h20_AvenirNext_500,
  h18_Medium,
  h14_AvenirNext,
  h13_Regular,
  h12_Regular,
} from '../../../../theme/fonts';
import { colors } from '../../../../theme/mayaMdColors';
import { profile } from '../../../../images';

const { themeColor, themeContainer, themeTextColor } = colors;

const DoctorProfile = ({ navigation }) => {
  const { state: { params = {} } = {} } = navigation || {};
  const {
    item = {}, index, openBooking, selectedTimeSlot, selectdDate,
  } = params;
  const { organization: { account_status } = {} } = item || {};
  useEffect(() => {
    if (openBooking) {
      navigation.push({
        view: 'booking',
        params: { item, index, openBooking: true },
      });
    }
  }, []);

  const [showProfile, setShowProfile] = useState(true);
  const [selectedTab, setSelectedTab] = useState(1);
  const [isDateAndTimeSelected] = useState(
    !!(selectdDate || selectedTimeSlot),
  );

  const awards = item?.award_and_achievement_description
    ? Array(item?.award_and_achievement_description)
    : [];

  const tabList = [
    {
      label: 'About',
      visible: true,
    },
    {
      label: 'Specialization',
      visible: true,
    },
    {
      label: 'Awards',
      visible: true,
    },
  ];
  return (
    <ScrollView
      style={{
        flex: 1,
      }}
    >
      {openBooking || selectedTimeSlot ? null : (
        <TouchableOpacity
          style={{
            zIndex: 10,
            cursor: 'pointer',
            marginLeft: 10,
          }}
          onPress={() => navigation.pop()}
        >
          <Text
            style={{
              ...h14_AvenirNext,
              lineHeight: 21,
              letterSpacing: 0.5,
              color: themeTextColor,
            }}
          >
            {'< '}
            {/* Back */}
            {'Back'}
          </Text>
        </TouchableOpacity>
      )}
      <View
        style={{
          borderRadius: 16,
          margin: 10,
          backgroundColor: themeContainer,
        }}
      >
        <View
          style={{
            paddingTop: isMobile ? 10 : 20,
            paddingBottom: 20,
            color: 'teal',
            alignItems: isMobile ? 'center' : undefined,
          }}
        >
          {isMobile ? (
            <View style={{ marginRight: 15, marginLeft: 15 }}>
              <Image
                source={profile}
                style={{ height: 100, width: 100, borderRadius: 50 }}
              />
            </View>
          ) : (
            void 0
          )}
          <View
            style={{ flexDirection: 'row', padding: isMobile ? 10 : undefined }}
          >
            {isMobile ? (
              void 0
            ) : (
              <View style={{ marginRight: 15, marginLeft: 15 }}>
                <Image
                  source={profile}
                  style={{ height: 100, width: 100, borderRadius: 50 }}
                />
              </View>
            )}
            <View style={{ flex: 1 }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Text
                    style={{
                      ...h20_AvenirNext_500,
                      fontWeight: 700,
                      lineHeight: 18,
                      color: themeTextColor,
                    }}
                  >
                    {item?.name}
                  </Text>
                  <Image
                    style={{
                      height: 20,
                      width: 20,
                      marginLeft: 6,
                      marginRight: 6,
                    }}
                  />
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 20,
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: 0,
                    marginLeft: 0,
                    backgroundColor: themeTextColor,
                    padding: 8,
                    borderRadius: 8,
                  }}
                >
                  <Image
                    style={{ marginRight: 5, marginLeft: 5 }}
                  />
                  <Text
                    style={{
                      ...h14_AvenirNext,
                      color: themeColor,
                    }}
                  >
                    {moment().diff(item?.joiningDate, 'months') }
                    {' '}
                    {'months exp'}
                  </Text>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: themeTextColor,
                    padding: 8,
                    borderRadius: 8,
                    marginRight: 15,
                    marginLeft: 15,
                  }}
                >
                  <Image
                    style={{ marginRight: 5, marginLeft: 5 }}
                  />
                  <Text style={{ ...h14_AvenirNext, color: themeColor }}>
                    HIMS
                  </Text>
                </View>
              </View>
              {isDateAndTimeSelected ? null : (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 30,
                  }}
                >
                  <View>
                    {navigation?.getActiveRoutes().includes('booking')
                    || item?.cost_of_booking == undefined
                    || account_status !== 'linked'
                    || !item?.consultation_duration ? (
                      void 0
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          const routes = navigation?.getActiveRoutes();
                          if (!routes.includes('booking')) {
                            // setSelectedDoctor(item.name);
                            // setInitiallyLogged();
                            navigation.push({
                              view: 'doctor-profile',
                              params: {
                                item: {
                                  ...item,
                                  appointment_type: 'new',
                                  mayaAISessionId: item?.mayaAISessionId,
                                },
                                index,
                                openBooking: true,
                              },
                            });
                          }
                          console.log(item, navigation.getActiveRoutes());
                        }}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: '#fff',
                          // padding: 10,
                          minHeight: 35,
                          paddingTop: 5,
                          paddingBottom: 5,
                          borderRadius: 8,
                          minWidth: 100,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Text
                          style={{
                            color: themeColor,

                            fontWeight: 400,
                            ...h14_Medium,
                          }}
                        >
                          Book
                        </Text>
                      </TouchableOpacity>
                    )}
                  </View>
                  <TouchableOpacity
                    style={{ cursor: 'pointer' }}
                    onPress={() => setShowProfile(!showProfile)}
                  >
                    <Text
                      style={{
                        ...h14_Medium,
                        color: themeTextColor,
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                    >
                      {showProfile ? 'Hide Profile' : 'Show Profile'}
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
        </View>
        {isDateAndTimeSelected ? (
          <View
            style={{
              flex: 1,
              padding: 30,
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 1.25 }}>
                <Text
                  style={{
                    ...h14_AvenirNext,
                    lineHeight: 21,
                    letterSpacing: 0.5,
                    color: themeTextColor,
                  }}
                >
                  Video Consultation Timings
                  :
                </Text>
              </View>
              <View style={{ flex: 1, marginLeft: 10 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 5,
                    backgroundColor: themeTextColor,
                    padding: 5,
                    borderRadius: 8,
                  }}
                >
                  <Image
                    style={{
                      height: 16,
                      width: 16,
                      marginRight: 10,
                    }}
                  />
                  <Text
                    style={{
                      ...h14_AvenirNext,
                      lineHeight: 21,
                      letterSpacing: 0.5,
                      color: themeColor,
                    }}
                  >
                    {'date', { date: new Date(selectdDate) }}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 5,
                    backgroundColor: themeTextColor,
                    paddingLeft: 3,
                    borderRadius: 8,
                  }}
                >
                  <Image
                    style={{
                      height: 16,
                      width: 16,
                      marginRight: 10,
                    }}
                  />
                  <View style={{ padding: 3 }}>
                    <Text
                      style={{
                        lineHeight: 21,
                        letterSpacing: 0.3,
                        color: themeColor,
                        display: 'flex',
                        ...h12_Regular,
                      }}
                    >
                      {moment(selectedTimeSlot?.start_time)?.format('hh:mm a')}
                    </Text>
                    <Text
                      style={{
                        lineHeight: 21,
                        letterSpacing: 0.3,
                        color: themeColor,
                        display: 'flex',
                        ...h12_Regular,
                      }}
                    >
                      {item?.consultation_duration || ''}
                      {' '}
                      {'min'}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <TouchableOpacity
                  style={{
                    cursor: 'pointer',
                    alignSelf: 'flex-end',
                  }}
                  onPress={() => navigation.pop(2)}
                >
                  <Text
                    style={{
                      ...h12_Regular,
                      color: themeTextColor,
                      textAlign: 'right',
                    }}
                  >
                    Change date & time
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : (
          <View>
            {showProfile ? (
              <>
                <View
                  style={{
                    borderStyle: 'groove',
                    opacity: 0.2,
                    borderWidth: 1,
                    height: 1,
                    margin: 20,
                  }}
                />

                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginRight: 20,
                    marginBottom: 20,
                    marginLeft: 20,
                  }}
                >
                  {tabList?.map((tab, index) => {
                    const { label, visible } = tab;
                    {
                      if (visible) {
                        return (
                          <TouchableOpacity
                            style={{
                              cursor: 'pointer',
                              padding: 20,
                              borderBottom: 'solid',
                              borderColor:
                                selectedTab === index + 1
                                  ? themeTextColor
                                  : 'transparent',
                              borderBottomWidth: 3,
                            }}
                            onPress={() => setSelectedTab(index + 1)}
                          >
                            <Text
                              style={{
                                ...h14_Medium,
                                color:
                                  selectedTab === index + 1
                                    ? themeTextColor
                                    : themeTextColor,
                                opacity: selectedTab === index + 1 ? 1 : 0.54,
                              }}
                            >
                              {label}
                            </Text>
                          </TouchableOpacity>
                        );
                      }
                      return null;
                    }
                  })}
                </View>

                {selectedTab === 1 ? (
                  <View
                    style={{
                      paddingLeft: 40,
                      paddingRight: 40,
                      paddingBottom: 40,
                    }}
                  >
                    <Text
                      style={{
                        ...h14_AvenirNext,
                        color: themeTextColor,
                        opacity: 0.87,
                      }}
                    >
                      {item?.about_me_description}
                    </Text>
                  </View>
                ) : null}

                {selectedTab === 2 ? (
                  <View
                    style={{
                      paddingLeft: 40,
                      paddingRight: 40,
                      paddingBottom: 40,
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                  >
                    {item?.specialities_procedures_name?.map((speciality) => (
                      <View
                        style={{
                          backgroundColor: themeTextColor,
                          margin: 7,
                          borderRadius: 15,
                        }}
                      >
                        <Text
                          style={{
                            ...h13_Regular,
                            color: themeColor,
                            opacity: 0.87,
                            paddingTop: 6,
                            paddingRight: 15,
                            paddingBottom: 6,
                            paddingLeft: 15,
                          }}
                        >
                          {speciality}
                        </Text>
                      </View>
                    ))}
                  </View>
                ) : null}

                {selectedTab === 3 ? (
                  <View
                    style={{
                      paddingLeft: 40,
                      paddingRight: 40,
                      paddingBottom: 40,
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                  >
                    {awards?.map((award) => (
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          backgroundColor: themeTextColor,
                          padding: 3,
                          borderRadius: 5,
                          width: 'fit-content',
                        }}
                      >
                        <Image
                            // source={TrophyIcon}
                          style={{ height: 30, marginRight: 10 }}
                        />
                        <Text
                          style={{
                            ...h13_Regular,
                            color: themeColor,
                            // opacity: 0.87,
                          }}
                        >
                          {award}
                        </Text>
                      </View>
                    ))}
                  </View>
                ) : null}
              </>
            ) : null}
          </View>
        )}
      </View>
      {isDateAndTimeSelected ? (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            paddingRight: 20,
            paddingLeft: 20,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Text
            style={{
              ...h12_Regular,
              color: '#fff',
              opacity: 0.7,
            }}
          >
            consultAnotherDoc
          </Text>
          <TouchableOpacity
            style={{
              marginLeft: 5,
              cursor: 'pointer',
            }}
            onPress={() => {
              navigation.reset({ view: 'doctor-search' });
            }}
          >
            <Text
              style={{
                ...h12_Regular,
                color: '#fff',
              }}
            >
              toSearchResult
            </Text>
          </TouchableOpacity>
        </View>
      ) : null}
    </ScrollView>
  );
};
export default DoctorProfile;
