import React from 'react';

import { Text } from '../../../app-components';
import { Form } from '../../../app-components/form/Form';
import { getString } from '../../../Lib/helpers';
import { EditPatientUri } from '../../../Queries/consultations';
import vars from '../../../theme/vars';
import BarCode from '../../../Components/BarCode';

const PatientDetailsForm = (props) => {
  const { user } = props;

  return (
    <Form
      {...props}
      uri={EditPatientUri({ user })}
      type="standard"
      editable={false}
      reloadOnChangeEvent="reloadOPDRegisteredPatients"
      defaultValues={{
        relation: 'S/O',
      }}
      mandatory={{
        firstName: 1,
        lastName: 1,
        mobile: 1,
        gender: 1,
        age: 1,
        aadharNumber: 1,
      }}
      lg={{
        formGroups: [
          {
            fieldVariant: 'filled',
            direction: 'row',
            groups: [
              {
                columnsPerRow: 4,
                columns: [
                  {
                    type: 'text',
                    field: 'patientName',
                    label: 'patient.labels.patientName'.getLabel(),
                    mandatory: true,
                    editable: false,
                  },
                  {
                    type: 'text',
                    label: 'patient.labels.gender'.getLabel(),
                    field: 'gender',
                    suggestionField: 'label',
                    placeholder: 'patient.placeholders.selectGender'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'number',
                    field: 'age',
                    label: 'patient.labels.age'.getLabel(),
                    mandatory: true,
                    editable: false,
                    render: ({ data }) => (
                      <Text style={{ ...vars.headings.h8, color: '#fff' }}>
                        {data?.age}
                        {' '}
                        {data?.ageType}
                      </Text>
                    ),
                  },
                  {
                    type: 'text',
                    label: 'patient.labels.relation'.getLabel(),
                    render: ({ data }) => (
                      <Text style={{ ...vars.headings.h8, color: '#fff' }}>
                        {getString([data?.relation, data?.relationName])}
                      </Text>
                    ),
                    editable: false,
                  },
                  {
                    type: 'number',
                    field: 'aadharNumber',
                    label: 'patient.labels.aadhar'.getLabel(),
                    mandatory: true,
                    editable: false,
                  },
                  {
                    type: 'number',
                    field: 'BPLNumber',
                    label: 'patient.labels.bpl'.getLabel(),
                    editable: false,
                  },
                  {
                    type: 'number',
                    field: 'mobile',
                    label: 'patient.labels.mobile'.getLabel(),
                    placeholder: 'patient.placeholders.mobile'.getLabel(),
                    mandatory: true,
                    editable: false,
                  },
                  {
                    type: 'text',
                    field: 'address',
                    label: 'patient.labels.address'.getLabel(),
                    placeholder: 'patient.placeholders.address'.getLabel(),
                    editable: false,
                  },
                ],
              },
              {
                columnsPerRow: 1,
                width: 250,
                columns: [
                  {
                    type: 'custom',
                    render: ({ data }) => <BarCode value={data?.uhid} allowScan={false} />,
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};
export default PatientDetailsForm;
