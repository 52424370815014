export default {
  labels: {
    step1: 'Step 1: Download Template',
    step2: 'Download the template, Fill out all the details and upload below.',
    downloadTemplate: 'Download Template',
    step3: 'Step 2: Upload',
    step4: ' Upload the template filled with your data below.',
    viewImportHistory: 'View Import History',
  },
};
