import {
  backCrossIcon,
  blueArrowDown,
  leftArrowIconNew,
  rightArrowIconNew,
} from '../images';
import {
  Headline3, Headline7, Headline8, Headline9,
} from './Headlines';
import { manazeV6Colors } from './manazeV6Colors';
import { dropDownShadow } from './shadows';
import vars from './vars';

const {
  neutral_1,
  surface_white,
  primary_2,
  neutral_2,
  neutral_8,
  neutral_9,
} = manazeV6Colors;

export const dateFilterTheme = {
  dateFilterToggleTheme: {
    itemClassName: 'date-toggle-item-style-new',
    selectedItemClassName: 'date-toggle-selected-item-style-new',
    style: {
      borderRadius: 8,
      backgroundColor: '#fff',
      flexDirection: 'column',
      paddingTop: 12,
      paddingBottom: 12,
      ...dropDownShadow,
    },
    itemStyle: {
      paddingTop: 11,
      paddingBottom: 11,
      paddingLeft: 16,
      paddingRight: 16,
      cursor: 'pointer',
    },
    selectedItemStyle: {
      backgroundColor: neutral_8,
    },
    optionStyle: {
      ...Headline9,
      // textAlign: 'center',
    },
    selectedOptionStyle: {
      ...Headline8,
      color: primary_2,
    },
  },
  customHeaderPopupTheme: {
    titleContainerStyle: {
      height: 40,
      paddingLeft: 24,
      paddingRight: 24,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      overflow: 'hidden',
    },
    titleStyle: {
      ...vars.headings.h9,
      // color: neutral_1,
    },
    bodyContainerStyle: { flex: 1, backgroundColor: '#FAFAFA', padding: 24 },
    buttonContainerStyle: {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      height: 48,
      paddingLeft: 24,
      paddingRight: 24,
    },
    buttonStyle: {
      reset: {
        labelStyle: {
          ...Headline9,
          color: primary_2,
        },
        color: surface_white,
        hoverColor: surface_white,
      },
      apply: {
        borderRadius: 20,
        labelStyle: {
          ...Headline7,
          color: surface_white,
        },
        color: primary_2,
      },
    },
  },
  textStyle: {
    ...vars.headings.h13,
    color: vars.colors.grey.color3,
  },
  containerStyle: {
    flexDirection: 'row',
    width: 303,
    height: 36,
    borderWidth: 1,
    padding: 9,
    borderRadius: 4,
    marginLeft: 16,
    borderStyle: 'solid',
    borderColor: vars.colors.grey.color6,
  },
  leftContainerStyle: {
    width: 120,
    cursor: 'pointer',
    borderRightWidth: 1,
    borderRightColor: vars.colors.grey.color6,
  },
  leftDropDownStyle: {
    width: 142, height: 256, marginTop: 15, marginLeft: -9
  },
  rightContainerStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightDropDownStyle: {
    width: 350,
    height: 320,
    backgroundColor: surface_white,
    borderRadius: 8,
    paddingTop: 24,
    paddingBottom: 16,
    overflow: 'hidden',
    marginTop: 15,
    ...dropDownShadow,
  },
  arrowDownIcon: blueArrowDown,
  crossIcon: backCrossIcon,
  leftArrowIcon: leftArrowIconNew,
  rightArrowIcon: rightArrowIconNew,
};
