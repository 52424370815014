import React, { Component } from 'react';
import { AutoSuggestWithSearchInput } from '@applane/react-autosuggest';
import {
  findIndex,
  getOptionValue,
  matchValue,
  isSelected,
} from './AutoSuggestUtility';

class DropdownFilter extends Component {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = { value };
  }

  getValue = () => {
    let { usePropsValue, value } = this.props;
    if (!usePropsValue) {
      value = this.state.value;
    }
    return value;
  };

  isHighlighted = ({ item = {} }) => isSelected({ ...this.props, item, value: this.getValue() });

  setFieldValue = (value) => {
    const { field, setValue } = this.props;
    setValue && setValue({ field, value });
  };

  setFilterValue = (value) => {
    const { usePropsValue, multiSelect } = this.props;
    if (Array.isArray(value) && !value.length) {
      value = null;
    }
    if (usePropsValue) {
      this.setFieldValue(value);
    } else if (multiSelect) {
      this.setState({ value });
    } else {
      this.setFieldValue(value);
    }
  };

  onFilterApply = () => {
    this.setFieldValue(this.getValue());
  };

  onItemSelect = (props) => {
    let {
      onSelect, multiSelect, options, idField, keyField,
    } = this.props;
    let value = this.getValue();
    let { item } = props;
    if (options) {
      item = getOptionValue({ item, keyField });
    } else {
      idField = idField || '_id';
    }
    let newValue = void 0;
    if (multiSelect) {
      value = value || [];
      const valueIndex = findIndex({ value, item, idField });
      if (valueIndex >= 0) {
        newValue = [...value];
        newValue.splice(valueIndex, 1);
      } else {
        newValue = [...value, item];
      }
    } else {
      newValue = matchValue(value, item, idField) ? void 0 : item;
    }
    this.setFilterValue(newValue);
    onSelect && onSelect(props);
  };

  resetFilter = () => {
    const { field, setValue } = this.props;
    setValue && setValue({ field, value: void 0 });
  };

  fetch = async (searchProps) => {
    try {
      const { fetch, data, fetchProps } = this.props;
      const result = fetch
        ? await fetch({ ...searchProps, ...fetchProps, data })
        : void 0;
      return (result && result.data) || [];
    } catch (err) {
      return [];
    }
  };

  render() {
    let { fetch, value, ...restProps } = this.props;
    value = this.getValue();
    return (
      <AutoSuggestWithSearchInput
        {...restProps}
        fetch={this.fetch}
        onItemSelect={this.onItemSelect}
        reset={this.resetFilter}
        onApply={this.onFilterApply}
        isHighlighted={this.isHighlighted}
        onRemove={this.onItemSelect}
        value={value}
      />
    );
  }
}

export default DropdownFilter;
