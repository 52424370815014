import React, { useState } from 'react';
import {
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  isMobile,
} from '../../../app-components';
import { h14_Medium } from '../../../theme/fonts';
import { getUser } from '../../../AppServices';
import { bgs, colors } from '../../../theme/colors';

const { borderColor } = bgs;
const { themeColor } = colors;

const SidePanel = (props) => {
  const isUserDoctor = getUser().userType === 'Doctor';
  const [selectedTab, setSelectedTab] = useState('evaluate');
  // const { item, openChat = () => {} } = props;

  return (
    <View
      style={{
        // display: props.sideViewVisible,
        position: 'absolute',
        right: 0,
        width: isMobile
          ? '100%'
          : Dimensions.get('window').width - props.publisherViewWidth,
        height: '100%',
        borderLeftWidth: 1,
        borderColor,
        backgroundColor: '#fff',
        transition: 'width 2s',
      }}
    >
      {/* Header */}
      {/* <SidePanelHeader item={item} openChat={openChat} /> */}
      {/* Tabs */}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          backgroundColor: '#ffffff',
          borderBottomWidth: 1,
          borderColor,
          height: 45,
          overflow: 'hidden',
        }}
      >
        {[
          { label: 'Documentation', value: 'evaluate', visible: isUserDoctor },
          {
            label: 'Patient Profile',
            value: 'patient_profile',
            visible: !isMobile && isUserDoctor,
          },
          {
            label: 'Medical History',
            value: 'medical_history',
            visible: isUserDoctor,
          },
          { label: 'Chat', value: 'chat', visible: true },
        ].map((item) => (item.visible ? (
          <TouchableOpacity
            style={{ cursor: 'pointer' }}
            onPress={() => {
              setSelectedTab(item?.value);
            }}
          >
            <Text
              style={{
                ...h14_Medium,
                color: selectedTab === item?.value ? themeColor : '#383838',
              }}
            >
              {item?.label}
            </Text>
          </TouchableOpacity>
        ) : (
          void 0
        )))}
      </View>
      {/* {selectedTab === 'patient_profile' ? (
        <PatientProfile item={item} />
      ) : (
        void 0
      )} */}
      {/* {false || selectedTab === 'patient_profile' ? (
        <ScrollView
          style={{
            flex: 1,
            // display: selectedTab === 'patient_profile' ? 'flex' : 'none',
          }}
        >
          <PatientDetailsUpdate
            item={item}
            selectedTab={selectedTab}
            {...props}
          />
        </ScrollView>
      ) : (
        void 0
      )} */}

      {/* {selectedTab === 'chat' ? (
        <ChatView
          item={{ ...item, appointment_id: { _id: item?._id } }}
          navigation={props?.navigation}
        />
      ) : (
        void 0
      )}
      {true || selectedTab === 'medical_history' ? (
        <MedicalHistory item={item} {...props} selectedTab={selectedTab} />
      ) : (
        void 0
      )} */}
    </View>
  );
};
export default SidePanel;
