import { manazeV5Colors } from './manazeV5Colors';
import { manazeV6Colors } from './manazeV6Colors';
import { colors as mayaMdColors } from './mayaMdColors';
import vars from './vars';

import {
  Headline3,
  Headline4,
  Headline10,
  Headline11,
  Headline9,
} from './Headlines';
import { toolBarTheme } from './toolBarTheme';

const { headings, colors } = vars;
const { themeContainer, themeColor } = mayaMdColors;
const { neutral_400_low_background } = manazeV5Colors;
const {
  neutral_1,
  neutral_2,
  neutral_3,
  neutral_8,
  surface_white,
  neutral_10,
  primary_6,
} = manazeV6Colors;

export const nonEditableformGroupHeaderStyle = {
  style: {
    marginLeft: 6,
    marginRight: 6,
    paddingTop: 4,
    paddingBottom: 4,
  },
  iconStyle: {
    marginRight: 6,
  },
  labelStyle: {
    ...headings.h9,
    color: vars.colors.grey.color3,
  },
};
export const formGroupHeaderStyle = {
  style: {
    marginLeft: 12,
    marginRight: 12,
    paddingTop: 4,
    paddingBottom: 4,
  },
  iconStyle: {
    marginRight: 6,
  },
  labelStyle: {
    ...headings.h9,
    color: vars.colors.grey.color3,
  },
};

export const formGroupStyle = {
  style: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 8,
    paddingRight: 8,
  },
  groupContainerStyle: {},
  separatorStyle: {
    marginLeft: 24,
    marginRight: 32,
    borderBottomWidth: 0.3,
    borderColor: neutral_400_low_background,
    opacity: 0.2,
  },
  columnContainerStyle: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
  },
};

export const formGroupMobileStyle = {
  style: {},
  separatorStyle: {
    marginLeft: 16,
    marginRight: 16,
    borderBottomWidth: 0.3,
    borderColor: neutral_400_low_background,
    opacity: 0.2,
  },
  columnContainerStyle: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 4,
    paddingBottom: 4,
  },
};

export const compactFormGroupStyle = {
  style: {
    padding: 6,
  },
  columnContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 6,
    paddingBottom: 6,
  },
};

export const nonEditableFormGroupStyle = {
  style: {
    padding: 6,
  },
  groupContainerStyle: {},
  separatorStyle: {
    marginLeft: 12,
    marginRight: 12,
    // borderBottomWidth: 0.3,
    // borderColor: neutral_400_low_background,
    // opacity: 0.2,
  },
  columnContainerStyle: {
    marginLeft: 6,
    marginRight: 6,
    marginTop: 16,
    marginBottom: 16,
  },
};

export const sectionFormGroupStyle = {
  style: {
    paddingTop: 28,
    paddingBottom: 20,
    paddingRight: 8,
  },
  groupContainerStyle: {},
  separatorStyle: {
    marginLeft: 24,
    marginRight: 24,
    borderWidth: 0.3,
    borderColor: neutral_400_low_background,
    opacity: 0.2,
  },
  columnContainerStyle: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 12,
    paddingBottom: 12,
  },
};

export const nestedFormGroupStyle = {
  columnContainerStyle: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
};

export const expandStyle = {
  style: {
    cursor: 'pointer',
    paddingRight: 8,
    paddingLeft: 8,
  },
};
export const expandIconStyle = {
  style: { height: 20, width: 20 },
};

export const formHeaderTheme = {
  ...toolBarTheme,
  bottomSeparator: true,
  separatorStyle: {
    height: 0,
    backgroundColor: neutral_8,
  },
  containerStyle: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 26,
    paddingRight: 26,
    backgroundColor: surface_white,
  },
  titleTextStyle: {
    paddingLeft: 12,
    paddingRight: 12,
    ...headings.h6,
    color: colors.primary.color1,
  },
  subTitleTextStyle: {
    paddingTop: 3,
    paddingLeft: 12,
    paddingRight: 12,
    ...headings.h9,
    color: colors.grey.color1,
  },
  actionStyle: {
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 12,
      paddingRight: 12,
    },
    iconStyle: { height: 24, width: 24, objectFit: 'contain' },
  },
};

export const formHeaderMobileTheme = {
  containerStyle: {
    padding: 12,
    backgroundColor: primary_6,
  },
  itemsStyle: {
    flexDirection: 'row',
  },
  titleContainerStyle: {
    padding: 4,
  },
  titleTextStyle: {
    ...Headline4,
    color: neutral_1,
  },
  subTitleTextStyle: {
    paddingTop: 3,
    ...Headline10,
    color: neutral_2,
  },
  actionContainerStyle: { flexDirection: 'row' },
  actionStyle: {
    containerStyle: {
      flexDirection: 'row',
      padding: 4,
    },
    iconStyle: { height: 20, width: 20, objectFit: 'contain' },
    textStyle: { ...Headline11, color: neutral_2 },
  },
};

export const compactFormHeaderTheme = {
  ...formHeaderTheme,
};

export const formFooterTheme = {
  ...toolBarTheme,
  topSeparator: true,
  separatorStyle: {
    height: 1,
    backgroundColor: neutral_8,
  },
  containerStyle: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: surface_white,
  },
  actionStyle: {
    containerStyle: {
      padding: 8,
    },
    iconStyle: {
      height: 20,
      width: 20,
    },
    textStyle: { ...Headline11, color: neutral_2 },
  },
};

export const formFooterMobileTheme = {
  containerStyle: {
    padding: 8,
    backgroundColor: primary_6,
  },
  titleTextStyle: {
    padding: 8,
    color: neutral_1,
    ...Headline9,
  },
  actionStyle: {
    containerStyle: {
      padding: 8,
    },
    iconStyle: {
      marginRight: 12,
      height: 20,
      width: 20,
    },
    textStyle: { ...Headline11, color: neutral_2 },
  },
};

export const compactFormFooterTheme = {
  ...toolBarTheme,
  containerStyle: {
    paddingTop: 6,
    paddingBottom: 12,
    paddingLeft: 6,
    paddingRight: 6,
    backgroundColor: surface_white,
  },
};

export const formTheme = {
  containerStyle: {
    flex: 1,
    backgroundColor: themeColor,
  },
  bodyContainerStyle: {
    marginLeft: 16,
    marginTop: 24,
    marginRight: 16,
    marginBottom: 24,
    borderRadius: 4,
    backgroundColor: themeColor,
  },
  horizontalSeparatorStyle: {
    width: 1,
    backgroundColor: neutral_8,
  },
  verticalSeparatorStyle: {
    width: 1,
    backgroundColor: neutral_8,
  },
};

export const formMobileTheme = {
  containerStyle: {
    backgroundColor: primary_6,
  },
};

export const compactFormTheme = {
  containerStyle: {
    // Comment it to overide the department form margin issues
    // marginTop: 29,
    // marginBottom: 29,
    // marginLeft: 37,
    // marginRight: 37,
    borderRadius: 8,
    maxHeight: 'content-fit',
    boxShadow: ' 0 2px 14px 0 rgba(56, 56, 56, 0.1)',
    backgroundColor: '#ffffff',
  },
};
