import { standardDropDownTheme } from './dropDownTheme';
import { shadow } from './shadows';

const moreActionsTheme = {
  textStyle: {
    marginLeft: 12,
  },
  style: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  autoHide: true,
  style: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropdownStyle: {
    margin: 8,
    height: 'auto',
    width: 200,
    minWidth: 200,
    maxHeight: 300,
    backgroundColor: '#fff',
    borderRadius: 4,
    ...shadow,
  },
  iconStyle: {
    height: 24,
    width: 24,
  },
  ...standardDropDownTheme,
};

const moreActionsMobileTheme = {
  textStyle: {
    marginLeft: 12,
  },
  autoHide: true,
  screenCenterDropdownStyle: {
    flex: 1,
    overflow: 'hidden',
    borderRadius: 4,
    maxHeight: '50%',
    maxWidth: '90%',
    backgroundColor: '#fff',
  },
  screenBottomDropdownStyle: {
    height: '50%',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    backgroundColor: '#fff',
  },
  screenTopDropdownStyle: {
    height: '50%',
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    backgroundColor: '#fff',
  },
  screenRightDropdownStyle: {
    width: '50%',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    backgroundColor: '#fff',
  },
  screenLeftDropdownStyle: {
    width: '50%',
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    backgroundColor: '#fff',
  },
  fullScreenStyle: {},
  dropdownStyle: {
    height: 'auto',
    width: 200,
    maxHeight: 300,
    backgroundColor: '#fff',
    borderRadius: 4,
    margin: 8,
    ...shadow,
  },
  ...standardDropDownTheme,
};

export { moreActionsTheme, moreActionsMobileTheme };
