/* eslint-disable import/no-cycle */
import React from 'react';
import {
  Image,
  View,
  Text,
  TextInput,
  NumberInput,
  ScrollView,
  TouchableOpacity,
  Platform,
  Linking,
  Dimensions,
  FlatList,
} from '@applane/react-core-components';
import { Avatar } from '@applane/react-avatar';
import { ReactForm } from '@applane/react-form';
import { TabPanel } from '@applane/react-tab-panel-v1';
import { DataFetch, DataFetchConstants } from '@applane/react-datafetch';
import { Stack } from '@applane/react-stack-navigator-v1';
import { AsyncStorage as Storage } from '@applane/react-async-storage';
import { CheckBox as CheckBoxComponent } from '@applane/react-check-box';
import {
  ReactModalView,
  ReactModalViewComponentContext,
  ReactModalViewListenerContext,
  ReactModalViewContextProvider,
} from '@applane/react-modal-view';
import {
  AppKeyboardListener,
  AppKeyboardListenerContext,
} from '@applane/react-app-keyboard-listener';
import { ReactEventDispatcher } from '@applane/react-event-dispatcher';
import {
  WithModal,
  WithModal as WithModalButton,
} from '@applane/react-with-modal';
import { Snackbar } from '@applane/react-snack-bar';
import { DatePicker } from '@applane/react-date-input';
import { GpsStore } from '@applane/react-gps-store';
import Toast from '@applane/react-toast';
import OTPInput from './input-components/otp/OTPInput';
import CheckBox from './input-components/checkBox/CheckBox';
import { progressIndicatorStyle } from '../theme/progressIndicatorTheme';
import ToolBar from './toolBar/ToolBar';
import { ToggleSwitchInput } from './input-components/Editors';
import { close, cancelButton } from './action/CloseAction';
import {
  save,
  clearSelection,
  selectAll,
  showSelectedCount,
} from './action/SaveAction';

import Table from './table/Table';
import {
  GradientButton,
  StandardButton,
  OutlineButton,
  LargeButton,
  MediumStandardButton
} from './buttons/Buttons';
import ExpandAction from './action/ExpandAction';
import detectMob from '../Lib/detectMobile';
import Fts from './filter/Fts';
import TabNavigator from './tab/TabNavigator';
import FormHoc from './form/Form';
import { ProgressIndicator, ActivityIndicator } from './loader/Loader';
import Breadcrumb from './Breadcumbs/Breadcrumb';
import { FormField } from './form/field';
import DateFilter from './filter/date/DateFilter';
import FilterGroup from './filter/filterGroup/FilterGroup';
import {
  MamcToggleFilter,
  PharmacyDepartmentInventoryToggleFilter,
  PharmacyCentralInventoryToggleFilter,
  // BloodInventoryToggleFilter,
  PharmacyInventoryRequestToggleFilter,
  PharmacyInventoryIndentRequestToggleFilter,
  AssetInventoryRequestToggleFilter,
  AssetCentralInventoryToggleFilter,
  AssetDepartmentInventoryToggleFilter,
} from './filter/MamcToggleFilters';

import PeriodFilterAction from './action/PeriodFilterAction';

const isMobile = detectMob();

export {
  FormHoc as Form,
  GpsStore,
  OTPInput,
  Snackbar,
  Dimensions,
  CheckBox,
  View,
  Text,
  Image,
  TextInput,
  NumberInput,
  ScrollView,
  TabPanel,
  DataFetch,
  Stack,
  TouchableOpacity,
  Platform,
  Linking,
  Storage,
  ToolBar,
  CheckBoxComponent,
  ReactModalView,
  ReactModalViewComponentContext,
  ReactModalViewListenerContext,
  ReactModalViewContextProvider,
  AppKeyboardListener,
  AppKeyboardListenerContext,
  ReactEventDispatcher,
  ActivityIndicator,
  ProgressIndicator,
  WithModalButton,
  FlatList,
  isMobile,
  // detectMob,
  DatePicker,
  ReactForm,
  WithModal,
  Avatar,
  GradientButton,
  Table,
  cancelButton,
  save,
  DataFetchConstants,
  Toast,
  close,
  ExpandAction,
  ToggleSwitchInput,
  Fts,
  TabNavigator,
  FormHoc,
  StandardButton,
  MediumStandardButton,
  OutlineButton,
  Breadcrumb,
  FormField,
  LargeButton,
  DateFilter,
  FilterGroup,
  clearSelection,
  selectAll,
  showSelectedCount,
  MamcToggleFilter as ToggleFilter,
  AssetCentralInventoryToggleFilter,
  AssetDepartmentInventoryToggleFilter,
  // BloodInventoryToggleFilter,
  PharmacyInventoryRequestToggleFilter,
  AssetInventoryRequestToggleFilter,
  PharmacyDepartmentInventoryToggleFilter,
  // BloodInventoryToggleFilter,
  PharmacyInventoryIndentRequestToggleFilter,
  PharmacyCentralInventoryToggleFilter,
  // ToggleFilter,
  Table as List,
  PeriodFilterAction,
};
