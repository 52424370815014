export default {
  labels: {
    employeeName: 'Employee Name',
    employeeType: 'Employee Type',
    code: 'Employee Code',
    rooms: 'Rooms',
    description: 'Description',
    status: 'Status',
    employees: 'Employees',
  },
  placeholders: {
    rooms: 'Select Rooms',
  },
  title: {
    addEmployeeType: 'Add Employee Type',
    subTitle: 'Fill out the form to add new employee type',
    employeeType: 'Employee Type',
    description: 'List of Employee in the Employee Type',
  },
  messages: {
    submitMessage: 'Employee Type has been added successfully.',
    updateMessage: 'Employee Type has been edited successfully.',
  },
};
