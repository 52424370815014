import { organizationLabels } from "./logoOrganizationLabels";

export default {
  labels: {
    hospitalName: organizationLabels.organizationName,
    hospitalAddress: 'INDIA',
    outPatientDepartment: 'Out Patient Department',
    patientDetails: 'Patient Details',
    patientName: 'Patient Name',
    gender: 'Gender',
    age: 'Age',
    mobileNo: 'Mobile No',
    speciality: 'Speciality',
    OPD: 'OPD',
    room: 'Room',
    charge: 'Charge',
    remarks: 'Remarks',
    diagnosis: 'Diagnosis',
    investigations: 'Investigations',
    treatments: 'Treatments',
    date: 'Date',
    time: 'Time',
    doctorName: 'Doctor Name',
    labReports: 'Lab Reports',
    prescription: 'Prescription',
    uploadedPrescription: 'Uploaded Prescription',
    department: 'Department',
    opdNumber: 'OPD No',
    consultationDate: 'Consultation Date',
    uhid: 'UHID',
    actions: 'Actions',
    consultationStatus: 'Consultation Status',
    diagnose: 'Diagnose',
    roomNo: 'Room No',
    assistantOnDuty: 'Assistant On Duty',
    status: 'Status',
    labType: 'Lab Type',
    testName: 'Test Name',
    reportGenerationDate: 'Report Generation Date',
    price: 'Price',
    attachment: 'Attachment',
    itemName: 'Item Name',
    brand: 'Brand',
    category: 'Category',
    opdNo: 'OPD No.',
  },
  headers: {
    consultationDate: 'Consultation Date',
    uhid: 'UHID',
    patientName: 'Patient Name',
    doctorName: 'Doctor Name',
    speciality: 'Speciality',
    actions: 'Actions',
    consultationStatus: 'Consultation Status',
    consultationReceipt: 'Consultation Receipt',
  },
  title: {
    consultedPatientHistory: 'Consulted Patient History',
  },
  buttons: {
    exportToExcel: 'Export To Excel',
    viewConsultationLog: 'View Consultation Log',
    viewVaccinationLog: 'View Vaccination Log',
  },
  messages: {
    remarksUpdateSuccessfully: 'Remarks has been updated successfully',
    diagnosisUpdateSuccessfully: 'Diagnosis has been added successfully',
    prescriptionUpdateSuccessfully: 'Prescription has been added successfully',
  },

};
