import React from 'react';
import { Toast } from '../index';
import { buttonMappings, StandardButton } from '../buttons/Buttons';
import { invoke as InvokeUri } from '../../AppServices';
import { getSelectionInfo, removeSelection } from './utility';

export const invoke = ({
  id,
  label,
  disabled,
  preMessage,
  postMessage,
  showLoader,
  reloadEvent,
  closeView,
  buttonType,
  outline,
  invoke,
  type = 'invoke',
  ...restProps
} = {}) => {
  label = label || 'Done';
  if (!id && typeof label === 'string') {
    id = label;
  }
  if (!buttonType) {
    buttonType = outline ? 'outline' : 'standard';
  }
  return {
    id: label,
    type,
    invoke,
    preMessage,
    postMessage,
    showLoader,
    reloadEvent,
    closeView,
    disabled,
    render: ({ state = {}, disabled } = {}) => {
      let buttonLabel = label;
      if (typeof buttonLabel === 'function') {
        buttonLabel = buttonLabel({ data: state.data });
      }
      const ButtonComponent = buttonMappings[buttonType] || StandardButton;
      return (
        <ButtonComponent
          {...restProps}
          label={buttonLabel}
          disabled={disabled}
        />
      );
    },
    ...restProps,
  };
};

export const formUpdateInvoke = (props) => invoke({
  ...props,
  type: 'formUpdateInvoke',
});

export const invokeAction = async (props = {}) => {
  let {
    action: {
      invoke,
      preMessage,
      postMessage,
      showLoader,
      reloadEvent,
      closeView,
      service,
    } = {},
    screenState,
    setScreenState,
    uid,
    eventDispatcher,
    navigation,
    data,
    item,
    fetchUriEvent,
  } = props;
  if (!invoke && !service) {
    return;
  }
  // if (!eventDispatcher) {
  //   throw new Error('EventDispatcher is mandatory in Invoke Action');
  // }
  if (showLoader && showLoader === true) {
    showLoader = 'appLoader';
  }
  // preMessage && Toast.show(preMessage);
  preMessage && Toast.show({
    message: 'Success',
    description: preMessage,
    type: 'success',
    position: 'top',
    direction: 'right',
    duration: 3000,
  });
  try {
    showLoader && eventDispatcher && eventDispatcher.notify(showLoader, true);
    if (uid && typeof uid === 'function') {
      uid = uid();
    }
    if (!data && fetchUriEvent) {
      // required when action is outside list/form state
      let uriEventInfo = eventDispatcher.notify(fetchUriEvent) || {};
      data = data || uriEventInfo.state?.data;
    }
    let { selectedIds, allPageSelected, selectedData } = getSelectionInfo({
      screenState,
      data,
      item,
    });
    let result = void 0;
    let invokeProps = {
      ...props,
      selectedIds,
      allPageSelected,
      uid,
      selectedData,
    };
    if (service) {
      if (typeof service === 'function') {
        service = service(invokeProps);
      }
      service = service || {};
      if (service.paramValue) {
        service.paramValue = {
          ...service.paramValue,
          _selectedIds_: selectedIds,
          _allPageSelected: allPageSelected,
        };
      }
      result = await InvokeUri(service);
    } else {
      result = await invoke(invokeProps);
    }
    postMessage && Toast.show({
      message: 'Success',
      description: postMessage,
      type: 'success',
      position: 'top',
      direction: 'right',
      duration: 3000,
    });
    // Toast.show(postMessage);
    if (reloadEvent && eventDispatcher) {
      if (Array.isArray(reloadEvent)) {
        reloadEvent.forEach((_event) => eventDispatcher.notify(_event, result));
      } else {
        eventDispatcher.notify(reloadEvent, result);
      }
    }
    removeSelection({ screenState, setScreenState });
    closeView && navigation.pop(closeView);
    return result;
  } catch (err) {
    Toast.show({
      message: 'Error',
      description: err.message || err || 'Error in invoke',
      type: 'error',
      position: 'top',
      direction: 'right',
      duration: 3000,
    });
    // Toast.error(err.message || err || 'Error in invoke');
  } finally {
    showLoader && eventDispatcher && eventDispatcher.notify(showLoader, false);
  }
};

export const formUpdateInvokeAction = async (props = {}) => {
  let {
    uid,
    action: { formUpdateEvent = 'formUpdates' } = {},
    eventDispatcher,
  } = props;
  if (!eventDispatcher) {
    throw new Error('EventDispatcher is mandatory in Invoke Action');
  }
  if (uid && typeof uid === 'function') {
    uid = uid();
  }
  const formUpdates = eventDispatcher.notify(formUpdateEvent);
  invokeAction({ ...props, formUpdates, uid });
};
