import { putDottedValue } from '@applane/react-form/src/Utility';

const initialPageLimit = 40;
const fetchMoreLimit = 20;

const mergeFilter = (filter, addFilter) => {
  if (!addFilter || !Object.keys(addFilter).length) {
    return filter;
  } else if (!filter || !Object.keys(filter).length) {
    return addFilter;
  } else {
    const newFilter = { ...filter };
    const andFilter = newFilter.$and || [];
    newFilter.$and = [...andFilter, addFilter];
    return newFilter;
  }
};

const getResolvedFilterValue = (filter) => {
  const {
    value, filterType, asParam, filterValueField,
  } = filter;
  if (
    value === undefined
    || value === null
    || (Array.isArray(value) && !value.length)
  ) {
    return;
  }
  let resolvedFilterValue = void 0;
  if (filterType === 'autoSuggest') {
    if (Array.isArray(value)) {
      resolvedFilterValue = { _id: { $in: value.map((row) => row._id) } };
    } else if (value._id) {
      resolvedFilterValue = { _id: value._id };
    }
  } else if (filterType === 'primary') {
    if (value[filterValueField]) {
      resolvedFilterValue = value[filterValueField];
    }
  } else if (filterType === 'googlePlace') {
    const address_levels = value && value.address_levels;
    if (address_levels) {
      resolvedFilterValue = { address_levels };
    }
  } else if (filterType === 'boolean') {
    const getBooleanValue = (_value) => (typeof _value === 'string' ? JSON.parse(_value) : _value);
    if (Array.isArray(value)) {
      resolvedFilterValue = {
        $in: value.map((_value) => getBooleanValue(_value)),
      };
    } else {
      resolvedFilterValue = getBooleanValue(value);
    }
  } else if ((filterType === 'date' || filterType === 'number') && !asParam) {
    if (value.from || value.to) {
      resolvedFilterValue = {};
      if (value.from) {
        resolvedFilterValue.$gte = value.from;
      }
      if (value.to) {
        resolvedFilterValue.$lte = value.to;
      }
    }
  } else {
    if (Array.isArray(value)) {
      resolvedFilterValue = { $in: value };
    } else {
      resolvedFilterValue = value;
    }
  }
  return resolvedFilterValue;
};

export const getResolvedFilters = (appliedFilters, isGlobal) => {
  const resolvedAppliedFilters = {};
  const paramValue = {};
  const globalParamValue = {};
  for (const field in appliedFilters) {
    const fieldValue = appliedFilters[field];
    if (!fieldValue || !fieldValue.filter) {
      continue;
    }
    const filterInfo = fieldValue.filter;
    const { asParam, asGlobalParam = isGlobal, ...restFilterInfo } = filterInfo;
    const resolvedFilterValue = getResolvedFilterValue({
      ...restFilterInfo,
      asParam: asGlobalParam || asParam,
    });
    if (resolvedFilterValue !== undefined) {
      if (asParam || asGlobalParam) {
        if (asParam) {
          paramValue[field] = resolvedFilterValue;
        }
        if (asGlobalParam) {
          globalParamValue[field] = resolvedFilterValue;
        }
      } else {
        putDottedValue(resolvedAppliedFilters, field, resolvedFilterValue);
      }
    }
  }
  return {
    filters: resolvedAppliedFilters,
    paramValue,
    globalParamValue,
  };
};

export const getResolvedParamValue = (dataParams, isGlobal) => {
  if (!dataParams || !dataParams.filters) {
    return;
  }
  const { paramValue, globalParamValue } = getResolvedFilters(
    dataParams.filters,
    isGlobal,
  );
  return isGlobal ? globalParamValue : paramValue;
};

export const beforeFetch = ({
  state, uri, fetchProps, props,
}) => {
  let { query, globalParamValue, viewFilters } = uri || {};
  const { dataParams, globalDataParams } = props;

  let viewParamValue = void 0;
  let sortParams = void 0;
  let ftsFilter = void 0;
  let queryParams = void 0;
  let group = void 0;
  if (globalDataParams && globalDataParams.filters) {
    const {
      paramValue: dataParamsParamvalue,
      globalParamValue: dataParamsGlobalParamValue,
    } = getResolvedFilters(globalDataParams.filters, true);
    if (
      dataParamsGlobalParamValue
      && Object.keys(dataParamsGlobalParamValue).length
    ) {
      globalParamValue = { ...dataParamsGlobalParamValue, ...globalParamValue };
    }
    if (dataParamsParamvalue && Object.keys(dataParamsParamvalue).length) {
      viewParamValue = { ...viewParamValue, ...dataParamsParamvalue };
    }
  }

  if (dataParams) {
    let {
      groupBy,
      filters: appliedFilters,
      ftsFilter: appliedFtsFilter,
      sort: appliedSort,
      dataFilter,
    } = dataParams;
    if (appliedFilters) {
      const {
        filters: resolvedAppliedFilters,
        paramValue: resolvedParamValue,
      } = getResolvedFilters(appliedFilters);
      if (
        resolvedAppliedFilters
        && Object.keys(resolvedAppliedFilters).length
      ) {
        viewFilters = mergeFilter(viewFilters, resolvedAppliedFilters);
      }
      if (resolvedParamValue && Object.keys(resolvedParamValue).length) {
        viewParamValue = { ...viewParamValue, ...resolvedParamValue };
      }
    }
    if (appliedSort) {
      sortParams = { sort: { ...appliedSort } };
    }
    if (groupBy) {
      group = groupBy.group;
    }
    if (appliedFtsFilter) {
      if (appliedFtsFilter.filter) {
        ftsFilter = appliedFtsFilter.filter;
      } else if (appliedFtsFilter.regExpFilter) {
        viewFilters = { ...viewFilters, ...appliedFtsFilter.regExpFilter };
      }
    }
    if (dataFilter) {
      if (dataFilter.filter) {
        queryParams = dataFilter.filter;
      } else if (dataFilter.regExpFilter) {
        viewFilters = { ...viewFilters, ...dataFilter.regExpFilter };
      }
    }
  }

  let {
    limit, skip, noLimit, addOnFilter, paramValue,
  } = query || {};
  const { fetchMoreProps: fetchMoreState = {} } = state;

  let newState = {};

  if (fetchProps && fetchProps.fetchMore) {
    let { skip: fetchMoreSkip, limit: fetchMoreLimit } = fetchMoreState;
    limit = fetchMoreLimit || fetchMoreLimit;
    skip = fetchMoreSkip;
    newState.fetchingMore = true;
  } else {
    if (limit === void 0) {
      if (!noLimit) {
        limit = initialPageLimit;
      }
    }
  }
  skip = skip || 0;
  limit = limit || initialPageLimit;
  if (viewFilters) {
    addOnFilter = { ...addOnFilter, ...viewFilters };
  }
  if (viewParamValue) {
    paramValue = { ...paramValue, ...viewParamValue };
  }

  query = {
    ...query, addOnFilter, paramValue, limit, skip,
  };

  if (sortParams) {
    query = { ...query, ...sortParams };
  }
  if (ftsFilter) {
    query = { ...query, ftsFilter };
  }
  if (group) {
    query = { ...query, group };
  }
  if (queryParams) {
    query = { ...query, paramValue: queryParams };
  }
  uri = { ...uri, query, globalParamValue };
  newState.loading = true;
  newState.source = fetchProps ? fetchProps.source : void 0;
  if (skip === 0) {
    newState.fetchMoreProps = {};
  }
  return { uri, state: newState };
};

export const afterFetch = ({
  state,
  uri,
  fetchProps,
  result,
  subscribeInfo,
  props,
}) => {
  let { onDataReload } = props || {};
  const { query } = uri;
  let { fetchMoreProps: fetchMoreState = {} } = state;
  const { data: newData = [], aggregates: newAggregates, _count } = result;
  let { fetchMore, source } = fetchProps || {};
  let { data = [] } = state;
  const newState = {};
  const { limit, skipFetchMore, noLimit } = query;
  const newSize = newData.length;

  let hasNext = true;
  if (noLimit || skipFetchMore) {
    hasNext = false;
  } else if (newSize < limit) {
    hasNext = false;
  }

  fetchMoreState = { ...fetchMoreState };
  fetchMoreState.hasNext = hasNext;

  if (!skipFetchMore) {
    fetchMoreState.skip = (fetchMoreState.skip || 0) + newSize;
    fetchMoreState.limit = fetchMoreLimit;
  }

  if (fetchMore) {
    data = [...data, ...newData];
    // if loading more then we do not need to override aggregates but if coming in result then should override
    if (newAggregates) {
      // aggregates should be override in case of result reload
      newState.aggregates = newAggregates;
      newState._count = _count;
    }
  } else {
    data = newData;
    newState.aggregates = newAggregates;
    newState._count = _count;
  }
  newState.aggregates = newAggregates;
  newState.data = data;
  newState.fetchMoreProps = fetchMoreState;
  newState.loading = false;
  newState.fetchingMore = false;
  newState.loaded = true;
  if (subscribeInfo) {
    newState.subscribeInfo = subscribeInfo;
  }
  // onDataReload called when navigation changed and dataParams and globalDataParams change
  if (
    !fetchMore &&
    (source === 'globalDataParamsChange' ||
      source === 'dataParamsChange' ||
      source === 'navigationChange')
  ) {
    onDataReload && onDataReload({ state: newState, fetchProps, props });
  }
  return { state: newState };
};

export const beforeFetchForm = ({ uri, fetchProps = {} }) => {
  let { query } = uri;
  query = { ...query, limit: query && query.limit ? query.limit : 1, skip: 0 };

  uri = { ...uri, query };
  return { uri, state: { loading: true, source: fetchProps.source } };
};

export const afterFetchForm = ({ result }) => {
  let { data: newData } = result;
  const newState = {};
  if (Array.isArray(newData)) {
    if (newData.length > 0) {
      newData = newData[0];
    } else {
      newData = {};
    }
  } else if (!newData) {
    newData = {};
  }
  newState.data = newData;
  newState.loading = false;
  newState.loaded = true;

  return { state: newState };
};
