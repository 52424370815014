import React from 'react';
import {View} from '@applane/react-core-components';
import CardContent from './CardContent';
import CardHeader from './CardHeader';
import CardFooter from './CardFooter';
import CardContentLayout from './CardContentLayout';
import CardTitleBar from './CardTitleBar';
import CardActions from './CardActions';
export default class Card extends React.PureComponent {
  render() {
    let {
      style,
      swipedStyle,
      hoverStyle,
      selectedHoverStyle,
      selectedStyle,
      checkedStyle,
      bodyContainerStyle,
      isSwiped,
      header,
      footer,
      left,
      right,
      rightActions,
      title,
      primaryTitle,
      primaryTitleField,
      secondaryTitle,
      secondaryTitleField,
      WrapperComponent,
      ...restProps
    } = this.props;
    let {isHover, selected, isChecked} = restProps;
    if (isChecked) {
      style = {...style, ...checkedStyle};
    } else if (selected) {
      style = {
        ...style,
        ...((isHover && selectedHoverStyle) || selectedStyle),
      };
    } else if (isSwiped) {
      style = {
        ...style,
        ...swipedStyle,
      };
    } else if (isHover) {
      style = {
        ...style,
        ...hoverStyle,
      };
    }
    if (
      !title &&
      (primaryTitle ||
        primaryTitleField ||
        secondaryTitle ||
        secondaryTitleField)
    ) {
      title = {
        primary: primaryTitle,
        primaryField: primaryTitleField,
        secondary: secondaryTitle,
        secondaryField: secondaryTitleField,
      };
    }
    let showTitleInFullWidth = title && title.fullWidth ? true : false;
    let component = (
      <View style={style}>
        {header && <CardHeader header={header} {...restProps} />}
        {showTitleInFullWidth ? (
          <CardTitleBar title={title} {...restProps} />
        ) : (
          void 0
        )}
        <View style={{flexDirection: 'row', ...bodyContainerStyle}}>
          {left && (
            <CardContentLayout
              {...restProps}
              direction="row"
              content={left}
              source={'left'}
            />
          )}
          <View style={{flex: 1, overflow: 'hidden', justifyContent: 'center'}}>
            <CardContent
              {...restProps}
              title={showTitleInFullWidth ? void 0 : title}
            />
          </View>
          {right && (
            <CardContentLayout
              {...restProps}
              direction="row"
              content={right}
              source={'right'}
            />
          )}
          {rightActions && (
            <CardActions
              actions={rightActions}
              actionSource={'rightActions'}
              {...restProps}
            />
          )}
        </View>
        {footer ? <CardFooter footer={footer} {...restProps} /> : void 0}
      </View>
    );
    if (WrapperComponent) {
      component = <WrapperComponent {...this.props}>{component}</WrapperComponent>;
    }
    return component;
  }
}
Card.defaultProps = {
  rightContainerStyle: {
    alignItems: 'center',
    margin: 6,
  },
  rightItemContainerStyle: {
    marginLeft: 6,
    marginRight: 6,
  },
  leftContainerStyle: {
    alignItems: 'center',
    margin: 6,
  },
  leftItemContainerStyle: {
    marginLeft: 6,
    marginRight: 6,
  },
};
