import {findById} from './Utility';
import {resolveExp} from './Utility';

const isMandatoryRequired = (data, field) => {
  let fieldValue = resolveExp(data, field);
  if (
    fieldValue === void 0 ||
    fieldValue === null ||
    (Array.isArray(fieldValue) && !fieldValue.length) ||
    (typeof fieldValue === 'string' && fieldValue.trim().length == 0)
  ) {
    return true;
  }
};

const populateMandatoryErrors = ({
  data,
  field,
  mandatory,
  mandatoryErrors,
  defaultMandatoryMessage,
}) => {
  if (mandatory && mandatory.hasOwnProperty(field)) {
    let mandatoryFieldInfo = mandatory[field];
    if (typeof mandatoryFieldInfo === 'function') {
      mandatoryFieldInfo = mandatoryFieldInfo({data, field});
    }
    if (mandatoryFieldInfo) {
      let mandatoryRequired = isMandatoryRequired(data, field);
      if (mandatoryRequired) {
        let message = mandatoryFieldInfo;
        if (
          (mandatoryFieldInfo === 1 || mandatoryFieldInfo === true) &&
          defaultMandatoryMessage
        ) {
          message = defaultMandatoryMessage;
        }
        mandatoryErrors[field] = message;
      } else {
        delete mandatoryErrors[field];
      }
    }
  }
};

const populateValidationErrors = async ({
  data,
  updates,
  field,
  validations,
  validationErrors,
}) => {
  let validation = validations && validations[field];
  if (validation) {
    let validationKeyError = await validation({data, updates});
    if (validationKeyError) {
      validationErrors[field] = validationKeyError;
    } else {
      delete validationErrors[field];
    }
  }
};

const resolveAllMandatoryErrors = props => {
  let {mandatory} = props;
  for (let _field in mandatory) {
    let mandatoryInfo = mandatory[_field];
    if (typeof mandatoryInfo === 'object') {
      populateNestedMandatoryErrors({
        ...props,
        nestedField: _field,
      });
    } else {
      populateMandatoryErrors({
        ...props,
        field: _field,
      });
    }
  }
};

const resolveAllValidationErrors = async props => {
  let {validations, updates, validateOnUpdate} = props;
  for (let _field in validations) {
    let validationInfo = validations[_field];
    if (typeof validationInfo === 'object') {
      await populateNestedValidationErrors({
        ...props,
        nestedField: _field,
      });
    } else if (!validateOnUpdate || !updates || updates[_field] !== undefined) {
      // handle validation on updates instead of data - case date validation on current date
      // validation should not work on update data if not change in field, Nested case handling pending
      // validateOnUpdate pass from without nested and without field
      await populateValidationErrors({
        ...props,
        field: _field,
      });
    }
  }
};

const populateNestedMandatoryErrors = props => {
  let {
    data,
    field,
    mandatory,
    mandatoryErrors,
    defaultMandatoryMessage,
    nestedField,
    nestedId,
    innerNestedField,
    innerNestedId,
  } = props;
  let nestedFieldMandatory = mandatory ? mandatory[nestedField] : void 0;
  if (!nestedFieldMandatory) {
    return;
  }
  let nestedFieldErrors = {
    ...mandatoryErrors[nestedField],
  };
  let nestedFieldValue = data ? data[nestedField] : void 0;
  if (nestedFieldValue) {
    for (let nestedDoc of nestedFieldValue) {
      let _nestedId = nestedDoc._id;
      if (nestedId && nestedId !== _nestedId) {
        continue;
      }

      let nestedFieldIdErrors = {
        ...nestedFieldErrors[_nestedId],
      };

      let nestedErrorProps = {
        data: {
          ...nestedDoc,
          _parent: data,
        },
        mandatory: nestedFieldMandatory,
        mandatoryErrors: nestedFieldIdErrors,
        defaultMandatoryMessage,
      };
      if (field) {
        if (innerNestedField) {
          populateNestedMandatoryErrors({
            ...nestedErrorProps,
            field,
            nestedField: innerNestedField,
            nestedId: innerNestedId,
          });
        } else {
          populateMandatoryErrors({
            ...nestedErrorProps,
            field,
          });
        }
      } else {
        resolveAllMandatoryErrors(nestedErrorProps);
      }
      if (nestedFieldIdErrors && Object.keys(nestedFieldIdErrors).length) {
        nestedFieldErrors[_nestedId] = nestedFieldIdErrors;
      } else {
        delete nestedFieldErrors[_nestedId];
      }
    }
  }
  if (!field) {
    for (let _nestedId in nestedFieldErrors) {
      if (!findById(nestedFieldValue || [], '_id', {_id: _nestedId})) {
        delete nestedFieldErrors[_nestedId];
      }
    }
  }
  if (nestedFieldErrors && Object.keys(nestedFieldErrors).length) {
    mandatoryErrors[nestedField] = nestedFieldErrors;
  } else {
    delete mandatoryErrors[nestedField];
  }
};

const populateNestedValidationErrors = async props => {
  let {
    data,
    field,
    validations,
    validationErrors,
    nestedField,
    nestedId,
    innerNestedField,
    innerNestedId,
  } = props;
  let nestedFieldValidations = validations ? validations[nestedField] : void 0;
  if (!nestedFieldValidations) {
    return;
  }
  let nestedFieldErrors = {
    ...validationErrors[nestedField],
  };
  let nestedFieldValue = data ? data[nestedField] : void 0;
  if (nestedFieldValue) {
    for (let nestedDoc of nestedFieldValue) {
      let _nestedId = nestedDoc._id;
      if (nestedId && nestedId !== _nestedId) {
        continue;
      }

      let nestedFieldIdErrors = {
        ...nestedFieldErrors[_nestedId],
      };

      let nestedErrorProps = {
        data: {
          ...nestedDoc,
          _parent: data,
        },
        validations: nestedFieldValidations,
        validationErrors: nestedFieldIdErrors,
      };
      if (field) {
        if (innerNestedField && innerNestedId) {
          await populateNestedValidationErrors({
            ...nestedErrorProps,
            field,
            nestedField: innerNestedField,
            nestedId: innerNestedId,
          });
        } else {
          await populateValidationErrors({
            ...nestedErrorProps,
            field,
          });
        }
      } else {
        await resolveAllValidationErrors(nestedErrorProps);
      }
      if (nestedFieldIdErrors && Object.keys(nestedFieldIdErrors).length) {
        nestedFieldErrors[_nestedId] = nestedFieldIdErrors;
      } else {
        delete nestedFieldErrors[_nestedId];
      }
    }
  }
  if (!field) {
    for (let _nestedId in nestedFieldErrors) {
      if (!findById(nestedFieldValue || [], '_id', {_id: _nestedId})) {
        delete nestedFieldErrors[_nestedId];
      }
    }
  }
  if (nestedFieldErrors && Object.keys(nestedFieldErrors).length) {
    validationErrors[nestedField] = nestedFieldErrors;
  } else {
    delete validationErrors[nestedField];
  }
};

const mergeErrors = (errors, newErrors, field) => {
  if (field && newErrors[field] != errors[field]) {
    errors = {
      ...errors,
    };
    if (newErrors[field]) {
      errors[field] = newErrors[field];
    } else {
      delete errors[field];
    }
  }
  return errors;
};

const mergeNestedErrors = ({errors, newErrors, field, pathRow}) => {
  let {_id: nestedId, field: nestedField} = pathRow;
  let newNestedFieldErrors =
    (newErrors[nestedField] ? newErrors[nestedField][nestedId] : void 0) || {};
  let nestedFieldErrors =
    (errors[nestedField] ? errors[nestedField][nestedId] : void 0) || {};

  if (newNestedFieldErrors[field] != nestedFieldErrors[field]) {
    nestedFieldErrors = mergeErrors(
      nestedFieldErrors,
      newNestedFieldErrors,
      field,
    );
    errors = {
      ...errors,
      [nestedField]: {
        ...errors[nestedField],
        [nestedId]: nestedFieldErrors,
      },
    };
    if (!Object.keys(nestedFieldErrors).length) {
      delete errors[nestedField][nestedId];
      if (!Object.keys(errors[nestedField]).length) {
        delete errors[nestedField];
      }
    }
  }
  return errors;
};

export const mergeFieldValidationState = ({state, newState, field, path}) => {
  let {mandatoryErrors = {}, validationErrors = {}} = state;
  let {
    mandatoryErrors: newMandatoryErrors = {},
    validationErrors: newValidationErrors = {},
  } = newState;
  if (path) {
    path.forEach(pathRow => {
      mandatoryErrors = mergeNestedErrors({
        errors: mandatoryErrors,
        newErrors: newMandatoryErrors,
        field,
        pathRow,
      });
      validationErrors = mergeNestedErrors({
        errors: validationErrors,
        newErrors: newValidationErrors,
        field,
        pathRow,
      });
    });
  } else if (field) {
    mandatoryErrors = mergeErrors(mandatoryErrors, newMandatoryErrors, field);
    validationErrors = mergeErrors(
      validationErrors,
      newValidationErrors,
      field,
    );
  }
  return {
    mandatoryErrors,
    validationErrors,
    hasMandatoryError: Object.keys(mandatoryErrors).length > 0,
    hasValidationError: Object.keys(validationErrors).length > 0,
  };
};

const hasError = (validations, errors, validateFields) => {
  if (validations && Object.keys(validations).length) {
    if (validateFields) {
      for (let k in validations) {
        if (errors[k] !== undefined && errors[k] !== null) {
          return true;
        }
      }
    } else {
      return true;
    }
  }
};

const validate = async (
  state,
  {
    mandatory,
    validations,
    validateOnUpdate = true,
    field,
    mandatoryMessage,
    path,
    checkCurrentValidations,
  },
) => {
  let {mandatoryErrors = {}, validationErrors = {}, data, updates} = state;
  mandatoryErrors = {...mandatoryErrors};
  validationErrors = {...validationErrors};
  let mandatoryProps = {
    data,
    mandatory,
    mandatoryErrors,
    defaultMandatoryMessage: mandatoryMessage,
  };
  let validationProps = {
    data,
    updates,
    validations,
    validationErrors,
  };
  if (field) {
    mandatoryProps.field = field;
    validationProps.field = field;
    if (path && path.length) {
      let {_id: nestedId, field: nestedField} = path[0];
      let nestedProps = {
        nestedField,
        nestedId,
      };
      if (path.length > 1) {
        let {_id: innerNestedId, field: innerNestedField} = path[1];
        nestedProps.innerNestedField = innerNestedField;
        nestedProps.innerNestedId = innerNestedId;
      }
      populateNestedMandatoryErrors({
        ...mandatoryProps,
        ...nestedProps,
      });
      await populateNestedValidationErrors({
        ...validationProps,
        ...nestedProps,
      });
    } else {
      populateMandatoryErrors(mandatoryProps);
      await populateValidationErrors(validationProps);
    }
  } else {
    resolveAllMandatoryErrors(mandatoryProps);
    await resolveAllValidationErrors({
      ...validationProps,
      validateOnUpdate,
    });
  }

  return {
    ...state,
    mandatoryErrors,
    validationErrors,
    hasMandatoryError: hasError(
      mandatoryErrors,
      mandatory,
      checkCurrentValidations,
    ), // need to do it on property base
    hasValidationError: hasError(
      validationErrors,
      validations,
      checkCurrentValidations,
    ),
  };
};

export {validate};
