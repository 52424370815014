export default {
  labels: {
    category: 'Category',
    description: 'Description',
    subCategory: 'Sub-Category',
    subCategoryName: 'Sub Category Name',
    status: 'Status',
    actions: 'Actions',
    supplierName: 'Supplier Name',
    address: 'Address',
    email: 'Email',
    contact: 'Contact No',
    manufacturer: 'Manufacturer',
    manufacturerName: 'Manufacturer Name',
    item: 'Item',
  },

  buttons: {
    addCategory: 'Add Category',
    editCategory: 'Edit Category',
    createSubCategory: 'Create Sub-Category',
    create: 'Create',
    update: 'Update',
    addSupplier: 'Add Supplier',
    editSupplier: 'Edit Supplier',
    addManufacturer: 'Add Manufacturer',
    editManufacturer: 'Edit Manufacturer',
  },
  title: {
    categoryManagement: 'Category Management',
    addSubCategoryDescription: 'Create Sub Category of the Category.',
    editSubCategoryDescription: 'Edit Sub Category of the Category.',
    supplierManagement: 'Suppliers and Manufacturers Management',
    addManufacturerDescription: 'Create Manufacturer of the Supplier.',
    editManufacturerDescription: 'Edit Manufacturer of the Supplier.',
    itemDetails: 'Item Details',
    manufactureDetails: 'Manufacture & Supplier Details',
  },
  messages: {
    categoryAddedSuccessfully: 'Category has been Added successfully.',
    categoryUpdatedSuccessfully: 'Category has been Updated successfully.',
    categoryAlreadyExists: 'Category Already Exists',
    subCategoryAddedSuccessfully: 'Sub-Category has been Added successfully.',
    subCategoryUpdatedSuccessfully:
      'Sub-Category has been Updated successfully.',
    subCategoryAlreadyExists: 'Sub-Category Already Exists',
    supplierAddedSuccessfully: 'Supplier has been Added successfully.',
    supplierUpdatedSuccessfully: 'Supplier has been Updated successfully.',
    supplierAlreadyExists: 'Supplier Already Exists',
    manufacturerAddedSuccessfully: 'Manufacturer has been Added successfully.',
    manufacturerUpdatedSuccessfully:
      'Manufacturer has been Updated successfully.',
    manufacturerAlreadyExists: 'Manufacturer Already Exists',
  },
};
