export default {
  labels: {
    shiftName: 'Shift Name',
    shiftId: 'Shift ID',
    startTime: 'Start Time',
    endTime: 'End Time',
    status: 'Status',
    activityLog: 'Activity Log',
    employee: 'Employee',
  },
  title: {
    shiftManagement: 'Shift Management',
    addShift: 'Add Shift',
    editShift: 'Edit Shift',
    exportToExcel: 'Export To Excel',
  },
  messages: {
    shiftAddedSuccessfully: 'Shift has been added successfully.',
    shiftUpdatedSuccessfully: 'Shift has been updated successfully.',
  },
};
