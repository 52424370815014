export const Headline1 = {
  fontFamily: 'AvenirNext-Medium',
  fontSize: '66px',
  lineHeight: '77px',
};

export const Headline2 = {
  fontFamily: 'AvenirNext-Regular',
  fontSize: '42px',
  lineHeight: '49px',
};

export const Headline3 = {
  fontFamily: 'AvenirNext-Medium',
  fontSize: '20px',
  lineHeight: '23px',
};

export const Headline4 = {
  fontFamily: 'AvenirNext-Regular',
  fontSize: '20px',
  lineHeight: '23px',
};

export const Headline5 = {
  fontFamily: 'AvenirNext-Medium',
  fontSize: '16px',
  lineHeight: '19px',
};

export const Headline6 = {
  fontFamily: 'AvenirNext-Regular',
  fontSize: '16px',
  lineHeight: '19px',
};

export const Headline7 = {
  fontFamily: 'Roboto-Bold',
  fontSize: '14px',
  lineHeight: '16px',
};

export const Headline8 = {
  fontFamily: 'AvenirNext-Medium',
  fontSize: '14px',
  lineHeight: '16px',
};

export const Headline9 = {
  fontFamily: 'AvenirNext-Regular',
  fontSize: '14px',
  lineHeight: '16px',
};

export const Headline10 = {
  fontFamily: 'Roboto-Light',
  fontSize: '14px',
  lineHeight: '16px',
};

export const Headline11 = {
  fontFamily: 'AvenirNext-Regular',
  fontSize: '12px',
  lineHeight: '14px',
};

export const Headline12 = {
  fontFamily: 'Roboto-Light',
  fontSize: '12px',
  lineHeight: '14px',
};

export const Headline13 = {
  fontFamily: 'AvenirNext-MediumItalic',
  fontSize: '12px',
  lineHeight: '14px',
};
export const Headline14 = {
  fontFamily: 'AvenirNext-Regular',
  fontSize: '10px',
  lineHeight: '12px',
};
export const Headline15 = {
  fontFamily: 'AvenirNext-Medium',
  fontSize: '10px',
  lineHeight: '12px',
};
