import React, { Component } from 'react';
import { Image, TouchableOpacity, View } from '@applane/react-core-components';
import MoreActions from '../../action/MoreActions';

class CheckBoxComponent extends Component {
  onPress = () => {
    const { item, onSelect } = this.props;
    onSelect && onSelect({ item });
  };
  render() {
    const { value, selectedIcon, unCheckboxIcon } = this.props;
    return (
      <TouchableOpacity
        style={{ paddingLeft: 6, paddingRight: 6 }}
        onPress={this.onPress}>
        <Image
          source={value ? selectedIcon : unCheckboxIcon}
          style={{ width: 20, height: 20 }}
          resizeMode="contain"
        />
      </TouchableOpacity>
    );
  }
}

export class HeaderRowSelector extends React.Component {
  render() {
    let {
      isChecked,
      onChecked,
      headerRowActions,
      singleSelection,
    } = this.props;
    if (singleSelection) {
      return null;
    }
    let renderComponent = (
      <CheckBoxComponent
        {...this.props}
        value={isChecked}
        onSelect={onChecked}
      />
    );
    if (headerRowActions) {
      renderComponent = (
        <View style={{ flexDirection: 'row' }}>
          {renderComponent}
          <MoreActions
            position={'right'}
            {...this.props}
            actions={headerRowActions}
            iconStyle={{ height: 16, width: 8 }}
          />
        </View>
      );
    }
    return renderComponent;
  }
}
export class RowSelector extends React.Component {
  render() {
    let { isChecked, onChecked } = this.props;
    return (
      <CheckBoxComponent
        {...this.props}
        value={isChecked}
        onSelect={onChecked}
      />
    );
  }
}

export const WithHeaderSelector = (Component) => {
  class HeaderSelectorComponent extends React.Component {
    onChecked = ({}) => {
      const { screenState, setScreenState, data } = this.props;
      if (!data || !data.length) {
        return;
      }
      let field = '_id';
      let { selectedIds = [], allPageSelected } = screenState || {};
      if (this.isChecked()) {
        allPageSelected = void 0;
        selectedIds = void 0;
      } else {
        selectedIds = data.map((item) => item[field] || item);
      }
      setScreenState && setScreenState({ selectedIds, allPageSelected });
    };
    isChecked = () => {
      const { screenState, data } = this.props;
      if (!data || !data.length) {
        return;
      }
      let { selectedIds = [], allPageSelected } = screenState || {};
      return data.length === selectedIds.length || allPageSelected;
    };
    render() {
      return (
        <Component
          {...this.props}
          onChecked={this.onChecked}
          isChecked={this.isChecked()}
        />
      );
    }
  }
  return HeaderSelectorComponent;
};

export const WithRowSelector = (Component) => {
  class RowSelectorComponent extends React.Component {
    onChecked = ({ item }) => {
      const { screenState, setScreenState, singleSelection } = this.props;
      if (!item) {
        return;
      }
      const field = '_id';
      let { selectedIds = [], allPageSelected } = screenState || {};
      let itemValue = item[field] || item;
      if (itemValue) {
        let idIndex = selectedIds.findIndex((key) => key === itemValue);
        if (idIndex > -1) {
          allPageSelected = false;
          selectedIds = [...selectedIds];
          selectedIds.splice(idIndex, 1);
        } else {
          if (singleSelection) {
            selectedIds = [itemValue];
          } else {
            selectedIds = [...selectedIds, itemValue];
          }
        }
      }
      setScreenState && setScreenState({ selectedIds, allPageSelected });
    };
    isChecked = () => {
      const { item, screenState } = this.props;
      const field = '_id';
      if (!item) {
        return;
      }
      let { selectedIds = [], allPageSelected } = screenState || {};
      if (allPageSelected) {
        return true;
      }
      let itemValue = item[field] || item;
      if (itemValue) {
        return selectedIds.findIndex((key) => key === itemValue) >= 0;
      }
    };
    render() {
      const {
        screenState,
        setScreenSate,
        state,
        setState,
        ...restProps
      } = this.props;
      return (
        <Component
          {...restProps}
          onChecked={this.onChecked}
          isChecked={this.isChecked()}
        />
      );
    }
  }
  return RowSelectorComponent;
};
