import React from 'react';
import { FormField, Image, View } from '.';

import { autoSuggestInput } from '../autoSuggestions';
import { downArrow_blue as downArrowBlue } from '../images';
import { relations } from '../Lib/constants';
import { validateAlphabetsWithSpaceOnly } from '../Lib/helpers';
import { manazeV6Colors } from '../theme/manazeV6Colors';
import vars from '../theme/vars';

const { primary_2 } = manazeV6Colors;

export const currencyInput = (props) => {
  const { amount, currency, ...restProps } = props;
  return {
    type: 'currency',
    fieldType: 'composite',
    fields: [
      {
        flex: 1,
        type: 'number',
        ...amount,
      },
      autoSuggestInput({
        flex: 0.5,
        minWidth: 85,
        maxWidth: 150,
        options: ['INR'],
        placeholder: '',
        skipRemoveValue: true,
        textColor: primary_2,
        ...currency,
      }),
    ],
    ...restProps,
  };
};

export const ageInput = (props) => {
  const { age, ageType, ...restProps } = props;
  return {
    type: 'currency',
    fieldType: 'composite',
    fields: [
      {
        flex: 1,
        type: 'number',
        ...age,
      },
      autoSuggestInput({
        flex: 0.5,
        minWidth: 85,
        maxWidth: 150,
        options: ['Years', 'Months', 'Days'],
        placeholder: '',
        skipRemoveValue: true,
        textColor: vars.colors.grey.color1,
        dropdownStyle: {
          width: 125,
          height: 270,
          backgroundColor: vars.colors.white,
        },
        ...ageType,
      }),
    ],
    ...restProps,
  };
};

export const dateInput = {
  type: 'currency',
  fieldType: 'composite',
  fields: [
    {
      flex: 1,
      type: 'number',
      field: 'hour',
      label: 'Hour',
      mandatory: true,
      allowedDecimalDigits: false,
      modifyValueOnChange: ({ prevValue, value }) => {
        if (value > 11) {
          return prevValue;
        }
        return value;
      },
    },
    {
      flex: 1,
      type: 'number',
      label: 'Minute',
      field: 'minute',
      mandatory: true,
      allowedDecimalDigits: false,
      modifyValueOnChange: ({ prevValue, value }) => {
        if (value > 59) {
          return prevValue;
        }
        return value;
      },
    },
    autoSuggestInput({
      flex: 0.5,
      minWidth: 85,
      maxWidth: 100,
      options: ['AM', 'PM'],
      placeholder: '',
      skipRemoveValue: true,
      textColor: vars.colors.grey.color1,
      dropdownStyle: {
        width: 125,
        height: 270,
        backgroundColor: vars.colors.white,
      },
      field: 'meridiem',
    }),
  ],
};

const RelationSelector = () => (
  <View
    style={{
      width: 20,
      justifyContent: 'center',
      alignItems: 'flex-start',
    }}
  >
    <Image
      source={downArrowBlue}
      style={{
        width: 11,
        height: 6,
      }}
    />
  </View>
);

export const RelationInput = (props) => {
  const {
    relationFieldName = 'relation',
    relationNameFieldName = 'relationName',
    mandatory = false,
    editable = true,
    relationProps = {},
    relationNameProps = {},
    disabled = false,
  } = props;

  return (
    <View style={{
      flex: 1,
      flexDirection: 'row',
    }}
    >
      <View
        style={{
          width: 80,
        }}
      >
        <FormField
          {...autoSuggestInput({
            field: relationFieldName,
            type: 'autoSuggest',
            variant: 'filled',
            suggestionField: 'value',
            label: editable ? '' : 'Relation',
            valueField: 'value',
            showArrow: false,
            options: relations,
            renderSelector: RelationSelector,
            editable,
            disabled,
            dropdownStyle: {
              width: 125,
              height: 270,
              backgroundColor: vars.colors.white,
            },
            ...relationProps,
          })}
        />
      </View>
      <View
        style={{
          flex: 1,
        }}
      >
        <FormField
          label="Relation Name"
          field={relationNameFieldName}
          placeholder="Relation Name"
          type="text"
          variant="filled"
          mandatory={mandatory}
          editable={editable}
          disabled={disabled}
          modifyValueOnChange={({ prevValue, value }) => {
            if (validateAlphabetsWithSpaceOnly(value)) {
              return prevValue;
            }
            return value.replace(/ {2,}/g, ' ');
          }}
          {...relationNameProps}
        />
      </View>
    </View>
  );
};
