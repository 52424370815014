import React from 'react';
import AutoSuggestWithoutModal from './AutoSuggestWithoutModal';
import AutoSuggestWithModal from './AutoSuggestWithModal';

let AutoSuggestFilter = props => {
  let {
    withoutModal,
    filterType = 'autoSuggest',
    ...restProps
  } = props;
  if (restProps.options) {
    filterType = 'autoSuggestOptions';
  }
  const Component = withoutModal
    ? AutoSuggestWithoutModal
    : AutoSuggestWithModal;
  return (
    <Component
      {...restProps}
      filterType={filterType}
    />
  );
};

const AutoSuggestOptionsFilter = props => {
  return <AutoSuggestFilter filterType="autoSuggestOptions" {...props} />;
};

export {AutoSuggestFilter, AutoSuggestOptionsFilter};
