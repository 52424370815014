import React from 'react';
import {View, TouchableOpacity, Text} from '@applane/react-core-components';
import {WithInputWrapper, WithInputError} from '@applane/react-input-wrapper';
import {getRenderComponent} from './Utility';
import WithFetch from './WithFetch';

class ToggleButton extends React.Component {
  render() {
    let {
      className,
      itemClassName,
      selectedItemClassName,
      style,
      itemStyle,
      selectedItemStyle,
      optionStyle,
      selectedOptionStyle,
      value,
      defaultValue,
      renderLabel,
      options,
      onChangeValue,
      disabled,
      valueField,
    } = this.props;
    if (!options || !options.length) {
      return null;
    }
    value = value || defaultValue;
    // if (value && typeof value !== 'string') {
    //   value = value.value;
    // }
    return (
      <View
        className={className}
        style={{
          flexDirection: 'row',
          ...style,
        }}>
        {options.map(option => {
          if (!option) {
            return;
          }
          let optionValue = option;
          let optionLabel = option;
          if (typeof option !== 'string') {
            optionValue = option.value;
            optionLabel = option.label || option.value;
          }
          let isSelected = false;
          if (value !== undefined && optionValue !== undefined) {
            if (
              typeof value === 'object' &&
              typeof optionValue === 'object' &&
              valueField
            ) {
              isSelected = value[valueField] === optionValue[valueField];
            } else {
              isSelected = value === optionValue;
            }
          }
          let labelComponent = renderLabel ? (
            getRenderComponent(renderLabel, option)
          ) : (
            <Text
              style={{
                ...optionStyle,
                ...(isSelected && selectedOptionStyle),
              }}>
              {optionLabel}
            </Text>
          );
          return (
            <TouchableOpacity
              key={optionValue}
              disabled={disabled}
              activeOpacity={0.6}
              className={(isSelected && selectedItemClassName) || itemClassName}
              style={{
                justifyContent: 'center',
                ...itemStyle,
                ...(isSelected && selectedItemStyle),
              }}
              onPress={e => {
                onChangeValue ? onChangeValue(optionValue, e) : void 0;
              }}>
              {labelComponent}
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }
}

ToggleButton.defaultProps = {
  style: {
    borderRadius: 4,
    backgroundColor: '#f6f6f6',
  },
  itemStyle: {
    minWidth: 100,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 16,
    paddingRight: 16,
    borderWidth: 1,
    borderColor: 'transparent',
    cursor: 'pointer',
  },
  selectedItemStyle: {
    borderRadius: 4,
    borderColor: '#1ed69e',
    backgroundColor: '#fbfbfb',
  },
  optionStyle: {
    textAlign: 'center',
    color: '#a2a2a2',
  },
  selectedOptionStyle: {
    color: '#1ed69e',
  },
};

export default WithFetch(WithInputWrapper(WithInputError(ToggleButton)));
