import React, { useState } from 'react';
import {
  Image,
  Text,
  View,
  TouchableOpacity,
  Platform,
  ScrollView,
} from '@applane/react-core-components';
import {
  backCrossIcon,
  newSearchIcon,
  collapseIcon,
  expandIcon,
} from '../../../images';
import { getImageUrl } from '../../../AppServices';
import { ActivityIndicator } from '@applane/react-activity-indicator';
import { Avatar } from '@applane/react-avatar';
import { renderRowStyle } from '../../../theme/editorTheme';
import { getOptionValue } from '../../filter/autoSuggest/AutoSuggestUtility';
import { manazeV6Colors } from '../../../theme/manazeV6Colors';
import { Headline9 } from '../../../theme/Headlines';
const { neutral_4 } = manazeV6Colors;
export const RenderLoading = ({
  loading,
  defaultLoadingComponent,
  closeModal,
}) => {
  // return defaultLoadingComponent()
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <ActivityIndicator width={3.5} size={35} color={'#6d91f0'} />
    </View>
  );
};

export const RenderRowComponent = (props) => {
  let {
    item,
    primaryField,
    secondaryField,
    iconField,
    modifyIcon,
    avatarIcon,
    highlighted,
    containerStyle,
    selectedIconContainerStyle,
    selectedIconStyle,
    avatarTextStyle,
    selectedContainerStyle,
    hoverContainerStyle,
    titleStyle,
    selectedTitleStyle,
    hoverTitleStyle,
    hoverDescriptionStyle,
    descriptionStyle,
    selectedDescriptionStyle,
    selectedIcon,
    disableOnHover,
    iconContainerStyle,
    iconStyle,
    avatarStyle,
    hoverAvatarStyle,
    selectedAvatarStyle,
    showAvatar,
    isHover,
    showSelected = true,
    resolveIcon = getImageUrl,
    iconAvatarSize = 24,
    avatarSize = 28,
  } = props;
  if (item === undefined || item === null) {
    return null;
  }
  let icon = typeof item === 'object' && iconField ? item[iconField] : void 0;
  if (icon && modifyIcon) {
    icon = modifyIcon(icon);
  }
  let title =
    typeof item === 'object' && primaryField ? item[primaryField] : item;
  if (title && typeof title === 'object') {
    title = JSON.stringify(title);
  }
  let description =
    typeof item === 'object' && secondaryField ? item[secondaryField] : void 0;
  if (description && typeof description === 'object') {
    description = JSON.stringify(description);
  }
  let backgroundColor = '#e8ecfc';

  if (!disableOnHover && isHover) {
    containerStyle = { ...containerStyle, ...hoverContainerStyle };
    titleStyle = { ...titleStyle, ...hoverTitleStyle };
    descriptionStyle = { ...descriptionStyle, ...hoverDescriptionStyle };
    avatarStyle = { ...avatarStyle, ...hoverAvatarStyle };
    // backgroundColor = '#fff';
  }
  if (highlighted) {
    containerStyle = { ...containerStyle, ...selectedContainerStyle };
    titleStyle = { ...titleStyle, ...selectedTitleStyle };
    descriptionStyle = { ...descriptionStyle, ...selectedDescriptionStyle };
    avatarStyle = { ...avatarStyle, ...selectedAvatarStyle };
  }
  let renderComponent = title ? <Text style={titleStyle}>{title}</Text> : null;
  if (description) {
    renderComponent = (
      <>
        {renderComponent}
        <Text style={descriptionStyle}>{description}</Text>
      </>
    );
  }
  renderComponent = (
    <View
      style={{
        flex: 1,
        overflow: 'hidden',
      }}>
      {renderComponent}
    </View>
  );
  let iconComponent = iconField ? (
    <View style={iconContainerStyle}>
      {icon ? (
        <Image style={iconStyle} source={icon} resizeMode="contain" />
      ) : (
        <Avatar
          size={iconAvatarSize}
          backgroundColor={backgroundColor}
          value={title}
          textStyle={avatarTextStyle}
        />
      )}
    </View>
  ) : (
    void 0
  );
  let avatarComponent = showAvatar ? (
    <Avatar
      size={avatarSize}
      style={avatarStyle}
      icon={avatarIcon ? item[avatarIcon] : void 0}
      backgroundColor={backgroundColor}
      value={title}
      resolveIcon={resolveIcon}
      textStyle={avatarTextStyle}
    />
  ) : (
    void 0
  );
  return (
    <View
      style={{
        flexDirection: 'row',
        cursor: 'pointer',
        ...containerStyle,
      }}
      title={title}>
      {iconComponent}
      {avatarComponent}
      {renderComponent}
      {showSelected && selectedIcon && highlighted ? (
        <View style={selectedIconContainerStyle}>
          <Image
            style={selectedIconStyle}
            source={selectedIcon}
            resizeMode="contain"
          />
        </View>
      ) : (
        void 0
      )}
    </View>
  );
};

export const getAutoSuggestProps = ({
  renderRow,
  renderRowProps,
  renderSelector,
  renderLoading,
  showAvatar,
  ...restProps
}) => {
  let renderRowComponent = void 0;
  if (renderRowProps || renderRow) {
    renderRowComponent = (params) => {
      return renderRow ? (
        renderRow({
          ...params,
          ...renderRowProps,
        })
      ) : (
        <RenderRowComponent
          showAvatar={showAvatar}
          {...params}
          {...renderRowStyle}
          {...renderRowProps}
        />
      );
    };
  }
  if (
    renderSelector &&
    (typeof renderSelector === 'object' || typeof renderSelector === 'boolean')
  ) {
    renderSelector = (params) => (
      <RenderSelector
        {...params}
        {...restProps}
        renderSelector={renderSelector}
      />
    );
  }
  if (renderLoading && typeof renderLoading === 'boolean') {
    renderLoading = (props) => {
      return <RenderLoading {...props} />;
    };
  }
  return {
    renderRow: renderRowComponent,
    renderSelector,
    renderLoading,
  };
};

export const RenderSelector = ({
  value,
  searching,
  modalOpen,
  searchText,
  clearValue,
  clearText,
  skipRemoveValue,
  inputSelectorContainerStyle,
  inputSelectorIconContainerStyle,
}) => {
  let onPress = void 0;
  let selectorIcon;
  if (searching) {
    if (searchText) {
      selectorIcon = backCrossIcon;
      onPress = clearText;
    } else if (modalOpen) {
      selectorIcon = newSearchIcon;
    } else if (value && !skipRemoveValue) {
      selectorIcon = backCrossIcon;
      onPress = clearValue;
    } else {
      selectorIcon = newSearchIcon;
    }
  } else {
    if (modalOpen) {
      selectorIcon = collapseIcon;
    } else if (value && !skipRemoveValue) {
      selectorIcon = backCrossIcon;
      onPress = clearValue;
    } else {
      selectorIcon = expandIcon;
    }
  }
  let touchProps = {};
  let RenderComponent = View;
  if (onPress) {
    RenderComponent = TouchableOpacity;
    if (Platform.OS === 'web') {
      touchProps.onMouseDown = (e) => {
        e && e.stopPropagation && e.stopPropagation();
        onPress && onPress(e);
      };
    } else {
      touchProps.onPress = onPress;
    }
  }

  return (
    <View
      style={
        inputSelectorContainerStyle || {
          paddingRight: 12,
          paddingTop: 6,
          paddingBottom: 6,
          cursor: 'pointer',
        }
      }>
      <RenderComponent {...touchProps} style={inputSelectorIconContainerStyle}>
        <Image source={selectorIcon} style={{ maxHeight: 16, maxWidth: 16 }} />
      </RenderComponent>
    </View>
  );
};

export const renderChips = ({
  disabled,
  roundedChipsStyle,
  value,
  valueField,
  idField,
  removeChipIcon,
  onRemove,
}) => {
  if (!value || !value.length) {
    return;
  }
  let {
    containerStyle,
    itemContainerStyle,
    itemTextStyle,
    crossContainerStyle,
    crossIconStyle,
    crossTextStyle,
  } = roundedChipsStyle;
  containerStyle = { ...containerStyle };
  return (
    <View style={containerStyle}>
      {value.map((item, index) => {
        if (!item) {
          return null;
        }
        let key = idField ? item[idField] : index;
        let displayValue = valueField ? item[valueField] : item;
        return (
          <View
            key={key}
            style={{ flexDirection: 'row', ...itemContainerStyle }}>
            <View
              style={{
                flexDirection: 'row',
                paddingLeft: 6,
                paddingRight: 6,
                alignItems: 'center',
              }}>
              <Text style={itemTextStyle}>{displayValue}</Text>
              {!disabled && onRemove && (
                <TouchableOpacity
                  style={{ cursor: 'pointer', ...crossContainerStyle }}
                  onPress={(e) => onRemove({ item, e })}>
                  {removeChipIcon ? (
                    <Image style={crossIconStyle} source={removeChipIcon} />
                  ) : (
                    <Text style={crossTextStyle}>X</Text>
                  )}
                </TouchableOpacity>
              )}
            </View>
          </View>
        );
      })}
    </View>
  );
};

export const renderAvatarChips = ({
  disabled,
  roundedChipsWithAvatarStyle,
  value,
  valueField,
  idField,
  avatarIcon,
  removeChipIcon,
  onRemove,
  maxHeight,
}) => {
  if (!value || !value.length) {
    return;
  }
  let {
    containerStyle,
    itemContainerStyle,
    itemTextStyle,
    crossContainerStyle,
    crossIconStyle,
    crossTextStyle,
    avatarStyle,
  } = roundedChipsWithAvatarStyle;
  containerStyle = { ...containerStyle };
  if (maxHeight) {
    containerStyle.maxHeight = maxHeight;
  }
  return (
    <ScrollView style={{ flex: 1 }}>
      <View style={containerStyle}>
        {value.map((item, index) => {
          if (!item) {
            return null;
          }
          let key = idField ? item[idField] : index;
          let displayValue = valueField ? item[valueField] : item;
          return (
            <View
              key={key}
              style={{ flexDirection: 'row', ...itemContainerStyle }}>
              <Avatar
                size={36}
                icon={avatarIcon ? item[avatarIcon] : void 0}
                value={displayValue}
                resolveIcon={getImageUrl}
                textStyle={{ ...itemTextStyle }}
                {...avatarStyle}
              />
              <View
                style={{
                  flexDirection: 'row',
                  paddingLeft: 6,
                  paddingRight: 6,
                  alignItems: 'center',
                }}>
                <Text style={itemTextStyle}>{displayValue || 'N/A'}</Text>
                {!disabled && onRemove && (
                  <TouchableOpacity
                    style={{ cursor: 'pointer', ...crossContainerStyle }}
                    onPress={(e) => onRemove({ item, e })}>
                    {removeChipIcon ? (
                      <Image style={crossIconStyle} source={removeChipIcon} />
                    ) : (
                      <Text style={crossTextStyle}>X</Text>
                    )}
                  </TouchableOpacity>
                )}
              </View>
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
};