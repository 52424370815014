import React from 'react';
import {View, Text, Image} from '@applane/react-core-components';
import WithHover from './WithHover';
import {getRenderComponent} from './Utility';

let StandardDropDownItem = props => {
  let {
    icon,
    hoverIcon,
    activeIcon,
    text,
    render,
    index,
    Action,
    action,
    highlighted,
    showSelected,
    selectedIcon,
    isHover,
    containerStyle,
    hoverContainerStyle,
    activeContainerStyle,
    iconContainerStyle,
    iconStyle,
    textStyle,
    hoverTextStyle,
    activeTextStyle,
    selectedIconContainerStyle,
    selectedIconStyle,
    numberOfLines,
    actionProps,
    ...restProps
  } = props;
  if (highlighted && typeof highlighted === 'function') {
    highlighted = highlighted({item: action, index: restProps.index});
  }
  if (isHover) {
    icon = hoverIcon || icon;
    containerStyle = {
      ...containerStyle,
      ...hoverContainerStyle,
    };
    textStyle = {
      ...textStyle,
      ...hoverTextStyle,
    };
  } else if (highlighted) {
    containerStyle = {
      ...containerStyle,
      ...activeContainerStyle,
    };
  }
  if (highlighted) {
    icon = activeIcon || icon;
    textStyle = {
      ...textStyle,
      ...activeTextStyle,
    };
  }
  if (numberOfLines) {
    textStyle = {
      ...textStyle,
      numberOfLines,
    };
  }
  let renderItemComponent = render ? (
    getRenderComponent(render, props)
  ) : (
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
      {icon ? (
        <View style={iconContainerStyle}>
          <Image style={iconStyle} source={icon} resizeMode="contain" />
        </View>
      ) : (
        void 0
      )}
      <View style={{flex: 1, overflow: 'hidden'}}>
        <Text style={textStyle}>{text || ''}</Text>
      </View>
    </View>
  );
  return (
    <Action
      key={index}
      style={{
        ...containerStyle,
        flexDirection: 'row',
        flex: 1,
        overflow: 'hidden',
        alignItems: 'center',
      }}
      action={action}
      {...restProps}
      {...actionProps}>
      <View style={{flex: 1, overflow: 'hidden'}}>{renderItemComponent}</View>
      {showSelected && selectedIcon && highlighted ? (
        <View style={selectedIconContainerStyle}>
          <Image
            style={selectedIconStyle}
            source={selectedIcon}
            resizeMode="contain"
          />
        </View>
      ) : (
        void 0
      )}
    </Action>
  );
};

StandardDropDownItem = WithHover(StandardDropDownItem);

export default StandardDropDownItem;
