export default {
  labels: {
    name: 'Name',
    parent: 'Parent',
    type: 'Type',
    description: 'Description',
    mis_view: 'MIS View',
    depreciation_account: 'Depreciation Account',
    isBreakable: 'Is Breakable',
    is_bank: 'Is Bank',
  },
  placeholders: {
    name: 'Name',
    type: 'Type',
    parent: 'Parent',
    description: 'Description',
    mis_view: 'MIS View',
    depreciation_account: 'Depreciation Account',

  },
  headers: {
    name: 'Name',
    parent: 'Parent',
    type: 'Type',
    action: 'Action',
    date: 'Month, Year',
    ecrNo: 'Ecr No.',
    ecrPageNo: 'Ecr Page No.',
    employeeName: 'Employee Name',
    employeeId: 'Employee Id',
    department: 'Department',
    designation: 'Designation',
    grossSalary: 'Gross Salary',
    tax: 'Tax',
    salaryGenerated: 'Salary Generated',
    status: 'Status',
  },
  messages: {
    updateMessage: 'Account Updated Successfully',
  },
};
