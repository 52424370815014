import React from 'react';
import {
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
  BasicTextInput,
  Platform,
} from '@applane/react-core-components';
import {
  WithFloatingLabel,
  WithInputWrapper,
  WithInputError,
  WithVariant,
  getInputProps,
} from '@applane/react-input-wrapper';

class MultiTextInputField extends React.Component {
  state = {};
  onSelect = e => {
    let item = Platform.OS === 'web' ? e.target.value : e.nativeEvent.text;
    let {value, onSubmitEditing, onChangeValue} = this.props;
    let newValue = value ? [...value] : [];
    newValue.push(item);
    onChangeValue && onChangeValue(newValue, e);
    onSubmitEditing && onSubmitEditing(e);
    this.setState({text: void 0});
  };
  onChangeText = text => {
    this.setState({text});
  };
  render() {
    let {
      onChange,
      onChangeValue,
      onChangeText,
      value,
      ...restInputProps
    } = getInputProps(this.props);
    return (
      <BasicTextInput
        {...restInputProps}
        value={this.state.text || ''}
        onChangeText={this.onChangeText}
        onSubmitEditing={this.onSelect}
      />
    );
  }
}

MultiTextInputField = WithVariant(
  WithInputError(WithFloatingLabel(MultiTextInputField)),
);

class MultiTextInput extends React.Component {
  removeItem = ({item, index, e}) => {
    let {value, onChangeValue} = this.props;
    if (!item || !value || !value.length) {
      return;
    }
    let newValue = value.filter((doc, _index) => {
      return index !== _index;
    });
    onChangeValue && onChangeValue(newValue, e);
  };

  renderChips = ({chipsStyle, roundedChipsStyle, chipsVariant}) => {
    let {value, removeChipIcon, maxHeight, disabled} = this.props;
    if (!value || (Array.isArray(value) && !value.length)) {
      return;
    }
    if (!Array.isArray(value)) {
      value = [value];
    }
    if (chipsVariant === 'rounded') {
      chipsStyle = roundedChipsStyle;
    }
    let {
      containerStyle,
      itemContainerStyle,
      itemTextStyle,
      crossContainerStyle,
      crossIconStyle,
      crossTextStyle,
    } = chipsStyle;
    containerStyle = {...containerStyle};
    if (maxHeight) {
      containerStyle.maxHeight = maxHeight;
    }
    return (
      <ScrollView style={containerStyle}>
        {value.map((item, index) => {
          if (!item) {
            return null;
          }
          return (
            <View
              key={index}
              style={{flexDirection: 'row', ...itemContainerStyle}}>
              <View style={{flex: 1}}>
                <Text style={itemTextStyle}>{item}</Text>
              </View>
              {!disabled && <TouchableOpacity
                style={{cursor: 'pointer', ...crossContainerStyle}}
                onPress={e => this.removeItem({item, index, e})}>
                {removeChipIcon ? (
                  <Image style={crossIconStyle} source={removeChipIcon} />
                ) : (
                  <Text style={crossTextStyle}>X</Text>
                )}
              </TouchableOpacity>}
            </View>
          );
        })}
      </ScrollView>
    );
  };
  render() {
    let {
      chipsVariant,
      chipsPosition = 'bottom',
      chipsStyle,
      renderChips,
      roundedChipsStyle,
      ...restProps
    } = this.props;
    let {value} = this.props;
    if (!renderChips) {
      renderChips = this.renderChips;
    }
    let chipsComponent = this.renderChips({
      value,
      chipsStyle,
      onRemove: this.removeItem,
      roundedChipsStyle,
      chipsVariant,
    });
    return (
      <React.Fragment>
        {chipsPosition === 'top' ? chipsComponent : void 0}
        <MultiTextInputField {...restProps} />
        {chipsPosition === 'bottom' ? chipsComponent : void 0}
      </React.Fragment>
    );
  }
}

MultiTextInput = WithInputWrapper(MultiTextInput);

export default MultiTextInput;
