export default {
  labels: {
    is_for_senior_citizen: 'IS FOR SENIOR CITIZEN',
    section: 'Section',
    name: 'Name',
    saving_head: 'Saving Head',
    tax_slabs: 'Tax Slabs',
  },
  headers: {
    name: 'Name',
    amount: 'Amount',
    max_limit: 'Max Limit',
    benefit_percent: 'Benefit Percent',
    is_based_on_salary_paid: 'Is based on salary paid',
    section: 'Section',
    finantial_year_id: 'Financial Year',
    tax_percentage: 'Tax Percent',
    to_amount: 'To Amount',
    from_amount: 'From Amount',
    action: 'Action',
  },
  title: {
    add_saving_head: 'Add New Saving Head',
    add_tax_slab: 'Add New Tax Slab',
    edit_saving_head: 'Edit Saving Head',
    edit_tax_slab: 'Edit Tax Slab',
  },
  buttons: {
    add: 'Add New',
    update: 'Update',
  },
  messages: {
    add_saving_head: 'Saving Head has been created successfully.',
    add_tax_slab: 'Tax Slab had been added successfully',
    update: 'Saving Head has been updated successfully.',
    tax_slab_updated: 'Tax Slab has been updated successfully.',
  },
};

