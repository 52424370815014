import React from 'react';
import { Toast } from '..';

import { downloadData } from '../../AppServices';
import { buttonMappings, StandardButton } from '../buttons/Buttons';
import { getResolvedParamValue } from '../data-fetch/DataFetchFunctions';
import { getSelectionInfo, removeSelection } from './utility';

export const download = ({
  id,
  label,
  disabled,
  preMessage,
  postMessage,
  reloadEvent,
  closeView,
  buttonType,
  outline,
  service,
  type = 'download',
  ...restProps
} = {}) => {
  label = label || 'Download';
  if (!id && typeof label === 'string') {
    id = label;
  }
  if (!buttonType) {
    buttonType = outline ? 'outline' : 'standard';
  }
  return {
    id: label,
    type,
    service,
    preMessage,
    postMessage,
    reloadEvent,
    closeView,
    disabled,
    render: ({ state = {}, disabled } = {}) => {
      let buttonLabel = label;
      if (typeof buttonLabel === 'function') {
        buttonLabel = buttonLabel({ data: state.data });
      }
      const ButtonComponent = buttonMappings[buttonType] || StandardButton;
      return (
        <ButtonComponent
          {...restProps}
          label={buttonLabel}
          disabled={disabled}
        />
      );
    },
    ...restProps,
  };
};

const downloadAction = async (props) => {
  let {
    action: { preMessage, postMessage, closeView, ...restAction },
    screenState,
    setScreenState,
    global_params,
    item,
    eventDispatcher,
    fetchUriEvent,
    navigation,
    state,
    data,
    uri,
  } = props;
  try {
    preMessage && Toast.show(preMessage);
    if (fetchUriEvent && eventDispatcher) {
      // required when action is outside list/form state
      let uriEventInfo = eventDispatcher.notify(fetchUriEvent) || {};
      data = data || uriEventInfo.state?.data;
      uri = uri || uriEventInfo.uri;
    }
    const { selectedIds, allPageSelected, selectedData } = getSelectionInfo({
      screenState,
      data,
      item,
    });
    const globalParamValue = getResolvedParamValue(
      global_params?.dataParams,
      true,
    );
    await downloadData({
      data: item,
      selectedData,
      uri,
      globalParamValue,
      selectedIds,
      allPageSelected,
      state,
      ...restAction,
    });
    removeSelection({ screenState, setScreenState });
    postMessage && Toast.show(postMessage);
    closeView && navigation.pop(closeView);
  } catch (err) {
    Toast.error(err.message || err || 'Error in download');
  }
};

export { downloadAction };
