import React from 'react';
import uuid from 'uuid/v4';

const removeExtraProps = (props, propKeys) => {
  let newProps = {...props};
  for (let key of propKeys) {
    if (newProps.hasOwnProperty(key)) {
      delete newProps[key];
    }
  }
  return newProps;
};

export default Component => {
  class DataLoader extends React.Component {
    propNames = [
      'screenName',
      'reloadEvent',
      'dataParams',
      'getUser',
      'uri',
      'data',
      'DataFetch',
      'onDataError',
      'dataFetchProps',
      'useNavigationState',
      'state',
      'setState',
      'onDataReload',
    ];

    constructor(props) {
      super(props);
      let {screenName = '', useNavigationState, setState} = props;
      let uid = `ReactTable_${screenName}_${uuid()}`;
      let state = {refreshing: false, uid};
      if (useNavigationState && setState) {
        setState(state);
      } else {
        this.state = state;
      }
    }

    _getState = () => {
      const {state, setState, useNavigationState} = this.props;
      let _state = void 0;
      if (useNavigationState && setState) {
        _state = state;
      } else {
        _state = this.state;
      }
      return _state;
    };

    _setState = _state => {
      const {setState, useNavigationState} = this.props;
      if (useNavigationState && setState) {
        setState(_state);
      } else {
        this.setState(_state);
      }
    };

    getUid = () => {
      let state = this._getState();
      return state.uid;
    };

    onLoadMore = e => {
      let {fetchMoreEvent, eventDispatcher} = this.props;
      eventDispatcher &&
        fetchMoreEvent &&
        eventDispatcher.notify(fetchMoreEvent(this.getUid()));
    };

    renderComponent = (dataProps = {}) => {
      let restProps = removeExtraProps(this.props, this.propNames);
      return (
        <Component onLoadMore={this.onLoadMore} {...restProps} {...dataProps} />
      );
    };

    render() {
      let {
        reloadEvent,
        screenName,
        navigation,
        eventDispatcher,
        dataParams,
        getUser,
        uri,
        data,
        DataFetch,
        onDataError,
        dataFetchProps = {},
        loading = false,
        fetchUriEvent,
        onDataReload,
      } = this.props;
      if (data) {
        return this.renderComponent({data, loading});
      } else {
        if (uri) {
          dataFetchProps.uri = uri;
        }
        if (typeof dataFetchProps.uri === 'function') {
          dataFetchProps.uri = uri({user: getUser && getUser(), navigation});
        }
        if (!dataFetchProps.uri) {
          return this.renderComponent({data: void 0, loading});
        }
        return (
          <DataFetch
            fetchUriEvent={fetchUriEvent}
            screenName={`ReactTable_${screenName}`}
            navigation={navigation}
            eventDispatcher={eventDispatcher}
            reloadEvent={reloadEvent}
            dataParams={dataParams}
            state={this._getState()}
            setState={this._setState}
            showError={onDataError}
            onDataReload={onDataReload}
            {...dataFetchProps}>
            {this.renderComponent}
          </DataFetch>
        );
      }
    }
  }
  return DataLoader;
};
