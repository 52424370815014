import React from 'react';
import {View, Text} from '@applane/react-core-components';
import {WithInputWrapper} from '@applane/react-input-wrapper';
import './Slider.css';
import PropTypes from 'prop-types';

class Slider extends React.Component {
  onChangeValue = e => {
    let {onChangeValue, onChange} = this.props;
    onChange && onChange(e);
    let value = e.target.value;
    if (typeof value === 'string') {
      value = Number(value);
    }
    onChangeValue && onChangeValue(value, e);
  };

  render() {
    let {
      containerStyle,
      titleStyle,
      valueStyle,
      title,
      value,
      height = 13,
      min = 0,
      max = 100,
      color,
      borderRadius,
      activeColor,
      thumbColor,
      showValue = true,
      disabled
    } = this.props;
    let diff = 100 / (max - min);
    value = value || min;
    let thumbStyle = {};
    if (thumbColor) {
      thumbStyle.backgroundColor = thumbColor;
    }
    let leftPosition = (value - min) * diff;
    if (leftPosition > 100) {
      leftPosition = 100;
    }
    if (title && typeof title === 'function') {
      title = title({value, percentage: leftPosition});
    }
    let sliderComponent = (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: height,
          marginTop: 4,
        }}>
        <View
          style={{
            borderRadius,
            position: 'absolute',
            width: `calc(100% - ${height}px)`,
            height: '100%',
            backgroundColor: color || '#f9f9f9',
          }}>
          <View
            style={{
              borderRadius,
              width: `${leftPosition}%`,
              height: '100%',
              backgroundColor: activeColor || '#1ed69e',
            }}></View>
          <View
            id="react-slider-button-container"
            style={{
              position: 'absolute',
              height: height + 3,
              width: height + 2,
              transform: 'translateX(-50%)',
              top: -3.5,
              left: `${leftPosition}%`,
            }}>
            <View
              id="react-slider-button"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: 2,
                border: `0.5px solid rgba(0, 0, 0, 0.1)`,
                borderBottomWidth: 0,
                ...thumbStyle,
              }}></View>
          </View>
        </View>
        <input
          id="react-slider"
          type="range"
          min={min}
          disabled={disabled}
          max={max}
          value={value}
          onChange={this.onChangeValue}
          style={{
            border: 'none',
            outline: 'none',
            width: '100%',
            height: '100%',
            margin: 0,
            padding: 0,
          }}
        />
      </View>
    );
    return (
      <View style={containerStyle}>
        <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
          <View
            style={{
              flex: 1,
              overflow: 'hidden',
              paddingLeft: height / 2 + 1,
              paddingRight: height / 2 + 1,
            }}>
            {title && (
              <Text
                numberOfLines={1}
                style={{...titleStyle, marginLeft: height / 2}}>
                {title}
              </Text>
            )}
            {sliderComponent}
          </View>
          {showValue && <Text style={valueStyle}>{`${leftPosition}%`}</Text>}
        </View>
      </View>
    );
  }
}
Slider.propstype = {
  min: PropTypes.number, //minimum range value
  max: PropTypes.number, //maximum range value
  value: PropTypes.number, //value
  style: PropTypes.object, // style of box
};

Slider = WithInputWrapper(Slider);

export default Slider;
