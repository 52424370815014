export default {
  labels: {
    test: 'Test Name',
    testID: 'Test ID',
    status: 'Status',
    labType: 'Lab Type',
    action: 'Action',
    charge: 'Charge',
    testName: 'Test Name',
    labName: 'Lab Name',
    description: 'Description',
    lab: 'Lab Name',
    labID: 'Lab ID',
    room: 'Room No Allocated',
    employee: 'Employees',
    department: 'Department',
    createdOn: 'Created On',
    rooms: 'Rooms',
    componentTest: 'Test Components',
    manualTest: 'This is a Manual Test',

  },
  title: {
    testManagement: 'Test Management',
    labManagement: 'Lab Management',
    confirmModalTitle: 'Are you sure want to remove manual components?',
  },
  buttons: {
    addTest: 'Add Test',
    editTest: 'Edit Test',
    addLab: 'Add Lab',
    editLab: 'Edit Lab',
    exportToExcel: 'Export To Excel',
    confirm: 'Confirm',
  },
  placeholders: {
    selectDepartment: 'Select Department',
    selectLab: 'Select Lab Type',
    selectRooms: 'Select Rooms',
    componentTest: 'componentTest',
    enterAmount: 'Enter Amount',
  },
  messages: {
    testAddedSuccessfully: 'Test has been Added successfully.',
    testUpdatedSuccessfully: 'Test has been Updated successfully.',
    labAddedSuccessfully: 'Lab has been Added successfully.',
    labUpdatedSuccessfully: 'Lab has been Updated successfully.',
    labAlreadyExists: 'Lab Already Exists',
    testAlreadyExists: 'Test Already Exists',
  },
};
