import React from 'react';
import {Text} from '@applane/react-core-components';

const CardHeader = props => {
 
  return <Text >Header</Text>;
};

CardHeader.defaultProps = {

  titleStyle: {
    fontSize: 14,
    color: '#000',
  },
};

export default CardHeader;
