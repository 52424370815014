import React from 'react';
import {Image, View, Text, Platform} from '@applane/react-core-components';
import PropsType from 'prop-types';

const resolveAvatarValue = value => {
  if (!value || typeof value !== 'string') {
    return '';
  }
  value = value.split(' ');
  let avatarValue = '';
  if (value.length) {
    for (let key of value) {
      avatarValue += key.substring(0, 1);
      if (avatarValue.length === 2) {
        break;
      }
    }
  }
  return avatarValue.toUpperCase();
};

const Avatar = props => {
  let {
    title,
    icon,
    resolveIcon,
    value,
    defaultIcon,
    defaultText,
    size,
    variant = 'circular',
    iconStyle,
    textStyle,
    children,
    isActive,
    indicatorStyle,
    backgroundColor,
    borderColor,
    borderWidth,
    resizeMode = 'cover',
  } = props;
  if (icon) {
    if (typeof icon === 'function') {
      icon = icon(props);
    }
    if (icon && resolveIcon) {
      icon = resolveIcon(icon);
    }
  }
  if (typeof value === 'function') {
    value = value(props);
  }
  if (icon === undefined && value === undefined) {
    icon = defaultIcon;
    value = defaultText;
  }
  if (icon === undefined && value === undefined) {
    return null;
  }
  if (typeof title === 'function') {
    title = title(props);
  } else if (title === undefined) {
    title = value;
  }
  if (typeof title !== 'string') {
    try {
      title = JSON.stringify(title);
    } catch (err) {
      title = void 0;
    }
  }
  let style =
    (size && typeof size === 'string' && props[`${size}Style`]) ||
    props['style'];
  style = {
    ...style,
    alignItems: 'center',
    justifyContent: 'center',
  };
  if (size && typeof size === 'number') {
    style.width = size;
    style.height = size;
    style.borderRadius = size / 2;
  }
  if (variant === 'square') {
    delete style.borderRadius;
  }
  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }
  if (borderColor) {
    style.borderColor = borderColor;
  }
  if (borderWidth) {
    style.borderWidth = borderWidth;
  }

  const source = typeof icon === 'string' ? {uri: icon} : icon;
  if (Platform.OS !== 'web' && source?.uri) {
    iconStyle = {width: '100%', height: '100%', ...iconStyle};
    resizeMode = 'contain';
    style = {overflow: 'hidden', ...style};
  }

  let avatarComponent = (
    <View style={style}>
      {children ||
        (icon ? (
          <Image
            title={title}
            style={{
              maxHeight: style.height,
              maxWidth: style.width,
              borderRadius: style.borderRadius,
              overflow: 'hidden',
              ...iconStyle,
            }}
            alt={false}
            source={source}
            resizeMode={resizeMode}
          />
        ) : (
          <Text title={title} style={textStyle}>
            {resolveAvatarValue(value)}
          </Text>
        ))}
      {isActive && indicatorStyle && <View style={{...props.indicatorStyle}} />}
    </View>
  );
  return avatarComponent;
};

export const AvatarGroup = props => {
  let {
    avatarData: data = [],
    maxAvatar,
    maxAvatarStyle,
    style = {},
    size,
    skipzIndex,
    ...restProps
  } = props || {};
  let avatarData = [];
  if (maxAvatar && data?.length > maxAvatar) {
    avatarData = data.splice(0, maxAvatar);
    avatarData.push({value: `+ ${data.length}`, ...maxAvatarStyle});
  } else {
    avatarData = data;
  }
  return (
    <View style={{display: 'flex', flexDirection: 'row'}}>
      {avatarData?.length &&
        avatarData.map((item, index) => {
          return (
            <Avatar
              key={index}
              {...restProps}
              {...item}
              size={size}
              style={{
                ...style,
                ...(skipzIndex ? {} : {zIndex: avatarData.length - index}),
                marginLeft:
                  index === 0 ? 0 : -(parseInt(size || style.width || 0) / 5),
              }}
            />
          );
        })}
    </View>
  );
};

AvatarGroup.prototype = {
  style: PropsType.object.isRequired, // style of avatar
  indicatorStyle: PropsType.object, //  indicator style
  children: PropsType.node, //  children of Avatar
};

Avatar.propsType = {
  style: PropsType.object.isRequired, // style of avatar
  indicatorStyle: PropsType.object, //  indicator style
  children: PropsType.node, //  children of Avatar
  source: PropsType.string, //  source of image of avatar
};

Avatar.defaultProps = {
  variant: 'circular',
  resizeMode: 'cover',
  backgroundColor: '#e8eeff',
  style: {
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  smStyle: {
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  mdStyle: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  lgStyle: {
    width: 70,
    height: 70,
    borderRadius: 35,
  },
};

export default Avatar;
