import {LeftIcon, LeftLabel, TopLabel} from '@applane/react-input-wrapper';
import React from 'react';
import { leftIconStyle, leftLabelStyle, topLabelStyle } from '../../../theme/columnContainerTheme';

const LeftIconContainer = props => <LeftIcon {...leftIconStyle} {...props} />;
const LeftLabelContainer = props => (
  <LeftLabel {...leftLabelStyle} {...props} />
);
const TopLabelContainer = props => <TopLabel {...topLabelStyle} {...props} />;

export default {
  leftIcon: LeftIconContainer,
  leftLabel: LeftLabelContainer,
  topLabel: TopLabelContainer,
};
