export default {
  labels: {
    uhid: 'UHID',
    patientName: 'Patient Name',
    relation: 'Relation',
    age: 'Age',
    gender: 'Gender',
    bloodGroup: 'Blood Group',
    unitRequired: 'Unit Required',
    unitRequested: 'Unit Requested',
    dateIssue: 'Date Issue',
    reason: 'Type the reason here...',
    doctorName: 'Doctor Name',
    category: 'Category',
    unitInStocks: 'Unit In Stocks',
    addNewItem: '+ Add New Item',
    time: 'Time Of Issue',
    file: 'Drag & Drop Your Files Here',
    bagNo: 'Bag No.',
    donationNo: 'Donation No.',
    tubeNo: 'Tube No.',
    donationDate: 'Donation Date',
    remainingSelfLife: 'Remaining Self Life',
    bloodGroups: 'Blood Group',
    bagWeight: 'Bag Weight',
    testStatus: 'Test Status',
    expiryStatus: 'Expiry Status',
    result: 'Result',
    bagStatus: 'Bag Status',
    expiryDate: 'Expiry Date',
    donorName: 'Donor Name',
    status: 'Status',
    remarks: 'Remarks',
    reasonOfIssue: 'Reason of Issue',
    dateOfIssue: 'Date Of Issue',
    timeOfIssue: 'Time Of Issue',
    department: 'Department',
    speciality: 'Speciality',
    doctor: 'Doctor Name',
    addRow: 'Add Row',
    UHID: 'UHID',
    bloodGroupName: 'Blood Group Name',
    additionalInfo: 'Additional Information',
    attachments: 'Attachments',
  },
  headers: {
    bloodCategory: 'Blood Category Name',
    bloodGroup: 'Blood Group Name',
    unitInStocks: 'Unit In Stocks',
    status: 'Status',
    action: 'Action',
    bagNo: 'Bag No.',
    donationNo: 'Donation No.',
    tubeNo: 'Tube No.',
    donationDate: 'Donation Date',
    remainingSelfLife: 'Remaining Self Life',
    bloodGroups: 'Blood Group',
    bagWeight: 'Bag Weight',
    testStatus: 'Test Status',
    expiryStatus: 'Expiry Status',
    result: 'Result',
    bagStatus: 'Bag Status',
    expiryDate: 'Expiry Date',
    donorName: 'Donor Name',
    category: 'category',
  },
  placeholders: {
    uhid: 'UHID',
    patientName: 'Patient Name',
    relation: 'Relation',
    age: 'Age',
    gender: 'Gender',
    bloodGroup: 'Blood Group',
    unitRequired: 'Unit Required',
    unitRequested: 'Unit Requested',
    unitProvided: 'Unit Provided',
    dateIssue: 'Date Issue',
    reason: 'Reason',
    doctorName: 'Doctor Name',
    category: 'Category',
    unitInStocks: 'Unit In Stocks',
    file: 'Drag & Drop Your Files Here',
  },
  title: {
    bloodBagDetails: 'Blood Bag Details',
    bloodBagDescription: 'Status and test status of the blood bag to edit.',
  },
  buttons: {
    save: 'Save',
    issueBlood: 'Issue Blood',
    issueToCompany: 'Issue To Company',
  },
  messages: {
    bloodBagAdd: 'Blood bag Details has been added successfully.',
    bloodBagUpdate: 'Blood bag details has been updated successfully.',
    issueBloodMessage: 'Blood bag has been issued successfully.',
    approveAndIssue: 'Request has been approved.',
    rejectRequest: 'Blood request has been rejected.',
    bloodRequest: 'Blood bag has been requested successfully.',
  },
};
