import React from 'react';
import {WithInputWrapper, WithInputError} from '@applane/react-input-wrapper';
import SwitchButton from './SwitchButton';

class Switch extends React.Component {
  render() {
    return <SwitchButton {...this.props} />;
  }
}

export default WithInputWrapper(WithInputError(Switch));
