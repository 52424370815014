import React from 'react';
import Dashboard from './Dashboard';
import Assistant from './Assistant';
import Graph from './Graph';
import EmergencyRoom from './EmergencyRoom';
import InstantConsultation from './InstantConsultation';
import { ROUTES } from '../../../Lib/constants';
import HistoryTabs from './HistoryTabs';
import { Fts, isMobile, View } from '../../../app-components';
import { h18_Medium } from '../../../theme/fonts';
import DoctorSearchList from './doctorSearch/DoctorSearchList';
import { colors } from '../../../theme/mayaMdColors';
import DoctorProfile from './doctorSearch/DoctorProfile';
import Booking from './doctorSearch/Booking';
import ViewConsultedPatientHistory from './viewConsulation';

const styles = {
  headerContainer: {
    backgroundColor: colors.themeColor,
    flexDirection: 'row',
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 40,
    paddingRight: 30,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 0,
  },
  headerText: {
    paddingLeft: 27,
    ...h18_Medium,
  },
};

export default {
  [ROUTES.dashboard.name]: {
    screen: Dashboard,
  },
  [ROUTES.healthAssistant.name]: {
    screen: Assistant,
  },
  'graph-image': {
    screen: Graph,
    expanded: true,
  },
  [ROUTES.history.name]: {
    screen: HistoryTabs,
    expanded: true,
  },
  WaitingRoom: {
    screen: InstantConsultation,
    expanded: true,
  },
  EmergencyRoom: {
    screen: EmergencyRoom,
    expanded: true,
  },
  [ROUTES.doctorSearch.name]: {
    renderHeader: (props) => {
      const { navigation, screenState } = props;
      const { setScreenState } = navigation;
      const routes = navigation.getActiveRoutes();
      const firstRoute = routes[0] || [];
      if (
        (firstRoute !== 'booking' && routes?.length > 1)
        || routes?.indexOf('booking') !== -1
      ) {
        return null;
      }
      return (
        <View style={styles.headerContainer}>
          {isMobile ? null : (
            <View style={{ width: 500, borderRadius: 20 }}>
              <Fts
                placeholder="Search By Doctor"
                screenState={screenState}
                setScreenState={setScreenState}
                action={{ exp: ['name'] }}
              />
            </View>
          )}
        </View>
      );
    },
    screen: DoctorSearchList,
  },
  [ROUTES.doctorProfile.name]: {
    screen: DoctorProfile,
    public: true,
  },
  [ROUTES.slotBooking.name]: {
    screen: Booking,
    public: true,
  },
  [ROUTES.viewConsultationPatient.name]: {
    screen: ViewConsultedPatientHistory,
    expanded: true,
  },
};
