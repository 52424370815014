export default {
  labels: {
    name: 'Name',
    code: 'Code',
    description: 'Description',
  },
  buttons: {
    addZipCode: 'Add ZipCode',
    bulkUpload: 'Bulk Upload',
    addCity: 'Add City',
    addDepartment: 'Add Department',
    addRole: 'Add Role',
    addState: 'Add State',
  },
};
