import React, { useState } from 'react';
import {
  View,
  TouchableOpacity,
  Dimensions,
  Text,
} from '../../../app-components';
import { logout } from '../../../AppServices';
import { ROUTES } from '../../../Lib/constants';
import { colors } from '../../../theme/colors';
import { h14_Medium as h14Medium } from '../../../theme/fonts';

const { themeColor } = colors;
const { width } = Dimensions.get('window');
const ProfileDropdown = (props) => {
  const {
    frameStyle,
    navigation = {},
    hideModal = () => {},
    userData = {},
    revertColor = false,
  } = props || {};
  const [hover, setHover] = useState('');
  return (
    <View
      style={{
        ...frameStyle,
        padding: 5,
        borderRadius: 8,
        backgroundColor: '#fff',
        border: revertColor ? `1px solid ${themeColor}` : undefined,
      }}
    >
      {[
        { label: 'Profile', value: 'profile' },
        { label: 'Logout', value: 'logout' },
      ].map((item, index) => (
        <TouchableOpacity
          key={index}
          onMouseEnter={() => {
            setHover(item.value);
          }}
          onMouseLeave={() => {
            setHover('');
          }}
          onPress={() => {
            if (item.value === 'profile') {
              navigation.push({
                view: ROUTES.history.name,
                params: { items: userData },
              });
            } else {
              logout().then(() => {
                navigation.reset('authenticator');
                hideModal();
              });
            }
            hideModal();
          }}
          style={{
            marginBottom: 5,
            backgroundColor: item.value === hover ? themeColor : '#fff',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: 'white',
            cursor: 'pointer',
            minHeight: 30,
            borderRadius: 15,
          }}
        >
          <Text
            style={{
              color: item.value === hover ? '#fff' : themeColor,
              ...h14Medium,
              alignSelf: 'center',
            }}
          >
            {item.label}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default ProfileDropdown;
