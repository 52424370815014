/* eslint-disable camelcase */
import { colors, bgs } from './colors';
import { colors as mayaMdColors } from './mayaMdColors';
import { h14_Regular, h14_SemiBold } from './fonts';
import {
  descendingSortActiveIcon,
  descendingSortUnActiveIcon,
  ascendingSortUnActiveIcon,
  ascendingSortActiveIcon,
} from '../images';
import { manazeV6Colors } from './manazeV6Colors';
import { toolBarTheme } from './toolBarTheme';
import { Headline14, Headline15, Headline9 } from './Headlines';
import vars from './vars';

const {
  neutral_1,
  neutral_4,
  surface_white,
  neutral_10,
  primary_5,
  primary_2,
} = manazeV6Colors;
const { colors: mamcColors, headings } = vars;

const { textColor40, textColor87, tableHoverColor } = colors;
const { themeColor, themeContainer } = mayaMdColors;
const { borderColor20 } = bgs;

export const sortableHeaderTheme = {
  containerStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  viewStyle: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  textStyle: {
    ...headings.h9,
    color: mamcColors.grey.color1,
    numberOfLines: 1,
  },
  imageTheme: {
    iconStyle: {
      height: 11,
      objectFit: 'cover',
    },
    imageContainerStyle: {
      marginLeft: 10,
      marginRight: 5,
      flexDirection: 'row',
      alignItems: 'center',
    },
    descendingSortActiveIcon,
    descendingSortUnActiveIcon,
    ascendingSortUnActiveIcon,
    ascendingSortActiveIcon,
  },
};

export const customHeaderCellTheme = {
  containerStyle: {},
  viewStyle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarStyle: {
    size: 24,
    backgroundColor: primary_5,
    textStyle: { ...Headline15, color: primary_2 },
  },
  leftContainerStyle: {},
  leftItemContainerStyle: {
    alignItems: 'center',
    marginRight: 12,
  },
};
export const numberCellTheme = {
  textStyle: {
    ...h14_Regular,
    color: textColor87,
    numberOfLines: 1,
    textAlign: 'right',
  },
};

export const textCellTheme = {
  textStyle: {
    ...h14_Regular,
    color: textColor87,
    numberOfLines: 1,
  },
};

export const linkCellTheme = {
  textStyle: {
    ...h14_Regular,
    color: vars.colors.secondary.color2,
    numberOfLines: 1,
  },
};

export const numberHeaderCellTheme = {
  textStyle: {
    ...h14_SemiBold,
    color: textColor40,
    numberOfLines: 2,
  },
  secondaryTextStyle: {
    ...Headline15,
    color: neutral_4,
    numberOfLines: 1,
  },
};
export const headerCellTheme = {
  textStyle: {
    ...h14_SemiBold,
    color: textColor40,
    numberOfLines: 2,
  },
  secondaryTextStyle: {
    ...Headline15,
    color: neutral_4,
    numberOfLines: 1,
  },
};
export const textHeaderCellTheme = {
  ...headerCellTheme,
};
export const numberEditableHeaderCellTheme = {
  ...headerCellTheme,
};

export const selectionCellTheme = {};
export const selectionHeaderCellTheme = {
  viewStyle: {
    paddingRight: 0,
  },
};

export const tableTheme = {
  leftGap: 26,
  rightGap: 6,
  cardScrollViewStyle: {
    flex: 1,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
    paddingBottom: 12,
  },
  textStyle: null,
  headerRowContainerStyle: {
    height: 56,
    marginLeft: 26,
    marginRight: 6,
    backgroundColor: themeColor,
  },
  headerRowColumnContainerStyle: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center',
  },
  headerRowColumnIconStyle: {
    width: 16,
    height: 16,
    marginRight: 12,
  },
  headerRowColumnTextStyle: {
    ...headings.h16,
    color: '#fff',
    textTransform: 'uppercase',
  },
  rowStyle: {
    cardWrapperStyle: {
      marginTop: 2,
      marginBottom: 2,
    },
    rowContainerStyle: {
      minHeight: 48,
      paddingLeft: 0,
      marginLeft: 26,
      marginRight: 6,
      borderBottomColor: borderColor20,
      borderBottomWidth: 1,
    },
    rowSelectedContainerStyle: {
      backgroundColor: themeContainer,
      borderRadius: 4,
    },
    rowHoverContainerStyle: {
      backgroundColor: themeContainer,
      borderRadius: 4,
    },
    rowColumnContainerStyle: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      justifyContent: 'center',
    },
    rowColumnTextStyle: {
      ...headings.h9,
      color: '#fff',
      numberOfLines: 1,
      paddingRight: 8,
    },
    hoverActionsWrapperStyle: {
      top: 1,
      bottom: 1,
      right: 1,
      backgroundColor: tableHoverColor,
    },
    selectedHoverActionsWrapperStyle: {
      backgroundColor: tableHoverColor,
    },
    hoverActionsContainerStyle: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: 8,
    },
  },
  typeWiseWidth: {
    number: {
      width: 100,
    },
    selection: {
      width: 50,
    },
    custom: {
      width: 100,
    },
    moreActions: {
      width: 30,
      height: 30,
    },
  },
};

export const tableMobileTheme = {
  leftGap: 6,
  rightGap: 6,
  cardScrollViewStyle: {
    flex: 1,
    // backgroundColor: surface_white,
  },
  headerRowContainerStyle: {
    height: 34,
    paddingLeft: 6,
    paddingRight: 6,
    // backgroundColor: '#FAFAFA',
  },
  headerRowColumnContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
  },
  headerRowColumnIconStyle: {
    width: 16,
    height: 16,
    marginRight: 12,
  },
  headerRowColumnTextStyle: {
    ...h14_Regular,
    color: textColor40,
    numberOfLines: 1,
  },
  rowStyle: {
    rowContainerStyle: {
      minHeight: 48,
      paddingLeft: 6,
      paddingRight: 6,
      backgroundColor: '#ffffff',
    },
    rowColumnContainerStyle: {
      paddingLeft: 6,
      paddingRight: 6,
      paddingTop: 8,
      paddingBottom: 8,
      justifyContent: 'center',
    },
    rowColumnTextStyle: {
      ...h14_Regular,
      color: textColor87,
      numberOfLines: 1,
    },
  },
  typeWiseWidth: {
    number: {
      width: 100,
    },
    selection: {
      width: 50,
    },
    custom: {
      width: 100,
    },
    moreActions: {
      width: 30,
    },
  },
};

export const NestedTableFormTheme = {
  ...tableTheme,
  leftGap: 6,
  rightGap: 6,
  containerStyle: {
    marginTop: 8,
    // borderRadius: 8,
  },
  headerRowContainerStyle: {
    minHeight: 32,
    paddingLeft: 12,
    paddingRight: 4,
    backgroundColor: 'red',
  },
  headerRowColumnTextStyle: {
    ...vars.headings.h13,
    color: vars.colors.grey.color3,
    numberOfLines: 1,
  },
  rowWrapperStyle: {
    // backgroundColor: surface_white,
    paddingLeft: 6,
    paddingRight: 6,
  },
  rowStyle: {
    ...tableTheme.rowStyle,
    rowContainerStyle: {
      paddingTop: 6,
      paddingBottom: 6,
    },
    rowSelectedContainerStyle: {},
    rowHoverContainerStyle: {},
    rowColumnContainerStyle: {
      paddingLeft: 6,
      paddingRight: 6,
      // marginRight: 2,
      // justifyContent: 'center',
      // backgroundColor: vars.colors.white,
    },
    rowColumnTextStyle: {
      ...vars.headings.h9,
      color: vars.colors.grey.color2,
      numberOfLines: 1,
    },
  },
};
export const NestedTableMobileFormTheme = {
  ...tableMobileTheme,
  headerRowContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
  },
  rowStyle: {
    ...tableMobileTheme.rowStyle,
    rowWrapperStyle: {
      backgroundColor: surface_white,
      paddingLeft: 6,
      paddingRight: 6,
    },
    rowContainerStyle: {
      paddingTop: 6,
      paddingBottom: 6,
      minHeight: 32,
    },
    rowSelectedContainerStyle: {},
    rowHoverContainerStyle: {},
    rowColumnContainerStyle: {
      paddingLeft: 6,
      paddingRight: 6,
      justifyContent: 'center',
    },
    rowColumnTextStyle: {
      ...Headline9,
      color: neutral_1,
      numberOfLines: 1,
    },
  },
};
export const tableFooterTheme = {
  ...toolBarTheme,
};
export const tableHeaderTheme = {
  containerStyle: {
    height: 62,
    paddingLeft: 32,
    paddingRight: 30,
    justifyContent: 'center',
    backgroundColor: mamcColors.white,
    borderRadius: '8px 8px 0px 0px',
    borderBottom: '1px solid #F2F2F2',
  },
  itemsStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleTextStyle: {
    ...headings.h5,
    color: mamcColors.primary.color1,
    fontWeight: 500,
  },
  subTitleTextStyle: {
    paddingTop: 3,
    paddingLeft: 12,
    paddingRight: 12,
  },
  actionContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionStyle: {
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 8,
      marginLeft: 4,
    },
    activeContainerStyle: {
      borderRadius: 4,
    },
    iconStyle: {
      height: 20,
      width: 20,
      objectFit: 'contain',
      resizeMode: 'contain',
    },
  },
};

export const cardCellTheme = {
  style: {},
  primaryTitleStyle: {
    ...Headline9,
    color: neutral_1,
    numberOfLines: 1,
    fontWeight: 400,
  },
  contentContainerStyle: {},
  contentItemTextStyle: {
    ...Headline14,
    color: neutral_4,
  },
};

export const fileCellTheme = {
  linkTextStyle: {
    textDecoration: 'underline',
    color: 'gray',
  },
};
export const customTableTheme = {
  ...tableTheme,
  dataRowStyle: {
    containerStyle: {
      ...tableTheme.containerStyle,
      height: 73,
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
};

export const editableTableTheme = {
  ...tableTheme,
  rowStyle: {
    ...tableTheme.rowStyle,
    rowWrapperStyle: {
      backgroundColor: surface_white,
      paddingLeft: 6,
      paddingRight: 6,
    },
    rowContainerStyle: {
      paddingTop: 6,
      paddingBottom: 6,
      minHeight: 32,
    },
    rowSelectedContainerStyle: {},
    rowHoverContainerStyle: {},
    rowColumnContainerStyle: {
      paddingLeft: 6,
      paddingRight: 6,
      justifyContent: 'center',
    },
    rowColumnTextStyle: {
      ...Headline9,
      color: neutral_1,
      numberOfLines: 1,
    },
  },
};
export const groupListTheme = {
  ...tableTheme,
  headerRowContainerStyle: {
    ...tableTheme.headerRowContainerStyle,
    backgroundColor: neutral_10,
  },
  rowWrapperStyle: {
    ...tableTheme.rowWrapperStyle,
    backgroundColor: neutral_10,
  },
  rowStyle: {
    ...tableTheme.rowStyle,
    rowHoverContainerStyle: {
      ...tableTheme.rowStyle.rowHoverContainerStyle,
      backgroundColor: neutral_10,
    },
  },
  lastRowBottomStyle: {
    ...tableTheme.lastRowBottomStyle,
    backgroundColor: neutral_10,
  },
};
export const groupListMobileTheme = {
  ...tableMobileTheme,
  headerRowContainerStyle: {
    ...tableMobileTheme.headerRowContainerStyle,
    backgroundColor: neutral_10,
  },
  rowWrapperStyle: {
    ...tableMobileTheme.rowWrapperStyle,
    backgroundColor: neutral_10,
  },
  rowStyle: {
    ...tableMobileTheme.rowStyle,
    rowHoverContainerStyle: {
      ...tableMobileTheme.rowStyle.rowHoverContainerStyle,
      backgroundColor: neutral_10,
    },
  },
  lastRowBottomStyle: {
    ...tableMobileTheme.lastRowBottomStyle,
    backgroundColor: neutral_10,
  },
};

export const groupCardListTheme = {
  ...groupListTheme,
  containerStyle: {},
  rowSeparatorPosition: false,
  rowWrapperStyle: {
    borderRadius: 8,
    backgroundColor: '#ffffff',
    margin: 8,
  },
};

export const groupCardListMobileTheme = {
  ...groupListMobileTheme,
  containerStyle: {},
  rowSeparatorPosition: false,
  rowWrapperStyle: {
    borderRadius: 8,
    backgroundColor: '#ffffff',
    margin: 8,
  },
};
