import { manazeV5Colors } from './manazeV5Colors';
import { manazeV6Colors } from './manazeV6Colors';
import { colors } from './mayaMdColors';

const { Surface } = manazeV5Colors;
const { neutral_9, neutral_10, surface_white } = manazeV6Colors;

export const stackTheme = {
  containerStyle: {
    userSelect: 'none',
    backgroundColor: surface_white,
  },
  itemStyle: {
    viewStyle: {
      flex: 1,
      overflow: 'hidden',
      backgroundColor: colors.themeColor,
    },
    singleViewStyle: {},
    sideViewStyle: {
      paddingLeft: 8,
    },
    animationType: 'left',
  },
  modalStyle: {
    position: 'screenCenter',
    backgroundColor: Surface,
    screenCenterStyle: {
      // flex: 1,
      overflow: 'hidden',
      borderRadius: 8,
      maxHeight: '90%',
      maxWidth: '90%',
    },
    fullScreenStyle: { flex: 1 },
    style: {
      borderRadius: 8,
      width: 300,
      height: 200,
      margin: 8,
    },
    backdropStyle: {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
  },
};

export const stackMobileTheme = {
  containerStyle: {
    userSelect: 'none',
    backgroundColor: neutral_9,
  },
  itemStyle: {
    viewStyle: {
      flex: 1,
      overflow: 'hidden',
      backgroundColor: neutral_10,
    },
    singleViewStyle: {},
    animationType: 'left',
  },
  modalStyle: {
    position: 'screenBottom',
    backgroundColor: Surface,
    backdropStyle: {
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    screenCenter: {
      borderRadius: 8,
    },
    screenBottomStyle: {
      height: '70%',
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
    },
    style: {
      borderRadius: 8,
      width: 300,
      height: 200,
      margin: 8,
    },
    animationType: 'slide',
  },
};
