import io from 'socket.io-client';

export default class GpsStore {
  constructor(config, options = {}) {
    this.config = config;
    this.options = options;
    this.subscriptions = {};
    this.ticketSubscriptions = {};
    this.notificationSubscriptions = {};
    this.events = {};
  }

  trackUserEvent = info => {
    let {trackUserEvents} = this.options || {};
    trackUserEvents && trackUserEvents(info);
  };

  getNotificationSubscriptions = () => {
    return this.notificationSubscriptions;
  };
  getTicketSubscriptions = () => {
    return this.ticketSubscriptions;
  };

  getSubscriptions = () => {
    return this.subscriptions;
  };

  setUser = user => {
    this.user = user;
  };

  getInfo = (props = {}, propName) => {
    let {imei} = props;
    const subscriptions = this.getSubscriptions();
    let imeiInfo = imei && subscriptions[imei];
    if (!imeiInfo) {
      return;
    }
    return propName ? imeiInfo[propName] : imeiInfo;
  };
  setInfo = ({imei, info}) => {
    const subscriptions = this.getSubscriptions();
    let imeiInfo = imei && subscriptions[imei];
    if (imeiInfo) {
      imeiInfo = {...imeiInfo, ...info};
      subscriptions[imei] = imeiInfo;
    }
  };

  isSubscribed = props => {
    return this.getInfo(props, 'subscribed');
  };

  emitData = (event, data, callback) => {
    this.socket && this.socket.emit(event, data, callback);
  };

  // register event on Connect to join group
  emitDataOnConnect = ({uid, event, data, unsubscribeEvent, callback}) => {
    if (!uid) {
      throw new Error('Uid is required when emitDataOnConnect called');
    }
    this.emitEventsOnConnect = this.emitEventsOnConnect || [];
    this.emitEventsOnConnect.push({
      uid,
      event,
      unsubscribeEvent,
      data,
      callback,
    });
    this.socket &&
      this.socket.connected &&
      this.socket.emit(event, data, callback);
  };

  // unregister event on Connect to join group
  removeEmitDataOnConnect = ({uid}) => {
    if (!uid) {
      throw new Error('Uid is required when removeEmitDataOnConnect called');
    }
    this.emitEventsOnConnect = this.emitEventsOnConnect || [];
    this.emitEventsOnConnect = this.emitEventsOnConnect.filter(
      ({uid: _uid, unsubscribeEvent, data}) => {
        if (uid === _uid) {
          if (unsubscribeEvent) {
            this.emitData(unsubscribeEvent, data);
          }
          return false;
        } else {
          return true;
        }
      },
    );
  };

  fireConnectionListeners = () => {
    this.connectionCallbacks &&
      this.connectionCallbacks.forEach(({callback}) => {
        callback({socket: this.socket});
      });
  };

  subscribeConnection = ({uid, callback}) => {
    this.connectionCallbacks = this.connectionCallbacks || [];
    this.connectionCallbacks.push({uid, callback});
  };

  unsubscribeConnection = ({uid, callback}) => {
    this.connectionCallbacks = this.connectionCallbacks || [];
    this.connectionCallbacks = this.connectionCallbacks.filter(
      ({uid: _uid}) => {
        return uid !== _uid;
      },
    );
  };

  disconnectSocket = () => {
    const subscriptions = this.getSubscriptions();
    for (var key in subscriptions) {
      subscriptions[key] = {...subscriptions[key], subscribed: false};
    }
    this.socket && this.socket.close();
  };

  connectSocket = () => {
    this.socket && this.socket.open();
  };

  getUpcomingStop = (imeiInfo = {}, coveredStopsId) => {
    let {stops = []} = imeiInfo;

    let stopindexes = stops.reduce((accum, item, index) => {
      if (item._id) {
        accum[item._id] = index;
        return accum;
      }
    }, {});

    let lastCoveredStopIndex = -1;
    for (let key in coveredStopsId) {
      let index = stopindexes[key];
      if (index !== undefined) {
        if (index > lastCoveredStopIndex) {
          lastCoveredStopIndex = index;
        }
      }
    }
    return {index: lastCoveredStopIndex + 1};
  };
  subscribeSocket = subscribeInfo => {
    this.socket.emit('subscribe', subscribeInfo, (err, data) => {
      let {imei} = data || {};

      let subscriptions = this.getSubscriptions();
      if (imei && subscriptions[imei]) {
        let imeiInfo = subscriptions[imei];
        let coveredStopsId = {};
        if (data && data.coveredStops && data.coveredStops.length) {
          data.coveredStops.forEach((key, index) => {
            if (key.stop && key.stop._id) {
              coveredStopsId[key.stop._id] = key;
            }
          });
        }
        let upcomingStop = this.getUpcomingStop(imeiInfo, coveredStopsId);
        subscriptions[imei] = {
          ...imeiInfo,
          ...data,
          coveredStopsId,
          upcomingStop,
          subscribed: true,
        };
        let views = imeiInfo.__views__;
        if (views) {
          for (var uid in views) {
            let {onLocationChange, onSubscribe} = views[uid] || {};
            onSubscribe && onSubscribe();
            onLocationChange && onLocationChange();
          }
        }
      }
    });
  };

  unSubscribeSocket = subscribeInfo => {
    this.socket && this.socket.emit('unsubscribe', subscribeInfo);
  };

  connectToSocket = () => {
    let {url, getGpsUrl} = this.config;
    let _url = url;
    if (getGpsUrl) {
      _url = getGpsUrl();
    }
    this.socket = io.connect(_url, {
      transports: ['polling'],
      reconnection: true,
      // secure: true
    });
    this.socket.on('connect', _ => {
      let subscriptions = this.getSubscriptions();
      for (var imei in subscriptions) {
        this.subscribeSocket({imei});
      }
      let ticketSubscriptions = this.getTicketSubscriptions();
      for (var ticketId in ticketSubscriptions) {
        this.subscribeTicketSocket({ticketId});
      }

      let notificationSubscriptions = this.getNotificationSubscriptions();
      for (var userId in notificationSubscriptions) {
        this.subscribeNotificationSocket({userId});
      }
      this.subscribeUnAssignedTicketSocket();
      this.trackUserEvent({
        event: 'GpsStore Socket Connect',
        eventData: {
          id: this.socket.id,
        },
        user: this.user,
      });
      if (this.events) {
        Object.keys(this.events).forEach(event => {
          let callbacks = this.events[event] || [];
          for (let index = 0; index < callbacks.length; index++) {
            let {callback} = callbacks[index] || {};
            if (callback) {
              this.subscribeSocketEvent(event, callback);
            }
          }
        });
      }

      // fire events this.emitEventsOnConnect so that we can join groups again if re connect
      if (this.emitEventsOnConnect) {
        this.emitEventsOnConnect.forEach(({event, data, callback}) => {
          this.socket && this.socket.emit(event, data, callback);
        });
      }

      this.fireConnectionListeners();
    });

    this.socket.on('onUnAssignedTicketChanged', () => {
      let {callback} = this.unAssignedTicketSubscription || {};
      if (callback) {
        callback({changed: true});
      }
    });

    this.socket.on('onNotification', data => {
      let {userId} = data || {};
      let notificationSubscriptions = this.getNotificationSubscriptions();

      let callbacks =
        userId &&
        notificationSubscriptions &&
        notificationSubscriptions[userId];
      if (callbacks) {
        callbacks.forEach(({callback}) => {
          if (callback) {
            callback(data);
          }
        });
      }
    });

    this.socket.on('onLiveChat', data => {
      let {ticketId} = data || {};
      let ticketSubscriptions = this.getTicketSubscriptions();

      let callbacks =
        ticketId && ticketSubscriptions && ticketSubscriptions[ticketId];
      if (callbacks) {
        callbacks.forEach(({callback}) => {
          if (callback) {
            callback(data);
          }
        });
      }
    });

    this.socket.on('location', data => {
      let {imei, location} = data || {};
      let subscriptions = this.getSubscriptions();
      if (imei && subscriptions[imei]) {
        let imeiInfo = subscriptions[imei];
        if (location && location.coveredStop && location.coveredStop._id) {
          let {coveredStopsId} = imeiInfo;
          let newCoveredStops = {
            ...coveredStopsId,
            [location.coveredStop._id]: {
              ...location.coveredStop,
              arrivalTime: location.serverTime,
            },
          };
          let upcomingStop = this.getUpcomingStop(imeiInfo, newCoveredStops);
          imeiInfo = {
            ...imeiInfo,
            coveredStopsId: newCoveredStops,
            upcomingStop,
          };
        }

        subscriptions[imei] = {
          ...imeiInfo,
          ...data,
        };
        let views = imeiInfo.__views__;
        if (views) {
          for (var uid in views) {
            let {onLocationChange} = views[uid] || {};
            onLocationChange && onLocationChange();
          }
        }
      }
    });
    this.socket.on('disconnect', err => {
      this.fireConnectionListeners();
      this.trackUserEvent({
        event: 'GpsStore Socket Disconnect',
        eventData: {
          err,
        },
        user: this.user,
      });
    });

    this.socket.on('error', err => {
      this.trackUserEvent({
        event: 'GpsStore Socket Error',
        eventData: {
          err,
        },
        user: this.user,
      });
    });

    this.socket.on('connect_error', err => {
      console.log('err=================', err);
    });
  };

  isConnected = () => {
    return this.socket && this.socket.connected;
  };
  subscribe = ({imei, uid, stops}, listeners) => {
    if (!imei) {
      return;
    }
    const subscriptions = this.getSubscriptions();
    let imeiInfo = subscriptions[imei];
    if (imeiInfo === undefined) {
      subscriptions[imei] = {
        __views__: {
          [uid]: {...listeners},
        },
        stops,
      };
      if (!this.socket) {
        this.connectToSocket();
      } else {
        this.subscribeSocket({imei});
      }
    } else {
      subscriptions[imei] = {
        ...imeiInfo,
        __views__: {
          ...imeiInfo.__views__,
          [uid]: {...listeners},
        },
        stops,
      };
    }
  };

  unsubscribe = ({imei, uid}) => {
    if (!imei) {
      return;
    }
    const subscriptions = this.getSubscriptions();
    let imeiInfo = subscriptions[imei];
    let viewInfo = imeiInfo && imeiInfo.__views__;
    if (viewInfo) {
      for (var viewId in viewInfo) {
        if (viewId.toString() === uid.toString()) {
          // case uid in number and viewid in String format on subscribe
          delete viewInfo[viewId];
          break;
        }
      }
    }
    if (!viewInfo || !Object.keys(viewInfo).length) {
      delete subscriptions[imei];
      this.unSubscribeSocket({imei});
    }
  };

  subscribeTicketSocket = subscribeInfo => {
    this.socket.emit('subscribeTicket', subscribeInfo);
  };

  subscribeNotificationSocket = subscribeInfo => {
    this.socket.emit('subscribeNotification', subscribeInfo);
  };

  subscribeUnAssignedTicketSocket = () => {
    this.socket.emit('subscribeUnAssignedTicket');
  };

  subscribeTicket = ({ticketId, callback}) => {
    if (!ticketId) {
      return;
    }
    this.ticketSubscriptions = this.ticketSubscriptions || {};
    this.ticketSubscriptions[ticketId] =
      this.ticketSubscriptions[ticketId] || [];
    this.ticketSubscriptions[ticketId].push({callback});
    if (!this.socket) {
      this.connectToSocket();
    } else {
      this.subscribeTicketSocket({ticketId});
    }
  };

  subscribeUnAssignedTicket = ({callback}) => {
    this.unAssignedTicketSubscription = this.unAssignedTicketSubscription || {};
    this.unAssignedTicketSubscription = {callback};
    if (!this.socket) {
      this.connectToSocket();
    } else {
      this.subscribeUnAssignedTicketSocket();
    }
  };

  unsubscribeUnAssignedTicket = () => {
    let unAssignedTicketSubscription = this.unAssignedTicketSubscription;
    if (unAssignedTicketSubscription.callback) {
      delete unAssignedTicketSubscription.callback;
    }
    this.socket && this.socket.emit('unsubscribeUnAssignedTicket');
  };

  unsubscribeTicket = ({ticketId}) => {
    if (!ticketId) {
      return;
    }
    let ticketSubscriptions = this.ticketSubscriptions || {};

    let ticketInfo = ticketSubscriptions && ticketSubscriptions[ticketId];
    if (ticketInfo) {
      delete ticketSubscriptions[ticketId];
    }

    this.socket && this.socket.emit('unsubscribeTicket', {ticketId});
  };

  subscribeNotification = ({userId, callback}) => {
    if (!userId) {
      return;
    }
    this.notificationSubscriptions = this.notificationSubscriptions || {};
    this.notificationSubscriptions[userId] =
      this.notificationSubscriptions[userId] || [];
    this.notificationSubscriptions[userId].push({callback});
    if (!this.socket) {
      this.connectToSocket();
    } else {
      this.subscribeNotificationSocket({userId});
    }
  };

  unsubscribeNotification = ({userId}) => {
    if (!userId) {
      return;
    }
    let notificationSubscriptions = this.notificationSubscriptions || {};

    let notificationInfo =
      notificationSubscriptions && notificationSubscriptions[userId];
    if (notificationInfo) {
      delete notificationSubscriptions[userId];
    }

    this.socket && this.socket.emit('unsubscribeNotification', {userId});
  };
  subscribeSocketEvent = (event, callback) => {
    this.socket.on(event, callback);
  };
  subscribeEvent = ({event, uid, callback}) => {
    this.events[event] = this.events[event] || [];
    this.events[event].push({
      event,
      uid,
      callback,
    });

    if (!this.socket) {
      this.connectToSocket();
    } else if (this.socket.connected) {
      this.subscribeSocketEvent(event, callback);
    }
  };
  unsubscribeEvent = ({event, callback}) => {
    this.events[event] = this.events[event] || [];

    this.events[event] = this.events[event].filter(({callback: eCallback}) => {
      if (eCallback !== callback) {
        return true;
      }
    });
    this.socket && this.socket.off(event, callback);
  };
}
