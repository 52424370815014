import React from 'react';
import { View, Image, TouchableOpacity } from '@applane/react-core-components';
import { sortableHeaderTheme } from '../../../theme/tableTheme';

export default (Component) => {
  class SortComponent extends React.Component {
    sort = () => {
      const { dataParams, setScreenState } = this.props;
      const sortField = this.getSortField();
      if (sortField) {
        const existingSort = dataParams && dataParams.sort;
        let sortDirection;
        const firstSortField = existingSort && existingSort[sortField];
        if (existingSort && existingSort[sortField] !== undefined) {
          // toggle it
          sortDirection = -1 * existingSort[sortField];
        } else if (firstSortField) {
          // check first field sort direction
          sortDirection = -1 * existingSort[firstSortField];
        } else {
          sortDirection = -1;
        }

        const sortValue = {
          dataParams: {
            ...dataParams,
            sort: { ...dataParams?.sort, [sortField]: sortDirection },
          },
        };

        if (sortDirection < 0 && firstSortField) {
          delete sortValue?.dataParams?.sort[sortField];
        }

        setScreenState && setScreenState(sortValue);
      }
    };

    getSortField = () => {
      const { column: { sortable, field } = {} } = this.props;
      return sortable && typeof sortable === 'boolean'
        ? field
        : typeof sortable === 'string'
          ? sortable
          : void 0;
    };

    render() {
      const { column: { sortable, field } = {}, dataParams } = this.props;
      const renderComponent = <Component {...this.props} />;
      if (!sortable) {
        return renderComponent;
      }
      const sortField = this.getSortField();
      let asc = void 0;
      if (sortable && dataParams) {
        const { sort } = dataParams;
        const keys = sort && Object.keys(sort);
        let key = keys && keys[0];
        if (keys && sortField && keys.indexOf(sortField) > -1) {
          // find out if current sortable Field exists
          key = keys[keys.indexOf(sortField)];
        }
        if (key !== undefined && (key === field || key === sortable)) {
          asc = sort[key] !== 1;
        }
      }
      const {
        containerStyle = {},
        imageTheme: {
          iconStyle,
          imageContainerStyle,
          imageViewStyle,
          descendingSortActiveIcon,
          descendingSortUnActiveIcon,
          ascendingSortUnActiveIcon,
          ascendingSortActiveIcon,
        } = {},
      } = sortableHeaderTheme;

      return (
        <TouchableOpacity onPress={this.sort} style={containerStyle}>
          <View style={{ overflow: 'hidden' }}>{renderComponent}</View>
          <View style={imageContainerStyle}>
            {asc === undefined && (
              <>
                <View style={imageViewStyle}>
                  <Image
                    style={iconStyle}
                    source={descendingSortUnActiveIcon}
                  />
                </View>
                <View style={imageViewStyle}>
                  <Image style={iconStyle} source={ascendingSortUnActiveIcon} />
                </View>
              </>
            )}
            {asc ? (
              <>
                {/* <View style={imageViewStyle}>
                  <Image style={iconStyle} source={descendingSortActiveIcon} />
                </View> */}
                <View style={imageViewStyle}>
                  <Image style={iconStyle} source={ascendingSortUnActiveIcon} />
                </View>
              </>
            ) : asc === false ? (
              <>
                <View style={imageViewStyle}>
                  <Image
                    style={iconStyle}
                    source={descendingSortUnActiveIcon}
                  />
                </View>
                {/* <View style={imageViewStyle}>
                  <Image style={iconStyle} source={ascendingSortActiveIcon} />
                </View> */}
              </>
            ) : null}
          </View>
        </TouchableOpacity>
      );
    }
  }
  return SortComponent;
};
