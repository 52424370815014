import React from 'react';
import {
  View,
  Text,
  BasicTextInput,
  TouchableOpacity,
  Image,
} from '@applane/react-core-components';
import {filterGroupSearchTheme} from '../../../theme/filterGroupTheme';
const {
  containerStyle,
  inputStyle,
  crossIcon,
  searchIcon,
  iconStyle,
} = filterGroupSearchTheme;

class RegexFilter extends React.Component {
  constructor(props) {
    super(props);
    let {data: filters, field} = this.props;
    let {value} = (filters && filters[field]) || {};
    let displayValue = (value && value.$regex) || '';
    this.state = {value: displayValue};
  }
  onChangeValue = value => {
    this.setState({value});
  };
  setValue = () => {
    let {value} = this.state;
    value = value && value.trim();
    if (value) {
      this.onApply(value);
    }
  };
  onApply = value => {
    let {
      onChangeValue,
      data: filters,
      field,
      asParam,
      asGlobalParam,
      onFilterChange,
    } = this.props;
    filters = {...filters};
    if (value === undefined || value === null || value === '') {
      delete filters[field];
    } else {

      const regexValue = {$regex: value, $options: 'i'};
      filters[field] = {
        value: regexValue,
        filter: {
          filterType: 'regex',
          value: regexValue,
          asParam,
          asGlobalParam,
        },
      };
    }
    if (onFilterChange) {
      filters = onFilterChange({filters});
    }
    onChangeValue && onChangeValue(filters);
  };
  render() {
    let {placeholder, onSubmitEditing} = this.props;
    const displayValue = this.state.value;
    return (
      <View style={{flex: 1, overflow: 'hidden'}}>
        <View style={containerStyle}>
          <BasicTextInput
            title={'Press enter to apply search'}
            style={inputStyle}
            placeholder={placeholder || 'Search here'}
            value={displayValue}
            onChangeValue={this.onChangeValue}
            onBlur={!onSubmitEditing && this.setValue}
            onSubmitEditing={onSubmitEditing && this.setValue}
            autoFocus
          />
          <TouchableOpacity
            title={displayValue ? 'Clear Text' : void 0}
            style={{cursor: 'pointer'}}
            onPress={() => {
              this.onChangeValue('');
            }}>
            <Image
              source={displayValue ? crossIcon : searchIcon}
              style={iconStyle}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

export default props => {
  return <RegexFilter {...props} />;
};
