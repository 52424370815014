export default {
  labels: {
    name: 'Name',
    description: 'Description',
    status: 'Status',
    role: 'Role',
    employee: 'Employee',
    activityLog: 'Activity log',
  },
  title: {
    roleManagement: 'Role Management',
    addRole: 'Add Role',
    subTitle: 'Fill out the form to add new Role',
    roleDetails: 'Role Details',
    EditRole: 'Edit Role',
    description: 'List of Employee in the role.',
  },
  messages: {
    roleAlreadyExists: 'Role Already exists',
    roleAddedSuccessfully: 'Role added successfully',
    roleEditedSuccessfully: 'Role edited successfully',
  },
};
