import React, {Component} from 'react';
import StandardDropDown from './StandardDropDown';
import {View, Text, Image} from '@applane/react-core-components';
import {WithModal} from '@applane/react-with-modal';
import {getRenderComponent} from './Utility';

export class DropDownAction extends Component {
  renderModal = props => modalProps => {
    const {
      renderModal,
      dropdownStyle,
      title,
      titleStyle,
      renderDropDown,
      ...restProps
    } = props;
    if (renderModal) {
      return renderModal(modalProps);
    } else {
      let {frameStyle, hideModal} = modalProps;
      let dropdownProps = {
        ...restProps,
        closeModal: hideModal,
      };
      let dropdownComponent = getRenderComponent(renderDropDown, dropdownProps);
      if (!dropdownComponent) {
        dropdownComponent = <StandardDropDown {...dropdownProps} />;
      }
      return (
        <View
          style={{
            overflow: 'hidden',
            backgroundColor: '#ffffff',
            ...frameStyle,
            ...dropdownStyle,
          }}>
          {title && titleStyle && <Text style={titleStyle}>{title}</Text>}
          {dropdownComponent}
        </View>
      );
    }
  };

  filterOptions = (options, props) => {
    const {resolveVisible} = this.props;
    if (!options?.length) {
      return options;
    }
    return options.filter(_option => {
      if (!_option) {
        return;
      }
      let {visible = true} = _option || {};
      if (typeof visible === 'function') {
        if (resolveVisible) {
          visible = resolveVisible({...this.props, visible});
        } else {
          visible = visible(props);
        }
      }
      return visible;
    });
  };

  render() {
    let {
      autoHide = true,
      position,
      style,
      backdropStyle,
      dropdownStyle,
      iconStyle,
      textStyle,
      activeTextStyle,
      icon,
      activeIcon,
      text,
      children,
      active,
      onModalShow,
      options,
      longPressOptions,
      resolveVisible,
      ...restProps
    } = this.props;
    if (position && this.props[`${position}DropdownStyle`]) {
      dropdownStyle = this.props[`${position}DropdownStyle`];
    }
    if (active) {
      icon = activeIcon || icon;
    }
    if (!children) {
      children = (
        <View style={{flexDirection: 'row'}}>
          {icon ? <Image style={iconStyle} source={icon} /> : void 0}
          {text ? (
            <Text
              style={{
                ...textStyle,
                ...((active && activeTextStyle) || {}),
              }}>
              {text}
            </Text>
          ) : (
            void 0
          )}
        </View>
      );
    }

    if (options && options.length) {
      options = this.filterOptions(options, restProps);
    }
    if (longPressOptions && longPressOptions.length) {
      longPressOptions = this.filterOptions(longPressOptions, restProps);
    }
    if (options?.length || longPressOptions?.length) {
      let extraProps = {};
      if (options?.length) {
        extraProps.renderModal = this.renderModal({
          ...restProps,
          options,
          dropdownStyle,
        });
      }
      if (longPressOptions?.length) {
        extraProps.renderModalOnLongPress = this.renderModal({
          ...restProps,
          options: longPressOptions,
          dropdownStyle,
        });
      }
      return (
        <WithModal
          style={{...style, cursor: 'pointer'}}
          autoHide={autoHide}
          dropdownStyle={dropdownStyle}
          backdropStyle={backdropStyle}
          position={position}
          onModalShow={onModalShow}
          {...extraProps}>
          {children}
        </WithModal>
      );
    } else {
      return <View style={style}>{children}</View>;
    }
  }
}

export default DropDownAction;
