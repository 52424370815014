import React from 'react';
import { View } from '@applane/react-core-components';
import { getResolvedGroups } from './Utility';

export const VerticalFormGroups = (props) => {
  let {
    verticalFormGroups,
    renderFormGroups,
    scrollable,
    verticalSeparatorStyle,
    ...restProps
  } = props;
  let verticalFormGroupsComponent = verticalFormGroups.map((formGroupInfo) => {
    let {
      separatorStyle = verticalSeparatorStyle,
      separator,
      formGroups,
      formGroupsContainerStyle,
    } = formGroupInfo;
    if (formGroups) {
      formGroups = getResolvedGroups(formGroups, restProps);
    }
    let formGroupComponent = renderFormGroups({
      formGroups,
    });

    return (
      <>
        <View style={{ overflow: 'hidden', ...formGroupsContainerStyle }}>
          {formGroupComponent}
        </View>
        {separator && separatorStyle ? <View style={separatorStyle} /> : void 0}
      </>
    );
  });
  let formGroupFlexStyle = {};

  return (
    <View style={{ ...formGroupFlexStyle }}>{verticalFormGroupsComponent}</View>
  );
};
