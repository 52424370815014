import React from 'react';
import {checkSelectedIcon, unCheckboxIcon} from '../../images';
import {resolveExp} from '../UtilityFunctions';
import {RowSelector} from '../table/selector/Selector';
import moment from 'moment';

export const CardSelection = props => {
  const {item, navigation} = props;
  let {getScreenState, setScreenState} = navigation;
  return (
    <RowSelector
      field={'_id'}
      selectedIcon={checkSelectedIcon}
      unCheckboxIcon={unCheckboxIcon}
      {...props}
      data={item}
      screenState={getScreenState()}
      setScreenState={setScreenState}
    />
  );
};

export const CardDateRangeRender = props => {
  const {
    column: {field, format = 'DD MMM YY', ...restColumn},
    ...restProps
  } = props;
  let {item} = restProps;
  let value = void 0;
  if (field) {
    let {from, to} = field;
    let fromDate = resolveExp(item, from);
    let toDate = resolveExp(item, to);
    if (fromDate || toDate) {
      value = `${
        fromDate
          ? moment(fromDate).format(format)
          : restColumn.defaultText || ''
      } - ${
        toDate ? moment(toDate).format(format) : restColumn.defaultText || ''
      } `;
    }
  }
  return value;
};
