import React from 'react';
import {WithInputWrapper, WithVariant} from '@applane/react-input-wrapper';
import AutosuggestInput from './AutosuggestInput';

class GooglePlaceInputField extends React.Component {
  keyExtractor = (item, index) => {
    return String(index);
  };
  getDisplayValue = item => {
    let {valueField, getDisplayValue} = this.props;
    if (item === undefined || item === null) {
      return '';
    }
    if (typeof getDisplayValue === 'function') {
      item = getDisplayValue({value: item});
    } else if (valueField && typeof item === 'object') {
      item = item[valueField];
    }
    return item;
  };

  onChangeValue = (value, e) => {
    const {onChangeValue, onChangeText} = this.props;
    onChangeValue && onChangeValue(value, e);
    onChangeText && onChangeText(value);
  };

  modifyAndSetValue = async ({item, e}) => {
    const {modifySelectedData} = this.props;
    try {
      if (modifySelectedData) {
        item = await modifySelectedData(item);
      }
      this.onChangeValue(item, e);
    } catch (err) {
      console.error('Error in google place field on select', err);
    }
  };

  onSelect = ({item, e}) => {
    let {onItemSelect} = this.props;
    this.modifyAndSetValue({item, e});
    onItemSelect && onItemSelect({item, props: this.props});
    return this.getDisplayValue(item);
  };

  clearValue = e => {
    this.onChangeValue(null, e);
  };

  onRenderNoDataCallback = ({result} = {}) => {
    if (result) {
      this.onChangeValue(result);
    }
  };

  renderNoData = searchProps => {
    const {renderNoData} = this.props;
    if (!renderNoData) {
      return;
    }
    return renderNoData({
      ...searchProps,
      onSubmitCallback: this.onRenderNoDataCallback,
    });
  };

  fetch = searchProps => {
    let {fetch, data, getData} = this.props;
    if (!data && getData) {
      data = getData();
    }
    return fetch({...searchProps, data}).then(result => {
      return result.data;
    });
  };

  render() {
    const {
      valueField,
      fetch,
      renderNoData,
      onItemSelect,
      afterSearchfetch,
      ...rest
    } = this.props;
    return (
      <AutosuggestInput
        keyExtractor={this.keyExtractor}
        getDisplayValue={this.getDisplayValue}
        onItemSelect={this.onSelect}
        clearValue={this.clearValue}
        fetch={this.fetch}
        renderNoData={this.renderNoData}
        {...rest}
      />
    );
  }
}
GooglePlaceInputField = WithInputWrapper(WithVariant(GooglePlaceInputField));

export default GooglePlaceInputField;
