import React from 'react';
import {View} from '@applane/react-core-components';
import BasicTextAreaInput from './BasicTextAreaInput';
import {
  WithFloatingLabel,
  WithInputWrapper,
  WithVariant,
  WithInputError,
  getInputProps,
} from '@applane/react-input-wrapper';

class TextAreaInputEditor extends React.Component {
  render() {
    let inputProps = getInputProps(this.props);
    return <BasicTextAreaInput {...inputProps} />;
  }
}

TextAreaInputEditor = WithInputError(WithFloatingLabel(TextAreaInputEditor));

class TextAreaInput extends React.Component {
  render() {
    return (
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <TextAreaInputEditor {...this.props} />
        </View>
      </View>
    );
  }
}

TextAreaInput = WithInputWrapper(WithVariant(TextAreaInput));

export default TextAreaInput;
