import React from 'react';
import DateCalendar from './DateCalendar';
import {getRenderComponent} from '@applane/react-input-wrapper';

const DateSelector = props => {
  let {
    value,
    onDateChange,
    renderInputSelector,
    inputSelectorContainerStyle,
    inputSelectorIconContainerStyle,
    calendarStyle,
    ...rest
  } = props;
  if (renderInputSelector) {
    return getRenderComponent(renderInputSelector, props);
  }
  return (
    <DateCalendar
      {...rest}
      {...calendarStyle}
      iconContainerStyle={inputSelectorContainerStyle}
      iconStyle={inputSelectorIconContainerStyle}
      value={value}
      onDateChange={onDateChange}
    />
  );
};

DateSelector.defaultProps = {
  inputSelectorContainerStyle: {
    paddingRight: 6,
    justifyContent: 'center',
  },
};

export default DateSelector;
