/* eslint-disable import/no-cycle */
import DateRangeFilter from './date/DateRangeFilter';
import {
  AutoSuggestFilter,
  AutoSuggestOptionsFilter,
} from './autoSuggest/AutoSuggestFilter';
import DateFilter from './date/DateFilter';
import FilterGroupFTS from './fts/FilterGroupFTS';
import RegexFilter from './regex/RegexFilter';
import { autosuggestFetch } from '../../AppServices';

export const filterGroupTypes = {
  date: DateRangeFilter,
  autoSuggest: (props) => {
    const {
      model, id, searchField, addOnFilter, modifyResult, ...restProps
    } = props;
    return AutoSuggestFilter({
      fetch: autosuggestFetch({
        model,
        id,
        search: searchField,
        addOnFilter,
        modifyResult,
      }),
      ...restProps,
    });
  },
  autoSuggestOptions: AutoSuggestOptionsFilter,
  fts: FilterGroupFTS,
  regex: RegexFilter,
};

export const filterGroupRowTypes = {
  date: DateFilter,
  autoSuggest: (props) => {
    const {
      model, id, searchField, addOnFilter, ...restProps
    } = props;
    return AutoSuggestFilter({
      fetch: autosuggestFetch({
        model,
        id,
        search: searchField,
        addOnFilter,
      }),
      ...restProps,
    });
  },
  autoSuggestOptions: AutoSuggestOptionsFilter,
};
