import React from 'react';
import {View, Text} from '@applane/react-core-components';
import {getRenderComponent} from './Utility';

class StackItem extends React.Component {
  state = {};
  componentDidCatch(error, info) {
    this.setState({hasError: true, error: {error, info}});
  }
  render() {
    let {
      view,
      navigation,
      eventDispatcher,
      index,
      lastViewIndex,
      screenState,
      setScreenState,
      ScreenComponent,
      renderHeader,
      renderFooter,
    } = this.props;
    const {hasError, error} = this.state;
    let {
      screen: Component,
      screenProps,
      title,
      header,
      footer,
      actions,
      renderHeader: viewRenderHeader,
      renderFooter: viewRenderFooter,
      ...rest
    } = view;
    if (viewRenderHeader) {
      renderHeader = viewRenderHeader;
    }
    if (viewRenderFooter) {
      renderFooter = viewRenderFooter;
    }

    let screenComponentProps = {
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
      currentViewIndex: index,
      lastViewIndex,
      ...screenProps,
      ...rest,
    };

    let children = null;
    if (ScreenComponent) {
      children = (
        <ScreenComponent {...screenComponentProps} screen={Component} />
      );
    } else {
      children = <Component {...screenComponentProps} />;
    }
    if (hasError) {
      children = (
        <View>
          <Text>Error in stack item :{error.message}</Text>
        </View>
      );
    }
    return (
      <React.Fragment>
        {getRenderComponent(renderHeader, {
          navigation,
          eventDispatcher,
          screenState,
          setScreenState,
          header,
        })}
        {children}
        {getRenderComponent(renderFooter, {
          screenState,
          setScreenState,
          navigation,
          eventDispatcher,
          footer,
        })}
      </React.Fragment>
    );
  }
}

export default StackItem;
