import React from 'react';
import {Image, Platform, Text, View} from '@applane/react-core-components';
import {resolveValue, resolveVisibleExp} from './CardUtility';
import {Avatar as CardAvatar} from '@applane/react-avatar';
import WithAction from './WithAction';

let CardItemComponent = props => {
  let {source = 'content', item, column, typeCast} = props;
  if (!column) {
    return null;
  }

  let {
    type,
    title,
    icon,
    value,
    html,
    color,
    flex,
    numberOfLines,
    textAlign,
    style,
    textStyle,
    iconContainerStyle,
    iconStyle,
    prefix,
    suffix,
    prefixStyle,
    suffixStyle,
    isDefaultText,
    resizeMode = 'contain',
    ...restItemInfo
  } = column;

  style = style || props[`${source}ItemStyle`];
  textStyle = textStyle || props[`${source}ItemTextStyle`];
  iconContainerStyle =
    iconContainerStyle || props[`${source}ItemIconContainerStyle`];
  iconStyle = iconStyle || props[`${source}ItemIconStyle`];

  let valueComponent = void 0;
  if (value !== undefined) {
    if (React.isValidElement(value)) {
      valueComponent = value;
    } else {
      textStyle = {
        ...textStyle,
      };
      if (numberOfLines) {
        textStyle.numberOfLines = numberOfLines;
      }
      if (textAlign) {
        textStyle.textAlign = textAlign;
      }
      if (color) {
        if (typeof color === 'function') {
          color = color({item, value});
        }
        if (color !== undefined) {
          textStyle.color = color;
        }
      }
      if (type && typeCast && typeCast[type]) {
        value = typeCast[type]({value, ...restItemInfo});
      }
      if (Array.isArray(value)) {
        value = value.join('; ');
      } else if (value && typeof value === 'object') {
        value = JSON.stringify(value);
      } else if (
        value !== undefined &&
        value !== null &&
        typeof value !== 'string'
      ) {
        value = value.toString ? value.toString() : JSON.stringify(value);
      }
      let prefixComponent = void 0;
      let suffixComponent = void 0;
      if (!isDefaultText) {
        if (prefix) {
          if (prefixStyle) {
            prefixComponent = <Text style={prefixStyle}>{prefix}</Text>;
          } else {
            value = prefix + value;
          }
        }
        if (suffix) {
          if (suffixStyle) {
            suffixComponent = <Text style={suffixStyle}>{suffix}</Text>;
          } else {
            value = value + suffix;
          }
        }
      }
      if (title === undefined) {
        title = value;
      }
      if (html && Platform.OS === 'web') {
        valueComponent = (
          <Text
            style={textStyle}
            dangerouslySetInnerHTML={{__html: value}}></Text>
        );
      } else {
        valueComponent = <Text style={textStyle}>{value}</Text>;
      }
      if (prefixComponent || suffixComponent) {
        valueComponent = (
          <View style={{flexDirection: 'row', flex: 1, overflow: 'hidden'}}>
            {prefixComponent}
            {valueComponent}
            {suffixComponent}
          </View>
        );
      }
    }
  }
  if (icon) {
    valueComponent = (
      <View style={{flexDirection: 'row'}}>
        {icon ? (
          <View style={iconContainerStyle}>
            <Image source={icon} style={iconStyle} resizeMode={resizeMode} />
          </View>
        ) : (
          void 0
        )}
        {valueComponent}
      </View>
    );
  }
  style = {
    ...style,
  };
  if (flex !== undefined) {
    style.flex = flex;
    style.overflow = 'hidden';
  }
  return (
    <View title={title} style={style}>
      {valueComponent}
    </View>
  );
};

CardItemComponent.defaultProps = {
  contentItemIconContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 4,
  },
};

CardItemComponent = WithAction(CardItemComponent);

const CardItem = props => {
  let {
    selected,
    column,
    defaultItemText,
    avatarStyle,
    selectedAvatarStyle,
    Avatar = CardAvatar,
    getTypeComponent,
    ...restProps
  } = props;
  if (!column) {
    return;
  }
  let {item} = restProps;
  let {
    type,
    field,
    value,
    text,
    render,
    icon,
    title,
    iconField,
    defaultText = defaultItemText,
    skipDefaultText,
    visible,
    action,
    actions,
    longPressActions,
    actionProps,
    avatar,
    ...restColumn
  } = column;
  if (!resolveVisibleExp(visible, props)) {
    return null;
  }
  if (typeof title === 'function') {
    title = title(props);
  }
  if (typeof icon === 'function') {
    //issue in svg icon by passing all props,left or style prop create issue
    icon = icon({item});
  } else if (!icon && iconField) {
    icon = resolveValue(item, iconField);
  }
  if (type && getTypeComponent) {
    let TypeComponent = getTypeComponent(type);
    if (TypeComponent) {
      value = TypeComponent(props);
    }
  }
  if (value === undefined) {
    value = render || text;
  }
  if (typeof value === 'function') {
    value = value(props);
  } else if (value === undefined && field) {
    value = resolveValue(item, field);
  }
  if (avatar) {
    avatarStyle = {
      ...avatarStyle,
      ...((selected && selectedAvatarStyle) || {}),
    };
    return (
      <Avatar
        title={title}
        icon={icon}
        value={value || ''}
        {...avatarStyle}
        {...restColumn}
      />
    );
  }
  let isDefaultText = false;
  if (
    (column.field !== undefined || column.value !== undefined) &&
    (value === undefined || value === null)
  ) {
    if (!skipDefaultText) {
      value = defaultText;
      isDefaultText = true;
    }
    icon = void 0;
  }
  if (icon === undefined && value === undefined) {
    return null;
  }
  return (
    <CardItemComponent
      {...restProps}
      column={{...restColumn, type, icon, value, title, isDefaultText}}
      action={action}
      actions={actions}
      longPressActions={longPressActions}
      actionProps={actionProps}
    />
  );
};

export default CardItem;
