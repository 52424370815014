import React from 'react';
import { StandardButton } from '../../../app-components';
import { TabNavigator } from '../../../app-components/tab/TabNavigator';

import { getUser } from '../../../AppServices';
import vars from '../../../theme/vars';
import PatientConsultationHistory from './PatientConsultationHistory';
import PatientDetailsForm from './PatientDetails';
import PatientVaccinationHistory from './PatientVaccinationHistory';
import printOPDCard from '../../../Wired/OPDCard';

const HistoryTabs = (props) => {
  const user = getUser();
  return (
    <TabNavigator
      {...props}
      tabs={{
        patientDetails: {
          label: 'Patient Details',
          screen: PatientDetailsForm,
          screenProps: {
            user,
          },
          actions: [
            {
              render: (actionProps) => (
                <StandardButton
                  label="Print Card"
                  color={vars.colors.primary.color2}
                  hoverColor={vars.colors.secondary.color2}
                  width="auto"
                  labelStyle={{
                    ...vars.headings.h7,
                    color: vars.colors.white,
                    numberOfLines: 1,
                  }}
                  {...actionProps}
                />
              ),
              type: 'invoke',
              invoke: () => {
                printOPDCard({
                  patientName: user?.patient?.patientName,
                  UHID: user?.username,
                });
              },
            },
          ],
        },
        consultationHistory: {
          label: 'Consultation History',
          screen: PatientConsultationHistory,
          screenProps: {
            user,
          },
        },
        vaccinationHistory: {
          label: 'Vaccination History',
          screen: PatientVaccinationHistory,
          screenProps: {
            addOnFilter: {
              patient: { _id: user?.patient?._id },
            },
          },
        },
      }}
    />
  );
};

export default HistoryTabs;
