import React from 'react';
import {View, Text, Image, Platform} from '@applane/react-core-components';
import {resolveExp, getRenderComponent} from './Utility';

const DataCell = props => {
  let {column = {}, item, typeCast, html: _html} = props;
  let columnTextStyle =
    (column.primary && props['rowColumnPrimaryTextStyle']) ||
    props['textStyle'] ||
    props['rowColumnTextStyle'];
  let columnIconStyle = props['iconStyle'] || props['rowColumnIconStyle'];
  let columnIconGap = props['iconGap'] || props['rowColumnIconGap'] || 6;
  let columnIconPosition =
    props['iconPosition'] || props['rowColumnIconPosition'] || 'left';

  let {
    text,
    icon,
    field,
    value,
    render,
    title,
    containerStyle,
    iconStyle = columnIconStyle,
    textStyle = columnTextStyle,
    iconGap = columnIconGap,
    iconPosition = columnIconPosition,
    defaultText,
    type,
    modifyValue,
    align = props.align,
    prefix,
    suffix,
    resizeMode = 'contain',
    numberOfLines,
    color,
    html = _html,
    ...rest
  } = column;
  let rowComponent = void 0;
  if (render) {
    rowComponent = getRenderComponent(render, props);
  } else {
    if (!value && text) {
      value = text;
    }
    if (typeof icon === 'function') {
      icon = icon(props);
    }
    if (typeof value === 'function') {
      value = value(props);
    } else if (field) {
      value = resolveExp(item, field);
    }
    textStyle = {
      ...textStyle,
    };
    if (numberOfLines) {
      textStyle.numberOfLines = numberOfLines;
    }
    if (modifyValue) {
      value = modifyValue({item, value});
    }
    if (color) {
      if (typeof color === 'function') {
        color = color({item, value});
      }
      if (color !== undefined) {
        textStyle.color = color;
      }
    }
    if (type && typeCast && typeCast[type]) {
      value = typeCast[type]({value, ...rest});
    }
    if (value === null || value === void 0) {
      value = defaultText;
    } else {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      } else {
        if (prefix) {
          value = prefix + value;
        }
        if (suffix) {
          value = value + suffix;
        }
      }
    }
    containerStyle = {
      flexDirection: 'row',
      alignItems: 'center',
      ...containerStyle,
    };
    let iconComponent = icon ? (
      <Image source={icon} style={iconStyle} resizeMode={resizeMode} />
    ) : (
      void 0
    );
    let valueComponent = void 0;
    if (value !== undefined) {
      if (html && Platform.OS === 'web') {
        valueComponent = (
          <Text
            style={textStyle}
            dangerouslySetInnerHTML={{__html: value}}></Text>
        );
      } else {
        valueComponent = <Text style={textStyle}>{value}</Text>;
      }
    }
    if (align === 'left') {
      containerStyle.justifyContent = 'flex-start';
    } else if (align === 'right') {
      containerStyle.justifyContent = 'flex-end';
    } else if (align === 'center') {
      containerStyle.justifyContent = 'center';
    } else if (valueComponent) {
      valueComponent = (
        <View style={{flex: 1, overflow: 'hidden'}}>{valueComponent}</View>
      );
    }
    let gapComponent =
      iconComponent && valueComponent ? (
        <View style={{width: iconGap}} />
      ) : (
        void 0
      );
    rowComponent = (
      <View style={containerStyle} title={title || value}>
        {iconPosition === 'left' && iconComponent ? (
          <React.Fragment>
            {iconComponent}
            {gapComponent}
          </React.Fragment>
        ) : (
          void 0
        )}
        {valueComponent}
        {iconPosition === 'right' && iconComponent ? (
          <React.Fragment>
            {gapComponent}
            {iconComponent}
          </React.Fragment>
        ) : (
          void 0
        )}
      </View>
    );
  }
  return rowComponent;
};

DataCell.defaultProps = {
  textStyle: {
    font: 14,
    color: '#383838',
    numberOfLines: 1,
  },
};
export default DataCell;
