import React, { Component } from 'react';
import { toggleFilterTheme } from '../../theme/toggleFilterTheme';
import ToggleComponent from './ToggleComponent';

class ToggleFilter extends Component {
  setValue = ({ value }) => {
    let {
      field,
      screenState: { dataParams } = {},
      setScreenState,
      asParams,
      onFilterChange,
    } = this.props;
    let { filters } = dataParams || {};
    filters = {
      ...filters,
    };
    if (value === undefined || value === null) {
      delete filters[field];
    } else {
      filters[field] = {
        value,
        filter: {
          asParams,
          value,
        },
      };
    }
    if (onFilterChange) {
      filters = onFilterChange({ filters });
    }
    setScreenState &&
      setScreenState({
        dataParams: {
          ...dataParams,
          filters,
        },
      });
  };

  getFieldValue = () => {
    const {
      screenState: { dataParams: { filters = {} } = {} } = {},
      field,
    } = this.props;
    return filters && filters[field]?.value;
  };
  resolveCount = (props) => {
    const { loaded, aggregates } = props || {};
    if (loaded) {
      return `(${(aggregates && aggregates._count) || 0})`;
    }
    return null;
  };
  onChangeValue = (value) => {
    this.setValue({ value });
  };

  render() {
    return (
      <ToggleComponent
        {...toggleFilterTheme}
        resolveCount={this.resolveCount}
        value={this.getFieldValue()}
        onChangeValue={this.onChangeValue}
        {...this.props}
      />
    );
  }
}

export default ToggleFilter;
