export default {
  labels: {
    doctorName: 'Doctor Name',
    newDoctor: 'New Doctor',
  },
  messages: {
    doctorUpdatedSuccessfullySuffix: 'has been assigned to',
    doctorUpdatedSuccessfullyPrefix: 'successfully',
    sessionEndSuccessfully: 'Session has been ended successfully',
  },
  title: {
    existingPatient: 'Are you sure you want to Edit doctor?',
    description: 'This action will change the doctor for this Room.',
    endSession: 'Are you sure you want to End Session?',
    endSessionSubWarning: 'This action will end the session. ',
    endSessionWarning: ' patients are waiting in the queue.',
    endSessionCountWarning: ' patient is waiting in the queue.',
  },
};
