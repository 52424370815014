import React from "react";
import {
  View,
  Text,
  ScrollView,
  Image,
  TouchableOpacity,
} from "@applane/react-core-components";
import { WithInputWrapper, WithVariant } from "@applane/react-input-wrapper";
import AutosuggestInput from "./AutosuggestInput";
import AutoSuggestBottomInput from "./AutoSuggestBottomInput";
import {
  findValue,
  findIndex,
  getRenderComponent,
  matchValue,
  getOptionDisplay,
  resolveDottedValue,
} from "./Utility";

/**
 *
 * need to provide idField so that we can search the selected value exists or not, other wise we will check === ops
 */

const renderChipsComponent = (props) => {
  let {
    renderChip,
    chipsStyle,
    roundedChipsStyle,
    chipsVariant,
    value,
    onRemove,
    valueField,
    idField,
    removeChipIcon,
    maxHeight,
    renderChips,
    renderNoValue,
    disabled,
    getDisplayValue,
  } = props;

  if (value && !Array.isArray(value)) {
    value = [value];
  }
  if (!value || !value.length) {
    return getRenderComponent(renderNoValue, props);
  }
  if (renderChips) {
    return getRenderComponent(renderChips, props);
  }
  if (chipsVariant === "rounded") {
    chipsStyle = roundedChipsStyle;
  }
  let {
    containerStyle,
    itemContainerStyle,
    itemTextStyle,
    crossContainerStyle,
    crossIconStyle,
    crossTextStyle,
  } = chipsStyle;
  containerStyle = { ...containerStyle };

  const scrollViewStyle = {};

  if (maxHeight) {
    scrollViewStyle.maxHeight = maxHeight;
  }

  return (
    <ScrollView nestedScrollEnabled style={scrollViewStyle}>
      <TouchableOpacity activeOpacity={1} style={containerStyle}>
        {value.map((item, index) => {
          if (!item) {
            return null;
          }
          let key = idField ? item[idField] : index;
          let displayValue = valueField
            ? resolveDottedValue(item, valueField)
            : item;

          if (typeof getDisplayValue === "function") {
            displayValue = getDisplayValue({ value: item });
          }
          if (renderChip) {
            return renderChip({
              disabled,
              displayValue,
              key,
              item,
              index,
              onRemove,
            });
          }
          return (
            <View key={key} style={{ maxWidth: "100%", overflow: "hidden" }}>
              <View style={{ flexDirection: "row", ...itemContainerStyle }}>
                <View
                  style={{
                    overflow: "hidden",
                    flexShrink: 1,
                  }}>
                  <Text numberOfLines={1} style={itemTextStyle}>
                    {displayValue}
                  </Text>
                </View>
                {!disabled && onRemove && (
                  <TouchableOpacity
                    style={{ cursor: "pointer", ...crossContainerStyle }}
                    onPress={(e) => onRemove({ item, e })}>
                    {removeChipIcon ? (
                      <Image style={crossIconStyle} source={removeChipIcon} />
                    ) : (
                      <Text style={crossTextStyle}>X</Text>
                    )}
                  </TouchableOpacity>
                )}
              </View>
            </View>
          );
        })}
      </TouchableOpacity>
    </ScrollView>
  );
};
class MultiAutoSuggestInputFieldComponent extends React.Component {
  keyExtractor = (item, index) => {
    return String(index);
  };
  onChangeValue = (value, e) => {
    const { onChangeValue, onChangeText } = this.props;
    onChangeValue && onChangeValue(value, e);
    onChangeText && onChangeText(value);
  };

  isAllSelected = ({ data, value }) => {
    if (data && value && value.length === data.length) {
      return true;
    }
  };

  onSelect = ({ item, e, data, isSelectAll }) => {
    let { value, idField, onItemSelect } = this.props;
    value = value || [];
    if (value && !Array.isArray(value)) {
      value = [value];
    }
    let newValue = value ? [...value] : [];
    if (isSelectAll) {
      newValue = this.isAllSelected({ data, value: newValue }) ? [] : [...data];
    } else {
      let selectedIndex = findIndex({ value: newValue, item, idField });
      if (selectedIndex === -1) {
        newValue.push(item);
      } else {
        newValue.splice(selectedIndex, 1);
      }
    }
    this.onChangeValue(newValue, e);
    onItemSelect && onItemSelect({ item, props: this.props });
  };

  isHighlighted = ({ item, data, isSelectAll }) => {
    let { value, idField } = this.props;
    if (isSelectAll) {
      if (this.isAllSelected({ data, value })) {
        return true;
      }
    } else {
      return findValue({ value, item, idField });
    }
  };

  onRenderNoDataCallback = ({ result } = {}) => {
    if (result) {
      this.onChangeValue(result);
    }
  };

  renderNoData = (searchProps) => {
    const { renderNoData } = this.props;
    if (!renderNoData) {
      return;
    }
    return renderNoData({
      ...searchProps,
      onSubmitCallback: this.onRenderNoDataCallback,
    });
  };
  getDisplayValue = () => {
    return void 0;
  };
  render() {
    const {
      valueField,
      renderNoData,
      onItemSelect,
      modalPosition,
      value,
      ...rest
    } = this.props;
    const AutoSuggestInputComponent =
      modalPosition === "screenBottom"
        ? AutoSuggestBottomInput
        : AutosuggestInput;
    return (
      <AutoSuggestInputComponent
        keyExtractor={this.keyExtractor}
        getDisplayValue={this.getDisplayValue}
        onItemSelect={this.onSelect}
        isHighlighted={this.isHighlighted}
        renderNoData={this.renderNoData}
        {...rest}
      />
    );
  }
}

MultiAutoSuggestInputFieldComponent = WithInputWrapper(
  WithVariant(MultiAutoSuggestInputFieldComponent),
);

class MultiAutosuggestInput extends React.Component {
  fetch = (searchProps) => {
    let { fetch, data, getData, fetchProps } = this.props;
    if (!data && getData) {
      data = getData();
    }
    return fetch({ ...searchProps, ...fetchProps, data }).then((result) => {
      return result.data;
    });
  };

  render() {
    const { getRef, ...rest } = this.props;
    return (
      <MultiAutoSuggestInputFieldComponent
        {...rest}
        ref={getRef}
        fetch={this.fetch}
      />
    );
  }
}

class MultiAutosuggestOptionsInput extends React.Component {
  fetch = ({ searchValue } = {}) => {
    let { options, keyField, searchField, valueField } = this.props;
    if (searchValue) {
      let regEx = new RegExp(`^${searchValue}|(?<= )${searchValue}`, "i");
      options = options.filter((item) => {
        let itemValue = getOptionDisplay({
          item,
          keyField,
          valueField: searchField || valueField,
        });
        if (itemValue && regEx.test(itemValue)) {
          return true;
        }
      });
    }
    return options;
  };

  render() {
    const { getRef, ...rest } = this.props;

    return (
      <MultiAutoSuggestInputFieldComponent
        {...rest}
        keyExtractor={this.keyExtractor}
        ref={this.props.getRef}
        fetch={this.fetch}
      />
    );
  }
}

export default (props) => {
  let _inputRef = void 0;
  const _getInputRef = (ref) => {
    _inputRef = ref;
  };
  const removeItem = ({ item, e }) => {
    let { value, idField } = props;
    if (!value || !value.length || !item || !_inputRef) {
      return;
    }
    let newValue = value.filter((doc) => {
      return !matchValue(doc, item, idField);
    });
    if (_inputRef.onChangeValue) {
      _inputRef.onChangeValue(newValue, e);
    }
  };

  let {
    chipsVariant,
    chipsPosition = "bottom",
    chipsStyle,
    renderChips,
    roundedChipsStyle,
    renderChip,
    renderNoValue,
    removeChipIcon,
    maxHeight,
    options,
    ...restProps
  } = props;

  let renderComponent = (
    <MultiAutosuggestInput {...restProps} getRef={_getInputRef} />
  );

  if (options) {
    if (typeof options === "function") {
      options = options(restProps);
    }
    renderComponent = (
      <MultiAutosuggestOptionsInput
        {...restProps}
        options={options}
        getRef={_getInputRef}
      />
    );
  }

  let chipsComponent = renderChipsComponent({
    ...props,
    onRemove: removeItem,
  });
  return (
    <React.Fragment>
      {chipsPosition === "top" ? chipsComponent : void 0}
      {renderComponent}
      {chipsPosition === "bottom" ? chipsComponent : void 0}
    </React.Fragment>
  );
};
