import React from 'react';
import { Form } from '../../app-components/form/Form';
import { autoSuggestInput } from '../../autoSuggestions';
import { genders } from '../../Lib/constants';
import {
  validateAlphabetsWithSpaceOnly,
  validateConfirmPassword,
  validateInputLength,
  validatePassword,
  getErrorString,
} from '../../Lib/helpers';
import { ageInput, RelationInput } from '../../app-components/compositeInputs';
import { invoke } from '../../AppServices';
import { cancelButton, save, Toast } from '../../app-components';
import vars from '../../theme/vars';

const header = {
  containerStyle: {
    paddingTop: 30,
    backgroundColor: vars.colors.grey.color4,
    paddingBottom: 40,
  },
  title: 'patient.title.signUpPatient'.getLabel(),
  subTitle: 'patient.subTitle.signUpPatient'.getLabel(),
  actions: [
    save({
      label: 'Sign Up',
    }),
    cancelButton({
      label: 'Login',
    }),
  ],
};

const PatientRegistrationForm = (props) => (
  <Form
    {...props}
    containerStyle={{
      backgroundColor: '#fff',
    }}
    bodyContainerStyle={{
      backgroundColor: vars.colors.grey.color5,
      paddingTop: 30,
    }}
    type="standard"
    closeView={1}
    onSubmit={async ({ data }) => {
      try {
        const result = await invoke({
          id: 'patientRegistrationUsingSignUp',
          paramValue: {
            data,
          },
        });
        if (result) {
          Toast.show({
            message: 'Success',
            description: 'patient.messages.patientAddedSuccessfully'.getLabel(),
            type: 'success',
            position: 'top',
            direction: 'right',
          });
        }
      } catch (e) {
        Toast.show({
          message: 'Error',
          description: `${getErrorString(e)}`,
          type: 'error',
          position: 'top',
          direction: 'right',
        });
      }
    }}
    defaultValues={{
      relation: 'S/O',
      gender: 'male',
      ageType: 'Years',
    }}
    mandatory={{
      patientName: 1,
      gender: 1,
      age: 1,
      relationName: 1,
      address: 1,
      username: 'Employee code is mandatory',
      newPassword: 'Password is mandatory',
      confirmPassword: 1,
    }}
    validations={{
      aadharNumber: ({ data }) => validateInputLength(data?.aadharNumber, 12, 12),
      BPLNumber: ({ data }) => validateInputLength(data?.BPLNumber, 10, 10),
      age: ({ data }) => validateInputLength(data?.age, 0, 3),
      newPassword: ({ data }) => validatePassword(data.newPassword),
      confirmPassword: ({ data }) => validateConfirmPassword(data),
    }}
    lg={{
      header,
      formGroups: [
        {
          fieldVariant: 'filled',
          direction: 'row',
          groups: [
            {
              columnsPerRow: 2,
              columns: [
                {
                  type: 'text',
                  field: 'patientName',
                  label: 'patient.labels.patientName'.getLabel(),
                  mandatory: true,
                  allowWhiteSpaces: true,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (validateAlphabetsWithSpaceOnly(value)) {
                      return prevValue;
                    }
                    return value.replace(/ {2,}/g, ' ');
                  },
                },
                {
                  type: 'text',
                  field: 'username',
                  label: 'patient.labels.username'.getLabel(),
                  mandatory: true,
                },
                {
                  type: 'password',
                  field: 'newPassword',
                  label: 'patient.labels.password'.getLabel(),
                  mandatory: true,
                },
                {
                  type: 'password',
                  field: 'confirmPassword',
                  label: 'patient.labels.confirmPassword'.getLabel(),
                  mandatory: true,
                },
                autoSuggestInput({
                  label: 'patient.labels.gender'.getLabel(),
                  field: 'gender',
                  suggestionField: 'label',
                  placeholder: 'patient.placeholders.selectGender'.getLabel(),
                  options: genders,
                  mandatory: true,
                }),
                ageInput({
                  age: {
                    type: 'number',
                    field: 'age',
                    label: 'patient.labels.age'.getLabel(),
                    mandatory: true,
                    allowedDecimalDigits: false,
                    modifyValueOnChange: ({ prevValue, value }) => {
                      if (value > 999) {
                        return prevValue;
                      }
                      return value;
                    },
                  },
                  ageType: {
                    field: 'ageType',
                  },
                }),
                {
                  type: 'custom',
                  render: (props) => (
                    <RelationInput
                      {...props}
                      mandatory
                      relationNameProps={{
                        allowWhiteSpaces: true,
                      }}
                    />
                  ),
                },
                {
                  type: 'number',
                  field: 'aadharNumber',
                  label: 'patient.labels.aadhar'.getLabel(),
                  allowedDecimalDigits: false,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (value?.toString()?.length > 12) {
                      return prevValue;
                    }
                    return value;
                  },
                },
                {
                  type: 'number',
                  field: 'BPLNumber',
                  label: 'patient.labels.bpl'.getLabel(),
                  inputProps: {
                    maxLength: 10,
                  },
                },
                {
                  type: 'number',
                  field: 'mobile',
                  label: 'patient.labels.mobile'.getLabel(),
                  placeholder: 'patient.placeholders.mobile'.getLabel(),
                  allowedDecimalDigits: false,
                  modifyValueOnChange: ({ prevValue, value }) => {
                    if (value?.toString()?.length > 10) {
                      return prevValue;
                    }
                    return value;
                  },
                },
                {
                  type: 'text',
                  field: 'address',
                  label: 'patient.labels.address'.getLabel(),
                  placeholder: 'patient.placeholders.address'.getLabel(),
                  allowWhiteSpaces: true,
                  mandatory: true,
                },
              ],
            },
          ],
        },
      ],
    }}
  />
);
export default PatientRegistrationForm;
