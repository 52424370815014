export default {
  placeholders: {
    year: 'Holiday Year',
    toDate: 'To Date',
    fromDate: 'From Date',
    description: 'description',
  },
  labels: {
    year: 'Holiday Year',
    fromDate: 'From Date',
    toDate: 'To Date',
    status: 'Status',
    action: 'Action',
    description: 'Description',
    NoOfHolidays: 'No. of Holidays',
    file: 'Drag & Drop Your Files Here',
  },
  title: {
    name: 'Holiday',
    activity: 'Activity Logs',
  },
  buttons: {
    addHoliday: 'Add Holiday Year',
    editHoliday: 'Edit Holiday',
  },
  messages: {
    holidayCalendarAddedSuccessfully: 'Holiday Calendar has been Added successfully.',
    holidayCalendarUpdatedSuccessfully: 'Holiday Calendar has been Updated successfully.',
  },
};
