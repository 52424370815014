import React from 'react';
import FloatingActions from '../action/FloatingActions';
import uuid from 'uuid/v4';
import {
  screenHeaderToolBarTheme,
  screenHeaderToolBarMobileTheme,
  screenFooterToolBarTheme,
} from '../../theme/toolBarTheme';
import FilterBar from '../filter/FilterBar';
import {isMobile} from '../UtilityFunctions';
import ToolBar from '../toolBar/ToolBar';
import {View} from '@applane/react-core-components';

export class ScreenComponent extends React.Component {
  constructor(props) {
    super(props);
    let {uid, fetchUriEvent} = props;
    this.fetchUriEvent = fetchUriEvent || `${uid || uuid()}_fetchUri`;
  }
  render() {
    let {
      screen: Component,
      floatingActions,
      header,
      footer,
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
      state,
      setState,
      setTabsState,
      ...rest
    } = this.props;

    let navigationScreenState =
      (navigation &&
        navigation.getScreenState &&
        navigation.getScreenState()) ||
      {};
    let componentProps = {
      fetchUriEvent: this.fetchUriEvent,
      navigation,
      eventDispatcher,
      screenState,
      setScreenState,
      state,
      setState,
      setTabsState,
    };
    let {filterAction} = navigationScreenState;
    let absoulteTopFilterBar = void 0;
    if (filterAction) {
      if (filterAction.position === 'absolute') {
        absoulteTopFilterBar = (
          <FilterBar
            absolutePosition={'top'}
            {...componentProps}
            {...filterAction}
          />
        );
      } else {
        header = {...header};
        header.actions = header.actions ? [...header.actions] : [];
        header.actions.unshift(
          <FilterBar {...componentProps} {...filterAction} />,
        );
      }
    }

    return (
      <View style={{flex: 1, overflow: 'hidden',background: '#0095C9'}}>
        {absoulteTopFilterBar}
        {header && (
          <ToolBar
            {...(isMobile
              ? screenHeaderToolBarMobileTheme
              : screenHeaderToolBarTheme)}
            {...componentProps}
            {...header}
          />
        )}
        <Component
          {...componentProps}
          {...rest}
          dataParams={screenState && screenState.dataParams}
        />
        {footer && (
          <ToolBar
            {...screenFooterToolBarTheme}
            {...componentProps}
            {...footer}
          />
        )}
        <FloatingActions
          floatingActions={floatingActions}
          {...componentProps}
          {...rest}
        />
      </View>
    );
  }
}

export default ScreenComponent;
