import React from 'react';
import moment from 'moment';
import { mamcLogo } from '../../images';
import vars from '../../theme/vars';
import { getConfiguration } from '../../AppServices';

const ConsultationReceipt = ({
  patientInfo,
  opdDetails,
  data,
  barcode,
  roomsInfo,
  departmentInfo,
  remarks,
  ODPNumber,
}) => {
  const configData = getConfiguration();
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      height: 592,
      width: 842,
      flex: 2,
    }}
    >
      <div style={{
        flex: 1,
        display: 'flex',
        borderRight: '1px dashed black',
      }}
      />
      <div style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        paddingLeft: 18,
        marginRight: 5,
      }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 13,
          alignItems: 'center',
          height: 70,
          position: 'relative',
        }}
        >
          <div
            style={{
              position: 'absolute',
              left: '-15px',
            }}
          >
            <img
              src={mamcLogo}
              alt="logo"
              style={{ height: 50 }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              textAlign: 'right',
              marginTop: '5px',
            }}
          >
            <div style={{
              fontFamily: 'Arial',
              fontWeight: 'bold',
              fontSize: 13,
            }}
            >
              {'consultation.labels.hospitalName'.getLabel()}
            </div>
            <div style={{
              fontFamily: 'Arial',
              fontWeight: 'bold',
              textAlign: 'right',
              fontSize: 13,
            }}
            >
              {'consultation.labels.hospitalAddress'.getLabel()}
            </div>
          </div>
        </div>
        <div style={{
          flex: 1, display: 'flex', flexDirection: 'column',
        }}
        >
          <div style={{
            flexDirection: 'row', justifyContent: 'space-between', display: 'flex',
          }}
          >
            <div style={{ ...vars.headings.h18 }}>
              {`Website: ${configData?.website}`}
            </div>
            <div style={{ ...vars.headings.h18 }}>
              {`Phone: ${configData?.contactInfo}`}
            </div>
          </div>
          <div>
            <div style={{ ...vars.headings.h18 }}>
              {`Email: ${configData?.email}`}
            </div>
          </div>
          <div
            style={{
              marginTop: 5,
              height: 1,
              borderBottom: '1px solid #828282',
            }}
          />
          <div style={{
            marginTop: 14,
          }}
          >
            <div
              style={{
                ...vars.headings.h13,
                fontFamily: 'Arial',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {'consultation.labels.outPatientDepartment'.getLabel()}
            </div>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 6,
          }}
          >
            <div style={{
              ...vars.headings.h17,
              color: vars.colors.grey.color3,
              fontSize: '7px',
            }}
            >
              {'consultation.labels.patientDetails'.getLabel()}
            </div>
            <div style={{
              ...vars.headings.h17,
              color: vars.colors.grey.color3,
              fontSize: '7px',
            }}
            >
              {`Date : ${moment(data?._createdOn).format('DD.MM.YYYY hh:mm A')}`}
            </div>
          </div>
          <div
            style={{
              marginTop: 5,
              height: 1,
              borderBottom: '1px solid #828282',
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <table style={{
              border: 'none!important',
              marginTop: 12,
              width: '100%',
            }}
            >
              <tr>
                <td
                  style={{
                    color: vars.colors.grey.color3,
                    fontSize: '9px',
                    paddingRight: '8px',
                  }}
                >
                  {`${'consultation.labels.patientName'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                }}
                >
                  {patientInfo?.patientName}
                </td>
                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '8px',
                  paddingLeft: 28,
                }}
                >
                  {`${'consultation.labels.speciality'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                }}
                >
                  {opdDetails?.name}
                </td>
                <td rowSpan="4" style={{ textAlign: 'center' }}>
                  <img
                    src={barcode}
                    width={57}
                    alt="barcode"
                  />
                </td>
              </tr>
              <tr>
                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '8px',
                }}
                >
                  {`${patientInfo?.relation}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                }}
                >
                  {patientInfo?.relationName}
                </td>
                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '8px',
                  paddingLeft: 28,
                }}
                >
                  {`${'consultation.labels.opdNumber'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                }}
                >
                  {data?.result?.ODPNumber ? data?.result?.ODPNumber : ODPNumber}
                </td>
              </tr>
              <tr>
                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '8px',
                }}
                >
                  {`${'consultation.labels.gender'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                }}
                >
                  {patientInfo?.gender.charAt(0).toUpperCase() + patientInfo?.gender.slice(1)}
                </td>
                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '8px',
                  paddingLeft: 28,
                }}
                >
                  {`${'consultation.labels.department'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                }}
                >
                  {departmentInfo}
                </td>
              </tr>
              <tr>
                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '8px',
                }}
                >
                  {`${'consultation.labels.age'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                }}
                >
                  {`${patientInfo?.age || ''} ${patientInfo?.ageType || ''}`}
                </td>
                {/* <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '8px',
                  paddingLeft: 28,
                }}
                >
                  {`${'consultation.labels.room'.getLabel()}: `}
                </td> */}
                {/* <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                }}
                >
                  {roomsInfo?.map((item) => item.name).toString()}
                </td> */}
                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '8px',
                  paddingLeft: 28,
                }}
                >
                  {`${'consultation.labels.charge'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                }}
                >
                  {opdDetails?.charge}
                </td>
              </tr>
              <tr>
                <td style={{
                  color: vars.colors.grey.color3,
                  fontSize: '9px',
                  paddingRight: '8px',
                }}
                >
                  {`${'consultation.labels.mobileNo'.getLabel()}: `}
                </td>
                <td style={{
                  color: vars.colors.grey.color1,
                  fontSize: '9px',
                }}
                >
                  {patientInfo?.mobile}
                </td>
              </tr>
            </table>
          </div>
          <div>
            <div style={{ flexDirection: 'row', marginTop: 24, display: 'flex' }}>
              <div style={{
                color: vars.colors.grey.color3,
                fontSize: '9px',
              }}
              >
                {`${'consultation.labels.room'.getLabel()}: `}
              </div>
              <div style={{
                paddingLeft: 8,
                color: vars.colors.grey.color1,
                fontSize: '9px',
              }}
              >
                {roomsInfo?.map((item) => item.name).toString()}
              </div>
            </div>
            <div style={{ flexDirection: 'row', marginTop: 5, display: 'flex' }}>
              <div style={{
                color: vars.colors.grey.color3,
                fontSize: '9px',
              }}
              >
                {`${'consultation.labels.remarks'.getLabel()}: `}
              </div>
              <div style={{
                paddingLeft: 8,
                color: vars.colors.grey.color1,
                fontSize: '9px',
              }}
              >
                {remarks}
              </div>
            </div>
            <div style={{ flexDirection: 'row', marginTop: 5, display: 'flex' }}>
              <div style={{
                color: vars.colors.grey.color3,
                fontSize: '9px',
              }}
              >
                {`${'consultation.labels.diagnosis'.getLabel()}: `}
              </div>
              <div style={{ paddingLeft: 8 }}>
                ----------------
              </div>
            </div>
          </div>
          <div style={{
            display: 'flex',
            borderTop: '1px solid grey',
            flex: 1,
          }}
          >
            <div style={{
              flex: 2,
              display: 'flex',
              borderRight: '1px solid grey',
              justifyContent: 'center',
            }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                height: 24,
                borderBottom: '1px solid grey',
                width: '100%',
                alignItems: 'center',
              }}
              >
                <span
                  style={{
                    ...vars.headings.h13,
                  }}
                >
                  {'consultation.labels.investigations'.getLabel()}
                </span>
              </div>
            </div>
            <div style={{
              flex: 3,
              display: 'flex',
              justifyContent: 'center',
            }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                height: 24,
                borderBottom: '1px solid grey',
                width: '100%',
                alignItems: 'center',
              }}
              >
                <span
                  style={{
                    ...vars.headings.h13,
                  }}
                >
                  {'consultation.labels.treatments'.getLabel()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};
export default ConsultationReceipt;
