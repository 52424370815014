import {
  backCrossIcon,
  newSearchIcon,
  filterIcon,
  crossIconPng,
  whiteCrossIcon,
  filterActive,
  filterInactive,
  // filterActiveIcon,
  // filterAppliedIcon,
} from '../images';
import { crossIcon } from '../images';
import { Headline15, Headline9, Headline11, Headline3 } from './Headlines';
import { manazeV6Colors } from './manazeV6Colors';
import { dropDownShadow, shadow } from './shadows';
import vars from './vars';
let {
  neutral_3,
  neutral_8,
  surface_white,
  neutral_2,
  primary_2,
  primary_5,
  neutral_1,
  neutral_10,
  negative_communication_2,
} = manazeV6Colors;

export const filterGroupTheme = {
  icon: filterInactive,
  activeIcon: filterInactive,
  appliedIcon: filterActive,
  containerStyle: {
    cursor: 'pointer',
    alignSelf: 'center',
    justifyContent: 'center',
    padding: 6,
    borderRadius: 6,
    width: 36,
    height: 36,
  },
  hoverContainerStyle: {
    backgroundColor: primary_5,
  },
  activeContainerStyle: {
    backgroundColor: primary_5,
  },
  dropdownStyle: {
    width: 300,
    height: 400,
    backgroundColor: 'white',
    ...dropDownShadow,
    borderRadius: 8,
    overflow: 'hidden',
  },
  crossIcon: whiteCrossIcon,
  imageStyle: { width: 14, height: 14 },
  crossIconStyle: { width: 14, height: 14 },
  titleStyle: {
    ...vars.headings.h14,
    color: vars.colors.white,
    textTransform: 'uppercase',
  },
  labelContainerStyle: {
    width: 102,
    maxWidth: '35%',
    overflow: 'hidden',
    backgroundColor: vars.colors.secondary.color4,
  },
  filterContainerStyle: {
    flex: 1,
    overflow: 'hidden',
    backgroundColor: surface_white,
  },
  headerContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 8,
    paddingTop: 12,
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: vars.colors.secondary.color2,
  },
  footerContainerStyle: {
    flexDirection: 'row',
    backgroundColor: vars.colors.secondary.color2,
    justifyContent: 'flex-end',
    padding: 10,
    paddingRight: 12,
  },
  labelValueContainerStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 2,
    marginBottom: 2,
    paddingLeft: 8,
    paddingRight: 4,
    height: 32,
  },
  selectedLabelValueContainerStyle: {
    backgroundColor: surface_white,
  },
  labelValueTextStyle: {
    flex: 1,
    ...vars.headings.h13,
    color: vars.colors.grey.color3,
    cursor: 'pointer',
    numberOfLines: 1,
  },
  selectedLabelValueTextStyle: {
    ...vars.headings.h12,
    cursor: 'pointer',
    color: vars.colors.secondary.color2,
  },
  labelValueDotStyle: {
    backgroundColor: vars.colors.secondary.color2,
    width: 6,
    height: 6,
    borderRadius: 3,
  },
  labelValueCountContainerStyle: {
    borderRadius: 50,
    backgroundColor: vars.colors.secondary.color2,
    padding: 5,
    paddingTop: 1,
    paddingBottom: 1,
  },
  labelValueCountTextStyle: {
    color: surface_white,
    ...Headline15,
  },
  labelValueCrossIcon: crossIconPng,
  labelValueCrossIconStyle: {},
  container: {
   
  },
  text: {
    ...vars.headings.h13,
    color: vars.colors.white,
  },
  badgeCountContainerStyle: {
    position: 'absolute',
    top: -5,
    right: -3,
    borderRadius: 50,
    padding: 4,
    backgroundColor: vars.colors.secondary.color2,
    paddingTop: 1,
    paddingBottom: 1,
  },
  badgeCountTextStyle: {
    color: surface_white,
    ...Headline15,
  },
  buttonStyle: {
    apply: {
      color: surface_white,
      hoverColor: surface_white,
      height: 32,
      borderRadius: 4,
      labelStyle: {
        ...vars.headings.h13,
        color: vars.colors.secondary.color2,
        numberOfLines: 1,
      },
    },
    reset: {
      color: vars.colors.secondary.color2,
      hoverColor: vars.colors.secondary.color2,
      height: 32,
      labelStyle: {
        numberOfLines: 1,
        ...vars.headings.h9,
        color: vars.colors.white,
      },
    },
  },
  backdropStyle: { backgroundColor: 'rgba(0,0,0,0.2)' },

};

export const filterGroupMobileTheme = {
  ...filterGroupTheme,
  modalPosition: 'screenBottom',
  dropdownStyle: {
    width: '100%',
    height: '70%',
    backgroundColor: 'white',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflow: 'hidden',
    ...dropDownShadow,
  },
  backdropStyle: { backgroundColor: 'rgba(0,0,0,0.2)' },
};

export const filterGroupSearchTheme = {
  crossIcon: backCrossIcon,
  searchIcon: newSearchIcon,
  iconStyle: {
    marginRight: 12,
    height: 16,
    width: 16,
  },
  containerStyle: {
    height: 32,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,
    marginBottom: 8,
    borderWidth: 1,
    borderColor: neutral_8,
    borderRadius: 4,
  },
  inputStyle: {
    outline: 'none',
    flex: 1,
    borderWidth: 0,
    paddingLeft: 12,
    paddingRight: 12,
    ...Headline9,
    color: neutral_3,
  },
};
