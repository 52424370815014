export default {
  labels: {
    name: 'Name',
    description: 'Description',
    employee: 'Employee',
    from_date: 'From Date',
    to_date: 'To Date',
    ctc_amount: 'CTC(Per Month)',
    package_review_date: 'Package Review Date',
    fixed_salary: 'Fixed',
    incentive_salary: 'Incentive',
    mark_salary: 'Unapprove Package',
    basicDetails: 'BASIC DETAILS',
    earning: 'EARNINGS',
    performance: 'PERFORMANCE',
    deduction: 'DEDUCTION',
    bonus: 'BONUS',
    structure_name: 'Structure Name',
    structure_id: 'Structure Id',
  },
  placeholders: {
    structure_Name: 'Structure Name',
    structure_id: 'Structure Id',
    templateName: 'Template Name',
    description: 'Description',
    employee: 'Employee',
    from_date: 'From Date',
    to_date: 'To Date',
    ctc_amount: 'CTC(Per Month)',
    package_review_date: 'Package Review Date',
    fixed_salary: 'Fixed',
    incentive_salary: 'Incentive',
    salary_component_id: 'Salary Component',
    ctc_percentage: 'Percentage',
    amount: 'Amount',
    reason: 'Reason',
  },
  headers: {
    salary_component_id: 'Salary Component',
    ctc_percentage: 'Percentage',
    amount: 'Amount',
    from_date: 'From Date',
    to_date: 'To Date',
    reason: 'Reason',
  },
  titles: {
    addTemplate: 'Add a Template',
    editTemplate: 'Edit a Template',
  },
  subtitles: {
    addTemplate: 'You can add a custom template with different salary components',
  },
  buttons: {
    addStructure: 'Add Structure',
  },
  messages: {
    salaryStructureSavedSuccessfully: 'Salary structure has been saved successfully.',
    salaryStructureUpdatedSuccessfully: 'Salary structure has been updated successfully.',
  },
};
