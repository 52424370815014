import React from 'react';
import {
  View, Text, Image, isMobile,
} from '../../../app-components';
import { userIcon } from '../../../images';
import { h16_Regular as h16Regular, h30_Regular as h30Regular } from '../../../theme/fonts';
import { WithModal } from '../../../npms/react-with-modal';
import { Avatar } from '../../../npms/react-avatar';
import { getUser } from '../../../AppServices';
import ProfileDropdown from './ProfileDropdown';

const Header = (props) => {
  const userData = getUser() || {};
  const { patient = {}, photo } = userData || {};
  const firstName = patient?.patientName;

  if (!userData) {
    return <View />;
  }

  if (userData?.username) {
    return (
      <View
        style={{
          flex: 1,
          maxHeight: 50,
          minHeight: 50,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#0095C9',
          paddingTop: 40,
          paddingLeft: 20,
          paddingBottom: 20,
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text style={{
            ...h30Regular, color: '#fff', opacity: 0.75, marginLeft: 10,
          }}
          >
            Welcome,
          </Text>
          <Text
            style={{
              ...h30Regular,
              color: '#fff',
              fontWeight: 800,
              maxWidth: 400,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            &nbsp;
            {firstName}
          </Text>
        </View>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              ...h16Regular,
              fontWeight: 700,
              color: '#fff',
              marginLeft: 15,
              marginRight: 15,
              maxWidth: 160,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {firstName}
          </Text>

          <WithModal
            autoHide
            dropdownStyle={{
              width: 100,
              height: 78,
            }}
            renderModal={({ frameStyle, hideModal }) => (
              <ProfileDropdown
                frameStyle={frameStyle}
                hideModal={hideModal}
                {...props}
              />
            )}
          >
            <View
              style={{
                flexDirection: 'row',
                position: 'relative',
                overflow: 'hidden',
                // alignItems: 'center/',
                cursor: 'pointer',
                height: isMobile ? 35 : 50,
                width: isMobile ? 35 : 50,
                borderRadius: 25,
              }}
            >
              {photo ? (
                <Image />
              ) : (
                <Avatar icon={userIcon} />
              )}
              {/* <View style={{paddingLeft: 5, overflow: 'hidden', maxWidth: 70}}>
                <Text numberOfLines={1} style={{...headerTextStyle}}>
                  {user?.name || 'NA'}
                </Text>
              </View> */}
            </View>
          </WithModal>
        </View>
      </View>
    );
  } return <View />;
};

export default Header;
