import React from 'react';
import PropTypes from 'prop-types';
import FormGroupHeader from './FormGroupHeader';
import {View} from '@applane/react-core-components';
import {getRenderComponent} from '@applane/react-input-wrapper';

class FormGroup extends React.Component {
  constructor(props) {
    super(props);
    let {expanded = false} = props;
    this.state = {expanded};
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.expandable &&
      !this.state.expanded &&
      !prevProps.error &&
      this.props.error
    ) {
      this.onExpandClick();
    }
  }

  populateRows = ({rows, columns = [], columnsPerRow = 1}) => {
    let {
      rowContainerStyle,
      columnContainerStyle,
      renderColumn,
      columnGap,
      rowGap,
    } = this.props;

    let maxIterationCount = Math.ceil(columns.length / columnsPerRow);
    let lastColumnIndex = -1;
    for (let i = 0; i < maxIterationCount; i++) {
      let row = [];
      let componentCount = 0;
      for (let j = lastColumnIndex + 1; j < columns.length; j++) {
        lastColumnIndex = j;
        let column = columns[j];
        if (!column) {
          continue;
        }
        let columnComponent = void 0;
        let columnStyle = void 0;
        if (!renderColumn) {
          if (React.isValidElement(column)) {
            columnComponent = column;
            columnStyle = {flex: 1, overflow: 'hidden'};
          } else {
            continue;
          }
        } else {
          let {expand, ...restColumn} = column;
          if (expand) {
            rowContainerStyle = {
              ...rowContainerStyle,
              flex: 1,
            };
            columnContainerStyle = {
              ...columnContainerStyle,
              flex: 1,
            };
          }
          column = restColumn;
          let renderColumnInfo = renderColumn(column) || {};
          if (renderColumnInfo) {
            columnStyle = renderColumnInfo.style;
            columnComponent = renderColumnInfo.component;
          }
        }
        if (columnComponent) {
          if (!columnStyle || columnStyle.width) {
            columnComponent = (
              <View
                style={{
                  ...columnContainerStyle,
                  ...columnStyle,
                }}>
                {columnComponent}
              </View>
            );
          } else {
            columnComponent = (
              <View style={columnStyle}>
                <View style={columnContainerStyle}>{columnComponent}</View>
              </View>
            );
          }

          if (row.length && columnGap) {
            row.push(<View style={{width: columnGap}} />);
          }
          row.push(columnComponent);
          componentCount += 1;
          if (componentCount === columnsPerRow) {
            break;
          }
        }
      }
      if (componentCount) {
        if (componentCount < columnsPerRow) {
          for (let i = componentCount; i < columnsPerRow; i++) {
            //so that last column do not spread all over row
            if (columnGap) {
              row.push(<View style={{width: columnGap}} />);
            }
            row.push(
              <View
                style={{
                  flex: 1,
                }}
              />,
            );
          }
        }

        if (rows.length && rowGap) {
          rows.push(<View style={{height: rowGap}} />);
        }
        let Row = (
          <View
            style={{
              flexDirection: 'row',
              ...rowContainerStyle,
            }}>
            {row}
          </View>
        );
        rows.push(Row);
      }
    }
  };

  getFormGroup = props => {
    const {
      columns = [],
      direction,
      groups,
      columnsPerRow = 1,
      children,
    } = props;
    let rows = [];
    if (children) {
      React.Children.forEach(children, child => {
        columns.push(child);
      });
    }
    if (groups && groups.length) {
      let rowGroupRows = [];
      for (let index = 0; index < groups.length; index++) {
        let group = groups[index];
        let groupRows = this.getFormGroup({
          ...group,
          columnsPerRow: group.columnsPerRow || columnsPerRow,
        });
        if (groupRows && groupRows.length) {
          if (direction === 'row') {
            let {width, maxWidth, style} = group;
            if (groupRows.length) {
              let groupStyle = style || {flex: 1, overflow: 'hidden'};
              if (width) {
                groupStyle = {width, ...style};
              } else if (maxWidth) {
                groupStyle.maxWidth = maxWidth;
              }
              rowGroupRows.push(<View style={groupStyle}>{groupRows}</View>);
            }
          } else {
            rows.push.apply(rows, groupRows);
          }
        }
      }
      if (rowGroupRows.length) {
        rows.push(<View style={{flexDirection: 'row'}}>{rowGroupRows}</View>);
      }
    } else if (columns && columns.length) {
      this.populateRows({
        columns,
        rows,
        columnsPerRow,
      });
    }
    return rows.length ? rows : void 0;
  };

  onExpandClick = () => {
    let newExpanded = !this.state.expanded;
    if (this.props.error) {
      newExpanded = true;
    }
    this.setState({expanded: newExpanded});
  };

  expandComponent = () => {
    let {expandable, expandComponent} = this.props || {};
    if (expandable && expandComponent) {
      let expandComponentProps = {
        onClick: this.onExpandClick,
        expanded: this.state.expanded,
      };
      return getRenderComponent(expandComponent, expandComponentProps);
    }
  };

  renderHeader = () => {
    let {
      headerType,
      header,
      label,
      icon,
      actions,
      error,
      subTitle,
      renderHeader,
      labelStyle,
    } = this.props;
    let expandComponent = this.expandComponent();
    if (!icon && !label && !actions && !expandComponent) {
      return;
    }
    let headerProps = {
      error,
      type: headerType,
      icon,
      subTitle,
      label,
      actions,
      expandComponent,
      ...header,
    };
    if (labelStyle) {
      headerProps.labelStyle = labelStyle;
    }
    return getRenderComponent(renderHeader || FormGroupHeader, headerProps);
  };

  render() {
    let {
      expandable,
      separator,
      style,
      extraStyle,
      groupsContainerStyle,
      separatorStyle,
      render,
      flex,
      error,
      renderToogleGroups,
      ...restProps
    } = this.props;
    let renderComponent = getRenderComponent(render, restProps);
    if (!renderComponent) {
      renderComponent = this.getFormGroup(restProps);
    }
    let ToggleGroupsComponent = renderToogleGroups
      ? getRenderComponent(renderToogleGroups(this.props))
      : void 0;
    if (!renderComponent && !ToggleGroupsComponent) {
      return null;
    }
    if (flex) {
      style = {
        ...style,
        flex,
        overflow: 'hidden',
      };
      groupsContainerStyle = {
        ...groupsContainerStyle,
        flex: 1,
        overflow: 'hidden',
      };
    }
    if (extraStyle) {
      style = {
        ...style,
        ...extraStyle,
      };
    }
    return (
      <React.Fragment>
        <View style={style}>
          {this.renderHeader()}
          {ToggleGroupsComponent}
          {!expandable || this.state.expanded ? (
            <View style={groupsContainerStyle}>{renderComponent}</View>
          ) : (
            void 0
          )}
        </View>
        {separator ? <View style={separatorStyle} /> : null}
      </React.Fragment>
    );
  }
}

FormGroup.defaultProps = {
  style: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  separatorStyle: {
    marginLeft: 24,
    marginRight: 24,
    borderBottomWidth: 0.3,
    borderColor: 'grey',
    opacity: 0.2,
  },
  columnContainerStyle: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 12,
    paddingBottom: 12,
  },
};

FormGroup.propTypes = {
  label: PropTypes.string, // used for header label
  icon: PropTypes.string, // used for header icon
  actions: PropTypes.array, // used for header actions
  renderHeader: PropTypes.func, // used to show header
  separator: PropTypes.bool,
  columnsPerRow: PropTypes.number,
  columns: PropTypes.arrayOf(PropTypes.object),
  groups: PropTypes.arrayOf(PropTypes.object),
  direction: PropTypes.string,
  columnGap: PropTypes.number,
  rowGap: PropTypes.number,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool, // default false, work only when expandable true
  expandComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]), // will be shown only when expandable true
  renderColumn: PropTypes.func,
  style: PropTypes.object,
  groupsContainerStyle: PropTypes.object,
  separatorStyle: PropTypes.object,
  rowContainerStyle: PropTypes.object,
  columnContainerStyle: PropTypes.object,
  children: PropTypes.element,
};

export default FormGroup;
