import React from 'react';
import PropTypes from 'prop-types';
import {WithReactForm} from './WithReactFormContext';
import {resolveExp} from '@applane/react-utility-functions';
import {getRenderComponent, getFieldError} from './Utility';

class FormField extends React.Component {
  setFocus = props => {
    const {field, form_context: {setFocus} = {}} = this.props;
    if (!setFocus) {
      return;
    }
    if (field && typeof field === 'string') {
      props = {
        field,
        ...props,
      };
    }
    setFocus(props);
  };

  setValue = props => {
    const {field, form_context: {setValue} = {}} = this.props;
    if (!setValue) {
      return;
    }
    if (field && typeof field === 'string') {
      props = {
        field,
        ...props,
      };
    }
    setValue(props);
  };

  getMeta = () => {
    const {field, form_context: {setFocus} = {}, form_state = {}} = this.props;
    if (!field) {
      return;
    }
    let {focusField, focussedFields} = form_state;
    let isActive = void 0;
    let isTouched = void 0;
    if (setFocus) {
      isActive = focusField === field;
      isTouched = focussedFields && focussedFields[field] ? true : false;
    }
    let meta = {isActive, isTouched};
    let error = getFieldError({field, form_state});
    if (error) {
      meta.error = error;
    }
    return meta;
  };

  getData = () => {
    const {form_state: {data = {}} = {}} = this.props;
    return data;
  };

  render() {
    const {
      field,
      form_state: {data = {}} = {},
      form_context: {navigation, eventDispatcher, setValue, setFocus} = {},
      children,
      ...restProps
    } = this.props;
    if (!children) {
      return null;
    }
    let childrenProps = {
      ...restProps,
      ...this.getMeta(),
      data,
      navigation,
      eventDispatcher,
      setValue,
      setFocus,
      getData: this.getData,
    };
    if (field) {
      childrenProps.field = field;
      childrenProps.value = data ? resolveExp(data, field) : void 0;
      childrenProps.setValue = this.setValue;
      childrenProps.setFocus = this.setFocus;
    }
    return getRenderComponent(children, childrenProps);
  }
}

FormField.propTypes = {
  field: PropTypes.string,
};

FormField = WithReactForm(FormField, {
  key: 'form',
});

export default FormField;
