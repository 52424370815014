/* eslint-disable camelcase */
import { manazeV6Colors } from '../../theme/manazeV6Colors';
import { colors } from '../../theme/colors';
import {
  Headline11,
  Headline14,
  Headline9,
} from '../../theme/Headlines';
import { h16_Regular } from '../../theme/fonts';

const {
  neutral_1,
  neutral_2,
  neutral_3,
  neutral_4,
  neutral_7,
  primary_2,
  negative_communication_3,
  surface_white,
} = manazeV6Colors;
const { topLabelColor } = colors;

const defaultInputStyle = {
  outline: 'none',
  border: 0,
  padding: 0,
  backgroundColor: 'transparent',
};

const errorMessageStyle = {
  errorMessageContainerStyle: { paddingTop: 3 },
  errorMessageIconStyle: {
    paddingRight: 3,
  },
  errorMessageStyle: {
    ...Headline11,
    color: negative_communication_3,
  },
};

const helperTextStyle = {
  helperTextContainerStyle: { paddingTop: 3 },
  helperTextIconStyle: {
    paddingRight: 3,
  },
  helperTextStyle: {
    ...Headline14,
    color: neutral_3,
  },
};

const labelStyle = {
  labelStyle: {
    ...Headline9,
    color: neutral_4,
  },
  floatingLabelStyle: {
    ...Headline14,
    color: neutral_2,
  },
  hoverLabelStyle: {
    // color: neutral_2,
  },
  activeLabelStyle: {
    color: primary_2,
  },
};

export const filledInputTheme = {
  style: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_7,
    borderRadius: 4,
    backgroundColor: surface_white,
    overflow: 'hidden',
    minHeight: 38,
  },
  hoverStyle: {
    // backgroundColor: neutral_100_background,
  },
  activeStyle: {
    borderColor: primary_2,
  },
  errorStyle: {
    borderColor: negative_communication_3,
  },
  inputStyle: {
    ...defaultInputStyle,
    paddingTop: 9,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    ...Headline9,
    color: neutral_1,
  },
  floatingLabelInputStyle: {
    ...defaultInputStyle,
    paddingTop: 21,
    paddingBottom: 2,
    paddingLeft: 12,
    paddingRight: 12,
    ...Headline9,
    color: neutral_1,
  },
  labelContainerStyle: {
    top: 14,
    left: 12,
  },
  floatingLabelContainerStyle: {
    top: 5,
  },
  ...labelStyle,
  ...errorMessageStyle,
  ...helperTextStyle,
  inputErrorStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    alignItems: 'center',
  },
  inputSelectorContainerStyle: {
    paddingLeft: 8,
    paddingRight: 8,
    justifyContent: 'center',
  },
};

export const standardInputTheme = {
  style: {
    justifyContent: 'flex-end',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_7,
  },
  hoverStyle: {
    // color: neutral_1,
  },
  errorStyle: {
    borderColor: negative_communication_3,
  },
  activeStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    borderBottomWidth: 2,
    borderColor: primary_2,
    borderStyle: 'solid',
  },
  inputStyle: {
    ...defaultInputStyle,
    paddingTop: 17,
    paddingBottom: 7,
    ...Headline9,
    color: neutral_1,
  },
  labelContainerStyle: {
    top: 17,
    left: 0,
  },
  floatingLabelContainerStyle: {
    top: 5,
  },
  ...labelStyle,
  ...errorMessageStyle,
  ...helperTextStyle,
  inputErrorStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 6,
    alignItems: 'flex-end',
  },
  inputSelectorContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 6,
    justifyContent: 'flex-end',
  },
};

export const leftLabelStyle = {
  labelStyle: {
    ...Headline9,
    color: neutral_4,
  },
  mandatoryStyle: {
    color: 'red',
    paddingLeft: 2,
  },
};

export const topLabelStyle = {
  labelContainerStyle: {
    marginBottom: 8,
  },
  labelStyle: {
    ...h16_Regular,
    color: topLabelColor,
  },
  mandatoryStyle: {
    color: 'red',
    paddingLeft: 2,
  },
};
