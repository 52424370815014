import React from 'react';
import {View, Text} from '@applane/react-core-components';
import {getResolvedStyle, getRenderComponent, isFloatingLabel} from './Utility';
import FloatingAnimatedLabel from './FloatingAnimatedLabel';

class FloatingInputLabel extends React.Component {
  render() {
    let {
      label,
      floatingLabel,
      labelPosition,
      error,
      hover,
      isActive,
      labelClassName,
      labelContainerStyle,
      floatingLabelContainerStyle,
      labelStyle,
      floatingLabelStyle,
      hoverLabelStyle,
      topHoverLabelStyle,
      activeLabelStyle,
      errorLabelStyle,
      getInputRef,
      mandatory,
      renderMandatory,
      mandatoryStyle,
    } = this.props;
    if (labelPosition === undefined && isFloatingLabel(this.props)) {
      labelPosition = 'floating';
    }
    if (labelPosition === 'floating') {
      if (floatingLabel) {
        label = floatingLabel;
      }
      labelStyle = {
        ...labelStyle,
        ...floatingLabelStyle,
      };
      hoverLabelStyle = {
        ...hoverLabelStyle,
        ...topHoverLabelStyle,
      };
    }
    labelStyle = getResolvedStyle({
      hover,
      error,
      isActive,
      style: labelStyle,
      activeStyle: activeLabelStyle,
      hoverStyle: hoverLabelStyle,
      errorStyle: errorLabelStyle,
    });
    return (
      <FloatingAnimatedLabel
        className={labelClassName}
        style={labelContainerStyle}
        floatingStyle={floatingLabelContainerStyle}
        labelStyle={labelStyle}
        labelPosition={labelPosition}
        label={label}
        getInputRef={getInputRef}>
        <View style={{flexDirection: 'row'}}>
          <Text style={labelStyle}>{label}</Text>
          {mandatory
            ? getRenderComponent(renderMandatory) || (
                <Text
                  style={{
                    ...labelStyle,
                    ...mandatoryStyle,
                    numberOfLines: void 0,
                  }}>
                  *
                </Text>
              )
            : void 0}
        </View>
      </FloatingAnimatedLabel>
    );
  }
}

FloatingInputLabel.defaultProps = {
  labelContainerStyle: {
    top: 13,
    left: 12,
  },
  floatingLabelContainerStyle: {
    left: 12,
    top: 5,
  },
  labelStyle: {
    fontSize: 14,
    color: '#8E8E8E',
  },
  floatingLabelStyle: {
    fontSize: 10,
    color: '#4B4B4B',
  },
  hoverLabelStyle: {
    color: '#a2a2a2',
  },
  activeLabelStyle: {
    color: '#4276EC',
  },
};

export default FloatingInputLabel;
