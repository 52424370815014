export default {
  title: {
    updateSchedule: 'Update Schedule',
    addSchedule: 'Add Schedule',
  },
  description: {
    detailsForTheSchedule: 'Details for the Schedule.',
  },
  labels: {
    date: 'DATE',
    shiftType: 'SHIFT NAME',
    effectiveFrom: 'EFFECTIVE FROM',
    effectiveTo: 'EFFECTIVE TO',
    effectiveDays: 'EFFECTIVE DAYS',
  },
  days: {
    monday: 'MONDAY',
    tuesday: 'TUESDAY',
    wednesday: 'WEDNESDAY',
    thrusday: 'THRUSDAY',
    friday: 'FRIDAY',
    saturday: 'SATURDAY',
    sunday: 'SUNDAY',
  },
  message: {
    scheduleUpdated: 'Schedule Updated',
  },
  options: {
    onlyWeekend: 'Only Weekend',
    allDays: 'All Days',
    weekdaysSchedule: 'Weekdays Schedule',
  },
};
