import React from 'react';
import ReactDOM from 'react-dom';
import './labels/en';
// import './date';
import App from './App';
import { reportWebVitals } from './Lib/constants';

import './index.css';

const MainApp = () => <App />;

ReactDOM.render(<MainApp />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
