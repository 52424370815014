import {
  autoSuggestMobileTheme,
  autoSuggestTheme,
  chipsMobileStyle,
  chipsStyle,
  roundedChipsMobileStyle,
  roundedChipsStyle,
  roundedChipsWithAvatarMobileStyle,
  roundedChipsWithAvatarStyle,
} from './editorTheme';
import {Headline9, Headline8} from './Headlines';
import {manazeV6Colors} from './manazeV6Colors';

const {
  primary_2,
  primary_3,
  neutral_2,
  neutral_6,
  surface_white,
} = manazeV6Colors;

export const autoSuggestFilterTheme = {
  autoSuggestModalTheme: autoSuggestTheme,
  buttonContainerStyle: {
    borderRadius: 20,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    width: 120,
    borderColor: neutral_6,
    borderWidth: 1.5,
    height: 36,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  activeButtonContainerStyle: {
    borderColor: primary_2,
  },
  dropdownStyle: {height: 260, width: 200},
  imageTextStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'space-between',
  },
  valueContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyConent: 'space-between',
    overflow: 'hidden',
    width: 80,
    marginLeft: 2,
    marginRight: 2,
  },
  valueTextStyle: {
    flex: 1,
    ...Headline8,
    color: neutral_2,
    cursor: 'pointer',
    numberOfLines: 1,
  },
  countConatiner: {
    backgroundColor: surface_white,
    borderRadius: 10,
    minWidth: 20,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 2,
    paddingRight: 2,
    marginRight: 2,
  },
  countTextStyle: {
    ...Headline8,
    color: neutral_2,
  },
  imageStyle: {width: 12, height: 12, marginLeft: 5, objectFit: 'contain'},
  crossImageStyle: {
    width: 12,
    height: 12,
    marginLeft: 5,
    objectFit: 'contain',
  },
  chipsStyle: {
    ...chipsStyle,
  },
  roundedChipsStyle: {
    ...roundedChipsStyle,
  },
  roundedChipsWithAvatarStyle: {
    ...roundedChipsWithAvatarStyle,
  },
};

export const autoSuggestFilterMobileTheme = {
  autoSuggestModalTheme: autoSuggestMobileTheme,
  buttonContainerStyle: {
    paddingLeft: 4,
    paddingRight: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  valueContainer: {
    flexDirection: 'row',
    justifyConent: 'space-between',
    width: 60,
    marginLeft: 2,
    marginRight: 2,
  },
  valueTextStyle: {
    ...Headline9,
    color: primary_2,
    cursor: 'pointer',
    numberOfLines: 1,
    flex: 1,
  },
  countConatiner: {
    borderRadius: 10,
    minWidth: 20,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 2,
    paddingRight: 2,
  },
  countTextStyle: {
    ...Headline8,
    color: primary_3,
  },
  imageStyle: {
    marginLeft: 8,
    width: 10,
  },
  crossImageStyle: {
    width: 10,
    height: 10,
    marginLeft: 8,
    resizeMode: 'contain',
  },
  chipsStyle: {
    ...chipsMobileStyle,
  },
  roundedChipsStyle: {
    ...roundedChipsMobileStyle,
  },
  roundedChipsWithAvatarStyle: {
    ...roundedChipsWithAvatarMobileStyle,
  },
};
