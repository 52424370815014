export default {
  labels: {
    employeeName: 'Employee Name',
    employeeId: 'Employee Id',
    shiftNames: 'Shift Names',
    actions: 'Actions',
    department: 'Department',
    employeeType: 'Employee Type',
    firstName: 'First Name',
    speciality: 'Speciality',
  },
  title: {
    employeeSchedule: 'Employee Schedule',
    exportToExcel: 'Export to Excel',
  },
  buttons: {
    addSchedule: 'Add Schedule',
  },
  header: {
    dateRange: 'Date Range',
    shiftType: 'Shift Name',
    weekdaysSchedule: 'Weekdays Schedule',
    status: 'Status',
  },
  placeholder: {
    selectDepartment: 'Select departments',
    employeeType: 'Select employee type',
  },
};
