import React, {Component} from 'react';
import {
  Text,
  View,
  Image,
  TouchableOpacity,
} from '@applane/react-core-components';

export default class CheckBoxComponent extends Component {
  onPress = () => {
    const {data, setValue, field} = this.props;
    let value = data && field && data[field];
    if (value === 'undefined') {
      value = true;
      setValue && setValue({field, value});
    } else {
      setValue && setValue({field, value: !value});
    }
  };
  render() {
    let {field, data, placeholder, theme, style} = this.props;

    if (typeof placeholder === 'function') {
      placeholder = placeholder({data});
    }
    let value = data && field && data[field] && data[field];
    let {selectedIcon, boxStyle, containerStyle, textStyle, boxImageStyle} =
      style || theme;

    return (
      <TouchableOpacity onPress={this.onPress}>
        <View style={{...styles.containerStyle, ...containerStyle}}>
          <View style={{...styles.boxStyle, ...boxStyle}}>
            {value
              ? selectedIcon && (
                  <Image
                    source={selectedIcon}
                    style={{...styles.boxImageStyle, ...boxImageStyle}}
                  />
                )
              : null}
          </View>
          <Text style={textStyle}>{placeholder}</Text>
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = {
  containerStyle: {flexDirection: 'row', alignItems: 'center'},
  boxStyle: {
    height: 15,
    width: 15,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxImageStyle: {width: 10, height: 10},
};
