import React from 'react';
import Barcode from 'react-barcode';
import { Image, Text, View } from '../../app-components';
import { barcode } from '../../images';
import vars from '../../theme/vars';

const barCodeConfig = {
  width: 1.5,
  height: 75,
  format: 'CODE128',
  displayValue: false,
  fontOptions: '',
  textAlign: 'center',
  textPosition: 'bottom',
  textMargin: 2,
  fontSize: 20,
  background: vars.colors.secondary.color4,
  lineColor: '#000000',
  margin: 10,
  marginTop: undefined,
  marginBottom: undefined,
  marginLeft: undefined,
  marginRight: undefined,
  renderer: 'img',
};

const BarCode = (props) => {
  const {
    value = 'UHID',
    allowScan = true,
  } = props;

  return (
    <View
      style={{
        width: allowScan ? 250 : 180,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {
      allowScan
        ? <Barcode {...barCodeConfig} {...props} value={value} /> : <Image source={barcode} />
    }
      <View>
        <Text
          style={[
            vars.headings.h13,
            { color: '#fff' },
          ]}
        >
          UHID
        </Text>
        <Text
          style={[
            vars.headings.h8,
            { color: '#fff', marginTop: 6 },
          ]}
        >
          {value}
        </Text>
      </View>
    </View>
  );
};

export default BarCode;
