import React from 'react';
import { View, Text } from '@applane/react-core-components';
import {
  FormField as ReactFormField,
  NestedField,
  FormCompositeField,
} from '@applane/react-form';
import { inputTypes, renderTypes } from './types';
import fieldContainers from './FieldContainers';
import { getRenderComponent } from '../../UtilityFunctions';

const WithContainer = (Component) => {
  class WithContainerWrapper extends React.Component {
    render() {
      const {
        containers = fieldContainers,
        container,
        ...restProps
      } = this.props;
      let renderComponent = <Component {...restProps} />;
      if (container) {
        let ContainerComponent = container;
        if (typeof container === 'string') {
          ContainerComponent = containers && containers[container];
        }
        if (ContainerComponent) {
          renderComponent = getRenderComponent(ContainerComponent, {
            ...restProps,
            children: renderComponent,
          });
        }
      }
      return renderComponent;
    }
  }
  return WithContainerWrapper;
};

export class FieldComponent extends React.PureComponent {
  render() {
    let {
      types = inputTypes,
      renders = renderTypes,
      type,
      editable = true,
      children,
      Component,
      disabled,
      componentProps,
      ...restProps
    } = this.props;
    if (typeof editable === 'function') {
      editable = editable(restProps);
    }

    if (typeof disabled === 'function') {
      disabled = disabled(restProps);
    }
    const renderComponentProps = {
      ...restProps,
      type,
      editable,
      disabled,
    };

    let renderComponent = null;
    if (type && typeof type === 'string') {
      const TypeComponent = editable
        ? types && types[type]
        : renders && renders[type];
      if (TypeComponent) {
        renderComponent = getRenderComponent(
          TypeComponent,
          renderComponentProps,
        );
      }
    }
    if (!renderComponent) {
      if (Component) {
        renderComponent = getRenderComponent(Component, {
          ...renderComponentProps,
          ...componentProps,
        });
      } else {
        renderComponent = getRenderComponent(
          restProps.render || children,
          renderComponentProps,
        );
      }
    }
    if (!renderComponent) {
      renderComponent = (
        <View>
          <Text>No Editor found</Text>
        </View>
      );
    }
    return renderComponent;
  }
}

FieldComponent = WithContainer(FieldComponent);

export class FormField extends React.Component {
  render() {
    const {
      types,
      renders,
      containers,
      children,
      fieldKey,
      ...restProps
    } = this.props;
    const { field, fieldType } = restProps;
    let FormFieldComponent = ReactFormField;
    if (fieldType === 'nested') {
      FormFieldComponent = NestedField;
    } else if (fieldType === 'composite') {
      FormFieldComponent = FormCompositeField;
    }
    return (
      <FormFieldComponent key={fieldKey || field} {...restProps}>
        <FieldComponent {...this.props} />
      </FormFieldComponent>
    );
  }
}

export default ({ types, renders, containers } = {}) => {
  types = {
    ...inputTypes,
    ...types,
  };
  renders = {
    ...renderTypes,
    ...renders,
  };
  containers = {
    ...fieldContainers,
    ...containers,
  };
  class JSONFormField extends React.Component {
    render() {
      return (
        <FormField
          types={types}
          renders={renders}
          containers={containers}
          {...this.props}
        />
      );
    }
  }
  return JSONFormField;
};
