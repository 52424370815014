import React from 'react';
import moment from 'moment';

import {
  Text,
  View,
  Image,
  TouchableOpacity,
  isMobile,
  ActivityIndicator,
} from '../../../app-components';
import {
  lifeLine,
  Edit,
  vehicleFlippedIcon,
  videoCall, pdf, profile,
} from '../../../images';
import {
  h18_Medium,
  h20_Regular,
  h14_Regular,
  h14_AvenirNext,
  h11_Medium,
  h12_Medium,
} from '../../../theme/fonts';
import { StartVideoCallButton } from '../../../app-components/buttons/Buttons';

import { patientWatingRoomCard } from '../../../theme/shadows';
import {
  getImageUrl,
  invoke,
  post,
} from '../../../AppServices';
import { colors } from '../../../theme/mayaMdColors';
import { ROUTES } from '../../../Lib/constants';

const { themeColor, themeContainer, themeTextColor } = colors;

class VideoButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { date: void 0 };
  }

  getVideoCallButton = () => (
    <TouchableOpacity
      style={{ cursor: 'pointer' }}
      onPress={() => {
        const { item = {}, navigation } = this.props || {};
        navigation.push({
          view: ROUTES.patientVideoCalling.name,
          params: { item },
        });
      }}
    >
      <StartVideoCallButton
        label="Join Session"
        image={videoCall}
        imageStyle={{ marginLeft: 15, width: 28, height: 28 }}
      />
    </TouchableOpacity>
  );

  getMobileVideoCallButton = () => (
    <TouchableOpacity
      style={{
        cursor: 'pointer',
        backgroundColor: themeColor,
        paddingLeft: 20,
        borderRadius: 8,
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 20,
      }}
      onPress={() => {
        const { item = {}, navigation } = this.props || {};
        navigation.push({
          view: 'videoSessionMobile',
          params: { item },
        });
      }}
    >
      <Image style={{ height: 28, width: 28 }} source={videoCall} />
    </TouchableOpacity>
  );

  render() {
    let {
      session_id = null,
      status = '',
      appointmentId,
      item,
    } = this.props;
    if (item && item?.session_id) {
      session_id = item?.session_id;
      appointmentId = item?._id;
    }
    return (
      <View style={{ justifyContent: 'center' }}>
        {session_id && item?.status === 'pending' && item?._id === appointmentId ? (
          isMobile ? (
            this.getMobileVideoCallButton()
          )
            : (
              this.getVideoCallButton()
            )
        )
          : (
            <View style={{ padding: 8, backgroundColor: '#fff', borderRadius: 8 }}>
              <Text style={{ ...h11_Medium, color: '#DA4E91' }}>
                {(item?.status === 'completed' || item?.status === 'cancelled') ? 'Meeting Ended' : 'wait For Doctor to Start Session'}
              </Text>
            </View>
          )}
      </View>
    );
  }
}

class Reschedule extends React.Component {
  render() {
    const { item, navigation, t } = this.props || {};
    const { doctor_id: doctorData } = item;

    return (
      <TouchableOpacity
        onPress={() => {
          navigation.reset({
            view: 'booking',
            params: {
              item: { ...doctorData },
              isReschedule: true,
              res: item,
            },
          });
        }}
        style={{
          ...theme.ExtraButtons,
          cursor: 'pointer',
          backgroundColor: themeColor,
          display: 'flex',
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        <Text
          style={{
            ...h12_Medium,
            color: '#fff',
          }}
        >
          Re-schedule Appointment
        </Text>
      </TouchableOpacity>
    );
  }
}

const calculateTime = (time, reverse = false) => {
  const CurrentTime = moment(new Date());
  const GivenTime = moment(time);

  return reverse
    ? GivenTime.diff(CurrentTime, 'seconds')
    : CurrentTime.diff(GivenTime, 'seconds');
};

class Cancel extends React.Component {
  // constructor(props){
  //   super(props)
  // }
  render() {
    const {
      item, navigation, appointmentDatas,
    } = this.props || {};
    const { time_slot = {} } = item || {};
    let loading = true;
    if (item) {
      loading = false;
    }
    if (loading) {
      return <ActivityIndicator />;
    }
    return (
      <TouchableOpacity
        onPress={() => {
          // if (calculateTime(time_slot?.start_time, true) < 6 * 60 * 60) {
          //   showMessage({
          //     message: 'Cannot be cancelled as less than 6 hours remain.',
          //   });
          // }
          // else {
          // navigation.push({
          //   view: 'cancel-modal',
          //   params: { item, appointmentDatas },
          //   modal: true,
          //   modalProps: {
          //     autoHide: true,
          //     minHeight: 130,
          //     height: 240,
          //     // width: isMobile ? 300 : 700,

          //     position: 'screenCenter',
          //     // ...confirmModalProps,
          //   },
          // });
          loading = true;
          invoke({
            id: 'setAppointmentStatus',
            paramValue: {
              data: {
                status: 'cancelled',
                _id: item?._id,
              },
            },
          })
            .then(() => {
              loading = false;
              navigation.reset('patient-waiting-room');
              appointmentDatas();
            })
            .catch((err) => {
              loading = false;
              const message = JSON.parse(err?.message)?.error?.message;
              // showMessage({ message });
            });
          // }
          // handleCancelReschedule({item});
        }}
        style={{
          ...theme.ExtraButtons,
          cursor: 'pointer',
          backgroundColor: themeColor,
        }}
      >
        <Text style={{ ...h12_Medium, color: '#fff' }}>
          Cancel Appointment
        </Text>
      </TouchableOpacity>
    );
  }
}

class CardView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonState: null,
    };
  }

  checkToday = (time) => {
    const today = new Date();
    if (
      time.getDay() === today.getDay()
      && time.getDate() === today.getDate()
      && time.getMonth() === today.getMonth()
      && today.getFullYear() === time.getFullYear()
    ) {
      return true;
    }
    return false;
  };

  imageClick = (medical_report) => {
    const { navigation } = this.props;
    navigation.push({
      view: 'pdfReader',
      params: {
        dataArr: medical_report,
      },
      modal: true,
      modalProps: {
        autoHide: true,
        minHeight: 180,
        height: 600,
        width: isMobile ? 300 : 700,
        position: 'screenCenter',
      },
    });
  };

  getText = ({
    text,
    value,
    medical_Report_Slice,
    medical_Report_Slice_Second,
    medical_report = [],
    patient_symptoms = [],
  }) => {
    if (isMobile) {
      return void 0;
    }
    if (!medical_report.length && !value && !patient_symptoms.length) {
      return void 0;
    }
    return (
      <View style={{ flexDirection: 'row', padding: 5 }}>
        <View style={{ width: 200 }}>
          <Text
            style={{ ...h14_AvenirNext, opacity: 0.87, color: themeTextColor }}
          >
            {text}
          </Text>
        </View>
        {value ? (
          <View
            style={{
              flexDirection: 'row',
              maxWidth: 300,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            <Text style={{ ...h14_AvenirNext, color: themeTextColor }}>
              {value}
            </Text>
          </View>
        ) : (
          void 0
        )}
        {medical_Report_Slice ? (
          <View style={{ flexDirection: 'row' }}>
            {Array.isArray(medical_Report_Slice)
              ? medical_Report_Slice.map((item) => (
                <TouchableOpacity
                  style={{
                    height: 38,
                    width: 38,
                    marginRight: 15,
                    borderRadius: 4,
                    cursor: 'pointer',
                  }}
                  onPress={() => this.imageClick(medical_report)}
                >
                  {/* {getFileExtension(item.name) === 'pdf' ? (
                    <Image source={pdf} style={{ height: 38, width: 38 }} />
                  ) : (
                    <Image
                      source={getImageUrl(item?.thumbnail)}
                      style={{ height: 38, width: 38 }}
                    />
                  )} */}
                </TouchableOpacity>
              ))
              : null}
            {Array.isArray(medical_Report_Slice_Second)
            && medical_Report_Slice_Second.length ? (
              <TouchableOpacity
                style={{
                  height: 38,
                  width: 38,
                  borderRadius: 4,
                  justifyContent: 'center',
                  backgroundColor: '#383838',
                  alignItems: 'center',
                }}
                onPress={() => this.imageClick(medical_report)}
              >
                <Text style={{ color: '#fff' }}>
                  {`+${medical_Report_Slice_Second.length}`}
                </Text>
              </TouchableOpacity>
              ) : null}
          </View>
        ) : (
          void 0
        )}
        {Array.isArray(patient_symptoms) && patient_symptoms.length ? (
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: '80%' }}>
            {patient_symptoms.map((item) => (
              <View style={theme.SymptomsChip}>
                <Text style={{ ...h14_AvenirNext, color: '#383838' }}>
                  {item}
                </Text>
              </View>
            ))}
          </View>
        ) : (
          void 0
        )}
      </View>
    );
  };

  chip = ({ iconName, text }) => (
    <View style={theme.chip}>
      <Image
        style={{
          width: 19,
          height: 16,
          objectFit: 'contain',
        }}
        source={iconName || lifeLine}
      />
      <View style={{ marginLeft: 10, paddingRight: 6 }}>
        <Text
          style={{
            ...h14_AvenirNext,
            color: themeColor,
          }}
        >
          {text}
        </Text>
      </View>
    </View>
  );

  submitData = ({ item }) => {
    post({
      data: { _id: item?._id },
      updates: {
        checkedIn: true,
      },
      model: 'appointments',
    })
      .then((res) => {
        const { result = [] } = res || {};
        if (Array.isArray(result) && result.length) {
          this.setState({ updateItem: result[0]?.result });
        }
      })
      .catch((err) => {
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>: submitData -> err', err);
      });
  };

  // handleCancelReschedule = ({item = {}, type = 'cancel'}) => {
  //   const {patient_id = {}, doctor_id = {}, _id = ''} = item || {};
  //   post({
  //     // id: 'requestLists',
  //     data: {},
  //     updates: {
  //       doctor: {_id: doctor_id?._id},
  //       appointment: {_id},
  //       patient: {_id: patient_id?._id},
  //       request_type: type, //(cancel / reschedule)
  //     },
  //     model: 'Appointmentrequests',
  //   })
  //     .then((res) => {
  //       this.setState({buttonState: {type, status: 'pending'}});
  //       // console.log('res', res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  render() {
    // console.log('at patienct card', this.props.t);
    const { border, doctorImage, textTheme } = theme;
    const {
      item, size, index, socketData, selectedTab = '',
    } = this.props;
    let { checkedIn = false } = item || {};
    if (this?.state?.updateItem && this?.state?.updateItem?.checkedIn) {
      checkedIn = this?.state?.updateItem?.checkedIn;
    }
    const { session_start, _id: appointmentId } = socketData || {};
    const {
      booking_fee,
      time_slot,
      doctor_id: doctorData,
      patient_id: patientData,
      illness_description,
      patient_symptoms,
      organization_id,
      session_end = null,
      medical_report = [],
      lab_result = [],
      e_prescription_report = [],
    } = item;
    let medical_Report_Slice = medical_report;
    let medical_Report_Slice_Second = [];
    let lab_result_slice = lab_result;
    let lab_result_slice_second = [];
    const IconLength = 4;
    if (medical_report.length > IconLength) {
      medical_Report_Slice = medical_report.slice(0, IconLength);
      medical_Report_Slice_Second = medical_report.slice(
        IconLength,
        medical_report.length,
      );
    }
    if (lab_result.length > IconLength) {
      lab_result_slice = lab_result.slice(0, IconLength);
      lab_result_slice_second = lab_result.slice(IconLength, lab_result.length);
    }
    const { t, i18n } = this.props;
    return (
      <View
        style={{
          ...patientWatingRoomCard,
          marginBottom: size - 1 === index && size > 1 ? 20 : 0,
          ...border,
          flexShrink: 0,
          backgroundColor: themeContainer,
          marginLeft: 20,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            paddingBottom: 23,
            paddingTop: 27,
            justifyContent: 'center',
            alignItems: 'center',
            paddingRight: 20,
            paddingLeft: 20,
            // paddingLeft: 34,
          }}
        >
          {isMobile ? (
            void 0
          ) : (
            <View
              style={{
                position: 'relative',
                width: '90px',
                height: '90px',
                overflow: 'hidden',
                borderRadius: '50%',
              }}
            >
              <Image
                source={profile}
                style={{ width: 90, height: 90, borderRadius: 90 }}
              />
            </View>
          )}
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              flex: 1,
              paddingBottom: 3,
              paddingTop: 5,
            }}
          >
            <View
              style={{
                alignItems: 'flexStart',
                flexDirection: 'column',
                paddingLeft: 17,
                paddingRight: 17,
              }}
            >
              <Text style={{ ...h20_Regular, color: themeTextColor }}>
                {doctorData?.firstName}
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 10,
                  marginTop: 6,
                }}
              >
                <Text
                  style={{
                    ...h14_AvenirNext,
                    color: themeTextColor,
                    maxWidth: 300,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {/* {item?.speciality?.name} */}
                  {doctorData?.specialities_procedures?.length > 0
                    && doctorData?.specialities_procedures.map((item, index) => {
                      const separator = (index + 1
                          < doctorData?.specialities_procedures.length
                          && ', ')
                        || ' ';
                      return (
                        item.speciality?.name
                        && item.speciality?.name + separator
                      );
                    })}
                  <Text
                    style={{
                      color: themeTextColor,
                      ...h14_AvenirNext,
                      maxWidth: 300,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {doctorData?.qualification_description}
                  </Text>
                </Text>
              </View>
              {/* Chip experience_in_years and name */}
              {isMobile ? (
                void 0
              ) : (
                <View style={{ flexDirection: 'row' }}>
                  {doctorData?.joiningDate
                    ? this.chip({
                      iconName: lifeLine,
                      text: `${moment().diff(doctorData?.joiningDate, 'months')} months exp`
                      ,
                    })
                    : void 0}
                </View>
              )}
            </View>
            {/* Start Video Session Button */}
            <View
              style={{
                marginRight: 42,
              }}
            >
              <VideoButton
                {...this.props}
                {...time_slot}
                session_start={session_start}
                session_end={session_end}
                appointmentId={appointmentId}
                item={item}
              />
            </View>
          </View>
        </View>
        {/* Lower Box */}
        <View
          style={{
            backgroundColor: 'rgba(220, 220, 220, 0.1)',
            padding: 10,
            flexDirection: 'row',
          }}
        >
          <View style={{ flex: 5, paddingLeft: 22 }}>
            {this.getText({
              text: "Patient's Name",
              value: patientData?.patientName,
            })}
            {this.getText({
              text: 'Consultation Timings',
              value: moment(time_slot?.start_time).format(
                'MMM, DD YY | hh:mm A',
              ),
            })}
            {this.getText({
              text: 'Patient’s Query',
              value: illness_description,
            })}
            {this.getText({
              text: 'Patient’s Symptoms',
              patient_symptoms,
            })}
            {this.getText({
              text: 'Attachments',
              medical_Report_Slice,
              medical_Report_Slice_Second,
              medical_report,
            })}
          </View>
          <View
            style={{
              flex: 1,
              paddingRight: 37,
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            {item?.session_start || socketData?.session_start ? (
              void 0
            ) : (
              <TouchableOpacity
                style={{ cursor: 'pointer', padding: 5 }}
                onPress={() => {
                  this.props.navigation.reset({
                    view: 'edit-booking',
                    params: {
                      item: { ...doctorData },
                      selectedTimeSlot: time_slot,
                      selectdDate: time_slot?.start_time,
                      titleText: 'Edit Your Booking Details!',
                      isCancel: true,
                      res: item,
                    },
                  });
                }}
              >
                <Text style={{ ...h14_Regular, color: themeTextColor }}>
                  Edit
                </Text>
                {/* <Image source={Edit} style={{height: 18, width: 45}}></Image> */}
              </TouchableOpacity>
            )}
            {!checkedIn && this.checkToday(new Date(time_slot?.start_time)) ? (
              <TouchableOpacity
                onPress={() => {
                  this.submitData({ item });
                }}
                style={{
                  ...theme.ExtraButtons,
                }}
              >
                <Text style={{ ...h12_Medium, color: '#fff' }}>
                  Check In
                </Text>
              </TouchableOpacity>
            ) : (
              void 0
            )}
            {(item?.status === 'completed' || item?.status === 'cancelled') ? (
              void 0
            ) : (
              <>
                <Cancel
                  item={item}
                  {...this.props}
                  buttonState={this.state.buttonState}
                />
                <Reschedule
                  item={item}
                  {...this.props}
                  buttonState={this.state.buttonState}
                />
              </>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const theme = {
  ExtraButtons: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: themeColor,
    marginTop: 5,
    borderRadius: 10,
    cursor: 'pointer',
  },
  SymptomsChip: {
    backgroundColor: themeColor,
    padding: 3,
    borderWidth: 1,
    borderColor: 'rgba(56, 56, 56,0.25)',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 50,
    marginRight: 8,
    marginBottom: 10,
  },
  main: {
    flex: 1,
    marginTop: 24,
    marginLeft: 78,
    marginRight: 78,
  },
  appointmentText: {
    ...h18_Medium,
    color: '#979797',
  },
  border: {
    // flex: 1,
    overflow: 'hidden',
    ...patientWatingRoomCard,
    // border: 'solid 1px #979797',
    // flexDirection: 'row',
    marginTop: 18,
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
  },
  doctorImage: {
    height: 100,
    width: 100,
    borderRadius: 100,
  },
  textTheme: {
    ...h14_Regular,
    color: '#000000',
    marginTop: 5,
  },
  chip: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: '#f9f9f9',
    width: 'fit-content',
    paddingTop: 7,
    paddingLeft: 13,
    paddingBottom: 7,
    paddingRight: 13,
    marginRight: 10,
  },
};

export default CardView;
