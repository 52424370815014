import React from 'react';
import isFunction from 'lodash/isFunction';

import { View } from '@applane/react-core-components';

import { get } from 'lodash';
import theme from './theme';
import { ROUTES } from '../../Lib/constants';
import { getConfigurationAPI, setConfiguration } from '../../AppServices';

export default ({ initUser, landingView, loginView }) => {
  class AuthLoadingScreen extends React.Component {
    componentDidMount() {
      this.checkIfUserExist();
    }

    fetchConfigurations = async () => {
      try {
        const resp = await getConfigurationAPI();
        setConfiguration(get(resp, 'result.0'));
      } catch (err) {
        console.log('Error', err);
      }
    }

    redirect = (res) => {
      if (res && res.user) {
        if (res.user.enforceChangePassword) {
          this.props.navigation.reset(ROUTES.ResetPassword.name);
        } else {
          let _landingView = landingView;
          if (isFunction(_landingView)) {
            _landingView = _landingView({ user: res.user });
          }
          this.props.navigation.reset(_landingView);
        }
      } else {
        this.props.navigation.reset(loginView);
      }
    };

    checkIfUserExist = async () => {
      initUser()
        .then(async (data) => {
          await this.fetchConfigurations();
          this.redirect(data);
        })
        .catch((e) => {
          console.error('Error in check if user exists', { error: e });
        });
    };

    render() {
      const { containerStyle } = theme;
      return <View style={containerStyle} />;
    }
  }

  return AuthLoadingScreen;
};
