import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './react-summernote-module/dist/react-summernote.css';
import './react-summernote-module/bootstrap/dist/css/bootstrap.css';
import {WithInputWrapper, getInputProps} from '@applane/react-input-wrapper';

import ReactSummernote from './react-summernote-module/dist/react-summernote';
import $ from 'jquery';
window.jQuery = $;
require('bootstrap/js/modal');
require('bootstrap/js/dropdown');
require('bootstrap/js/tooltip');

class RichTextInput extends React.Component {
  onImageUpload = (files, editor) => {
    // let {upload, getImageUrl, showMessage} = this.props;
    // let filesKeys = files ? Object.keys(files) : [];
    // if (!filesKeys.length) {
    //   return;
    // }
    // upload &&
    //   upload(files['0'])
    //     .then(result => {
    //       let {key, name} = result || {};
    //       name = name && encodeURIComponent(name);
    //       let imageUrl = getImageUrl && getImageUrl({key, name});
    //       editor && editor(imageUrl, name);
    //     })
    //     .catch(err => showMessage && showMessage(err.message || err, 3000));
  };

  componentDidMount() {
    document
      .getElementsByClassName('summerNote')[0]
      .setAttribute('style', 'width:100%;');
  }

  onChangeValue = text => {
    let {onChangeValue, onChangeText} = this.props;
    onChangeText && onChangeText(text);
    onChangeValue && onChangeValue(text);
  };

  render() {
    let {height, toolbar} = this.props;
    let {
      value,
      placeholder,
      onChange,
      onChangeText,
      onChangeValue,
      ...restInputProps
    } = getInputProps(this.props);
    return (
      <ReactSummernote
        {...restInputProps}
        className={'summerNote'}
        value={value}
        onImageUpload={this.onImageUpload}
        options={{
          height: height || 150,
          dialogsInBody: true,
          placeholder: placeholder,
          toolbar: toolbar || [
            // ['fontname', ['fontname', 'fontsize']],
            ['color', ['color']],
            ['font', ['bold', 'underline', 'italic', 'clear' ]],
            ['para', ['paragraph', 'ul', 'ol'/* , 'height' */]],
            // ['insert', ['addVariable', 'addTemplate', 'link']],
            // ['table', ['table']],
            // ['style', ['style']],
            ['view', ['fullscreen', 'codeview']],
          ],
        }}
        onChange={this.onChangeValue}
      />
    );
  }
}
export default WithInputWrapper(RichTextInput);
