import React from 'react';
import { Image, Text, View } from '../../app-components';
import { exclamationFilled } from '../../images';
import vars from '../../theme/vars';

const TableNoData = () => (
  <View style={{
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  }}
  >
    <View style={{
      flexDirection: 'row',
      alignItems: 'center',
    }}
    >
      <Image
        source={exclamationFilled}
        style={[
          {
            marginRight: 10,
          }]}
      />
      <Text
        style={[
          vars.headings.h10,
          {
            color: '#fff',
          }]}
      >
        No Data Available
      </Text>
    </View>
  </View>
);

export default TableNoData;
