import React, {Component} from 'react';
import {
  View,
  ScrollView,
  TouchableOpacity,
  Text,
  Platform,
  Image,
} from '@applane/react-core-components';

let isScrollPending = false;

export default class TabBar extends Component {
  state = {showScrollIcon: false};

  getSelectedTabRef = ({tab, tabKey, selectedTab}) => ref => {
    if (ref) {
      let isSelectedTab = selectedTab === tabKey;
      if (Array.isArray(tab)) {
        isSelectedTab = tab.find(_tab => _tab.tabKey === selectedTab);
      }
      if (isSelectedTab) {
        this.selectedTabRef = ref;
      }
    }
  };

  getTab = ({tab, tabKey, index}) => {
    if (!tab) {
      return null;
    }
    let {
      renderItem,
      selectedTab,
      navigation,
      getTabNavigation,
      eventDispatcher,
      uid,
    } = this.props;
    if (!Array.isArray(tab) && tab.renderItem) {
      renderItem = tab.renderItem;
    }
    if (!renderItem) {
      return null;
    }
    let renderItemProps = {
      uid,
      navigation,
      getTabNavigation,
      eventDispatcher,
      key: tabKey,
      tab,
      tabKey,
      tabIndex: index,
      selectedTab,
      getRef:
        Platform.OS === 'web'
          ? this.getSelectedTabRef({tab, tabKey, selectedTab})
          : void 0,
    };

    if (typeof renderItem === 'function') {
      renderItem = renderItem(renderItemProps);
    } else if (React.isValidElement(renderItem)) {
      renderItem = React.cloneElement(renderItem, renderItemProps);
    }
    return renderItem;
  };

  scrollTo = action => {
    if (Platform.OS !== 'web') {
      return;
    }
    let scrollWidth = 0;
    const maxScrollWidth =
      this.scrollRef.scrollWidth - this.scrollRef.clientWidth;
    if (action === 'right' && this.scrollRef.scrollLeft < maxScrollWidth) {
      if (maxScrollWidth - this.scrollRef.scrollLeft > 50) {
        scrollWidth = 50;
      } else {
        scrollWidth = maxScrollWidth - this.scrollRef.scrollLeft;
      }
      this.scrollRef.scrollLeft = this.scrollRef.scrollLeft + scrollWidth;
    } else if (action === 'left' && this.scrollRef.scrollLeft > 0) {
      if (this.scrollRef.scrollLeft > 50) {
        scrollWidth = 50;
      } else {
        scrollWidth = this.scrollRef.scrollLeft;
      }
      this.scrollRef.scrollLeft = this.scrollRef.scrollLeft - scrollWidth;
    }
  };

  scrollToSelectedTab = () => {
    Platform.OS === 'web' &&
      this.selectedTabRef &&
      this.selectedTabRef.scrollIntoViewIfNeeded &&
      this.selectedTabRef.scrollIntoViewIfNeeded(false);
  };

  scrollToSelectedTabInTimer = timer => {
    if (this.scrollTimeout) {
      return;
    }
    if (!isScrollPending) {
      isScrollPending = true;
      this.scrollTimeout = setTimeout(_ => {
        this.scrollToSelectedTab();
        this.scrollTimeout = void 0;
        isScrollPending = false;
      }, timer || 0);
    } else if (!this.scrollInterval) {
      this.scrollInterval = setInterval(_ => {
        this.scrollIntervalCount = (this.scrollIntervalCount || 0) + 1;
        if (!isScrollPending || this.scrollIntervalCount > 20) {
          isScrollPending = false;
          clearInterval(this.scrollInterval);
          this.scrollInterval = void 0;
          this.scrollToSelectedTabInTimer(1000);
        }
      }, 100);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedTab !== this.props.selectedTab) {
      this.scrollToSelectedTab();
    }
  }

  onLayout = e => {
    if (Platform.OS !== 'web') {
      return;
    }
    const {nativeEvent: {layout: {x = 0, width = 0} = {}} = {}} = e;
    if (width && this.layoutWidth !== width) {
      this.layoutWidth = width;
      this.scrollToSelectedTabInTimer();
    }
    const overflow = this.scrollRef.scrollWidth > this.scrollRef.offsetWidth;
    this.state.showScrollIcon !== overflow &&
      this.setState({showScrollIcon: overflow}, this.onScroll);
  };

  onScroll = () => {
    if (Platform.OS !== 'web') {
      return;
    }
    if (!this.scrollRef.scrollLeft) {
      this.setState({disabledIcon: 'left'});
    } else if (
      this.scrollRef.scrollLeft ===
      this.scrollRef.scrollWidth - this.scrollRef.clientWidth
    ) {
      this.setState({disabledIcon: 'right'});
    } else {
      this.setState({disabledIcon: false});
    }
  };

  renderAction = ({scrollTo, icon, text}) => {
    let {
      scrollIconTextStyle = {padding: 6, fontWeight: 'bold'},
      scrollIconStyle,
    } = this.props;
    const {disabledIcon} = this.state;
    const disabled = disabledIcon === scrollTo;
    return (
      <TouchableOpacity
        onPress={() => {
          !disabled && this.scrollTo(scrollTo);
        }}
        style={{
          cursor: disabled ? 'default' : 'pointer',
          userSelect: 'none',
          opacity: disabled ? 0.3 : 1,
        }}>
        {icon ? (
          <Image source={icon} style={scrollIconStyle} />
        ) : (
          <Text style={scrollIconTextStyle}>{text}</Text>
        )}
      </TouchableOpacity>
    );
  };

  render() {
    let {
      tabs,
      minTabScrollCount,
      containerStyle,
      minTabContainerStyle,
      showScrollIcon,
      leftScrollIcon,
      rightScrollIcon,
      scrollIconContainerStyle,
      showsHorizontalScrollIndicator,
      contentContainerStyle
    } = this.props;
    if (!tabs) {
      return null;
    }

    let newTabs = {};
    for (let tabKey in tabs) {
      let tabInfo = tabs[tabKey];

      if (tabInfo) {
        let {groupName} = tabInfo;

        if (groupName) {
          newTabs[groupName] = newTabs[groupName] || [];
          newTabs[groupName].push({tabKey, tab: tabInfo});
        } else {
          newTabs[tabKey] = tabInfo;
        }
      }
    }
    let tabKeys = Object.keys(newTabs);
    if (!tabKeys.length) {
      return null;
    }
    let renderComponent = tabKeys.map((tabKey, index) => {
      const tab = newTabs[tabKey];
      return this.getTab({tab, tabKey, index});
    });
    if (minTabScrollCount && tabKeys.length < minTabScrollCount) {
      return (
        <View style={{flex: 1, flexDirection: 'row', ...minTabContainerStyle}}>
          {renderComponent}
        </View>
      );
    } else {
      return (
        <View
          style={{flex: 1, flexDirection: 'row', ...containerStyle}}
          onLayout={this.onViewLayout}>
          <ScrollView
            style={{flex: 1, scrollBehavior: 'smooth'}}
            getRef={ref => {
              this.scrollRef = ref;
            }}
            onScroll={this.onScroll}
            onLayout={this.onLayout}
            horizontal={true}
            contentContainerStyle={contentContainerStyle}
            showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}>
            {renderComponent}
          </ScrollView>
          {showScrollIcon && this.state.showScrollIcon ? (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                ...scrollIconContainerStyle,
              }}>
              {this.renderAction({
                scrollTo: 'left',
                icon: leftScrollIcon,
                text: '<',
              })}
              {this.renderAction({
                scrollTo: 'right',
                icon: rightScrollIcon,
                text: '>',
              })}
            </View>
          ) : null}
        </View>
      );
    }
  }
}
