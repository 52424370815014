const decodeAction = ({path, action}) => {
  if (!action) {
    return path;
  }
  if (typeof action === 'string') {
    path += ':' + action;
    return path;
  } else if (action.view) {
    path += ':' + action.view;
    return decodeAction({path, action: action.action});
  }
};

export const getPathName = () => {
  let {pathname, hash, search} =
    typeof window !== undefined ? window.location : {pathname: '/'};
  return {pathName: pathname};
};

export const setPathName = ({pathName, replace, remove}) => {
  let {pathname: currentPathName, hash, search} =
    typeof window !== undefined ? window.location : {pathname: '/'};

  let history = window.history;
  if (history && currentPathName !== pathName) {
    if (remove) {
      let removeCount = 1;
      if (typeof remove === 'number') {
        removeCount = 1;
      }
      history.go(removeCount);
    } else if (replace) {
      history.replaceState({}, '', pathName);
    } else {
      history.pushState({}, '', pathName);
    }
  }
};

export const setLocationView = ({view, action}) => {
  let path = '/';
  if (view && typeof view === 'string') {
    path += view;
  }
  path = decodeAction({path, action});

  let {pathname: currentPathname, search: currentSearch} = window.location;

  let history = window.history;
  if (history && currentPathname !== path) {
    history.pushState({}, '', path);
  }
};
export const getLocationView = ({pathName}) => {
  let slashIndex = pathName.indexOf('/');

  if (slashIndex >= 0) {
    pathName = pathName.substring(slashIndex + 1);
  }
  if (!pathName) {
    return void 0;
  }
  let actions = pathName.split(':');
  if (actions && actions.length) {
    let view = {
      view: actions[0],
    };

    if (actions.length > 1) {
      let lastViewAction = view;
      for (let index = 1; index < actions.length; index++) {
        lastViewAction.action = {
          view: actions[index],
        };
        lastViewAction = lastViewAction.action;
      }
    }
    return view;
  }
};

export const getDefaultLocationView = (view) => {
  let {pathname, hash, search} =
    typeof window !== undefined ? window.location : {pathname: '/'};

  if (pathname === '/login' || pathname === '/otp') {
    pathname = '/';
  }
  let locationView = getLocationView({pathName: pathname}) || view;
  // console.log('@@@@@ locationview', locationView);
  return locationView;
};
