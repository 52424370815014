/* eslint-disable camelcase */
import { manazeV6Colors } from './manazeV6Colors';
import vars from './vars';

import { Headline11, Headline9, Headline15 } from './Headlines';

const {
  neutral_1,
  neutral_2,
  neutral_4,
  neutral_7,
  neutral_9,
  neutral_10,
  primary_2,
  negative_communication_3,
  surface_white,
} = manazeV6Colors;

const defaultInputStyle = {
  outline: 'none',
  border: 0,
  padding: 0,
  backgroundColor: 'transparent',
};

export const errorMessageStyle = {
  errorMessageContainerStyle: { paddingTop: 3 },
  errorMessageIconStyle: {
    paddingRight: 3,
  },
  errorMessageStyle: {
    ...Headline11,
    color: vars.colors.error,
  },
};

export const helperTextStyle = {
  helperTextContainerStyle: { paddingTop: 3 },
  helperTextIconStyle: {
    paddingRight: 3,
  },
  helperTextStyle: {
    ...Headline11,
    color: neutral_4,
  },
};

const labelStyle = {
  labelStyle: {
    ...Headline9,
    color: vars.neutrals.placeholder,
    numberOfLines: 1,
  },
  floatingLabelStyle: {
    ...Headline15,
    color: vars.neutrals.placeholder,
    fontSize: 10,
    textTransform: 'uppercase',
    numberOfLines: 1,
  },
  hoverLabelStyle: {
    // color: neutral_2,
  },
  activeLabelStyle: {
    color: vars.neutrals.placeholder,
  },
};

const compactLabelStyle = {
  labelStyle: {
    ...Headline11,
    color: neutral_4,
    numberOfLines: 1,
  },
  floatingLabelStyle: {
    ...Headline15,
    color: neutral_2,
    numberOfLines: 1,
  },
  hoverLabelStyle: {
    // color: neutral_2,
  },
  activeLabelStyle: {
    color: neutral_4,
  },
};

export const filledInputTheme = {
  style: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_7,
    borderRadius: 4,
    backgroundColor: surface_white,
    minHeight: 38,
  },
  hoverStyle: {
    // backgroundColor: neutral_100_background,
  },
  disabledStyle: {
    backgroundColor: vars.colors.grey.color6,
  },
  activeStyle: {
    borderColor: vars.colors.primary.color2,

  },
  errorStyle: {
    borderColor: vars.colors.error,
  },
  inputStyle: {
    ...defaultInputStyle,
    paddingTop: 13,
    paddingBottom: 9,
    paddingLeft: 12,
    paddingRight: 12,
    ...vars.headings.h9,
    color: vars.colors.grey.color1,
  },
  floatingLabelInputStyle: {
    ...defaultInputStyle,
    paddingTop: 19,
    paddingBottom: 3,
    paddingLeft: 12,
    paddingRight: 12,
    ...Headline9,
    color: neutral_1,
  },
  labelContainerStyle: {
    top: 14,
    left: 12,
    right: 12,
  },
  floatingLabelContainerStyle: {
    top: 5,
  },
  ...labelStyle,
  ...errorMessageStyle,
  ...helperTextStyle,
  inputErrorStyle: {
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 6,
    alignSelf: 'center',
  },
  inputSelectorContainerStyle: {
    paddingRight: 12,
    justifyContent: 'center',
  },
  inputSelectorIconContainerStyle: {
    paddingTop: 6,
    paddingBottom: 6,
    cursor: 'pointer',
  },
  inputSelectorMultiIconContainerStyle: {
    paddingTop: 4,
    paddingBottom: 4,
    cursor: 'pointer',
  },
};

export const standardInputTheme = {
  style: {
    justifyContent: 'flex-end',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: vars.colors.grey.color5,
  },
  hoverStyle: {
    // color: neutral_1,
  },
  errorStyle: {
    borderColor: vars.colors.error,
  },
  activeStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    borderBottomWidth: 2,
    borderColor: vars.colors.primary.color2,
    borderStyle: 'solid',
  },
  inputStyle: {
    ...defaultInputStyle,
    paddingTop: 16,
    paddingBottom: 7,
    paddingRight: 12,
    ...Headline9,
    color: neutral_1,
  },
  labelContainerStyle: {
    top: 17,
    left: 0,
    right: 12,
  },
  floatingLabelContainerStyle: {
    top: 5,
  },
  ...labelStyle,
  ...errorMessageStyle,
  ...helperTextStyle,
  inputErrorStyle: {
    paddingRight: 12,
    paddingTop: 9,
    alignSelf: 'center',
  },
  inputSelectorContainerStyle: {
    paddingRight: 12,
    paddingTop: 9,
    justifyContent: 'center',
  },
  inputSelectorIconContainerStyle: {
    paddingTop: 6,
    paddingBottom: 6,
    cursor: 'pointer',
  },
  inputSelectorMultiIconContainerStyle: {
    paddingTop: 4,
    paddingBottom: 4,
    cursor: 'pointer',
  },
};

export const filledCompactInputTheme = {
  style: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_7,
    borderRadius: 4,
    backgroundColor: surface_white,
    minHeight: 32,
  },
  hoverStyle: {
    // backgroundColor: neutral_100_background,
  },
  activeStyle: {
    borderColor: primary_2,
  },
  errorStyle: {
    borderColor: negative_communication_3,
  },
  inputStyle: {
    ...defaultInputStyle,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 10,
    paddingBottom: 8,
    ...Headline11,
    color: neutral_1,
  },
  floatingLabelInputStyle: {
    ...defaultInputStyle,
    paddingTop: 15,
    paddingBottom: 3,
    paddingLeft: 6,
    paddingRight: 6,
    ...Headline11,
    color: neutral_1,
  },
  labelContainerStyle: {
    top: 11,
    left: 6,
    right: 6,
  },
  floatingLabelContainerStyle: {
    top: 3,
  },
  ...compactLabelStyle,
  ...errorMessageStyle,
  ...helperTextStyle,
  inputErrorStyle: {
    paddingRight: 6,
    paddingTop: 4,
    paddingBottom: 4,
    alignSelf: 'center',
  },
  inputSelectorContainerStyle: {
    paddingRight: 6,
    justifyContent: 'center',
  },
  inputSelectorIconContainerStyle: {
    paddingTop: 4,
    paddingBottom: 4,
    cursor: 'pointer',
  },
  inputSelectorMultiIconContainerStyle: {
    paddingTop: 3,
    paddingBottom: 3,
    cursor: 'pointer',
  },
};

export const standardCompactInputTheme = {
  style: {
    justifyContent: 'flex-end',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_7,
  },
  hoverStyle: {
    // color: neutral_1,
  },
  errorStyle: {
    borderColor: negative_communication_3,
  },
  activeStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    borderBottomWidth: 2,
    borderColor: primary_2,
    borderStyle: 'solid',
  },
  inputStyle: {
    ...defaultInputStyle,
    paddingTop: 11,
    paddingBottom: 8,
    paddingRight: 6,
    ...Headline11,
    color: neutral_1,
  },
  floatingLabelInputStyle: {
    ...defaultInputStyle,
    paddingTop: 16,
    paddingBottom: 3,
    paddingRight: 6,
    ...Headline11,
    color: neutral_1,
  },
  labelContainerStyle: {
    top: 11,
    left: 0,
    right: 6,
  },
  floatingLabelContainerStyle: {
    top: 3,
  },
  ...compactLabelStyle,
  ...errorMessageStyle,
  ...helperTextStyle,
  inputErrorStyle: {
    paddingRight: 6,
    paddingTop: 6,
    paddingBottom: 6,
    alignSelf: 'center',
    cursor: 'pointer',
  },
  inputSelectorContainerStyle: {
    paddingRight: 12,
    justifyContent: 'center',
  },
  inputSelectorIconContainerStyle: {
    paddingTop: 6,
    paddingBottom: 6,
    cursor: 'pointer',
  },
  inputSelectorMultiIconContainerStyle: {
    paddingTop: 3,
    paddingBottom: 3,
    cursor: 'pointer',
  },
};

export const filledCompactUnborderedInputTheme = {
  ...filledCompactInputTheme,
  style: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: 4,
    backgroundColor: neutral_10,
    minHeight: 32,
  },
};

export const standardCompactUnborderedInputTheme = {
  ...standardCompactInputTheme,
  style: {
    justifyContent: 'flex-end',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    minHeight: 32,
  },
};

export const filledCompactBorderedInputTheme = {
  ...filledCompactInputTheme,
  style: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_9,
    borderRadius: 4,
    backgroundColor: neutral_10,
    minHeight: 32,
  },
};

export const standardCompactBorderedInputTheme = {
  ...standardCompactInputTheme,
  style: {
    justifyContent: 'flex-end',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_9,
    minHeight: 32,
  },
};
