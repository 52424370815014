import {
  filledInputTheme,
  standardInputTheme,
} from './inputTheme';

const getInputTheme = (props) => {
  const {
    variant = 'filled',
    height,
    minHeight,
    maxHeight,
    moreInputStyle,
  } = props || {};

  let inputTheme = variant === 'filled' ? filledInputTheme : standardInputTheme;

  inputTheme = {
    ...inputTheme,
  };

  const extraProps = {
    ...moreInputStyle,
  };

  if (height) {
    extraProps.height = height;
  }

  if (minHeight) {
    extraProps.minHeight = minHeight;
  }

  if (maxHeight) {
    extraProps.maxHeight = maxHeight;
  }

  if (extraProps) {
    inputTheme.inputStyle = {
      ...inputTheme.inputStyle,
      ...extraProps,
    };

    if (inputTheme.floatingLabelInputStyle) {
      inputTheme.floatingLabelInputStyle = {
        ...inputTheme.floatingLabelInputStyle,
        ...extraProps,
      };
    }
  }
  return inputTheme;
};

export default getInputTheme;
