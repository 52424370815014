import React from 'react';
import {
  View,
  Text,
  BasicTextInput,
  TouchableOpacity,
  Image,
} from '@applane/react-core-components';
import {manazeV6Colors} from '../../../theme/manazeV6Colors';
import {Headline3, Headline9} from '../../../theme/Headlines';
import {filterGroupSearchTheme} from '../../../theme/filterGroupTheme';
const {neutral_1, neutral_3} = manazeV6Colors;

const {
  containerStyle,
  inputStyle,
  crossIcon,
  searchIcon,
  iconStyle,
} = filterGroupSearchTheme;
class FilterGroupFTS extends React.Component {
  constructor(props) {
    super(props);
    let {data: filters} = props;
    let {value = ''} = filters || {};
    this.state = {value};
  }
  onChangeValue = value => {
    this.setState({value});
  };

  setValue = () => {
    let {value} = this.state;
    value = value && value.trim();
    if (value) {
      this.onApply(value);
    }
  };

  onApply = value => {
    let {onChangeValue, action} = this.props;
    let ftsFilter = null;
    if (value) {
      ftsFilter = {value};
      if (action && action.exp) {
        ftsFilter.regExpFilter = {[action.exp]: {$regex: value, $options: 'i'}};
      } else {
        ftsFilter.filter = {$text: {$search: `"${value}"`}};
      }
    }
    onChangeValue && onChangeValue(ftsFilter);
  };
  render() {
    let {
      title,
      subTitle,
      placeholder,
      inputLabel,
      onSubmitEditing,
    } = this.props;
    const displayValue = this.state.value;
    return (
      <View style={{flex: 1}}>
        {title && (
          <Text style={{marginBottom: 5, color: neutral_1, ...Headline3}}>
            {title}
          </Text>
        )}
        {subTitle && (
          <Text style={{marginBottom: 5, color: neutral_3, ...Headline9}}>
            {subTitle}
          </Text>
        )}
        <View style={containerStyle}>
          <BasicTextInput
            style={inputStyle}
            label={inputLabel}
            placeholder={placeholder}
            value={displayValue}
            onChangeValue={this.onChangeValue}
            onBlur={!onSubmitEditing && this.setValue}
            onSubmitEditing={onSubmitEditing && this.setValue}
            autoFocus
          />
          <TouchableOpacity
            title={'Press here to clear text'}
            style={{cursor: 'pointer'}}
            onPress={() => {
              this.onChangeValue('');
            }}>
            <Image
              source={displayValue ? crossIcon : searchIcon}
              style={iconStyle}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

export default props => {
  return <FilterGroupFTS {...props} />;
};
