import React, {Component} from 'react';
import {TouchableOpacity} from '@applane/react-core-components';

export default class FloatingAnimatedLabel extends Component {
  render() {
    let {className, style, floatingStyle, labelPosition, children} = this.props;
    return (
      <TouchableOpacity
        className={className}
        style={{
          ...style,
          ...(labelPosition === 'floating' ? floatingStyle : {}),
          overflow: 'hidden',
          position: 'absolute',
          transition: 'all 200ms',
          transitionTimingFunction: 'linear',
          // transitionProperty: 'color, transform',
          // transitionDuration: '200ms, 200ms',
          // transitionTimingFunction:
          //   'cubic-bezier(0.0, 0, 0.2, 1), cubic-bezier(0.0, 0, 0.2, 1)',
          // transitionDelay: '0ms, 0ms',
          // zIndex: 1,
          pointerEvents: 'none',
        }}>
        {children}
      </TouchableOpacity>
    );
  }
}
