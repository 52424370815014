import React from 'react';
import {View, BasicTextInput} from '@applane/react-core-components';
import {
  WithFloatingLabel,
  WithInputWrapper,
  WithVariant,
  WithInputError,
  getInputProps,
} from '@applane/react-input-wrapper';

class TextInputEditor extends React.Component {
  render() {
    let inputProps = getInputProps(this.props);
    return <BasicTextInput {...inputProps} />;
  }
}

TextInputEditor = WithInputError(WithFloatingLabel(TextInputEditor));

class TextInput extends React.Component {
  render() {
    return (
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <TextInputEditor {...this.props} />
        </View>
      </View>
    );
  }
}

TextInput = WithInputWrapper(WithVariant(TextInput));

export default TextInput;
