import jsPDF from 'jspdf';
import './PublicSans-Regular-normal';
import './public_sans_bold-bold';

export const printPdf = ({
  tag,
  cb,
  orientation,
  format,
  unit,
}) => {
  const pdf = new jsPDF(
    {
      orientation,
      format,
      unit,
    },
  );
  pdf.html(tag, {
    callback: (doc) => {
      window.open(doc.output('bloburl'), '_blank');
    },
    x: 999,
    y: 0,
    margin: [0, 0],
  });
  cb && cb();
};
