export const clearSelectionState = ({screenState, setScreenState} = {}) => {
  if (!setScreenState) {
    return;
  }
  let {selectedIds, allPageSelected, allSelected, selectMode} =
    screenState || {};
  if (
    (selectedIds && selectedIds.length) ||
    allSelected ||
    allPageSelected ||
    selectMode
  ) {
    setScreenState({
      selectedIds: void 0,
      allPageSelected: void 0,
      allSelected: void 0,
      selectMode: void 0,
    });
  }
};
