import React from 'react';
import HoverActions from './HoverActions';
import {detectMob} from './CardUtility';
import {View} from '@applane/react-core-components';

const isMobile = detectMob();

export default Component => {
  class WithMobileHoverActions extends React.Component {
    state = {translateX: 0};
    minSwipeWidth = 20;
    initialTouch = 0;
    findDx = (x, y) => {
      if (x === null || x === undefined || y === null || y === undefined) {
        return 0;
      }
      return x - y;
    };
    findalDx = 0;

    onTouchMove = e => {
      const {pageX} = e?.touches?.['0'] || {};
      const dx = this.findDx(pageX, this.initialTouch);
      if (dx > 0) {
        if (this.state.swiped) {
          this.setState({
            translateX: dx < this.actionsWidth ? dx - this.actionsWidth : 0,
          });
        } else if (this.state.translateX < 0) {
          this.setState({translateX: 0});
        }
      } else if (dx < 0) {
        if (!this.state.swiping) {
          this.setState({swiping: true});
        }
        if (dx <= -this.minSwipeWidth && !this.state.minSwiped) {
          this.onSwipe();
        }
        if (this.state.swiped) {
          this.setState({translateX: -this.actionsWidth});
        } else {
          this.setState({
            translateX: dx > -this.actionsWidth ? dx : -this.actionsWidth,
          });
        }
      }
      this.findalDx = dx;
    };
    onTouchStart = e => {
      let {pageX} = e?.touches?.['0'] || {};
      this.initialTouch = pageX || 0;
    };

    onTouchRelease = e => {
      let dx = this.findalDx;
      if (this.state.swiped && dx < 0) {
        return;
      }
      if (dx <= -this.minSwipeWidth) {
        this.setState({translateX: -this.actionsWidth, swiped: true});
      } else {
        this.closeSwipe();
      }
    };
    closeSwipe = () => {
      this.setState({
        translateX: 0,
        swiping: false,
        swiped: false,
        minSwiped: false,
      });
      this.findalDx = 0;
    };
    onSwipe = () => {
      this.setState({minSwiped: true});
      this.props.onSwipe && this.props.onSwipe(this.closeSwipe);
    };
    onLayout = ({nativeEvent: {layout}}) => {
      let {width} = layout || {};
      this.actionsWidth = width;
    };

    render() {
      let {
        hoverActions,
        swipeActions,
        onSwipe,
        onSwipeActionSelect,
        ...restProps
      } = this.props;
      let actions = swipeActions || hoverActions;
      if (typeof actions === 'function') {
        actions = actions(restProps);
      }
      if (!actions || (Array.isArray(actions) && !actions.length)) {
        return <Component {...restProps} />;
      }
      let extraProps = {};
      // to create the close action only when the card is opened and not during the swipe
      if (this.state.swiped) {
        extraProps.action = {onPress: this.closeSwipe};
      }
      return (
        <React.Fragment>
          <HoverActions
            {...restProps}
            actions={actions}
            onLayout={this.onLayout}
          />
          <View
            onTouchEnd={this.onTouchRelease}
            onTouchStart={this.onTouchStart}
            onTouchMove={this.onTouchMove}
            style={{
              transform: `translateX(${this.state.translateX || 0}px)`,
              transition: 'all 0.2s',
            }}>
            <Component
              {...restProps}
              isSwiped={this.state.swiping}
              {...extraProps}
            />
          </View>
        </React.Fragment>
      );
    }
  }

  class WithWebHoverActions extends React.Component {
    render() {
      let {hoverActions, ...restProps} = this.props;
      let renderComponent = <Component {...restProps} />;
      let actions = hoverActions;
      if (typeof actions === 'function') {
        actions = actions(restProps);
      }
      if (!actions || (Array.isArray(actions) && !actions.length)) {
        return renderComponent;
      }
      return (
        <React.Fragment>
          {renderComponent}
          <HoverActions
            {...restProps}
            actions={hoverActions}
            opacity={restProps.isHover ? 1 : 0}
          />
        </React.Fragment>
      );
    }
  }
  return isMobile ? WithMobileHoverActions : WithWebHoverActions;
};
