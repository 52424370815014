import { MODELS } from '../Lib/constants';

export const employeeListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'employeeListsForTable',
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.EMPLOYEE,
});

export const EditEmployee = ({ navigation }) => ({
  query: {
    model: MODELS.EMPLOYEE,
    id: 'getEmployeeDetails',
    relationValue: {
      model: MODELS.EMPLOYEE,
      _id: navigation?.state?.params?.item?._id,
      relation: '_id',
    },
    limit: 1,
  },
  model: MODELS.EMPLOYEE,
});

export const employeeWithScheduleListUri = ({ filter = {} } = {}) => ({
  query: {
    id: 'employeeListsForTableWithEffectiveSchedule',
    addOnFilter: filter,
  },
  aggregate: true,
  model: MODELS.EMPLOYEE,
});
