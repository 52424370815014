import React from 'react';
import {
  Image,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
} from '@applane/react-core-components';
import {filterBarTheme} from '../../theme/filterBarTheme';
import {getRenderComponent, isMobile} from '../UtilityFunctions';
import {leftSmallBlueArrow, left_arrow, rightSmallBlueArrow, right_Arrow} from '../../images';
import {filterGroupRowTypes} from './FilterTypes';
const maxScrollViewWidth = isMobile ? 306 : 500;
const scrollWidth = isMobile ? 102 : 136;
const {
  resetContainerStyle,
  resetTextStyle,
  countBoxStyle,
  countTextStyle,
} = filterBarTheme;
export default class FilterBar extends React.Component {
  left = 0;
  setScreenState = key => state => {
    const {navigation, setScreenState} = this.props;
    setScreenState && setScreenState(state);
    // if (key === 'tabFilter') {
    //   let {setScreenState: setNavigationScreenState, getScreenState} =
    //     navigation || {};
    //   let navigationScreenState = (getScreenState && getScreenState()) || {};
    //   let tabState = {};
    //   for (let key in state) {
    //     let keyValue = state[key];
    //     if (key === 'dataParams') {
    //       let tabPrevFilters =
    //         navigationScreenState &&
    //         navigationScreenState.dataParams &&
    //         navigationScreenState.dataParams.filters;
    //       if (tabPrevFilters) {
    //         tabState[key] = {
    //           ...keyValue,
    //           filters: {
    //             ...tabPrevFilters,
    //             ...keyValue.filters,
    //           },
    //         };
    //       } else {
    //         tabState[key] = keyValue;
    //       }
    //     } else {
    //       tabState[key] = keyValue;
    //     }
    //   }

    //   setNavigationScreenState && setNavigationScreenState(tabState);
    // }
  };

  isFilterApplied = () => {
    const {screenState, filters = []} = this.props;
    let filtersState = screenState?.dataParams?.filters;
    if (!filtersState) {
      return false;
    }
    let isMatched = false;
    for (let item of filters) {
      let {field} = item;
      if (filtersState[field]) {
        isMatched = true;
        break;
      }
    }
    return isMatched;
  };

  clearFilter = () => {
    const {screenState, setScreenState, filters} = this.props;
    const filtersState = screenState?.dataParams?.filters;
    let newFilterState = filtersState;
    for (let item of filters) {
      let {field} = item;
      if (filtersState[field]) {
        delete newFilterState[field];
      }
    }
    setScreenState({
      dataParams: {
        ...screenState?.dataParams,
        filters: {
          ...newFilterState,
        },
      },
    });
  };

  scroll = action => {
    let width = this.scrollRef.scrollWidth - maxScrollViewWidth;
    if (
      action === 'right' &&
      this.scrollRef.scrollLeft >= 0 &&
      this.scrollRef.scrollLeft < width
    ) {
      this.left = this.left + scrollWidth;
      this.scrollRef.scrollLeft = this.left;
    } else if (action === 'left' && this.scrollRef.scrollLeft > 0) {
      this.left = this.left - scrollWidth;
      this.scrollRef.scrollLeft = this.left;
    }
    this.setState({});
  };

  getDispalCount = () => {
    let {filters = []} = this.props;
    let filtersCount = filters.length;
    let maxItemInScrollView = maxScrollViewWidth / scrollWidth;
    let leftCount = 0;
    let rightCount = 0;
    let showCount = false;

    if (filtersCount <= maxItemInScrollView) {
      return {leftCount, rightCount};
    }
    showCount = true;
    if (this.left === 0) {
      rightCount = filtersCount - maxItemInScrollView;
    } else {
      leftCount = this.left / scrollWidth;
      rightCount = filtersCount - maxItemInScrollView - leftCount;
    }
    leftCount = Math.ceil(leftCount);
    rightCount = Math.ceil(rightCount);
    return {leftCount, rightCount, showCount};
  };
  render() {
    const {
      navigation,
      eventDispatcher,
      screenState,
      filters,
      position,
      absolutePosition,
      skipClearValue,
      setTabsState,
    } = this.props;
    if (!filters || !filters.length) {
      return null;
    }
    let {leftCount, rightCount, showCount} = this.getDispalCount();
    let containerStyle =
      (position && filterBarTheme[`${position}ContainerStyle`]) ||
      filterBarTheme.containerStyle ||
      {};
    if (position === 'absolute') {
      containerStyle = {
        ...containerStyle,
        ...filterBarTheme[`${absolutePosition}ContainerStyle`],
      };
    }
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          overflow: 'hidden',
          ...containerStyle,
        }}>
        {!skipClearValue && this.isFilterApplied() && (
          <TouchableOpacity
            style={resetContainerStyle}
            onPress={this.clearFilter}>
            <Text style={resetTextStyle}>Clear</Text>
          </TouchableOpacity>
        )}
        {!isMobile && showCount && (
          <TouchableOpacity
            style={countBoxStyle}
            disabled={leftCount === 0}
            onPress={() => {
              this.scroll('left');
            }}>
            <Image source={left_arrow} />
            <Image source={left_arrow} />
            {leftCount > 0 && (
              <Text style={{...countTextStyle, paddingLeft: 10}}>
                +{leftCount}
              </Text>
            )}
          </TouchableOpacity>
        )}
        <View>
          <ScrollView
            style={{
              scrollBehavior: 'smooth',
              maxWidth: maxScrollViewWidth,
            }}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{
              alignItems: 'center',
            }}
            getRef={ref => {
              this.scrollRef = ref;
            }}
            id="filterScroll">
            {filters.map(filter => {
              if (!filter) {
                return null;
              }
              let filterProps = {
                navigation,
                eventDispatcher,
                screenState,
                setTabsState,
                setScreenState: this.setScreenState(),
              };
              let filterComponent = getRenderComponent(filter, filterProps);
              if (!React.isValidElement(filterComponent)) {
                let {type, filterType, filter, ...restFilter} = filterComponent;
                if (!filter && type) {
                  filter = filterGroupRowTypes[type];
                }
                if (filterType) {
                  filterProps = {
                    ...filterProps,
                    screenState,
                    setScreenState: this.setScreenState(filterType),
                  };
                }
                filterComponent = getRenderComponent(filter, {
                  type,
                  ...filterProps,
                  ...restFilter,
                });
              }
              return (
                <View style={{marginLeft: 6, marginRight: 6}}>
                  {filterComponent}
                </View>
              );
            })}
          </ScrollView>
        </View>
        {!isMobile && showCount && (
          <TouchableOpacity
            style={countBoxStyle}
            disabled={rightCount === 0}
            onPress={() => {
              this.scroll('right');
            }}>
            {rightCount > 0 && (
              <Text style={{...countTextStyle, paddingRight: 10}}>
                +{rightCount}
              </Text>
            )}
            <Image source={right_Arrow} />
            <Image source={right_Arrow} />
          </TouchableOpacity>
        )}
      </View>
    );
  }
}
