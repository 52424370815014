export const h18_light = {
  fontSize: '18px',
  fontFamily: 'AvenirNext-Regular',
};
export const h14 = {
  fontSize: '14px',
  fontFamily: 'AvenirNext-Regular',
};
export const h18_Regular = {
  fontSize: '18px',
  fontFamily: 'AvenirNext-Regular',
};
export const h9_Bold = {
  fontSize: '9px',
  fontFamily: 'AvenirNext-DemiBold',
};

export const h18_SemiBold = {
  fontSize: '18px',
  fontFamily: 'AvenirNext-DemiBold',
};
export const h11_SemiBold = {
  fontSize: '11px',
  fontFamily: 'AvenirNext-DemiBold',
};
export const h22_SemiBold = {
  fontSize: '22px',
  fontFamily: 'AvenirNext-DemiBold',
};

export const h28_SemiBold = {
  fontSize: '28px',
  fontFamily: 'AvenirNext-DemiBold',
};

export const h14_SemiBold = {
  fontSize: '14px',
  fontFamily: 'AvenirNext-DemiBold',
};

export const h10_Regular = {
  fontSize: '10px',
  fontFamily: 'AvenirNext-Regular',
};

export const h10_Italic = {
  fontSize: '10px',
  fontFamily: 'AvenirNext-Italic',
};

export const h10_Medium = {
  fontSize: '10px',
  fontFamily: 'AvenirNext-Medium',
};

export const h9_Regular = {
  fontSize: '9px',
  fontFamily: 'AvenirNext-Regular',
};

export const h9_italic = {
  fontSize: '9px',
  fontFamily: 'AvenirNext-Italic',
};

export const h14_Regular = {
  fontSize: '14px',
  fontFamily: 'AvenirNext-Regular',
};
export const h15_Regular = {
  fontSize: '15px',
  fontFamily: 'AvenirNext-Regular',
};

export const h11_Regular = {
  fontSize: '11px',
  fontFamily: 'AvenirNext-Regular',
};

export const h11_Medium = {
  fontSize: '11px',
  fontFamily: 'AvenirNext-Medium',
};

export const h12_Regular = {
  fontSize: '12px',
  fontFamily: 'AvenirNext-Regular',
};
export const h12_Regular_500 = {
  fontSize: '12px',
  fontWeight: 500,
  fontFamily: 'AvenirNext-Regular',
};

export const h16_Regular = {
  fontSize: '16px',
  fontFamily: 'AvenirNext-Regular',
};

export const h12_Italic = {
  fontSize: '12px',
  fontFamily: 'AvenirNext-Italic',
};

export const h12_SemiBold = {
  fontSize: '12px',
  fontFamily: 'AvenirNext-DemiBold',
};

export const h10_SemiBold = {
  fontSize: '10px',
  fontFamily: 'AvenirNext-DemiBold',
};

export const h24_SemiBold = {
  fontSize: '24px',
  fontFamily: 'AvenirNext-DemiBold',
};
export const h24_Regular = {
  fontSize: '24px',
  fontFamily: 'AvenirNext-Regular',
};

export const h13_SemiBold = {
  fontSize: '13px',
  fontFamily: 'AvenirNext-DemiBold',
};
export const h15_SemiBold = {
  fontSize: '15px',
  fontFamily: 'AvenirNext-DemiBold',
};
export const h16_SemiBold = {
  fontSize: '16px',
  fontFamily: 'AvenirNext-DemiBold',
};

export const h13_Regular = {
  fontSize: '13px',
  fontFamily: 'AvenirNext-Regular',
};
export const h12_Medium = {
  fontSize: '12px',
  fontFamily: 'AvenirNext-Medium',
};
export const h40_Medium = {
  fontSize: '40px',
  fontFamily: 'AvenirNext-Medium',
};

export const h13_Medium = {
  fontSize: '13px',
  fontFamily: 'AvenirNext-Medium',
};

export const h14_Medium = {
  fontSize: '14px',
  fontFamily: 'AvenirNext-Medium',
};

export const h15_Medium = {
  fontSize: '15px',
  fontFamily: 'AvenirNext-Medium',
};
export const h16_Medium = {
  fontSize: '16px',
  fontFamily: 'AvenirNext-Medium',
};
export const h22_Medium = {
  fontSize: '22px',
  fontFamily: 'AvenirNext-Medium',
};
export const h18_Medium = {
  fontSize: '18px',
  fontFamily: 'AvenirNext-Medium',
};
export const h70_Regular = { fontSize: '70px', fontFamily: 'AvenirNext-Regular' };
export const h28_Regular = { fontSize: '28px', fontFamily: 'AvenirNext-Regular' };
export const h8_Regular = {
  fontSize: '8px',
  fontFamily: 'AvenirNext-Regular',
};

export const h14_SansRegular = {
  fontSize: '14px',
  fontFamily: 'AvenirNext-Regular',
};

export const h12_SansSemiBold = {
  fontSize: '12px',
  fontFamily: 'AvenirNext-DemiBold',
};

export const h14_SansSemiBold = {
  fontSize: '14px',
  fontFamily: 'AvenirNext-DemiBold',
};
export const h14_AvenirNext = {
  fontSize: '14px',
  fontFamily: 'AvenirNext-Regular',
};
export const h14_AvenirNext_Bold = {
  fontSize: '14px',
  fontFamily: 'AvenirNext-Regular',
  fontWeight: 500,
};
export const h33_AvenirNext = {
  fontSize: '33px',
  fontFamily: 'AvenirNext-Regular',
};

export const h28_AvenirNext = {
  fontSize: '28px',
  fontFamily: 'AvenirNext-Regular',
  fontWeight: 600,
};
export const h18_AvenirNext = {
  fontSize: '18px',
  fontFamily: 'AvenirNext-Regular',
};
export const h24_AvenirNext_600 = {
  fontSize: '24px',
  fontWeight: 600,
  fontFamily: 'AvenirNext-Regular',
};

export const h24_AvenirNext_500 = {
  fontSize: '24px',
  fontWeight: 500,
  fontFamily: 'AvenirNext-Regular',
};

export const h16_SansSemiBold = {
  fontSize: '16px',
  fontFamily: 'AvenirNext-DemiBold',
};

export const h28_SansSemiBold = {
  fontSize: '28px',
  fontFamily: 'AvenirNext-DemiBold',
};
export const h12_italic = {
  fontSize: '12px',
  fontFamily: 'AvenirNext-Italic',
};
export const h11_italic = {
  fontSize: '11px',
  fontFamily: 'AvenirNext-Italic',
};
export const h20_Regular = {
  fontSize: '20px',
  fontFamily: 'AvenirNext-Regular',
};
export const h20_AvenirNext_500 = {
  fontWeight: 500,
  fontSize: '20px',
  fontFamily: 'AvenirNext-Regular',
};
export const h21_Regular = {
  fontSize: '21px',
  fontFamily: 'AvenirNext-Regular',
};
export const h22_Regular = {
  fontSize: '22px',
  fontFamily: 'AvenirNext-Regular',
};
export const h30_Regular = {
  fontSize: '30px',
  fontFamily: 'AvenirNext-Regular',
};
export const h36_Regular = {
  fontSize: '36px',
  fontFamily: 'AvenirNext-Regular',
};
export const h26_Regular = {
  fontSize: '26px',
  fontFamily: 'AvenirNext-Regular',
};
export const h14_italic = {
  fontSize: '14px',
  fontFamily: 'AvenirNext-Italic',
};
export const h8_SansSemiBold = {
  fontSize: '8px',
  fontFamily: 'AvenirNext-DemiBold',
};

export const h24_Medium = {
  fontSize: '24px',
  fontFamily: 'AvenirNext-Medium',
};

export const h32_SansSemiBold = {
  fontSize: '32px',
  fontFamily: 'AvenirNext-DemiBold',
};
