import { MODELS } from '../Lib/constants';

export const VaccinationList = ({ filter = {} }) => ({
  query: {
    model: MODELS.VACCINATION,
    id: 'patientVaccinationList',
    addOnFilter: filter,
  },
  model: MODELS.VACCINATION,
});

export const GetVaccineVaccineDetail = ({ navigation = {} }) => ({
  query: {
    model: MODELS.VACCINATION,
    id: 'patientVaccinationDetail',
    addOnFilter: { _id: navigation?.state?.params?.item?._id },
  },
  model: MODELS.VACCINATION,
});

export const VaccinationItemList = ({ addOnFilter }) => ({
  query: {
    model: MODELS.PHARMACY_ITEM,
    id: 'masterDataListForTable',
    addOnFilter,
  },
  model: MODELS.PHARMACY_ITEM,
});
