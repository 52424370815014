import React from 'react';
import {WithReactForm} from './WithReactFormContext';
import {getRenderComponent, resolveExp, getFieldError} from './Utility';

class FormCompositeField extends React.Component {
  getMeta = () => {
    const {fields, form_context: {setFocus} = {}, form_state = {}} = this.props;
    if (!fields || !fields.length) {
      return;
    }
    let {focusField, focussedFields} = form_state;
    let isActive, isTouched, error, fieldsError;
    fields.forEach(fieldInfo => {
      let {field} = fieldInfo;
      if (setFocus) {
        if (!isActive && focusField) {
          isActive = focusField === field;
        }
        if (!isTouched && focussedFields && focussedFields[field]) {
          isTouched = true;
        }
        let fieldError = getFieldError({field, form_state});
        if (fieldError) {
          fieldsError = fieldsError || {};
          fieldsError[field] = fieldError;
          if (!error) {
            error = fieldError;
          }
        }
      }
    });
    return {
      isActive,
      isTouched,
      error,
      fieldsError,
    };
  };

  getData = () => {
    const {form_state: {data = {}} = {}} = this.props;
    return data;
  };

  render() {
    const {
      form_state: {data = {}} = {},
      form_context: {navigation, eventDispatcher, setValue, setFocus} = {},
      children,
      fields,
      ...restProps
    } = this.props;
    if (!children || !fields || !fields.length) {
      return null;
    }
    let childrenProps = {
      ...restProps,
      ...this.getMeta(),
      setValue,
      setFocus,
      data,
      navigation,
      eventDispatcher,
      getData: this.getData,
      fields: fields.map(fieldInfo => ({
        ...fieldInfo,
        value: data ? resolveExp(data, fieldInfo.field) : void 0,
      })),
    };
    return getRenderComponent(children, childrenProps);
  }
}

FormCompositeField = WithReactForm(FormCompositeField, {
  key: 'form',
});

export default FormCompositeField;
