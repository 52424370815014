import React from 'react';
import {StyleSheet} from '@applane/react-core-components';
import TextAreaAutoResize from 'react-textarea-autosize';

class TextAreaInput extends React.Component {
  onChange = e => {
    let {onChangeText, onChangeValue, onChange} = this.props;
    if (onChange) {
      onChange(e);
    }
    if (onChangeText || onChangeValue) {
      let text = e && e.target && e.target.value;
      onChangeText && onChangeText(text);
      onChangeValue && onChangeValue(text, e);
    }
  };

  onKeyUp = e => {
    let {onKeyUp, onSubmitEditing} = this.props;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      onSubmitEditing && onSubmitEditing(e);
    }
    onKeyUp && onKeyUp(e);
  };

  render() {
    let {
      editable,
      onSubmitEditing,
      getRef,
      style,
      onChange,
      onChangeText,
      onChangeValue,
      value,
      ...restProps
    } = this.props;
    if (style && Array.isArray(style)) {
      style = StyleSheet.flatten(style);
    }
    if (value === undefined || value === null) {
      value = '';
    }
    let extraProps = {};
    if (editable === false) {
      extraProps['disabled'] = true;
    }
    if (getRef) {
      extraProps.inputRef = getRef;
    }
    if (onSubmitEditing) {
      extraProps['onKeyUp'] = this.onKeyUp;
    }
    return (
      <TextAreaAutoResize
        style={style}
        value={value}
        onChange={this.onChange}
        {...extraProps}
        {...restProps}
      />
    );
  }
}
export default TextAreaInput;
