import { manazeV6Colors } from './manazeV6Colors';
import { tableMobileTheme, tableTheme } from './tableTheme';

const { neutral_10, surface_white } = manazeV6Colors;
export const expandedListTheme = {
  ...tableTheme,
  leftGap: 0,
  rightGap: 0,
  containerStyle: {},
  cardScrollViewStyle: {
    flex: 1,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: 12,
  },
  headerRowContainerStyle: {
    minHeight: 40,
    paddingTop: 12,
    backgroundColor: surface_white,
  },
  rowWrapperStyle: {
    backgroundColor: surface_white,
  },
  rowSeparatorPosition: 'top',
};

export const expandedListMobileTheme = {
  ...tableMobileTheme,
  leftGap: 0,
  rightGap: 0,
  containerStyle: {},
  cardScrollViewStyle: {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 6,
  },
  headerRowContainerStyle: {
    minHeight: 32,
    backgroundColor: surface_white,
  },
  rowWrapperStyle: {
    backgroundColor: surface_white,
  },
  rowSeparatorPosition: 'top',
};

export const groupCardExpandedListTheme = {
  ...expandedListTheme,
  cardScrollViewStyle: {
    flex: 1,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 8,
  },
};

export const groupCardExpandedListMobileTheme = {
  ...expandedListMobileTheme,
  cardScrollViewStyle: {
    flex: 1,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 8,
  },
};

export const filledExpandedListTheme = {
  ...tableTheme,
  containerStyle: {
    marginBottom: 8,
    borderRadius: 8,
    backgroundColor: neutral_10,
  },
  cardContainerStyle: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 8,
    backgroundColor: neutral_10,
  },
  headerRowContainerStyle: {
    minHeight: 40,
    paddingTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
  },
  rowWrapperStyle: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  rowStyle: {
    ...tableTheme.rowStyle,
    rowHoverContainerStyle: {
      ...tableTheme.rowStyle.rowHoverContainerStyle,
      backgroundColor: surface_white,
    },
  },
  rowSeparatorPosition: 'top',
  lastRowBottomStyle: {
    ...tableTheme.lastRowBottomStyle,
    backgroundColor: neutral_10,
  },
};

export const filledExpandedListMobileTheme = {
  ...tableMobileTheme,
  containerStyle: {
    marginBottom: 8,
    borderRadius: 8,
    backgroundColor: neutral_10,
  },
  cardContainerStyle: {
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 8,
    backgroundColor: neutral_10,
  },
  headerRowContainerStyle: {
    minHeight: 32,
    paddingLeft: 6,
    paddingRight: 6,
  },
  rowWrapperStyle: {
    paddingLeft: 6,
    paddingRight: 6,
  },
  rowStyle: {
    ...tableMobileTheme.rowStyle,

    rowHoverContainerStyle: {
      ...tableTheme.rowStyle.rowHoverContainerStyle,
      backgroundColor: surface_white,
    },
  },
  rowSeparatorPosition: 'top',
  lastRowBottomStyle: {
    ...tableTheme.lastRowBottomStyle,
    backgroundColor: neutral_10,
  },
};
