import React from 'react';
import { View } from '@applane/react-core-components';
import { WithInputWrapper, WithVariant } from '@applane/react-input-wrapper';
import { modifyInputProps } from './InputUtility';
import { FieldComponent } from '../form/field/FormField';

const CompositeFieldSeparator = ({ error }) => <View style={{ width: 1, backgroundColor: error ? 'red' : '#E1E1E1' }} />;

class CompositeField extends React.Component {
  setValue = (field) => (props) => {
    this.props.setValue
      && this.props.setValue({
        ...props,
        field,
      });
  };

  setFocus = (field) => (props) => {
    this.props.setFocus
      && this.props.setFocus({
        ...props,
        field,
      });
  };

  render() {
    const {
      fields,
      helperText,
      error,
      fieldsError,
      isActive,
      ...restProps
    } = this.props;
    return (
      <View style={{ flexDirection: 'row', overflow: 'hidden' }}>
        {fields.map((fieldInfo, index) => {
          const {
            width,
            flex = 1,
            minWidth,
            maxWidth,
            ...restFieldInfo
          } = fieldInfo;
          const { field } = restFieldInfo;
          let fieldContainerStyle = { flex, overflow: 'hidden' };
          if (width) {
            fieldContainerStyle = { width };
          }
          if (minWidth) {
            fieldContainerStyle.minWidth = minWidth;
          }
          if (maxWidth) {
            fieldContainerStyle.maxWidth = maxWidth;
          }
          return (
            <>
              <View style={fieldContainerStyle}>
                <FieldComponent
                  {...restProps}
                  {...restFieldInfo}
                  error={(fieldsError && fieldsError[field]) || void 0}
                  skipVariant
                  skipErrorMessage
                  setValue={this.setValue(field)}
                  setFocus={this.setFocus(field)}
                />
              </View>
              {index < fields.length - 1 ? (
                <CompositeFieldSeparator
                  error={(fieldsError && fieldsError[field]) || void 0}
                />
              ) : void 0}
            </>
          );
        })}
      </View>
    );
  }
}
CompositeField = WithInputWrapper(WithVariant(CompositeField));

const CompositeInput = (props) => {
  const modifiedProps = modifyInputProps(props);
  return <CompositeField {...modifiedProps} />;
};

export const CurrencyInput = (props) => <CompositeInput {...props} />;

export default CompositeInput;
