import React, { useCallback, useState } from 'react';
import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';

import { ToggleSwitchInput } from '../../../app-components';

import getInputTheme from '../getInputTheme';

const SwitchInput = (props = {}) => {
  const { onRowTouch, data, item, column: { field } = {} } = props;

  const [value, setValue] = useState(!!item[field]);
  const theme = getInputTheme({ ...props });

  const handleOnChangeValue = useCallback(() => {
    if (isFunction(onRowTouch)) {
      onRowTouch({ data }, value ? 'remove' : 'selected');
    }
    setValue(!value);
  }, [data, onRowTouch, value]);

  return (
    <ToggleSwitchInput
      inputProps={{
        value,
      }}
      onChangeValue={handleOnChangeValue}
      {...theme}
      style={{
        ...theme.style,
        borderWidth: 0,
      }}
      {...props}
    />
  );
};

SwitchInput.defaultProps = {
  data: {},
  label: '',
  onRowTouch: () => {},
};

SwitchInput.propTypes = {
  data: PropTypes.instanceOf(PropTypes.object),
  label: PropTypes.string,
  onRowTouch: PropTypes.func,
};

export default SwitchInput;
