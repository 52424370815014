/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useState } from 'react';

import {
  View,
  Text,
  ScrollView,
  Image,
  FormField,
  LargeButton,
} from '../../app-components';

import { getUser } from '../../AppServices';
import { validateConfirmPassword, validatePassword } from '../../Lib/helpers';
import { TextInput } from '../../app-components/input-components/Editors';
import { Form } from '../../app-components/form/Form';
import { LANDING_VIEW } from '../../Config';

import { notificationError } from '../../images';
import loginStyle from './theme';
import vars from '../../theme/vars';
import { ROUTES, userType } from '../../Lib/constants';

// const validatePassword = ({ data }) => {
//   if (data.newPassword && data.confirmPassword && (data.newPassword !== data.confirmPassword)) {
//     return 'Passwords do not match. please re-enter';
//   }
//   return false;
// };
export default ({ mamcResetPassword, images }) => {
  const { scrollViewStyle } = loginStyle;
  const ResetPassword = ({ navigation }) => {
    const { state: { params = {} } = {} } = navigation;
    const [errorMessage, setErrorMessage] = useState('');
    const handleSubmit = ({ data }) => {
      mamcResetPassword(data).then((res) => {
        if (res?.message === 'Password changed successfully') {
          if (params?.userType === userType.patient) {
            navigation.replace({ view: ROUTES.viewPatientPortalOPD.name, params: { uhid: params?.username } });
          } else {
            navigation.replace(LANDING_VIEW);
          }
        }
      });
    };
    return (
      <ScrollView style={scrollViewStyle}>
        <Form
          scrollable={false}
          mandatory={{
            newPassword: 'Password is mandatory',
          }}
          required={{
            newPassword: 1,
            confirmPassword: 1,
          }}
          defaultValues={() => {
            const user = getUser();
            return {
              username: user?.username,
            };
          }}
          onSubmit={handleSubmit}
          containerStyle={{ flex: 1 }}
          bodyContainerStyle={{
            margin: 0,
            flex: 1,

          }}
          validations={{
            newPassword: ({ data }) => validatePassword(data.newPassword),
            confirmPassword: ({ data }) => validateConfirmPassword(data),
          }}
        >
          <ResetPasswordNew
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            images={images}
          />
        </Form>
      </ScrollView>
    );
  };
  return ResetPassword;
};

const ResetPasswordNew = ({
  form_context,
  images,
  errorMessage,
}) => {
  const { handleSubmit } = form_context;
  const { bgImage, resetPassword } = images;
  const {
    containerStyle,
    loginBoxStyle,
    loginLogoStyle,
    loginLogoContainer,
    errorStyle,
  } = loginStyle;

  return (
    <View
      style={{
        ...containerStyle,
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
      }}
    >
      <View style={{ loginBoxStyle }}>
        <View
          style={{
            ...loginBoxStyle,
          }}
        >
          <View style={loginLogoContainer}>
            <Image
              source={resetPassword}
              style={{ ...loginLogoStyle }}
            />
          </View>
          <View style={{
            width: 312,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignSelf: 'center',
          }}
          >
            <Text
              style={{
                marginTop: 16,
                ...vars.headings.h5,
                textAlign: 'center',
                fontWeight: 500,
              }}
            >
              {'resetPassword.title.resetPassword'.getLabel()}
            </Text>
            <Text
              style={{
                marginTop: 16,
                ...vars.headings.h11,
                textAlign: 'center',
                fontStyle: 'italic',
                color: vars.colors.grey.color3,
              }}
            >
              Your password has expired after first login. Please create a new one.
            </Text>
          </View>
          <View style={{ marginTop: 32 }}>
            <FormField
              field="username"
              label={'resetPassword.labels.username'.getLabel()}
              variant="filled"
              placeholder={'resetPassword.placeholders.username'.getLabel()}
              editable={false}
              style={{
                backgroundColor: vars.colors.grey.color6,
                color: vars.colors.grey.color3,
                borderRadius: '4px',
                width: '424px',
                height: '44px',
              }}
            >
              <TextInput />
            </FormField>
          </View>
          <View style={{ marginTop: 25 }}>
            <FormField
              field="newPassword"
              label={'resetPassword.labels.newPassword'.getLabel()}
              variant="filled"
              placeholder={'resetPassword.placeholders.newPassword'.getLabel()}
              mandatory
            >
              <TextInput inputProps={{ type: 'password' }} />
            </FormField>
          </View>
          <View style={{ marginTop: 25 }}>
            <FormField
              field="confirmPassword"
              label={'resetPassword.labels.confirmPassword'.getLabel()}
              variant="filled"
              placeholder={'resetPassword.placeholders.confirmPassword'.getLabel()}
              mandatory
            >
              <TextInput inputProps={{ type: 'password' }} />
            </FormField>
          </View>
          <View style={{ marginTop: 24, textAlign: 'center' }}>
            <Text style={{ ...vars.headings.h14, color: vars.neutrals.color3, fontStyle: 'italic' }}> Your password should contain</Text>
            <Text style={{ ...vars.headings.h14, color: vars.neutrals.color3, fontStyle: 'italic' }}>-Minimum 6 and Maximum 30 characters</Text>
            <Text style={{ ...vars.headings.h14, color: vars.neutrals.color3, fontStyle: 'italic' }}>-At least one letter(a-z A-Z), one numeric digit(0-9), and one special character.</Text>
          </View>
          {errorMessage ? (
            <View style={{
              flexDirection: 'row',
              marginTop: 27,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <Image
                source={notificationError}
                style={{ height: 18, width: 18 }}
              />
              <Text style={{ ...errorStyle }}>{errorMessage}</Text>
            </View>
          ) : null}
          <View style={{ marginTop: 48, display: 'flex', alignItems: 'center' }}>
            <LargeButton
              label={'resetPassword.buttons.save'.getLabel()}
              onClick={handleSubmit}
              width={109}
              height={40}
              borderRadius={4}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

