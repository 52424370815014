import React from 'react';
import {View} from '@applane/react-core-components';
import {WithHoverActions, WithAction} from '@applane/react-card';
import {getRenderComponent} from './Utility';

class ColumnWithAction extends React.Component {
  render() {
    let {renderColumn, ...restProps} = this.props;
    return getRenderComponent(renderColumn, restProps) || null;
  }
}

ColumnWithAction = WithAction(ColumnWithAction);

class TableRow extends React.PureComponent {
  render() {
    let {
      columns,
      rowContainerStyle,
      rowSelectedContainerStyle,
      rowHoverContainerStyle,
      rowColumnContainerStyle,
      ...restProps
    } = this.props;
    // if (!columns || !columns.length) {
    //   return null;
    // }
    let {isHover, selected} = restProps;
    let rowContainerExtraStyle = selected
      ? rowSelectedContainerStyle
      : isHover
      ? rowHoverContainerStyle
      : void 0;

    let component = (
      <View
        style={{
          flexDirection: 'row',
          overflow: 'hidden',
          ...rowContainerStyle,
          ...rowContainerExtraStyle,
        }}>
        {columns
          ? columns.map(({column, widthStyle}) => {
          if (!column) {
            return null;
          }
          let {action, actions, ...restColumn} = column;
          // width disables flex so we need to remove flex related properties to stop log errors
          if (widthStyle?.width) {
            widthStyle = {...widthStyle, flex: void 0, flexShrink: void 0};
          }
          return (
            <View
              style={{
                ...rowColumnContainerStyle,
                ...widthStyle,
              }}>
              <ColumnWithAction
                {...restProps}
                action={action}
                actions={actions}
                column={restColumn}
              />
            </View>
          );
            })
          : void 0}
      </View>
    );
    return component;
  }
}

export default WithHoverActions(WithAction(TableRow));
