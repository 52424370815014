import moment from 'moment';
export const timeZoneOffset = new Date().getTimezoneOffset() * -1;

export const getZeroTimeDate = (date, eod) => {
  if (!date) {
    return date;
  }
  if (typeof date === 'string' || typeof date === 'number') {
    date = new Date(date);
  }
  let minutes = date.getTimezoneOffset() * -1;
  if (eod) {
    return moment(date).endOf('day').add(minutes, 'minutes').utc().toDate();
  } else {
    return moment(date).startOf('day').add(minutes, 'minutes').utc().toDate();
  }
};

export const getRangeData = ({filterOf, from, to, source}) => {
  if ((source === 'Prev' || source === 'Next') && filterOf !== 'Custom') {
    if (source === 'Prev') {
      from = moment(from).subtract(1, filterOf).toDate();
      to = moment(to).subtract(1, filterOf).toDate();
    } else {
      from = moment(from).add(1, filterOf).toDate();
      to = moment(to).add(1, filterOf).toDate();
    }
  } else if (filterOf !== 'Custom') {
    const today = moment().toDate();
    if (filterOf === 'Day' || filterOf === 'Month') {
      from = getZeroTimeDate(today);
      to = getZeroTimeDate(today, true);
      if (filterOf === 'Month') {
        from = moment(today).startOf(filterOf).toDate();
        to = moment(today).endOf(filterOf).toDate();
        from = getZeroTimeDate(from);
        to = getZeroTimeDate(to, true);
      }
    } else if (filterOf === 'Week') {
      from = moment(today).subtract(3, 'day').startOf('day').toDate();
      from = getZeroTimeDate(from);
      to = moment(today).add(3, 'day').endOf('day').toDate();
      to = getZeroTimeDate(to, true);
    } else if (filterOf === 'Year') {
      const month = moment(today).format('M');
      const year = moment(today).format('YYYY');
      if (month > 3) {
        from = moment(`${4} ${year}`, 'MM YYYY').startOf('month').toDate();
        from = getZeroTimeDate(from);
        to = moment(`${3} ${year + 1}`, 'MM YYYY')
          .endOf('month')
          .toDate();
        to = getZeroTimeDate(to, true);
      } else {
        from = moment(`${4} ${year - 1}`, 'MM YYYY')
          .startOf('month')
          .toDate();
        from = getZeroTimeDate(from);
        to = moment(`${3} ${year}`, 'MM YYYY').endOf('month').toDate();
        to = getZeroTimeDate(to, true);
      }
    }
  } else if (filterOf === 'Custom') {
    from = from && getZeroTimeDate(from);
    to = to && getZeroTimeDate(to, true);
  }
  return {from, to};
};
