import React from 'react';
import { View, TouchableOpacity } from '@applane/react-core-components';
import { getPermission } from '../../AppServices';

const actions = {};
export const registerAction = (type, action) => {
  actions[type] = action;
};
export const getAction = (type) => actions[type];
class ActionContainer extends React.Component {
  state = {};

  onLongPress = () => {
    const { item, navigation } = this.props;
    if (navigation) {
      const selected = navigation.isSelected && navigation.isSelected(item);
      selected
        ? navigation.unselectRow && navigation.unselectRow(item)
        : navigation.selectRow && navigation.selectRow(item);
    }
  };

  componentWillUnmount() {
    const { action: { onUnmount } = {} } = this.props;
    onUnmount && onUnmount({ ...this.props });
  }

  onPress = () => {
    const {
      children, selectable, style, ...restProps
    } = this.props;
    const {
      action, navigation, closeModal, onSelect,
    } = restProps;

    if (selectable && navigation) {
      const selectMode = navigation.selectMode && navigation.selectMode();
      if (selectMode) {
        this.onLongPress();
        return;
      }
    }
    let confirmAction = void 0;
    if (action && typeof action === 'function') {
      action({ ...restProps });
    } else if (action) {
      const { confirm, type, onPress } = action;
      confirmAction = confirm && getAction && getAction('confirm');
      const actionTask = confirmAction
        || (getAction && type && getAction(type))
        || action.action
        || onPress;
      if (actionTask) {
        actionTask({ ...restProps, actionRef: this.actionRef });
      }
    }
    if (!confirmAction) {
      onSelect && onSelect({ ...restProps });
    }
    closeModal && closeModal();
  };

  render() {
    let {
      children,
      selectable,
      highlightColor,
      style,
      navigation,
      item,
      action,
      disabled,
    } = this.props;
    if (!action) {
      return <View style={style}>{children}</View>;
    }
    let addOnProps = { onPress: this.onPress };
    if (selectable) {
      addOnProps = { ...addOnProps, onLongPress: this.onLongPress };
      if (navigation) {
        if (navigation.isSelected && navigation.isSelected(item)) {
          style = { ...style, backgroundColor: highlightColor };
        }
      }
    }

    return (
      <TouchableOpacity
        style={{ ...style, cursor: disabled ? 'not-allowed' : 'pointer' }}
        {...addOnProps}
        disabled={disabled}
        activeOpacity={1}
        ref={(_) => (this.actionRef = _)}>
        {children}
      </TouchableOpacity>
    );
  }
}

export const Action = ActionContainer;
