import React from 'react';

import {
  Text, View,
} from '../../../app-components';
import { Form } from '../../../app-components/form/Form';
import FileLink from '../../../Components/FileLink';
import {
  doseTime, frequency, MODELS,
} from '../../../Lib/constants';
import { getString } from '../../../Lib/helpers';
import { consultationDetailUri } from '../../../Queries/consultations';
import { autoSuggestInput, searchInput } from '../../../autoSuggestions';
import vars from '../../../theme/vars';
import { NestedTable } from '../../../app-components/input-components/nestedTable/NestedTable';
import { colors } from '../../../theme/mayaMdColors';

const ViewConsultedPatientHistory = (props) => {
  const { navigation: { state: { params: { item = {} } = {} } = {} } = {} } = props;

  return (
    <Form
      {...props}
      editable={false}
      uri={consultationDetailUri(item?._id)}
      lg={{
        formGroups: [
          {
            columnsPerRow: 4,
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    type: 'date',
                    field: '_createdOn',
                    label: 'consultation.labels.consultationDate'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'patient.uhid',
                    label: 'consultation.labels.uhid'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'patient.patientName',
                    label: 'consultation.labels.patientName'.getLabel(),
                  },
                  {
                    type: 'consultationStatus',
                    field: 'status',
                    label: 'consultation.labels.status'.getLabel(),
                  },
                  {
                    type: 'text',
                    label: 'consultation.labels.assistantOnDuty'.getLabel(),
                    render: () => (
                      <Text style={{ ...vars.headings.h8 }}>
                        {getString([item?.assignedBy?.firstName,
                               item?.assignedBy?.lastName]) || '--'}
                      </Text>
                    ),
                  },
                  {
                    type: 'number',
                    field: 'sessionQueue.room.name',
                    label: 'consultation.labels.roomNo'.getLabel(),
                  },
                  {
                    type: 'number',
                    field: 'ODPNumber',
                    label: 'consultation.labels.opdNumber'.getLabel(),
                  },
                  {
                    type: 'text',
                    label: 'consultation.labels.doctorName'.getLabel(),
                    field: 'consultedBy.name',
                  },
                  {
                    type: 'text',
                    field: 'speciality.name',
                    label: 'consultation.labels.speciality'.getLabel(),
                  },
                  {
                    type: 'file',
                    field: 'prescription',
                    render: ({ data }) => {
                      const { prescription } = data;
                      if (prescription) {
                        return (
                          <FileLink style={{ ...vars.headings.h9 }} file={prescription} />
                        );
                      }
                      return <View />;
                    },
                    label: 'consultation.labels.uploadedPrescription'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'remarks',
                    label: 'consultation.labels.remarks'.getLabel(),
                  },
                  {
                    type: 'text',
                    field: 'diagnosis.name',
                    label: 'consultation.labels.diagnose'.getLabel(),
                  },
                ],
              },
            ],
          },
          {
            fieldVariant: 'filled',
            groupsContainerStyle: { paddingLeft: 12 },
            groups: [
              {
                columns: [
                  {
                    field: 'pharmacyDetails',
                    fieldType: 'nested',
                    Component: NestedTable,
                    componentProps: {
                      maxHeight: 400,
                      skipTableHeaderOnNoData: true,
                      listProps: {
                        hideColumnHeader: false,
                        headerRowContainerStyle: {
                          paddingLeft: 12,
                          paddingRight: 4,
                        },
                        rowColumnTextStyle: {
                          ...vars.headings.h9,
                          color: '#fff',
                          numberOfLines: 1,
                        },
                        headerRowColumnTextStyle: {
                          ...vars.headings.h13,
                          color: '#fff',
                          backgroundColor: colors.themeColor,
                          numberOfLines: 1,
                          marginBottom: 10,
                        },
                        columns: [
                          searchInput({
                            type: 'autoSuggest',
                            field: 'item',
                            header: `${'billing.labels.itemName'.getLabel()}`,
                            variant: 'filled',
                            keyFiled: 'name',
                            suggestionField: 'name',
                            valueField: 'name',
                            model: MODELS.PHARMACY_ITEM,
                            query: 'masterDataList',
                            width: 200,
                          },
                          {
                            addOnFilter: ({
                              data: {
                                _parent: {
                                  pharmacyDetails = [],
                                } = {},
                              },
                            } = {}) => {
                              const itemIds = pharmacyDetails?.filter(
                                ({ item }) => item?._id,
                              ).map(({ item }) => item?._id);

                              return {
                                _id: { $nin: itemIds },
                              };
                            },
                          }),
                          searchInput({
                            type: 'autoSuggest',
                            field: 'item',
                            header: `${'billing.labels.itemId'.getLabel()}`,
                            variant: 'filled',
                            keyFiled: 'itemCode',
                            suggestionField: 'itemCode',
                            valueField: 'itemCode',
                            model: MODELS.PHARMACY_ITEM,
                            query: 'masterDataList',
                            mandatory: true,
                            disabled: true,
                            width: 200,
                          }),
                          {
                            type: 'number',
                            header: `${'billing.labels.quantity'.getLabel()}`,
                            field: 'quantity',
                            variant: 'filled',
                            mandatory: true,
                            width: 100,
                          },
                          autoSuggestInput({
                            header: `${'billing.labels.frequency'.getLabel()}`,
                            field: 'frequency',
                            suggestionField: 'label',
                            placeholder: 'Select frequency',
                            options: frequency,
                            mandatory: true,
                            variant: 'filled',
                            width: 200,
                          }),
                          {
                            type: 'text',
                            header: `${'billing.labels.doses'.getLabel()}`,
                            field: 'doses',
                            variant: 'filled',
                            mandatory: true,
                            width: 100,
                          },
                          autoSuggestInput({
                            header: `${'billing.labels.doseTime'.getLabel()}`,
                            field: 'doseTime',
                            suggestionField: 'label',
                            placeholder: 'select dose time',
                            options: doseTime,
                            mandatory: true,
                            variant: 'filled',
                            width: 150,
                          }),
                          {
                            type: 'text',
                            header: 'Comments',
                            field: 'comments',
                            variant: 'filled',
                            width: 200,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
      }}
    />
  );
};

export default ViewConsultedPatientHistory;
