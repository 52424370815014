export default {
  label: {
    companyName: 'Company Name',
    description: 'Description',
    email: 'Email',
    address: 'Address',
    contactNo: 'Contact No',
    status: 'Status',
  },
  placeholder: {},
  header: {
    companyName: 'Company Name',
    description: 'Description',
    email: 'EMAIL',
    address: 'ADDRESS',
    contactNo: 'CONTACT NO',
    status: 'STATUS',
  },
  message: {
    updateMessage: 'Company has been updated successfully.',
    submitMessage: 'Company has been added successfully.',
  },
};
