export default {
  labels: {
    donorId: 'Donor Id',
    donorName: 'Donor Name',
    age: 'Age',
    donationDate: 'Donation Date',
    gender: 'Gender',
    bloodGroup: 'Blood Group',
    mobile: 'Contact Number',
    occupation: 'Occupation',
    address: 'Address',
    additionalInfo: 'Additional Information',
    file: 'Documents of Donor',
    addNewItem: '+ Add New Item',
    time: 'Time Of Issue',
    bagNo: 'Bag No.',
    bagWeight: 'Bag Weight (GM)',
    tubeNo: 'Tube No.',
    donationNo: 'Donation No.',
    remarks: 'Remarks',
    expiryDate: 'Expiry Date',
  },
  placeholders: {
    donorId: 'Donor Id',
    donorName: 'Donor Name',
    age: 'Age',
    gender: 'Gender',
    bloodGroup: 'Blood Group',
    mobile: 'Contact Number',
    occupation: 'Occupation',
    address: 'Address',
    additionalInfo: 'Additional Information',
    file: 'Drag & Drop Your Files Here',
    typeHere: 'Type Here',
    enterExpiryDate: 'Enter Expiry Date',
  },
  message: {
    add: 'Donor has been added successfully.',
    edit: 'Donor has been updated successfully.',
    bagNoExist: 'Bag number already exist',
    addDonation: 'Donation has been added successfully.',
    bloodBag: 'Please select type of blood bag',
    fpp: 'Please fill fpp',
    prbc: 'Please fill prbc',
    platelets: 'Please fill platelets',
  },
  buttons: {
    editDonor: 'Edit Donor',
    addDonation: 'Add Donation',
  },
  headers: {
    donorId: 'Donor Id',
    donorName: 'Donor Name',
    bloodGroup: 'Blood Group',
    age: 'Age',
    mobile: 'Contact Number',
    gender: 'Gender',
    donationDate: 'Donation Date',
    unitDonated: 'Unit Donated',
    weightDonated: 'Weight',
    donationNo: 'Donation No.',
    bagNo: 'Bag No.',
    tubeNo: 'Tube No.',
    action: 'Action',
    category: 'Category',
  },
};
