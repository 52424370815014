import React, {Component} from 'react';
import {Image, Text, View} from '@applane/react-core-components';
import {Action} from './Action';
import {shadow} from '../../theme/shadows';
import {manazeV5Colors} from '../../theme/manazeV5Colors';
const {primary_900, Surface} = manazeV5Colors;
const floatingActionsStyle = {
  actionContainerStyle: {
    right: 24,
    bottom: 26,
    position: 'absolute',
  },
  containerStyle: {
    flexDirection: 'row',
    height: 50,
    width: 50,
    borderRadius: 50 / 2,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Surface,
    ...shadow,
  },
  actionTextStyle: {fontSize: 28, color: primary_900},
  actionIconStyle: {height: 16, width: 16},
};
const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

export class FloatingActions extends Component {
  state = {expanded: false};

  getActions = ({
    uid,
    state,
    setState,
    actions,
    navigation,
    eventDispatcher,
    ...rest
  }) => {
    const {
      actionContainerStyle,
      containerStyle,
      actionTextStyle,
      actionIconStyle,
    } = rest || {};

    if (actions && typeof actions === 'function') {
      actions = actions();
    }
    if (!actions || !actions.length) {
      return null;
    }

    return (
      <View style={actionContainerStyle}>
        {actions.map((action, index) => {
          if (!action) {
            return null;
          }
          let actionProps = {
            uid,
            navigation,
            eventDispatcher,
            index,
            state,
            setState,
          };
          action = getRenderComponent(action, actionProps);
          if (React.isValidElement(action)) {
            return <View style={containerStyle}>{action}</View>;
          }

          let {
            icon,
            text,
            style = containerStyle,
            iconStyle = actionIconStyle,
            textStyle = actionTextStyle,
            visible = true,
          } = action;
          if (typeof visible === 'function') {
            visible = visible && visible(actionProps);
          }
          if (!visible) {
            return null;
          }
          actionProps = {
            ...actionProps,
            action,
          };
          let component = null;
          if (action.render) {
            component = getRenderComponent(action.render, actionProps);
          } else if (icon || text) {
            component = (
              <>
                {icon && <Image style={iconStyle} source={icon} />}
                {text && <Text style={textStyle}>{text}</Text>}
              </>
            );
          }
          if (!component) {
            return null;
          }
          return (
            <Action style={style} {...actionProps}>
              {component}
            </Action>
          );
        })}
      </View>
    );
  };

  render() {
    const {
      uid,
      state,
      setState,
      floatingActions,
      navigation,
      eventDispatcher,
    } = this.props;
    if (!floatingActions) {
      return null;
    }
    return this.getActions({
      uid,
      state,
      setState,
      actions: floatingActions,
      navigation,
      eventDispatcher,
      ...floatingActionsStyle,
    });
  }
}

export default FloatingActions;
