import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image,
} from '@applane/react-core-components';
import { WithInputWrapper, WithInputError } from '@applane/react-input-wrapper';

class CheckBoxGroup extends React.Component {
  render() {
    let {
      value,
      options,
      style,
      optionStyle,
      labelStyle,
      selectedLabelStyle,
      direction = 'row',
      labelDirection = 'right',
      onChangeValue,
      defaultValue = [],
      editable,
      disabled,
      selectedIcon,
      unSelectedIcon,
      iconStyle,
      ...restProps
    } = this.props;
    if (!options || !options.length) {
      return null;
    }
    if (!value) {
      value = defaultValue;
    }
    return (
      <View
        style={{
          ...(direction === 'row'
            ? { flex: 1, flexWrap: 'wrap', flexDirection: 'row' }
            : {}),
          ...style,
        }}>
        {options.map((option) => {
          if (!option) {
            return null;
          }
          let optionValue = option;
          let optionLabel = option;
          if (typeof option !== 'string') {
            optionValue = option.value;
            optionLabel = option.label;
          }
          let selected;
          if (value !== undefined || value !== null || value.length) {
            value.map((item) => {
              if (item === optionValue) {
                selected = true;
              }
            });
          }
          let labelComponent = optionLabel ? (
            <Text
              style={{
                ...labelStyle,
                ...(selected ? selectedLabelStyle : {}),
              }}>
              {optionLabel}
            </Text>
          ) : (
            void 0
          );
          const onPress = (e) => {
            let { onChangeValue } = this.props;
            let updatedValue =
              (value.length > 0 &&
                value.filter((item) =>
                  item === optionValue ? false : true,
                )) ||
              [];
            if (updatedValue.length === value.length) {
              updatedValue.push(optionValue);
            }
            onChangeValue && onChangeValue([...updatedValue], e);
          };
          let renderComponent = (
            <View style={{ alignItems: 'flex-start' }}>
              <TouchableOpacity style={{ cursor: 'pointer' }} onPress={onPress}>
                <Image
                  style={iconStyle}
                  source={selected ? selectedIcon : unSelectedIcon}
                  resizeMode="contain"
                />
              </TouchableOpacity>
            </View>
          );
          if (editable === false || disabled) {
            renderComponent = (
              <View style={{ alignItems: 'flex-start', cursor: 'not-allowed' }}>
                <Image
                  source={selected ? selectedIcon : unSelectedIcon}
                  resizeMode="contain"
                />
              </View>
            );
          }
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                ...optionStyle,
              }}>
              {labelDirection === 'left' ? labelComponent : void 0}
              {renderComponent}
              {labelDirection === 'right' ? labelComponent : void 0}
            </View>
          );
        })}
      </View>
    );
  }
}

CheckBoxGroup.defaultProps = {
  optionStyle: { paddingRight: 8 },
  labelStyle: { paddingLeft: 8 },
};

CheckBoxGroup = WithInputWrapper(WithInputError(CheckBoxGroup));

export default CheckBoxGroup;
