export default {
    labels: {
        username: 'Employee Id',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
        resetPassword: 'Reset Password',
    },
    placeholders: {
        username: 'Enter your employee code',
        newPassword: 'New Password',
        confirmPassword: 'Confirm Password',
    },
    buttons: {
        login: 'Login',
        save: 'Save',
    },
    title: {
        resetPassword: 'Reset Password',
    },
};
