import React from 'react';

export default Component => {
  class WithAction extends React.Component {
    render() {
      let {
        action,
        actions,
        longPressActions,
        actionProps,
        selectable,
        disabled,
        ...restProps
      } = this.props;
      let renderComponent = <Component {...restProps} />;
      let {style, Action, MultiActions, ...restActionProps} = restProps;

      if (action && Array.isArray(action)) {
        actions = action;
        action = void 0;
      }
      if ((action || selectable) && Action) {
        if (typeof action === 'function') {
          action = action(restProps);
        }
        if (action || selectable) {
          renderComponent = (
            <Action
              action={action}
              {...actionProps}
              {...restActionProps}
              disabled={disabled}
              selectable={selectable}>
              {renderComponent}
            </Action>
          );
        }
      } else if (MultiActions && (actions || longPressActions)) {
        if (typeof actions === 'function') {
          actions = actions(restActionProps);
        }
        if (typeof longPressActions === 'function') {
          longPressActions = longPressActions(restActionProps);
        }
        if (actions?.length || longPressActions?.length) {
          renderComponent = (
            <MultiActions
              actions={actions}
              longPressActions={longPressActions}
              Action={Action}
              {...actionProps}
              {...restActionProps}>
              {renderComponent}
            </MultiActions>
          );
        }
      }
      return renderComponent;
    }
  }
  return WithAction;
};
