export default {
  labels: {
    room: 'Room Number',
    name: 'Name',
    speciality: 'Speciality',
    department: 'Department',
    totalBeds: 'Total beds',
    emptyBeds: 'Vacant beds',
    actions: 'Actions',
    status: 'Status',
    edit: 'Edit',
    addRoom: 'Add room',
    createdOn: 'Created On',
  },
  placeholders: {
    rooms: 'Select Rooms',
    speciality: 'Select Speciality',
    department: 'Select department',
  },
  title: {
    addRoom: 'Add Room',
    editRoom: 'Edit Room',
    subTitle: 'Fill out the form to add new Room',
    roomDetails: 'Room Details',
    exportToExcel: 'Export to Excel',
    roomManagement: 'Room Management',
  },
  messages: {
    roomAddedSuccessfully: 'Room added successfully',
    roomEditedSuccessfully: 'Room edited successfully',
    roomAlreadyExists: 'Room number already exists',
  },
};
