import {
  filledInputTheme,
  filledCompactInputTheme,
  filledCompactUnborderedInputTheme,
  standardCompactInputTheme,
  standardInputTheme,
  standardCompactUnborderedInputTheme,
  filledCompactBorderedInputTheme,
  standardCompactBorderedInputTheme,
} from '../../theme/inputTheme';
import {
  filledCompactBorderedMobileInputTheme,
  filledCompactMobileInputTheme,
  filledCompactUnborderedMobileInputTheme,
  filledMobileInputTheme,
  standardCompactBorderedMobileInputTheme,
  standardCompactMobileInputTheme,
  standardCompactUnborderedMobileInputTheme,
  standardMobileInputTheme,
} from '../../theme/inputMobileTheme';
import { isMobile } from '../../Lib/detectMobile';

import {
  fileInputBoxTheme,
  compactFileInputBoxTheme,
} from '../../theme/fileBoxTheme';

const variantWiseInputTheme = isMobile
  ? {
      standard: standardMobileInputTheme,
      filled: filledMobileInputTheme,
      'standard-compact': standardCompactMobileInputTheme,
      'filled-compact': filledCompactMobileInputTheme,
      'standard-compact-unbordered': standardCompactUnborderedMobileInputTheme,
      'filled-compact-unbordered': filledCompactUnborderedMobileInputTheme,
      'standard-compact-bordered': standardCompactBorderedMobileInputTheme,
      'filled-compact-bordered': filledCompactBorderedMobileInputTheme,
    }
  : {
      standard: standardInputTheme,
      filled: filledInputTheme,
      'standard-compact': standardCompactInputTheme,
      'filled-compact': filledCompactInputTheme,
      'standard-compact-unbordered': standardCompactUnborderedInputTheme,
      'filled-compact-unbordered': filledCompactUnborderedInputTheme,
      'standard-compact-bordered': standardCompactBorderedInputTheme,
      'filled-compact-bordered': filledCompactBorderedInputTheme,
    };

const variantWiseFileTheme = {
  standard: fileInputBoxTheme,
  filled: fileInputBoxTheme,
  'standard-compact': compactFileInputBoxTheme,
  'filled-compact': compactFileInputBoxTheme,
  'standard-compact-unbordered': compactFileInputBoxTheme,
  'filled-compact-unbordered': compactFileInputBoxTheme,
  'standard-compact-bordered': compactFileInputBoxTheme,
  'filled-compact-bordered': compactFileInputBoxTheme,
};

export const getTheme = (props, variantWiseTheme) => {
  let {variant} = props || {};
  let inputTheme = void 0;
  let inputVariant = void 0;
  if (variant && typeof variant === 'string') {
    inputVariant = variant.split('-')[0];
    inputTheme = variantWiseTheme[variant] || variantWiseTheme[inputVariant];
  }
  if (!inputTheme) {
    inputVariant = 'standard';
    inputTheme = variantWiseTheme[inputVariant];
  }
  inputTheme = {
    ...inputTheme,
    variant: inputVariant,
  };
  return inputTheme;
};

export const getInputTheme = props => {
  return getTheme(props, variantWiseInputTheme);
};
export const getFileTheme = props => {
  return getTheme(props, variantWiseFileTheme);
};
