import { WithInputError, getResolvedStyle } from "@applane/react-input-wrapper";
import React, { Component } from "react";
import { View, Text, Image } from "@applane/react-core-components";
import { ActivityIndicator } from "@applane/react-activity-indicator";
import { getRenderComponent } from "./fileUtility";

class FileInputRenderComponent extends Component {
  render() {
    let {
      inputStyle,
      disabledStyle,
      uploadIcon,
      uploadLabel = "Drag & Drop your files here",
      uploadIconStyle,
      uploadLabelStyle,
      placeholder,
      label,
      mandatory,
      renderMandatory,
      mandatoryStyle,
      disabled,
    } = this.props;
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          ...inputStyle,
          ...(disabled ? disabledStyle : void 0),
        }}
      >
        <Image source={uploadIcon} style={uploadIconStyle} resizeMode={"contain"} />

        <Text style={uploadLabelStyle}>{label || placeholder || uploadLabel}</Text>
        {mandatory
          ? getRenderComponent(renderMandatory) || (
              <Text
                style={{
                  ...uploadLabelStyle,
                  ...mandatoryStyle,
                  numberOfLines: void 0,
                }}
              >
                *
              </Text>
            )
          : void 0}
      </View>
    );
  }
}

FileInputRenderComponent = WithInputError(FileInputRenderComponent);

export default class FileInputRender extends React.Component {
  render() {
    let { renderInputComponent, uploading } = this.props;
    if (renderInputComponent && typeof renderInputComponent === "function") {
      renderInputComponent = renderInputComponent(this.props);
    }
    if (!renderInputComponent) {
      let style = getResolvedStyle({
        ...this.props,
      });
      renderInputComponent = (
        <View style={style}>
          <FileInputRenderComponent {...this.props} />
          {uploading && ActivityIndicator && (
            <View
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActivityIndicator key="file_upload_status" size={"small"} color="green" />
            </View>
          )}
        </View>
      );
    }
    return renderInputComponent;
  }
}
