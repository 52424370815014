import {Platform} from '@applane/react-core-components';

export const resolveValue = (values, key) => {
  if (!values || !key) {
    return values;
  }
  if (typeof key === 'function') {
    return key(values);
  }
  if (Array.isArray(values)) {
    var result = [];
    for (var i = 0; i < values.length; i++) {
      var row = values[i];
      var resolvedValue = resolveValue(row, key);
      if (resolvedValue !== undefined) {
        if (Array.isArray(resolvedValue)) {
          for (var j = 0; j < resolvedValue.length; j++) {
            /* for save the duplicate values for field other then _id @sourabh -- 4-2-17*/
            if (key !== '_id' || result.indexOf(resolvedValue[j]) === -1) {
              result.push(resolvedValue[j]);
            }
          }
        } else {
          if (key !== '_id' || result.indexOf(resolvedValue) === -1) {
            result.push(resolvedValue);
          }
        }
      }
    }
    return result;
  } else {
    var value = values[key];
    if (value !== undefined) {
      return value;
    }
    var index = key.indexOf('.');
    if (index === -1) {
      return;
    }
    var firstPart = key.substring(0, index);
    var nextPart = key.substring(index + 1);
    return resolveValue(values[firstPart], nextPart);
  }
};

export const resolveVisibleExp = (visible = true, props = {}) => {
  let {resolveVisible, ...restProps} = props;
  if (resolveVisible) {
    return resolveVisible({...restProps, visible});
  }
  if (typeof visible === 'function') {
    visible = visible(props);
  }
  return visible;
};

export const resolveActions = (actions, props) => {
  if (props.resolveActions) {
    return props.resolveActions({...props, actions});
  }
  if (typeof actions === 'function') {
    actions = actions(props);
  }
  if (!Array.isArray(actions) || !actions.length) {
    return actions;
  }
  return actions.filter(action => {
    if (typeof action === 'object') {
      let {visible, actions: innerActions} = action;
      if (visible !== undefined) {
        visible = resolveVisibleExp(visible, props);
        if (!visible) {
          return false;
        }
      }
      if (innerActions && Array.isArray(innerActions)) {
        let resolvedInnerActions = resolveActions(innerActions, props);
        if (!resolvedInnerActions || !resolvedInnerActions.length) {
          return false;
        }
      }
    }
    return true;
  });
};

export const modifyStyle = ({
  style,
  width,
  maxWidth,
  minWidth,
  direction,
  flex,
  position,
  align,
  defaultAlign = 'center',
}) => {
  style = {
    ...style,
  };
  if (width) {
    style.width = width;
  }
  if (maxWidth) {
    style.maxWidth = maxWidth;
  }
  if (minWidth) {
    style.minWidth = minWidth;
  }
  if (direction) {
    style.flexDirection = direction;
  }
  if (flex) {
    style.flex = flex;
    style.overflow = 'hidden';
  }
  if (direction === 'row') {
    if (position === 'top') {
      style.alignItems = 'flex-start';
    } else if (position === 'center') {
      style.alignItems = 'center';
    } else if (position === 'bottom') {
      style.alignItems = 'flex-end';
    } else if (defaultAlign) {
      style.alignItems = defaultAlign;
    }
    if (align === 'left') {
      style.justifyContent = 'flex-start';
    } else if (align === 'center') {
      style.justifyContent = 'center';
    } else if (align === 'right') {
      style.justifyContent = 'flex-end';
    } else if (align) {
      style.justifyContent = align;
    }
  } else {
    if (position === 'top') {
      style.justifyContent = 'flex-start';
    } else if (position === 'center') {
      style.justifyContent = 'center';
    } else if (position === 'bottom') {
      style.justifyContent = 'flex-end';
    } else if (defaultAlign) {
      style.justifyContent = defaultAlign;
    }
    if (align === 'left') {
      style.alignItems = 'flex-start';
    } else if (align === 'center') {
      style.alignItems = 'center';
    } else if (align === 'right') {
      style.alignItems = 'flex-end';
    } else if (align) {
      style.alignItems = align;
    }
  }
  return style;
};

export const detectMob = () => {
  if (
    Platform.OS !== 'web' ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
