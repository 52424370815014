/* eslint-disable import/no-cycle */
import React from 'react';
import { View, Text } from '../../app-components';
import vars from '../../theme/vars';

const ConsultationStatusCell = (props = {}) => {
  const {
    value, label, containerStyles,
  } = props;

  let consultationValue = 'Pending';
  let textStyle = {
    ...vars.headings.h11,
    color: vars.colors.warning,
  };
  let dotStyle = {
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: vars.colors.warning,
    marginLeft: 8,
  };

  if (value === 'completed') {
    consultationValue = 'Consulted';
    textStyle = {
      ...textStyle,
      color: vars.colors.primary.color2,
    };
    dotStyle = {
      ...dotStyle,
      backgroundColor: vars.colors.primary.color2,
    };
  }
  if (value === 'not-appeared') {
    consultationValue = 'Not Appeared';
    textStyle = {
      ...textStyle,
      color: vars.colors.error,
    };
    dotStyle = {
      ...dotStyle,
      backgroundColor: vars.colors.error,
    };
  }
  return (
    <View style={{ flex: 1 }}>
      {label && (
        <Text style={[
          vars.headings.h16,
          { color: vars.colors.grey.color3 },
        ]}
        >
          {label}
        </Text>
      )}
      <View style={[
        {
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
        },
        containerStyles,
      ]}
      >
        <Text style={textStyle}>{consultationValue}</Text>
        <View style={dotStyle} />
      </View>
    </View>
  );
};

export default ConsultationStatusCell;
