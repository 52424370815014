/* eslint-disable camelcase */
import { manazeV6Colors } from './manazeV6Colors';
import {
  Headline5, Headline8, Headline9,
} from './Headlines';
import vars from './vars';

const {
  negative_communication_3,
  negative_communication_4,
  positive_communication_3,
  positive_communication_4,
  primary_2,
  primary_1,
  neutral_5,
  surface_white,
} = manazeV6Colors;

const commonButtonStyle = {
  color: vars.colors.secondary.color2,
  hoverColor: vars.colors.secondary.color2,
  borderRadius: 4,
  labelStyle: {
    ...vars.headings.h7,
    color: vars.colors.white,
    numberOfLines: 1,
  },
  'style-sm': {
    paddingLeft: 16,
    paddingRight: 16,
  },
  'style-md': {
    paddingLeft: 16,
    paddingRight: 16,
  },
  'style-medium': {
    paddingLeft: 16,
    paddingRight: 16,
    height: 33,
    justifyContent: 'center',
  },
  'style-lg': {
    paddingLeft: 16,
    height: 40,
    justifyContent: 'center',
    paddingRight: 16,
  },
};
const commonButtonStyleForNotAppread = {
  color: '#EB5757',
  hoverColor: '#EB5757',
  borderRadius: 4,
  labelStyle: {
    ...vars.headings.h7,
    color: vars.colors.white,
    numberOfLines: 1,
  },
  'style-sm': {
    paddingLeft: 16,
    paddingRight: 16,
  },
  'style-md': {
    paddingLeft: 16,
    paddingRight: 16,
  },
  'style-lg': {
    paddingLeft: 16,
    height: 37,
    justifyContent: 'center',
    paddingRight: 16,
  },
};

const TableActionButtonStyles = {
  color: vars.colors.secondary.color4,
  hoverColor: vars.colors.secondary.color4,
  borderRadius: 4,
  width: 120,
  height: 40,
  borderColor: vars.colors.error,
  labelStyle: {
    ...vars.headings.h7,
    color: vars.colors.secondary.color2,
    numberOfLines: 1,
  },
};

const commonButtonMobileStyle = {
  color: primary_2,
  hoverColor: primary_2,
  borderRadius: 24,
  labelStyle: {
    ...Headline5,
    color: surface_white,
    numberOfLines: 1,
  },
};

export const negativeButtonStyle = {
  color: negative_communication_3,
  hoverColor: negative_communication_4,
};

export const positiveButtonStyle = {
  color: positive_communication_3,
  hoverColor: positive_communication_4,
};

export const standardButtonStyle = {
  ...commonButtonStyle,
  style: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 13,
    paddingBottom: 13,
  },
  size: 'lg',
};
export const standardButtonMediumStyle = {
  ...commonButtonStyle,
  style: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 13,
    paddingBottom: 13,
  },
  size: 'medium',
};

export const standardButtonMobileStyle = {
  ...commonButtonMobileStyle,
  style: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 9,
    paddingBottom: 9,
  },
};

export const compactButtonStyle = {
  ...commonButtonStyle,
  size: 'sm',
};

export const largeButtonStyle = {
  ...commonButtonStyle,
  size: 'lg',
};

export const TableActionButtonStyle = {
  ...TableActionButtonStyles,
  size: 'lg',
};

export const outlineButtonStyle = {
  ...commonButtonStyle,
  size: 'lg',
  outline: true,
};

export const notAppreadButtonStyle = {
  ...commonButtonStyleForNotAppread,
  size: 'lg',
  outline: true,
};

export const outlineButtonMobileStyle = {
  ...standardButtonMobileStyle,
  outline: true,
};

export const negativeStandardButtonStyle = {
  ...negativeButtonStyle,
  ...standardButtonStyle,
};

export const negativeStandardButtonMobileStyle = {
  ...negativeButtonStyle,
  ...standardButtonMobileStyle,
};

export const negativeCompactButtonStyle = {
  ...negativeButtonStyle,
  ...compactButtonStyle,
};

export const positiveStandardButtonStyle = {
  ...positiveButtonStyle,
  ...standardButtonStyle,
};

export const positiveStandardButtonMobileStyle = {
  ...positiveButtonStyle,
  ...standardButtonMobileStyle,
};

export const positiveCompactButtonStyle = {
  ...positiveButtonStyle,
  ...compactButtonStyle,
};

export const rowButtonStyle = {
  containerStyle: {
    background: primary_2,
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 16,
    paddingRight: 16,
    color: surface_white,
    borderRadius: 3,
  },
  textStyle: {
    ...Headline8,
  },
};

export const standardLinkButtonStyle = {
  style: {},
  labelStyle: {
    ...Headline9,
    color: primary_2,
  },
  hoverLabelStyle: {
    color: primary_1,
  },
  disabledLabelStyle: {
    color: neutral_5,
  },
};
