export default {
  labels: {
    doctor: 'doctor',
    speciality: 'Speciality',
    department: 'Department',
    room: 'Room No',
    viewDetails: 'View Details',
  },
  placeholders: {

  },
  title: {

  },
  messages: {
    consultationUpdatedSuccessfully: 'Consultation Updated successfully',
  },
};
