import {manazeV5Colors} from './manazeV5Colors';
import {manazeV6Colors} from './manazeV6Colors';

import {Headline8} from './Headlines';
import { checkSelectedIcon, unCheckboxIcon } from '../images';

const {
  neutral_500_medium_surface,
  neutral_300_low_surface,
  neutral_200,
  secondary_100,
  secondary_050,
} = manazeV5Colors;
const {primary_2} = manazeV6Colors;

export const checkBoxTheme = {
  labelContainerStyle: {
    paddingLeft: 12,
    alignItems: 'center',
  },
  iconStyle: { height: 20, width: 20 },
  selectedIcon: checkSelectedIcon,
  unSelectedIcon: unCheckboxIcon,
};
export const checkBoxGroupTheme = {
  ...checkBoxTheme,
  optionStyle: {paddingRight: 8},
  labelStyle: {paddingLeft: 8, ...Headline8, color: neutral_500_medium_surface},
  disabledColor: neutral_300_low_surface,
  activeDisabledColor: secondary_100,
  color: neutral_500_medium_surface,
  activeColor: primary_2,
  hoverColor: neutral_200,
  activeHoverColor: secondary_050,
};
