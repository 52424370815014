/**
 *
 * Save button in bottom
 *
 */
import React from 'react';
import { Keyboard } from '@applane/react-core-components';
import { loadData } from '@applane/data-update-v1';
import ReactFormContext from './ReactFormContext';
import ReactFormStateContext from './ReactFormStateContext';
import { mergeFieldValidationState, validate } from './Validations';
import { compute } from './Computations';
import {
  modifyUpdates,
  denormalizeUpdates,
  populateNestedDataAndUpdates,
} from './ComputationResolver';
import { getNewId, getUid, putDottedValue } from './Utility';

class ReactForm extends React.PureComponent {
  state = {};

  constructor(props) {
    super(props);
    if (!props.useState || !props.state) {
      this.state = { uid: getUid(), data: {} };
    }
    const { user, getUser, navigation, eventDispatcher } = props;
    this.formContext = {
      user,
      getUser,
      navigation,
      eventDispatcher,
      setValue: this.setValue,
      setFocus: this.setFocus,
      setState: this._setState,
      handleSubmit: this.handleSubmit,
    };
  }

  getFormUpdates = () => {
    const state = this._getState() || {};
    return {
      uid: state.uid,
      data: state.data,
      updates: state.updates,
    };
  };

  addFormUpdateListener = () => {
    let { eventDispatcher, formUpdateEvent } = this.props;
    if (!formUpdateEvent || this.formUpdateEvent) {
      return;
    }
    if (formUpdateEvent === true) {
      formUpdateEvent = 'formUpdates';
    }
    eventDispatcher &&
      eventDispatcher.listen(formUpdateEvent, this.getFormUpdates);
    this.formUpdateEvent = formUpdateEvent;
  };

  componentDidMount() {
    this.loadData(this._getState());
    const uid = this.getUid();
    const { eventDispatcher, navigation, reloadOnChangeEvent, screenName } =
      this.props;
    reloadOnChangeEvent &&
      eventDispatcher &&
      eventDispatcher.listen(reloadOnChangeEvent, this.reloadData);
    uid &&
      eventDispatcher &&
      eventDispatcher.listen(`${uid}-submit`, this.handleSubmit);
    eventDispatcher && eventDispatcher.notify(`${screenName}_formUid`, uid);
    if (navigation && navigation.state) {
      this.oldParams = navigation.state.params;
    }
  }

  componentWillUnmount() {
    this._unmounted = true;
    const uid = this.getUid();
    const { eventDispatcher, reloadOnChangeEvent } = this.props;
    reloadOnChangeEvent &&
      eventDispatcher &&
      eventDispatcher.unlisten(reloadOnChangeEvent, this.reloadData);
    uid &&
      eventDispatcher &&
      eventDispatcher.unlisten(`${uid}-submit`, this.handleSubmit);
    this.formUpdateEvent &&
      eventDispatcher &&
      eventDispatcher.unlisten(this.formUpdateEvent, this.getFormUpdates);
  }

  componentDidUpdate() {
    const { navigation, reloadOnNavigationChange } = this.props;
    if (navigation && navigation.state) {
      const { params } = navigation.state;
      if (
        reloadOnNavigationChange &&
        this.oldParams &&
        this.oldParams !== params
      ) {
        this.formContext = {
          ...this.formContext,
          navigation,
        };
        // load Data again
        const newState = {
          data: {},
          updates: void 0,
          mandatoryErrors: void 0,
          validationErrors: void 0,
          submitTried: false,
        };
        this.loadData(newState, { source: 'navigationChange' });
        this.oldParams = params;
      }
    }
  }

  getUid = () => {
    const state = this._getState();
    return state && state.uid;
  };

  _getState = () => {
    if (this.props.useState && this.props.state) {
      return this.props.state;
    }
    return this.state;
  };

  _setState = (_state, callback) => {
    if (this._unmounted) {
      return;
    }
    if (this.props.useState && this.props.setState) {
      this.props.setState(_state);
    } else {
      this.setState(_state, callback);
    }
  };

  reloadData = () => {
    this.loadData(this._getState(), { source: 'reloadEvent' });
  };

  loadData = async (state = {}, fetchProps) => {
    let {
      fetch,
      beforeFetch,
      afterFetch,
      getUser,
      dataMode,
      uri,
      data,
      navigation,
      defaultValues,
      eventDispatcher,
    } = this.props;
    if (dataMode !== 'insert' && uri) {
      if (typeof uri === 'function') {
        uri = uri({
          user: getUser && getUser(),
          navigation,
        });
      }
      const resp = loadData({
        uri,
        fetch,
        fetchProps,
        beforeFetch,
        afterFetch,
        state,
      });
      const { beforeState, afterState } = resp;
      state = { ...state, ...beforeState };
      this._setState(state);
      if (afterState) {
        afterState().then(async (_afterState) => {
          this._setState(_afterState);
          // THIS WILL ONLY WORK WHEN DATA IS EMPTY AND FORM WILL BECOME INSERT FORM.
          // INSERT AND UPDATE FORM
          if (!_afterState?.data?._id) {
            if (defaultValues && typeof defaultValues === 'function') {
              defaultValues = defaultValues({ navigation });
            }
            let values = defaultValues;
            values = values || {};
            values._id = values._id || getNewId();
            _afterState = {
              ..._afterState,
              data: { ..._afterState?.data, _id: values._id },
            };
            if (values && Object.keys(values).length) {
              // COMPUTATION BASED ON DEFAULT VALUE
              await this.handleUpdates(_afterState, { values });
            }
          }
          const { data } = _afterState || {};
          eventDispatcher &&
            eventDispatcher.notify &&
            eventDispatcher.notify(`${this.props.screenName}_loadData`, data);
        });
      }
    } else {
      if (typeof data === 'function') {
        data = data({ navigation });
      }
      state = { ...state, data: data || {} };

      if (navigation && navigation.getParam) {
        const prevData = navigation.getParam('nextData');
        const prevUpdates = navigation.getParam('nextUpdates');
        if (prevData) {
          state.data = { ...state.data, ...prevData };
        }
        if (prevUpdates) {
          state.updates = { ...state.updates, ...prevUpdates };
        }
      }
      if (defaultValues && typeof defaultValues === 'function') {
        defaultValues = defaultValues({ navigation });
      }
      let values = defaultValues;
      if (!state.data._id) {
        values = values || {};
        values._id = values._id || getNewId();
        state = { ...state, data: { ...data, _id: values._id } };
      }
      if (values && Object.keys(values).length) {
        this.handleUpdates(state, { values });
      } else {
        this._setState(state);
      }
    }
  };

  submitUpdates = async (params, autoSave) => {
    const {
      navigation,
      eventDispatcher,
      dataMode,
      onSubmit,
      beforeSubmit,
      afterSubmit,
      reloadEvent,
      submitNext,
      skipNoChangeError,
      deepDiff,
    } = this.props;
    let { data, updates } = this._getState();
    if (beforeSubmit) {
      let beforeSubmitState = {};
      const modifiedState = await beforeSubmit({
        ...params,
        navigation,
        eventDispatcher,
        data,
        updates,
      });
      if (modifiedState && typeof modifiedState === 'object') {
        beforeSubmitState = modifiedState;
        const { data: modifiedData, updates: modifiedUpdates } = modifiedState;
        if (modifiedData) {
          data = modifiedData;
        }
        if (modifiedUpdates) {
          updates = modifiedUpdates;
        }
      }
      if (beforeSubmitState && Object.keys(beforeSubmitState).length) {
        this._setState(beforeSubmitState);
      }
    }
    // in case of auto save we have to manage oldUpdates
    if (autoSave && deepDiff) {
      const oldupdates = { ...updates };
      updates = deepDiff(this.autoSaveUpdates || {}, updates);
      this.autoSaveUpdates = { ...oldupdates };
    }
    if (!skipNoChangeError && (!updates || !Object.keys(updates).length)) {
      const noChangesError = new Error('No Changes Found');
      noChangesError.code = 'no_changes_found';
      throw noChangesError;
    }

    const result =
      onSubmit &&
      (await onSubmit({
        ...params,
        navigation,
        eventDispatcher,
        dataMode,
        data,
        updates,
      }));
    const afterSubmitParams = {
      ...params,
      navigation,
      eventDispatcher,
      submitResult: result,
      data,
    };
    if (afterSubmit) {
      let afterSubmitState = {};
      const modifiedState = await afterSubmit(afterSubmitParams);
      if (modifiedState && typeof modifiedState === 'object') {
        afterSubmitState = {
          ...afterSubmitState,
          ...modifiedState,
        };
      }
      if (afterSubmitState && Object.keys(afterSubmitState).length) {
        this._setState(afterSubmitState);
      }
    }

    if (reloadEvent && eventDispatcher && eventDispatcher.notify) {
      if (Array.isArray(reloadEvent)) {
        reloadEvent.forEach((_event) => eventDispatcher.notify(_event, result));
      } else {
        eventDispatcher.notify(reloadEvent, result);
      }
    }
    submitNext && submitNext(afterSubmitParams);
  };

  handleNext = () => {
    const { data, updates } = this._getState();
    const { navigation, passDataToNext, next } = this.props;
    let nextParams = void 0;
    if (passDataToNext) {
      if (typeof passDataToNext === 'function') {
        nextParams = passDataToNext(this._getState(), { navigation });
      } else {
        nextParams = {
          nextData: { ...data },
          nextUpdates: { ...updates },
        };
      }
    }
    navigation && navigation.navigate && navigation.navigate(next, nextParams);
  };

  handleValidations = async () => {
    const {
      mandatoryErrors,
      hasMandatoryError,
      validationErrors,
      hasValidationError,
    } = await this.validateState({ state: this._getState() });
    if (hasMandatoryError || hasValidationError) {
      this._setState({
        mandatoryErrors,
        validationErrors,
      });
      this.props.onValidationError &&
        this.props.onValidationError({ mandatoryErrors, validationErrors });
      return true;
    }
  };

  closeForm = () => {
    let { navigation, eventDispatcher, closeView } = this.props;
    if (typeof closeView === 'function') {
      closeView = closeView({ navigation, eventDispatcher });
    }
    if (closeView && navigation && navigation.pop) {
      navigation.pop(closeView);
      return true;
    }
  };

  handleSubmit = async (params) => {
    try {
      if (this.submitting) {
        return;
      }
      Keyboard.dismiss();
      this.submitting = true;
      const isInvalid = await this.handleValidations();
      if (isInvalid) {
        this._setState({ submitTried: true });
      } else if (this.props.next) {
        this.handleNext(params);
      } else {
        this._setState({ submitTried: true, submitting: true });
        await this.submitUpdates(params);
        this._setState({ submitTried: false, submitting: false });
        this.props.onSubmitSuccess &&
          this.props.onSubmitSuccess(this._setState);
        if (this.props.closeView) {
          const isClose = this.closeForm();
          if (isClose) {
            return;
          }
        }
      }
    } catch (err) {
      this.props.onSubmitError && this.props.onSubmitError(err, this._setState);
    } finally {
      this.submitting = false;
      this.state.submitting && this._setState({ submitting: false });
    }
  };

  validateOnBlur = ({ path, field }) => {
    setTimeout(async (_) => {
      let revisedState = await this.validateState({
        state: this._getState(),
        field,
        path,
      });
      // for merging validation state by field,
      // because on change of other field validation it does not affect current state validations
      // Vishal Joshi - 26 June 2021
      revisedState = mergeFieldValidationState({
        state: this._getState(),
        newState: revisedState,
        field,
        path,
      });
      this._setState({ ...revisedState });
    }, 0);
  };

  setFocus = (focusProps) => {
    const { setFocus } = this.props;
    const state = this._getState();
    let { data, focusField, focussedFields } = state;
    if (setFocus) {
      // case of nested table field focus
      setFocus({ data, ...focusProps });
      return;
    }
    const { field, path, focus } = focusProps;
    if (!field) {
      return;
    }
    if (path && path.length) {
      const { _id: nestedId, field: nestedField } = path[0];
      if (path.length > 1) {
        const { _id: innerNestedId, field: innerNestedField } = path[1];
        if (focus) {
          focusField = {
            [nestedField]: {
              [nestedId]: {
                [innerNestedField]: {
                  [innerNestedId]: field,
                },
              },
            },
          };
        } else {
          const nestedFocusField =
            focusField &&
            focusField[nestedField] &&
            focusField[nestedField][nestedId];
          const innerNestedFocusField =
            nestedFocusField &&
            nestedFocusField[innerNestedField] &&
            nestedFocusField[innerNestedField][innerNestedId];
          if (innerNestedFocusField === field) {
            focusField = void 0;
          }
          const nestedFocussedFields = {
            ...(focussedFields ? focussedFields[nestedField] : void 0),
          };
          const innerNestedFocussedFields = {
            ...(nestedFocussedFields &&
              nestedFocussedFields[nestedId] &&
              nestedFocussedFields[nestedId][innerNestedField]),
          };
          focussedFields = {
            ...focussedFields,
            [nestedField]: {
              ...nestedFocussedFields,
              [nestedId]: {
                ...nestedFocussedFields[nestedId],
                [innerNestedField]: {
                  ...innerNestedFocussedFields,
                  [innerNestedId]: {
                    ...innerNestedFocussedFields[innerNestedId],
                    [field]: 1,
                  },
                },
              },
            },
          };
        }
      } else if (focus) {
        focusField = {
          [nestedField]: {
            [nestedId]: field,
          },
        };
      } else {
        const nestedFocusField =
          focusField &&
          focusField[nestedField] &&
          focusField[nestedField][nestedId];
        if (nestedFocusField === field) {
          focusField = void 0;
        }
        const nestedFocussedFields = {
          ...(focussedFields ? focussedFields[nestedField] : void 0),
        };
        focussedFields = {
          ...focussedFields,
          [nestedField]: {
            ...nestedFocussedFields,
            [nestedId]: {
              ...nestedFocussedFields[nestedId],
              [field]: 1,
            },
          },
        };
      }
    } else if (focus) {
      focusField = field;
    } else {
      if (focusField === field) {
        focusField = void 0;
      }
      focussedFields = { ...focussedFields, [field]: 1 };
    }
    if (!focus && this.props.validateOnBlur) {
      this.validateOnBlur({ path, field });
    }
    this._setState({
      focusField,
      focussedFields,
    });
  };

  handleAutoSave = async () => {
    const { onSubmit, autoSaveDuration = 1000, getDataMode } = this.props;
    if (!onSubmit) {
      return;
    }
    if (this.saveTimer) {
      clearTimeout(this.saveTimer);
    }

    this.saveTimer = setTimeout(async () => {
      const { data } = this._getState();

      const dataMode = (getDataMode && getDataMode({ data })) || 'insert';
      if (dataMode === 'insert') {
        try {
          if (this.autoSaving) {
            this.pendingAutoSaving = true;
            return;
          }
          this.autoSaving = true;
          await this.submitUpdates({}, true);
        } catch (err) {
          // console.log("!!!!!error in autosaving >>>>>", err)
        } finally {
          this.autoSaving = false;
          if (this.pendingAutoSaving) {
            this.pendingAutoSaving = false;
            this.handleAutoSave();
          }
        }
      } else {
        try {
          await this.submitUpdates({}, true);
        } catch (err) {
          // console.log("!!!!!error in autosaving >>>>>", err)
        }
      }
    }, autoSaveDuration);
  };

  handleAutoSaveOld = async ({ data, field, value }) => {
    const { onSubmit } = this.props;
    if (!onSubmit) {
      return;
    }
    try {
      const result = await onSubmit({
        data,
        updates: { [field]: value },
      });
      return result;
    } catch (err) {
      console.error('Error in DateUpdate autosave in data update', err);
      // ToDo handle error
    }
  };

  resolveComputations = async (state, newState, updates) => {
    const { computations, computeProps } = this.props;
    if (computations && (computations.self || computations.children)) {
      const computedUpdates = await compute({
        computations,
        computeProps,
        data: newState.data,
        updates,
      });
      if (computedUpdates) {
        newState = await this.modifyDataAndUpdates(
          { ...state, ...newState },
          computedUpdates
        );
      }
    }
    return newState;
  };

  modifyDataAndUpdates = async (state, updates) => {
    const { nestedFields } = this.props;
    const modifiedUpdates = denormalizeUpdates({
      updates,
      data: state.data,
      children: nestedFields,
    });
    if (!modifiedUpdates || !modifiedUpdates.length) {
      return;
    }
    let newState = { data: { ...state.data }, updates: { ...state.updates } };
    for (const modifiedUpdate of modifiedUpdates) {
      const { path, updates: updatesToSet } = modifiedUpdate;
      const { set, ...restUpdates } = updatesToSet;
      let newData = set;
      let newUpdates = set;
      if (path && path.length) {
        const { nestedData, nestedUpdates } = populateNestedDataAndUpdates(
          newState,
          {
            path,
            values: { ...set },
            ...restUpdates,
          }
        );
        newData = nestedData;
        newUpdates = nestedUpdates;
      }
      for (const key in newData) {
        putDottedValue(newState.data, key, newData[key]);
        if (this.props.validateOnChange && !this.props.validateAllOnChange) {
          newState = await this.validateState({
            state,
            newState,
            field: key,
            path,
          });
        }
      }
      for (const key in newUpdates) {
        putDottedValue(newState.updates, key, newUpdates[key]);
      }
    }
    if (this.props.validateAllOnChange) {
      newState = await this.validateState({
        state,
        newState,
      });
    }
    this._setState(newState);
    return await this.resolveComputations(state, newState, modifiedUpdates);
  };

  handleUpdates = async (state, valueProps) => {
    const modifiedUpdates = modifyUpdates(state, valueProps);
    if (!modifiedUpdates) {
      return;
    }
    this.addFormUpdateListener();
    await this.modifyDataAndUpdates(state, modifiedUpdates);
  };

  setValue = async (setValueProps) => {
    const state = this._getState();
    const { data } = state;
    const { setValue, saveOnChange } = this.props;
    if (setValue) {
      // case of nested table field updates
      setValue({ data, ...setValueProps });
      return;
    }
    await this.handleUpdates(state, setValueProps);
    saveOnChange && this.handleAutoSave();
  };

  validateState = async ({ state, newState, field, path }) => {
    const {
      mandatory,
      validations,
      validateOnUpdate,
      mandatoryMessage,
      nestedFields,
      checkCurrentValidations,
    } = this.props;
    const revisedState = await validate(
      { ...state, ...newState },
      {
        field,
        mandatory,
        validations,
        validateOnUpdate,
        mandatoryMessage,
        nestedFields,
        path,
        checkCurrentValidations,
      }
    );
    // for merging validation state by field,
    // because on change of other field validation it does not affect current state validations
    // Vishal Joshi - 26 June 2021
    // revisedState = mergeFieldValidationState({
    //   state: this._getState(),
    //   newState: revisedState,
    //   field,
    //   path,
    // });
    // return {
    //   ...state,
    //   ...revisedState,
    // };
    return revisedState;
  };

  render() {
    const state = this._getState();
    const { children } = this.props;
    return (
      <ReactFormContext.Provider value={this.formContext}>
        <ReactFormStateContext.Provider value={state}>
          {typeof children === 'function'
            ? children({
                form_context: this.formContext,
                form_state: state,
              })
            : children}
        </ReactFormStateContext.Provider>
      </ReactFormContext.Provider>
    );
  }
}
export default ReactForm;
