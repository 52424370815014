import React from 'react';
import {View, Image} from '@applane/react-core-components';

class LeftIcon extends React.Component {
  render() {
    let {icon, iconContainerStyle, iconStyle, children} = this.props;
    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
        }}>
        <View style={iconContainerStyle}>
          {icon && <Image style={iconStyle} source={icon} />}
        </View>
        <View style={{flex: 1, overflow: 'hidden', justifyContent: 'center'}}>
          {React.cloneElement(children, {
            icon: void 0,
          })}
        </View>
      </View>
    );
  }
}

LeftIcon.defaultProps = {
  iconContainerStyle: {
    height: 44,
    width: 38,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
};

export default LeftIcon;
