import { h20_AvenirNext_500 } from './fonts';
import {
  Headline11,
  Headline9,
  Headline12,
  Headline5,
  Headline10,
  Headline4,
} from './Headlines';
import { manazeV6Colors } from './manazeV6Colors';
import vars from './vars';

const {
  neutral_1,
  neutral_2,
  neutral_3,
  neutral_4,
  neutral_7,
  neutral_8,
  primary_5,
  primary_2,
  primary_6,
  surface_white,
} = manazeV6Colors;

export const cardTheme = {
  titleGap: 7,
  style: {
    backgroundColor: surface_white,
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    overflow: 'hidden',
  },
  hoverStyle: {
    borderColor: neutral_7,
  },
  selectedStyle: {
    backgroundColor: primary_5,
    borderColor: primary_2,
  },
  // selectedHoverStyle: {
  //   borderColor: primary_2,
  // },
  hoverActionsWrapperStyle: {
    top: 1,
    bottom: 1,
    right: 1,
    borderRadius: 8,
    backgroundColor: surface_white,
  },
  topHoverActionsWrapperStyle: {
    top: 1,
    right: 1,
    borderRadius: 8,
    backgroundColor: surface_white,
  },
  bottomHoverActionsWrapperStyle: {
    bottom: 1,
    right: 1,
    borderRadius: 8,
    backgroundColor: surface_white,
  },
  selectedHoverActionsWrapperStyle: {
    backgroundColor: primary_5,
  },
  hoverActionsContainerStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 8,
  },
  primaryTitleStyle: {
    ...Headline9,
    color: neutral_1,
    numberOfLines: 1,
  },
  secondaryTitleStyle: {
    ...Headline11,
    color: neutral_3,
    numberOfLines: 1,
    marginBottom: 4,
  },
  contentItemTextStyle: {
    ...Headline12,
    color: neutral_3,
    flexShrink: 1,
    numberOfLines: 1,
  },
  contentItemIconContainerStyle: {
    maxHeight: 14,
    maxWidth: 14,
    justifyContent: 'center',
    marginRight: 4,
  },
  contentItemSeparatorStyle: {
    marginLeft: 8,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentItemSeparatorTextStyle: { ...Headline4, color: neutral_4 },
  rightItemTextStyle: {
    ...Headline11,
    color: neutral_3,
    flexShrink: 1,
    numberOfLines: 1,
  },
  rightItemIconContainerStyle: {
    maxHeight: 14,
    maxWidth: 14,
    justifyContent: 'center',
    marginRight: 4,
  },
  avatarStyle: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: primary_5,
    textStyle: { ...Headline9, color: primary_2 },
  },
  selectedAvatarStyle: {
    backgroundColor: surface_white,
  },
  dotSeparatorStyle: {
    width: 5,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: neutral_4,
  },
  contentContainerStyle: {
    paddingTop: 16,
    paddingBottom: 16,
    marginLeft: 8,
    marginRight: 8,
  },
};

export const mayaCardTheme = {
  style: {
    backgroundColor: 'rgba(1, 59, 86, 0.35)',
    borderRadius: 16,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    overflow: 'hidden',
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 8,
    // marginTop: 63,
  },
  hoverStyle: {
    boxShadow:
      '0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)',
  },
  selectedStyle: {
    backgroundColor: 'rgba(1, 59, 86, 0.35)',
    borderColor: '#fff',
  },
  hoverActionsWrapperStyle: {
    top: 1,
    bottom: 1,
    right: 1,
    borderRadius: 4,
    backgroundColor: surface_white,
  },
  topHoverActionsWrapperStyle: {
    top: 1,
    right: 1,
    borderRadius: 4,
    backgroundColor: surface_white,
  },
  bottomHoverActionsWrapperStyle: {
    bottom: 1,
    right: 1,
    borderRadius: 4,
    backgroundColor: surface_white,
  },
  hoverActionsContainerStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    justifyContent: 'flex-end',
  },
  selectedHoverActionsWrapperStyle: {
    backgroundColor: primary_5,
  },
  primaryTitleStyle: {
    ...h20_AvenirNext_500,
    color: '#000',
    numberOfLines: 1,
    paddingTop: 23,
    paddingLeft: 22,
  },
  secondaryTitleStyle: {
    ...Headline11,
    color: neutral_3,
    numberOfLines: 1,
    marginBottom: 4,
  },
  contentItemTextStyle: {
    ...Headline12,
    color: neutral_3,
    flexShrink: 1,
    numberOfLines: 1,
  },
  contentItemIconStyle: {
    width: 16,
    height: 16,
    marginRight: 6,
  },
  rightItemTextStyle: {
    ...Headline11,
    color: neutral_3,
    flexShrink: 1,
    numberOfLines: 1,
  },
  defaultAvatarStyle: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: primary_5,
  },
  defaultAvatarTextStyle: { ...Headline9, color: primary_2 },
  dotSeparatorStyle: {
    width: 5,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: neutral_4,
  },
};

export const mayaCardMDTheme = {
  style: {
    // backgroundColor: '#fff',
    backgroundColor: 'rgba(1, 59, 86, 0.35)',
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    overflow: 'hidden',
    // marginLeft: 10,
    // marginRight: 10,
    marginBottom: 10,
    // marginTop: 63,
  },
  hoverStyle: {
    boxShadow:
      '0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)',
  },
  selectedStyle: {
    backgroundColor: 'rgba(1, 59, 86, 0.35)',
    borderColor: '#fff',
  },
  hoverActionsWrapperStyle: {
    top: 1,
    bottom: 1,
    right: 1,
    borderRadius: 4,
    backgroundColor: surface_white,
  },
  topHoverActionsWrapperStyle: {
    top: 1,
    right: 1,
    borderRadius: 4,
    backgroundColor: surface_white,
  },
  bottomHoverActionsWrapperStyle: {
    bottom: 1,
    right: 1,
    borderRadius: 4,
    backgroundColor: surface_white,
  },
  hoverActionsContainerStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    justifyContent: 'flex-end',
  },
  selectedHoverActionsWrapperStyle: {
    backgroundColor: primary_5,
  },
  primaryTitleStyle: {
    ...h20_AvenirNext_500,
    color: '#000',
    numberOfLines: 1,
    paddingTop: 23,
    paddingLeft: 22,
  },
  secondaryTitleStyle: {
    ...Headline11,
    color: neutral_3,
    numberOfLines: 1,
    marginBottom: 4,
  },
  contentItemTextStyle: {
    ...Headline12,
    color: neutral_3,
    flexShrink: 1,
    numberOfLines: 1,
  },
  contentItemIconStyle: {
    width: 16,
    height: 16,
    marginRight: 6,
  },
  rightItemTextStyle: {
    ...Headline11,
    color: neutral_3,
    flexShrink: 1,
    numberOfLines: 1,
  },
  defaultAvatarStyle: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: primary_5,
  },
  defaultAvatarTextStyle: { ...Headline9, color: primary_2 },
  dotSeparatorStyle: {
    width: 5,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: neutral_4,
  },
  contentItemStyle: {
    padding: 0,
    justifyContent: 'flex-start',
  },
};

export const cardMobileTheme = {
  titleGap: 4,
  style: {
    marginLeft: 12,
    backgroundColor: primary_6,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: neutral_8,
    overflow: 'hidden',
  },
  swipedStyle: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  hoverActionsWrapperStyle: {
    top: 0,
    bottom: 0,
    right: 0,
    paddingLeft: 12,
    backgroundColor: surface_white,
  },
  hoverActionsContainerStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 8,
  },
  primaryTitleStyle: {
    ...Headline5,
    color: neutral_1,
    numberOfLines: 1,
  },
  secondaryTitleStyle: {
    ...Headline11,
    color: neutral_3,
    numberOfLines: 1,
    marginBottom: 4,
  },
  contentItemTextStyle: {
    ...Headline12,
    color: neutral_2,
    flexShrink: 1,
    numberOfLines: 1,
  },
  contentItemIconContainerStyle: {
    maxHeight: 14,
    maxWidth: 14,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 4,
  },
  contentItemSeparatorStyle: {
    marginLeft: 4,
    marginRight: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentItemSeparatorTextStyle: { ...Headline4, color: neutral_4 },
  rightItemTextStyle: {
    ...Headline11,
    color: neutral_3,
    flexShrink: 1,
    numberOfLines: 1,
  },
  rightItemIconContainerStyle: {
    maxHeight: 14,
    maxWidth: 14,
    justifyContent: 'center',
    marginRight: 4,
  },
  avatarStyle: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: primary_5,
    textStyle: { ...Headline9, color: primary_2 },
  },
  selectedAvatarStyle: {
    backgroundColor: surface_white,
  },
  dotSeparatorStyle: {
    width: 3,
    height: 3,
    borderRadius: 1.5,
    backgroundColor: neutral_3,
  },
  contentContainerStyle: {
    paddingTop: 16,
    paddingBottom: 16,
    marginLeft: 8,
    marginRight: 8,
  },
};

export const headerCardTheme = {
  titleGap: 7,
  style: {
    backgroundColor: surface_white,
    overflow: 'hidden',
  },
  contentContainerStyle: {},
  primaryTitleStyle: {
    ...Headline9,
    color: neutral_1,
    numberOfLines: 1,
  },
  secondaryTitleStyle: {
    ...Headline11,
    color: neutral_3,
    numberOfLines: 1,
    marginBottom: 4,
  },
  contentItemTextStyle: {
    ...Headline12,
    color: neutral_3,
    flexShrink: 1,
    numberOfLines: 1,
  },
  contentItemIconContainerStyle: {
    maxHeight: 14,
    maxWidth: 14,
    justifyContent: 'center',
    marginRight: 4,
  },
  contentItemSeparatorStyle: {
    marginLeft: 8,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentItemSeparatorTextStyle: { ...Headline4, color: neutral_4 },
  dotSeparatorStyle: {
    width: 5,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: neutral_4,
  },
};

export const headerCardMobileTheme = {
  titleGap: 4,
  style: {
    backgroundColor: surface_white,
    overflow: 'hidden',
  },
  contentContainerStyle: {},
  primaryTitleStyle: {
    ...Headline4,
    color: neutral_1,
    numberOfLines: 2,
  },
  secondaryTitleStyle: {
    ...Headline11,
    color: neutral_3,
    numberOfLines: 1,
    marginBottom: 4,
  },
  contentItemTextStyle: {
    ...Headline10,
    color: neutral_2,
    flexShrink: 1,
    numberOfLines: 1,
  },
  contentItemIconContainerStyle: {
    maxHeight: 16,
    maxWidth: 16,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 4,
  },
  contentItemSeparatorStyle: {
    marginLeft: 4,
    marginRight: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentItemSeparatorTextStyle: { ...Headline4, color: neutral_4 },
  dotSeparatorStyle: {
    width: 3,
    height: 3,
    borderRadius: 1.5,
    backgroundColor: neutral_3,
  },
};
export const titleCardTheme = {
  style: {
    backgroundColor: vars.colors.white,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    overflow: 'hidden',
  },
  hoverStyle: {
    boxShadow:
      '0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)',
    backgroundColor: vars.colors.secondary.color4,
  },
  selectedStyle: {
    backgroundColor: primary_5,
    borderColor: primary_2,
  },
  hoverActionsWrapperStyle: {
    top: 1,
    bottom: 1,
    right: 1,
    borderRadius: 4,
    backgroundColor: surface_white,
  },
  topHoverActionsWrapperStyle: {
    top: 1,
    right: 1,
    borderRadius: 4,
    backgroundColor: surface_white,
  },
  bottomHoverActionsWrapperStyle: {
    bottom: 1,
    right: 1,
    borderRadius: 4,
    backgroundColor: surface_white,
  },
  hoverActionsContainerStyle: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    justifyContent: 'flex-end',
  },
  selectedHoverActionsWrapperStyle: {
    backgroundColor: primary_5,
  },
  primaryTitleStyle: {
    ...vars.headings.h8,
    color: vars.colors.grey.color1,
    numberOfLines: 1,
    marginBottom: 4,
  },
  secondaryTitleStyle: {
    ...vars.headings.h13,
    color: vars.colors.grey.color2,
    numberOfLines: 1,
    // marginBottom: 4,
  },
  contentItemTextStyle: {
    ...Headline12,
    color: neutral_3,
    flexShrink: 1,
    numberOfLines: 1,
  },
  contentItemIconStyle: {
    width: 16,
    height: 16,
    marginRight: 6,
  },
  rightItemTextStyle: {
    ...vars.headings.h13,
    color: vars.colors.secondary.color2,
    flexShrink: 1,
    numberOfLines: 1,
    textDecorationLine: 'underline',
  },
  avatarStyle: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: vars.colors.white,
    borderColor: vars.colors.secondary.color2,
    borderWidth: 1,
    textStyle: { ...vars.headings.h9, color: vars.colors.secondary.color2 },
  },
  selectedAvatarStyle: {
    backgroundColor: surface_white,
  },
  dotSeparatorStyle: {
    width: 5,
    height: 5,
    borderRadius: 2.5,
    backgroundColor: neutral_4,
  },
  contentContainerStyle: {
    paddingTop: 16,
    paddingBottom: 16,
    marginLeft: 8,
    marginRight: 8,
  },
};
