import React from 'react';
import {BaseToast} from './src/BaseToast';
import uuid from 'uuid/v4';

import ToastComponent from './src/components/ToastComponent';

import {standardTheme, errorTheme, successTheme} from './src/toastTheme';

const defaultComponentsConfig = {
  standard: BaseToast(standardTheme),
  error: BaseToast(errorTheme),
  success: BaseToast(successTheme),
};

let TOAST_POSITION_HEIGHT = 60;
class Toast extends React.Component {
  static _ref = null;

  static setRef(ref = {}) {
    Toast._ref = ref;
  }

  static getRef() {
    return Toast._ref;
  }

  static clearRef() {
    Toast._ref = null;
  }

  static show(info, duration, type) {
    Toast._ref.show(info, duration, type);
  }

  static error(info, duration, type) {
    Toast._ref.error(info, duration, type);
  }

  static hide() {
    Toast._ref.hide();
  }

  constructor(props) {
    super(props);
    this.state = {
      toast: [],
    };
    this.show = this.show.bind(this);
    this.error = this.error.bind(this);
  }

  startTimer = (id, duration) => {
    this.timer = this.timer || {};
    this.timer[id] = setTimeout(() => {
      clearTimeout(this.timer[id]);
      this.timer[id] = void 0;
      this.hide(id);
    }, duration);
  };
  onMouseEnter = id => {
    clearTimeout(this.timer[id]);
  };
  onMouseLeave = (id, duration) => {
    this.startTimer(id, duration);
  };

  show(info, duration, type) {
    if (!info) {
      return null;
    }
    if (typeof info === 'string') {
      info = {message: info};
    }
    if (typeof info === 'object' && !Array.isArray(info)) {
      info = [info];
    }
    let newInfo = [];
    for (let item of info) {
      if (typeof item === 'string') {
        item = {message: item};
      }
      item = {...item};
      item.type = item.type || type || 'standard';
      item.id = item.id || uuid();
      item.duration = item.duration || duration || 2000;
      newInfo.push(item);
      this.startTimer(item.id, item.duration);
    }
    let {info: prevInfo = []} = this.state;
    this.setState({
      info: [...prevInfo, ...newInfo],
    });
  }

  error(info, duration, type = 'error') {
    this.show(info, duration, type);
  }

  hide(id) {
    let {info} = this.state;
    info = info.filter(item => item.id !== id);
    this.setState({info});
  }

  hideToast = id => () => {
    this.hide(id);
  };

  getRenderComponent = ({type, ...props}) => {
    let {config = {}} = this.props;
    let Component = config[type] || defaultComponentsConfig[type];
    if (!Component) {
      //   console.error(
      //     `Type '${type}' does not exist. Make sure to add it to your 'config'. You can read the documentation here: https://github.com/calintamas/react-native-toast-message/blob/master/README.md`,
      //   );
      return null;
    }
    return Component(props);
  };

  render() {
    let {info} = this.state;
    if (!info || !info.length) {
      return null;
    }
    let height = TOAST_POSITION_HEIGHT;
    return (
      <React.Fragment>
        {info.map((item, index) => {
          let {position = 'bottom', ...restItem} = item;
          let toastPositionStyle = {};
          if (position === 'top') {
            toastPositionStyle.top = height;
          } else {
            toastPositionStyle.bottom = height;
          }
          return (
            <ToastComponent
              key={item.id}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              toastPositionStyle={toastPositionStyle}
              index={index}
              position={position}
              {...restItem}
              Component={this.getRenderComponent({
                ...item,
                hideToast: this.hideToast(item.id),
              })}
            />
          );
        })}
      </React.Fragment>
    );
  }
}
export default Toast;
