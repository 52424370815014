import React from 'react';
import moment from 'moment';
import { hospitalLiveLogo } from '../../images';
import vars from '../../theme/vars';
import { getConfiguration } from '../../AppServices';
import { getString } from '../../Lib/helpers';

const PatientInvoice = ({ billInfo, barcode }) => {
  const {
    billAmount = 0, billingDate, discount = 0, patientId: patientInfo = {}, pharmacyDetails = []
  } = billInfo || {};
  const totalPrice = ((billAmount * 100) / (100 - discount)) || 0;
  const discountedAmount = (totalPrice * (discount / 100));
  const tableRowStyle = {
    color: '#000000',
    fontWeight: 500,
    align: "left",
    fontSize: 10,
    paddingLeft: 8,
    borderRight: `0.5px solid ${vars.colors.grey.color3}`,
    borderBottom: `0.5px solid ${vars.colors.grey.color3}`,
  };
  const tableDataStyle={
    color: vars.colors.grey.color2,
    fontWeight: 400,
    fontSize: 10,
    paddingLeft: 8,
    textAlign: "left",
    borderRight: `0.5px solid ${vars.colors.grey.color3}`,
  }
  const tableColumnStyle = {
    borderRight: `0.5px solid ${vars.colors.grey.color3}`,
    borderBottom: `0.5px solid ${vars.colors.grey.color3}`,
  };
  const tableRightBorderStyle = {
    borderRight: `0.5px solid ${vars.colors.grey.color3}`,
  };
  const tableBottomBorderStyle = {
    borderBottom: `0.5px solid ${vars.colors.grey.color3}`,
  };
  const configData = getConfiguration();
  
  return (
    <div style={{
      paddingLeft: 17,
      paddingRight: 17,
      paddingTop: 22,
      width: 595,
      height: 822,
    }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            display: 'flex',
          }}
        >
          <img
            src={hospitalLiveLogo}
            alt="logo"
            style={{ height: 44 }}
          />
        </div>
        <div style={{
          flexDirection: 'column', justifyContent: 'center', display: 'flex', marginLeft: 115,
        }}
        >
          <div style={{ ...vars.headings.h18, fontWeight: 400 }}>
            {`Phone: ${configData?.contactInfo}`}
          </div>
          <div style={{ ...vars.headings.h18, fontWeight: 400, paddingTop: 4 }}>
            {`Website: ${configData?.website}`}
          </div>
          <div style={{ ...vars.headings.h18, fontWeight: 400, paddingTop: 4 }}>
            {`Email: ${configData?.email}`}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <table style={{
          border: '1px solid black',
          marginTop: 21,
          width: '100%',
          borderCollapse: 'separate',
          borderSpacing: '0 4px',
        }}
        >
          <tr>
            <td rowSpan="5" style={{ textAlign: 'center' }}>
              <img
                src={barcode}
                width={57}
                alt="barcode"
              />
            </td>
            <td
              style={{
                color: vars.colors.grey.color3,
                fontSize: '9px',
                paddingRight: '8px',
              }}
            >
              {`${'billing.labels.patientName'.getLabel()}: `}
            </td>
            <td style={{
              color: vars.colors.grey.color1,
              fontSize: '9px',
            }}
            >
              {patientInfo?.patientName}
            </td>
            <td style={{
              color: vars.colors.grey.color3,
              fontSize: '9px',
              paddingRight: '8px',
              paddingLeft: 28,
            }}
            >
              {`${'billing.labels.date'.getLabel()}: `}
            </td>
            <td style={{
              color: vars.colors.grey.color1,
              fontSize: '9px',
            }}
            >
              { moment(billingDate).format('DD/MM/YY')}
            </td>
          </tr>
          <tr>
            <td style={{
              color: vars.colors.grey.color3,
              fontSize: '9px',
              paddingRight: '8px',
            }}
            >
              {`${patientInfo?.relation}:`}
            </td>
            <td style={{
              color: vars.colors.grey.color1,
              fontSize: '9px',
            }}
            >
              {patientInfo?.relationName}
            </td>
            <td style={{
              color: vars.colors.grey.color3,
              fontSize: '9px',
              paddingRight: '8px',
              paddingLeft: 28,
            }}
            >
              {`${'billing.labels.doctorName'.getLabel()}: `}
            </td>
            <td style={{
              color: vars.colors.grey.color1,
              fontSize: '9px',
            }}
            >
              {getString([
                patientInfo?.lastConsultation?.sessionQueue?.doctor?.firstName,
                patientInfo?.lastConsultation?.sessionQueue?.doctor?.lastName,
              ])}
            </td>
          </tr>
          <tr>
            <td style={{
              color: vars.colors.grey.color3,
              fontSize: '9px',
              paddingRight: '8px',
            }}
            >
              {`${'billing.labels.gender'.getLabel()}: `}
            </td>
            <td style={{
              color: vars.colors.grey.color1,
              fontSize: '9px',
            }}
            >
              {patientInfo?.gender?.charAt(0).toUpperCase() + patientInfo?.gender?.slice(1)}
            </td>
            <td style={{
              color: vars.colors.grey.color3,
              fontSize: '9px',
              paddingRight: '8px',
              paddingLeft: 28,
            }}
            >
              {`${'billing.labels.speciality'.getLabel()}: `}
            </td>
            <td style={{
              color: vars.colors.grey.color1,
              fontSize: '9px',
            }}
            >
              {patientInfo?.lastConsultation?.speciality?.name}
            </td>
          </tr>
          <tr>
            <td style={{
              color: vars.colors.grey.color3,
              fontSize: '9px',
              paddingRight: '8px',
            }}
            >
              {`${'billing.labels.age'.getLabel()}: `}
            </td>
            <td style={{
              color: vars.colors.grey.color1,
              fontSize: '9px',
            }}
            >
              {`${patientInfo?.age || ''} ${patientInfo?.ageType || ''}`}
            </td>
            <td style={{
              color: vars.colors.grey.color3,
              fontSize: '9px',
              paddingRight: '8px',
              paddingLeft: 28,
            }}
            >
              {`${'billing.labels.department'.getLabel()}: `}
            </td>
            <td style={{
              color: vars.colors.grey.color1,
              fontSize: '9px',
            }}
            >
              { patientInfo?.lastConsultation?.department?.name}
            </td>
          </tr>
          <tr>
            <td style={{
              color: vars.colors.grey.color3,
              fontSize: '9px',
              paddingRight: '8px',
            }}
            >
              {`${'billing.labels.mobile'.getLabel()}: `}
            </td>
            <td style={{
              color: vars.colors.grey.color1,
              fontSize: '9px',
            }}
            >
              {patientInfo?.mobile}
            </td>
            {/* <td style={{
              color: vars.colors.grey.color3,
              fontSize: '9px',
              paddingRight: '8px',
              paddingLeft: 28,
            }}
            >
              {`${'billing.labels.patientType'.getLabel()}: `}
            </td>
            <td style={{
              color: vars.colors.grey.color1,
              fontSize: '9px',
            }}
            >
              {patientInfo?.patientType}
            </td> */}
          </tr>
        </table>
      </div>
      <div style={{
        marginTop: 15, ...vars.headings.h12, color: '#000000', textAlign: 'center',
      }}
      >
        Invoice
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <table style={{
          border: `0.5px solid ${vars.colors.grey.color3}`,
          marginTop: 12,
          width: '100%',
        }}
        >
          <tr>
            <th width="10%" style={tableRowStyle}>
              S.No.
            </th>
            <th width="30%" style={tableRowStyle}>
              {'billing.labels.services'.getLabel()}
            </th>
            <th width="15%" style={tableRowStyle}>
              {'billing.labels.qty'.getLabel()}
            </th>
            <th width="20%" style={tableRowStyle}>
              {'billing.labels.unitCharges'.getLabel()}
            </th>
            <th width="25%" style={{ ...tableRowStyle, borderRight: '' }}>
              {'billing.labels.amount'.getLabel()}
            </th>
          </tr>
          { pharmacyDetails?.map(({
            item, quantity,  totalPrice: totalUnitsPrice,
          }, index) => (
            <tr key={index}>
              <td style={tableDataStyle}>{index + 1}</td>
              <td style={tableDataStyle}>{item?.name}</td>
              <td style={tableDataStyle}>{quantity}</td>
              <td style={{
                ...tableDataStyle,
                // textAlign: 'right',
                paddingRight: 6,
              }}
              >
                {item?.sellingPrice}
              </td>
              <td style={{
                ...tableDataStyle,
                // textAlign: 'right',
                paddingRight: 6,
                borderRight:''
              }}
              >
              {`${totalUnitsPrice} INR`}
              </td>
            </tr>
          ))}
          <tr style={{
            borderTop:`0.5px solid ${vars.colors.grey.color3}`,
            borderBottom:`0.5px solid ${vars.colors.grey.color3}`
            }}>
            <td style={tableRightBorderStyle} />
            <td
              style={{
                color: '#000000',
                fontSize: 10,
                paddingLeft: 8,
                ...tableRightBorderStyle
              }}
            >
              {'billing.labels.total'.getLabel()}
            </td>
            <td style={tableRightBorderStyle} />
            <td style={tableRightBorderStyle} />
            <td style={{ 
              color: '#000000',
              fontSize: 10,
              textAlign: 'left',
              paddingLeft: 8,
              }}>
              {`${totalPrice} INR`}
            </td>
          </tr>
          <tr>
            <td style={tableColumnStyle} />
            <td
              style={{
                color: '#000000',
                fontSize: 10,
                paddingLeft: 8,
                ...tableColumnStyle,
              }}
            >
              {'billing.labels.discount'.getLabel()}
            </td>
            <td style={tableColumnStyle} />
            <td style={{ 
              ...tableColumnStyle,              
              color: '#000000',
              fontSize: 10,              
              textAlign: 'left', 
              paddingLeft: 8
               }}
               >
              {`${discount}%`}
            </td>
            <td style={{ 
              color: '#000000',
              fontSize: 10,
              textAlign: 'left',
              paddingLeft: 8,
              ...tableBottomBorderStyle,
               }}
               >
              {`${discountedAmount} INR`}
            </td>
          </tr>
          <tr>
            <td style={tableRightBorderStyle} />
            <td
              style={{
                color: '#000000',
                fontSize: 10,
                fontWeight:'bold',
                paddingLeft: 8,
                ...tableRightBorderStyle,
              }}
            >
              {'billing.labels.grandTotal'.getLabel()}
            </td>
            <td style={tableRightBorderStyle} />
            <td style={tableRightBorderStyle} />
            <td style={{
              color: '#000000',
              fontSize: 10,
              fontWeight:'bold',
              textAlign: 'left',
              paddingLeft: 8,
            }}
            >
              {`${billAmount} INR`}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};
export default PatientInvoice;
