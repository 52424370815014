import React from 'react';
import {ScrollView, View} from '@applane/react-core-components';
import {getResolvedGroups} from './Utility';

export const HorizontalFormGroups = props => {
  let {
    horizontalFormGroups,
    renderFormGroups,
    scrollable,
    horizontalSeparatorStyle,
    ...restProps
  } = props;
  let horizontalFormGroupsComponent = horizontalFormGroups.map(
    formGroupInfo => {
      let {
        flex = 1,
        separatorStyle = horizontalSeparatorStyle,
        separator,
        formGroups,
      } = formGroupInfo;
      if (formGroups) {
        formGroups = getResolvedGroups(formGroups, restProps);
      }
      let formGroupComponent = renderFormGroups({
        formGroups,
      });
      if (scrollable === false) {
        formGroupComponent = (
          <ScrollView style={{flex: 1}}>{formGroupComponent}</ScrollView>
        );
      }
      return (
        <>
          <View style={{flex, overflow: 'hidden'}}>{formGroupComponent}</View>
          {separator && separatorStyle ? (
            <View style={separatorStyle} />
          ) : (
            void 0
          )}
        </>
      );
    },
  );
  let formGroupFlexStyle = {};
  if (scrollable === false) {
    formGroupFlexStyle = {flex: 1, overflow: 'hidden'};
  }
  return (
    <View style={{flexDirection: 'row', ...formGroupFlexStyle}}>
      {horizontalFormGroupsComponent}
    </View>
  );
};
