import React from 'react';
import {
  View,
  BasicTextInput,
  TouchableOpacity,
  Image,
} from '@applane/react-core-components';
import {
  WithFloatingLabel,
  WithInputWrapper,
  WithVariant,
  WithInputError,
  getInputProps,
} from '@applane/react-input-wrapper';

class PasswordInputEditor extends React.Component {
  render() {
    let {type, ...restProps} = this.props;
    let inputProps = getInputProps(restProps);
    return (
      <BasicTextInput
        {...inputProps}
        type={type}
        secureTextEntry={type === 'text' ? false : true}
      />
    );
  }
}

PasswordInputEditor = WithInputError(WithFloatingLabel(PasswordInputEditor));

class PasswordInput extends React.Component {
  state = {type: 'password'};
  onChangeValue = (value, e) => {
    let {onChangeText, onChangeValue} = this.props;
    onChangeText && onChangeText(value);
    onChangeValue && onChangeValue(value, e);
  };

  togglePassword = () => {
    let {type} = this.state;
    if (type === 'password') {
      this.setState({type: 'text'});
    } else {
      this.setState({type: 'password'});
    }
  };
  render() {
    let {showIcon, hideIcon} = this.props;
    let {type} = this.state;
    return (
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <PasswordInputEditor {...this.props} type={type} />
        </View>
        {/* <TouchableOpacity
            onPress={this.togglePassword}
            style={{alignItems: 'center', justifyContent: 'center', cursor:"pointer"}}>
            <Image
              source={type === 'text' ? hideIcon : showIcon}
              style={{height: 20, width: 20}}
            />
          </TouchableOpacity> */}
      </View>
    );
  }
}

PasswordInput = WithInputWrapper(WithVariant(PasswordInput));

export default PasswordInput;
