import React from 'react';
import {View, Text} from '@applane/react-core-components';
import {getRenderComponent} from './Utility';
class LeftLabel extends React.Component {
  render() {
    let {
      label,
      labelContainerStyle,
      labelStyle,
      mandatory,
      mandatoryStyle,
      renderMandatory,
      children,
    } = this.props;
    if (label === false) {
      return children;
    }
    return (
      <View style={{flexDirection: 'row'}}>
        <View style={{flexDirection: 'row', ...labelContainerStyle}}>
          {label ? <Text style={labelStyle}>{label}</Text> : void 0}
          {label && mandatory
            ? getRenderComponent(renderMandatory) || (
                <Text
                  style={{
                    ...labelStyle,
                    ...mandatoryStyle,
                    numberOfLines: void 0,
                  }}>
                  *
                </Text>
              )
            : void 0}
        </View>
        <View style={{flex: 1, overflow: 'hidden', justifyContent: 'center'}}>
          {React.cloneElement(children, {
            label: false,
            mandatory: false,
          })}
        </View>
      </View>
    );
  }
}

LeftLabel.defaultProps = {
  labelContainerStyle: {
    height: 44,
    width: 100,
    alignItems: 'center',
    paddingRight: 6,
  },
  labelStyle: {
    fontSize: 14,
    numberOfLines: 1,
  },
};

export default LeftLabel;
