import React from 'react';
import {Stack} from '@applane/react-stack-navigator-v1';
import {Platform, View} from '@applane/react-core-components';
import {setLocationView} from './Location';
import {stackTheme, stackMobileTheme} from '../../theme/stackTheme';
import {getActiveMQ, getResolvedMQProps} from '../BreakPoints';
import {
  stackHeaderTheme,
  stackModalHeaderTheme,
  stackFooterTheme,
  stackModalFooterTheme,
  stackModalHeaderMobileTheme,
  stackHeaderMobileTheme,
} from '../../theme/toolBarTheme';
import {Snackbar} from '@applane/react-snack-bar';
import {isMobile} from '../UtilityFunctions';
import ToolBar from '../toolBar/ToolBar';

let theme = isMobile ? stackMobileTheme : stackTheme;

const showBackPressMessage = () => {
  Snackbar.show({
    text: 'Double Press to Exit!',
    duration: Snackbar.LENGTH_SHORT,
  });
};

export default (views, stackOptions) => {
  class StackNavigator extends React.Component {
    onRouteChange = ({views}) => {
      if (views && views.length) {
        let view = views[0];
        const {navigationKey} = stackOptions || {};
        if (view) {
          const {
            routeName,
            navigation: {action},
          } = view;
          if (Platform.OS === 'web' && navigationKey === 'main') {
            setLocationView({view: routeName, action});
          }
        }
      }
    };
    renderHeader = props => {
      const {modal, header, ...rest} = props;
      if (header) {
        return (
          <ToolBar
            {...(modal
              ? (isMobile && stackModalHeaderMobileTheme) ||
                stackModalHeaderTheme
              : (isMobile && stackHeaderMobileTheme) || stackHeaderTheme)}
            {...header}
            {...rest}
          />
        );
      } else {
        return null;
      }
    };
    renderFooter = props => {
      const {modal, footer, ...rest} = props;
      if (footer) {
        return (
          <ToolBar
            {...(modal ? stackModalFooterTheme : stackFooterTheme)}
            {...rest}
            {...footer}
          />
        );
      } else {
        return null;
      }
    };

    render() {
      return (
        <Stack
          getActiveMQ={getActiveMQ}
          getResolvedMQProps={getResolvedMQProps}
          {...theme}
          {...stackOptions}
          {...this.props}
          routes={views}
          onRouteChange={this.onRouteChange}
          renderHeader={this.renderHeader}
          renderFooter={this.renderFooter}
          showBackPressMessage={showBackPressMessage}
        />
      );
    }
  }
  return StackNavigator;
};
