import React from 'react';
import {View} from '@applane/react-core-components';
import StackItem from './StackItem';

class AnimatedStackItem extends React.Component {
  state = {value: 100};
  componentDidMount() {
    this.setState({value: 0});
  }
  render() {
    let {view, theme = {}, index, screenState, navigation} = this.props;
    let {viewStyle, animationType, singleViewStyle = {}, sideViewStyle = {}} =
      view.theme || theme;

    let {expanded} = screenState || {};
    const visibleViews = navigation?.getVisibleViews && navigation.getVisibleViews()
    let isSingleViewVisible =
      index == 0 || !visibleViews || visibleViews === 1 || expanded
        ? true
        : false;
    let animStyle = {};


    // to animate only transform property of view
    if (index > 0 && (!visibleViews || visibleViews=== 1)) {
      if (animationType === 'left') {
        animStyle = {
          transform: `translateX(-${this.state.value}%)`,
          transition: 'transform 0.5s',
        };
      } else if (animationType === 'right') {
        animStyle = {
          transform: `translateX(+${this.state.value}%)`,
          transition: 'transform 0.5s',
        };
      } else if (animationType === 'bottom') {
        animStyle = {
          transform: `translateY(+${this.state.value}%)`,
          transition: 'transform 0.5s',
        };
      } else if (animationType === 'top') {
        animStyle = {
          transform: `translateY(-${this.state.value}%)`,
          transition: 'transform 0.5s',
        };
      }
    }

    return (
      <View
        style={{
          ...viewStyle,
          ...(isSingleViewVisible ? singleViewStyle : sideViewStyle),
          // to animate only in mobile
          ...animStyle,
        }}>
        <StackItem {...this.props} />
      </View>
    );
  }
}

export default AnimatedStackItem;
