import React from 'react';
import {View} from '@applane/react-core-components';
export default Component => {
  class Hover extends React.Component {
    state = {};

    onMouseEnter = e => {
      this.setState({
        hover: true,
      });
      this.props.onMouseEnter && this.props.onMouseEnter(e);
    };

    onMouseLeave = e => {
      this.setState({
        hover: false,
      });
      this.props.onMouseLeave && this.props.onMouseLeave(e);
    };

    render() {
      let {onMouseEnter, onMouseLeave, ...restProps} = this.props;
      let {hover} = this.state;
      return (
        <View onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <Component {...restProps} isHover={hover} />
        </View>
      );
    }
  }
  return Hover;
};
