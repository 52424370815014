export default {
  labels: {
    purchaseDepartmentTCName: 'T&C Name',
    description: 'Description',
    totalConditions: 'Total Terms & Conditions',
    status: 'Status',
    activityLog: 'Activity Log',
    SNO: 'S.NO.',
    name: 'Name',
  },
  title: {
    purchaseDepartmentTCManagement: 'Terms and Conditions Management',
    addPurchaseDepartmentTC: 'Add T&C',
    editPurchaseDepartmentTC: 'Edit T&C',
    exportToExcel: 'Export To Excel',
  },
  messages: {
    purchaseDepartmentTCAddedSuccessfully: 'T&C has been added successfully.',
    purchaseDepartmentTCUpdatedSuccessfully: 'T&C has been updated successfully.',
  },
};

