import React from 'react';
import {View} from '@applane/react-core-components';
import {getResolvedStyle} from './Utility';

export default class Outlined extends React.Component {
  render() {
    let {
      className,
      style,
      hoverStyle,
      activeStyle,
      errorStyle,
      disabledStyle,
      Component,
      ...restProps
    } = this.props;
    let {isActive, hover, disabled, error} = restProps;
    style = getResolvedStyle({
      isActive,
      hover,
      disabled,
      error,
      style,
      hoverStyle,
      activeStyle,
      errorStyle,
      disabledStyle,
    });
    return (
      <View className={className} style={style}>
        <Component {...restProps} />
      </View>
    );
  }
}

Outlined.defaultProps = {
  style: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#fbfbfb',
    borderRadius: 3,
  },
  hoverStyle: {
    backgroundColor: '#f6f6f6',
  },
  activeStyle: {
    borderColor: '#6d91f0',
  },
  errorStyle: {
    borderColor: 'red',
  },
};
