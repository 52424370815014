import { Headline11 } from './Headlines';
import { manazeV6Colors } from './manazeV6Colors';
import vars from './vars';

const { neutral_4 } = manazeV6Colors;

export const leftIconStyle = {};

export const leftLabelStyle = {
  labelContainerStyle: {
    height: 38,
    minWidth: 55,
    alignItems: 'center',
    paddingRight: 16,
  },
  labelStyle: {
    ...Headline11,
    color: neutral_4,
    numberOfLines: 1,
  },
};

export const topLabelStyle = {
  labelContainerStyle: {
    height: 14,
  },
  labelStyle: {
    ...vars.headings.h17,
    color: vars.colors.grey.color3,
    numberOfLines: 1,
  },
};
