import React from 'react';
import {View} from '@applane/react-core-components';
import {getRenderComponent, matchValue, uploadData} from './fileUtility';
import FileValueRender from './FileValueRender';
import FileInputRender from './FileInputRender';
import {WithInputWrapper} from '@applane/react-input-wrapper';

class FileInput extends React.Component {
  state = {};
  componentDidMount() {
    window.addEventListener('dragover', this._fileDrag, false);
    window.addEventListener('drop', this._fileDrag, false);
  }

  componentWillUnmount() {
    window.removeEventListener('dragover', this._fileDrag, false);
    window.removeEventListener('drop', this._fileDrag, false);
  }

  _fileDrag = e => {
    e.preventDefault();
  };

  onFocus = e => {
    let {onFocus} = this.props;
    onFocus && onFocus(e);
  };

  onBlur = e => {
    let {onBlur} = this.props;
    setTimeout(_ => {
      onBlur && onBlur(e);
    });
  };

  setValue = ({value, e}) => {
    let {onChangeValue} = this.props;
    onChangeValue && onChangeValue(value, e);
    this.onBlur(e);
  };

  uploadFile = async (fileValues, e) => {
    let {multiple} = this.props;
    this.setState({uploading: true});
    try {
      let {result} = await uploadData(
        multiple ? fileValues : fileValues[0],
        this.props,
      );
      if (result) {
        this.setValue({value: result, e});
      }
    } catch (err) {
      console.warn('!!!!! Error in file upload >>>>>', err);
    }
    this.setState({uploading: false});
  };

  onChangeValue = e => {
    e.preventDefault();
    let files = e.dataTransfer
      ? e.dataTransfer.files
      : e.target && e.target.files;
    let fileValues = files ? Object.values(files) : [];
    if (!fileValues.length) {
      return;
    }
    this.uploadFile(fileValues);
  };

  onClick = e => {
    this.inputRef.click();
    this.onFocus(e);
  };

  removeItem = ({item}) => {
    let {idField = 'key', multiple, value} = this.props;
    if (!value) {
      return;
    }
    if (multiple && Array.isArray(value)) {
      if (item) {
        let newValue = value.filter(doc => {
          return !matchValue(doc, item, idField);
        });
        this.setValue({value: newValue});
      }
    } else {
      this.setValue({value: null});
    }
  };

  getRef = ref => {
    this.inputRef = ref;
    this.props.getRef && this.props.getRef(ref);
  };

  render() {
    let {
      value,
      multiple,
      imageOnly,
      editable,
      hideLink,
      renderLink,
      disabled,
    } = this.props;
    let defaultProps = {type: 'file'};
    if (imageOnly) {
      defaultProps = {...defaultProps, accept: 'image/*'};
    }
    if (multiple) {
      defaultProps = {...defaultProps, multiple};
    }
    return (
      <React.Fragment>
        {editable !== false && (
          <View
            draggable={true}
            onDragOver={e => {
              this.onFocus(e);
              this.setState({dragOver: true});
            }}
            onDrop={e => {
              this.onChangeValue(e);
              this.setState({dragOver: false});
            }}
            onDragLeave={e => {
              this.setState({dragOver: false});
            }}
            onClick={this.onClick}>
            <View>
              <FileInputRender
                {...this.props}
                uploading={this.state.uploading}
              />
              <input
                {...defaultProps}
                style={{display: 'none'}}
                ref={this.getRef}
                disabled={disabled}
                onChange={this.onChangeValue}
              />
            </View>
          </View>
        )}
        {!hideLink && value
          ? getRenderComponent(renderLink || FileValueRender, {
              ...this.props,
              onRemove: this.removeItem,
            })
          : null}
      </React.Fragment>
    );
  }
}

export default WithInputWrapper(FileInput);
