import React, { useState, useEffect } from 'react';
import moment from 'moment';

import {
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  ActivityIndicator,
  Toast,
} from '../../../../app-components';
import {
  h12_Regular,
  h14_AvenirNext,
  h14_Medium,
  h14_Regular,
  h15_Regular,
} from '../../../../theme/fonts';
import { invoke, fetch } from '../../../../AppServices';
import { GradientButton } from '../../../../app-components/buttons/Buttons';
import { colors } from '../../../../theme/mayaMdColors';
import DateRange from './DateRange';
import { ROUTES } from '../../../../Lib/constants';

const {
  themeContainer, themeTextColor, themeColor,
} = colors;

const Booking = ({ navigation, eventDispatcher }) => {
  const {
    state: { params: { item, isReschedule = false, res = {} } = {} } = {},
  } = navigation;

  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(null);
  const [selectdDate, setSelectedDate] = useState(null);
  const [onPress, setOnPress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [switchToNextWeek, setSwitchToNextWeek] = useState(0);

  const { openBooking = false } = navigation?.state?.params;

  const checkSelectedDate = (time, selectedTime) => {
    const today = new Date(selectedTime);
    if (
      time.getDay() === today.getDay()
      && time.getDate() === today.getDate()
      && time.getMonth() === today.getMonth()
      && today.getFullYear() === time.getFullYear()
    ) {
      return true;
    }
    return false;
  };

  const checkToday = (time) => {
    const today = new Date();
    if (
      time.getDay() === today.getDay()
      && time.getDate() === today.getDate()
      && time.getMonth() === today.getMonth()
      && today.getFullYear() === time.getFullYear()
    ) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    eventDispatcher.listen('ScreenPop', () => {
      setCount(count + 1);
    });
    return () => {
      eventDispatcher.unlisten('ScreenPop');
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { _id } = item;
        const query = {
          doctor: _id,
          period: {
            from: moment(new Date(selectdDate)).startOf('day').toDate(),
            to: moment(new Date(selectdDate)).endOf('day').toDate(),
          },
          status: 'Available',
        };
        let result = void 0;
        result = await invoke({
          paramValue: { query },
          id: 'getDoctorSlots',
        });
        const { data = [] } = result || {};
        if (Array.isArray(data) && data.length) {
          const timeSlot = [];
          if (checkToday(selectdDate)) {
            for (let i = 0; i < data.length; i += 1) {
              if (availableSlot(data[i].start_time)) {
                timeSlot.push(data[i]);
              }
            }
            setTimeSlots(timeSlot);
            setSelectedTimeSlotIndex(0);
            setSelectedTimeSlot(timeSlot[0]);
          } else {
            setTimeSlots(data);
            setSelectedTimeSlotIndex(0);
            setSelectedTimeSlot(data[0]);
            // timeSlot = [...data];
          }
        } else {
          const { _id } = item;
          if (checkToday(selectdDate)) {
            const result = await fetch({
              uri: {
                props: {
                  query: {
                    id: 'nextAvailableSlot',
                    addOnFilter: {
                      doctor: { _id },
                      // status: 'Available',
                    },
                    limit: 1,
                    paramValue: {
                      period: {
                        from: moment().startOf('day').toDate(),
                      },
                    },
                  },
                  model: 'DoctorSlots',
                },
              },
            });
            const { data = {} } = result;
            if (
              selectdDate
              && data?.date
              && !checkSelectedDate(new Date(selectdDate), new Date(data?.date))
            ) {
              const weekDiffrence = moment(data?.date).isoWeek() - moment().isoWeek();
              console.log('~ file: Booking.js ~ line 142 ~ weekDiffrence', weekDiffrence);
              if (weekDiffrence > 0) {
                setSwitchToNextWeek(weekDiffrence);
              }
              setSelectedDate(moment(data?.date).toDate());
            } else {
              setTimeSlots([]);
              setSelectedTimeSlotIndex(null);
              setSelectedTimeSlot(null);
            }
          } else {
            setTimeSlots([]);
            setSelectedTimeSlotIndex(null);
            setSelectedTimeSlot(null);
          }
        }
      } catch (e) {
        console.log('Error in Fetching BookingSlots', e);
      } finally {
        setLoading(false);
      }
    })();
  }, [selectdDate, item, count]);

  const availableSlot = (date) => {
    const today = new Date();
    const slotGiven = new Date(date);
    const year = slotGiven.getFullYear() - today.getFullYear();
    const month = slotGiven.getMonth() - today.getMonth();
    const day = slotGiven.getDate() - today.getDate();
    const hrs = slotGiven.getHours() - today.getHours();
    const min = slotGiven.getMinutes() - today.getMinutes();
    if (
      year > 0
      || (year === 0 && month > 0)
      || (month === 0 && day > 0)
      || (day === 0 && hrs > 0)
      || (hrs === 0 && min > 0)
    ) {
      return true;
    }
    return false;
  };

  // useTranslation(); // T function
  return (
    <View
      style={{
        padding: 10,
        borderColor: 'rgba(56, 56, 56, 0.37)',
        flex: 1,
        justifyContent: 'space-between',
        overflow: 'hidden',
        height: '80vh',
      }}
    >
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          backgroundColor: themeContainer,
          padding: 10,
          paddingBottom: 0,
          borderRadius: 10,
          marginBottom: 10,
          // ...doctorProfileContainerShadow,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            if (isReschedule) {
              navigation.reset('patient-waiting-room');
            } else {
              openBooking ? navigation.pop(2) : navigation.pop();
            }
          }}
          style={{
            cursor: 'pointer',
            alignItems: 'flex-start',
            marginTop: 5,
            marginBottom: 20,
          }}
        >
          <Text
            style={{
              ...h14_AvenirNext,
              lineHeight: 21,
              letterSpacing: 0.5,
              color: themeTextColor,
              // opacity: 0.54,
            }}
          >
            {'< '}
            {' '}
            {'Back'}
          </Text>
        </TouchableOpacity>
        <View>
          <Text
            style={{ ...h15_Regular, color: themeTextColor, fontWeight: 600 }}
          >
            Book an Appointment
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: 15,
            paddingBottom: 15,
            // marginTop: 8,
            paddingRight: 24,
          }}
        >
          <Text style={{ ...h14_Regular, color: themeTextColor, opacity: 0.87 }}>
            Select Appointment Date
          </Text>
          {/* <View
            style={{
              borderRadius: 8,
              borderWidth: 1,
              borderColor: '#e7eef5',
              flexDirection: 'row',
              paddingLeft: 10,
              paddingTop: 5,
              paddingRight: 10,
              paddingBottom: 5,
            }}>
            <Text style={{...h12_Regular, color: '#222222'}}>24 Dec, 20</Text>
            <View
              style={{
                width: 1,
                backgroundColor: 'rgb(46,110,205, 0.1)',
                marginRight: 8,
                marginLeft: 8,
              }}
            />
            <Image source={calender} style={{width: 14, height: 14}} />
          </View> */}
        </View>
        <DateRange
          setSelectedDate={setSelectedDate}
          checkToday={checkToday}
          key={switchToNextWeek}
          selectdDate={selectdDate}
          checkSelectedDate={checkSelectedDate}
          switchToNextWeek={switchToNextWeek}
        />
        <View
          style={{
            borderWidth: 1,
            borderColor: 'rgb(151,151,151, 0.2)',
            marginTop: 10,
            marginRight: 16,
          }}
        />
        {!loading ? (
          <>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 15,
                paddingBottom: 10,
              }}
            >
              <Text
                style={{
                  ...h14_Regular,
                  fontWeight: 600,
                  color: themeTextColor,
                }}
              >
                Showing Available Slots for selected day
              </Text>
            </View>
            <ScrollView
              style={{
                height: '42vh',
              }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  paddingBottom: 25,
                }}
              >
                {timeSlots.length ? (
                  timeSlots.map((item, index) => (
                    <TouchableOpacity
                      key={index}
                      style={{
                        backgroundColor:
                          selectedTimeSlotIndex !== index ? '#fff' : themeColor,
                        width: 90,
                        justifyContent: 'center',
                        alignItems: 'center',
                        border:
                          selectedTimeSlotIndex !== index
                            ? '1px solid rgba(56, 56, 56, 0.11)'
                            : '',
                        height: 35,
                        borderRadius: 20,
                        margin: 10,
                        cursor: 'pointer',
                      }}
                      onPress={() => {
                        setOnPress(false);
                        setSelectedTimeSlotIndex(index);
                        setSelectedTimeSlot(item);
                      }}
                    >
                      <Text
                        style={{
                          color:
                            selectedTimeSlotIndex !== index
                              ? themeColor
                              : themeTextColor,
                          fontWeight: 600,
                          ...h14_Medium,
                        }}
                      >
                        {moment(item?.start_time).format('hh:mm A')}
                      </Text>
                    </TouchableOpacity>
                  ))
                ) : (
                  <Text
                    style={{
                      ...h14_Regular,
                      color: '#fff',
                    }}
                  >
                    No Slot available for the selected day.
                  </Text>
                )}
              </View>
            </ScrollView>
          </>
        ) : (
          <View
            style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <ActivityIndicator size="small" />
          </View>
        )}
      </View>
      <TouchableOpacity
        style={{
          cursor: 'pointer',
        }}
        onPress={() => {
          if (availableSlot(selectedTimeSlot?.start_time)) {
            setOnPress(false);
            if (isReschedule) {
              setUpdateLoading(true);
              invoke({
                id: 'updateTimeSlot',
                paramValue: {
                  data: {
                    appointment: { _id: res?._id },
                    time_slot: { _id: selectedTimeSlot?._id },
                  },
                },
              })
                .then((res) => {
                  setUpdateLoading(false);
                  Toast.show({
                    message: 'Success',
                    description: 'Appointment has been successfully rescheduled',
                    type: 'success',
                    position: 'top',
                    direction: 'right',
                    duration: 3000,
                  });
                  navigation.reset('patient-waiting-room');
                })
                .catch((err) => {
                  console.log('>>>>errr', err);
                  setUpdateLoading(false);
                });
            } else {
              setUpdateLoading(true);
              invoke({
                id: 'addConsultationByPatient',
                paramValue: {
                  data: {
                    appointment: { _id: res?._id },
                    time_slot: { _id: selectedTimeSlot?._id },
                    doctor_id: { _id: item._id },
                    type: 'videoAppointment',
                    department: { code: 'OPD' },
                  },
                },
              })
                .then((res) => {
                  setUpdateLoading(false);
                  Toast.show({
                    message: 'Success',
                    description: 'Appointment has been successfully booked',
                    type: 'success',
                    position: 'top',
                    direction: 'right',
                    duration: 3000,
                  });
                  navigation.reset(ROUTES.doctorSearch.name);
                })
                .catch((err) => {
                  console.log('>>>>errr', err);
                  setUpdateLoading(false);
                });
            }
          } else {
            setOnPress(true);
          }
        }}
      >
        <GradientButton
          label={
            isReschedule ? 'Update Appointment' : 'Confirm Appointment'
          }
          buttonStyle={{ flex: 1 }}
          loading={updateLoading}
        />
      </TouchableOpacity>
      {onPress
      && !availableSlot(selectedTimeSlot?.start_time)
      && selectedTimeSlot != null ? (
        <Text style={{ ...h12_Regular, color: 'red' }}>
          {/* Selected slot has passed, Please select another slot. */}
          {'slotPassed'}
        </Text>
        ) : null}
      {onPress && selectedTimeSlot === null ? (
        <Text style={{ ...h12_Regular, color: 'red' }}>
          {/* Please select a slot. */}
          {'selectSlot'}
        </Text>
      ) : null}
    </View>
  );
};

export default Booking;
