import React from 'react';
import JsBarcode from 'jsbarcode';
import ReactDOMServer from 'react-dom/server';
import { printPdf } from '../../Lib/pdfUtil';
import Card from './Card';

const printOPDCard = (patientInfo, cb) => {
  const tag = document.getElementById('printable-area');
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, patientInfo.UHID, {
    format: 'CODE128', height: 55, fontSize: 8, textMargin: 1, margin: 0,
  });
  const image = canvas.toDataURL('image/png');
  const test = ReactDOMServer.renderToString(<Card patientInfo={patientInfo} barcode={image} />);
  tag.innerHTML = test;
  const size = ['l', 'in', [2, 3.5]];
  printPdf({
    tag,
    cb,
    size,
    format: [214, 134],
    orientation: 'l',
    unit: 'px',
  });
};

export default printOPDCard;
