import React from 'react';
import Toast from '@applane/react-toast';
import { View, Text, Image } from '@applane/react-core-components';
import { Headline5, Headline9 } from '../../theme/Headlines';
import { manazeV6Colors } from '../../theme/manazeV6Colors';
import { Action } from '../action/Action';
import {
  crossIcon,
  crossIconPng,
  notificationError, notificationTick, notificationWarning,
} from '../../images';
import vars from '../../theme/vars';

const {
  neutral_1,
  surface_white,
} = manazeV6Colors;

const standardTheme = {
  containerStyle: {
    boxShadow: '4px 4px 8px rgba(202, 202, 202, 0.5)',
    flexDirection: 'row',
    borderRadius: 5,
    padding: '12px 23px',
    backgroundColor: surface_white,
  },
  leftIconStyle: {
    width: 20,
    height: 20,
    marginLeft: 2,
    marginRight: 23,
  },
  messageStyle: {
    ...vars.headings.h8,
    color: vars.colors.grey.color1,
  },
  descriptionStyle: {
    ...vars.headings.h13,
    color: vars.colors.grey.color1,
    paddingTop: 4,
  },
  actionIconStyle: {
    marginLeft: 5,
    marginRight: 5,
    width: 12,
    height: 12,
  },
  actionTextStyle: {
    marginLeft: 5,
    marginRight: 5,
    ...Headline5,
    color: neutral_1,
  },
};

const successTheme = {
  ...standardTheme,
  actionTextStyle: {
    marginLeft: 12,
    marginRight: 5,
    ...Headline9,
    color: neutral_1,
  },
};
const errorTheme = {
  ...standardTheme,
  actionTextStyle: {
    marginLeft: 5,
    marginRight: 5,
    ...Headline5,
    color: neutral_1,
  },
};

const warningTheme = {
  ...standardTheme,
  actionTextStyle: {
    marginLeft: 5,
    marginRight: 5,
    ...Headline5,
    color: neutral_1,
  },
};

const ToastComponent = (theme, type) => (props) => {
  const {
    containerStyle,
    leftIconStyle,
    messageStyle,
    descriptionStyle,
    actionTextStyle,
    actionIconStyle,
  } = theme || {};

  const defaultIcon = {
    success: notificationTick,
    error: notificationError,
    warning: notificationWarning,
  };

  const {
    leftIcon, message, description, CustomDescription, action, hideToast,
  } = props;
  const {
    text, icon, textStyle, iconStyle, onPress, ...restAction
  } = action || {};

  return (
    <View style={containerStyle}>
      {(leftIcon || defaultIcon[type]) && (
      <Image
        source={leftIcon || defaultIcon[type]}
        style={leftIconStyle}
      />
      )}
      <View style={{ flex: 1 }}>
        {message && <Text style={messageStyle}>{message}</Text>}
        {description && <Text style={descriptionStyle}>{description}</Text>}
        {CustomDescription && <CustomDescription />}
      </View>
      <Action
        style={{
          flexDirection: 'row',
          cursor: 'pointer',
        }}
        action={{
          ...restAction,
          onPress: () => {
            hideToast && hideToast();
            onPress && onPress();
          },
        }}
      >
        <>
          {text && <Text style={textStyle || actionTextStyle}>{text}</Text>}
          <View style={{ marginBottom: 20 }}>
            <Image source={crossIconPng} style={iconStyle || actionIconStyle} />
          </View>
        </>
      </Action>
    </View>
  );
};

const toastConfig = {
  standard: ToastComponent(standardTheme),
  success: ToastComponent(successTheme, 'success'),
  error: ToastComponent(errorTheme, 'error'),
  warning: ToastComponent(warningTheme, 'warning'),
};
export default (props) => (
  <Toast
    config={toastConfig}
    ref={(ref) => {
      Toast.setRef(ref);
    }}
    action={{ icon: crossIcon }}
    {...props}
  />
);
