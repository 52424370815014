import React from 'react';
import CardTitleBar from './CardTitleBar';
import CardContentLayout from './CardContentLayout';
import {View} from '@applane/react-core-components';

const CardContent = props => {
  let {contentContainerStyle, titleGap, contentItems, title, ...restProps} =
    props;
  let itemsComponent = void 0;
  let containerStyle = contentContainerStyle;
  if (typeof contentItems === 'function') {
    contentItems = contentItems(restProps);
  }
  if (contentItems) {
    itemsComponent = (
      <CardContentLayout
        {...restProps}
        content={contentItems}
        source={'content'}
      />
    );
    if (titleGap && title && itemsComponent) {
      itemsComponent = (
        <React.Fragment>
          <View style={{height: titleGap}} />
          {itemsComponent}
        </React.Fragment>
      );
    }
  }

  return (
    <View style={containerStyle}>
      {title ? <CardTitleBar title={title} {...restProps} /> : void 0}
      {itemsComponent}
    </View>
  );
};

CardContent.defaultProps = {
  titleGap: 6,
};

export default CardContent;
