import React from 'react';
import {View} from '@applane/react-core-components';
import CardItem from './CardItem';

class CardActions extends React.Component {
  render() {
    let {actions, actionSource, ...restProps} = this.props;
    if (typeof actions === 'function') {
      actions = actions(restProps);
    }
    if (!actions) {
      return null;
    }
    let actionContainerStyle = this.props[`${actionSource}ContainerStyle`];
    if (!Array.isArray(actions)) {
      let {width, containerStyle} = actions;
      actionContainerStyle = {
        ...actionContainerStyle,
        ...containerStyle,
      };
      if (width) {
        actionContainerStyle.width = width;
      }
      actions = actions.actions || actions.items;
    }
    if (!actions || !actions.length) {
      return null;
    }
    return (
      <View style={actionContainerStyle}>
        {actions.map((actionInfo, index) => {
          if (typeof actionInfo === 'function') {
            actionInfo = actionInfo(restProps);
          }
          if (!actionInfo) {
            return null;
          }
          if (!actionInfo.action && !actionInfo.actions) {
            actionInfo = {
              ...actionInfo,
              action: {
                ...actionInfo,
              },
            };
          }
          return (
            <CardItem
              {...restProps}
              column={actionInfo}
              itemIndex={index}
              source={actionSource}
            />
          );
        })}
      </View>
    );
  }
}

CardActions.defaultProps = {
  rightActionsContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 6,
  },
  rightActionsItemStyle: {
    paddingLeft: 6,
    paddingRight: 6,
  },
};

export default CardActions;
