import React from 'react';
import {Text, View} from '@applane/react-core-components';

const CardFooter = props => {
  let {footerStyle, renderCardFooter} = props;
  if (renderCardFooter) {
    return renderCardFooter(props);
  }
  return (
    <View style={footerStyle}>
      <Text>Footer</Text>
    </View>
  );
};

CardFooter.defaultProps = {};

export default CardFooter;
