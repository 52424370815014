import React from 'react';
import {
  TouchableOpacity,
  View,
  Text,
  Image,
  Platform,
} from '@applane/react-core-components';
import { DatePicker } from '@applane/react-date-input';
import { Button } from '@applane/react-button';

class Modal extends React.Component {
  state = {
    date: {},
  };

  onDone = (remove) => {
    const { onApply, hideModal } = this.props;
    const { date = {} } = this.state;
    let { from, to } = date;
    if (from) {
      from = new Date(from).setHours(0, 0, 0);
      from = new Date(from);
    }
    if (to) {
      to = new Date(to).setHours(24, 0, 0);
      to = new Date(to).setHours(0, 0, 0, -1);
      to = new Date(to);
    }
    const data = { from, to, value: 'Custom' };
    if (remove === true) {
      data.removeFilter = true;
    }

    onApply && onApply({ ...data });
    hideModal && hideModal();
  };

  handleDate = ({ field, value }) => {
    const { date } = this.state;
    if (field === 'from') {
      date[field] = value;
    } else if (field === 'to') {
      date[field] = value;
    }
    this.setState({ date });
  };

  componentDidMount = () => {
    let { from, to } = this.props || {};
    if (from && Platform.OS === 'web') {
      from = new Date(from);
      from = from.setHours(0, 0, 0, -1) + 86400000;
      from = new Date(from);
    }
    if (to && Platform.OS !== 'web') {
      to = new Date(to);
      to = to.setHours(18, 30, 0);
      to = new Date(to);
    }
    const date = {};
    date.from = from;
    date.to = to;
    this.setState({
      date,
    });
  };

  resetDate = () => {
    const { from, to, hideModal } = this.props;
    if (from || to) {
      this.onDone(true);
    } else {
      hideModal && hideModal();
    }
  };

  render() {
    const { customHeaderPopupTheme = {} } = this.props;
    const {
      buttonStyle,
      titleStyle = {},
      buttonContainerStyle = {},
      titleContainerStyle = {},
      bodyContainerStyle = {},
    } = customHeaderPopupTheme;
    const {
      date: { from, to },
    } = this.state || {};
    const { frameStyle, hideModal } = this.props;
    return (
      <View
        onPress={(e) => e.stopPropagation()}
        style={{
          ...frameStyle,
        }}
      >
        <View style={titleContainerStyle}>
          <Text title="Select Range" style={{ ...titleStyle }}>
            Select Range
          </Text>
          <TouchableOpacity
            onPress={hideModal}
            style={{ cursor: 'pointer', justifyContent: 'center' }}
          >
            <Image
              style={{ width: 24, height: 24 }}
              resizeMode="contain"
              source={this.props.crossIcon}
            />
          </TouchableOpacity>
        </View>
        <View style={bodyContainerStyle}>
          <DatePicker
            {...this.props}
            value={from}
            onChangeValue={(value) => this.handleDate({ field: 'from', value })}
            placeholder="Start Date"
            label="Start Date"
          />
          <View style={{ height: 20 }} />
          <DatePicker
            {...this.props}
            value={to}
            onChangeValue={(value) => this.handleDate({ field: 'to', value })}
            placeholder="End Date"
            label="End Date"
          />
        </View>
        <View style={buttonContainerStyle}>
          <Button
            onPress={this.resetDate}
            label="Reset"
            {...(buttonStyle && buttonStyle.reset)}
          />
          <Button
            disabled={
              !(this.state.date && (this.state.date.from || this.state.date.to))
            }
            onPress={this.onDone}
            label="Apply"
            {...(buttonStyle && buttonStyle.apply)}
          />
        </View>
      </View>
    );
  }
}

export default Modal;
