import React from 'react';
import {View, Image, Text} from '@applane/react-core-components';
import {getRenderComponent} from './Utility';

const HelperText = props => {
  let {
    helperTextClassName,
    helperIcon,
    helperText,
    renderHelperText,
    helperTextContainerStyle,
    helperTextIconStyle,
    helperTextStyle,
  } = props;
  if (!helperText) {
    return null;
  }
  if (renderHelperText) {
    return getRenderComponent(renderHelperText, props);
  }
  return (
    <View
      className={helperTextClassName}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        ...helperTextContainerStyle,
      }}>
      {helperIcon && (
        <Image
          style={helperTextIconStyle}
          source={helperIcon}
        />
      )}
      <Text style={helperTextStyle}>{helperText}</Text>
    </View>
  );
};

HelperText.defaultProps = {
  helperTextConatinerStyle: {
    // marginTop: 3,
  },
  helperTextIconStyle: {
    paddingRight: 3,
  },
  helperTextStyle: {
    fontSize: 10,
    color: '#6E6E6E',
  },
};

export default HelperText;
