import React, { useState, useEffect } from 'react';

import { fetch, getUser } from '../../../AppServices';
import {
  ActivityIndicator, ScrollView, View, Text,
} from '../../../app-components';
import PatientCard from './PatientCard';
import { h15_Medium } from '../../../theme/fonts';

const PastAppointment = (props) => {
  const [waitingRoomData, setWaitingRoom] = useState([]);
  const [loading, setLoading] = useState(false);

  const appointmentDatas = async () => {
    const { patient } = getUser();
    setLoading(true);
    try {
      const result = await fetch({
        uri: {
          props: {
            query: {
              id: 'appointmentLists',
              addOnFilter: {
                status: { $in: ['completed', 'cancelled'] },
                patient_id: {
                  _id: patient?._id,
                },
              },
            },
            model: 'appointments',
          },
        },
      });
      const { data = [] } = result || {};
      if (Array.isArray(data)) {
        setWaitingRoom(data.reverse());
      }
    } catch (err) {
      return err;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    appointmentDatas();
  }, []);

  const { selectedTab = '' } = props;
  return (
    <ScrollView
      style={{
        flex: 1,
        height: '70vh',
      }}
    >
      {loading ? (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ActivityIndicator size="small" color="#fff" />
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            height: '70vh',
          }}
        >
          {Array.isArray(waitingRoomData) && waitingRoomData.length ? (
            waitingRoomData.map((item, index) => (
              <View key={index} style={{ flexBasis: 'auto' }} className="appointment-card">
                <PatientCard
                  item={item}
                  size={waitingRoomData.length}
                  index={index}
                  selectedTab={selectedTab}
                  {...props}
                  key={index.toString()}
                  socketData={{}}
                  appointmentDatas={appointmentDatas}
                />
              </View>
            ))
          ) : (
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text style={{ ...h15_Medium, color: '#fff' }}>
                You have no past appointments
              </Text>
            </View>
          )}
        </View>
      )}
    </ScrollView>
  );
};
export default PastAppointment;
