import React from 'react';

import { TouchableOpacity, Image, Text } from '../../../app-components';
import { Table } from '../../../app-components/table/Table';
import {
  MODELS, vaccinationStatus,
} from '../../../Lib/constants';
import vars from '../../../theme/vars';
import { downloadSalarySlip } from '../../../images';
import printPharmacyBill from '../../../Wired/PatientInvoices';
import { VaccinationList } from '../../../Queries/vaccination';

const tableItems = {
  lg: {
    columns: [
      {
        width: 14,
      },
      {
        header: 'vaccination.headers.vaccinatedDate'.getLabel(),
        type: 'date',
        field: '_createdOn',
      },
      {
        header: 'vaccination.headers.opdNo'.getLabel(),
        type: 'text',
        field: 'consultation.ODPNumber',
      },
      {
        header: 'vaccination.headers.crNo'.getLabel(),
        type: 'text',
        field: '',
      },
      {
        header: 'vaccination.headers.nurseName'.getLabel(),
        type: 'text',
        field: 'vaccinatedByName',
      },
      {
        header: 'vaccination.headers.vaccinationStatus'.getLabel(),
        type: 'text',
        render: ({ item: { status } = {} }) => {
          if (status === vaccinationStatus.vaccinated) {
            return (
              <Text style={{ ...vars.headings.h8, color: vars.colors.primary.color2 }}>
                Vaccinated
              </Text>
            );
          }
          if (status === vaccinationStatus.nurseAssign) {
            return (
              <Text style={{ ...vars.headings.h8, color: vars.colors.primary.color2 }}>
                Nurse Assigned
              </Text>
            );
          }
          if (status === vaccinationStatus.notAppear) {
            return (
              <Text style={{ ...vars.headings.h8, color: vars.colors.error }}>
                Not Appeared
              </Text>
            );
          }
          return (
            <Text style={{ ...vars.headings.h8, color: vars.colors.warning }}>
              No Status
            </Text>
          );
        },
      },
      {
        header: 'vaccination.headers.noOfVaccine'.getLabel(),
        type: 'text',
        render: ({ item: { patientVaccineDetails } }) => (
          <Text style={{ color: '#fff', fontSize: 14 }}>
            {patientVaccineDetails.length}
          </Text>
        ),
      },
      {
        header: 'vaccination.headers.bill'.getLabel(),
        type: 'text',
        render: ({ item }) => {
          const { billing } = item;
          return (
            <TouchableOpacity
              onPress={({ submitResult }) => printPharmacyBill({
                data: { ...billing },
                submitResult,
              })}
            >
              <Image
                source={downloadSalarySlip}
                style={{
                  width: 12,
                  height: 16,
                }}
              />
            </TouchableOpacity>
          );
        },
      },
    ],
  },
};

const PatientVaccinationHistory = (props) => {
  const { tableColumns = tableItems, addOnFilter } = props;
  return (
    <Table
      {...tableColumns}
      uri={() => VaccinationList({ filter: addOnFilter })}
    //   action={({ item }) => ({
    //     type: 'link',
    //     link: {
    //       view: ROUTES.viewPatientVaccinationHistoryDetails.name,
    //       params: { item },
    //     },
    //   })}
      reloadEvent={`reload${MODELS.VACCINATION}`}
      {...props}
    />
  );
};
export default PatientVaccinationHistory;
