import React from 'react';

import {
  View,
  Text,
  TouchableOpacity,
  BasicTextInput,
  FlatList,
  Image,
  Keyboard,
  Platform,
} from '@applane/react-core-components';
import {ActivityIndicator} from '@applane/react-activity-indicator';
import {isSelected, getRenderComponent} from './Utility';
class AutoSuggestWithSearchInput extends React.Component {
  lastFetchSequence = 0;
  state = {};
  initialRowIndex = 0;

  componentDidMount() {
    let {showValueInSearch, value} = this.props;
    if (showValueInSearch && value) {
      this.setValueInSearchText(value);
    } else {
      this.fetchSuggestion();
    }
    this.addKeyDownListener();
  }

  componentWillUnmount() {
    this.removeKeyDownListener();
  }

  setValueInSearchText = value => {
    let {getDisplayValue} = this.props;
    this.setState({
      searchText: getDisplayValue ? getDisplayValue(value) : value,
      data: [],
    });
  };

  scrollToHoverIndex = hoverRowIndex => {
    let {hoverRowIndex: prevHoverRowIndex} = this.state || {};
    const dataLength = this.data?.length;
    this.setState({hoverRowIndex});
    let scrollOption = {block: 'nearest'};
    if (dataLength) {
      if (
        !(
          (prevHoverRowIndex === dataLength - 1 &&
            (hoverRowIndex === undefined ||
              hoverRowIndex === this.initialRowIndex)) ||
          (hoverRowIndex === dataLength - 1 &&
            (prevHoverRowIndex === undefined ||
              prevHoverRowIndex === this.initialRowIndex))
        )
      ) {
        scrollOption.behavior = 'smooth';
      }
    }
    const elementToFocus = document.querySelector(
      `.flatListRowFocus${hoverRowIndex}`,
    );
    elementToFocus &&
      elementToFocus.scrollIntoView &&
      elementToFocus.scrollIntoView(scrollOption);
  };

  addKeyDownListener = () => {
    Platform.OS === 'web' && window.addEventListener('keydown', this.onKeyDown);
  };

  removeKeyDownListener = () => {
    Platform.OS === 'web' &&
      window.removeEventListener('keydown', this.onKeyDown);
  };

  onKeyDown = e => {
    let {onKeyDown, hideModal} = this.props;
    let {hoverRowIndex} = this.state;
    const dataLength = this.data?.length;
    const {key} = e || {};
    if (key && dataLength > this.initialRowIndex) {
      if (key === 'ArrowUp' || key === 'ArrowDown') {
        e && e.preventDefault && e.preventDefault();
        if (key === 'ArrowUp') {
          if (
            hoverRowIndex === undefined ||
            hoverRowIndex === this.initialRowIndex
          ) {
            hoverRowIndex = dataLength - 1;
          } else {
            hoverRowIndex = hoverRowIndex - 1;
          }
        } else if (key === 'ArrowDown')
          if (hoverRowIndex === undefined || hoverRowIndex === dataLength - 1) {
            hoverRowIndex = this.initialRowIndex;
          } else {
            hoverRowIndex = hoverRowIndex + 1;
          }
        this.scrollToHoverIndex(hoverRowIndex);
      }
      if (key === 'Enter') {
        e && e.preventDefault && e.preventDefault();
        this.onEnterPressRow(e);
      }
    }
    if (key === 'Escape') {
      hideModal && hideModal();
    }
    onKeyDown && onKeyDown(e);
  };

  onEnterPressRow = e => {
    let {hoverRowIndex} = this.state;
    if (
      hoverRowIndex !== undefined &&
      this.data?.length > this.initialRowIndex
    ) {
      this._onRowPress({item: this.data[hoverRowIndex], index: hoverRowIndex});
    }
  };

  _onRowPress = ({item, index}) => {
    const {onItemSelect, hideModal, multiSelect, showValueInSearch} =
      this.props;
    const {searchText} = this.state;
    Keyboard && Keyboard.dismiss();
    let value = void 0;
    if (onItemSelect) {
      value = onItemSelect({item, index, searchValue: this.state.searchText});
    }
    if (showValueInSearch) {
      if (value instanceof Promise) {
        value.then(result => {
          this.setValueInSearchText(result);
        });
      } else {
        this.setValueInSearchText(value);
      }
    }
    !multiSelect && hideModal && hideModal();
    this.initialRowIndex && this.setState({hoverRowIndex: undefined});
  };

  _renderRow = ({item, index}) => {
    const {
      renderRow,
      dropdownTextStyle,
      dropdownTextHighlightStyle,
      accessible,
      isHighlighted,
      getDisplayValue,
      ...restProps
    } = this.props;
    const {sugestionField} = restProps || {};
    if (item.valueComponent) {
      return item.valueComponent;
    }
    let highlighted = isHighlighted && isHighlighted({item: item, index});

    const row = !renderRow ? (
      <Text
        style={[dropdownTextStyle, highlighted && dropdownTextHighlightStyle]}>
        {sugestionField ? item[sugestionField] : item}
      </Text>
    ) : (
      renderRow({
        item,
        index,
        isHover: this.state.hoverRowIndex === index,
        highlighted,
        props: restProps,
      })
    );

    let preservedProps;
    if (Platform.OS === 'web') {
      preservedProps = {
        accessible,
        onMouseDown: e => {
          e.preventDefault();
          this._onRowPress({item, index});
        },
      };
    } else {
      preservedProps = {
        accessible,
        onPress: e => {
          this._onRowPress({item, index});
        },
      };
    }

    return (
      <TouchableOpacity
        activeOpacity={0.6}
        onMouseMove={e => {
          e && e.preventDefault && e.preventDefault();
          this.state.hoverRowIndex !== index && this.scrollToHoverIndex(index);
        }}
        className={`flatListRowFocus${index}`}
        {...preservedProps}>
        {row}
      </TouchableOpacity>
    );
  };

  updateSuggestions = data => {
    this.setState({data, hoverRowIndex: void 0});
  };

  _renderSeparator = rowID => {
    let {separatorStyle} = this.props;
    return <View key={`spr_${rowID}`} style={separatorStyle} />;
  };

  fetchSuggestion = searchValue => {
    const {fetch, options, minChar} = this.props;
    if (minChar && (!searchValue || searchValue.length < minChar)) {
      this.updateSuggestions([]);
      return;
    }
    this.lastFetchSequence++;
    let sequence = this.lastFetchSequence;
    this.setState({toggled: !this.state.toggled});
    let result;
    if (options && Array.isArray(options)) {
      result = options;
    } else if (fetch) {
      this.fetching = true;
      result = fetch({
        searchValue,
      });
    } else {
      console.warn(
        'Autosuggest input neither fetch provided nor options (in array )',
      );
    }

    if (result && result instanceof Promise) {
      result
        .then(data => {
          if (this.lastFetchSequence === sequence) {
            this.fetching = false;
            this.updateSuggestions(data);
          }
        })
        .catch(e => {
          this.fetching = false;
          // console.error('Error in fetching in autosuggest input', e);
        });
    } else if (result) {
      this.fetching = false;
      this.updateSuggestions(result);
    }
  };

  onChangeText = text => {
    this.setState({
      searchText: text,
    });
    this.fetchSuggestion(text);
  };

  _renderLoading = () => {
    return <ActivityIndicator size="small" color="#4276EC" />;
  };

  removeSelectedData = ({data, value}) => {
    return data && value
      ? data.filter(item => {
          if (
            isSelected({
              ...this.props,
              value,
              item,
            })
          ) {
            return false;
          }
          return true;
        })
      : data;
  };

  onClearSearch = () => {
    let {showValueInSearch, reset} = this.props;
    this.onChangeText('');
    if (showValueInSearch && reset) {
      reset();
    }
  };

  modifyDataWithChips = () => {
    const {
      autoSuggestModalTheme,
      value,
      valueField,
      idField,
      renderChips,
      multiSelect,
      onRemove,
    } = this.props;
    const {valueContainerStyle} = autoSuggestModalTheme;
    let {data, searchText} = this.state;
    if (multiSelect) {
      if (!searchText && value && value.length) {
        if (renderChips) {
          let valueComponent = getRenderComponent(renderChips, {
            value,
            valueField,
            idField,
            onRemove,
          });
          valueComponent = (
            <View style={valueContainerStyle}>{valueComponent}</View>
          );
          if (data && data.length) {
            data = this.removeSelectedData({data, value});
            data = [{valueComponent}, ...data];
          } else {
            data = [{valueComponent}];
          }
        }
      }
    }

    this.data = data;
    this.initialRowIndex = data?.[0]?.valueComponent ? 1 : 0;
  };

  render() {
    this.modifyDataWithChips();
    const {
      renderSeparator,
      showsVerticalScrollIndicator,
      keyboardShouldPersistTaps = 'always',
      keyExtractor,
      label,
      placeholder,
      searchPlaceholder,
      autoSuggestModalTheme,
      searching,
      renderLoading,
      reset,
      onApply,
      hideModal,
      showHeader,
      showButton = false,
      autoFocus,
    } = this.props;
    const {
      crossIcon,
      searchIcon,
      headerContainer,
      headerTextAndIconContainer,
      headerStyle,
      crossIconStyle,
      searchContainer,
      inputStyle,
      searchIconStyle,
      flatlistStyle,
      bottomButtonContainer,
      resetButtonStyle,
      resetTextStyle,
      submitButtonStyle,
      submitTextStyle,
    } = autoSuggestModalTheme;
    let {searchText} = this.state;
    return (
      <View style={{flex: 1, overflow: 'hidden'}}>
        <View style={headerContainer}>
          {showHeader && (
            <View style={headerTextAndIconContainer}>
              <Text style={headerStyle}>{label || placeholder || ''}</Text>
              <TouchableOpacity
                onPress={hideModal}
                activeOpacity={0.6}
                style={{padding: 8, margin: -8}}>
                <Image source={crossIcon} style={crossIconStyle} />
              </TouchableOpacity>
            </View>
          )}
          {searching && (
            <View style={searchContainer}>
              <BasicTextInput
                style={inputStyle}
                value={searchText || ''}
                placeholder={searchPlaceholder || placeholder || 'Search here'}
                onChangeText={this.onChangeText}
                autoFocus={autoFocus}
              />
              {searchText ? (
                <TouchableOpacity
                  style={{cursor: 'pointer'}}
                  onPress={this.onClearSearch}
                  activeOpacity={0.6}>
                  <Image source={crossIcon} style={searchIconStyle} />
                </TouchableOpacity>
              ) : (
                <Image source={searchIcon} style={searchIconStyle} />
              )}
            </View>
          )}
        </View>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <FlatList
            keyExtractor={keyExtractor}
            ref={index => (this.FlatList = index)}
            style={{flex: 1, ...flatlistStyle}}
            data={this.data}
            renderItem={this._renderRow}
            ItemSeparatorComponent={renderSeparator || this._renderSeparator}
            showsHorizontalScrollIndicator={showsVerticalScrollIndicator}
            keyboardShouldPersistTaps={keyboardShouldPersistTaps}
            bounces={Platform.OS === 'ios' ? false : void 0}
          />
          {this.fetching && !renderLoading ? this._renderLoading() : void 0}
        </View>
        {showButton && (
          <View style={bottomButtonContainer}>
            <TouchableOpacity
              style={resetButtonStyle}
              onPress={() => {
                reset && reset();
                hideModal && hideModal();
              }}>
              <Text style={resetTextStyle}>Reset</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={submitButtonStyle}
              onPress={() => {
                onApply && onApply();
                hideModal && hideModal();
              }}>
              <Text style={submitTextStyle}>Apply</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    );
  }
}

export default AutoSuggestWithSearchInput;
