import { uniqueId } from 'lodash';
import {
  filterIcon,
  nonSelectedSession,
  nonSelectedSession as filterAppliedIcon,
  nonSelectedSession as filterActiveIcon,
  activeDateFilter,
} from '../../images';

export const FilterGroupRowAction = (props) => ({
  key: uniqueId(),
  type: 'filterRow',
  icon: filterIcon,
  activeIcon: filterActiveIcon,
  appliedIcon: filterAppliedIcon,
  visible: ({ navigation } = {}) => {
    if (navigation) {
      const routeCount = navigation.getRouteCount && navigation.getRouteCount();
      const routeIndex = navigation.getIndex && navigation.getIndex();
      if (
        routeCount
          && routeIndex !== undefined
          && routeIndex < routeCount - 1
      ) {
        return false;
      }
    }
    return true;
  },
  isActive: ({ navigation, action }) => {
    const { getScreenState /* , getTabScreenState */ } = navigation || {};
    if (!getScreenState /* && !getTabScreenState */) {
      return;
    }
    const { key } = action;
    const { dataParams: { filters, ftsFilter } = {}, filterActionKey } = (getScreenState
         && getScreenState()) || {};
    if (filterActionKey === key) {
      return true;
    }
    if (!filters && !ftsFilter /* && !tabFilters */) {
      return;
    }
    if (action.filters) {
      for (const filterInfo of action.filters) {
        if (filterInfo && typeof filterInfo === 'object') {
          const { field, type } = filterInfo;
          if (field) {
            if (
              filters
                && filters[field]
                && filters[field].value !== undefined
                && filters[field].value !== null
            ) {
              return true;
            }
          } else if (type === 'fts' && ftsFilter && ftsFilter.value) {
            return true;
          }
        }
      }
    }
  },
  onUnmount: ({ navigation, action }) => {
    const { getScreenState, setScreenState } = navigation || {};
    if (!getScreenState && setScreenState) {
      return;
    }
    const { key } = action || {};
    const { filterActionKey } = getScreenState() || {};
    if (filterActionKey === key) {
      setScreenState({
        filterAction: void 0,
        filterActionKey: void 0,
      });
    }
  },
  ...props,
});

export default FilterGroupRowAction({
  icon: nonSelectedSession,
  activeIcon: activeDateFilter,
  skipClearValue: true,
  filters: [
    {
      type: 'date',
      field: 'period',
      label: 'Select Period',
      asParam: true,
    },
  ],
});
