import { ROUTES } from './Lib/constants';

let baseUrl = process.env.REACT_APP_BASE_URL;
// baseUrl = 'http://192.168.100.43:7000';
// baseUrl = 'http://localhost:7000';
// baseUrl = 'http://192.168.100.31:7000';
baseUrl = process.env.REACT_APP_BASE_URL;
let gpsSocketUrl = process.env.REACT_APP_GPS_URL;
gpsSocketUrl = 'http://127.0.0.1:4582';
export const BASE_URL = baseUrl;
export const SOCKET_BASE_URL = baseUrl;
export const GPS_SOCKET_URL = gpsSocketUrl;
export const SESSION_CHECK = process.env.REACT_APP_SESSION_CHECK;
export const VERSION = 'v-2.3.4 ';
export const SERVER_VERSION = 10;
export const MODE = process.env.REACT_APP_MODE;
export const LOG_ENABLED = process.env.REACT_APP_LOG_ENABLED;
export const LANDING_VIEW = 'Dashboard';
export const currency = {
  code: 'INR',
  sign: ' ₹',
  name: 'rupay',
};
