import React from 'react';
import {Platform} from '@applane/react-core-components';
import {Snackbar} from '@applane/react-snack-bar';

export const imageExtensions = [
  'jpg',
  'png',
  'jpeg',
  'tif',
  'gif',
  'dng',
  'raw',
  'bmp',
  'psd',
];

export const extensions = [...imageExtensions];

export const getFileExtension = filename => {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
};

const iterator = (array, task) => {
  return new Promise((resolve, reject) => {
    let length = array ? array.length : 0;
    if (length === 0) {
      resolve();
      return;
    }
    let index = 0;
    let loop = index => {
      try {
        let onResolve = function() {
          index = index + 1;
          if (index === array.length) {
            resolve();
          } else {
            loop(index);
          }
        };
        try {
          let p = task(index, array[index]);
          if (!p) {
            onResolve();
            return;
          }
          p.then(onResolve).catch(function(err) {
            reject(err);
          });
        } catch (e) {
          reject(e);
        }
      } catch (e) {
        reject(e);
      }
    };
    loop(index);
  });
};

const isImage = ({name, _extensionProps}) => {
  let ext = name && name.split('.').pop();
  if (ext) {
    ext = ext.toLowerCase();
  }

  if (_extensionProps) {
    return _extensionProps.indexOf(ext) >= 0;
  }
  return extensions.indexOf(ext) >= 0;
};

const canOpenInNewTab = ({name, extensions: _extensionProps}) => {
  if (!isImage || isImage({name, _extensionProps})) {
    return true;
  } else {
    let extension = name && name.split('.').pop();
    if (extension) {
      extension = extension.toLowerCase();
    }
    if (extension === 'pdf') {
      return true;
    }
  }
};
const resolveExp = (values, key) => {
  if (!values || !key) {
    return values;
  }
  if (typeof key === 'function') {
    return key(values);
  }
  if (Array.isArray(values)) {
    var result = [];
    for (var i = 0; i < values.length; i++) {
      var row = values[i];
      var resolvedValue = resolveExp(row, key);
      if (resolvedValue !== undefined) {
        if (Array.isArray(resolvedValue)) {
          for (var j = 0; j < resolvedValue.length; j++) {
            /* for save the duplicate values for field other then _id @sourabh -- 4-2-17*/
            if (key !== '_id' || result.indexOf(resolvedValue[j]) === -1) {
              result.push(resolvedValue[j]);
            }
          }
        } else {
          if (key !== '_id' || result.indexOf(resolvedValue) === -1) {
            result.push(resolvedValue);
          }
        }
      }
    }
    return result;
  } else {
    var value = values[key];
    if (value !== undefined) {
      return value;
    }
    var index = key.indexOf('.');
    if (index === -1) {
      return;
    }
    var firstPart = key.substring(0, index);
    var nextPart = key.substring(index + 1);
    return resolveExp(values[firstPart], nextPart);
  }
};

export const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

export const matchValue = (value1, value2, fieldToMatch) => {
  if (fieldToMatch && value1 && value2) {
    return value1[fieldToMatch] === value2[fieldToMatch];
  } else {
    return value1 === value2;
  }
};

const getModifiedResponse = response => {
  if (Platform.OS === 'web') {
    return {response};
  }
  const {path, uri} = response;
  if (!response.name && response.fileName) {
    response.name = response.fileName;
  }
  if (Platform.OS === 'ios') {
    let path = response.uri;
    path = '~' + path.substring(path.indexOf('/Documents'));
    if (!response.fileName) response.name = path.split('/').pop();
  }
  return {
    response,
    localPath: Platform.OS === 'ios' ? uri : 'file://' + path,
  };
};

export const uploadData = async (response, props) => {
  let {
    multiple,
    onUploadFile,
    upload,
    uploadOptions,
    validateValue,
    value,
  } = props;
  let validateMessage = validateValue && validateValue(response);
  if (validateMessage) {
    Snackbar.show({
      text: validateMessage,
      duration: Snackbar.LENGTH_SHORT,
    });
    return;
  }
  let result, localPath;
  if (!multiple) {
    let modifiedResponse = getModifiedResponse(response);
    localPath = response.localPath;
    if (onUploadFile) {
      result = await onUploadFile(modifiedResponse.response, props);
    } else {
      result = await upload(response, uploadOptions);
    }
  } else {
    let newValue;
    if (value && !Array.isArray(value)) {
      newValue = [value];
    } else {
      newValue = value ? [...value] : [];
    }
    await iterator(response, (index, file) => {
      let modifiedResponse = getModifiedResponse(file);
      localPath = localPath || [];
      localPath.push(modifiedResponse.localPath);
      return upload(modifiedResponse.response, uploadOptions).then(result => {
        if (result) {
          newValue.push(result);
        }
      });
    });
    if (newValue.length) {
      result = newValue;
    }
  }
  return {
    result,
    localPath,
  };
};

export {iterator, isImage, canOpenInNewTab, resolveExp};
