import React from 'react';
import {View} from '@applane/react-core-components';
import {WithInputWrapper, WithInputError} from '@applane/react-input-wrapper';
import RadioButton from './RadioButton';

class Radio extends React.Component {
  render() {
    let {
      value,
      option,
      style,
      onChangeValue,
      defaultValue,
      ...restProps
    } = this.props;
    if (!value) {
      value = defaultValue;
    }
    return (
      <View style={style}>
        <RadioButton
          {...restProps}
          selected={value !== undefined && value === option}
          onChangeValue={(_value, e) => {
            if (value !== option) {
              onChangeValue && onChangeValue(option, e);
            }
          }}
        />
      </View>
    );
  }
}

Radio = WithInputWrapper(WithInputError(Radio));

export default Radio;
