import React from 'react';
import {
  View,
  Text,
  Image,
  Linking,
  TouchableOpacity,
} from '@applane/react-core-components';
import {
  canOpenInNewTab,
  getFileExtension,
  imageExtensions,
} from './fileUtility';
import FilePreview from './filepreview.png';

class FileDownloadLink extends React.Component {
  render() {
    let {
      children,
      value,
      openInNewTab,
      getDownloadUrl,
      linkTextStyle,
      linkViewStyle,
      extensions,
    } = this.props;
    let {key, name} = value || {};
    let downloadUrl =
      getDownloadUrl && getDownloadUrl({key, name, openInNewTab});
    let linkProps = {};

    if (openInNewTab && canOpenInNewTab({name, extensions})) {
      linkProps.target = '_blank';
    }
    let downloadLink = (
      <TouchableOpacity
        style={{cursor: 'pointer'}}
        onPress={() => Linking.openURL(downloadUrl, linkProps.target)}
        onClick={e => e && e.stopPropagation && e.stopPropagation()}>
        {children || <Text style={linkTextStyle}>{name || key || ''}</Text>}
      </TouchableOpacity>
    );
    return <View style={linkViewStyle}>{downloadLink}</View>;
  }
}

export default class FileValueRender extends React.Component {
  getPreviewComponent = ({item}) => {
    let {
      getDownloadUrl,
      openInNewTab = true,
      extensions,
      filePreviewStyle = {},
      previewCrossTextStyle = {},
      previewCrossIconStyle = {},
      onRemove,
      fileRenderBoxTheme,
    } = this.props;
    let {
      crossStyle: {image: _crossImage},
    } = fileRenderBoxTheme || {};
    let {key, name} = item || {};
    let downloadUrl =
      getDownloadUrl && getDownloadUrl({key, name, openInNewTab: true});
    let ext = getFileExtension(name);
    let linkProps = {};

    if (openInNewTab && canOpenInNewTab({name, extensions})) {
      linkProps.target = '_blank';
    }
    let previewImage = FilePreview;
    if (imageExtensions.includes(ext)) {
      previewImage = {
        uri: downloadUrl,
      };
    }
    return (
      <View
        style={{
          ...filePreviewStyle,
        }}>
        <TouchableOpacity
          style={{flex: 1, cursor: 'pointer'}}
          onPress={() => Linking.openURL(downloadUrl, linkProps.target)}>
          <Image
            style={{
              ...filePreviewStyle,
              margin: 0,
            }}
            source={previewImage}
            resizeMode="stretch"
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            right: 0,
            position: 'absolute',
            cursor: 'pointer',
            backgroundColor: '#ffffff',
          }}
          onPress={e => {
            onRemove({item});
          }}>
          {_crossImage ? (
            <Image
              style={{height: 16, width: 16, ...previewCrossIconStyle}}
              source={_crossImage || ''}
            />
          ) : previewCrossTextStyle ? (
            <Text style={previewCrossTextStyle}>{'X'}</Text>
          ) : null}
        </TouchableOpacity>
      </View>
    );
  };

  getRenderComponent = ({item}) => {
    let {onRemove, disabled, editable, fileRenderBoxTheme} = this.props;
    const {
      containerStyle,
      documentImageStyle: {
        viewStyle: _documentViewStyle,
        imageStyle: _documentImageStyle,
        image: _documentImage,
      },
      downloadLinkContainerStyle: {
        containerStyle: _downloadcontainerStyle,
        textStyle: _downloadTextStyle,
        linkViewStyle,
      },
      crossStyle: {
        viewStyle: _crossViewStyle,
        image: _crossImage,
        imageStyle: _crossImageStyle,
        textStyle: _crossTextStyle,
        text,
      },
    } = fileRenderBoxTheme || {};
    return (
      <View style={{...containerStyle}}>
        <View
          style={{
            ..._documentViewStyle,
          }}>
          <Image style={_documentImageStyle} source={_documentImage || ''} />
        </View>
        <View
          style={{
            ..._downloadcontainerStyle,
          }}>
          <FileDownloadLink
            {...this.props}
            linkTextStyle={_downloadTextStyle}
            linkViewStyle={linkViewStyle}
            value={item}
          />
        </View>
        {!disabled && editable !== false && (
          <View
            style={{
              ..._crossViewStyle,
            }}
            onClick={() => {
              onRemove({item});
            }}>
            {_crossImage && _crossImageStyle ? (
              <Image style={{..._crossImageStyle}} source={_crossImage || ''} />
            ) : _crossTextStyle ? (
              <Text style={_crossTextStyle}>{text || 'X'}</Text>
            ) : null}
          </View>
        )}
      </View>
    );
  };

  render() {
    let {value, preview, filePreviewStyle} = this.props;
    if (!value || (Array.isArray(value) && !value.length)) {
      return null;
    }
    if (preview) {
      if (Array.isArray(value)) {
        return (
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}>
            {value
              .map(val => this.getPreviewComponent({item: val}))
              .slice(0, 3)}
            {value.length > 3 && (
              <View
                style={{
                  ...filePreviewStyle,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Text style={{fontSize: 14, textAlign: 'center'}}>
                  + {value.length - 3} more file
                </Text>
              </View>
            )}
          </View>
        );
      } else {
        return this.getPreviewComponent({item: value});
      }
    } else {
      if (Array.isArray(value)) {
        return value.map(val => this.getRenderComponent({item: val}));
      } else {
        return this.getRenderComponent({item: value});
      }
    }
  }
}

FileValueRender.defaultProps = {
  filePreviewStyle: {
    width: 70,
    height: 70,
    borderRadius: 4,
    marginTop: 5,
    marginRight: 10,
    backgroundColor: '#e6e6e6',
  },
  previewCrossIconStyle: {},
  previewCrossTextStyle: {
    fontWeight: 'bold',
    color: 'gray',
    fontSize: 14,
  },
};
