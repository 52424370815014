export default {
  labels: {
    generalInformation: 'General Information',
    personalDetails: 'Personal Details',
    employeeId: 'Employee Id',
    speciality: 'Speciality',
    role: 'Role',
    state: 'State/Province/Region',
    city: 'City',
    pinCode: 'PinCode',
    gender: 'Gender',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    dob: 'Date of Birth',
    lastName: 'Last Name',
    firstName: 'First Name',
    designationType: 'Designation Type',
    employeeType: 'Employee Type',
    staffType: 'Staff Type',
    panNumber: 'PAN Number',
    mobileNo: 'Mobile No.',
    email: 'Email',
    password: 'Password',
    status: 'Status',
    department: 'Department',
    contactDetails: 'Contact Details',
    registrationDetails: 'Registration Details',
    contractDetails: 'Contract Details',
    bloodGroup: 'Blood Group',
    maritalStatus: 'Marital Status',
    joiningDate: 'Joining Date',
    experience: 'Experience (in Years)',
    shiftType: 'Shift Type',
    leave: 'Leave Policy',
    bondDuration: 'Bond Duration (in Months)',
    noticePeriod: 'Notice Period (in Months)',
    pfNumber: 'PF Number',
    bank: 'Bank',
    ifsc: 'IFSC Code',
    paymentMode: 'Payment Mode',
    account: 'Account Number',
    aadhar: 'Aadhar No.',
    labType: 'Lab Type',
    pranNumber: 'PRAN Number',
    ecrNumber: 'ECR Number',
    ecrPageNumber: 'ECR Page Number',
    bankAccountNo: 'Bank Account No',
    employeeSchedule: 'Employee Schedule',
  },
  buttons: {
    bulkUpload: 'Bulk Upload',
    addEmployee: 'Add Employee',
    editEmployee: 'Edit Employee',
    update: 'Update',
  },
  messages: {
    submitMessage: 'Employee has been added successfully.',
    updateMessage: 'Employee has been updated successfully.',
    uniqueError: 'Employee Id should be unique.',
  },
  placeholders: {
    enterEmployeeId: 'Enter Employee Id',
    selectDepartment: 'Select Department',
    addressLine1: 'Enter Building name, street etc',
    addressLine2: 'Address Line2',
    selectRole: 'Select Role',
    selectWard: 'Select Speciality',
    enterPassword: 'Enter Password',
    enterMobile: 'Enter Mobile',
    enterEmail: 'Enter Email',
    selectEmployeeType: 'Select EmployeeType',
    selectStaffType: 'Select StaffType',
    selectDesignation: 'Select Designation',
    enterPAN: 'Enter PAN Number',
    enterName: 'Enter Name',
    selectDOB: 'Select DOB',
    selectCity: 'Select City',
    selectState: 'Select State',
    selectGender: 'Select Gender',
    enterPincode: 'Enter PinCode',
    selectbloodGroup: 'Select Blood Group',
    selectmaritalStatus: 'Select Marital Status',
    selectjoiningDate: 'Select Joining Date',
    selectshiftType: 'Select Shift Type',
    selectleaves: 'Select Leaves Policy',
    employeeSchedule: 'Employee Schedule',
  },
  title: {
    employeeManagement: 'Employee Management',
    exportToExcel: 'Export To Excel',
  },
};
