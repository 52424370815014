/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React from 'react';
import { get } from 'lodash';
import {
  MultiTextInputField,
  TextInput as ReactTextInput,
  PasswordInput as ReactPasswordInput,
} from '@applane/react-text-input';
import { TextAreaInput as ReactTextAreaInput } from '@applane/react-text-area-input';
import { NumberInput as ReactNumberInput } from '@applane/react-number-input';
import { DatePicker } from '@applane/react-date-input';
import { Slider } from '@applane/react-slider';
import { TimeInput as ReactTimeInput } from '@applane/react-time-input';
import { RadioGroup, Radio } from '@applane/react-radio-button-v1';
import { RichTextInput as ReactRichTextInput } from '@applane/react-rich-text-input';
import { FileInput as ReactFileInput } from '@applane/react-file-input-v1';
import { ToggleButton, ToggleSwitch } from '@applane/react-toggle';
import {
  View,
  Text,
  Platform,
  TouchableOpacity,
  Image,
} from '@applane/react-core-components';
import {
  AutosuggestInputField,
  MultiAutosuggestInputField,
  GooglePlaceInputField,
} from '@applane/react-autosuggest';
import { isMobile } from '../index';
import { dateInputStyle } from '../../theme/DateBoxtheme';
import {
  autoSuggestInputTheme,
  multiAutosuggestInputTheme,
  multiAutosuggestMobileInputTheme,
  multiTextInputTheme,
} from '../../theme/editorTheme';
import {
  fileInputBoxTheme,
  downloadFileBoxTheme,
  UploadImageTheme,
} from '../../theme/fileBoxTheme';
import {
  inputToggleTheme,
  inputChipsToggleTheme,
  inputToggleSwitchTheme,
} from '../../theme/toggleTheme';
import { upload, getDownloadUrl, getImageUrl } from '../../AppServices';
import {
  backCrossIcon,
  showIcon,
  profile_preview,
  profileNonEditable,
  collapseIcon,
  expandIcon,
} from '../../images';
import {
  renderAvatarChips,
  getAutoSuggestProps,
} from './autoSuggest/renderRowCard';
import { progressSliderTheme } from '../../theme/sliderTheme';
import { radioGroupTheme, radioTheme } from '../../theme/radioTheme';
import { getFileTheme } from './themeUtility';
import ReactCheckBox from './checkBox/CheckBox';
import ReactCheckBoxGroup from './checkBox/CheckBoxGroup';
import GoogleMap from '../GoogleMap';
import { h13_Medium } from '../../theme/fonts';
import { formatNumberMaxTwoDecimalOnChange, removeAllSpacesOnChange } from '../../Lib/helpers';
// import ReactPasswordInput from './password/PasswordInput';
import { modifyInputProps, renderInputError } from './InputUtility';
import ConsultationStatusCell from '../../Components/ConsultationStatus';
import { checkBoxGroupTheme, checkBoxTheme } from '../../theme/checkBoxTheme';

const modifyToUpperCase = ({ value }) => value.toUpperCase();

export const TextInput = (props) => {
  const modifiedProps = modifyInputProps(props);
  const validationProps = {};
  const { allowWhiteSpaces = false, upperCase = false, allowConsecutiveSpaces = false } = props;

  if (!allowWhiteSpaces) {
    validationProps.modifyValueOnChange = removeAllSpacesOnChange;
  }
  if (upperCase) {
    validationProps.modifyValueOnChange = modifyToUpperCase;
  }
  if (allowConsecutiveSpaces) {
    validationProps.modifyValueOnChange = ({ value }) => {
      if (/\s{2,}/.test(value)) {
        return value.replace(/ {2,}/g, ' ');
      }
      return value;
    };
  }
  return <ReactTextInput {...modifiedProps} {...validationProps} />;
};

export const TextAreaInput = (props) => {
  const modifiedProps = modifyInputProps(props);
  return <ReactTextAreaInput {...modifiedProps} />;
};

export const NumberInput = (props) => {
  const modifiedProps = modifyInputProps(props);

  const validationProps = {};
  let iconProps = {};
  const { allowedDecimalDigits = 2 } = props;
  const { showCustomIcon = false } = props;

  if (allowedDecimalDigits) {
    validationProps.modifyValueOnChange = formatNumberMaxTwoDecimalOnChange;
  }

  if (showCustomIcon) {
    iconProps = {
      incIcon: collapseIcon,
      decIcon: expandIcon,
    };
  }
  if (allowedDecimalDigits) {
    validationProps.modifyValueOnChange = formatNumberMaxTwoDecimalOnChange;
  }

  return (
    <ReactNumberInput
      {...modifiedProps}
      {...validationProps}
      {...iconProps}
    />
  );
};

export const DateInput = (props) => {
  const modifiedProps = modifyInputProps(props);
  return (
    <DatePicker
      format="DD/MM/YYYY"
      zeroTimeDate
      calendarStyle={dateInputStyle.dateCalendarStyle}
      floatLabelOnActive={Platform.OS === 'web'}
      {...modifiedProps}
    />
  );
};

export const TimeInput = (props) => {
  const modifiedProps = modifyInputProps(props);
  return (
    <ReactTimeInput
      renderSeparator={
        <View style={{ width: 10, backgroundColor: 'transparent' }} />
      }
      {...modifiedProps}
    />
  );
};

export const ProgressSliderInput = (props) => {
  const title = ({ value } = {}) => {
    let progressTitle = '';
    if (!value || value < 50) {
      progressTitle = 'Progress (just started)';
    } else if (value < 80) {
      progressTitle = 'Progress (Halfway there)';
    } else {
      progressTitle = 'Progress (Almost there)';
    }
    return progressTitle;
  };
  return <Slider {...progressSliderTheme} title={title} {...props} />;
};

export const FileInput = (props) => {
  const componentProps = {
    upload, getDownloadUrl, openInNewTab: true, ...props,
  };
  const theme = getFileTheme(props);
  return (
    <ReactFileInput
      {...theme}
      renderInputError={renderInputError}
      // renderInputComponent={FileInputRender}
      {...componentProps}
    />
  );
};

export const DownloadFile = (props) => {
  const componentProps = {
    upload,
    getDownloadUrl,
    editable: false,
    openInNewTab: true,
    ...props,
  };
  return <ReactFileInput theme={downloadFileBoxTheme} {...componentProps} />;
};

export const CheckBox = (props) => {
  const modifiedProps = modifyInputProps(props);
  return <ReactCheckBox {...checkBoxTheme} {...modifiedProps} />;
};
export const CheckBoxGroup = (props) => <ReactCheckBoxGroup {...checkBoxGroupTheme} {...props} />;

export const RadioGroupInput = (props) => (
  <RadioGroup {...radioGroupTheme} {...props} />
);

export const RadioInput = (props) => <Radio {...radioTheme} {...props} />;

export const ToggleInput = (props) => <ToggleButton {...inputToggleTheme} {...props} />;

export const ToggleSwitchInput = (props) => {
  const { onValueLabel = 'Active', offValueLabel = 'Inactive' } = props;
  return (
    <ToggleSwitch
      {...inputToggleSwitchTheme}
      showOuterLabel
      onValue={{ label: onValueLabel, value: true }}
      offValue={{ label: offValueLabel, value: false }}
      {...props}
    />
  );
};

export const AutoSuggestInput = (props) => {
  const modifiedProps = modifyInputProps(props);
  const extraProps = getAutoSuggestProps(modifiedProps);
  const { renderRowProps, showAvatar, ...restProps } = modifiedProps;
  return (
    <AutosuggestInputField
      {...autoSuggestInputTheme}
      {...restProps}
      {...extraProps}
    />
  );
};

export const AutoSuggestAvatarInput = (props) => <AutoSuggestInput showAvatar {...props} />;

export const SearchInput = (props) => <AutoSuggestInput searching {...props} />;

export const SearchAvatarInput = (props) => <AutoSuggestAvatarInput searching {...props} />;

export const MultiAutoSuggestInput = (props) => {
  const modifiedProps = modifyInputProps(props);
  const extraProps = getAutoSuggestProps(modifiedProps);
  const { renderRowProps, showAvatar, ...restProps } = modifiedProps;
  return (
    <MultiAutosuggestInputField
      {...(isMobile
        ? multiAutosuggestMobileInputTheme
        : multiAutosuggestInputTheme)}
      chipsVariant="rounded"
      {...restProps}
      {...extraProps}
    />
  );
};

export const MultiAutoSuggestAvatarInput = (props) => {
  const { renderRowProps } = props;
  const multiAutosuggestTheme = isMobile
    ? multiAutosuggestMobileInputTheme
    : multiAutosuggestInputTheme;
  return (
    <MultiAutoSuggestInput
      showAvatar
      renderChips={(props) => renderAvatarChips({
        ...props,
        ...multiAutosuggestTheme,
        ...renderRowProps,
      })}
      {...props}
    />
  );
};

export const MultiSearchInput = (props) => <MultiAutoSuggestInput searching {...props} />;

export const MultiSearchAvatarInput = (props) => <MultiAutoSuggestAvatarInput searching {...props} />;

export const GooglePlaceInput = (props) => {
  const modifiedProps = modifyInputProps(props);
  const extraProps = getAutoSuggestProps(modifiedProps);
  const { renderRowProps, ...restProps } = modifiedProps;
  return (
    <GooglePlaceInputField
      {...autoSuggestInputTheme}
      minChar={3}
      searching
      {...restProps}
      {...extraProps}
    />
  );
};
export const MultiTextInput = (props) => {
  const modifiedProps = modifyInputProps(props);
  let chipCrossIcon = backCrossIcon;
  const { removeChipIcon, chipsVariant = 'rounded' } = props;
  if (removeChipIcon) {
    chipCrossIcon = removeChipIcon;
  }

  return (
    <MultiTextInputField
      removeChipIcon={chipCrossIcon}
      floatLabelOnValue={false}
      chipsVariant={chipsVariant}
      {...multiTextInputTheme}
      {...modifiedProps}
    />
  );
};

export const RichTextInput = (props) => {
  const modifiedProps = modifyInputProps(props);
  return <ReactRichTextInput {...modifiedProps} />;
};

export const PasswordInput = (props) => {
  const modifiedProps = modifyInputProps(props);
  return (
    <ReactPasswordInput
      showIcon={showIcon}
      hideIcon={showIcon}
      {...modifiedProps}
    />
  );
};

export const ChipsToggleInput = (props) => <ToggleButton {...inputChipsToggleTheme} {...props} />;

export const consultationStatusInput = (props) => {
  const { label, editable } = props;
  return (
    <ConsultationStatusCell
      label={label}
      editable={editable}
      value={props?.data[props.field]}
      containerStyles={{
        marginTop: 8,
      }}
      {...props}
    />
  );
};

export const GooglePlaceMapInput = (props) => {
  const { value, mapHeight } = props;
  const { latitude, longitude } = value || {};

  return (
    <>
      <GooglePlaceInput {...props} />
      <GoogleMap
        latitude={latitude}
        longitude={longitude}
        mapHeight={mapHeight}
      />
    </>
  );
};

export const UploadImage = (props) => {
  const componentProps = {
    upload,
    getDownloadUrl,
    openInNewTab: true,
    ...props,
  };
  const {
    data,
    setValue,
    field,
    defaultImage = profile_preview,
    default_NonEditable = profileNonEditable,
    description,
    imageWidth = 200,
    imageHeight = 200,
    imageOnly = true,
    editable,
    nonEditable,
  } = props;
  const image = (data && field && data[field]) || {};
  const ImageUrl = image && getImageUrl(image);
  if (editable === false) {
    if (nonEditable === true) {
      return (
        <Image
          source={ImageUrl || default_NonEditable}
          style={{ width: imageWidth, height: imageHeight }}
        />
      );
    }
    return (
      <Image
        source={ImageUrl || defaultImage}
        style={{ width: imageWidth, height: imageHeight }}
      />
    );
  }
  return (
    <View style={{ justifyContent: 'center', alignItems: 'center' }}>
      {ImageUrl ? (
        <View>
          <Image
            source={ImageUrl}
            style={{ width: imageWidth, height: imageHeight, borderRadius: 5 }}
          />
          <View
            style={{
              flexDirection: 'row',
              padding: 10,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ReactFileInput
              hideLink
              data={data}
              field={field}
              imageOnly={imageOnly}
              setValue={setValue}
              {...UploadImageTheme}
              renderInputComponent={(
                <TouchableOpacity style={{ cursor: 'pointer' }}>
                  <Text style={{ color: '#40c1de', ...h13_Medium }}>
                    Replace
                  </Text>
                </TouchableOpacity>
              )}
              {...componentProps}
            />
            <View
              style={{
                width: 2,
                height: 10,
                backgroundColor: 'rgba(154, 154, 154, 0.34)',
                marginLeft: 10,
                marginRight: 10,
              }}
            />
            <TouchableOpacity
              style={{ cursor: 'pointer' }}
              onPress={() => {
                setValue({ value: null, field });
              }}
            >
              <Text style={{ color: 'rgba(241, 79, 79, 0.7)', ...h13_Medium }}>
                Remove
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      ) : (
        <ReactFileInput
          data={data}
          imageOnly
          field={field}
          setValue={setValue}
          hideLink
          theme={fileInputBoxTheme}
          renderInputComponent={(
            <TouchableOpacity style={{ cursor: 'pointer' }}>
              <Image
                source={defaultImage}
                style={{ width: imageWidth, height: imageHeight }}
              />
            </TouchableOpacity>
          )}
          {...componentProps}
        />
      )}
      {description ? (
        <Text
          style={{
            color: 'rgba(56, 56, 56, 0.4)',
            ...h13_Medium,
            marginTop: 12,
          }}
        >
          {description}
        </Text>
      ) : (
        void 0
      )}
    </View>
  );
};
