import React from 'react';
import {View, BasicTextInput} from '@applane/react-core-components';
import {ActivityIndicator} from '@applane/react-activity-indicator';
import {
  getInputProps,
  WithInputError,
  WithFloatingLabel,
} from '@applane/react-input-wrapper';

class AutoSuggestInputEditor extends React.Component {
  renderLoading = () => {
    const {loadingStyle} = this.props;
    let {viewStyle, activityIndicatorProps} = loadingStyle || {};
    return (
      <React.Fragment>
        {viewStyle ? (
          <View style={viewStyle}>
            <ActivityIndicator {...activityIndicatorProps} />
          </View>
        ) : (
          <ActivityIndicator size="small" />
        )}
      </React.Fragment>
    );
  };

  render() {
    let {
      imageComponent,
      renderButtonText,
      renderLoading,
      hideLoader,
      ...restProps
    } = this.props;
    let {displayValue, loading} = restProps;
    let {onChange, onChangeValue, ...restInputProps} = getInputProps({
      ...restProps,
      value: displayValue,
    });
    let textComponent = null;
    if (renderButtonText) {
      textComponent = renderButtonText(restProps);
    } else {
      textComponent = <BasicTextInput {...restInputProps} />;
    }
    return (
      <View style={{flexDirection: 'row'}}>
        {imageComponent ? imageComponent(restProps) : void 0}
        <View style={{flex: 1, overflow: 'hidden'}}>
          {textComponent}
          {loading && !renderLoading && !hideLoader
            ? this.renderLoading()
            : void 0}
        </View>
      </View>
    );
  }
}

export default WithInputError(WithFloatingLabel(AutoSuggestInputEditor));
