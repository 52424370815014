import React from 'react';
import {getInputTheme} from './themeUtility';
import {errorIcon, errorFilledIcon} from '../../images';
import {View, Text, Image} from '@applane/react-core-components';

export const removeAllSpacesOnChange = ({value}) => {
  if (value && typeof value === 'string') {
    value = value.split(' ').join('');
  }
  return value;
};
export const trimLeftValueOnChange = ({value}) => {
  if (value && typeof value === 'string') {
    value = value.trimLeft();
  }
  return value;
};
export const trimValueOnBlur = ({value}) => {
  if (value && typeof value === 'string') {
    value = value.trim();
  }
  return value;
};
export const formatNumberMaxTwoDecimalOnChange = ({prevValue, value}) => {
  if (value) {
    if (typeof value == 'string') {
      value = Number(value);
    }
    if (!isNaN(value)) {
      let strValue = value.toString();
      let splitValue = strValue.split('.');
      if (splitValue.length > 1) {
        if (splitValue[1].length > 2) {
          value = prevValue;
        }
      }
    }
  }
  return value;
};

export const renderInputError = ({error, inputErrorStyle}) => {
  let icon = errorIcon;
  let errorText = error;
  if (Array.isArray(error)) {
    icon = errorFilledIcon;
    errorText = error.join('; ');
  } else if (typeof errorText === 'object') {
    errorText = error.shortMessage || error.message || JSON.stringify(error);
  }
  return (
    <View title={errorText} style={inputErrorStyle}>
      <Image source={icon} />
    </View>
  );
};

export const modifyInputProps = (props = {}) => {
  let {skipVariant} = props;
  let {variant, skipInputError, ...theme} = getInputTheme(props);
  return {
    ...theme,
    renderInputError: skipInputError ? void 0 : renderInputError,
    ...props,
    variant: skipVariant ? false : variant,
  };
};
