import { colors, bgs } from './colors';
import { h13_Regular } from './fonts';
import vars from './vars';

const { textWhite, lightGrey, textColor20 } = colors;
const { lightGrayColor } = bgs;
let placeholderFont = void 0;
if (window.innerWidth < 800) {
  if (window.innerWidth < 480) {
    placeholderFont = h13_Regular;
  }
}
const ftsThemeMD = {
  searchContainerStyle: {
    flexDirection: 'row',
    paddingRight: 15,
    paddingLeft: 15,
    alignItems: 'center',
    borderRadius: 4,
    width: 294,
    height: 38,
    backgroundColor: vars.colors.primary.color5,
    borderColor: textColor20,
    marginRight: 5,
  },
  inputStyle: {
    height: 38,
    flex: 1,
    outline: 'none',
    borderWidth: 0,
    backgroundColor: vars.colors.primary.color5,
  },
  searchImageStyle: {
    opacity: 0.5,
    width: 17,
    height: 17,
  },
  crossImageStyle: {
    width: 20,
    height: 20,
  },
};

const ftsThemeSM = {
  searchContainerStyle: {
    flexDirection: 'row',
    paddingRight: 10,
    paddingLeft: 5,
    alignItems: 'center',
    backgroundColor: lightGrayColor,
    marginRight: 5,
    paddingTop: 3,
    paddingBottom: 3,
    marginTop: 14,
  },
  inputStyle: {
    ...placeholderFont,
    height: 30,
    paddingLeft: 10,
    outline: 'none',
    border: `0px solid ${textWhite}`,
    backgroundColor: lightGrayColor,
  },
  searchImageStyle: {
    opacity: 0.5,
    width: 17,
    height: 17,
  },
};

export { ftsThemeSM, ftsThemeMD };
