import { Image, TouchableOpacity } from '@applane/react-core-components';
import React from 'react';
import { collapseIcon, expandIcon } from '../../images';
import {
  compactFormGroupStyle,
  expandIconStyle,
  expandStyle,
  formGroupHeaderStyle,
  formGroupMobileStyle,
  formGroupStyle,
  nestedFormGroupStyle,
  nonEditableformGroupHeaderStyle,
  nonEditableFormGroupStyle,
  sectionFormGroupStyle,
} from '../../theme/formTheme';

import {
  FormGroupHeader as ReactFormGroupHeader,
  FormGroup as ReactFormGroup,
} from '@applane/react-form-groups-v1';
import { FormField } from './field';
import { isMobile, getRenderComponent } from '../UtilityFunctions';

const resolveVisibleValue = (visible = true, props) => {
  const { resolveVisible, ...restProps } = props;
  if (typeof visible === 'function') {
    if (resolveVisible) {
      return resolveVisible({
        ...restProps,
        visible,
      });
    } else {
      visible = visible(restProps);
    }
  }
  return visible;
};

const renderFormGroupHeader = (props) => {
  let { type, formType, editable, ...restProps } = props;
  if (type === undefined && editable === false) {
    type = 'nonEditableFormGroup';
  }
  type = type || formType;
  let formGroupHeaderTheme = formGroupHeaderStyle;
  if (type === 'nonEditableFormGroup') {
    formGroupHeaderTheme = nonEditableformGroupHeaderStyle;
  }
  return <ReactFormGroupHeader {...formGroupHeaderTheme} {...restProps} />;
};

export const renderFormGroup = (props) => {
  let { type, formType, ...restProps } = props;
  if (type === undefined && restProps.editable === false) {
    type = 'nonEditableFormGroup';
  }
  type = type || formType;
  if (type === 'custom') {
    return <CustomFormGroup {...restProps} />;
  } else {
    let formGroupTheme = isMobile ? formGroupMobileStyle : formGroupStyle;
    if (type === 'nonEditableFormGroup') {
      formGroupTheme = nonEditableFormGroupStyle;
    } else if (type === 'section') {
      formGroupTheme = sectionFormGroupStyle;
    } else if (type === 'nested') {
      formGroupTheme = nestedFormGroupStyle;
    } else if (type === 'compact') {
      formGroupTheme = compactFormGroupStyle;
    }
    return <FormGroup {...formGroupTheme} {...restProps} />;
  }
};

export class CustomFormGroup extends React.Component {
  render() {
    const { visible, render, ...restProps } = this.props;
    if (!resolveVisibleValue(visible, restProps)) {
      return null;
    }
    return getRenderComponent(render, restProps) || null;
  }
}

export default class FormGroup extends React.Component {
  renderColumn = (column) => {
    const {
      navigation,
      eventDispatcher,
      fieldVariant,
      fieldContainer,
      editable: fieldEditable = true,
    } = this.props;
    let style = { flex: 1, overflow: 'hidden' };
    let component = void 0;
    if (React.isValidElement(column)) {
      component = column;
    } else {
      const {
        field,
        render,
        width,
        maxWidth,
        minWidth,
        visible,
        style: columnStyle,
        container = fieldContainer,
        editable = fieldEditable,
        variant = fieldVariant,
        ...rest
      } = column;
      if (!resolveVisibleValue(visible, this.props)) {
        return null;
      }
      if (columnStyle) {
        style = columnStyle;
      }
      if (width) {
        style = { width, ...columnStyle };
      } else {
        if (maxWidth) {
          style.maxWidth = maxWidth;
        }
        if (minWidth) {
          style.minWidth = minWidth;
        }
      }
      const activeMQ =
        navigation && navigation.getActiveMQ && navigation.getActiveMQ();
      const formFieldProps = {
        activeMQ,
        navigation,
        eventDispatcher,
        field,
        render,
        editable,
        variant,
        container,
        ...rest,
      };
      component = <FormField {...formFieldProps} />;
    }
    return {
      style,
      component,
    };
  };

  renderHeader = (headerProps) => {
    const { type, formType, editable } = this.props;
    return renderFormGroupHeader({ type, formType, editable, ...headerProps });
  };

  renderExpandComponent = (props) => {
    const { expanded, onClick } = props;
    return (
      <TouchableOpacity onPress={onClick} {...expandStyle}>
        <Image
          source={expanded ? collapseIcon : expandIcon}
          {...expandIconStyle}
        />
      </TouchableOpacity>
    );
  };

  render() {
    let { visible, label, ...restProps } = this.props;
    if (!resolveVisibleValue(visible, restProps)) {
      return null;
    }
    if (label && typeof label === 'function') {
      label = label(restProps);
    }
    return (
      <ReactFormGroup
        {...restProps}
        label={label}
        renderHeader={this.renderHeader}
        expandComponent={this.renderExpandComponent}
        renderColumn={this.renderColumn}
      />
    );
  }
}
