import { manazeV6Colors } from './manazeV6Colors';
import vars from './vars';

const progressIndicatorStyle = {
  containerStyle: {
    height: 3,
    backgroundColor: 'transparent',
    overflow: 'hidden',
    left: 0,
    right: 0,
    position: 'absolute',
    zIndex: 1,
  },
  animationStyle: {
    backgroundColor: vars.colors.secondary.color2,
    width: 100,
    height: 3,
    animationName: 'horizontalStatusBar',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-out',
  },
};

const activityIndicatorStyle = {
  color: vars.colors.secondary.color2,
};
export { progressIndicatorStyle, activityIndicatorStyle };
