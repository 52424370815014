import React from 'react';
import {View, BasicTextInput} from '@applane/react-core-components';
import {getFormatValue, typecast} from './DateUtility';
import {
  WithFloatingLabel,
  WithInputWrapper,
  WithVariant,
  WithInputError,
  getInputProps,
} from '@applane/react-input-wrapper';
import DateSelector from './DateSelector';

class DateInputEditor extends React.Component {
  render() {
    let {onChange, onChangeText, ...restInputProps} = getInputProps(this.props);
    return <BasicTextInput {...restInputProps} />;
  }
}

DateInputEditor = WithInputError(WithFloatingLabel(DateInputEditor));

class DateInput extends React.Component {
  state = {};

  setStateValue = value => {
    this.setState({
      value,
    });
  };

  onChangeValue = (value, e) => {
    let {onChangeText, onChangeValue} = this.props;
    if (typeof value === 'string') {
      value = value.trim();
    }
    let typecastDate = this.props.typecast || typecast;
    if (typecastDate) {
      value = typecastDate(value, this.props);
    } else {
      value = value ? new Date(value) : null;
      if (value === 'Invalid Date') {
        value = null;
      }
    }
    if (onChangeText) {
      onChangeText(value);
    }
    onChangeValue && onChangeValue(value, e);
  };

  onDateChange = (value, e) => {
    let {onBlur} = this.props;
    this.onChangeValue(value, e);
    onBlur && onBlur(e);
  };

  onBlur = e => {
    let {onBlur} = this.props;
    let {value} = this.state;
    if (value !== undefined) {
      this.onChangeValue(value, e);
      this.setStateValue(void 0);
    }
    onBlur && onBlur(e);
  };

  render() {
    let {mode = 'date', modalProps, ...rest} = this.props;
    let {value} = rest;
    if (value === undefined || value === null) {
      value = '';
    }
    const {value: stateValue} = this.state;
    let formattedValue = getFormatValue(value, rest);
    return (
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <DateInputEditor
            {...this.props}
            value={stateValue !== undefined ? stateValue : formattedValue}
            onBlur={this.onBlur}
            onChangeValue={this.setStateValue}
          />
        </View>
        <DateSelector
          {...this.props}
          value={formattedValue}
          onDateChange={this.onDateChange}
          mode={mode}
          modalProps={{
            position: 'left',
            ...modalProps,
          }}
        />
      </View>
    );
  }
}

DateInput = WithInputWrapper(WithVariant(DateInput));

export default DateInput;
