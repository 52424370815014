import React from "react";

export const matchValue = (value1, value2, fieldToMatch) => {
  if (
    value1 === undefined ||
    value1 === null ||
    value2 === undefined ||
    value2 === null
  ) {
    return;
  }
  if (
    fieldToMatch &&
    value1[fieldToMatch] !== undefined &&
    value1[fieldToMatch] !== null &&
    value2[fieldToMatch] !== undefined &&
    value2[fieldToMatch] !== null
  ) {
    return (
      resolveDottedValue(value1, fieldToMatch) ===
      resolveDottedValue(value2, fieldToMatch)
    );
  } else {
    return value1 === value2;
  }
};

export const findValue = ({ value, item, idField }) => {
  if (
    value === undefined ||
    value === null ||
    item === undefined ||
    item === null
  ) {
    return;
  }
  if (Array.isArray(value)) {
    for (let row of value) {
      let matched = matchValue(row, item, idField);
      if (matched) {
        return true;
      }
    }
  } else {
    return matchValue(value, item, idField);
  }
};

export const findIndex = ({ value, item, idField }) => {
  if (
    value === undefined ||
    value === null ||
    item === undefined ||
    item === null
  ) {
    return;
  }
  if (Array.isArray(value)) {
    return value.findIndex((row) => {
      return matchValue(row, item, idField);
    });
  }
};

export const getOptionValue = ({ item, keyField = "value" }) => {
  if (item === undefined || item === null) {
    return item;
  }
  return typeof item === "object" &&
    item[keyField] !== undefined &&
    item[keyField] !== null
    ? item[keyField]
    : item;
};

export const getOptionDisplay = ({ item, keyField, valueField }) => {
  if (item === undefined || item === null) {
    return item;
  }
  return (
    (typeof item === "object" && valueField && item[valueField]) ||
    getOptionValue({ item, keyField })
  );
};

export const isSelected = ({ value, item, options, idField, keyField }) => {
  if (options) {
    item = getOptionValue({ item, keyField });
  } else {
    idField = idField || "_id";
  }
  return findValue({ value, item, idField });
};

export const resolveDottedValue = (data, expression, confirm, confirmType) => {
  if (!data) {
    return;
  }
  while (expression !== undefined) {
    var fieldIndex = expression.indexOf(".");
    var exp = expression;
    if (fieldIndex >= 0) {
      exp = expression.substring(0, fieldIndex);
      expression = expression.substring(fieldIndex + 1);
    } else {
      expression = undefined;
    }

    if ((data[exp] === undefined || data[exp] === null) && !confirm) {
      return;
    }
    if (data[exp] !== undefined && data[exp] !== null) {
      data = data[exp];
    } else {
      if (expression) {
        data[exp] = {};
      } else {
        if (confirmType === "array") {
          data[exp] = [];
        } else {
          data[exp] = {};
        }
      }
      data = data[exp];
    }
  }
  return data;
};

export const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === "function") {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};
