import React from 'react';
import {View, Image, TouchableOpacity} from '@applane/react-core-components';
import SelectDate from './SelectDate';
import {WithModal} from '@applane/react-with-modal';

export default class DateCalendar extends React.Component {
  onChange = value => {
    let {onDateChange} = this.props;
    onDateChange && onDateChange(value);
  };

  render() {
    let {
      modalProps,
      disabled,
      mode,
      backdropStyle,
      dropdownStyle,
      pushModal = true,
      iconContainerStyle,
      iconStyle,
      modalStyle,
      calendarIconStyle,
      timeCalendarIconStyle,
      calendarStyle = {},
    } = this.props || {};

    let ValueSelector = mode === 'time' ? null : SelectDate;

    const renderModal = ({frameStyle, hideModal}) => {
      return (
        <TouchableOpacity
          style={{...frameStyle, ...modalStyle}}
          onPress={e => {
            e.stopPropagation();
          }}>
          <ValueSelector
            {...this.props}
            theme={calendarStyle}
            mode={mode}
            hideModal={hideModal}
            onChange={this.onChange}
          />
        </TouchableOpacity>
      );
    };

    let {image: selectorIcon, viewStyle, imageStyle} =
      (mode == 'time' ? timeCalendarIconStyle : calendarIconStyle) || {};
    let selectorComponent = (
      <Image style={imageStyle} source={selectorIcon}></Image>
    );
    if (!disabled && ValueSelector) {
      selectorComponent = (
        <WithModal
          style={iconStyle || viewStyle}
          backdropStyle={backdropStyle}
          renderModal={renderModal}
          autoHide={true}
          dropdownStyle={dropdownStyle || {height: 230, width: 230}}
          pushModal={pushModal}
          {...modalProps}>
          {selectorComponent}
        </WithModal>
      );
    }
    return <View style={iconContainerStyle}>{selectorComponent}</View>;
  }
}
