import React from 'react';
import {
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  StatusBarManager,
  View,
} from '@applane/react-core-components';
import {withModalContext} from '@applane/react-modal-view';

let STATUS_BAR_HEIGHT = 0;

StatusBarManager.getHeight(statusBarHeight => {
  STATUS_BAR_HEIGHT = statusBarHeight && statusBarHeight.height;
});

class ModalView extends React.Component {
  componentDidMount() {
    let {setModal, onModalShow, closeModal} = this.props;
    if (!setModal || !setModal.pushModal) {
      return;
    }
    onModalShow && onModalShow(true);
    setModal.pushModal({
      renderModal: this.getModal,
      showModal: true,
      closeModal,
    });
  }

  componentWillUnmount() {
    let {setModal, onModalShow} = this.props;
    if (!setModal) {
      return;
    }
    onModalShow && onModalShow(false);
    setModal.popModal && setModal.popModal();
  }

  _calcPosition({style}) {
    let {parentFrame, dropdownStyle, adjustFrame, position} = this.props;
    let positionStyle = {};
    if (parentFrame) {
      const dimensions = Dimensions.get('window');
      const windowWidth = dimensions.width;
      let windowHeight = dimensions.height;
      if (dropdownStyle) {
        dropdownStyle = StyleSheet.flatten(dropdownStyle);
      }
      if (style) {
        style = StyleSheet.flatten(style);
      }
      let marginBottom =
        (dropdownStyle && dropdownStyle.marginBottom) ||
        (style && style.marginBottom) ||
        0;
      if (!marginBottom) {
        marginBottom =
          (dropdownStyle && dropdownStyle.margin) ||
          (style && style.margin) ||
          0;
      }
      let marginTop =
        (dropdownStyle && dropdownStyle.marginTop) ||
        (style && style.marginTop) ||
        0;
      if (!marginTop) {
        marginTop =
          (dropdownStyle && dropdownStyle.margin) ||
          (style && style.margin) ||
          0;
      }
      let topBottomMargin = marginTop + marginBottom;

      const dropdownHeight =
        (dropdownStyle && dropdownStyle.height) || (style && style.height) || 0;
      //check whether modal should open in top or bottom
      let availableBottomSpace =
        windowHeight - parentFrame.y - parentFrame.h - STATUS_BAR_HEIGHT;
      let availabelTopSpace =
        parentFrame.y - STATUS_BAR_HEIGHT - topBottomMargin;

      let showInBottom =
        dropdownHeight <= availableBottomSpace ||
        availableBottomSpace >= availabelTopSpace;
      if (
        showInBottom &&
        position === 'top' &&
        dropdownHeight &&
        dropdownHeight <= availabelTopSpace
      ) {
        showInBottom = false;
      }

      let modalHeight = 0;
      let modalTopPosition = 0;
      let modalBottomPosition = 0;
      //here we decide the modal height and modal top position
      if (showInBottom) {
        modalHeight =
          dropdownHeight <= availableBottomSpace
            ? dropdownHeight
            : availableBottomSpace;
        modalTopPosition = parentFrame.y + parentFrame.h;
      } else {
        //check if  space is sufficient for default given height or not
        modalHeight =
          dropdownHeight <= availabelTopSpace
            ? dropdownHeight
            : availabelTopSpace;
        modalBottomPosition = windowHeight - parentFrame.y;
      }
      const dropdownWidth =
        (dropdownStyle && dropdownStyle.width) ||
        (style && style.width) ||
        parentFrame.w;
      positionStyle = {
        position: 'absolute',
      };

      positionStyle.width = dropdownWidth;
      if (modalHeight !== undefined) {
        positionStyle.height = modalHeight;
      }
      if (modalTopPosition) {
        positionStyle.top = modalTopPosition;
      }
      if (modalBottomPosition) {
        positionStyle.bottom = modalBottomPosition;
      }

      const rightSpace = windowWidth - parentFrame.x;
      let showInRight = rightSpace >= dropdownWidth;
      if (showInRight && position === 'left' && dropdownWidth < parentFrame.x) {
        showInRight = false;
      }

      if (showInRight) {
        positionStyle.left = parentFrame.x;
      } else {
        const dropdownWidth =
          (dropdownStyle && dropdownStyle.width) ||
          (style && style.width) ||
          -1;
        if (dropdownWidth !== -1) {
          positionStyle.width = dropdownWidth;
        }
        positionStyle.right = rightSpace - parentFrame.w;
      }
    }
    return adjustFrame ? adjustFrame(positionStyle, {}) : positionStyle;
  }

  getModal = () => {
    let {
      index,
      autoHide,
      display,
      closeModal,
      backdropStyle,
      backdropColor,
      noBackdrop,
      children,
      position,
      height,
      width,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      backgroundColor,
      style = {},
    } = this.props;
    if (position && this.props[`${position}Style`]) {
      style = this.props[`${position}Style`];
    }
    style = {
      ...style,
    };
    if (height !== undefined) {
      style.height = height;
      delete style.flex;
    }
    if (width !== undefined) {
      style.width = width;
    }
    if (maxHeight !== undefined) {
      style.maxHeight = maxHeight;
    }
    if (maxWidth !== undefined) {
      style.maxWidth = maxWidth;
    }
    if (minHeight !== undefined) {
      style.minHeight = minHeight;
    }
    if (minWidth !== undefined) {
      style.minWidth = minWidth;
    }
    if (backgroundColor !== undefined) {
      style.backgroundColor = backgroundColor;
    }
    let backDropAddOnStyle = {};
    let frameStyle = {};
    if (position === 'fullScreen') {
      frameStyle.width = '100%';
      frameStyle.height = '100%';
    } else if (position === 'screenTop') {
      backDropAddOnStyle = {
        justifyContent: 'flex-start',
      };
      frameStyle.width = '100%';
    } else if (position === 'screenBottom') {
      backDropAddOnStyle = {
        justifyContent: 'flex-end',
      };
      frameStyle.width = '100%';
    } else if (position === 'screenLeft') {
      backDropAddOnStyle = {
        alignItems: 'flex-start',
      };
      frameStyle.height = '100%';
    } else if (position === 'screenRight') {
      backDropAddOnStyle = {
        alignItems: 'flex-end',
      };
      frameStyle.height = '100%';
    } else if (position === 'screenCenter') {
      backDropAddOnStyle = {
        justifyContent: 'center',
        alignItems: 'center',
      };
    } else {
      frameStyle = this._calcPosition({style});
    }
    if (noBackdrop) {
      backDropAddOnStyle.backgroundColor = 'transparent';
    } else if (backdropColor) {
      backDropAddOnStyle.backgroundColor = backdropColor;
    }
    if (display) {
      backDropAddOnStyle.display = display;
    }
    let RenderComponent = autoHide ? TouchableOpacity : View;
    return (
      <RenderComponent
        key={`modalViewBackdrop_${index}`}
        activeOpacity={1}
        style={{
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          position: 'absolute',
          backgroundColor: 'transparent',
          ...backdropStyle,
          ...backDropAddOnStyle,
        }}
        onPress={
          autoHide
            ? e => {
                closeModal && closeModal();
              }
            : void 0
        }>
        <RenderComponent
          key={`modalViewBody_${index}`}
          onPress={autoHide ? e => {} : void 0}
          activeOpacity={1}
          style={{
            overflow: 'hidden',
            backgroundColor: '#fff',
            ...frameStyle,
            ...style,
          }}>
          {children}
        </RenderComponent>
      </RenderComponent>
    );
  };

  render() {
    return null;
  }
}
ModalView = withModalContext(ModalView);
export default ModalView;
