import React from 'react';
import {
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  StatusBarManager,
  Platform,
} from '@applane/react-core-components';
import {withAppKeyboardListenerContext} from '@applane/react-app-keyboard-listener';
import {withModalContext} from '@applane/react-modal-view';

let STATUS_BAR_HEIGHT = 0;

StatusBarManager.getHeight(statusBarHeight => {
  STATUS_BAR_HEIGHT = statusBarHeight && statusBarHeight.height;
});

class WithModal extends React.Component {
  state = {};

  _updatePosition = callback => {
    if (this._button && this._button.measure) {
      this._button.measure((fx, fy, width, height, px, py) => {
        this._buttonFrame = {x: px, y: py, w: width, h: height};
        callback && callback();
      });
    }
  };

  onLongPress = () => {
    if (!this.props.renderModalOnLongPress) {
      return;
    }
    this.showModal({longPressed: true});
  };

  onPress = () => {
    if (!this.props.renderModal) {
      return;
    }
    this.showModal();
  };

  showModal = props => {
    this._updatePosition(() => {
      this.show(props);
    });
  };

  show = ({longPressed} = {}) => {
    if (!this.setModal) {
      return;
    }
    const renderModal = longPressed
      ? this.renderModalOnLongPress
      : this.renderModal;
    this.props.onModalShow && this.props.onModalShow(true);
    if (this.props.pushModal) {
      // For open second popup in first popup, case second level nested table
      this.setModal.pushModal &&
        this.setModal.pushModal({
          renderModal,
          showModal: true,
          closeModal: this.hide,
        });
    } else {
      this.setModal.setModalState &&
        this.setModal.setModalState({
          renderModal,
          showModal: true,
          closeModal: this.hide,
        });
    }
  };

  hide = () => {
    if (!this.setModal) {
      return;
    }
    this.props.onModalShow && this.props.onModalShow(false);
    if (this.props.pushModal) {
      this.setModal.popModal &&
        this.setModal.popModal({renderModal: null, showModal: false});
    } else {
      this.setModal.setModalState &&
        this.setModal.setModalState({renderModal: null, showModal: false});
    }
  };

  componentDidUpdate() {
    if (this.props.updateStateRequired) {
      // For render data in case of change in parent, case second level nested table
      this.setModal && this.setModal.updateState && this.setModal.updateState();
    }
  }

  componentDidMount() {
    this._updatePosition();
  }

  _renderModal = ({renderModal} = {}) => {
    let {
      autoHide,
      backdropStyle,
      noBackdrop,
      backdropColor,
      position,
    } = this.props;
    if (!this._buttonFrame || !renderModal) {
      return;
    }
    let backDropAddOnStyle = void 0;
    let frameStyle = {};
    if (position === 'fullScreen') {
      frameStyle.width = '100%';
      frameStyle.height = '100%';
    } else if (position === 'screenTop') {
      backDropAddOnStyle = {
        justifyContent: 'flex-start',
      };
      frameStyle.width = '100%';
    } else if (position === 'screenBottom') {
      backDropAddOnStyle = {
        justifyContent: 'flex-end',
      };
      frameStyle.width = '100%';
    } else if (position === 'screenLeft') {
      backDropAddOnStyle = {
        alignItems: 'flex-start',
      };
      frameStyle.height = '100%';
    } else if (position === 'screenRight') {
      backDropAddOnStyle = {
        alignItems: 'flex-end',
      };
      frameStyle.height = '100%';
    } else if (position === 'screenCenter') {
      backDropAddOnStyle = {
        justifyContent: 'center',
        alignItems: 'center',
      };
    } else {
      frameStyle = this._calcPosition();
    }

    if (noBackdrop) {
      backDropAddOnStyle.backgroundColor = 'transparent';
    } else if (backdropColor) {
      backDropAddOnStyle.backgroundColor = backdropColor;
    }

    return (
      <TouchableOpacity
        activeOpacity={1}
        style={{
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          position: 'absolute',
          backgroundColor: 'transparent',
          ...backdropStyle,
          ...backDropAddOnStyle,
        }}
        onPress={autoHide ? this.hide : void 0}>
        {renderModal({frameStyle, hideModal: this.hide})}
      </TouchableOpacity>
    );
  };

  renderModal = () => {
    const {renderModal} = this.props;
    return this._renderModal({renderModal});
  };

  renderModalOnLongPress = () => {
    const {renderModalOnLongPress} = this.props;
    return this._renderModal({renderModal: renderModalOnLongPress});
  };

  _calcPosition() {
    let {dropdownStyle, style, adjustFrame, position} = this.props;
    const {keyboardHeight} = this.state;
    const dimensions = Dimensions.get('window');
    const windowWidth = dimensions.width;
    let windowHeight = dimensions.height;
    if (keyboardHeight) {
      windowHeight -= keyboardHeight;
    }
    if (dropdownStyle) {
      dropdownStyle = StyleSheet.flatten(dropdownStyle);
    }
    if (style) {
      style = StyleSheet.flatten(style);
    }
    let marginBottom =
      (dropdownStyle && dropdownStyle.marginBottom) ||
      (style && style.marginBottom) ||
      0;
    if (!marginBottom) {
      marginBottom =
        (dropdownStyle && dropdownStyle.margin) || (style && style.margin) || 0;
    }
    let marginTop =
      (dropdownStyle && dropdownStyle.marginTop) ||
      (style && style.marginTop) ||
      0;
    if (!marginTop) {
      marginTop =
        (dropdownStyle && dropdownStyle.margin) || (style && style.margin) || 0;
    }
    let topBottomMargin = marginTop + marginBottom;

    const dropdownHeight = (dropdownStyle && dropdownStyle.height) || 0;
    //check whether modal should open in top or bottom
    let availableBottomSpace =
      windowHeight -
      this._buttonFrame.y -
      this._buttonFrame.h -
      STATUS_BAR_HEIGHT;
    let availabelTopSpace =
      this._buttonFrame.y - STATUS_BAR_HEIGHT - topBottomMargin;

    let showInBottom =
      dropdownHeight <= availableBottomSpace ||
      availableBottomSpace >= availabelTopSpace;
    if (
      showInBottom &&
      position === 'top' &&
      dropdownHeight &&
      dropdownHeight <= availabelTopSpace
    ) {
      showInBottom = false;
    }

    let modalHeight = 0;
    let modalTopPosition = 0;
    let modalBottomPosition = 0;
    //here we decide the modal height and modal top position
    if (showInBottom) {
      modalHeight =
        dropdownHeight <= availableBottomSpace
          ? dropdownHeight
          : availableBottomSpace;
      modalTopPosition =
        this._buttonFrame.y +
        this._buttonFrame.h -
        (Platform.OS === 'ios' ? STATUS_BAR_HEIGHT : 0);
    } else {
      //check if  space is sufficient for default given height or not
      modalHeight =
        dropdownHeight <= availabelTopSpace
          ? dropdownHeight
          : availabelTopSpace;
      modalBottomPosition =
        windowHeight - STATUS_BAR_HEIGHT - this._buttonFrame.y;
    }
    const dropdownWidth =
      (dropdownStyle && dropdownStyle.width) ||
      (style && style.width) ||
      this._buttonFrame.w;
    const positionStyle = {
      position: 'absolute',
    };

    positionStyle.width = dropdownWidth;
    if (modalHeight !== undefined) {
      positionStyle.height = modalHeight;
    }
    if (modalTopPosition) {
      positionStyle.top = modalTopPosition;
    }
    if (modalBottomPosition) {
      positionStyle.bottom = modalBottomPosition;
    }

    const rightSpace = windowWidth - this._buttonFrame.x;
    let showInRight = rightSpace >= dropdownWidth;
    if (
      showInRight &&
      position === 'left' &&
      dropdownWidth < this._buttonFrame.x
    ) {
      showInRight = false;
    }

    if (showInRight) {
      positionStyle.left = this._buttonFrame.x;
    } else {
      const dropdownWidth =
        (dropdownStyle && dropdownStyle.width) || (style && style.width) || -1;
      if (dropdownWidth !== -1) {
        positionStyle.width = dropdownWidth;
      }
      positionStyle.right = rightSpace - this._buttonFrame.w;
    }
    // console.warn('position style', positionStyle);
    return adjustFrame ? adjustFrame(positionStyle, this.state) : positionStyle;
  }

  render() {
    let {setModal, style, children} = this.props;
    this.setModal = setModal;
    return (
      <TouchableOpacity
        activeOpacity={0.6}
        style={style}
        ref={button => (this._button = button)}
        onPress={this.onPress}
        onLongPress={this.onLongPress}>
        {children}
      </TouchableOpacity>
    );
  }
}

WithModal = withAppKeyboardListenerContext(withModalContext(WithModal));

export default WithModal;
