import React from 'react';

import StackNavigator from '../app-components/stack/StackNavigator';
import { registerAction } from '../app-components/action/Action';
import {
  invokeAction,
  formUpdateInvokeAction,
} from '../app-components/action/InvokeAction';
import { downloadAction } from '../app-components/action/DownloadAction';
import { GroupFilterAction } from '../app-components/action/FilterAction';
import LinkAction from '../app-components/action/LinkAction';
import ModalLinkAction from '../app-components/action/ModalLinkAction';
import { closeAction } from '../app-components/action/CloseAction';
import ConfirmAction from '../app-components/action/ConfirmAction';
import { saveAction } from '../app-components/action/SaveAction';
import AuthScreens from '../Auth';
import { ScreenComponent } from '../app-components/screen/ScreenComponent';
import ConfirmView from '../app-components/screen/ConfirmView';
import AppContainer from './AppContainer';
import Screens from '../screens';
import { ROUTES } from '../Lib/constants';
import PatientRegistration from '../Auth/PatientRegistration';

/*
  *************
  Following functions are used to register the actions that can be fired by action types
  in applane components
  *************
*/

registerAction('invoke', invokeAction);
registerAction('link', LinkAction);
registerAction('modalLink', ModalLinkAction);
registerAction('close', closeAction);
registerAction('save', saveAction);
registerAction('confirm', ConfirmAction);
registerAction('download', downloadAction);
registerAction('formUpdateInvoke', formUpdateInvokeAction);
registerAction('groupFilter', GroupFilterAction);

const AppNavigator = StackNavigator(
  {
    authenticator: { screen: AuthScreens.Authenticator },
    login: {
      public: true,
      screen: ({ navigation }) => <AuthScreens.Login navigation={navigation} />,
    },
    [ROUTES.ResetPassword.name]: {
      screen: ({ navigation }) => <AuthScreens.ResetPassword navigation={navigation} />,
    },
    [ROUTES.patientRegistration.name]: {
      screen: PatientRegistration,
      expanded: true,
    },
    ...Screens,
    confirm: { screen: ConfirmView },
  },
  {
    Container: AppContainer,
    ScreenComponent,
    navigationKey: 'main',
  },
);

export default AppNavigator;
