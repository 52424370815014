import React, { Component } from 'react';
import { ReactForm } from '@applane/react-form';
import { Table } from '../table/Table';
import { isMobile } from '..';
import { editableTableTheme } from '../../theme/tableTheme';
import { EditableCellRender } from './cell/CellRenders';

export class EditableList extends Component {
  renderRowWrapper = ({ item = {}, index }) => {
    let { navigation, eventDispatcher, formProps } = this.props;
    if (typeof formProps === 'function') {
      formProps = formProps({ item, index });
    }
    return {
      Container: ReactForm,
      containerProps: {
        ...formProps,
        key: item._id,
        data: item,
        navigation,
        eventDispatcher,
        validateOnBlur: true,
      },
    };
  };
  render() {
    return (
      <Table
        renderColumn={EditableCellRender}
        renderRowWrapper={this.renderRowWrapper}
        {...(isMobile ? editableTableTheme : editableTableTheme)}
        {...this.props}
      />
    );
  }
}

const EditableListHoc = defs => {
  class EditableListComponent extends Component {
    render() {
      let _defs = defs;
      if (typeof _defs === 'function') {
        _defs = _defs(this.props);
      }
      return <EditableList {..._defs} {...this.props} />;
    }
  }

  return EditableListComponent;
};

export default EditableListHoc;
