import React from 'react';
import {
  View,
  TouchableOpacity,
  TextInput,
  Text,
  Platform,
} from '@applane/react-core-components';
export default class OTPInput extends React.Component {
  textInput = null;
  componentDidMount() {
    this.focus();
    setInterval(this.colorChange, 500);
  }
  colorChange = () => {
    const {
      data: {active, isFocused},
      setValue,
    } = this.props;
    isFocused && setValue({field: 'active', value: !active});
  };
  inputRef() {
    return this.textInput;
  }

  focus() {
    if (this.props.autoFocus === false) {
      return;
    }
    if (this.props.editable !== false) {
      this.inputRef().focus();
    }
  }

  blur() {
    this.inputRef().blur();
  }

  isFocused() {
    return this.inputRef().isFocused();
  }

  render() {
    let {otpLength, setValue, field, data, theme, ...otherProps} = this.props;
    const internalVal = data[field];
    const extraProps = {};
    const {
      containerStyle,
      cellBoxStyle,
      cellTextStyle,
      offTintColor,
      cellGap,
    } = theme;
    if (Platform.OS === 'web') {
      extraProps.inputmode = 'numeric';
    } else {
      extraProps.keyboardType = 'numeric';
    }
    return (
      <>
        <View style={containerStyle}>
          {new Array(otpLength).fill('').map((_, index) => {
            return (
              <TouchableOpacity
                style={{
                  ...cellBoxStyle,
                  borderColor: offTintColor,
                  marginRight: index < otpLength - 1 ? cellGap : 0,
                }}
                onPress={() => this.inputRef().focus()}>
                <Text style={cellTextStyle}>
                  {internalVal && internalVal.length > index
                    ? internalVal[index]
                    : ''}
                  {(internalVal && index === 5 && internalVal.length === 6) ||
                  (internalVal && index === internalVal.length) ||
                  (index === 0 && !internalVal)
                    ? data.active &&
                      data.isFocused && (
                        <Text style={{display: 'inline-block', color: 'black'}}>
                          |
                        </Text>
                      )
                    : ''}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
        <TextInput
          getRef={input => (this.textInput = input)}
          onChangeText={this.handleChangeText}
          style={{
            width: 0,
            height: 0,
            padding: 1,
            border: 'hidden',
            outline: 'none',
          }}
          field={field}
          setValue={setValue}
          minLength={otpLength}
          maxLength={otpLength}
          data={data}
          {...extraProps}
          {...otherProps}
        />
      </>
    );
  }
}
