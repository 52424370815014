import { colors, bgs } from './colors';

import { h12_Regular, h10_Regular } from './fonts';
import {
  uploadIcon,
  crossIconPng,
  documentIcon,
  fileUploadIcon,
} from '../images';
import { Headline9 } from './Headlines';
import { manazeV6Colors } from './manazeV6Colors';
import { errorMessageStyle, helperTextStyle } from './inputTheme';
import vars from './vars';

const { textColor60 } = colors;
const {
  neutral_4,
  neutral_7,
  neutral_9,
  primary_2,
  surface_white,
  negative_communication_3,
} = manazeV6Colors;
const boxStyle = {
  alignItems: 'center',
  flexDirection: 'row',
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 6,
  paddingRight: 10,
  backgroundColor: vars.colors.grey.color6,
  borderWidth: 1,
  borderRadius: 4,
  borderStyle: 'dashed',
  borderColor: vars.colors.grey.color2,
};
export const downloadFileBoxTheme = {
  fileRenderBoxTheme: {
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 4,
      marginBottom: 4,
    },

    documentImageStyle: {
      image: documentIcon,
      viewStyle: {
        padding: 6,
      },
      imageStyle: {
        width: 16,
        height: 16,
      },
    },
    downloadLinkContainerStyle: {
      containerStyle: {
        width: 150,
        flex: 1,
        padding: 6,
      },
      textStyle: {
        ...h12_Regular,
        color: textColor60,
        numberOfLines: 1,
      },
    },
    crossStyle: {
      image: crossIconPng,
      viewStyle: {
        padding: 6,
        cursor: 'pointer',
      },
      imageStyle: {
        width: 15,
        height: 15,
      },
    },
  },
};

export const fileInputBoxTheme = {
  style: {
    justifyContent: 'center',
    backgroundColor: surface_white,
    borderWidth: 1,
    borderRadius: 4,
    borderStyle: 'dashed',
    borderColor: neutral_7,
    minHeight: 44,
    cursor: 'pointer',
  },
  hoverStyle: { backgroundColor: neutral_9 },
  activeStyle: { borderColor: primary_2 },
  errorStyle: {
    borderColor: negative_communication_3,
  },
  inputStyle: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    alignItems: 'center',
  },
  uploadIcon: fileUploadIcon,
  uploadIconStyle: {
    marginRight: 6,
  },
  uploadLabelStyle: {
    ...vars.headings.h9,
    color: vars.neutrals.placeholder,
    numberOfLines: 2,
  },
  ...errorMessageStyle,
  ...helperTextStyle,
  inputErrorStyle: {
    paddingRight: 12,
    alignSelf: 'center',
  },
  fileRenderBoxTheme: {
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 4,
      marginBottom: 4,
    },

    documentImageStyle: {
      image: documentIcon,
      viewStyle: {
        padding: 6,
      },
      imageStyle: {
        width: 16,
        height: 16,
      },
    },
    downloadLinkContainerStyle: {
      containerStyle: {
        width: 150,
        flex: 1,
        padding: 6,
      },
      textStyle: {
        ...h10_Regular,
        color: textColor60,
        numberOfLines: 1,
      },
    },
    crossStyle: {
      image: crossIconPng,
      viewStyle: {
        padding: 6,
        cursor: 'pointer',
      },
      imageStyle: {
        width: 15,
        height: 15,
      },
    },
  },
};
export const compactFileInputBoxTheme = {
  style: {
    justifyContent: 'center',
    backgroundColor: surface_white,
    borderWidth: 1,
    borderRadius: 4,
    borderStyle: 'dashed',
    borderColor: neutral_7,
    minHeight: 32,
    cursor: 'pointer',
  },
  hoverStyle: { backgroundColor: neutral_9 },
  activeStyle: { borderColor: primary_2 },
  errorStyle: {
    borderColor: negative_communication_3,
  },
  inputStyle: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 6,
    paddingRight: 6,
    alignItems: 'center',
  },
  uploadIcon: fileUploadIcon,
  uploadIconStyle: {
    marginRight: 6,
  },
  uploadLabelStyle: {
    ...vars.headings.h9,
    color: vars.neutrals.placeholder,
    numberOfLines: 2,
  },
  inputErrorStyle: {
    paddingRight: 6,
    alignSelf: 'center',
  },
  ...errorMessageStyle,
  ...helperTextStyle,
  fileRenderBoxTheme: {
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 4,
      marginBottom: 4,
    },

    documentImageStyle: {
      image: documentIcon,
      viewStyle: {
        padding: 6,
      },
      imageStyle: {
        width: 16,
        height: 16,
      },
    },
    downloadLinkContainerStyle: {
      containerStyle: {
        width: 150,
        flex: 1,
        padding: 6,
      },
      textStyle: {
        ...vars.headings.h9,
        color: vars.colors.secondary.color2,
        numberOfLines: 1,
      },
    },
    crossStyle: {
      image: crossIconPng,
      viewStyle: {
        padding: 6,
        cursor: 'pointer',
      },
      imageStyle: {
        width: 15,
        height: 15,
      },
    },
  },
};

export const UploadImageTheme = {
  style: {
    justifyContent: 'center',
    backgroundColor: surface_white,
    borderWidth: 1,
    borderRadius: 4,
    borderStyle: 'dashed',
    borderColor: neutral_7,
    minHeight: 44,
    cursor: 'pointer',
  },
  hoverStyle: { backgroundColor: neutral_9 },
  activeStyle: { borderColor: primary_2 },
  errorStyle: {
    borderColor: negative_communication_3,
  },
  inputStyle: {
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    alignItems: 'center',
  },
  uploadIcon: fileUploadIcon,
  uploadIconStyle: {
    marginRight: 6,
  },
  uploadLabelStyle: {
    ...vars.headings.h9,
    color: vars.neutrals.placeholder,
    numberOfLines: 2,
  },
  ...errorMessageStyle,
  ...helperTextStyle,
  inputErrorStyle: {
    paddingRight: 12,
    alignSelf: 'center',
  },
  fileRenderBoxTheme: {
    containerStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 4,
      marginBottom: 4,
    },

    documentImageStyle: {
      image: documentIcon,
      viewStyle: {
        padding: 6,
      },
      imageStyle: {
        width: 16,
        height: 16,
      },
    },
    downloadLinkContainerStyle: {
      containerStyle: {
        width: 150,
        flex: 1,
        padding: 6,
      },
      textStyle: {
        ...vars.headings.h9,
        color: vars.colors.secondary.color2,
        numberOfLines: 1,
      },
    },
    crossStyle: {
      image: crossIconPng,
      viewStyle: {
        padding: 6,
        cursor: 'pointer',
      },
      imageStyle: {
        width: 15,
        height: 15,
      },
    },
  },
};
