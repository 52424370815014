export const matchValue = (value1, value2, fieldToMatch) => {
  if (
    value1 === undefined ||
    value1 === null ||
    value2 === undefined ||
    value2 === null
  ) {
    return;
  }
  if (
    fieldToMatch &&
    value1[fieldToMatch] !== undefined &&
    value1[fieldToMatch] !== null &&
    value2[fieldToMatch] !== undefined &&
    value2[fieldToMatch] !== null
  ) {
    return value1[fieldToMatch] === value2[fieldToMatch];
  } else {
    return value1 === value2;
  }
};

export const findValue = ({value, item, idField}) => {
  if (
    value === undefined ||
    value === null ||
    item === undefined ||
    item === null
  ) {
    return;
  }
  if (Array.isArray(value)) {
    for (let row of value) {
      let matched = matchValue(row, item, idField);
      if (matched) {
        return true;
      }
    }
  } else {
    return matchValue(value, item, idField);
  }
};

export const findIndex = ({value, item, idField}) => {
  if (
    value === undefined ||
    value === null ||
    item === undefined ||
    item === null
  ) {
    return;
  }
  if (Array.isArray(value)) {
    return value.findIndex(row => {
      return matchValue(row, item, idField);
    });
  }
};

export const getOptionValue = ({item, keyField = 'value'}) => {
  if (item === undefined || item === null) {
    return item;
  }
  return (typeof item === 'object' && item[keyField]) || item;
};

export const isSelected = ({value, item, options, idField, keyField}) => {
  if (options) {
    item = getOptionValue({item, keyField});
  } else {
    idField = idField || '_id';
  }
  return findValue({value, item, idField});
};
