import { colors } from '../../theme/colors';

import {
  h12_Regular,
  h12_SemiBold,
  h18_Regular,
  h28_AvenirNext,
  h14_AvenirNext,
} from '../../theme/fonts';
import { shadow } from '../../theme/shadows';
import vars from '../../theme/vars';

const {
  darkTextColor,
  textColor70,
  textColor20,
  errorColor,
  textWhite,
  textColor54,
  purpleColor,
} = colors;
const loginTheme = {
  scrollViewStyle: {
    flex: 1,
    backgroundColor: vars.colors.white,
  },
  containerStyle: {
    flex: 1,
    backgroundColor: vars.colors.white,
    backgroundPosition: 'bottom',
    // backgroundSize: '100% 212px',
    backgroundRepeat: 'no-repeat',
    alignItems: 'center',
    justifyContent: 'center',
  },

  loginBoxStyle: {
    width: 478,
    // height: 480,
    backgroundColor: textWhite,
    borderRadius: 8,
    ...shadow,
    borderColor: vars.colors.white,
    paddingTop: 42,
    borderWidth: 1,
    paddingLeft: 56,
    paddingRight: 56,
    paddingBottom: 42,
  },
  // LoginLogo_style: { width: 188, height: 18 },
  loginLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginLogoStyle: { width: 150 },

  loginText: {
    marginBottom: 25,
    ...h28_AvenirNext,
    color: darkTextColor,
  },
  mobileLabel: {
    ...h14_AvenirNext,
    marginBottom: 17,
    color: textColor70,
  },
  loginTextInput: {
    padding: 10,
    borderWidth: 1,
    borderColor: textColor20,
    borderRadius: 4,
    ...h14_AvenirNext,
    outline: 'none',
    color: darkTextColor,
  },
  loginButtonText: {
    ...h14_AvenirNext,
    lineHeight: 0.88,
    color: textWhite,
  },
  loginButton: {
    cursor: 'pointer',
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    backgroundColor: ' #5CCFE9',
    // width: 109,
  },
  buttonContainer: {
    alignItems: 'center',
  },
  helpContainer: {
    flexDirection: 'row',
    paddingTop: 16,
    paddingbottom: 16,
  },
  helpText: { paddingLeft: 4, color: purpleColor, ...h12_SemiBold },
  errorStyle: {
    ...vars.headings.h14,
    color: vars.colors.error,
    textAlign: 'center',
    marginLeft: 9,
  },
  contactSupportViewStyle: {
    borderBottomWidth: 1,
    alignItem: 'center',
    margin: 20,
    borderBottomColor: textColor54,
  },
  contactSupportText: {
    ...h18_Regular,
    color: textColor54,
  },
};

export default loginTheme;
