import { initUser, mamcAuthenticate, mamcResetPassword } from '../AppServices';

import {
  loginLogo,
  classRoom_logo,
  helpIcon,
  hospitalLiveLogo,
  login_background_web,
  resetPassword,
} from '../images';

import LoginHoc from './Login';
import AuthenticatorHoc from './Authenticator';
import { LANDING_VIEW } from '../Config';

import ResetPasswordHOC from './ResetPassword';

const getAuthTemplate = () => {
  const Login = LoginHoc({
    mamcAuthenticate,
    images: {
      bgImage: login_background_web, helpIcon, loginLogo, hospitalLiveLogo, classRoom_logo,
    },
  });

  const ResetPassword = ResetPasswordHOC({
    mamcResetPassword,
    images: {
      bgImage: login_background_web,
      helpIcon,
      loginLogo,
      hospitalLiveLogo,
      classRoom_logo,
      resetPassword,
    },
  });

  const getPath = () => {
    const path = decodeURIComponent(window.location.pathname);
    if (path && path.trim() === '/') {
      return LANDING_VIEW;
    }
    return path.split('/')[1] || LANDING_VIEW;
  };
  const Authenticator = AuthenticatorHoc({
    initUser,
    landingView: getPath,
    loginView: 'login',
  });

  return {
    Login,
    Authenticator,
    ResetPassword,
  };
};

const authTemplates = getAuthTemplate();

export default authTemplates;
