import React from 'react';
import moment from 'moment';
import {
  Text, TouchableOpacity, View, Image,
} from '../../../../app-components';
import {
  lifeLine,
  profile,
  tickIcon,
} from '../../../../images';
import Table from '../../../../app-components/table/Table';
import { h14_AvenirNext, h20_AvenirNext_500 } from '../../../../theme/fonts';
import {
// setInitiallyLogged,
// setSelectedDoctor,
// getProfileUrl,
// getImageStyle,
// getCurrency,
// getUserLocation,
} from '../../../../AppServices';
import {
  mayaCardTheme,
  mayaCardMDTheme,
} from '../../../../theme/cardTheme';
import { employeeListUri } from '../../../../Queries/employee';
import { colors } from '../../../../theme/mayaMdColors';

const { themeColor } = colors;

const uri = employeeListUri({ filter: { role: { code: 'DOCTOR' } } });
const cardDemo = {
  lg: {
    card: {
      ...mayaCardTheme,
      left: [
        {
          items: [
            {
              value: ({ navigation, item }) => {
                const { setScreenState } = navigation;
                setScreenState({ doctorCount: item.length });
                return (
                  <View
                    style={{
                      position: 'relative',
                      width: '90px',
                      height: '90px',
                      overflow: 'hidden',
                      borderRadius: '50%',
                    }}
                  >
                    <Image
                      source={profile}
                      style={{ width: 90, height: 90, borderRadius: 90 }}
                    />
                  </View>
                );
              },
            },
          ],
        },
      ],
      contentItems: [
        [
          {
            value: ({ item, navigation }) => {
              const {
                name,
                speciality: specialities_procedures = [],
                joiningDate,
              } = item;
              const experience_in_years = moment().diff(joiningDate, 'months');
              return (
                <View style={{}}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text
                      style={{
                        ...h20_AvenirNext_500,
                        marginTop: 10,
                        marginLeft: 22,
                        color: '#fff',
                      }}
                    >
                      {name}
                    </Text>
                    <Image
                      style={{
                        marginTop: 11,
                        marginLeft: 11,
                        height: 20,
                        width: 20,
                      }}
                      source={tickIcon}
                    />
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      marginLeft: 22,
                      marginTop: 6,
                    }}
                  >
                    <Text
                      style={{
                        ...h14_AvenirNext,
                        color: '#fff',
                      }}
                    >
                      {item?.speciality?.name}
                      {specialities_procedures?.length > 0
                        && specialities_procedures.map((item, index) => {
                          const separator = (index + 1 < specialities_procedures.length
                              && ', ')
                            || ' ';

                          return (
                            item?.speciality?.name
                            && item?.speciality?.name + separator
                          );
                        })}
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      marginTop: 12,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderRadius: 8,
                        backgroundColor: '#f9f9f9',
                        marginLeft: 16,
                        paddingTop: 7,
                        paddingLeft: 13,
                        paddingBottom: 7,
                        paddingRight: 13,
                      }}
                    >
                      <Image
                        style={{
                          width: 19,
                          height: 16,
                        }}
                        source={lifeLine}
                      />
                      <View style={{ marginLeft: 10, paddingRight: 6 }}>
                        <Text
                          style={{
                            ...h14_AvenirNext,
                            color: '#383838',
                          }}
                        >
                          {experience_in_years}
                          {' '}
                          {'months exp'}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              );
            },
          },
        ],
      ],
      right: [
        {
          align: 'right',
          items: [
            { paddingTop: 16, paddingBottom: 16 },
            [
              {
                action: ({ item, index }) => ({
                  type: 'link',
                  link: {
                    view: 'doctor-profile',
                    params: { item, index },
                  },
                }),
                value: () => (
                  <TouchableOpacity
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: 8,
                      borderWidth: 1,
                      borderColor: themeColor,
                      paddingTop: 7,
                      paddingLeft: 16,
                      paddingBottom: 7,
                      paddingRight: 16,
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: 29,
                      marginTop: 12,
                    }}
                  >
                    <Text
                      style={{
                        ...h14_AvenirNext,
                        color: themeColor,
                      }}
                    >
                      {/* View Profile */}
                      {'View Profile'}
                    </Text>
                  </TouchableOpacity>
                ),
              },
              {
                action: ({ item, index, navigation }) => {
                  const {
                    organization: { account_status } = {},
                    consultation_duration,
                    cost_of_booking,
                  } = item;
                  const visible = true;
                  // if (
                  //   account_status !== 'linked'
                  //   || cost_of_booking === undefined
                  //   || !consultation_duration
                  // ) {
                  //   visible = false;
                  // }
                  // if (cost_of_booking == 0) {
                  //   visible = true;
                  // }
                  // if (!visible) {
                  //   return null;
                  // }
                  const {
                    state: {
                      params: { mayaAISessionId = null, dependent } = {},
                    } = {},
                  } = navigation;
                  return {
                    type: 'link',
                    link: () => {
                      navigation.push({
                        view: 'doctor-profile',
                        params: {
                          item: {
                            ...item,
                            appointment_type: 'new',
                            mayaAISessionId,
                            dependent,
                          },
                          index,
                          openBooking: true,
                        },
                      });
                    },
                  };
                },
                value: ({ item }) => {
                  const {
                    organization: { account_status } = {},
                    consultation_duration,
                    cost_of_booking,
                  } = item;
                  const visible = true;
                  // if (
                  //   account_status !== 'linked'
                  //   || cost_of_booking === undefined
                  //   || !consultation_duration
                  // ) {
                  //   visible = false;
                  // }
                  // if (cost_of_booking == 0) {
                  //   visible = true;
                  // }
                  return (
                    <TouchableOpacity
                      style={{
                        backgroundColor: visible ? themeColor : '#cccccc',
                        borderRadius: 8,
                        paddingTop: 7,
                        paddingLeft: 30,
                        paddingBottom: 7,
                        paddingRight: 30,
                        cursor: visible ? 'pointer' : 'not-allowed',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 29,
                        marginTop: 12,
                      }}
                    >
                      <Text
                        style={{
                          ...h14_AvenirNext,
                          color: '#fff',
                        }}
                      >
                        {visible ? 'Book' : 'Book'}
                      </Text>
                    </TouchableOpacity>
                  );
                },
              },
            ],
            {
              value: () => <View style={{}} />,
            },
          ],
        },
      ],
    },
  },
  md: {
    card: {
      ...mayaCardMDTheme,
      left: [
        {
          items: [
            {
              value: ({ navigation, item }) => {
                const { setScreenState } = navigation;
                setScreenState({ doctorCount: item.length });

                return (
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Image
                      source={profile}
                      style={{ width: 90, height: 90, borderRadius: 90 }}
                    />
                  </View>
                );
              },
            },
          ],
        },
      ],
      contentItems: [
        {
          align: 'left',
          position: 'top',
          value: ({ item, t }) => {
            const {
              name,
              speciality: specialities_procedures = [],
              joiningDate,
            } = item;
            const experience_in_years = moment().diff(joiningDate, 'months');

            return (
              <View style={{ marginLeft: 10 }}>
                <Text
                  style={{
                    ...h20_AvenirNext_500,
                    color: '#fff',
                  }}
                >
                  {name}
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: 6,
                  }}
                >
                  <Text
                    style={{
                      ...h14_AvenirNext,
                      color: '#fff',
                    }}
                  >
                    {specialities_procedures?.length > 0
                      && specialities_procedures.map((item, index) => {
                        const separator = (index + 1 < specialities_procedures.length
                            && ', ')
                          || ' ';
                        return (
                          item.speciality?.name
                          && item.speciality?.name + separator
                        );
                      })}
                  </Text>

                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderRadius: 8,
                    backgroundColor: '#f9f9f9',
                    paddingTop: 7,
                    paddingLeft: 9,
                    paddingBottom: 7,
                    paddingRight: 9,
                    marginTop: 8,
                    width: 'fit-content',
                  }}
                >
                  <Image
                    style={{
                      width: 19,
                      height: 16,
                    }}
                    source={lifeLine}
                  />
                  <View style={{ marginLeft: 5 }}>
                    <Text
                      style={{
                        ...h14_AvenirNext,
                        color: '#383838',
                      }}
                    >
                      {experience_in_years}
                      {' '}
                      {'months exp'}
                    </Text>
                  </View>
                </View>
              </View>
            );
          },
        },
      ],
      right: [
        {
          align: 'right',
          items: [
            {
              prefix: 'for ',
              field: 'consultation_duration',
              suffix: 'min',
              textStyle: {
                ...h14_AvenirNext,
                color: '#fff',
                marginTop: 6,
              },
            },

            {
              action: ({ item, index }) => ({
                type: 'link',
                link: {
                  view: 'doctor-profile',
                  params: { item, index },
                },
              }),
              value: ({ t }) => (
                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <TouchableOpacity
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: 8,
                      borderWidth: 1,
                      borderColor: themeColor,
                      paddingTop: 7,
                      paddingLeft: 30,
                      paddingBottom: 7,
                      paddingRight: 16,
                      cursor: 'pointer',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 8,
                    }}
                  >
                    <Text
                      style={{
                        ...h14_AvenirNext,
                        color: themeColor,
                      }}
                    >
                      View Profile
                    </Text>
                  </TouchableOpacity>
                </View>
              ),
            },

            {
              value: () => <View style={{}} />,
            },
          ],
        },
      ],
    },
  },
  // sm: {
  //   card: {
  //     // ...mayaCardTheme,
  //     ...mayaCardMDTheme,
  //     // left: [
  //     //   {
  //     //     items: [
  //     //       {
  //     //         value: ({navigation, item}) => {
  //     //           let {setScreenState} = navigation;
  //     //           setScreenState({doctorCount: item.length});

  //     //           let {profile_picture} = item;
  //     //           let imageUrl = getProfileUrl(profile_picture?.file);

  //     //           return (
  //     //             <View
  //     //               style={{
  //     //                 justifyContent: 'center',
  //     //                 alignItems: 'center',
  //     //                 // paddingTop: 5,
  //     //                 // paddingLeft: 5,
  //     //                 // marginTop: 6,
  //     //               }}>
  //     //               <Image
  //     //                 source={imageUrl || profile}
  //     //                 style={{width: 90, height: 90, borderRadius: 90}}
  //     //               />
  //     //             </View>
  //     //           );
  //     //         },
  //     //       },
  //     //     ],
  //     //   },
  //     // ],
  //     contentItems: [
  //       {
  //         align: 'left',
  //         position: 'top',
  //         value: ({ item, t }) => {
  //           const {
  //             name,
  //             specialities_procedures = [],
  //             qualification_description,
  //             experience_in_years,
  //             organization,
  //           } = item;
  //           const qualification = qualification_description
  //             ? specialities_procedures.length
  //               ? ` | ${qualification_description}`
  //               : qualification_description
  //             : '';
  //           return (
  //             // <View style={{flex: 1}}>
  //             <View style={{ marginLeft: 10 }}>
  //               <Text
  //                 style={{
  //                   ...h20_AvenirNext_500,
  //                   color: '#fff',
  //                 }}
  //               >
  //                 {name}
  //               </Text>
  //               <View
  //                 style={{
  //                   flexDirection: 'row',
  //                   // marginLeft: 22,
  //                   marginTop: 6,
  //                 }}
  //               >
  //                 <Text
  //                   style={{
  //                     ...h14_AvenirNext,
  //                     // color: textColor87,
  //                     color: '#fff',
  //                   }}
  //                 >
  //                   {/* {item?.speciality?.name} */}
  //                   {specialities_procedures?.length > 0
  //                     && specialities_procedures.map((item, index) => {
  //                       const separator = (index + 1 < specialities_procedures.length
  //                           && ', ')
  //                         || ' ';
  //                       return (
  //                         item.speciality?.name
  //                         && item.speciality?.name + separator
  //                       );
  //                     })}
  //                 </Text>

  //                 {/* <Text
  //                   style={{
  //                     color: '#fff',
  //                     ...h14_AvenirNext,
  //                     whiteSpace: 'normal',
  //                   }}>
  //                   {qualification}
  //                 </Text> */}
  //               </View>
  //               <View
  //                 style={{
  //                   flexDirection: 'row',
  //                   alignItems: 'center',
  //                   // justifyContent: 'flex-start',
  //                   borderRadius: 8,
  //                   backgroundColor: '#f9f9f9',
  //                   // marginLeft: 16,
  //                   paddingTop: 7,
  //                   paddingLeft: 9,
  //                   paddingBottom: 7,
  //                   paddingRight: 9,
  //                   marginTop: 8,
  //                   width: 'fit-content',
  //                 }}
  //               >
  //                 <Image
  //                   style={{
  //                     width: 19,
  //                     height: 16,
  //                     // paddingLeft: 13,
  //                     // paddingRight: 1,
  //                     // marginTop: 2,
  //                   }}
  //                   source={lifeLine}
  //                 />
  //                 <View style={{ marginLeft: 5 }}>
  //                   <Text
  //                     style={{
  //                       ...h14_AvenirNext,
  //                       color: '#383838',
  //                     }}
  //                   >
  //                     {experience_in_years}
  //                     {' '}
  //                     {t('yrs exp')}
  //                   </Text>
  //                 </View>
  //               </View>
  //             </View>
  //           );
  //         },
  //       },
  //     ],
  //     right: [
  //       {
  //         align: 'right',
  //         items: [
  //           // {align: 'right'},
  //           {
  //             prefix: '$',
  //             field: 'cost_of_booking',
  //             textStyle: {
  //               color: '#fff',
  //               // ...h18_AvenirNext,
  //               ...h20_AvenirNext_500,
  //               // marginTop: 10,
  //               // marginRight: 32,
  //             },
  //           },
  //           {
  //             prefix: 'for ',
  //             field: 'consultation_duration',
  //             suffix: 'min',
  //             textStyle: {
  //               ...h14_AvenirNext,
  //               color: '#fff',
  //               marginTop: 2,
  //               // marginTop: 4,
  //               // marginRight: 32,
  //             },
  //           },

  //           {
  //             action: ({ item, index }) => ({
  //               type: 'link',
  //               link: {
  //                 view: 'doctor-profile',
  //                 params: { item, index },
  //               },
  //             }),
  //             value: ({ t }) => (
  //               <View
  //                 style={{
  //                   flexDirection: 'row',
  //                   // marginTop: 12,
  //                   // marginBottom: 20,
  //                 }}
  //               >
  //                 <TouchableOpacity
  //                   style={{
  //                     backgroundColor: '#fff',
  //                     borderRadius: 8,
  //                     borderWidth: 1,
  //                     borderColor: themeColor,
  //                     paddingTop: 7,
  //                     minWidth: 100,
  //                     // paddingLeft: 30,
  //                     paddingBottom: 7,
  //                     // paddingRight: 16,
  //                     cursor: 'pointer',
  //                     alignItems: 'center',
  //                     justifyContent: 'center',
  //                     // marginRight: 29,
  //                     marginTop: 4,
  //                   }}
  //                 >
  //                   <Text
  //                     style={{
  //                       ...h14_AvenirNext,
  //                       color: themeColor,
  //                     }}
  //                   >
  //                     {/* View Profile */}
  //                     {t('View Profile')}
  //                   </Text>
  //                 </TouchableOpacity>
  //               </View>
  //             ),
  //           },

  //           {
  //             value: () => <View style={{}} />,
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // },
};

export default Table({
  uri,
  cardScrollViewStyle: {
    backgroundColor: themeColor,
    flex: 1,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
    paddingBottom: 12,
  },
  reloadEvent: 'reloadSearchList',
  ...cardDemo,
});
