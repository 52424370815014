export const DEFAULT_GROUP_NAME_IN_LABEL = true;

export const populateTabDef = (defs, options) => {
  if (defs && !defs.tabs) {
    defs = {
      tabs: defs,
    };
  }
  let tabs = {...defs.tabs};
  for (let key in tabs) {
    let tab = tabs[key];
    if (!tab.label) {
      tab = {...tab, label: key};
      tabs[key] = tab;
    }
  }
  defs = {...defs, tabs};
  return defs;
};

export const populateFilterState = ({
  tabKey,
  tabState,
  field,
  filterInfo,
  filters,
}) => {
  const {filterTabs} = filterInfo;
  if (filterTabs) {
    let filterValue = filters[field];
    if (
      (Array.isArray(filterTabs) && filterTabs.indexOf(tabKey) > -1) ||
      filterTabs === true ||
      filterTabs === 'All' ||
      filterTabs === 'all'
    ) {
      tabState = {
        ...tabState,
        dataParams: {
          ...tabState?.dataParams,
          filters: {...tabState?.dataParams?.filters},
        },
      };
      if (filterValue === undefined || filterValue === null) {
        delete tabState.dataParams.filters[field];
      } else {
        tabState.dataParams.filters[field] = filterValue;
      }
    }
  }
  return tabState;
};

export const closeNextViews = ({navigation} = {}) => {
  if (navigation) {
    let isModalView = navigation.isModalView && navigation.isModalView();
    if (!isModalView) {
      let routeCount = navigation.getRouteCount && navigation.getRouteCount();
      if (routeCount && navigation.getIndex) {
        let count = routeCount - 1 - (navigation.getIndex() || 0);
        if (count) {
          navigation.pop(count);
        }
      }
    }
  }
};

export const resolveTabItemCount = props => {
  const {loaded, aggregates} = props || {};
  if (loaded) {
    return `(${(aggregates && aggregates._count) || 0})`;
  }
  return null;
};

export const resolveDataFetchProps = ({navigation}) => {
  const {getScreenState} = navigation || {};
  const {dataParams} = (getScreenState && getScreenState()) || {};
  return {dataParams};
};

export const resolveTabItemLabel = ({tab, group, navigation, selected}) => {
  let {
    groupName,
    label,
    defaultGroupNameInLabel = DEFAULT_GROUP_NAME_IN_LABEL,
  } = tab;
  if (typeof label === 'function') {
    label = label(navigation);
  }
  if (defaultGroupNameInLabel && group && groupName) {
    return selected ? `${groupName} (${label})` : groupName;
  }
  return label;
};

export const resolveTabState = ({
  tabKey,
  tabState,
  filtersInfo,
  selectedTab,
  skipSelectedTab,
  filters,
}) => {
  if (skipSelectedTab && tabKey === selectedTab) {
    return;
  }
  Object.keys(filtersInfo).forEach(field => {
    tabState = populateFilterState({
      field,
      filterInfo: filtersInfo[field],
      filters,
      tabKey,
      tabState,
    });
  });
  return tabState;
};
