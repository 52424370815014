import React, { useEffect } from 'react';
import { uniqueId } from 'lodash';

import {
  ScrollView,
  View,
  Image,
  Text,
  TouchableOpacity,
} from '../../../app-components';
import {
  doctorSearchMaya,
  appointments,
  dashboardIcon,
  mamcLogo,
} from '../../../images';
import { h16_Regular as h16Regular } from '../../../theme/fonts';
import './style.css';
// import { brandImg, brandStyle } from '../IconStyleBrand';
import SideBarFooter from './SideBarFooter';
import { ROUTES } from '../../../Lib/constants';
import { gpsStore } from '../../../AppServices';

const SidePannel = (props) => {
  const {
    selectedMenu, setSelectedMenu, navigation, user,
  } = props;

  const connectToSockets = () => {
    const socketUid = uniqueId();

    gpsStore.emitDataOnConnect({
      uid: socketUid,
      event: 'subscribeGroup',
      data: {
        sessionId: socketUid,
        source: 'web',
      },
      callback: () => {
        console.log('Real time sync enabled ->>>');
      },
    });
  };

  useEffect(() => {
    connectToSockets();
  }, []);

  if (!user) {
    return <View />;
  }

  return (
    <View
      style={{
        height: '100%',
        width: 240,
        backgroundColor: 'rgba(1, 59, 86, 0.35)',
        borderRadius: 20,
        padding: 5,
        alignItems: 'center',
        marginLeft: 20,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          setSelectedMenu('dashboard');
          navigation.reset(ROUTES.dashboard.name);
        }}
        style={{
          cursor: 'pointer',
          borderRadius: 10,
          marginTop: 33,
        }}
      >
        <Image
          source={mamcLogo}
          style={{ height: 80 }}
          className="pulse"
        />
      </TouchableOpacity>
      <ScrollView style={{ marginTop: 50, width: '100%', flexShrink: 'initial' }}>
        {[
          {
            label: 'Dashboard',
            value: 'dashboard',
            isNavigate: ROUTES.dashboard.name,
            icon: dashboardIcon,
          },
          {
            label: 'Appointments',
            value: 'appointments',
            icon: appointments,
            isNavigate: ROUTES.patientWaitingRoom.name,
          },
          {
            label: 'Find a Provider',
            value: 'findAProvider',
            isNavigate: ROUTES.doctorSearch.name,
            icon: doctorSearchMaya,
          },
        ].map((item, key) => (
          <TouchableOpacity
            key={key}
            onPress={() => {
              if (item?.isNavigate) {
                navigation.reset(item?.isNavigate);
              }
              setSelectedMenu(item.value);
            }}
            style={{
              backgroundColor:
                  selectedMenu === item.value ? 'rgba(255, 255, 255, 0.2)' : '',
              flex: 1,
              borderRadius: 8,
              paddingLeft: 15,
              minHeight: 48,
              marginBottom: 10,
              cursor: 'Pointer',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                flex: 1,
                // paddingLeft: 35,
                alignItems: 'center',
              }}
            >
              <Image
                style={{
                  width: 16.5,
                  height: 16.5,
                  objectFit: 'contain',
                  marginRight: 20,
                  marginLeft: 20,
                }}
                source={item.icon}
              />
              <Text style={{ ...h16Regular, color: '#fff' }}>
                {item.label}
              </Text>
            </View>
          </TouchableOpacity>
        ))}
      </ScrollView>
      <View
        style={{
          flex: 1,
          width: '98%',
          justifyContent: 'flex-end',
          marginBottom: 5,
        }}
      >
        {/* <SideBarFooter {...props} /> */}
      </View>
    </View>
  );
};

export default SidePannel;
