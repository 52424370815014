export default {
  labels: {
    wardName: 'Speciality Name',
    wardId: 'Speciality ID',
    department: 'Department',
    users: 'Users',
    employees: 'Employees',
    status: 'Status',
    description: 'Description',
    roomsAllocated: 'Room No. Allocated',
    charge: 'Charge ',
    edit: 'Edit',
    createdOn: 'Created On',
  },
  placeholders: {
    rooms: 'Select Rooms',
    speciality: 'Select Speciality',
    department: 'Select department',
  },
  title: {
    wardManagement: 'Speciality Management',
    addWard: 'Add Speciality',
    subTitle: 'Fill out the form to add new Speciality',
    editWard: 'Edit Speciality',
    wardDetails: 'Speciality Details',
    exportToExcel: 'Export To Excel',
    description: 'List of Employee in the Speciality.',
  },
  messages: {
    wardAddedSuccessfully: 'Speciality added successfully',
    wardAlreadyExists: 'Speciality already Exits',
    wardEditedSuccessfully: 'Speciality edited successfully',
  },
};
