import React from 'react';
import PropTypes from 'prop-types';
import {WithReactForm} from './WithReactFormContext';
import {getRenderComponent, resolveExp, getNewId, findById} from './Utility';

class NestedField extends React.Component {
  state = {};
  formExtraData = {};

  resolveNestedRowErrors = ({item, errors}) => {
    let {field} = this.props;
    return errors && errors[field] && errors[field][item._id];
  };

  _getState = ({item = {}, index} = {}) => {
    const {form_state: {data} = {}} = this.props;
    let mandatoryErrors =
      this.formExtraData.mandatoryErrors &&
      this.formExtraData.mandatoryErrors[item._id];
    let validationErrors =
      this.formExtraData.validationErrors &&
      this.formExtraData.validationErrors[item._id];
    let focusField =
      this.formExtraData.focusField && this.formExtraData.focusField[item._id];
    let focussedFields =
      this.formExtraData.focussedFields &&
      this.formExtraData.focussedFields[item._id];
    return {
      ...this.state[item._id],
      mandatoryErrors,
      validationErrors,
      focusField,
      focussedFields,
      data: {...item, _parent: {...data}},
      index,
    };
  };

  _setState = ({item = {}} = {}) => state => {
    let _state = state;
    if (typeof _state === 'function') {
      _state = _state(this.state[item._id] || {});
    }
    this.setState({[item._id]: {...this.state[item._id], ..._state}});
  };

  resolveVisible = ({visible, ...restProps}) => {
    const {
      form_state: {data},
      form_context: {navigation},
    } = this.props;
    if (typeof visible === 'function') {
      visible = visible({
        ...restProps,
        navigation,
        _parent: data,
      });
    }
    return visible;
  };

  addRow = ({_id, values}) => {
    const {field, form_context: {setValue} = {}, addInBottom} = this.props;
    setValue &&
      setValue({
        path: [{_id: _id || getNewId(), field}],
        insert: true,
        bottom: addInBottom,
        values,
      });
  };

  removeRow = ({item}) => {
    if (!item || !item._id) {
      return;
    }
    const {
      field,
      form_state: {data},
      form_context: {setValue} = {},
    } = this.props;
    const value = data && field && data[field];
    if (!value || !value.length) {
      return;
    }
    const row = findById(value, '_id', item);
    if (!row) {
      return;
    }
    const path = [{_id: item._id, field}];
    setValue &&
      setValue({
        path,
        remove: true,
      });
  };

  setValue = ({data, path, ...rest}) => {
    if (!data || !data._id) {
      return;
    }
    const {field, form_context: {setValue} = {}} = this.props;
    path = path ? [...path] : [];
    path.unshift({_id: data._id, field});
    setValue &&
      setValue({
        path,
        ...rest,
      });
  };

  setFocus = ({data, path, ...rest}) => {
    if (!data || !data._id) {
      return;
    }
    const {field, form_context: {setFocus} = {}} = this.props;
    path = path ? [...path] : [];
    path.unshift({_id: data._id, field});
    setFocus &&
      setFocus({
        path,
        ...rest,
      });
  };

  setFormExtraData = () => {
    const {field, form_state = {}} = this.props;
    let {
      mandatoryErrors,
      validationErrors,
      focusField,
      focussedFields,
    } = form_state;
    let fieldMandatoryErrors =
      (mandatoryErrors && mandatoryErrors[field]) || void 0;
    let fieldValidationErrors =
      (validationErrors && validationErrors[field]) || void 0;
    let fieldFocusField = (focusField && focusField[field]) || void 0;
    let fieldFocussedFields =
      (focussedFields && focussedFields[field]) || void 0;

    if (this.formExtraData.mandatoryErrors !== fieldMandatoryErrors) {
      this.formExtraData = {
        ...this.formExtraData,
        mandatoryErrors: fieldMandatoryErrors,
      };
    }
    if (this.formExtraData.validationErrors !== fieldValidationErrors) {
      this.formExtraData = {
        ...this.formExtraData,
        validationErrors: fieldValidationErrors,
      };
    }
    if (this.formExtraData.focusField !== fieldFocusField) {
      this.formExtraData = {
        ...this.formExtraData,
        focusField: fieldFocusField,
      };
    }
    if (this.formExtraData.focussedFields !== fieldFocussedFields) {
      this.formExtraData = {
        ...this.formExtraData,
        focussedFields: fieldFocussedFields,
      };
    }
  };

  render() {
    const {
      field,
      form_state = {},
      form_context: {navigation, eventDispatcher} = {},
      children,
      ...restProps
    } = this.props;
    if (!children) {
      return null;
    }
    let {data = {}} = form_state;
    this.setFormExtraData();
    let error =
      this.formExtraData.mandatoryErrors || this.formExtraData.validationErrors;
    const childrenProps = {
      ...restProps,
      data,
      field,
      formExtraData: this.formExtraData,
      error,
      value: data ? resolveExp(data, field) : void 0,
      navigation,
      eventDispatcher,
      setValue: this.setValue,
      setFocus: this.setFocus,
      addRow: this.addRow,
      removeRow: this.removeRow,
      _getState: this._getState,
      _setState: this._setState,
      resolveVisible: this.resolveVisible,
    };
    return getRenderComponent(children, childrenProps);
  }
}

NestedField.propTypes = {
  field: PropTypes.string,
};

NestedField = WithReactForm(NestedField, {
  key: 'form',
});

export default NestedField;
