import React from 'react';
import {ScrollView} from '@applane/react-core-components';
import {formTabMobileTheme, formTabTheme} from '../../theme/tabBarTheme';
import FormTabNavigator from '../tab/FormTabNavigator';
import {isMobile} from '../UtilityFunctions';
import {getResolvedGroups} from './Utility';

class FormTabScreen extends React.Component {
  render() {
    let {children} = this.props;
    return children;
  }
}

export const FormTab = props => {
  let theme = isMobile ? formTabMobileTheme : formTabTheme;
  let {tabs, renderFormGroups, scrollable, ...restProps} = props;
  let formTabs = {};
  for (let tabKey in tabs) {
    let {formGroups, ...restTabInfo} = tabs[tabKey];
    if (formGroups) {
      formGroups = getResolvedGroups(formGroups, restProps);
    }
    let screenChildren = renderFormGroups({formGroups});
    if (scrollable !== false) {
      screenChildren = (
        <ScrollView style={{flex: 1}}>{screenChildren}</ScrollView>
      );
    }
    formTabs[tabKey] = {
      ...restTabInfo,
      screen: FormTabScreen,
      screenProps: {
        key: tabKey,
        children: screenChildren,
      },
    };
  }
  return <FormTabNavigator {...theme} tabs={formTabs} {...restProps} />;
};
