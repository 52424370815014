import React from 'react';

import RootNavigation from './RootNavigator';
import {
  ReactEventDispatcher,
  AppKeyboardListener,
  ReactModalViewContextProvider,
  ReactModalView,
  isMobile,
} from '../app-components';
import { GpsContext } from '../withGpsContext';
import { gpsStore } from '../AppServices';
import Toast from '../app-components/toast/Toast';
import AppLoader from '../app-components/loader/AppLoader';

export default () => {
  const visibleViews = isMobile ? 1 : 2;

  return (
    <GpsContext.Provider value={gpsStore}>
      <ReactEventDispatcher>
        <AppKeyboardListener>
          <ReactModalViewContextProvider>
            <RootNavigation visibleViews={visibleViews} />
            <ReactModalView />
            <AppLoader />
            <Toast />
          </ReactModalViewContextProvider>
        </AppKeyboardListener>
      </ReactEventDispatcher>
    </GpsContext.Provider>
  );
};
