import React from 'react';
import uuid from 'uuid/v4';
import { Image, View, Text } from '@applane/react-core-components';
import { WithModal } from '@applane/react-with-modal';
import { ReactForm } from '@applane/react-form';
import { Table } from '../../table/Table';
import { EditableList } from '../../table/EditableList';
import {
  NestedTableFormTheme,
  NestedTableMobileFormTheme,
} from '../../../theme/tableTheme';
import { Headline11, Headline6 } from '../../../theme/Headlines';
import { manazeV6Colors } from '../../../theme/manazeV6Colors';
import { ToolBar } from '../..';
import {
  nestedTableFooterMobileTheme,
  nestedTableFooterTheme,
  nestedTableHeaderMobileTheme,
  nestedTableHeaderTheme,
  nestedTableModalHeaderTheme,
} from '../../../theme/toolBarTheme';

import { dropDownShadow } from '../../../theme/shadows';
import { crossIcon } from '../../../images';
import { getRenderComponent, isMobile } from '../../UtilityFunctions';

const nestedTableModalTheme = {
  dropdownStyle: { width: 500, height: 250 },
  backdropStyle: { backgroundColor: 'rgba(0,0,0,0.2)' },
  containerStyle: {
    overflow: 'hidden',
    margin: 8,
    borderRadius: 4,
    backgroundColor: 'white',
    ...dropDownShadow,
  },
};

const MIN_HEIGHT = 32;
const { primary_2 } = manazeV6Colors;

const getNewId = () => {
  return `new_${uuid()}`;
};

export const RemoveMultiRowNestedAction = ({ text = 'Delete' } = {}) => {
  return {
    text,
    visible: ({ screenState }) => {
      let { selectedIds } = screenState || {};
      return selectedIds && selectedIds.length > 0;
    },
    onPress: ({ screenState, setScreenState, removeRow }) => {
      let { selectedIds } = screenState || {};
      if (removeRow && selectedIds && selectedIds.length) {
        for (let id of selectedIds) {
          setTimeout((_) => {
            removeRow({ item: { _id: id } });
          });
        }
        setTimeout((_) => {
          setScreenState && setScreenState({ selectedIds: void 0 });
        }, 1000);
      }
    },
  };
};

export const NestedAction = ({
  width = 30,
  align = 'right',
  text,
  icon,
  containerStyle = { height: MIN_HEIGHT, alignItems: 'center' },
  textStyle = { ...Headline11, color: primary_2 },
  addAction,
  header,
  ...rest
} = {}) => {
  if (!icon && !text) {
    text = 'X';
  }
  return {
    type: 'action',
    width,
    align,
    header:
      addAction && typeof header !== 'function'
        ? ({ addRow }) => {
            if (header && typeof header === 'string') {
              header = { label: header };
            }
            header = header
              ? { ...header }
              : {
                  label: '+',
                  textStyle: { ...Headline6, color: primary_2 },
                };
            header.onPress = addRow;
            return header;
          }
        : header,
    icon,
    text,
    containerStyle,
    textStyle,
    action: ({ removeRow }) => ({
      onPress: removeRow,
    }),
    ...rest,
  };
};

class NestedForm extends React.Component {
  extraDataKeys = [
    'mandatoryErrors',
    'validationErrors',
    'focusField',
    'focussedFields',
  ];

  shouldComponentUpdate(nextProps) {
    if (Object.keys(nextProps).length !== Object.keys(this.props).length) {
      return true;
    }
    let form_id = nextProps.item._id;
    for (let key in nextProps) {
      if (key === 'children') {
        continue;
      }
      if (nextProps[key] !== this.props[key]) {
        if (key === 'formExtraData') {
          let oldValues = this.props[key];
          let newValues = nextProps[key];
          for (let keyToCheck of this.extraDataKeys) {
            let keyOldValue = oldValues ? oldValues[keyToCheck] : void 0;
            let keyNewValue = newValues ? newValues[keyToCheck] : void 0;
            if (keyOldValue !== keyNewValue) {
              let oldIdValue = keyOldValue && keyOldValue[form_id];
              let newIdValue = keyNewValue && keyNewValue[form_id];
              if (oldIdValue !== newIdValue) {
                return true;
              }
            }
          }
        } else {
          return true;
        }
      }
    }
    return false;
  }

  render() {
    let {
      item,
      index,
      _getState,
      _setState,
      navigation,
      eventDispatcher,
      setValue,
      setFocus,
      children,
    } = this.props;
    return (
      <ReactForm
        key={item._id}
        data={item}
        navigation={navigation}
        eventDispatcher={eventDispatcher}
        setValue={setValue}
        setFocus={setFocus}
        state={_getState({ item, index })}
        setState={_setState({ item, index })}
        useState
        validateOnBlur>
        {children}
      </ReactForm>
    );
  }
}

class RenderBody extends React.Component {
  state = {};

  _setState = (state) => {
    this.setState(state);
  };

  renderRowWrapper = (rowProps) => {
    let {
      setValue,
      setFocus,
      _getState,
      _setState,
      formExtraData,
      formProps,
    } = this.props;
    if (typeof formProps === 'function') {
      formProps = formProps(rowProps);
    }
    return {
      Container: NestedForm,
      containerProps: {
        ...rowProps,
        ...formProps,
        formExtraData,
        _getState,
        _setState,
        setValue,
        setFocus,
      },
    };
  };

  addRow = () => {
    const { addRow } = this.props;
    addRow && addRow({ _id: getNewId() });
  };

  removeRow = ({ item, index }) => {
    const { removeRow } = this.props;
    removeRow && removeRow({ item, index });
  };

  render() {
    const {
      value = [],
      skipTableHeaderOnNoData,
      listProps,
      editable = true,
      navigation,
      eventDispatcher,
      resolveVisible,
      formExtraData,
    } = this.props;
    if (skipTableHeaderOnNoData && !value.length) {
      return null;
    }

    let Component = Table;
    const componentProps = {
      addRow: this.addRow,
      removeRow: this.removeRow,
    };
    if (editable) {
      Component = EditableList;
      componentProps.renderRowWrapper = this.renderRowWrapper;
      componentProps.extraData = formExtraData;
    }
    return (
      <Component
        {...(isMobile ? NestedTableMobileFormTheme : NestedTableFormTheme)}
        data={value}
        minColumnWidth={50}
        hoverable={false}
        editable={editable}
        navigation={navigation}
        eventDispatcher={eventDispatcher}
        screenState={this.state}
        setScreenState={this._setState}
        resolveVisible={resolveVisible}
        skipRenderNoData={true}
        {...componentProps}
        {...listProps}
      />
    );
  }
}

class NestedTable extends React.Component {
  renderHeader = () => {
    let { header, renderHeader, addRow, ...restProps } = this.props;
    let headerProps = {
      ...restProps,
      addRow: () => {
        addRow({ _id: getNewId() });
      },
    };
    if (renderHeader) {
      return getRenderComponent(renderHeader, headerProps);
    } else if (header) {
      if (typeof header === 'function') {
        header = header(headerProps);
      }
      return (
        <ToolBar
          {...restProps}
          {...((isMobile && nestedTableHeaderMobileTheme) ||
            nestedTableHeaderTheme)}
          {...header}
        />
      );
    } else {
      return null;
    }
  };
  renderFooter = () => {
    let { footer, renderFooter, addRow, ...restProps } = this.props;
    let footerProps = {
      ...restProps,
      addRow: () => {
        addRow({ _id: getNewId() });
      },
    };
    if (renderFooter) {
      return getRenderComponent(renderFooter, footerProps);
    } else if (footer) {
      if (typeof footer === 'function') {
        footer = footer(footerProps);
      }
      return (
        <ToolBar
          {...restProps}
          {...((isMobile && nestedTableFooterMobileTheme) ||
            nestedTableFooterTheme)}
          {...footer}
        />
      );
    } else {
      return null;
    }
  };

  renderBody = () => {
    const { renderBody, ...restProps } = this.props;
    if (renderBody) {
      return getRenderComponent(renderBody, restProps);
    }
    return <RenderBody {...restProps} />;
  };

  render() {
    const { maxHeight, minHeight } = this.props;
    let containerStyle = { flex: 1, overflow: 'hidden' };
    if (maxHeight) {
      containerStyle.maxHeight = maxHeight;
    }
    if (minHeight) {
      containerStyle.minHeight = minHeight;
    }
    return (
      <View style={containerStyle}>
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFooter()}
      </View>
    );
  }
}

class NestedTableModal extends React.Component {
  renderModal = ({ frameStyle, hideModal }) => {
    const {
      icon,
      iconStyle,
      text,
      textStyle,
      title,
      modalProps,
      ...restProps
    } = this.props;
    const { containerStyle } = nestedTableModalTheme || {};
    return (
      <View
        style={{
          ...frameStyle,
          ...((modalProps && modalProps.dropdownStyle) || {}),
          ...containerStyle,
        }}>
        <ToolBar
          title={title}
          actions={[
            {
              icon: crossIcon,
              onPress: hideModal,
            },
          ]}
          {...nestedTableModalHeaderTheme}
        />
        <View
          style={{
            margin: 8,
            flex: 1,
            overflow: 'hidden',
          }}>
          <NestedTable {...restProps} />
        </View>
      </View>
    );
  };

  render() {
    const {
      containerStyle = { height: MIN_HEIGHT, alignItems: 'center' },
      icon,
      iconStyle,
      text,
      textStyle,
      modalProps,
      error,
    } = this.props;
    const { dropdownStyle, backdropStyle } = nestedTableModalTheme || {};

    return (
      <WithModal
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          cursor: 'pointer',
          ...(containerStyle || {}),
        }}
        renderModal={this.renderModal}
        dropdownStyle={dropdownStyle}
        backdropStyle={backdropStyle}
        updateStateRequired
        pushModal
        {...modalProps}>
        <View
          style={{
            flexDirection: 'row',
            ...(error
              ? { padding: 1, borderWidth: 1, borderColor: 'red' }
              : {}),
          }}>
          {icon ? (
            <Image
              style={{ resizeMode: 'contain', ...iconStyle }}
              source={icon}
            />
          ) : (
            void 0
          )}
          {text ? <Text style={textStyle}>{text}</Text> : void 0}
        </View>
      </WithModal>
    );
  }
}

export { NestedTable, NestedTableModal };
