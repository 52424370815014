/* eslint-disable import/no-cycle */
import React from 'react';
import { Text } from '@applane/react-core-components';
import { FormField } from '../../form/field';
import {
  AvatarRender,
  AvatarTextRender,
  CardRender,
  checkBoxRender,
  TextRender,
  TextAreaRender,
  NumberRender,
  OpenActionsRender,
  MoreActionsRender,
  CustomRender,
  RowSelectionRender,
  HeaderRowSelectionRender,
  HeaderTextRender,
  HeaderNumberRender,
  HeaderCustomRender,
  DateRangeRender,
  CurrencyRender,
  RecursiveRender,
  FileRender,
  SwitchRender,
  LinkRender,
} from '../../render-components/ListRenders';
import WithSort from '../sort/WithSort';
import WithAggregate from '../aggregate/WithAggregate';
import { getRenderComponent } from '../../UtilityFunctions';
import {
  customRenderTypes,
  customFooterRenderTypes,
} from './CustomCellRenders';

const renderTypes = {
  default: TextRender,
  custom: CustomRender,
  selection: RowSelectionRender,
  avatar: AvatarRender,
  avatarText: AvatarTextRender,
  card: CardRender,
  moreActions: MoreActionsRender,
  openActions: OpenActionsRender,
  text: TextRender,
  multiText: TextRender,
  textArea: TextAreaRender,
  number: NumberRender,
  date: TextRender,
  time: TextRender,
  file: FileRender,
  checkBox: checkBoxRender,
  autoSuggest: TextRender,
  autoSuggestAvatar: TextRender, // todo
  search: TextRender,
  searchAvatar: TextRender, // todo
  multiAutoSuggest: TextRender,
  multiAutoSuggestAvatar: TextRender, // todo
  multiSearch: TextRender,
  multiSearchAvatar: TextRender, // todo
  googlePlace: TextRender,
  switch: SwitchRender,
  toggle: TextRender,
  toggleSwitch: TextRender,
  radioGroup: TextRender,
  radio: TextRender,
  richText: TextAreaRender,
  progressSlider: TextRender,
  downloadFile: TextRender, // todo
  action: TextRender,
  dateRange: DateRangeRender,
  currency: CurrencyRender,
  recursive: RecursiveRender,
  autoSuggestOptions: TextRender,
  link: LinkRender,
  ...customRenderTypes,
};

const headerTypes = {
  selection: HeaderRowSelectionRender,
  default: HeaderTextRender,
  number: HeaderNumberRender,
  currency: HeaderNumberRender,
  text: HeaderTextRender,
  custom: HeaderCustomRender,
};

let HeaderCellRender = (props) => {
  let { column: { type, headerType } = {} } = props;
  if (headerType) {
    type = headerType;
  }
  if (type && headerTypes[type]) {
    return headerTypes[type](props);
  }
  return headerTypes.default(props);
};
const footerTypes = {
  default: HeaderTextRender,
  number: HeaderNumberRender,
  text: HeaderTextRender,
  currency: HeaderNumberRender,
  custom: HeaderCustomRender,
  ...customFooterRenderTypes,
};

HeaderCellRender = WithSort(WithAggregate(HeaderCellRender));

let FooterCellRender = (props) => {
  let { column: { type, footerType } = {} } = props;
  if (footerType) {
    type = footerType;
  }
  if (type && footerTypes[type]) {
    return footerTypes[type](props);
  }
  return footerTypes.default(props);
};

FooterCellRender = WithSort(WithAggregate(FooterCellRender));

export const CellRender = (props) => {
  const { column, ...restProps } = props;
  const {
    type, render, Component, componentProps, ...restColumn
  } = column;
  if (type && renderTypes[type]) {
    return renderTypes[type](props);
  }
  if (Component) {
    return getRenderComponent(Component, {
      ...restProps,
      ...restColumn,
      data: restProps.item,
      ...componentProps,
    });
  } if (render) {
    return render(props);
  }
  return type ? (
    <Text>
      No type registered :
      {type}
    </Text>
  ) : (
    renderTypes.default(props)
  );
};

export const EditableCellRender = (props) => {
  const { column, navigation, eventDispatcher } = props;
  let { header, editable, ...restColumn } = column;
  if (typeof editable === 'function') {
    editable = editable(props);
  }
  const { type } = restColumn;
  if (editable === false || type === 'action' || type === 'selection') {
    return CellRender(props);
  }
  return (
    <FormField
      variant="standard-compact-unbordered"
      navigation={navigation}
      eventDispatcher={eventDispatcher}
      {...restColumn}
    />
  );
};

export { HeaderCellRender, FooterCellRender };
