import React from 'react';
import {Text} from '@applane/react-core-components';

export default class TabItemCount extends React.Component {
  render() {
    let {
      tabKey,
      DataFetch,
      dataFetchProps,
      navigation,
      eventDispatcher,
      reloadEvent,
      countUri,
      resolveCount,
      countStyle,
      activeCountStyle,
      selected,
    } = this.props;
    if (countUri && typeof countUri === 'function') {
      countUri = countUri({navigation});
    }
    if (!DataFetch || !countUri) {
      return null;
    }
    if (dataFetchProps && typeof dataFetchProps === 'function') {
      dataFetchProps = dataFetchProps({
        navigation,
      });
    }
    if (selected) {
      countStyle = {
        ...countStyle,
        ...activeCountStyle,
      };
    }
    return (
      <DataFetch
        screenName={`TabItemCount-${tabKey}`}
        navigation={navigation}
        eventDispatcher={eventDispatcher}
        reloadEvent={reloadEvent}
        uri={countUri}
        {...dataFetchProps}>
        {result => {
          let Count = resolveCount ? resolveCount({...result}) : null;
          if (
            Count === undefined ||
            Count === null ||
            React.isValidElement(Count)
          ) {
            return Count;
          }
          if (typeof Count !== 'string' && Count.toString) {
            Count = Count.toString();
          }
          return <Text style={countStyle}>{Count}</Text>;
        }}
      </DataFetch>
    );
  }
}
