import React from 'react';
import {View, BasicTextInput, Platform} from '@applane/react-core-components';
import {
  WithFloatingLabel,
  WithInputWrapper,
  WithVariant,
  getInputProps,
  WithInputError,
} from '@applane/react-input-wrapper';

class TextInput extends React.Component {
  render() {
    let {value, style, ...restInputProps} = getInputProps(this.props);
    if (typeof value === 'number') {
      value = String(value);
    }
    return (
      <BasicTextInput
        {...restInputProps}
        value={value}
        keyboardType={'numeric'}
        style={{
          ...style,
          ...(Platform.OS === 'android'
            ? {alignSelf: 'center'}
            : {textAlign: 'center'}),
        }}
      />
    );
  }
}

TextInput = WithVariant(WithInputError(WithFloatingLabel(TextInput)));

class TimeInput extends React.Component {
  constructor(props) {
    super(props);
    let {value} = props;
    let state = this.getValue(value);
    this.state = state;
  }

  getValue = value => {
    let hrValue, minValue;
    if (value) {
      hrValue = Math.floor(value);
      minValue = 60 * value - 60 * Math.floor(value);
    }
    return {
      hrValue,
      minValue,
    };
  };

  onChangeValue = e => {
    let {onChangeValue, onChangeText} = this.props;
    let newValue = (this.state.hrValue || 0) + (this.state.minValue || 0) / 60;
    onChangeValue && onChangeValue(newValue, e);
    onChangeText && onChangeText(newValue);
  };

  onChangeHr = (value, e) => {
    if (value === '') {
      this.setState({hrValue: ''}, () => {
        this.onChangeValue(e);
      });
      return;
    }
    value = Number(value);
    if (isNaN(value)) {
      this.setState({
        hrValue: this.state.hrValue === undefined ? '' : this.state.hrValue,
      });
      return;
    }
    let {maxHr} = this.props;
    if (!maxHr || value < maxHr) {
      this.setState({hrValue: value}, () => {
        this.onChangeValue(e);
      });
    }
  };

  onChangeMin = (value, e) => {
    if (value === '') {
      this.setState({minValue: ''}, () => {
        this.onChangeValue(e);
      });
      return;
    }
    value = Number(value);
    if (isNaN(value)) {
      this.setState({
        minValue: this.state.minValue === undefined ? '' : this.state.minValue,
      });
      return;
    }
    if (value < 60) {
      this.setState({minValue: value}, () => {
        this.onChangeValue(e);
      });
    }
  };

  onFocus = e => {
    let {onFocus} = this.props;
    onFocus && onFocus(e);
  };

  onFocusHr = e => {
    this.setState({
      isHrActive: true,
    });
    this.onFocus(e);
  };

  onFocusMin = e => {
    this.setState({
      isMinActive: true,
    });
    this.onFocus(e);
  };

  onBlur = e => {
    let {onBlur} = this.props;
    onBlur && onBlur(e);
  };

  onBlurHr = e => {
    this.setState({
      isHrActive: false,
    });
    this.onBlur(e);
  };

  onBlurMin = e => {
    this.setState({
      isMinActive: false,
    });
    this.onBlur(e);
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      !this.state.isHrActive &&
      !this.state.isMinActive
    ) {
      let stateValue = this.getValue(this.props.value);
      this.setState(stateValue);
    }
  }
  render() {
    let {
      hrPlaceholder = 'Hr',
      minPlaceholder = 'Min',
      mandatory,
      renderSeparator,
      value,
      autoFocus,
      ...restProps
    } = this.props;
    if (mandatory) {
      if (hrPlaceholder) {
        hrPlaceholder = hrPlaceholder + '*';
      }
      if (minPlaceholder) {
        minPlaceholder = minPlaceholder + '*';
      }
    }
    let {isHrActive, isMinActive, hrValue, minValue} = this.state;

    let hrDisplayValue = hrValue;
    let minDisplayValue = minValue;
    if (!isHrActive && hrDisplayValue !== undefined && hrDisplayValue !== '') {
      hrDisplayValue = hrDisplayValue + ' Hr';
    }
    if (
      !isMinActive &&
      minDisplayValue !== undefined &&
      minDisplayValue !== ''
    ) {
      minDisplayValue = minDisplayValue + ' Min';
    }

    return (
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <TextInput
            {...restProps}
            autoFocus={autoFocus}
            placeholder={hrPlaceholder}
            isActive={isHrActive}
            value={hrDisplayValue}
            onChangeValue={this.onChangeHr}
            onFocus={this.onFocusHr}
            onBlur={this.onBlurHr}
          />
        </View>
        {renderSeparator}
        <View style={{flex: 1, overflow: 'hidden'}}>
          <TextInput
            {...restProps}
            placeholder={minPlaceholder}
            isActive={isMinActive}
            value={minDisplayValue}
            onChangeValue={this.onChangeMin}
            onFocus={this.onFocusMin}
            onBlur={this.onBlurMin}
          />
        </View>
      </View>
    );
  }
}

TimeInput = WithInputWrapper(TimeInput);

export default TimeInput;
