import React from 'react';
import StyleSheet from './StyleSheet';

const Image = ({source, resizeMode, style, ...rest}) => {
  if (!source) {
    return null;
  }
  if (Array.isArray(style)) {
    style = StyleSheet.flatten(style);
  }
  if (style && style.resizeMode) {
    style = {
      ...style,
    };
    resizeMode = style.resizeMode;
    delete style.resizeMode;
  }
  if (resizeMode && (!style || !style.objectFit)) {
    style = {
      ...style,
      objectFit: resizeMode,
    };
  }
  if (typeof source === 'function') {
    source = source();
  }
  if (typeof source === 'object') {
    if (source.spriteProps) {
      style = {...style, ...source.spriteProps};
    }
    source = source.uri;
  }
  return <img src={source} alt="" draggable={false} style={style} {...rest} />;
};

export default Image;
