import React from 'react';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
} from '@applane/react-core-components';
import {getRenderComponent} from './Utility';

export const HeaderCell = props => {
  let {column, _source_ = 'header'} = props;
  let columnTextStyle =
    props['textStyle'] || props[`${_source_}RowColumnTextStyle`];
  let columnSecondaryTextStyle =
    props['secondaryTextStyle'] ||
    props[`${_source_}RowColumnSecondaryTextStyle`];
  let columnIconStyle =
    props['iconStyle'] || props[`${_source_}RowColumnIconStyle`];
  let columnIconGap = props['iconGap'] || props[`${_source_}RowIconGap`] || 6;
  let columnIconPosition =
    props['iconPosition'] || props[`${_source_}RowIconPosition`] || 'left';
  let propAlign = props['align'];

  let {align: columnAlign = propAlign} = column || {};
  let headerInfo = column[_source_];
  if (!headerInfo) {
    return null;
  }
  if (typeof headerInfo === 'function') {
    headerInfo = headerInfo(props);
  }
  if (typeof headerInfo === 'string') {
    headerInfo = {label: headerInfo};
  }
  let {
    label,
    secondaryLabel,
    icon,
    render,
    align = columnAlign,
    containerStyle,
    iconStyle = columnIconStyle,
    textStyle = columnTextStyle,
    secondaryTextStyle = columnSecondaryTextStyle,
    iconGap = columnIconGap,
    iconPosition = columnIconPosition,
    onPress,
    resizeMode = 'contain',
    textTransform,
  } = headerInfo;
  let renderComponent = void 0;
  if (render) {
    renderComponent = getRenderComponent(render, props);
  } else {
    containerStyle = {
      flexDirection: 'row',
      ...containerStyle,
    };
    if (textTransform !== undefined) {
      textStyle = {
        ...textStyle,
        textTransform,
      };
    }
    let iconComponent = icon ? (
      <Image source={icon} style={iconStyle} resizeMode={resizeMode} />
    ) : (
      void 0
    );
    let valueComponent =
      label !== undefined ? <Text style={textStyle}>{label}</Text> : void 0;
    if (valueComponent && secondaryLabel) {
      valueComponent = (
        <View style={{flex: 1, overflow: 'hidden'}}>
          {valueComponent}
          <Text style={secondaryTextStyle || textStyle}>{secondaryLabel}</Text>
        </View>
      );
    }
    if (align === 'left') {
      containerStyle.justifyContent = 'flex-start';
    } else if (align === 'right') {
      containerStyle.justifyContent = 'flex-end';
    } else if (align === 'center') {
      containerStyle.justifyContent = 'center';
    } else if (valueComponent) {
      valueComponent = (
        <View style={{flex: 1, overflow: 'hidden'}}>{valueComponent}</View>
      );
    }
    let gapComponent =
      iconComponent && valueComponent ? (
        <View style={{width: iconGap}} />
      ) : (
        void 0
      );
    renderComponent = (
      <View style={containerStyle} title={label}>
        {iconPosition === 'left' && iconComponent ? (
          <React.Fragment>
            {iconComponent}
            {gapComponent}
          </React.Fragment>
        ) : (
          void 0
        )}
        {valueComponent}
        {iconPosition === 'right' && iconComponent ? (
          <React.Fragment>
            {gapComponent}
            {iconComponent}
          </React.Fragment>
        ) : (
          void 0
        )}
      </View>
    );
  }
  if (!renderComponent) {
    return null;
  }
  if (onPress) {
    renderComponent = (
      <TouchableOpacity style={{cursor: 'pointer'}} onPress={onPress}>
        {renderComponent}
      </TouchableOpacity>
    );
  }
  return renderComponent;
};

HeaderCell.defaultProps = {
  textStyle: {
    font: 14,
    fontWeight: 'bold',
    color: 'rgba(56, 56, 56, 0.37)',
    numberOfLines: 1,
  },
};

export default HeaderCell;
