export default {
  labels: {
    firstName: 'First Name',
    lastName: 'Last Name',
    gender: 'Gender',
    dob: 'Date of Birth',
    bloodGroup: 'Blood Group',
    maritalStatus: 'Marital Status',
    experience: 'Experience',
    mobile: 'Mobile No',
    email: 'Email ID',
    addressLine1: 'Address line 1',
    addressLine2: 'Address line 2',
    city: 'City',
    state: 'State/Province/Region',
    zipcode: 'Zip/Postal code',
    employeeId: 'Employee ID',
    department: 'Department',
    role: 'Role',
    speciality: 'Speciality',
    staffType: 'Staff Type',
    status: 'Status',
    shiftType: 'Shift Type',
    joiningDate: 'Joining Date',
    employeeType: 'Employee Type',
    bondDuration: 'Bond Duration (in Months)',
    noticePeriod: 'Notice Period (in Months)',
    panNumber: 'PAN Number',
    pfNumber: 'PF Number',
    account: 'Account No.',
    bank: 'Bank',
    ifsc: 'IFSC Code',
    paymentMode: 'Payment Mode',
    schedule: 'Schedule',
    leavePolicy: 'Leave Policy',
  },
};
