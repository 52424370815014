import { isMobile } from './app-components';
import {
  autosuggestFetch,
  googlePlaceFetch,
  getGooglePlaceDetail,
} from './AppServices';
import { roundCross, crossIcon } from './images';

const defaultProps = (props, fetchProps, extraProps) => {
  const {
    model,
    query,
    searchField,
    valueField,
    suggestionField,
    primaryField,
    secondaryField,
    avatarIcon,
    ...restProps
  } = props;
  const newProps = {
    valueField: valueField || suggestionField,
    sugestionField: suggestionField,
    renderSelector: true,
    renderRowProps: {
      primaryField: primaryField || suggestionField,
      secondaryField,
      avatarIcon,
    },
    modalPosition: isMobile ? 'screenBottom' : void 0,
    ...extraProps,
    ...restProps,
  };
  if (model && query && !newProps.fetch) {
    newProps.fetch = autosuggestFetch({
      model,
      id: query,
      search: searchField,
      ...fetchProps,
    });
  }
  return newProps;
};

export const autoSuggestInput = (props, fetchProps) => defaultProps(props, fetchProps, {
  type: 'autoSuggest',
  showArrow: true,
  floatLabelOnActive: false,
});

export const multiAutoSuggestInput = (props, fetchProps) => defaultProps(props, fetchProps, {
  type: 'multiAutoSuggest',
  showArrow: true,
  floatLabelOnActive: false,
  floatLabelOnValue: false,
  removeChipsIcon: roundCross,
  chipsVariant: 'rounded',
});

export const autoSuggestAvatarInput = (props, fetchProps) => defaultProps(props, fetchProps, {
  type: 'autoSuggestAvatar',
  showArrow: true,
  floatLabelOnActive: false,
});

export const multiAutoSuggestAvatarInput = (props, fetchProps) => defaultProps(props, fetchProps, {
  type: 'multiAutoSuggestAvatar',
  showArrow: true,
  floatLabelOnActive: false,
  floatLabelOnValue: false,
  removeChipsIcon: roundCross,
  chipsVariant: 'rounded',
});

export const autoSuggestOptionsInput = (props) => defaultProps(props, void 0, {
  type: 'autoSuggest',
  keyField: 'value',
  showArrow: true,
  floatLabelOnActive: false,
});

export const multiAutoSuggestOptionsInput = (props) => defaultProps(props, void 0, {
  type: 'multiAutoSuggest',
  keyField: 'value',
  showArrow: true,
  floatLabelOnValue: false,
  floatLabelOnActive: false,
  removeChipsIcon: roundCross,
  chipsVariant: 'rounded',
});

export const searchInput = (props, fetchProps) => defaultProps(props, fetchProps, {
  type: 'search',
  searching: true,
  removeChipsIcon: crossIcon,
});

export const searchAvatarInput = (props, fetchProps) => defaultProps(props, fetchProps, {
  type: 'searchAvatar',
  searching: true,
});

export const multiSearchInput = (props, fetchProps) => defaultProps(props, fetchProps, {
  type: 'multiSearch',
  searching: true,
  floatLabelOnValue: false,
  removeChipsIcon: roundCross,
  chipsVariant: 'rounded',
  skipRemoveValue: true,
});

export const multiSearchAvatarInput = (props, fetchProps) => defaultProps(props, fetchProps, {
  type: 'multiSearchAvatar',
  searching: true,
  removeChipsIcon: roundCross,
  chipsVariant: 'rounded',
  floatLabelOnValue: false,
  skipRemoveValue: true,
});

export const googlePlaceInput = (props) => {
  const {
    valueField = 'description',
    suggestionField = 'description',
    ...restProps
  } = props || {};
  return defaultProps({ ...restProps, valueField, suggestionField }, void 0, {
    type: 'googlePlace',
    fetch: googlePlaceFetch,
    modifySelectedData: getGooglePlaceDetail,
    searching: true,
  });
};

const defaultFilterProps = (props, fetchProps, extraProps) => {
  let {
    model,
    id,
    query,
    searchField,
    valueField,
    suggestionField,
    primaryField,
    secondaryField,
    avatarIcon,
    ...restProps
  } = props;

  const newProps = {
    valueField: valueField || suggestionField,
    sugestionField: suggestionField,
    renderSelector: true,
    primaryField: primaryField || suggestionField,
    secondaryField,
    avatarIcon,
    ...extraProps,
    ...restProps,
  };
  id = id || query;
  if (model && id && !newProps.fetch) {
    newProps.fetch = autosuggestFetch({
      model,
      id,
      search: searchField,
      ...fetchProps,
    });
  }
  return newProps;
};
export const autoSuggestFilter = (props, fetchProps) => defaultFilterProps(props, fetchProps, {
  type: 'autoSuggest',
});

export const autoSuggestOptionsFilter = (props, fetchProps) => defaultFilterProps(props, fetchProps, {
  type: 'autoSuggestOptions',
});

export const searchFilter = (props, fetchProps) => defaultFilterProps(props, fetchProps, {
  type: 'autoSuggest',
  searching: true,
});

export const searchOptionsFilter = (props, fetchProps) => defaultFilterProps(props, fetchProps, {
  type: 'autoSuggestOptions',
  searching: true,
});
