export default {
  label: {
    testComponentName: 'Test Component Name',
    testLinked: 'Test Linked',
    status: 'Status',
    unit: 'UNIT',
    edit: 'Edit',
    addComponent: 'Add Component',
    description: 'DESCRIPTION',
    testComponent: 'Test Component',
  },
  placeholder: {
    testComponentName: 'Test Component Name',
    testLinked: 'Test Linked',
    status: 'Status',
    unit: 'Unit',
  },
  header: {
    testComponentName: 'Test Component Name',
    testLinked: 'Test Linked',
    status: 'Status',
    unit: 'UNIT',
    name: 'Name',
  },
  title: {
    test: 'Test',
    testLinked: 'Test Linked',
    listOfTest: 'List of Tests in the component.',
    name: 'NAME',
  },
  message: {
    testComponentUpdated: 'Test Component Updated successfully!',
    testComponentAdded: 'Test Component Added successfully!',
    testComponentUnique: 'Test Component Already exists',
  },
  button: {},
};
