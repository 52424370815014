import React from 'react';

export const getInputProps = props => {
  let {
    value,
    placeholder,
    placeholderTextColor,
    getRef,
    inputStyle,
    disabled,
    editable,
    onFocus,
    onBlur,
    onChange,
    onChangeValue,
    onChangeText,
    onSubmitEditing,
    inputProps: propInputProps,
    maxRows,
    align,
    height,
    minHeight,
    maxHeight,
    autoFocus,
    caretHidden,
    showSoftInputOnFocus,
    onKeyPress,
    onPaste,
    textColor,
    onKeyUp,
    min,
    max,
    step,
  } = props;
  let inputProps = {
    style: inputStyle,
    value,
    placeholder,
    getRef,
    onFocus,
    onBlur,
    onChange,
    onChangeValue,
    onChangeText,
    min,
    max,
    step,
    ...propInputProps,
  };
  let extraStyle = {};
  if (placeholderTextColor !== undefined) {
    inputProps.placeholderTextColor = placeholderTextColor;
  }
  if (disabled !== undefined) {
    inputProps.disabled = disabled;
  }
  if (editable !== undefined) {
    inputProps.editable = editable;
  }
  if (maxRows !== undefined) {
    inputProps.maxRows = maxRows;
  }
  if (align !== undefined) {
    inputProps.textAlign = align;
  }
  if (autoFocus !== undefined) {
    inputProps.autoFocus = autoFocus;
  }
  if (caretHidden !== undefined) {
    inputProps.caretHidden = caretHidden;
  }
  if (showSoftInputOnFocus !== undefined) {
    inputProps.showSoftInputOnFocus = showSoftInputOnFocus;
  }
  if (onKeyPress !== undefined) {
    inputProps.onKeyPress = onKeyPress;
  }
  if (onPaste !== undefined) {
    inputProps.onPaste = onPaste;
  }
  if (onKeyUp !== undefined) {
    inputProps.onKeyUp = onKeyUp;
  }
  if (onSubmitEditing !== undefined) {
    inputProps.onSubmitEditing = onSubmitEditing;
  }
  if (height) {
    extraStyle.height = height;
  }
  if (minHeight) {
    extraStyle.minHeight = minHeight;
  }
  if (maxHeight) {
    extraStyle.maxHeight = maxHeight;
  }
  if (textColor) {
    extraStyle.color = textColor;
  }
  if (Object.keys(extraStyle).length) {
    inputProps.style = {
      ...inputProps.style,
      ...extraStyle,
    };
  }
  return inputProps;
};

export const getResolvedStyle = props => {
  let {
    isActive,
    hover,
    error,
    disabled,
    style,
    disabledStyle,
    activeStyle,
    hoverStyle,
    errorStyle,
  } = props;
  let resolvedStyle = void 0;
  if (disabled) {
    resolvedStyle = disabledStyle;
  } else if (error && errorStyle) {
    resolvedStyle = errorStyle;
  } else if (isActive && activeStyle) {
    resolvedStyle = activeStyle;
  } else if (hover && hoverStyle) {
    resolvedStyle = hoverStyle;
  }
  if (Array.isArray(style)) {
    style = [...style, resolvedStyle];
  } else {
    style = {...style, ...resolvedStyle};
  }
  return style;
};

export const getRenderComponent = (Component, props) => {
  if (!Component) {
    return;
  }
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, {...props});
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component({...props});
    }
  }
  return Component;
};

export const isFloatingLabel = props => {
  let {isActive, floatLabelOnActive, floatLabelOnValue, value} = props;
  if (isActive && floatLabelOnActive !== false) {
    return true;
  }
  if (
    floatLabelOnValue !== false &&
    value !== undefined &&
    value !== null &&
    (typeof value !== 'string' || value.trim().length > 0)
  ) {
    return true;
  }
};
