const colors = {
  themeColor: '#40c1de',
  defaultThemeColor: '#383838',
  darkBlackColor: '#000',
  tabBackgroundColor: 'rgba(231, 238, 245, 0.34)',
  topLabelColor: '#3c3c3c',

  lightBlack: '#616161',
  errorColor: 'red',
  blueButton1: '#57ccbf',
  /**new colors here */
  lightgreenTextColor: '#60d4a1',
  lightgreenTextColor7: '#73c5a7',

  tableHoverColor: '#f9f9f9',
  lightgreenTextColor40: 'rgba(96, 212, 161, 0.4)' /** #60d4a1 opacity 40% */,
  redTextColor: '#fc5a5a',
  redTextColor87: 'rgba(252, 90, 90, 0.87)' /** #fc5a5a opacity 87% */,
  activeColor: 'rgb(234, 121, 99)' /** #ea7963 opacity 0% */,
  darkTextColor: '#383838' /** #383838 opacity 0% */,
  textColor: 'rgba(56, 56, 56, 0.7)' /** #383838 opacity 70% */,
  textColor10: 'rgba(56, 56, 56, 0.21)' /** #383838 opacity 710% */,
  textColor20: 'rgba(56, 56, 56, 0.2)' /** #383838 opacity 70% */,
  textColor61: 'rgba(56, 56, 56, 0.61)' /** #383838 opacity 61% */,
  textColor70: 'rgba(56, 56, 56, 0.7)' /** #383838 opacity 70% */,
  textColor44: 'rgba(56, 56, 56, 0.44)' /** #383838 opacity 44% */,
  textColor87: 'rgba(56, 56, 56, 0.87)' /** #383838 opacity 87% */,
  textColor60: 'rgba(56, 56, 56, 0.6)' /** #383838 opacity 60% */,
  textColor37: 'rgba(56, 56, 56, 0.37)' /** #383838 opacity 37% */,
  textColor30: 'rgba(56, 56, 56, 0.30)' /** #383838 opacity 30% */,
  textColor54: 'rgba(56, 56, 56, 0.54)' /** #383838 opacity 54% */,
  textColor40: 'rgba(56, 56, 56, 0.4)' /** #383838 opacity 40% */,
  textColor49: 'rgba(56, 56, 56, 0.49)' /** #383838 opacity 49% */,
  textColor80: 'rgba(56, 56, 56, 0.80)' /** #383838 opacity 80% */,
  textColor15: 'rgba(252, 90, 90, 0.15)' /** #fc5a5a opacity 15% */,
  textColor34: 'rgba(56, 56, 56, 0.34)' /** #383838 opacity 34% */,
  backdropColor70: 'rgba(0,0,0, 0.70)' /**#000000 opcaity70% */,
  textColorRed: '#fc5a5a' /** #fc5a5a opacity 0% */,
  textColorGreen15: 'rgba(96, 212, 161, 0.15)' /** #60d4a1 opacity 15% */,
  textColorGreen: '#60d4a1' /** #60d4a1 opacity 0% */,
  shadowRed: '#4ab63c25',
  shadowBlack: '#19000000',
  textWhite: '#ffffff',
  lightTextColor: 'rgb(247, 247, 247)',
  textGreenColor: 'rgb(81, 211, 106)',
  textYellowColor: '#fcd04d',
  yellowColor: '#fcecbb',
  textRedColor: 'rgb(251, 129, 129)',
  textColor11: '#4f4f4f',
  nero: '#1a1a1a',
  neroLight: 'rgb(99, 97, 97)',
  greyishBrown: '#4c4c4c',
  brightBlue: 'rgb(44, 117, 192)',
  slateBlue: '#8f45d0',
  lightBlue: '#46a9fa',
  deActiveDateColor: '#d7d3d3',
  greyishbrown: '#4c4c4c',
  headerTextColor: 'rgb(143, 151, 141)',
  darkColor: 'rgb(42, 43, 41)',
  dashboardTextColor: '#4d4d4d',
  dashboardHeaderColor1: 'rgb(240, 75, 44)',
  dashboardHeaderColor2: 'rgb(243, 121, 45)',
  dashboardCardTextColor: 'rgb(114, 111, 111)',
  yellowColor58: '#FFBB28',
  caribbeanGreen: '#00C49F',
  dodgerBlue: '#0088FE',
  gainsBoro: '#d9d9d9',
  dimGray: 'rgb(102, 102, 102)',
  silverTree: '#71d398',
  wewak: '#ef8f94',
  barColor1: '#FF9999',
  barColor2: '#bfbfbf',
  lightCoral: '#ff8080',
  salmon: '#ff8566',
  sunshade: '#ffa64d',
  dashboardAnalyticsHeaderTextColor: 'rgb(116, 118, 112)',
  dashboardLiveHeaderTextColor: 'rgb(39, 39, 39)',
  grey: '#797979',
  lightBlue: '#6bc3f0',
  secondaryColor: '#8b8b8b',
  radioButtonColor: 'rgb(93, 82, 224)',
  greenButton: '#1fad6e',
  textColor50: 'rgba(56, 56, 56, 0.50)' /** #383838 opacity 50% */,
  textColor40: 'rgba(56, 56, 56, 0.40)' /** #383838 opacity 40% */,
  greenColor20: 'rgba(115, 206, 161, 0.2)' /**#73cea1 opacity 20% */,
  gray70: 'rgba(167, 167, 167, 0.7)',
  green22: 'rgba(74, 212, 158, 0.22)',
  lightGrey: '#f1f1f0',
  purpleColor: '#7764ca',
  blueTextColor87: 'rgba(64, 193, 222, 0.87)',
  labelHeaderTextColor: '#606060',
  textColorGrey: '#9a9a9a',
};

/// manaze color from zeplin
let manazeColors = {
  lightBlue: '#b9e5fe',
  blue: '#6d91f0',
  gray: '#707781',
  blackText: '#2b2836',
  purpleColor: '#7764ca',
  darkGreen: '#00a583',
  green: '#33d4a3',
  lightGreen: '#92fcc3',
  red: '#ff9c9c',
  lightRed: 'rgba(255, 156, 156, 0.53)',
};

const bgs = {
  cardHighlight: '#ffffff',
  cardBlack: '#1f1f1f',
  darkBlackColor: '#000000',
  borderColor: 'rgba(151, 151, 151, 0.1)',
  borderColor20: 'rgba(151, 151, 151, 0.2)',
  lightGray: '#c1c1bb21',
  greenColor: '#a8e8c5',
  darkGreen: '#4ac984',
  lightGreen: '#eafbf2',
  yellowColor: '#eac335c2',
  lightYellow: '#fce7a6',
  redColor: '#fb8e8f',
  lightRed: '#fcb9ba',
  red: '#ff1e1e',
  blackColor: 'rgb(26, 26, 26)',
  darkGray: '#b5b5b5',
  GrayColor: '#5d5b5b',
  lightGrayColor: '#f3f3f3',
  lightGrayColor8: '#f3f3f8',
  paleBlue: 'rgb(184, 234, 247)',
  paleBlueLight: 'rgb(244, 254, 253)',
  transparent: 'transparent',
  white2: 'rgb(253, 253, 253)',
  whiteBg: '#ffffff',
  backLightColor: '#fbf9fa',
  backTransparent: '#a9a9a933',
  backColor: 'rgb(251, 251, 251)',
  backHeaderColor: '#fafafa',
  borderDashedFile: 'rgb(224, 224, 224)',
  progressIndicatorColor: '#fcd80d',
  snow: '#fcfcfc',
  dashboardAnalyticsCardBg1: 'rgb(234, 251, 231)',
  dashboardAnalyticsCardBg2: 'rgb(240, 239, 254)',
  dashboardAnalyticsCardBg3: 'rgb(232, 251, 249)',
  dashboardAnalyticsCardBg4: 'rgb(244, 252, 238)',
  dashboardAnalyticsCardBg5: 'rgb(232, 249, 243)',
  dashboardAnalyticsCardBg6: 'rgb(254, 246, 239)',
  dashboardAnalyticsCardBg7: 'rgb(244, 242, 255)',
  dashboardAnalyticsCardBg8: 'rgb(244, 242, 255)',
  dashboardLiveCardBg1: 'rgb(252, 252, 252)',
  dashboardLiveCardBg2: 'rgb(255, 255, 255)',
  dashboardLiveCardBg3: 'rgb(242, 250, 252)',
  dashboardLiveCardBg4: 'rgb(255, 251, 240)',
  dashboardLiveCardBg5: 'rgb(253, 243, 243)',
  dashboardLiveCardBg6: 'rgb(253, 241, 240)',
  dashboardLiveCardBg7: 'rgb(252, 234, 233)',
  redColor70: 'rgba(255, 235, 235, 0.7)',
  criticalColor: '#fee3e2',
  heather: '#babdbf',
  lightgrey28: 'rgba(211, 211, 211, 0.28)',
  chatBg: 'rgba(96,212,161,0.34)',
  greenBorder: 'rgba(22,207,125,1)' /** "#16cf7d"*/,
  trollyGrey: '#808080',
  scheduleColor: '#96dff2',
  greenBg60: 'rgba(96, 212, 161, 0.6)' /** #60d4a1 opacity 60% */,
  grey20: 'rgba(219,219,219,0.2)', //#dbdbdb color, opacity 20,
  messageChipGreen: '#e3fef6',
  greyChatBg: '#30425b',
  lightgreenChatBg: '#184c4b',
  green25: 'rgba(78, 212, 160, 0.25)' /** #4ed4a0 opacity 25% */,
  blueColor: '#77d1ff',
  actionBgColor: '#5ccfe9',
  actionBg2: '#f0f0f7',
  roundedChipsbg: 'rgba(92, 207, 233, 0.2)',
  chatContainerBg: 'rgba(249, 249, 249, 0.34)',
  ownMessageBg: 'rgba(233, 233, 233, 1)', //#e9e9e9 opacity 100%
  onHoverBackgroundColor: 'rgba(216, 216, 216, 0.34)',
};

export {colors, bgs, manazeColors};
