import React from 'react';
import {View} from '@applane/react-core-components';
import {getRenderComponent} from './Utility';

const WithInputError = Component => {
  class InputError extends React.Component {
    render() {
      let {error, renderInputError, skipInputError, ...restProps} = this.props;
      let renderComponent = <Component {...restProps} />;
      if (!error || skipInputError || !renderInputError) {
        return renderComponent;
      }
      return (
        <View style={{flexDirection: 'row'}}>
          <View style={{flex: 1, overflow: 'hidden'}}>{renderComponent}</View>
          {getRenderComponent(renderInputError, this.props)}
        </View>
      );
    }
  }
  return InputError;
};

export default WithInputError;
