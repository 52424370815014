
export const isFilterVisible = ({navigation} = {}) => {
    if (navigation) {
      const routeCount = navigation.getRouteCount && navigation.getRouteCount();
      const routeIndex = navigation.getIndex && navigation.getIndex();
      if (routeCount && routeIndex !== undefined && routeIndex < routeCount - 1) {
        return false;
      }
    }
    return true;
  };
  