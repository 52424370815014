import React from 'react';

const {
  View, TouchableOpacity, Text, Linking,
} = require('../../app-components');
const { getDownloadUrl } = require('../../AppServices');

const textStyle = {
  textDecoration: 'underline',
  color: '#4070C9',
  fontStyle: 'italic',
  fontWeight: 'normal',
  fontSize: '16px',
  lineHeight: '30px',
  textDecorationLine: 'underline',
  overflow: 'hidden',
};

export default ({ file = {} }) => {
  const { name, key } = file;
  const downloadUrl = getDownloadUrl({ name, key, openInNewTab: true });
  return (
    <View
      style={{
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <TouchableOpacity
        style={{ cursor: 'pointer' }}
        onPress={() => Linking.openURL(downloadUrl, '_blank')}
        onClick={(e) => e && e.stopPropagation && e.stopPropagation()}
      >
        <Text style={textStyle}>{name || key || ''}</Text>
      </TouchableOpacity>
    </View>
  );
};
