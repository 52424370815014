import { manazeV5Colors } from './manazeV5Colors';
import { manazeV6Colors } from './manazeV6Colors';
import {
  Headline8, Headline7, Headline9, Headline10,
} from './Headlines';
import { bgs, colors } from './colors';
import { h14_Medium } from './fonts';
import vars from './vars';

const { roundedChipsbg } = bgs;
const { blueTextColor87, textColor40 } = colors;
const {
  neutral_500_medium_surface,
  neutral_100_background,
  secondary_300_main,
  neutral_50,
  Surface,
} = manazeV5Colors;
const { primary_5, primary_2, neutral_1 } = manazeV6Colors;
export const toggleLabelTheme = {
  labelStyle: {
    paddingRight: 16,
    ...Headline8,
    color: neutral_500_medium_surface,
  },
};

export const toggleTheme = {
  itemClassName: 'toggle-item-style',
  selectedItemClassName: 'toggle-selected-item-style',
  style: {
    borderRadius: 4,
    backgroundColor: neutral_100_background,
  },
  itemStyle: {
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 16,
    paddingRight: 16,
    borderWidth: 1,
    borderColor: 'transparent',
    cursor: 'pointer',
  },
  selectedItemStyle: {
    borderRadius: 4,
    borderColor: secondary_300_main,
    backgroundColor: neutral_50,
  },
  optionStyle: {
    textAlign: 'center',
    ...Headline9,
    color: neutral_500_medium_surface,
  },
  selectedOptionStyle: {
    ...Headline7,
    color: secondary_300_main,
  },
};

export const toggleSwitchTheme = {
  height: 23,
  width: 48,
  borderRadius: 11.5,
  thumbSize: 19,
  tintColor: vars.colors.error,
  activeTintColor: vars.colors.primary.color2,
  thumbColor: vars.colors.width,
  onLabelStyle: {
    paddingLeft: 16,
    ...Headline8,
    color: neutral_500_medium_surface,
  },
  offLabelStyle: {
    paddingRight: 16,
    ...Headline8,
    color: neutral_500_medium_surface,
  },
  selectedLabelStyle: {
    color: secondary_300_main,
  },
};

export const dateFilterToogleTheme = {
  itemClassName: 'date-toggle-item-style',
  selectedItemClassName: 'date-toggle-selected-item-style',
  style: {
    borderRadius: 20,
    backgroundColor: primary_5,
    height: 38,
  },
  itemStyle: {
    paddingTop: 11,
    paddingBottom: 11,
    paddingLeft: 16,
    paddingRight: 16,
    borderWidth: 1,
    borderColor: 'transparent',
    cursor: 'pointer',
  },
  selectedItemStyle: {
    borderRadius: 20,
    borderColor: primary_2,
    // backgroundColor: neutral_50,
  },
  optionStyle: {
    textAlign: 'center',
    ...Headline10,
    color: neutral_1,
  },
  selectedOptionStyle: {
    ...Headline9,
    color: primary_2,
  },
};

export const inputToggleTheme = {
  ...toggleTheme,
  itemStyle: {
    ...toggleTheme.itemStyle,
    minWidth: 100,
  },
};

export const inputToggleSwitchTheme = {
  ...toggleSwitchTheme,
  style: {
    ...toggleSwitchTheme.style,
    minHeight: 38,
  },
};

export const inputChipsToggleTheme = {
  style: {},
  itemStyle: {
    paddingTop: 9,
    paddingBottom: 8,
    paddingLeft: 18,
    paddingRight: 18,
    borderWidth: 1,
    borderRadius: 19,
    marginRight: 8,
    borderColor: '#e1e1e1',
    cursor: 'pointer',
    backgroundColor: vars.colors.secondary.color4,
  },
  selectedItemStyle: {
    borderColor: roundedChipsbg,
    backgroundColor: vars.colors.secondary.color1,
  },
  optionStyle: {
    ...vars.headings.h14,
    color: vars.colors.grey.color1,
  },
  selectedOptionStyle: {
    ...vars.headings.h14,
    color: vars.colors.white,
  },
};
