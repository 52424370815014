export default {
  headers: {
    componentName: 'Component Name',
    componentType: 'Component type',
    status: 'Taxation Status',
    componentDependance: 'Component dependance',
    calculationBase: 'Calculation Base',
    payTo: 'Pay To',
    action: 'Action',
  },
  buttons: {
    add: 'Add New',
  },
};
