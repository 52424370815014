import React, {Component} from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Keyboard,
  Platform,
  StyleSheet,
} from '@applane/react-core-components';
import PropTypes from 'prop-types';
import {withAppKeyboardListenerContext} from '@applane/react-app-keyboard-listener';
import {withModalContext} from '@applane/react-modal-view';
import AutoSuggestWithSearchInput from './AutoSuggestWithSearchInput';
import AutoSuggestInputEditor from './AutoSuggestInputEditor';
import {getRenderComponent} from './Utility';

class AutosuggestInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    valueField: PropTypes.string,
    sugestionField: PropTypes.string,
    options: PropTypes.array,
    minChar: PropTypes.number,
    accessible: PropTypes.bool,
    searching: PropTypes.bool,
    animated: PropTypes.bool,
    showsVerticalScrollIndicator: PropTypes.bool,
    keyboardShouldPersistTaps: PropTypes.string,
    style: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
    ]),
    renderRow: PropTypes.func,
    onDropdownWillShow: PropTypes.func,
    onDropdownWillHide: PropTypes.func,
    onItemSelect: PropTypes.func,
  };

  show = () => {
    this.props.setModal &&
      this.props.setModal.setModalState({
        renderModal: this._renderModal,
        showModal: true,
        closeModal: this.hide,
      });
  };

  hide = () => {
    this.props.setModal &&
      this.props.setModal.setModalState({
        renderModal: null,
        showModal: false,
        replace: true,
      });
  };

  setButtonText = value => {
    this.buttonText = value;
  };

  onFocus = e => {
    const {onFocus} = this.props;
    onFocus && onFocus(e);
    this.show();
  };

  onBlur = e => {
    const {onBlur} = this.props;
    this.hide();
    onBlur && onBlur(e);
    this.setButtonText(void 0);
  };

  _onRequestClose = e => {
    const {onDropdownWillHide} = this.props;
    if (!onDropdownWillHide || onDropdownWillHide() !== false) {
      this.hide();
    }
    Keyboard && Keyboard.dismiss();
    this.onBlur(e);
  };

  _renderModal = ({frameStyle} = {}) => {
    let {autoSuggestModalTheme: {dropdownStyle} = {}} = this.props;
    const {accessible} = this.props;
    return (
      <TouchableOpacity
        accessible={accessible}
        activeOpacity={1}
        onPress={e => {
          if (this.props.autoHide !== false) {
            this._onRequestClose(e);
          }
        }}
        style={{
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          position: 'absolute',
          backgroundColor: 'rgba(52, 52, 52, 0.1)',
        }}>
        <TouchableOpacity
          style={[dropdownStyle, frameStyle]}
          activeOpacity={1}
          onPress={() => {}}>
          <AutoSuggestWithSearchInput
            {...this.props}
            hideModal={this._onRequestClose}
            autoFocus
          />
        </TouchableOpacity>
      </TouchableOpacity>
    );
  };

  clearValue = e => {
    this.props.clearValue && this.props.clearValue(e);
    this.onBlur(e);
  };

  renderSelector = () => {
    let {
      showArrow,
      arrowDownIcon,
      inputSelectorContainerStyle,
      inputSelectorIconContainerStyle,
    } = this.props;
    if (!showArrow) {
      return null;
    }
    return (
      <View style={inputSelectorContainerStyle}>
        <TouchableOpacity style={inputSelectorIconContainerStyle}>
          <Image
            source={arrowDownIcon}
            style={{
              maxWidth: 16,
              maxHeight: 16,
            }}
          />
        </TouchableOpacity>
      </View>
    );
  };

  _onButtonPress = e => {
    e.preventDefault();
    const {onDropdownWillShow} = this.props;
    onDropdownWillShow && onDropdownWillShow();
    this.onFocus(e);
  };

  _renderButton() {
    let {
      disabled,
      accessible,
      children,
      value,
      getDisplayValue,
      renderSelector,
    } = this.props;
    let buttonComponent = children;
    let displayValue = getDisplayValue ? getDisplayValue(value) : value;
    if (!buttonComponent) {
      if (
        value === this.oldValue &&
        (this.buttonText || this.buttonText === '')
      ) {
        displayValue = this.buttonText;
      } else {
        this.buttonText = void 0;
      }
      this.oldValue = value;
      let textComponent = (
        <AutoSuggestInputEditor
          {...this.props}
          loading={this.fetching}
          displayValue={displayValue || ''}
          caretHidden={true}
          showSoftInputOnFocus={false}
          editable={true}
          onFocus={this.onFocus}
        />
      );
      buttonComponent = (
        <View style={{flexDirection: 'row'}}>
          <View style={{flex: 1, overflow: 'hidden'}}>{textComponent}</View>
          {getRenderComponent(renderSelector || this.renderSelector, {
            ...this.props,
            loading: this.fetching,
            clearValue: this.clearValue,
          })}
        </View>
      );
    }
    let touchProps = {};
    if (Platform.OS === 'web') {
      touchProps.onMouseDown = this._onButtonPress;
    } else {
      touchProps.onPress = this._onButtonPress;
    }
    return (
      <TouchableOpacity
        disabled={disabled}
        accessible={accessible}
        {...touchProps}>
        {buttonComponent}
      </TouchableOpacity>
    );
  }

  render() {
    return this._renderButton();
  }
}

AutosuggestInput.defaultProps = {
  disabled: false,
  placeholder: 'Please select...',
  animated: true,
  showsVerticalScrollIndicator: true,
  keyboardShouldPersistTaps: 'always',
  searching: false,
  minChar: 0,
  separatorStyle: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: 'lightgray',
  },
  arrowIconStyle: {
    height: 10,
    width: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default withAppKeyboardListenerContext(
  withModalContext(AutosuggestInput),
);
