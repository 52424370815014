import React from 'react';
import isFunction from 'lodash/isFunction';
import { StandardButton, ActionButton, ButtonLoader } from '../buttons/Buttons';
import { saveButtonTheme } from '../../theme/actionTheme';
import { Text } from '..';
import vars from '../../theme/vars';

export const save = ({
  id, label, text, role, ...restProps
} = {}) => {
  label = label || text || 'Save';
  if (!id && typeof label === 'string') {
    id = label;
  }
  return {
    id: label,
    label,
    type: 'save',
    role: role || 'edit',
    render: ({ state } = {}) => {
      if (typeof label === 'function') {
        label = label({ data: state.data });
      }
      return (
        <ActionButton
          submitting={!!state?.submitting}
          label={label}
          theme={saveButtonTheme}
          {...restProps}
        />
      );
    },
  };
};

export const saveAction = (props = {}) => {
  let { uid, eventDispatcher, action } = props;

  if (isFunction(uid)) {
    uid = uid();
  }

  if (!uid && !eventDispatcher) {
    throw new Error(
      'EventDispatcher and uid is mandatory while pressing save action',
    );
  }
  eventDispatcher.notify(`${uid}-submit`, {
    action,
  });
};

export const clearSelection = {
  render: () => (
    <Text
      style={[
        vars.headings.h9,
        {
          color: vars.colors.secondary.color2,
          textDecoration: 'underline',
        },
      ]}
    >
      Clear Selection
    </Text>
  ),
  onPress: (props) => {
    const { setScreenState } = props;
    if (isFunction(setScreenState)) {
      setScreenState({ selectedIds: [], allPageSelected: false });
    }
  },
};

export const selectAll = {
  render: () => (
    <Text
      style={[
        vars.headings.h9,
        {
          color: vars.colors.secondary.color2,
          textDecoration: 'underline',
        },
      ]}
    >
      Select All
    </Text>
  ),
  onPress: (props) => {
    const { setScreenState } = props;
    if (isFunction(setScreenState)) {
      setScreenState({ allPageSelected: true });
    }
  },
};

export const showSelectedCount = (
  props,
) => {
  const { state: { selectedIds = [], allPageSelected } = {}, aggregates } = props;

  return {
    render: () => (
      <Text
        style={[
          vars.headings.h13,
          {
            color: vars.colors.color3,
          },
        ]}
      >
        {allPageSelected ? aggregates?._count : selectedIds?.length }
        {' '}
        of
        {' '}
        {aggregates?._count}
        {' '}
        selected
      </Text>
    ),
  };
};
