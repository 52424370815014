import moment from 'moment';
import {resolveExp, numberFormatter} from '../UtilityFunctions';

const getOptionValue = ({value, options}) => {
  for (let option of options) {
    if (typeof option === 'object') {
      if (option.value === value) {
        return option.label || option.value;
      }
    }
  }
  return value;
};

const resolveOptionsValue = ({value, options}) => {
  if (Array.isArray(value)) {
    return value.map(_value => getOptionValue({value: _value, options}));
  } else {
    return getOptionValue({value, options});
  }
};

const resolveValue = ({value, valueField, options, separator = '; '}) => {
  if (!value) {
    return value;
  }
  if (options) {
    value = resolveOptionsValue({value, options});
  } else if (typeof value === 'object' && valueField) {
    value = resolveExp(value, valueField);
  }
  if (Array.isArray(value)) {
    value = value.join(separator);
  }
  return value;
};

const resolveToggleValue = props => {
  let {value, options = []} = props;
  if (!value) {
    return value;
  }
  options.forEach(option => {
    if (typeof option === 'object') {
      if (option.value === value) {
        value = option.label || option.value;
      }
    }
  });
  return value || '';
};

export default {
  number: props => {
    let {value, toFixed, format} = props;
    if (!value) {
      return '--';
    }
    value = Number(value);
    if (!isNaN(value) && toFixed) {
      value = value.toFixed(toFixed);
    }
    if (!isNaN(value) && format) {
      value = numberFormatter(value, format);
    }
    return value;
  },
  date: props => {
    let {value, format = 'DD MMM YY'} = props;
    if (!value || !format) {
      return value;
    }
    return moment(value).format(format);
  },
  time: props => {
    let {value, toFixed = 2} = props;
    if (!value) {
      return value;
    }
    if (typeof value === 'number') {
      value = value.toFixed(toFixed);
    }
    return value;
  },
  multiText: resolveValue,
  autoSuggest: resolveValue,
  autoSuggestAvatar: resolveValue,
  search: resolveValue,
  searchAvatar: resolveValue,
  multiAutoSuggest: resolveValue,
  multiAutoSuggestAvatar: resolveValue,
  multiSearch: resolveValue,
  multiSearchAvatar: resolveValue,
  googlePlace: resolveValue,
  toggle: resolveToggleValue,
  toggleSwitch: resolveToggleValue,
  radio: resolveToggleValue,
  radioGroup: resolveToggleValue,
  switch: resolveToggleValue,
  file: props => {
    return resolveValue({...props, valueField: 'name'});
  },
};
