import React from "react";
import { WithInputWrapper, WithVariant } from "@applane/react-input-wrapper";
import AutosuggestInput from "./AutosuggestInput";
import AutoSuggestBottomInput from "./AutoSuggestBottomInput";
import {
  getOptionValue,
  getOptionDisplay,
  findValue,
  resolveDottedValue,
} from "./Utility";

class AutoSuggestInputFieldComponent extends React.Component {
  onChangeValue = (value, e) => {
    const { onChangeValue, onChangeText } = this.props;
    onChangeValue && onChangeValue(value, e);
    onChangeText && onChangeText(value);
  };

  onSelect = ({ item, e }) => {
    const { onItemSelect, modifyValueOnSelect, getDisplayValue } = this.props;
    let newValue = item;
    if (modifyValueOnSelect) {
      newValue = modifyValueOnSelect({ item });
    }
    this.onChangeValue(newValue, e);
    onItemSelect && onItemSelect({ item, props: this.props });
    return getDisplayValue ? getDisplayValue(newValue) : newValue;
  };

  clearValue = (e) => {
    this.onChangeValue(null, e);
    this.props.clearValue && this.props.clearValue(e);
  };

  onRenderNoDataCallback = ({ result } = {}) => {
    if (result) {
      this.onChangeValue(result);
    }
  };

  renderNoData = (searchProps) => {
    const { renderNoData } = this.props;
    if (!renderNoData) {
      return;
    }
    return renderNoData({
      ...searchProps,
      onSubmitCallback: this.onRenderNoDataCallback,
    });
  };

  render() {
    const { modifyValueOnSelect, modalPosition, ...rest } = this.props;
    const AutoSuggestComponent =
      modalPosition === "screenBottom"
        ? AutoSuggestBottomInput
        : AutosuggestInput;
    return (
      <AutoSuggestComponent
        {...rest}
        keyExtractor={this.keyExtractor}
        onItemSelect={this.onSelect}
        clearValue={this.clearValue}
        renderNoData={this.renderNoData}
      />
    );
  }
}

AutoSuggestInputFieldComponent = WithInputWrapper(
  WithVariant(AutoSuggestInputFieldComponent),
);

class AutoSuggestInputField extends React.Component {
  getDisplayValue = (item) => {
    let { valueField, getDisplayValue } = this.props;
    if (item === undefined || item === null) {
      return "";
    }
    if (typeof getDisplayValue === "function") {
      item = getDisplayValue({ value: item });
    } else if (valueField && typeof item === "object") {
      item = resolveDottedValue(item, valueField);
    }
    return item;
  };

  isHighlighted = ({ item }) => {
    let { value, idField = "_id" } = this.props;
    return findValue({ value, item, idField });
  };

  fetch = async (searchProps) => {
    try {
      let { fetch, data, getData, fetchProps } = this.props;
      if (!data && getData) {
        data = getData();
      }
      let result = fetch
        ? await fetch({ ...searchProps, ...fetchProps, data })
        : void 0;
      return (result && result.data) || [];
    } catch (err) {
      return [];
    }
  };

  render() {
    let { valueField, ...restProps } = this.props;
    return (
      <AutoSuggestInputFieldComponent
        {...restProps}
        getDisplayValue={this.getDisplayValue}
        isHighlighted={this.isHighlighted}
        fetch={this.fetch}
      />
    );
  }
}

class AutoSuggestOptionsInputField extends React.Component {
  getDisplayValue = (item) => {
    let { valueField, keyField, options } = this.props;
    if (item === undefined || item === null) {
      return "";
    }
    if (valueField && options) {
      for (let option of options) {
        if (getOptionValue({ item: option, keyField }) === item) {
          return getOptionDisplay({ item: option, keyField, valueField });
        }
      }
    }
    return item;
  };

  modifyValueOnSelect = ({ item }) => {
    let { keyField } = this.props;
    return getOptionValue({ item, keyField });
  };

  isHighlighted = ({ item }) => {
    let { value, keyField } = this.props;
    item = getOptionValue({ item, keyField });
    return findValue({ value, item });
  };

  fetch = ({ searchValue } = {}) => {
    let { options, keyField, searchField, valueField } = this.props;
    if (searchValue) {
      let regEx = new RegExp(`^${searchValue}|(?<= )${searchValue}`, "i");
      options = options.filter((item) => {
        let itemValue = getOptionDisplay({
          item,
          keyField,
          valueField: searchField || valueField,
        });
        if (itemValue && regEx.test(itemValue)) {
          return true;
        }
      });
    }
    return options;
  };

  render() {
    let { valueField, ...restProps } = this.props;
    return (
      <AutoSuggestInputFieldComponent
        {...restProps}
        getDisplayValue={this.getDisplayValue}
        modifyValueOnSelect={this.modifyValueOnSelect}
        isHighlighted={this.isHighlighted}
        fetch={this.fetch}
      />
    );
  }
}

export default (props) => {
  let { options, ...restProps } = props;
  if (options) {
    if (typeof options === "function") {
      options = options(restProps);
    }
    return <AutoSuggestOptionsInputField {...restProps} options={options} />;
  } else {
    return <AutoSuggestInputField {...restProps} />;
  }
};
