const colors = {
  primary: {
    color1: '#307343',
    color2: '#55B971',
    color3: '#B7E1C3',
    color4: '#D4F6De',
    color5: '#F0FFF4',
  },
  secondary: {
    color1: '#192F59',
    color2: '#4070C9',
    color3: '#80A0DB',
    color4: '#EFF3FB',
  },
  grey: {
    color1: '#333333',
    color2: '#4F4F4F',
    color3: '#828282',
    color4: '#BDBDBD',
    color5: '#E0E0E0',
    color6: '#F2F2F2',
    color7: '#383838',
    color8: '#FAFAFA' /* Alternate BG || neutral 10 */,
  },
  error: '#EB5757',
  warning: '#F2994A',
  emphasis: '#F2C94C',
  white: '#FFFFFF',
};

const neutrals = {
  text1: '#333333',
  text2: '#4F4F4F',
  placeholder: '#828282',
  disabled: '#BDBDBD',
  hover: '#E0E0E0',
  divider: '#F2F2F2',
  color3: '#6E6E6E',
};

const headings = {
  h1: {
    fontFamily: 'public_sans_medium',
    fontSize: 66,
    lineHeight: '78px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h2: {
    fontFamily: 'public_sans_regular',
    fontSize: 42,
    lineHeight: '49px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h3: {
    fontFamily: 'public_sans_medium',
    lineHeight: '28px',
    letterSpacing: '0em',
    fontSize: 24,
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h4: {
    fontFamily: 'public_sans_regular',
    letterSpacing: '0em',
    fontSize: 24,
    lineHeight: '28px',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h5: {
    fontFamily: 'public_sans_medium',
    fontStyle: 'normal',
    letterSpacing: '0em',
    fontSize: 18,
    lineHeight: '21px',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h6: {
    fontFamily: 'public_sans_regular',
    letterSpacing: '0em',
    fontSize: 18,
    lineHeight: '21px',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h7: {
    fontFamily: 'public_sans_bold',
    fontStyle: 'normal',
    letterSpacing: '0em',
    fontSize: 14,
    lineHeight: '16px',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h8: {
    fontFamily: 'public_sans_medium',
    letterSpacing: '0em',
    fontSize: 14,
    lineHeight: '16px',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h9: {
    fontFamily: 'public_sans_regular',
    fontStyle: 'normal',
    letterSpacing: '0em',
    fontSize: 14,
    lineHeight: '16px',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h10: {
    fontFamily: 'public_sans_light',
    letterSpacing: '0em',
    fontSize: 14,
    lineHeight: '16px',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h11: {
    fontFamily: 'public_sans_regular_i',
    letterSpacing: '0em',
    fontSize: 14,
    lineHeight: '16px',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h12: {
    fontFamily: 'public_sans_bold',
    letterSpacing: '0em',
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h13: {
    fontFamily: 'public_sans_regular',
    letterSpacing: '0em',
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h14: {
    fontFamily: 'public_sans_light',
    letterSpacing: '0em',
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h15: {
    fontFamily: 'public_sans_regular_i',
    letterSpacing: '0em',
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h16: {
    fontFamily: 'public_sans_medium',
    letterSpacing: '0em',
    fontSize: 10,
    lineHeight: '12px',
    textAlign: 'left',
    color: '#2C2C2C',
  },
  h17: {
    fontFamily: 'public_sans_regular',
    letterSpacing: '0em',
    fontSize: 10,
    lineHeight: '12px',
    textAlign: 'left',
    color: '#2C2C2C',
    textTransform: 'uppercase',
  },
  h18: {
    fontFamily: 'public_sans_regular',
    letterSpacing: '0em',
    fontSize: 10,
    lineHeight: '12px',
    textAlign: 'left',
    color: '#000000',
  },
  h19: {
    fontFamily: 'public_sans',
    letterSpacing: '0em',
    fontSize: 14,
    lineHeight: '17px',
    textAlign: 'left',
  },
};

const gradients = {
  primary: {
    bg1: 'linear-gradient(180deg, #307343 0%, #55B971 100%)',
  },
  secondary: {
    bg1: 'linear-gradient(180deg, #192F59 0%, #4070C9 100%)',
  },
};

const vars = {
  colors,
  gradients,
  neutrals,
  headings,
};

export default vars;
