import React from 'react';
import {View} from '@applane/react-core-components';
import {Headline9} from '../../theme/Headlines';
import {manazeV6Colors} from '../../theme/manazeV6Colors';

const {primary_2} = manazeV6Colors;

export const OpenActions = props => {
  let {
    openActions,
    style,
    actionStyle,
    align,
    Action,
    renderAction,
    resolveVisible,
    ...restProps
  } = props;
  if (openActions && typeof openActions === 'function') {
    openActions = openActions(restProps);
  }
  if (!openActions || !openActions.length) {
    return null;
  }
  const rowContainerStyle = {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    ...style,
  };
  if (align === 'center') {
    rowContainerStyle.justifyContent = 'center';
  } else if (align === 'right') {
    rowContainerStyle.justifyContent = 'flex-end';
  }
  return (
    <View style={rowContainerStyle}>
      {openActions.map((action, index) => {
        if (action && typeof action === 'function') {
          action = action(restProps);
        }
        if (!action) {
          return null;
        }
        let {
          style,
          visible,
          textStyle = {
            ...Headline9,
            color: primary_2,
          },
          ...restAction
        } = action;
        if (visible !== undefined) {
          if (typeof visible === 'function') {
            visible = visible({
              ...restProps,
            });
          }
          if (!visible) {
            return null;
          }
        }
        let islastIndex = index === openActions.length - 1;
        return (
          <Action
            style={
              style ||
              actionStyle || {
                paddingLeft: index === 0 ? 0 : 12,
                paddingRight: islastIndex ? 0 : 12,
              }
            }
            action={restAction}
            {...restProps}>
            {renderAction({textStyle, ...restAction})}
          </Action>
        );
      })}
    </View>
  );
};
