export default {
  labels: {
    doctor: 'Doctor',
    speciality: 'Speciality',
    department: 'Department',
    room: 'Room No',
    viewDetails: 'View Details',
  },
  placeholders: {

  },
  title: {

  },
  messages: {
    consultationUpdatedSuccessfully: 'Consultation Updated successfully',
    roomAssignedSuccessfully: 'Room assigned successfully',
    tokenAssignedSuccessfully: 'Token assigned successfully',
    assignAToken: 'Please assign a token to patient.',
  },
};
