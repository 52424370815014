import React, { useState } from 'react';
import moment from 'moment';

import {
  ActivityIndicator,
  TouchableOpacity,
  View,
  Text,
  Image,
} from '../../../app-components';
import { colors } from '../../../theme/colors';
import { closeIcon, delete_Image } from '../../../images';
import { h14_AvenirNext, h14_Medium } from '../../../theme/fonts';
import { invoke } from '../../../AppServices';

const CancelModal = (props) => {
  const [loading, setLoading] = useState(false);
  const { navigation = {} } = props || {};
  const {
    state: { params: { item = {}, t, appointmentDatas = () => {} } = {} } = {},
  } = navigation || {};
  const cancel = () => {
    navigation && navigation.pop();
  };

  const { textColor70, themeColor } = colors;

  return (
    <TouchableOpacity
      style={{
        height: 30,
        padding: 15,
        // paddingLeft: 20,
        // paddingTop: 20,
        // paddingRight: 10,
        // paddingBottom: 35,
      }}
      // style={deleteContainerViewStyle}
      onPress={(event) => event.stopPropagation()}
    >
      <TouchableOpacity
        onPress={cancel}
        style={{
          alignSelf: 'flex-end',
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image source={closeIcon} style={{ objectFit: 'contains' }} />
      </TouchableOpacity>

      <View
        style={{
          paddingLeft: 10,
          justifyContent: 'center',
          paddingRight: 10,
        }}
      >
        <View style={{ flexDirection: 'row' }}>
          <View style={{ maxWidth: 193 }}>
            {/* <Text
              style={{
                color: '#f14f4f',
                ...h24_AvenirNext_500,
                opacity: 0.87,
                paddingBottom: 10,
              }}></Text> */}
            <Text
              style={{
                // ...h24_AvenirNext_500,
                color: textColor70,
                ...h14_Medium,
                fontFamily: 'AvenirNext-Medium',
                paddingBottom: 10,
              }}
            >
              {/* ${moment(
                time_slot?.end_time,
              ).format('HH:mm A')}`}
 */}
              {`${t('confirmDelete')} with ${item.doctor_id.name[0].given[0]} ${
                item.doctor_id.name[0].family
              } on
                 ${t('date', { date: new Date(item?.time_slot?.start_time) })} at 
                 ${moment(item?.time_slot?.start_time).format('hh:mm A')}?`}
            </Text>
          </View>
          <Image
            source={delete_Image}
            style={{ objectFit: 'contains', height: 120, widthL: 120 }}
          />
        </View>
        <View style={{ borderWidth: 1, borderColor: '#ebebeb', bottom: 5 }} />
        <View style={{ flexDirection: 'row', marginTop: 10 }}>
          <TouchableOpacity
            style={{
              cursor: 'pointer',
              marginLeft: 14.5,
              marginRight: 14.5,
              borderRadius: 4,
              paddingLeft: 49,
              paddingRight: 49,
              paddingTop: 11,
              paddingBottom: 11,
              backgroundColor: '#e7eef5',
            }}
            onPress={() => {
              cancel();
            }}
          >
            <View
              style={{
                alignItems: 'center',
              }}
            >
              <Text
                style={{
                  ...h14_AvenirNext,
                  color: 'black',
                  fontWeight: 500,
                  opacity: 0.54,
                }}
              >
                {t('No')}
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setLoading(true);
              invoke({
                id: 'refundTransaction',
                paramValue: {
                  data: {
                    status: 'cancelled',
                    _id: item?._id,
                  },
                },
              })
                .then(() => {
                  navigation.reset('patient-waiting-room');
                  appointmentDatas();
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  const message = JSON.parse(err?.message)?.error?.message;
                  // showMessage({ message });
                  cancel();
                });
            }}
            style={{
              cursor: 'pointer',
              marginLeft: 14.5,
              marginRight: 14.5,
              borderRadius: 4,
              paddingLeft: 49,
              paddingRight: 49,
              paddingTop: 11,
              paddingBottom: 11,
              backgroundColor: themeColor,
            }}
            //   onPress={deleteCall}
          >
            <Text
              style={{
                ...h14_AvenirNext,
                color: '#ffffff',
                fontWeight: 500,
                zIndex: 11,
                // marginLeft:14.5,
                // marginRight:14.5
                // opacity: 0.54,
              }}
            >
              {loading ? (
                <ActivityIndicator size="small" color="#fff" />
              ) : (
                t('Yes')
              )}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default CancelModal;
