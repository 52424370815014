import React from 'react';
import { View, TouchableOpacity, Text } from '@applane/react-core-components';
import { DataFetch } from '../data-fetch/DataFetch';

class ToggleComponent extends React.PureComponent {
  renderComponent = ({ isSelected, optionValue, optionLabel, Count }) => {
    let {
      onChangeValue,
      itemClassName,
      selectedItemClassName,
      itemStyle,
      selectedItemStyle,
      optionStyle,
      selectedOptionStyle,
    } = this.props;
    return (
      <TouchableOpacity
        key={`${optionValue}`}
        className={(isSelected && selectedItemClassName) || itemClassName}
        style={{
          justifyContent: 'center',
          ...itemStyle,
          ...(isSelected && selectedItemStyle),
        }}
        onPress={(e) => {
          onChangeValue && onChangeValue(optionValue, e);
        }}>
        <Text
          style={{
            ...optionStyle,
            ...(isSelected && selectedOptionStyle),
          }}>
          {`${optionLabel} ${Count ? Count : ''}`}
        </Text>
      </TouchableOpacity>
    );
  };
  render() {
    let {
      className,
      style,
      value,
      field,
      defaultValue,
      options,
      reloadEvent,
      navigation,
      eventDispatcher,
      dataFetchProps,
      resolveCount,
      countUri,
      screenState: { dataParams = {} },
    } = this.props;
    if (!options || !options.length) {
      return null;
    }
    if (value === undefined && value === null) {
      value = defaultValue;
    }
    if (value && typeof value !== 'string' && typeof value !== 'boolean') {
      value = value.value;
    }
    if (typeof countUri === 'function') {
      countUri = countUri(this.props);
    }

    return (
      <View
        className={className}
        style={{
          flexDirection: 'row',
          ...style,
        }}>
        {options.map((option) => {
          if (!option) {
            return;
          }
          let optionValue = option;
          let optionLabel = option;
          if (typeof option !== 'string') {
            optionValue = option.value;
            optionLabel = option.label || option.value;
          }

          let newDataParams = { ...dataParams };
          if (optionValue !== undefined || optionValue !== null) {
            newDataParams.filters = {
              ...newDataParams.filters,
              [field]: { value: optionValue, filter: { value: optionValue } },
            };
          }
          let isSelected = value === optionValue;
          return countUri ? (
            <DataFetch
              screenName={`Tab-DataFetch-Count_${optionValue}`}
              key={optionValue}
              navigation={navigation}
              eventDispatcher={eventDispatcher}
              dataParams={newDataParams}
              reloadEvent={reloadEvent}
              uri={countUri}
              {...dataFetchProps}>
              {(result) => {
                let Count = resolveCount ? resolveCount({ ...result }) : null;
                if (
                  Count === undefined ||
                  Count === null ||
                  React.isValidElement(Count)
                ) {
                  return Count;
                }
                if (typeof Count !== 'string' && Count.toString) {
                  Count = Count.toString();
                }

                return this.renderComponent({
                  isSelected,
                  optionValue,
                  optionLabel,
                  Count,
                });
              }}
            </DataFetch>
          ) : (
            this.renderComponent({ isSelected, optionValue, optionLabel })
          );
        })}
      </View>
    );
  }
}

ToggleComponent.defaultProps = {
  style: {
    borderRadius: 4,
    backgroundColor: '#f6f6f6',
  },
  itemStyle: {
    minWidth: 100,
    paddingTop: 7,
    paddingBottom: 7,
    paddingLeft: 16,
    paddingRight: 16,
    borderWidth: 1,
    borderColor: 'transparent',
    cursor: 'pointer',
  },
  selectedItemStyle: {
    borderRadius: 4,
    borderColor: '#1ed69e',
    backgroundColor: '#fbfbfb',
  },
  optionStyle: {
    textAlign: 'center',
    color: '#a2a2a2',
  },
  selectedOptionStyle: {
    color: '#1ed69e',
  },
};

export default ToggleComponent;
