import React from 'react';
import {View, Text} from '@applane/react-core-components';
import {getRenderComponent} from './Utility';
class TopLabel extends React.Component {
  render() {
    let {
      label,
      labelContainerStyle,
      labelStyle,
      mandatory,
      mandatoryStyle,
      renderMandatory,
      children,
    } = this.props;
    if (label === false) {
      return children;
    }
    return (
      <React.Fragment>
        <View style={{flexDirection: 'row', ...labelContainerStyle}}>
          {label ? <Text style={labelStyle}>{label}</Text> : void 0}
          {label && mandatory
            ? getRenderComponent(renderMandatory) || (
                <Text
                  style={{
                    ...labelStyle,
                    ...mandatoryStyle,
                    numberOfLines: void 0,
                  }}>
                  *
                </Text>
              )
            : void 0}
        </View>
        {React.cloneElement(children, {
          label: false,
          mandatory: false,
        })}
      </React.Fragment>
    );
  }
}

TopLabel.defaultProps = {
  labelContainerStyle: {
    height: 20,
  },
  labelStyle: {
    fontSize: 14,
    numberOfLines: 1,
  },
};

export default TopLabel;
