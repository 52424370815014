import { manazeV5Colors } from './manazeV5Colors';
import { manazeV6Colors } from './manazeV6Colors';

import { Headline8 } from './Headlines';
import vars from './vars';

const {
  neutral_500_medium_surface,
  neutral_300_low_surface,
  neutral_200,
  secondary_100,
  secondary_050,
} = manazeV5Colors;
const { primary_2 } = manazeV6Colors;

export const radioGroupTheme = {
  optionStyle: { paddingRight: 8 },
  labelStyle: {
    paddingLeft: 8,
    ...vars.headings.h13,
    color: vars.colors.grey.color1,
  },
  disabledColor: neutral_300_low_surface,
  activeDisabledColor: secondary_100,
  color: neutral_500_medium_surface,
  activeColor: primary_2,
  hoverColor: neutral_200,
  activeHoverColor: secondary_050,
};

export const radioTheme = {
  activeDisabledColor: secondary_100,
  color: neutral_500_medium_surface,
  activeColor: primary_2,
  hoverColor: neutral_200,
  activeHoverColor: secondary_050,
  disabledStyle: {},
  size: 18,
  borderWidth: 2,
  innerSize: 10,
  hoverSize: 32,
};
