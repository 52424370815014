import React from 'react';
import {View} from '@applane/react-core-components';
import ErrorMessage from './ErrorMessage';
import HelperText from './HelperText';
import {isFloatingLabel} from './Utility';

export default Component => {
  class InputWrapper extends React.PureComponent {
    state = {};

    onMouseEnter = e => {
      let {onMouseEnter, hoverable} = this.props;
      if (hoverable !== false) {
        this.setState({
          hover: true,
        });
      }
      onMouseEnter && onMouseEnter(e);
    };

    onMouseLeave = e => {
      let {onMouseLeave, hoverable} = this.props;
      if (hoverable !== false) {
        this.setState({
          hover: false,
        });
      }
      onMouseLeave && onMouseLeave(e);
    };

    onFocus = e => {
      let {onFocus, setFocus} = this.props;
      if (setFocus) {
        setFocus({focus: true, event: e});
      } else {
        this.setState({focus: true});
      }
      onFocus && onFocus(e);
    };

    onBlur = e => {
      let {onBlur, setFocus, modifyValueOnBlur, value} = this.props;
      if (modifyValueOnBlur) {
        value = modifyValueOnBlur({value});
        this.onChangeValue(value, e);
      }
      if (setFocus) {
        setFocus({focus: false, event: e});
      } else {
        this.setState({focus: false});
      }
      onBlur && onBlur(e);
    };

    onChangeValue = (value, e) => {
      let {
        value: prevValue,
        setValue,
        onChange,
        onChangeValue,
        onChangeText,
        modifyValueOnChange,
      } = this.props;
      if (modifyValueOnChange) {
        value = modifyValueOnChange({prevValue, value});
      }
      onChange && onChange(e);
      onChangeValue && onChangeValue(value, e);
      onChangeText && onChangeText(value);
      setValue && setValue({value, event: e});
    };

    render() {
      let {focus, hover} = this.state;
      let {
        placeholder,
        activePlaceholder,
        onFocus,
        onBlur,
        onChange,
        onChangeText,
        onMouseEnter,
        onMouseLeave,
        isActive = focus,
        labelPosition,
        inputStyle,
        floatingLabelInputStyle,
        skipErrorMessage,
        ...restProps
      } = this.props;
      restProps.isActive = isActive;
      restProps.hover = hover;

      let {label, mandatory} = restProps;
      if (placeholder === undefined) {
        placeholder = activePlaceholder;
      } else if (isActive && activePlaceholder !== undefined) {
        placeholder = activePlaceholder;
      }
      if (label) {
        if (labelPosition === undefined) {
          if (isFloatingLabel(restProps)) {
            labelPosition = 'floating';
            inputStyle = floatingLabelInputStyle || inputStyle;
          } else {
            placeholder = '';
          }
        }
      } else {
        labelPosition = void 0;
        if (mandatory && placeholder) {
          placeholder = `${placeholder}*`;
        }
      }
      return (
        <React.Fragment>
          <View
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}>
            <Component
              {...restProps}
              inputStyle={inputStyle}
              labelPosition={labelPosition}
              placeholder={placeholder}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChangeValue={this.onChangeValue}
            />
          </View>
          {!skipErrorMessage && <ErrorMessage {...restProps} />}
          <HelperText {...restProps} />
        </React.Fragment>
      );
    }
  }
  return InputWrapper;
};
