export default {
  labels: {
    departmentName: 'Department Name',
    departmentId: 'Department ID',
    roomsAllocated: 'Room No. Allocated',
    users: 'Users',
    specialities: 'Specialities',
    status: 'Status',
    description: 'Description',
    edit: 'Edit',
    containNumberOfBeds: 'Track Beds',
    createdOn: 'Created On',
    employees: 'Employees',
  },
  placeholders: {
    rooms: 'Select Rooms',
  },
  title: {
    addDepartment: 'Add Department',
    subTitle: 'Fill out the form to add new Department',
    departmentManagement: 'Department Management',
    editDepartment: 'Edit department',
    departmentDetails: 'Department Details',
    exportToExcel: 'Export to Excel',
    description: 'List of Employee in the department.',
  },
  messages: {
    departmentAddedSuccessfully: 'Department added successfully',
    departmentEditedSuccessfully: 'Department edited successfully',
    departmentAlreadyExists: 'Department name already exists',
  },
};
