import React from 'react';
import { consultationListUri } from '../../../Queries/consultations';

import { Image, TouchableOpacity } from '../../../app-components';
import { Table } from '../../../app-components/table/Table';
import { downloadSalarySlip } from '../../../images';
import printConsultationReceipt from '../../../Wired/ConsutationReceipt';
import { ROUTES } from '../../../Lib/constants';

const tableColumns = {
  lg: {
    columns: [
      { width: 14 },
      {
        header: 'consultation.headers.consultationDate'.getLabel(),
        type: 'date',
        field: '_createdOn',
      },
      {
        header: 'consultation.headers.doctorName'.getLabel(),
        field: 'consultedByName',
      },
      {
        header: 'consultation.headers.speciality'.getLabel(),
        type: 'text',
        field: 'speciality.name',
      },
      {
        header: 'consultation.headers.consultationStatus'.getLabel(),
        type: 'consultationStatus',
        field: 'status',
      },
      {
        header: 'consultation.headers.consultationReceipt'.getLabel(),
        type: 'text',
        render: ({ item }) => (
          <TouchableOpacity
            onPress={({ submitResult }) => printConsultationReceipt(
              {
                data: { ...item },
                submitResult,
              },
            )}
          >
            <Image
              source={downloadSalarySlip}
              style={{
                width: 12,
                height: 16,
              }}
            />
          </TouchableOpacity>
        ),
      },
    ],
  },
};

const PatientConsultationHistory = (props) => {
  const { user = {} } = props;
  return (
    <Table
      {...props}
      uri={consultationListUri({ filter: { patient: { _id: user?.patient?._id } } })}
      {...tableColumns}
      action={({ item }) => ({
        type: 'link',
        link: {
          view: ROUTES.viewConsultationPatient.name,
          params: { item },
        },
      })}
    />
  );
};
export default PatientConsultationHistory;
