import React from 'react';

import {withGoogleMap, GoogleMap, Marker} from 'react-google-maps';

const MapWithAMarker = withGoogleMap(({lat , lng }) => {
  return (
    <GoogleMap defaultZoom={15} defaultCenter={{lat, lng}} key={lat + lng}>
      <Marker position={{lat, lng}} />
    </GoogleMap>
  );
});

export default MapWithAMarker;
