import {getNewId, findById, findIndex} from './Utility';

export const populateNestedDataAndUpdates = ({data, updates = {}}, props) => {
  const {field, value, path, insert, remove, bottom, values} = props;
  let {_id: nestedId, field: nestedField} = path[0];

  let nestedData = data[nestedField] || [];
  let nestedUpdates = updates[nestedField] || {};

  if (path.length > 1) {
    nestedData = [...nestedData];
    let nestedDoc = findById(nestedData, '_id', {
      _id: nestedId,
    });
    if (nestedDoc) {
      let nestedDocUpdates = findById(nestedUpdates.insert, '_id', {
        _id: nestedId,
      });
      if (!nestedDocUpdates) {
        nestedDocUpdates = findById(nestedUpdates.update, '_id', {
          _id: nestedId,
        });
        if (nestedDocUpdates) {
          nestedDocUpdates.changes = nestedDocUpdates.changes || {};
          nestedDocUpdates = nestedDocUpdates.changes;
        } else {
          nestedDocUpdates = {};
          nestedUpdates['update'] = nestedUpdates['update'] || [];
          nestedUpdates['update'].push({
            _id: nestedId,
            changes: nestedDocUpdates,
          });
        }
      }
      let {
        nestedData: innerNestedData,
        nestedUpdates: innerNestedUpdates,
      } = populateNestedDataAndUpdates(
        {
          data: nestedDoc,
          updates: nestedDocUpdates,
        },
        {
          ...props,
          path: path.slice(1, path.length),
        },
      );
      let nestedDocIndex = findIndex(nestedData, '_id', {
        _id: nestedId,
      });
      if (nestedDocIndex >= 0) {
        nestedData[nestedDocIndex] = {
          ...nestedDoc,
          ...innerNestedData,
        };
        Object.assign(nestedDocUpdates, {...innerNestedUpdates});
      }
    }
  } else {
    let isOverride = Array.isArray(nestedUpdates);
    if (insert) {
      //handle insert case
      nestedId = nestedId || getNewId();
      let nestedDoc = {_id: nestedId, ...values};
      if (
        !findById(nestedData, '_id', {
          _id: nestedId,
        })
      ) {
        nestedData = bottom
          ? [...nestedData, nestedDoc]
          : [nestedDoc, ...nestedData];
      }
      if (isOverride) {
        nestedUpdates = [...nestedData];
      } else {
        nestedUpdates['insert'] = nestedUpdates['insert'] || [];
        let nestedUpdatesDoc = findById(nestedUpdates['insert'], '_id', {
          _id: nestedId,
        });
        if (!nestedUpdatesDoc) {
          nestedUpdates['insert'].push({...nestedDoc});
        }
      }
    } else if (remove) {
      //handle remove case
      nestedData = nestedData.filter(doc => doc._id !== nestedId);

      if (isOverride) {
        nestedUpdates = [...nestedData];
      } else {
        //check if exists in insert
        let nestedDoc = findById(nestedUpdates['insert'], '_id', {
          _id: nestedId,
        });
        if (nestedDoc) {
          //means it is just added and now removed, so remove insert op as well
          nestedUpdates['insert'] = nestedUpdates['insert'].filter(
            nestedDoc => nestedDoc._id !== nestedId,
          );
        } else {
          nestedUpdates['remove'] = nestedUpdates['remove'] || [];
          nestedDoc = findById(nestedUpdates['remove'], '_id', {
            _id: nestedId,
          });
          if (!nestedDoc) {
            nestedUpdates['remove'].push({_id: nestedId});
          }
        }
      }
    } else {
      let nestedFieldUpdates = {};
      if (field) {
        nestedFieldUpdates[field] = value;
      } else if (values) {
        nestedFieldUpdates = values;
      }

      nestedData = nestedData.map(doc =>
        doc._id === nestedId ? {...doc, ...nestedFieldUpdates} : doc,
      );
      if (isOverride) {
        nestedUpdates = [...nestedData];
      } else {
        //check first in insert then in updates
        //check in insert
        let nestedUpdatesDoc = findById(nestedUpdates['insert'], '_id', {
          _id: nestedId,
        });
        if (nestedUpdatesDoc) {
          Object.assign(nestedUpdatesDoc, {...nestedFieldUpdates});
        } else {
          nestedUpdatesDoc = findById(nestedUpdates['update'], '_id', {
            _id: nestedId,
          });
          if (nestedUpdatesDoc) {
            //it is in updates
            Object.assign(nestedUpdatesDoc.changes, {...nestedFieldUpdates});
          } else {
            nestedUpdates['update'] = nestedUpdates['update'] || [];
            nestedUpdates['update'].push({
              _id: nestedId,
              changes: {...nestedFieldUpdates},
            });
          }
        }
      }
    }
  }
  return {
    nestedData: {
      [nestedField]: nestedData,
    },
    nestedUpdates: {
      [nestedField]: nestedUpdates,
    },
  };
};

const getIdRecord = (data, _id) => {
  if (Array.isArray(data)) {
    if (!_id) {
      throw new Error(
        `_id must be defined to getIdrecord for data ${JSON.stringify(data)}`,
      );
    }
    const doc = data.find(doc => doc && doc._id === _id);
    if (!doc) {
      throw new Error(
        `Document not found for _id: [${_id}] in array data [${JSON.stringify(
          data,
        )}] `,
      );
    }
    return doc;
  } else if (data && data._id === _id) {
    return data;
  } else {
    throw new Error(
      `Document not found for _id: [${_id}] in document data [${JSON.stringify(
        data,
      )}] `,
    );
  }
};

const resolvePathFromData = ({data, path, _id}) => {
  let doc = getIdRecord(data, _id);
  if (path) {
    for (let node of path) {
      const {field, _id: childId} = node;
      doc = getIdRecord(doc[field], childId);
    }
  }
  return doc;
};

export const denormalizeUpdates = ({updates: batchUpdates, data, children}) => {
  //populate overrides as insert and delete
  let newUpdates = [];
  if (!batchUpdates) {
    return [];
  }
  if (!Array.isArray(batchUpdates)) {
    batchUpdates = [batchUpdates];
  }
  batchUpdates.forEach(batchUpdate => {
    let {_id, path, updates} = batchUpdate;
    let set = (updates && updates.set) || {};
    const newSet = {};
    let arraySet = null;
    let childrenToUse = children;
    if (path && Array.isArray(path)) {
      //from client side udpates, we will have all childrens as object as recursive and updates will come individually for nested table or inner nested table
      // updates:{_id:"i1",path:[{_id:d1,field:"deliveries"}{_id:"t1",field:"taxes"}],set:{tax:"sgst"}} and children:{delivereis:{taxes:1}}
      path.forEach(pathNode => {
        if (pathNode.field) {
          childrenToUse =
            (childrenToUse && childrenToUse[pathNode.field]) || void 0;
        }
      });
    }
    for (let field in set) {
      const fieldValue = set[field];
      if (childrenToUse && childrenToUse[field] && Array.isArray(fieldValue)) {
        //mark them as insert  - hold it
        //consider case of autosuggest multiple -> we should treat them override for the time
        arraySet = arraySet || {}; /** if array override mark them as insert */
        arraySet[
          field
        ] = fieldValue; /** if array override mark them as insert */
        // newSet[field] = fieldValue;  /** todo consider case of autosuggest multiple -> we should treat them override for the time */
      } else {
        newSet[field] = fieldValue;
      }
    }
    batchUpdate = {_id, path, updates: {...updates, set: newSet}};
    //add batchUpdates
    newUpdates.push(batchUpdate);
    //check if arrayValues, then denormalize it into inserts and deletes
    if (arraySet) {
      for (let field in arraySet) {
        //add delete in updates for each old docs for array field
        let oldParentValue = null;
        try {
          oldParentValue = resolvePathFromData({data, path, _id});
        } catch (e) {
          //ignore error as data may be found or not if trying again recursively for denormalize
        }
        const oldValue = (oldParentValue && oldParentValue[field]) || [];

        for (let oldDoc of oldValue) {
          let newPath = path ? [...path] : [];

          newPath.push({field, _id: oldDoc._id});
          newUpdates.push({
            _id,
            path: newPath,
            updates: {
              remove: true,
            },
          });
        }

        //add insert in updates for each new Value of array field
        const fieldValue = arraySet[field];

        for (let doc of fieldValue) {
          let newPath = path ? [...path] : [];
          if (doc._id === undefined) {
            doc = {...doc, _id: getNewId()};
          }
          newPath.push({field, _id: doc._id});

          const updateForChildInsert = {
            _id,
            path: newPath,
            updates: {
              insert: true,
              set: doc,
            },
          };

          const denormalizedUpdates = denormalizeUpdates({
            updates: [updateForChildInsert],
            data,
            children,
          });
          newUpdates.push(...denormalizedUpdates);
        }
      }
    }
  });
  return newUpdates;
};

export const modifyUpdates = ({data}, props) => {
  if (!data || !data._id) {
    return;
  }
  let {field, value, values, path, insert, remove, bottom} = props;
  if (!values && field) {
    values = {[field]: value};
  }
  let updates = void 0;
  if (path && path.length) {
    if (insert) {
      updates = {
        insert,
        set: {_id: path[path.length - 1]._id, ...values},
        bottom,
      };
    } else if (remove) {
      updates = {remove, set: {}};
    } else {
      updates = {set: {...values}};
    }
  } else if (values && Object.keys(values).length) {
    path = void 0;
    updates = {
      set: {
        ...values,
      },
    };
  }
  if (updates) {
    return {
      _id: data._id,
      path,
      updates,
    };
  }
};
