import React from 'react';
import {View, Text, ScrollView, Platform} from '@applane/react-core-components';
import ReactForm from './ReactForm';
import {WithReactForm} from './WithReactFormContext';
import {getRenderComponent} from './Utility';

class JSONReactForm extends React.Component {
  renderFormGroups = ({formGroups} = {}) => {
    let {
      form_context,
      form_state,
      fieldVariant,
      fieldContainer,
      formGroupType,
      renderFormGroup,
      editable,
      resolveVisible,
    } = this.props;

    let {data = {}} = form_state || {};
    let {setValue, navigation, eventDispatcher, user, getUser} =
      form_context || {};
    user = user || (getUser && getUser());

    if (!formGroups || !formGroups.length) {
      return (
        <View style={{padding: 12, backgroundColor: 'red'}}>
          <Text style={{color: '#ffffff'}}>
            {'Either formGroups or children must be defined in JSONReactForm'}
          </Text>
        </View>
      );
    }

    if (!renderFormGroup) {
      return (
        <View style={{padding: 12, backgroundColor: 'red'}}>
          <Text style={{color: '#ffffff'}}>
            {'renderFormGroup is not defined in JSONReactForm'}
          </Text>
        </View>
      );
    }

    let component = formGroups.map((formGroup, index) => {
      return renderFormGroup({
        user,
        resolveVisible,
        editable,
        data,
        setValue,
        navigation,
        eventDispatcher,
        fieldVariant,
        fieldContainer,
        type: formGroupType,
        ...formGroup,
      });
    });
    return component;
  };

  renderChildren = () => {
    let {
      formGroups,
      tabs,
      tabProps,
      renderTab,
      renderHorizontalFormGroup,
      navigation,
      eventDispatcher,
      horizontalFormGroups,
      verticalFormGroups,
      renderVerticalFormGroup,
      scrollable,
      form_state,
    } = this.props;
    let {data = {}} = form_state || {};
    if (tabs) {
      if (!renderTab) {
        return (
          <View style={{padding: 12, backgroundColor: 'red'}}>
            <Text style={{color: '#ffffff'}}>
              {'renderTab is not defined in JSONReactForm'}
            </Text>
          </View>
        );
      }
      return renderTab({
        navigation,
        eventDispatcher,
        data,
        tabs,
        renderFormGroups: this.renderFormGroups,
        ...tabProps,
      });
    } else if (horizontalFormGroups && horizontalFormGroups.length) {
      return renderHorizontalFormGroup({
        horizontalFormGroups,
        data,
        scrollable,
        renderFormGroups: this.renderFormGroups,
      });
    } else if (verticalFormGroups && verticalFormGroups.length) {
      return renderVerticalFormGroup({
        verticalFormGroups,
        data,
        scrollable,
        renderFormGroups: this.renderFormGroups,
      });
    } else {
      return this.renderFormGroups({formGroups});
    }
  };

  render() {
    let {
      children,
      flex = 1,
      bodyContainerFlex,
      scrollable,
      renderLoading,
      renderHeader,
      renderFooter,
      renderForm,
      containerStyle,
      bodyContainerStyle,
      showsVerticalScrollIndicator,
      showsHorizontalScrollIndicator,
      keyboardShouldPersistTaps,
      bounces,
      form_context,
      form_state,
    } = this.props;
    let {navigation, eventDispatcher} = form_context || {};
    let formProps = {
      form_context,
      form_state,
      navigation,
      eventDispatcher,
    };
    if (!children) {
      children = renderForm || this.renderChildren;
    }
    let bodyComponent = getRenderComponent(children, formProps);
    if (bodyContainerStyle) {
      if (bodyContainerFlex) {
        bodyContainerStyle = {
          flex: bodyContainerFlex === true ? 1 : bodyContainerFlex,
          overflow: 'hidden',
          ...bodyContainerStyle,
        };
      }
      bodyComponent = <View style={bodyContainerStyle}>{bodyComponent}</View>;
    }
    let extraProps = {};
    if (Platform.OS !== 'web') {
      if (bounces !== undefined) {
        extraProps.bounces = bounces;
      }
      if (keyboardShouldPersistTaps !== undefined) {
        extraProps.keyboardShouldPersistTaps = keyboardShouldPersistTaps;
      }
    }
    if (flex !== false) {
      if (scrollable !== false) {
        bodyComponent = (
          <ScrollView
            {...extraProps}
            showsVerticalScrollIndicator={showsVerticalScrollIndicator}
            showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
            style={{flex: 1}}>
            {bodyComponent}
          </ScrollView>
        );
      }
      containerStyle = {flex, overflow: 'hidden', ...containerStyle};
      bodyComponent = (
        <View style={{flex: 1, overflow: 'hidden'}}>{bodyComponent}</View>
      );
    }
    return (
      <View style={containerStyle}>
        {getRenderComponent(renderHeader, formProps)}
        {bodyComponent}
        {getRenderComponent(renderFooter, formProps)}
        {getRenderComponent(renderLoading, formProps)}
      </View>
    );
  }
}

JSONReactForm = WithReactForm(JSONReactForm, {
  key: 'form',
});

export default class ReactFormWrapper extends React.Component {
  render() {
    return (
      <ReactForm {...this.props}>
        <JSONReactForm {...this.props} />
      </ReactForm>
    );
  }
}

export {JSONReactForm};
