import React, {useState} from 'react';
import {
  View,
  TouchableOpacity,
  Text,
  Image,
} from '@applane/react-core-components';
import {WithInputWrapper, WithInputError} from '@applane/react-input-wrapper';
import {getRenderComponent} from './Utility';
import WithFetch from './WithFetch';
const ChipsetButton = props => {
  let {
    options,
    styles: _styles,
    direction = 'row',
    rowWrapperStyle,
    style,
  } = props;
  if (!options || !options.length) {
    return null;
  }

  return (
    <View
      style={{
        ...(direction === 'row'
          ? {
              flexWrap: 'wrap',
              flexDirection: 'row',
            }
          : {}),
        ..._styles.rowWrapperStyle,
        ...rowWrapperStyle,
        ...style,
      }}>
      {options.map(option => {
        return <RadioChip option={option} {...props} />;
      })}
    </View>
  );
};

export default WithFetch(WithInputError(ChipsetButton));

let RadioChip = props => {
  let {
    className,
    itemClassName,
    selectedItemClassName,
    renderLabel,
    value,
    onChangeValue,
    valueField,
    styles: _styles = {},
    chipBackground,
    disabled,
    option,
    hover,
    shape,
    toggle = false,
    showSelectedIcon = true,
  } = props;

  let wrapperStyle = {
    ..._styles.wrapper,
  };
  if (shape === 'circular') {
    showSelectedIcon = false;
  }
  let disabledStyle = {
    ..._styles.disabled,
  };
  let tick = '✓';
  let {chipColor, icon} = option;
  let optionValue = option;
  let optionLabel = option;
  if (icon) {
    showSelectedIcon = false;
  }
  if (typeof option !== 'string') {
    optionValue = option.value;
    optionLabel = option.label;
  }
  let selected = false;
  if (value !== undefined && optionValue !== undefined) {
    if (
      typeof value === 'object' &&
      typeof optionValue === 'object' &&
      valueField
    ) {
      selected = value[valueField] === optionValue[valueField];
    } else {
      selected = value === optionValue;
    }
  }
  let textStyle = {
    ..._styles.text,
    ...(selected ? _styles.selectedText : void 0),
    ...(chipColor && {color: chipColor}),
  };
  let labelComponent = renderLabel ? (
    getRenderComponent(renderLabel, {option})
  ) : (
    <View style={{flexDirection: 'row'}}>
      {selected && icon && (
        <Image source={icon} style={{height: 16, width: 16, marginRight: 5}} />
      )}
      <Text
        style={{
          ...textStyle,
        }}>
        {showSelectedIcon && selected && tick} {optionLabel}
      </Text>
    </View>
  );

  let itemsStyle = {
    ..._styles.items,
    ...(chipColor && {
      borderColor: chipColor,
      color: chipColor,
      ...((selected || (!disabled && hover)) && {
        borderColor: 'transparent',
        backgroundColor: `${chipColor}4D`,
      }),
    }),
    ...(selected && _styles?.selectedItem),
    ...(chipBackground &&
      (selected || (!disabled && hover)) && {backgroundColor: chipBackground}),
  };
  return (
    <View
      className={className}
      style={{
        ...wrapperStyle,
      }}>
      <TouchableOpacity
        key={value}
        disabled={disabled}
        className={(selected && selectedItemClassName) || itemClassName}
        style={{
          ...itemsStyle,
          ...(disabled && disabledStyle),
        }}
        onPress={e => {
          if (value !== optionValue) {
            onChangeValue && onChangeValue(optionValue, e);
          } else {
            if (toggle) {
              onChangeValue && onChangeValue(null, e);
            }
          }
        }}>
        {labelComponent}
      </TouchableOpacity>
    </View>
  );
};

RadioChip = WithInputWrapper(RadioChip);
