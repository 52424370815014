import React from 'react';
import {TouchableOpacity} from '@applane/react-core-components';
import './Toast.css';

const ToastComponent = props => {
  let {
    position,
    onToastPress,
    direction = 'left',
    id,
    duration,
    toastContainerStyle,
    toastPositionStyle,
    Component,
    onMouseEnter,
    onMouseLeave,
  } = props;
  return (
    <TouchableOpacity
      style={{
        zIndex: 100,
        position: 'absolute',
        ...toastContainerStyle,
        ...toastPositionStyle,
      }}
      onPress={e => {
        onToastPress && onToastPress(e);
      }}
      onMouseEnter={() => onMouseEnter && onMouseEnter(id, duration)}
      onMouseLeave={() => onMouseLeave && onMouseLeave(id, duration)}
      className={`${position}-${direction}`}>
      {Component}
    </TouchableOpacity>
  );
};

ToastComponent.defaultProps = {
  toastContainerStyle: {
    marginLeft: 16,
    marginRight: 16,
    width: 'auto',
    marginTop: 5,
    marginBottom: 5,
  },
};
export default ToastComponent;
