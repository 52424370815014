import React from 'react';
import uuid from 'uuid/v4';
import DataFetchConstants from './DataFetchConstants';

const {fetchMoreEvent} = DataFetchConstants;

class DataFetch extends React.Component {
  constructor(props) {
    super(props);
    let state = props.state;
    if (state && !state.uid) {
      throw new Error('no uid exists in state passed to DataFetch');
    } else {
      this.state = {uid: 'DataFetch_' + uuid()};
    }

    const {reloadEvent, fetchUriEvent, eventDispatcher} = props;
    if (eventDispatcher) {
      fetchUriEvent &&
        eventDispatcher.listen(fetchUriEvent, this.fetchUriEventCallback);
      reloadEvent && eventDispatcher.listen(reloadEvent, this.reloadData);
      eventDispatcher.listen(fetchMoreEvent(this.getUid()), this.fetchMore);
    }
  }
  fetchUriEventCallback = () => {
    let state = this._getState();
    let result = this.getModifyUri({state});
    return {
      ...result,
      state: {
        ...state,
        ...result.state,
      },
    };
  };
  getUid = () => {
    const state = this._getState();
    return state.uid;
  };
  reloadData = params => {
    this.fetchData({
      ...params,
      source: 'reloadEvent',
    });
  };
  _getState = () => {
    return this.props.state || this.state;
  };

  _setState = state => {
    if (this._unmounted) {
      return;
    }
    if (this.props.setState) {
      this.props.setState(state);
    } else {
      this.setState(state);
    }
  };

  onRealTimeUpdate = state => {
    this._setState(state);
  };

  isIgnoreResult = sequence => {
    return sequence && this.sequence > sequence;
  };

  incFetchCount = () => {
    this.sequence = this.sequence ? this.sequence + 1 : 1;
  };

  onRealTimeEvent = event => {
    const {onRealTimeUpdate} = this.props;
    if (onRealTimeUpdate) {
      const state = this._getState();
      onRealTimeUpdate({
        event,
        state,
        onUpdateState: this.onRealTimeUpdate,
      });
    }
  };

  //get Navigation params
  getNavigationParams = () => {
    return (
      this.props.navigation &&
      this.props.navigation.state &&
      this.props.navigation.state.params
    );
  };

  //setOldParams used to compare value of old params with new params for data reload
  setOldParams = () => {
    const {
      reloadOnGlobalDataParamsChange,
      reloadOnNavigationChange,
      reloadOnDataParamsChange,
      dataParams,
      globalDataParams,
    } = this.props;

    if (reloadOnNavigationChange) {
      this.oldParams = this.getNavigationParams();
    }
    if (reloadOnDataParamsChange) {
      this.oldDataParams = dataParams; //oldDataParams stored in this for componentDidUpdate check
    }
    if (reloadOnGlobalDataParamsChange) {
      this.oldGlobalDataParams = globalDataParams; //oldGlobalDataParams stored in this for componentDidUpdate check
    }
  };
  componentDidMount() {
    let state = this._getState();
    this.setOldParams();
    if (!state || !state.data || !state.data.length) {
      this.fetchData({source: 'loadingInitial'});
    }
  }

  componentDidUpdate() {
    const {
      reloadOnGlobalDataParamsChange,
      reloadOnNavigationChange,
      reloadOnDataParamsChange,
      dataParams,
      globalDataParams,
    } = this.props;
    let newParams = this.getNavigationParams();

    let reloadRequired = false;
    let reloadProps = void 0;
    if (
      reloadOnGlobalDataParamsChange &&
      (globalDataParams || this.oldGlobalDataParams) &&
      globalDataParams !== this.oldGlobalDataParams
    ) {
      reloadProps = {source: 'globalDataParamsChange'};
      reloadRequired = true;
    } else if (
      reloadOnNavigationChange &&
      (this.oldParams || newParams) &&
      this.oldParams !== newParams
    ) {
      reloadRequired = true;
      reloadProps = {source: 'navigationChange'};
    } else if (
      reloadOnDataParamsChange &&
      (dataParams || this.oldDataParams) &&
      dataParams !== this.oldDataParams
    ) {
      reloadProps = {source: 'dataParamsChange'};
      reloadRequired = true;
    }

    this.setOldParams();
    if (reloadRequired) {
      this.fetchData(reloadProps);
    }
  }

  fetchMore = ({sequence} = {}) => {
    if (this.fetchingMore) {
      return;
    }
    let state = this._getState();
    if (state && state.fetchMoreProps && !state.fetchMoreProps.hasNext) {
      return;
    }
    sequence = sequence || this.sequence;
    return this.fetchData({source: 'fetchMore', fetchMore: true, sequence});
  };
  getModifyUri = ({state}, fetchProps) => {
    let {uri} = this.props;
    if (this.props.beforeFetch) {
      let resp = this.props.beforeFetch({
        state,
        uri,
        fetchProps,
        props: this.props,
      });
      return resp;
    } else {
      return {uri};
    }
  };
  fetchData = async fetchProps => {
    let {fetch, uri, uriRequired, subscribe} = this.props;
    let {fetchMore, sequence} = fetchProps;
    if (!fetch || (uriRequired && !uri)) {
      return;
    }

    let state = this._getState();
    let {uid} = state;
    if (this.props.beforeFetch) {
      let resp = this.getModifyUri({state}, fetchProps);

      if (resp) {
        if (resp.uri) {
          uri = resp.uri;
        }
        if (resp.state) {
          this._setState(resp.state);
        }
      }
    }

    if (!fetchMore) {
      this.incFetchCount();
      sequence = this.sequence;
    }

    if (fetchProps && fetchProps.fetchMore) {
      this.fetchingMore = true;
    }
    return fetch({uri, uid, onDataUpdate: this.onRealTimeEvent})
      .then(async result => {
        this.fetchingMore = false;
        if (this._unmounted || this.isIgnoreResult(sequence)) {
          return;
        }
        let subscribeInfo = void 0;

        // for real-time-sync returns the required subscribeInfo Properties -- Vishal Joshi  / 4-Jun-2021 
        if (subscribe) {
          subscribeInfo = subscribe({
            uid,
            uri,
            result,
            onDataUpdate: this.onRealTimeEvent,
          });
        }
        if (this.props.afterFetch) {
          let resultState = await this.props.afterFetch({
            state: this._getState(),
            fetchProps,
            result,
            subscribeInfo,
            uri,
            props: this.props,
          });
          if (resultState && resultState.state) {
            this._setState(resultState.state);
          }
        } else if (result) {
          this._setState(result);
        }
      })
      .catch(e => {
        this.fetchingMore = false;
        let {showError} = this.props;
        showError && showError(e);
        this._setState({loading: false});
      });
  };


  componentWillUnmount() {
    this._unmounted = true;
    const {
      eventDispatcher,
      reloadEvent,
      fetchUriEvent,
      unsubscribe,
    } = this.props;
    if (eventDispatcher) {
      fetchUriEvent &&
        eventDispatcher.unlisten(fetchUriEvent, this.fetchUriEventCallback);
      reloadEvent && eventDispatcher.unlisten(reloadEvent, this.reloadData);
      eventDispatcher.unlisten(fetchMoreEvent(this.getUid()), this.fetchMore);
    }
    if (unsubscribe) {
      unsubscribe({uid: this.getUid(), state: this._getState()});
    }
  }

  render() {
    const state = this._getState();
    let {children} = this.props;

    if (typeof children === 'function') {
      children = children(state);
    } else if (React.isValidElement(children)) {
      children = React.cloneElement(children, state);
    }
    return children;
  }
}

export default DataFetch;
