import React from 'react';
import {
  Text,
  Image,
  TouchableOpacity,
  View,
} from '@applane/react-core-components';
import {getRenderComponent} from './Utility';
import TabItemCount from './TabItemCount';

export class TabItemRender extends React.Component {
  render() {
    let {
      group,
      tab,
      tabKey,
      selected,
      itemContainerStyle,
      selectedItemContainerStyle,
      textStyle,
      activeTextStyle,
      iconStyle,
      dataFetchProps,
      getTabNavigation,
      navigation: selectedTabNavigation,
      resolveLabel,
      skipCount,
      ...restProps
    } = this.props;
    if (!tab) {
      return null;
    }
    let navigation = getTabNavigation({tabKey}) || selectedTabNavigation;
    let {
      label,
      icon,
      activeIcon,
      dataFetchProps: tabDataFetchProps,
      ...restTabInfo
    } = tab;
    if (resolveLabel) {
      label = resolveLabel({tab, group, navigation, selected, tabKey});
    } else if (typeof label === 'function') {
      label = label({navigation});
    }
    dataFetchProps = tabDataFetchProps || dataFetchProps;
    if (selected) {
      icon = activeIcon || icon;
      textStyle = {
        ...textStyle,
        ...activeTextStyle,
      };
    }
    return (
      <View
        style={{
          ...itemContainerStyle,
          ...((selected && selectedItemContainerStyle) || {}),
        }}>
        {icon ? (
          <Image resizeMode={'contain'} source={icon} style={iconStyle} />
        ) : null}
        {label ? <Text style={textStyle}>{label}</Text> : null}
        {!skipCount ? (
          <TabItemCount
            key={tabKey}
            tabKey={tabKey}
            selected={selected}
            navigation={navigation}
            dataFetchProps={dataFetchProps}
            {...restProps}
            {...restTabInfo}
          />
        ) : null}
      </View>
    );
  }
}

class TabItem extends React.Component {
  renderGroupTab = ({selected}) => {
    let {
      tab,
      renderGroupItems,
      selectedTab,
      getTabNavigation,
      navigation: selectedTabNavigation,
      eventDispatcher,
      uid,
    } = this.props;
    if (!tab.length || !renderGroupItems) {
      return null;
    }
    let groupTab = selected
      ? tab.find(item => item.tabKey === selectedTab)
      : this.groupTab || tab[0];
    this.groupTab = groupTab;
    let navigation =
      getTabNavigation({tabKey: groupTab.tabKey}) || selectedTabNavigation;
    let groupItemsProps = {
      uid,
      navigation,
      eventDispatcher,
      items: tab.map(item => {
        return {
          ...item,
          render: () => {
            return (
              <TabItemRender
                {...this.props}
                tab={item.tab}
                tabKey={item.tabKey}
                selected={item.tabKey === selectedTab}
                itemContainerStyle={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              />
            );
          },
          highlighted: this.highlighted(item),
          onSelect: this.onSelectTab(item),
        };
      }),
      children: this.renderTabItem({
        group: true,
        tab: groupTab.tab,
        tabKey: groupTab.tabKey,
        selected,
      }),
    };
    return getRenderComponent(renderGroupItems, groupItemsProps);
  };

  isSelected = () => {
    let {tab, tabKey, selectedTab} = this.props;
    if (Array.isArray(tab)) {
      for (let tabInfo of tab) {
        if (tabInfo && tabInfo.tabKey === selectedTab) {
          return true;
        }
      }
    } else {
      return tabKey === selectedTab;
    }
  };

  highlighted = item => () => {
    let {selectedTab} = this.props;
    return item && selectedTab === item.tabKey;
  };

  onSelectTab = item => () => {
    this.handleOnPress(item);
  };

  handleOnPress = ({tab, tabKey}) => {
    const {getTabNavigation, navigation: selectedTabNavigation} = this.props;
    let navigation = getTabNavigation({tabKey}) || selectedTabNavigation;
    let {onPress, params, action} = tab;
    if (typeof onPress === 'function') {
      return onPress({navigation});
    }
    if (!navigation) {
      return;
    }
    navigation.navigate && navigation.navigate(tabKey, params, action);
  };

  renderTabItem = props => {
    let {
      containerStyle,
      activeContainerStyle,
      activeBorderStyle,
      groupIcon,
      activeGroupIcon,
      groupIconContainerStyle,
      groupIconStyle,
      activeGroupIconStyle,
      defaultGroupNameInLabel,
      getRef,
      onLayout,
      ...restProps
    } = this.props;
    let {group, tab, selected} = props;
    if (!tab) {
      return null;
    }
    defaultGroupNameInLabel =
      tab.defaultGroupNameInLabel || defaultGroupNameInLabel;
    if (tab.containerStyle) {
      containerStyle = {
        ...containerStyle,
        ...tab.containerStyle,
      };
    }
    if (selected) {
      containerStyle = {
        ...containerStyle,
        ...activeContainerStyle,
      };
      groupIcon = activeGroupIcon || groupIcon;
      groupIconStyle = activeGroupIconStyle || groupIconStyle;
    }
    let skipCount = defaultGroupNameInLabel !== false && group && !selected;
    let itemComponent = (
      <TabItemRender {...restProps} {...props} skipCount={skipCount} />
    );
    if (group && groupIcon) {
      itemComponent = (
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {itemComponent}
          <View style={groupIconContainerStyle}>
            <Image
              resizeMode={'contain'}
              source={groupIcon}
              style={groupIconStyle}
            />
          </View>
        </View>
      );
    }
    return (
      <View getRef={getRef} onLayout={onLayout} style={{flex: 1}}>
        <View style={{flex: 1, ...containerStyle}}>
          {itemComponent}
          {selected && activeBorderStyle && <View style={activeBorderStyle} />}
        </View>
      </View>
    );
  };

  render() {
    let {tab, tabKey, wrapperStyle} = this.props;
    const selected = this.isSelected();
    if (Array.isArray(tab)) {
      return this.renderGroupTab({selected});
    }
    return (
      <TouchableOpacity
        activeOpacity={0.6}
        key={tabKey}
        style={{cursor: 'pointer', ...wrapperStyle}}
        onPress={() => this.handleOnPress({tab, tabKey})}>
        {this.renderTabItem({
          tab,
          tabKey,
          selected,
        })}
      </TouchableOpacity>
    );
  }
}

export default TabItem;
