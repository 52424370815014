import React from 'react';
import ReactDOMServer from 'react-dom/server';
import JsBarcode from 'jsbarcode';
import { printPdf } from '../../Lib/pdfUtil';
import ConsultationReceipt from './Receipt';

const printConsultationReceipt = ({ data, submitResult }, cb) => {
  const tag = document.getElementById('printable-area');
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, data?.patient?.uhid, {
    format: 'CODE128', height: 140, fontSize: 28, textMargin: 1, margin: 0,
  });
  const image = canvas.toDataURL('image/png');
  const test = ReactDOMServer.renderToString(<ConsultationReceipt
    departmentInfo={data?.department?.name}
    roomsInfo={data?.room}
    patientInfo={{ ...data?.patient, age: data?.age, ageType: data?.ageType }}
    opdDetails={data?.speciality}
    data={submitResult}
    ODPNumber={data?.ODPNumber}
    barcode={image}
    remarks={data?.remarks}
  />);
  tag.innerHTML = test;
  printPdf({
    tag,
    cb,
    format: [842, 595],
    orientation: 'l',
    unit: 'px',
  });
};
export default printConsultationReceipt;
