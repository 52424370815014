import React from "react";
import { View, Text } from "@applane/react-core-components";
import { Headline9, Headline14, Headline15 } from "../../../theme/Headlines";
import { manazeV6Colors } from "../../../theme/manazeV6Colors";
import { resolveExp } from "../../UtilityFunctions";
const { neutral_1, neutral_3, positive_communication_2, negative_communication_2 } = manazeV6Colors;

export const AmountComparisonCard = props => {
  let { item, column: { field, totalField, compareField, fieldSource } } = props;
  if (!item) {
    return null;
  }
  let comparePercentageColor = positive_communication_2;
  let amount = (field && resolveExp(item, field)) || 0;
  let comparePercentage = void 0;
  if (compareField) {
    let compareAmount = resolveExp(item, compareField) || 0; //Previous Month
    let BigNumber = compareAmount > amount ? compareAmount : amount;
    let SmallNumber = compareAmount < amount ? compareAmount : amount;
    comparePercentage = BigNumber === 0 && SmallNumber === 0 ? 0 : (BigNumber - SmallNumber) / BigNumber * 100;
    if (
      (fieldSource === "expense" && BigNumber === amount) ||
      (fieldSource !== "expense" && BigNumber === compareAmount)
    ) {
      comparePercentageColor = negative_communication_2;
    }
    if (comparePercentage < 0) {
      comparePercentage = comparePercentage * -1;
    }
  }

  let totalPercentage = void 0;
  if (totalField) {
    let totalAmount = resolveExp(item, totalField) || 1;
    totalPercentage = amount === 0 ? 0 : amount / totalAmount * 100;
  }
  if (amount) {
    amount = amount.toFixed(0);
    amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <View>
      <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
        <Text style={{ ...Headline9, color: neutral_1 }}>{amount}</Text>
        {comparePercentage !== undefined
          ? <Text
              style={{
                ...Headline14,
                color: comparePercentageColor,
                paddingLeft: 4
              }}
            >
              {parseFloat(comparePercentage).toFixed(2)}
              {"%"}
            </Text>
          : void 0}
      </View>
      {totalPercentage !== undefined
        ? <Text style={{ ...Headline15, color: neutral_3, paddingTop: 4 }}>
            {totalPercentage.toFixed(2)}
            {"%"}
          </Text>
        : void 0}
    </View>
  );
};

export const ProfitComparisonCard = props => {
  let { item, column: { fromField, toField, compareFromField, compareToField } } = props;
  if (!item) {
    return null;
  }
  let comparePercentageColor = positive_communication_2;
  let amount = (resolveExp(item, fromField) || 0) - (resolveExp(item, toField) || 0);
  let comparePercentage = void 0;
  if (compareFromField && compareToField) {
    let previousMonthProfitLoss = (resolveExp(item, compareFromField) || 0) - (resolveExp(item, compareToField) || 0);
    let monthProfitLoss = (resolveExp(item, fromField) || 0) - (resolveExp(item, toField) || 0);

    let BigNumber = previousMonthProfitLoss > monthProfitLoss ? previousMonthProfitLoss : monthProfitLoss;
    let SmallNumber = previousMonthProfitLoss < monthProfitLoss ? previousMonthProfitLoss : monthProfitLoss;
    let Divider = BigNumber || 1;
    comparePercentage = BigNumber === 0 && SmallNumber === 0 ? 0 : (BigNumber - SmallNumber) / Divider * 100;
    if (BigNumber === previousMonthProfitLoss) {
      comparePercentageColor = negative_communication_2;
    }
    if (comparePercentage < 0) {
      comparePercentage = comparePercentage * -1;
    }
  }

  if (amount) {
    amount = amount.toFixed(0);
    amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <View>
      <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
        <Text style={{ ...Headline9, color: neutral_1 }}>{amount}</Text>
        {comparePercentage !== undefined
          ? <Text
              style={{
                ...Headline14,
                color: comparePercentageColor,
                paddingLeft: 4
              }}
            >
              {parseFloat(comparePercentage).toFixed(2)}
              {"%"}
            </Text>
          : void 0}
      </View>
    </View>
  );
};

const WithAggregateFooter = Component => {
  const FooterComponent = props => {
    let {
      aggregates,
      column: {footer, ...restColumn},
      ...restProps
    } = props;
    if (!footer) {
      return null;
    }
    if (footer === true) {
      footer = {};
    }
    return (
      <Component
        {...restProps}
        item={aggregates}
        column={{...restColumn, ...footer}}
      />
    );
  };
  return FooterComponent;
};

export const customRenderTypes = {
  amountComparison: AmountComparisonCard,
  profitComparison: ProfitComparisonCard
};

export const customFooterRenderTypes = {
  amountComparison: WithAggregateFooter(AmountComparisonCard),
  profitComparison: WithAggregateFooter(ProfitComparisonCard)
};
