export default {
  labels: {
    itemName: 'Item Name',
    category: 'Category',
    itemId: 'Item Id',
    date: 'Date',
    isActive: 'Active / Inactive',
    subCategory: 'Sub Category',
    reorderLevel: 'Reorder Level',
    sellingPrice: 'Selling Price',
    description: 'Description',
    supplier: 'Supplier',
    manufacturer: 'Manufacturer',
    status: 'Status',
    rateContractValidity: 'Rate Contract Validity',
    addRow: 'Add Row',
    batchNumber: 'Batch Number',
    manufacturingDate: 'Manufacturing Date',
    expiryDate: 'Expiry Date',
    stockEntryDate: 'Stock Entry Date',
    remainingSelfLife: 'Remaining Self Life',
    supplyReceivedOn: 'Supply Received On',
    purchasedPrice: 'Purchased Price',
    stockAvailable: 'Stock Available',
    quantityReceived: 'Quantity Received',
    lastPurchaseDate: 'Last Purchase Date',
    lastPurchaseQuantity: 'Last Purchase Quantity',
    lastIndentDate: 'Last Indent Date',
    lastIndentQuantity: 'Last Indent Quantity',
    avgConsumption: 'Avg. Monthly Consumption',
    requiredQuantity: 'Required Quantity',
    reasonForPurchase: 'Reason',
    reasonForUpdation: 'Reason For Updation',
    unitPrice: 'Unit Price',
    fromDepartment: 'From Department',
    toDepartment: 'To Department',
    purchaseRequestNumber: 'Purchase Request Number',
    dateRaised: 'Date Raised',
    progressChart: 'Progress Chart',
    stockStatus: 'Stock Status',
    expiryStatus: 'Expiry Status',
    purchasePrice: 'Purchase Price',
    indentNumber: 'Indent Number',
    department: 'Department',
    responseDate: 'Response Date',
    providedQuantity: 'Provided Quantity',
    reasonForRejection: 'Reason For Rejection',
    speciality: 'Speciality',
    transferQuantity: 'Transfer Quantity',
    type: 'Type',
    stockRequired: 'Stock Required',
    stockProvided: 'Stock Provided',
    updatedQuantity: 'Updated Quantity',
    stockAdditionDate: 'Stock Addition Date',
    stockRequested: 'Stock Requested',
    dateOfDispensing: 'Date Of Dispensing',
    patientUHID: 'Patient UHID',
    patientName: 'Patient Name',
    dispensedBy: 'Dispensed By',
    noOfItems: 'No of Items',
    quantityDispensed: 'Quantity Dispensed',
    updatedStock: 'Updated Stock',
    totalPrice: 'Total Price',
    assetName: 'Asset Name',
    quantityAssignedDep: 'Quantity Assigned To Dep.',
    quantityInStocks: 'Quantity In Stocks',
    serialNo: 'Serial No.',
    value: 'Value',
    days: 'No. of Days',
    lastLoanOutDate: 'Last Loaned-Out Dated',
    lastLoanOutQuantity: 'Last Loaned-Out Quantity',
    statusTracking: 'Status Tracking',
    prescription: 'Prescription',
    invoice: 'Invoice',
    isVaccine: 'Is Vaccine',
    revaccinateGap: 'ReVaccinate Gap',
    vaccineName: 'Vaccine Name',
    weeks: 'Weeks',
    years: 'Years',
    noOfDoses: 'No of Doses',
    allDosesOccuring: 'All Doses are recurring at equal intervals',
    paymentType: 'Payment Type',
    vaccinationPeriod: 'Vaccination Period',
    amount: 'Amount',

  },
  placeholders: {
    itemName: 'Item Name',
    category: 'Category',
    subCategory: 'Sub Category',
    reorderLevel: 'Reorder Level',
    sellingPrice: 'Selling Price',
    description: 'Description',
    supplier: 'Supplier',
    manufacturer: 'Manufacturer',
    rateContractValidity: 'Rate Contract Validity',
    batchNumber: 'Batch Number',
    manufacturingDate: 'Manufacturing Date',
    expiryDate: 'Expiry Date',
    stockEntryDate: 'Stock Entry Date',
    remainingSelfLife: 'Remaining Self Life',
    supplyReceivedOn: 'Supply Received On',
    stockAvailable: 'Stock Available',
    quantityReceived: 'Quantity Received',
    selectItemName: 'Item Name',
    fromDepartment: 'From Department',
    toDepartment: 'To Department',
    serialNo: 'Serial No.',
    days: 'No. of Days',
    enterAmount: 'Enter Amount',

  },
  title: {
    centralInventory: 'Central Inventory',
    vaccineDetails: 'Vaccine Details',
    departmentInventory: 'Department Inventory',
    itemDetails: 'Item Details',
    assetDetails: 'Asset Details',
    loanOutDetails: 'Loan Out Details',
    manufacturerSupplierDetails: 'Manufacturer & Supplier Details',
    batchInformation: 'Batch Information',
    indentDetails: 'Indent Details',
    purchaseRequestDetails: 'Purchase Request Details',
    transferStock: 'Transfer Stock',
    transferStockDescription: 'Transfer stocks to departments',
    requests: 'Requests (Pharmacy)',
    assetRequests: 'Requests (Assets)',
    batchDetails: 'Batch Details',
    batchDescription: 'Details of the batch to edit. Edited details will be sent for approval to the Medical Superintendent.',
    departmentRecords: 'Dept. Records (Pharmacy)',
    centralRecords: 'Central Records (Pharmacy)',
    BatchDetailsBeforeUpdation: 'Batch Details Before Updation',
    BatchDetailsAfterUpdation: 'Batch Details After Updation',
    lowShelfLifeTitle: 'Remaining shelf life less than 70%!',
    lowShelfLifeDescription: 'An approval is needed to add this item in the inventory.',
    listOfItems: 'List Of Items',
    lowShelfLifeWarning: 'Request to add an item with low shelf life to the inventory. The Item has a lower shelf life than 70%.',
  },
  buttons: {
    addItem: 'Add Item',
    addAsset: 'Add Asset',
    addStock: 'Add Stock',
    provideRemainingStock: 'Provide Remaining Stock',
    exportToExcel: 'Export To Excel',
    raisePurchaseRequest: 'Raise Purchase Request',
    transferStock: 'Transfer Stock',
    raiseIndent: 'Raise Indent',
    transfer: 'Transfer',
    sendForApproval: 'Send For Approval',
    cancel: 'Cancel',
    edit: 'Edit',
    provideStock: 'Provide Stock',
    submit: 'Submit',
    reject: 'Reject',
    approve: 'Approve',
    addNewRow: 'Add New Row',
    loanOut: 'Loan Out',
  },
  messages: {
    itemAddedSuccessfully: 'Item has been added successfully.',
    itemUpdatedSuccessfully: 'Item has been updated successfully.',
    itemAlreadyExists: 'Item Already Exists.',
    batchAddedSuccessfully: 'Batch has been added successfully.',
    indentRaisedSuccessfully: 'Indent has been raised successfully.',
    transferStockSuccessfully: 'Stock has been transfer successfully.',
    requestRaisedSuccessfully: 'Request has been raised successfully.',
    sendForApproval: 'Batch update approval request has been send successfully.',
    stockProvidedSuccessfully: 'Stock has been provided to the department successfully.',
    stockAlreadyProvided: 'Stock has been already provided to the department.',
    indentStatusApproval: 'Status for this request not approved by MSI ',
    transferRequest: 'Transfer request has been raised successfully.',
    transferRequestReject: 'Transfer request has been rejected successfully.',
    transferRequestApprove: 'Transfer request has been approved succesfully',
    transferRequestAlreadyApproved: 'Transfer request has been already approved.',
    transferRequestAlreadyRejected: 'Transfer request has been already rejected.',
    loginUserError: 'Login user is not an employee.',
    batchUpdateRequestReject: 'Batch update request has been rejected successfully.',
    batchUpdateRequestApprove: 'Batch update request has been approved succesfully',
    batchUpdateRequestAlreadyApproved: 'Batch update request has been already approved.',
    batchUpdateRequestAlreadyRejected: 'Batch update request has been already rejected.',
    lowShelfLifeRequestReject: 'Low-Shelf life request has been rejected successfully.',
    lowShelfLifeRequestApprove: 'Low-Shelf life request has been approved succesfully',
    lowShelfLifeRequestAlreadyApproved: 'Low-shelf life request has been already approved.',
    lowShelfLifeRequestAlreadyRejected: 'Low-shelf life request has been already rejected.',
    lowShelfLifeApproval: 'Low-shelf life request has been raised successfully.',
    batchUpdationError: 'Batch update request cannot be raised after 7 days of stock entry date',
  },
  headers: {
    itemId: 'Item Id',
    itemName: 'Item Name',
    assetName: 'Asset Name',
    category: 'Category',
    subCategory: 'Sub Category',
    manufacturer: 'Manufacturer',
    description: 'Description',
    batchNo: 'Batch No.',
    supplyReceivedOn: 'Supply Received On',
    stockEntryDate: 'Stock Entry Date',
    manufacturingDate: 'Manufacturing Date',
    expiryDate: 'Expiry Date',
    remainingSelfLife: 'Remaining Self Life',
    stockAvailable: 'Stock Available',
    quantityReceived: 'Quantity Received',
    purchasedPrice: 'Purchase Price',
    status: 'Status',
    stockStatus: 'Stock Status',
    expiryStatus: 'Expiry Status',
    dateRaised: 'Date Raised',
    purchaseRequestNumber: 'Purchase Request Number',
    department: 'Department',
    noOfItems: 'No of Items',
    responseDate: 'Response Date',
    indentNumber: 'Indent Number',
    active: 'Active/Inactive',
    speciality: 'Speciality',
    avgConsumption: 'Avg. Monthly Consumption',
    transferQuantity: 'Transfer Quantity',
    type: 'Type',
    date: 'Date',
    stockRequired: 'Stock Required',
    stockProvided: 'Stock Provided',
    batchNumber: 'Batch Number',
    fromDepartment: 'From Department',
    toDepartment: 'To Department',
    stockAdditionDate: 'Stock Addition Date',
    dateOfDispensing: 'Date Of Dispensing',
    patientUHID: 'Patient UHID',
    patientName: 'Patient Name',
    dispensedBy: 'DispensedBy',
    value: 'Value',
    lastLoanOutDate: 'Last Loan Out Dated',
    lastLoanOutQuantity: 'Last Loaned-Out Quantity',
    OPDNumber: 'OPD Number',
    quantity: 'Quantity',
    dispensingDate: 'Dispensing Date',
    issuedBy: 'Issued By',
    vaccinationPeriod: 'VACCINATION PERIOD',
    dose: 'DOSE',
  },
  errorMessage: {
    thisFieldCannotBeEmpty: 'This field cannot be empty',
  },
};
