import { calenderIcon, crossIcon } from '../images';
import { dropDownShadow } from './shadows';
import { colors, bgs } from './colors';
import { h13_Regular, h12_Regular } from './fonts';
import { manazeV6Colors } from './manazeV6Colors';
import { Headline11, Headline8, Headline9 } from './Headlines';
import vars from './vars';

const {
  nero, redTextColor, darkBlackColor, deActiveDateColor,
} = colors;
const {
  primary_2, primary_5, neutral_5, neutral_1, neutral_4,
} = manazeV6Colors;
const { whiteBg, paleBlue, paleBlueLight } = bgs;

const calendarComponentStyle = {
  dataView: {
    header: {
      containerStyle: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      prev: {
        prevIcon: void 0,
        imageStyle: {},
        viewStyle: {
          width: 20,
          height: 20,
          backgroundColor: primary_5,
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'center',
        },
        textStyle: { color: primary_2, fontWeight: 'bold' },
      },
      next: {
        nextIcon: void 0,
        imageStyle: {},
        viewStyle: {
          width: 20,
          height: 20,
          backgroundColor: primary_5,
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'center',
        },
        textStyle: { color: primary_2, fontWeight: 'bold' },
      },
      year: {
        viewStyle: {
          cursor: 'pointer',
          flex: 1,
          padding: 5,
          justifyContent: 'center',
          alignItems: 'center',
        },
        textStyle: { ...Headline8, color: primary_2 },
      },
      month: {
        viewStyle: {
          flex: 1,
          cursor: 'pointer',
          padding: 5,
          justifyContent: 'center',
          alignItems: 'center',
        },
        textStyle: { ...Headline8, color: primary_2 },
      },
    },
    body: {
      weekContainerStyle: {
        flexDirection: 'row',
        paddingTop: 5,
        alignItems: 'center',
      },
    },
  },
  monthView: {
    containerStyle: {
      paddingTop: 10,
      paddingBottom: 10,
      ...h13_Regular,
    },
    header: {
      containerStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        // justifyContent: 'space-around',
      },
      prev: {
        prevIcon: void 0,
        imageStyle: {},
        viewStyle: {
          width: 20,
          height: 20,
          backgroundColor: primary_5,
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'center',
        },
        textStyle: { color: primary_2, fontWeight: 'bold' },
      },
      next: {
        nextIcon: void 0,
        imageStyle: {},
        viewStyle: {
          width: 20,
          height: 20,
          backgroundColor: primary_5,
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'center',
        },
        textStyle: { color: primary_2, fontWeight: 'bold' },
      },
      year: {
        viewStyle: {
          cursor: 'pointer',
          flex: 1,
          padding: 5,
          justifyContent: 'center',
          alignItems: 'center',
        },
        textStyle: { ...Headline8, color: primary_2 },
      },
    },
    bodyStyle: {
      monthRowStyle: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        cursor: 'pointer',
      },
      monthTextStyle: {
        padding: 7,
        ...Headline9,
        color: neutral_1,
      },
      monthViewStyle: {
        cursor: 'pointer',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },
  yearView: {
    containerStyle: {
      paddingTop: 10,
      paddingBottom: 10,
      ...h13_Regular,
    },
    header: {
      containerStyle: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      prev: {
        prevIcon: void 0,
        imageStyle: {},
        viewStyle: {
          width: 20,
          height: 20,
          backgroundColor: primary_5,
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'center',
        },
        textStyle: { color: primary_2, fontWeight: 'bold' },
      },
      next: {
        nextIcon: void 0,
        imageStyle: {},
        viewStyle: {
          width: 20,
          height: 20,
          backgroundColor: primary_5,
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'center',
        },
        textStyle: { color: primary_2, fontWeight: 'bold' },
      },
      year: {
        viewStyle: {
          cursor: 'pointer',
          flex: 1,
          padding: 5,
          justifyContent: 'center',
          alignItems: 'center',
        },
        textStyle: { ...Headline8, color: primary_2 },
      },
    },
    bodyStyle: {
      // container: {justifyContent: 'space-around', alignItems: 'center'},
      yearRowStyle: {
        flexDirection: 'row',
        cursor: 'pointer',
        justifyContent: 'space-between',
      },
      yearTextStyle: { padding: 7, ...Headline9, color: neutral_1 },
      yearViewStyle: {
        cursor: 'pointer',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },
  dateStyle: {
    viewStyle: {
      flex: 1,
      paddingTop: 5,
      paddingBottom: 5,
      // minWidth: 15,
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    deActiveViewStyle: {},
    selectedViewStyle: {},
    textStyle: {
      paddingLeft: 5,
      paddingRight: 5,
      whiteSpace: 'pre-wrap',
      ...Headline9,
      color: neutral_1,
    },
    deActiveTextStyle: {
      color: neutral_5,
    },
    selectedTextStyle: {
      color: primary_2,
      borderBottomStyle: 'solid',
      borderBottomColor: primary_2,
      borderBottomWidth: 2,
    },
  },
  weekComponentStyle: {
    viewStyle: {
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      padding: 5,
      cursor: 'pointer',
    },
    textStyle: { textAlign: 'center', color: neutral_4, ...Headline11 },
    primaryViewStyle: {},
    primaryTextStyle: { color: redTextColor },
  },
  timeViewStye: {
    seperatorStye: {
      width: '100%',
      height: 1,
      backgroundColor: '#97979766',
      marginBottom: 5,
    },
    timeContainerStyle: { flexDirection: 'row', alignItems: 'center' },
    itemStyle: { paddingLeft: 5, alignItems: 'center' },
    mainStyle: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: 6,
    },
  },
};

const dateInputStyle = {
  boxStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: vars.colors.grey.color6,
    borderRadius: 4,
    backgroundColor: vars.colors.white,
  },
  inputContainerStyle: {
    flex: 1,
  },
  textInputStyle: {
    outline: 'none',
    color: nero,
    borderStyle: 'none',
    borderWidth: 0,
    borderColor: 'none',
    padding: 6,
    backgroundColor: paleBlueLight,
    ...vars.headings.h13,
  },
  dateCalendarStyle: {
    calendarIconStyle: {
      image: calenderIcon,
      crossImage: crossIcon,
      imageStyle: { width: 15, height: 15 },
      viewStyle: { paddingLeft: 6, paddingRight: 6 },
    },
    timeCalendarIconStyle: {
      image: void 0,
      imageStyle: { width: 15, height: 15 },
      viewStyle: { paddingLeft: 6, paddingRight: 6 },
    },
    modalStyle: {
      marginTop: 8,
      marginBottom: 8,
      padding: 6,
      width: 270,
      height: 285,
      padding: 10,
      borderRadius: 4,
      backgroundColor: 'white',
      ...dropDownShadow,
    },
    calendarStyle: {
      ...calendarComponentStyle,
    },
  },
};
export { dateInputStyle };
