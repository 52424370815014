export default {
  labels: {
    scanner: 'Scanner Available',
    enterUHID: 'Enter UHID',
    uhid: 'UHID',
    name: 'Name',
    age: 'Age',
    department: 'Department',
    speciality: 'Speciality',
    gender: 'Gender',
    doctorName: 'Doctor Name',
    type: 'Type',
    labType: 'LabType',
    testType: 'TestType',
    amount: 'Amount',
    currency: 'Currency',
    patientType: 'PatientType',
    discount: 'Discount',
    billAmount: 'Bill Amount',
    addNewRow: 'Add New Row',
    relation: 'Relation',
    mobile: 'Mobile No.',
    latestOPD: 'Latest Opd Id',
    lastConsultation: 'Latest/Last Consultation',
    itemId: 'Item Id',
    itemName: 'Item Name',
    category: 'Category',
    manufacturer: 'Manufacturer',
    quantity: 'Quantity',
    unitPrice: 'Unit Price',
    stockAvailable: 'Stock Available',
    price: 'Price ',
    suggestions: 'Suggestions',
    documents: 'Upload Prescription',
    patientName: 'Patient Name',
    date: 'Date',
    sno: 'S.no.',
    services: 'Services/Items',
    qty: 'Qty',
    unitCharges: 'Unit Charges',
    total: 'Total',
    grandTotal: 'Grand Total',
    frequency: 'Frequency',
    doses: 'Doses',
    doseTime: 'Dose Time',
  },
  title: {
    labTestBill: 'Generate Lab Test Bill',
    pharmacyTestBill: 'Generate Pharmacy Test Bill',
  },
  placeholders: {
    enterUHID: 'Enter UHID',
  },
  buttons: {
    search: 'Search',
    exportToExcel: 'Export To Excel',
  },
  messages: {
    enterUHID: 'Please Enter Patient UHID to Generate Lab-Test Bill',
    incorrectUHID: 'Patient Details not found with the given UHID.',
    billingDetailsSavedSuccessfully: 'Billing Details has been saved succesfully.',
    invoiceGenerateSuccessfully: 'Invoice has been generated successfully.',
  },
  headers: {
    date: 'Date',
    patientName: 'Patient Name',
    uhid: 'UHID',
    opdNo: 'OPD No.',
    generatedBy: 'Generated By',
    billingAmount: 'Billing Amount',
    crNo: 'CR No.',
    department: 'Department',
    doctorName: 'Doctor Name',
    speciality: 'Speciality',
  },
};
