import WaitingRoomTabs from './WaitingRoomTabs';
import CancelModal from './CancelModal';
import { ROUTES } from '../../../Lib/constants';
import PatientVideoCalling from '../VideoCalling';

export default {
  [ROUTES.patientWaitingRoom.name]: {
    screen: WaitingRoomTabs,
    expanded: true,
  },

  'cancel-modal': {
    screen: CancelModal,
    expanded: true,
  },
  [ROUTES.patientVideoCalling.name]: {
    screen: PatientVideoCalling,
    modal: true,
    modalProps: {
      position: 'fullScreen',
    },
  },

};
