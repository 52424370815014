import React from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
} from '@applane/react-core-components';
import { manazeV6Colors } from '../../theme/manazeV6Colors';
import { Headline3, Headline7, Headline9 } from '../../theme/Headlines';
import { Action } from '../action/Action';
import { crossIconPng as crossIcon } from '../../images';

const {
  neutral_1, surface_white, neutral_3, primary_2,
} = manazeV6Colors;
const confirmModalStyle = {
  containerStyle: {
    backgroundColor: surface_white,
    padding: 24,
    borderRadius: 12,
  },
  headerContainerStyle: {
    flexDirection: 'row',
  },
  titleTextStyle: {
    ...Headline3,
    color: neutral_1,
    numberOfLines: 2,
    flex: 1,
  },
  crossIconContainerStyle: {
    paddingLeft: 6,
    paddingRight: 6,
    margin: 3,
    cursor: 'pointer',
  },
  crossIconStyle: {
    // height: 16,
    // width: 16,
  },
  descriptionContainerStyle: {
    marginTop: 17,
    flexDirection: 'row',
  },
  descriptionStyle: { ...Headline9, color: neutral_3 },
  actionRowStyle: {
    marginTop: 33,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flexWrap: 'wrap-reverse',
  },
  buttonContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 6,
    minWidth: 60,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 24,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: primary_2,
    marginLeft: 16,
    marginTop: 5,
  },
  buttonTextStyle: { ...Headline7, color: primary_2 },
  activeButtonContainerStyle: {
    backgroundColor: primary_2,
  },
  activeButtonTextStyle: {
    color: surface_white,
  },
  iconStyle: {
    height: 18,
    width: 18,
    margin: 3,
    marginRight: 11,
  },
};
const getValueComponent = ({ value, valueTextStyle, ...props }) => {
  if (typeof value === 'function') {
    value = value({ ...props });
  }
  if (!value) {
    return null;
  }
  if (React.isValidElement(value)) {
    return value;
  }
  if (typeof value !== 'string') {
    value = JSON.stringify(value);
  }
  return <Text style={valueTextStyle}>{value}</Text>;
};
export const StandardConfirmView = (props) => {
  let {
    icon,
    title = 'title',
    message = 'message',
    hideCancel,
    hideCross,
    hideConfirm,
    cancelText = 'Cancel',
    confirmText = 'Ok',
    closeModal,
    Action,
    action,
    actions,
    containerStyle,
    headerContainerStyle,
    titleTextStyle,
    crossIconContainerStyle,
    crossIconStyle,
    descriptionContainerStyle,
    descriptionStyle,
    actionRowStyle,
    buttonContainerStyle,
    activeButtonContainerStyle,
    buttonTextStyle,
    activeButtonTextStyle,
    iconStyle,
    ...restProps
  } = props;
  if (!title && !message) {
    return null;
  }
  if (actions) {
    if (typeof actions === 'function') {
      actions = actions({ action });
    }
  } else {
    const cancelButton = hideCancel ? void 0 : { text: cancelText };
    const confirmButton = hideConfirm
      ? void 0
      : { ...action, text: confirmText, active: true };
    actions = [cancelButton, confirmButton];
  }
  const onClose = (e) => {
    setTimeout((_) => {
      props.navigation && props.navigation.pop && props.navigation.pop();
    }, 0);
  };
  return (
    <View style={containerStyle}>
      <View style={headerContainerStyle}>
        {icon && <Image style={iconStyle} source={icon} />}
        <View style={{ flex: 1, overflow: 'hidden', flexDirection: 'row' }}>
          {getValueComponent({
            value: title,
            valueTextStyle: titleTextStyle,
            ...restProps,
          })}
        </View>
        {hideCross ? (
          void 0
        ) : (
          <TouchableOpacity style={crossIconContainerStyle} onPress={onClose}>
            <Image style={crossIconStyle} source={crossIcon} />
          </TouchableOpacity>
        )}
      </View>
      <View style={descriptionContainerStyle}>
        {getValueComponent({
          value: message,
          valueTextStyle: descriptionStyle,
          ...restProps,
        })}
      </View>
      {actions.length && (
        <View style={actionRowStyle}>
          {actions.map((action, index) => {
            if (!action) {
              return null;
            }
            if (action && typeof action === 'function') {
              action = action(props);
            }
            const {
              text, icon, active, ...restAction
            } = action;
            return (
              <Action
                {...restProps}
                style={{
                  ...buttonContainerStyle,
                  ...(active && activeButtonContainerStyle),
                  ...(index === 0 && { marginLeft: 0 }),
                }}
                action={restAction}
                closeModal={onClose}
              >
                {icon ? <Image source={icon} /> : void 0}
                {text ? (
                  <Text
                    style={{
                      ...buttonTextStyle,
                      ...(active && activeButtonTextStyle),
                    }}
                  >
                    {text}
                  </Text>
                ) : (
                  void 0
                )}
              </Action>
            );
          })}
        </View>
      )}
    </View>
  );
};
const typeWiseConfirmModal = {
  standard: (props) => StandardConfirmView({ ...confirmModalStyle, ...props, Action }),
};
export default (props) => {
  const { navigation } = props;
  let { action: { confirm, ...restAction } = {}, ...restConfirmProps } = navigation && navigation.getParams && navigation.getParams('confirmProps') || {};
  if (!confirm) {
    return null;
  }
  if (confirm === true) {
    confirm = {};
  }
  const { type, ...restConfirm } = confirm;
  const confirmModalComponent = (type && typeWiseConfirmModal[type]) || typeWiseConfirmModal.standard;
  return confirmModalComponent({
    ...props,
    ...restConfirmProps,
    ...restConfirm,
    action: restAction,
  });
};
