import React, {Component} from 'react';
import moment from 'moment';
import {ScrollView, View} from '@applane/react-core-components';
import {cloneDeep} from 'lodash';
import {getRangeData, timeZoneOffset} from './DateUtility';
import {DateInput} from '../../input-components/Editors';
import {ToggleButton} from '@applane/react-toggle';
import {dateFilterTheme} from '../../../theme/dateFilterTheme';

const dateSelectorData = [
  {label: 'Day', value: 'Day'},
  {label: 'Week', value: 'Week'},
  {label: 'Month', value: 'Month'},
  {label: 'Year', value: 'Year'},
];
class DateRangeFilter extends Component {
  setValue = ({field, newValue}) => {
    let {
      onChangeValue,
      data = {},
      field: fieldName,
      asParam,
      asGlobalParam,
    } = this.props;
    data = data ? {...data} : {};
    let value = data[fieldName];
    value = value ? value.value : {};
    if ((field === 'to' ? !value.from : !value.to) && !newValue) {
      delete data[fieldName];
    } else {
      if (field === 'from') {
        if (newValue) {
          const dateData = getRangeData({filterOf: 'Custom', from: newValue});
          value.from = dateData?.from;
        } else {
          value.from && delete value.from;
        }
      }
      if (field === 'to') {
        if (newValue) {
          const dateData = getRangeData({filterOf: 'Custom', to: newValue});
          value.to = dateData?.to;
        } else {
          value.to && delete value.to;
        }
      }
      const valueData = {...value, filterOf: 'Custom'};
      data[fieldName] = {
        value: valueData,
        filter: {
          filterType: 'date',
          value: valueData,
          asParam,
          asGlobalParam,
        },
      };
    }
    onChangeValue && onChangeValue(data);
  };
  applyFilter = data => {
    let {
      onChangeValue,
      data: filterData,
      field: fieldName,
      asParam,
      asGlobalParam,
    } = this.props;
    filterData = filterData ? {...filterData} : {};
    data = data ? {...data} : {};
    let {value} = data;
    let {value: oldValue} = filterData[fieldName] || {};
    oldValue = oldValue ? {...oldValue} : {};
    let from = '';
    let to = '';
    let filterOf = '';
    if (value === oldValue.filterOf || value == 'No Filter') {
      data.removeFilter = true;
    } else {
      const dateData = getRangeData({filterOf: value, from, to});
      from = dateData?.from;
      to = dateData?.to;
      filterOf = value;
    }
    if (data.removeFilter) {
      delete filterData[fieldName];
    } else {
      const valueData = {from, to, filterOf};
      filterData[fieldName] = {
        value: valueData,
        filter: {
          filterType: 'date',
          value: valueData,
          asParam,
          asGlobalParam,
        },
      };
    }
    onChangeValue && onChangeValue(filterData);
  };

  render() {
    let {data = {}, field} = this.props;
    let value = cloneDeep(data[field]);
    value = value && value.value;
    value = value || {};
    let filtersData = {};
    if (data && field && data[field]) {
      filtersData.date = {
        value: [data[field]?.value?.filterOf],
        filter: {
          ...data[field].filter,
          value: [data[field]?.value?.filterOf],
        },
      };
    }
    if (value && value.to) {
      value.to = moment(value.to)
        .subtract(timeZoneOffset, 'minutes')
        .endOf('day')
        .toDate();
    }
    return (
      <ScrollView style={{flex: 1, padding: 5}}>
        {/* <ToggleButton
          value={value.filterOf}
          options={dateSelectorData}
          onChangeValue={value => {
            this.applyFilter({value, source: 'Toggle'});
          }}
          {...dateFilterTheme.dateFilterToggleTheme}
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        /> */}
        <View style={{padding: 8}}>
          <DateInput
            label="From"
            placeholder="From"
            variant="filled"
            value={value.from}
            onChangeValue={value => {
              this.setValue({field: 'from', newValue: value});
            }}
          />
          <View style={{height: 10}} />
          <DateInput
            label="To"
            placeholder="To"
            variant="filled"
            value={value.to}
            onChangeValue={value => {
              this.setValue({field: 'to', newValue: value});
            }}
          />
        </View>
      </ScrollView>
    );
  }
}

export default DateRangeFilter;
