import React from 'react';
import {Platform} from '@applane/react-core-components';

export const resolveExp = (values, key) => {
  if (!values || !key) {
    return values;
  }
  if (typeof key === 'function') {
    return key(values);
  }
  if (Array.isArray(values)) {
    var result = [];
    for (var i = 0; i < values.length; i++) {
      var row = values[i];
      var resolvedValue = resolveExp(row, key);
      if (resolvedValue !== undefined) {
        if (Array.isArray(resolvedValue)) {
          for (var j = 0; j < resolvedValue.length; j++) {
            /* for save the duplicate values for field other then _id @sourabh -- 4-2-17*/
            if (key !== '_id' || result.indexOf(resolvedValue[j]) === -1) {
              result.push(resolvedValue[j]);
            }
          }
        } else {
          if (key !== '_id' || result.indexOf(resolvedValue) === -1) {
            result.push(resolvedValue);
          }
        }
      }
    }
    return result;
  } else {
    var value = values[key];
    if (value !== undefined) {
      return value;
    }
    var index = key.indexOf('.');
    if (index === -1) {
      return;
    }
    var firstPart = key.substring(0, index);
    var nextPart = key.substring(index + 1);
    return resolveExp(values[firstPart], nextPart);
  }
};

export const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

const getWidthStyle = (column, typeWiseWidth, minColumnWidth = 50) => {
  let {width, flex, type, maxWidth, minWidth = minColumnWidth} = column;
  let widthStyle = {};
  if (width) {
    widthStyle['width'] = width;
  } else if (flex) {
    widthStyle['flex'] = flex;
  } else if (type && typeWiseWidth && typeWiseWidth[type]) {
    const {width: typeWidth, flex: typeFlex} = typeWiseWidth[type];
    if (typeWidth) {
      widthStyle['width'] = typeWidth;
    } else if (typeFlex) {
      widthStyle['flex'] = typeFlex;
    }
  }
  if (!widthStyle.width && !widthStyle.flex) {
    widthStyle['flex'] = 1;
  }
  if (widthStyle.flex) {
    widthStyle.overflow = 'hidden';
    widthStyle.minWidth = minWidth;
    widthStyle.maxWidth = maxWidth;
  }
  return widthStyle;
};

export const getResolvedColumnsAndWidth = ({
  columns,
  clientWidth,
  typeWiseWidth,
  minColumnWidth,
  leftGap = 0,
  rightGap = 0,
}) => {
  let totalWidthConsumed = void 0;
  if (columns && columns.length) {
    let columnWidthConsumed = 0;
    let totalFlexColumns = 0;
    columns = columns.map(column => {
      if (!column) {
        return null;
      }
      const columnStyle = getWidthStyle(column, typeWiseWidth, minColumnWidth);
      let {width, flex} = columnStyle;
      if (width) {
        columnWidthConsumed += width;
      } else {
        totalFlexColumns += flex || 0;
      }
      return {
        column,
        widthStyle: columnStyle,
      };
    });
    if (columnWidthConsumed > 0 && clientWidth) {
      totalWidthConsumed = leftGap + rightGap;
      let availableFlexWidth =
        clientWidth - columnWidthConsumed - totalWidthConsumed;
      columns = columns.map(({column, widthStyle}) => {
        let {width, flex, minWidth, maxWidth} = widthStyle;
        let newWidthStyle = {};
        if (!width && flex) {
          if (availableFlexWidth > 0) {
            width = (availableFlexWidth * flex) / totalFlexColumns;
            width = Math.floor(width);
          } else {
            width = minWidth;
          }
        }
        if (minWidth && width < minWidth) {
          width = minWidth;
        } else if (maxWidth && width > maxWidth) {
          width = maxWidth;
        }
        newWidthStyle = {
          width,
        };
        totalWidthConsumed += width;
        return {
          column,
          widthStyle: newWidthStyle,
        };
      });
      if (totalWidthConsumed < clientWidth) {
        totalWidthConsumed = clientWidth;
      }
    }
  }
  return {columns, width: totalWidthConsumed};
};

export const detectMob = () => {
  if (
    Platform.OS !== 'web' ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
};
