export const manageMasterDataLogoActive = '/manage_active.png';
export const tickIcon = './tickIcon.png';
export const lifeLine = '/lifeline.png';
export const starIconRating = '/star-icon.png';
export const profile_preview = '/profile_preview.png';
export const favorite = '/favorite.png';
export const support = '/support.png';
export const mySchedule = '/myAppointment.png';
export const hospitalUpload = '/hospitalUpload.png';
export const myScheduleActive = '/myAppointmentActive.png';
export const patientQueueActive = '/patientQueueActive.png';
export const patientQueue = '/patientQueue.png';
export const supportActive = '/support_active.png';
export const managePatientsLogoActive = '/patient_active.png';
export const manageClinicsLogoActive = '/clinic_active.png';
export const analyticsLogoActive = '/analytics_active.png';
export const manageMasterDataLogo = '/manage.png';
export const managePatientsLogo = '/patient.png';
export const manageClinicsLogo = '/clinic.png';
export const analyticsLogo = '/analytics.png';
export const headerLogo = '/header_logo.png';
export const notInterested = 'not_interested-24px@3x.png';
export const screenExpandIcon = '/ic_expand.png';
export const profile = '/profile.png';
export const withoutProfile = '/without-profile-pic.png';
export const flagIcon = '/logo.png';
export const flagGreyIcon = './flagGrey.png';
export const searchIcon = '/search.png';
export const newSearchIcon = '/NewSerach.png';
export const notificationIcon = '/ic_notification.png';
export const userIcon = '/user.png';
export const busIcon = '/bus.png';
export const complainIcon = '/complaint.png';
export const dashBoardIcon = '/dashboard.png';
export const schoolIcon = '/school.png';
export const teamIcon = '/team.png';
export const rupeeIcon = '/finance.png';
export const rupeeIcon1 = '/rupee1.png';
export const routeIcon = '/route.png';
export const colorRouteIcon = '/routeIcon.png';
export const familyIcon = '/shape.png';
export const checkBoxIcon = '/checkboxUnSelected.png';
export const checkSelectedIcon = '/checkboxSelected.png';
export const unCheckboxIcon = '/unCheckbox.png';
export const backIconNew = '/back.png';
export const homeIcon = '/home.png';
export const parentChild = '/parentChild.jpg';
export const starIcon = '/star.png';
export const arrowDownIcon = '/arrowDown.png';
export const yellowStarIcon = '/yellowStar.png';
export const collapseIcon = './collapse.svg';
export const expandIcon = './expand.svg';
export const reportsIcon = './reports.svg';
export const sortUpIcon = '/sortUp.png';
export const moreOptionsIcon = '/moreOptions.png';
export const profileIcon = '/userIcon.png';
export const mobileIcon = '/mobileIcon.png';
// export const uploadIcon = './ic_upload.png';
export const documentIcon = './documents.png';
export const crossIcon = './cross.svg';
export const crossIconPng = './crossIcon.png';
export const calenderIcon = './ic_calender.svg';
export const backCrossIcon = './crossIcon.svg';
export const downArrow = './downArrow.png';
export const sortDownIcon = './sortDown.png';
export const descendingSortIcon = './arrowDownSort.png';
export const ascendingSortIcon = './arrowUpSort.svg';
export const plusIcon = './plus.png';
export const descendingSortActiveIcon = './descendingSort.svg';
export const ascendingSortActiveIcon = './ascendingSort.svg';
export const descendingSortUnActiveIcon = './descendingSort.svg';
export const ascendingSortUnActiveIcon = './ascendingSort.svg';
export const zoomInIcon = './zoomIn-icon.png';
export const zoomInSelectedIcon = './zoomIn-selected-icon.png';
export const zoomOutIcon = './zoom-out-icon.png';
export const zoomOutSelectedIcon = './zoom-out-selected-icon.png';
export const resetZoomIcon = './resetZoom.png';
export const resetZoomSelectedIcon = './resetSelectedZoom.png';
export const nextIcon = './next.png';
export const previousIcon = './previous.png';
export const pathIcon = './path.png';
export const path_greyIcon = './path_grey.png';
export const relocateNewIcon = './arrowUp.png';
export const tripIcon = './trip.png';
export const listViewIcon = './listView.png';
export const leftRightArrowsIcon = './leftRightArrows.png';
export const cameraIcon = './camera.png';
export const blackStarIcon = './blackStar.png';
export const sendChatIcon = './sendMessage.png';
export const chatBotFlagIcon = './chatbotFlag.png';
export const liveCameraIcon = './liveCamera.png';
export const deviceIcon = './device.png';
export const mapDirectionIcon = './mapDirection.png';
export const dublicateIcon = './dublicate.png';
export const newDeleteIcon = './deleteIcon.png';
export const greenLoacIcon = './Password.png';

export const complaintBusIcon = './ic_bus.png';
export const complaintReportIcon = './ic_report.png';
export const complaintFeeIcon = './ic_wallet.png';
export const chatIcon = './chat.png';

export const arrivalExpectedIcon = './arrivalExpected.png';
export const schoolLeftIcon = './schoolLeft.png';
export const schoolReachedIcon = './schoolReached.png';
export const stopReachedIcon = './stopReached.png';
export const vehicleStartedIcon = './vehicleStarted.png';
export const vehicleFlippedIcon = './vehicleFlipped.png';
export const playButtonIcon = './historyPlay.png';
export const historyStopIcon = './historyStop.png';
export const mapActionIcon = './mapActions.png';
export const studentIcon = './student.png';
export const montageNextWhiteIcon = './triangle.png';
export const montagePrevWhiteIcon = './trianglePre.png';
export const montagePlayIcon = './montagePlay.png';
export const youTubeLogo = 'youTubeLogo.png';
export const microphone = './microphone.png';
export const noMicrophone = './noMicrophone.png';
export const screenSharing = './screenSharingIcon.png';
export const pin = './pin.png';
export const unpin = './unpin.png';
export const stopVideo = './stopVideo.png';
export const stopVideoActive = './stopVideoActive.png';
export const videoColored = './videoColored.png';
export const videoWhite = './videoWhite.png';
export const imagePreview = './imagePreview.png';
export const videoPreview = './videoPreview.png';
export const pdfPreviewIcon = './pdfPreview.png';
export const descIcon = './desc.png';
export const bulletIcon = './bullets.png';
export const uploadPreview = './uploadReview.png';
export const uploadVideoPreview = './uploadVideoPreview.png';
export const backIcon = './backIcon.png';
export const uploadImageTransparent = './uploadImageTransparent.png';
export const greenStarIcon = './greenStar.png';
export const unSelectedStar = './unSelectedStar.png';
export const rightArrow = './rightArrow.png';
export const rightArrowWhite = './rightArrowWhite.png';
export const leftArrowWhite = './leftArrowWhite.png';
export const blackLeftArrow = './blackLeftArrow.png';
export const right_arrow = './right_arrow.png';
export const left_arrow = './left_arrow.png';
export const right_Arrow = './right_Arrow.png';
export const leftArrow = './leftArrow.png';
export const back_Icon = './backIcon.png';
export const wrongIcon = './wrongIcon.png';
export const subworkflow = './Subworkflow.png';

export const circleUnSelected = './circleUnSelected.png';
export const selectedCircle = './selectedCircle.png';
export const iconUserImage = './iconUserImage.png';
export const handRaised = './handRaised.png';
export const handRaisedActive = './handRaisedActive.png';
export const sessionViewIcon = './sessionView.png';
export const microphoneMD = './newDarkThemeIcon/microphoneMD.png';
export const noMicrophoneMD = './newDarkThemeIcon/noMicrophoneMD.png';
export const handRaisedMD = './newDarkThemeIcon/handRaisedMD.png';
export const handRaisedActiveMD = './newImages/handRaisedActiveMD.png';
export const stopVideoMD = './newDarkThemeIcon/stopVideoMD.png';
export const stopVideoActiveMD = './newDarkThemeIcon/stopVideoActiveMD.png';
export const activeStudent = './activeStudent.png';
export const inActiveStudent = './inActiveStudent.png';
export const allTabSelectedIcon = './allTabSelected.png';
export const allTabUnSelectedIcon = './newDarkThemeIcon/tabAllInActive.png';
export const chatSelected = './chatselected.png';
export const rowMenuIcon = './ic_menu.png';
export const liveClassRoomIcon = './liveClassRoomIcon.png';
export const liveClassRoomSelected = './liveClassRoomSelected.png';
export const lessonPlan = './lessonPlan.png';
export const lessonPlanSelected = './lessonPlanSelected.png';
export const checkedIcon = './checkedIcon.png';
export const nonSelectedSession = './nonSelectedSession.png';
export const selectedSession = './selectedSession.png';
export const selectionIcon = './selectionIcon.png';
export const nextWhiteArrow = './nextWhiteArrow.png';
export const prevBlackArrow = './prevBlackArrow.png';
export const uncheckedCheckboxIcon = './unchecked-checkbox.png';
export const selected_checkBoxIcon = './selected_checkBox.png';
export const reportIcon = './reportIcon.png';
export const reportSelectedIcon = './reportSelectedIcon.png';
export const gridViewSelectedIcon = './newDarkThemeIcon/gridViewSelected.png';
export const gridViewIcon = './newDarkThemeIcon/gridView.png';
export const privateChatIcon = './newDarkThemeIcon/privateChat.png';
export const privateChatSelectedIcon = './newDarkThemeIcon/privateChatSelected.png';
export const groupChatIcon = './newDarkThemeIcon/groupChat.png';
export const groupChatSelectedIcon = './newDarkThemeIcon/GroupChatSelected.png';
export const enterFullScreenIcon = './newDarkThemeIcon/enterFullScreen.png';
export const exitFullScreenIcon = './newDarkThemeIcon/exitFullScreen.png';
export const toggleExpandIcon = './newDarkThemeIcon/toggleExpand.png';
export const toggleCollapsedIcon = './newImages/toggleCollapsed.png';
export const miniCollapsedIcon = './newImages/miniCollapsedIcon.png';

export const leaveMeetingIcon = './newImages/leaveMeetingIcon.png';
export const minimizeIcon = './newImages/minimizeIcon.png';
export const screenShareIcon = './newImages/screenShareIcon.png';
export const backgroundImage = './newImages/backgroundImage.png';
export const trashIcon = './trash.png';
export const noisyMicIcon = './newDarkThemeIcon/noisyMic.png';
export const noisySelectedMicIcon = './noisySelected.png';
export const disConnectedIcon = './disConnected.png';
export const videoDeniedPermissionIcon = './newDarkThemeIcon/videoDeniedPermission.png';
export const audioDeniedPermissionIcon = './newDarkThemeIcon/audioDeniedPermission.png';
export const signalStrengthBadIcon = './signalStrengthBad.png';
export const signalStrengthBestIcon = './signalStrengthBest.png';
export const signalStrengthgoodIcon = './signalStrengthgood.png';
export const myVideoIcon = '../newDarkThemeIcon/myVideoIcon.png';
export const myVideoSelectedIcon = './myVideoSelectedIcon.png';
export const showAllVideo = './showAllVideo.png';
export const arrowDownWhite = './newDarkThemeIcon/arrowDown.png';
export const studentListaudioDenied = './newDarkThemeIcon/studentListaudioDenied.png';
export const studentListMicroPhone = './newDarkThemeIcon/studentListmuteIcon.png';
export const studentListNoMicrophone = './newDarkThemeIcon/studentListUnmuteicon.png';
export const studentListNoVideo = './newDarkThemeIcon/studentListNoVideo.png';
export const idleMarkerIcon = './oldImages/idleMarker.png';
export const studentListVideo = './newDarkThemeIcon/studentListVideo.png';
export const studentListvideoDenied = './newDarkThemeIcon/studentListvideoDenied.png';

export const studentListHandRaised = './newDarkThemeIcon/studentListHandRaised.png';
export const studentListChat = './newDarkThemeIcon/studentListChat.png';
export const mesageSendTickIcon = './messageSendTick.png';
export const prevWhiteIcon = './newDarkThemeIcon/prevWhite.png';
export const enterFullScreen22Icon = './newDarkThemeIcon/enterFullScreen22.png';
export const helpIcon = './help.png';
export const otpLogo = './otp_logo_sec.png';
export const classRoom_logo = './classroom_live_logo.png';
export const login_background = './login_background.png';
export const confirmationCrossIcon = './newDarkThemeIcon/confirmationCross.png';
export const arrowDownColored = './newDarkThemeIcon/arrowDownColored.png';
export const triangleDownWhiteDropdownIcon = './triangleDownWhiteDropdown.png';
export const triangleUpWhiteDropdown = './triangleUpWhiteDropdown.png';
export const tickWhiteIcon = './tickWhiteIcon.png';
export const ActiveStudentIcon = './ActiveStudentIcon.png';
export const ActiveStudentSelectedIcon = './ActiveStudentSelected.png';
export const pencilSelectedIcon = './canvasPenSelected.png';
export const pencilIcon = './canvasPen.png';
export const dropIcon = './Drop.png';
export const taxIcon = './tax_icon.png';

export const iraserIcon = './erageHilight.png';
export const iraserSelectedIcon = './erageHilightSlected.png';
export const moveCursor = './pointerHand.png';
export const moveCursorSelected = './pointerHandSelected.png';
export const zoom_in_preview = './zoom_in_preview.png';
export const zoom_out_preview = './zoom_out_preview.png';
export const reset_preview = './reset_preview.png';

export const link_icon = './linkIcon.png';
export const settingIcon = './settings@2x.png';
export const addIcon = './addicon.png';
export const uploadArrowIcon = './upload@3x.png';
export const smallUploadIcon = './smallUploadIcon.png';
export const rectangularChatIcon = './chatRectangleType.png';
export const eventIcon = './Event.png';
export const empty = './empty.png';
export const menu = './menu.png';
export const eventIconTop = './event_icon_top.png';
export const chatIconTop = './Chat_Icon_top.png';
export const Empty_Planner_Image = './Empty_Planner.png';
export const empty_3x = './empty@3x.png';
export const unitIcon = './unitIcon.png';
export const inputErrorIcon = './inputError.png';

// from headerImage for the header
export const workflowIcon = './headerImages/workflow.svg';
export const workflowSetUpIcon = './headerImages/setUp.svg';
export const workflowReviewIcon = './headerImages/review.svg';
export const workflowPlannerIcon = './headerImages/planner.svg';
export const workflowNotificationIcon = './headerImages/notification.svg';
export const workflowMyTaskIcon = './headerImages/myTask.svg';
export const workflowAddIcon = './headerImages/addIcon.svg';
export const workflowReportIcon = './headerImages/report.svg';
export const applyWorkflowIcon = './headerImages/workflowActive.svg';
export const applyWorkflowSetUpIcon = './headerImages/setUpActive.svg';
export const applyWorkflowReviewIcon = './headerImages/reviewActive.svg';
export const applyWorkflowPlannerIcon = './headerImages/plannerActive.svg';
export const applyWorkflowNotificationIcon = './headerImages/notificationActive.svg';
export const applyWorkflowMyTaskIcon = './headerImages/myTaskActive.svg';
export const applyWorkflowAddIcon = './headerImages/addIconActive.svg';
export const applyWorkflowreportIcon = './headerImages/reportActive.svg';
export const smallChatIcon = './headerImages/Chat1.png';
export const moderateChatIcon = './headerImages/Chat2.png';
export const bigChatIcon = './headerImages/Chat3.png';
export const rescheduleIcon = './Reschedule.png';
export const editWorkFlowIcon = './editWorkflow.png';
export const departmentIcon = './department.png';
export const topLevelFilterIconForDept = './top_level_filter_icon_for_dept.png';
export const whiteSettingsIcon = './headerImages/whiteSettingsIcon.png';
export const filterIcon = './ic_filter.png';
export const horizontalMoreOptionsIcon = './Options.png';
export const org2x = './Org@2x.png';
export const Unassign = './Unassigned.png';
export const downIconBlue = './blue_angleArrowDown.png';
export const blueArrowDown = './blue_arrow_down.png';
export const blueArrowBigDown = './blue_arrow_down@3x.png';
export const right = './right.png';
export const mediumRight = './right@2x.png';
export const bigRight = './right@3x.png';
export const icon_collapse = './Icon_Collaps.png';
export const icon_expand = './Icon_Expand.png';
export const icon_inProgress = './Icon_Inprogress.png';
export const icon_PDF = './Icon_PDF.png';
export const icon_completed = './Icon_Completed.png';
export const resetPassword = './resetPassword.svg';

export const scheduleIcon = './scheduleIcon.png';
export const rightArrowIcon = './rightArrowIcon.png';
export const moveToInApprovalIcon = './moveToInApprovalIcon.png';
export const blueChatIcon = './blueChatIcon.png';
export const moreIcon = './moreIcon.png';
export const filters = './filters.png';
export const activeFilters = './activeFilters.png';
export const dateFilter = './dateFilter.png';
export const activeDateFilter = './activeDateFilter.png';
export const bluePencilIcon = './BluePencilIcon.png';
export const closeIcon = './Close.png';
export const add_client_icon = './add_client.png';
export const edit_image = './edit_image.png';
export const approve_image = './approve.png';
export const add_image = './group-14.png';
export const add_icon = './group-14.png';
export const save_icon = './save.png';
export const setting_icon = './setting.png';
export const edit_icon = './edit.png';
export const back = './back.png';
export const editIcon = './editIcon.png';
export const temp_map = './temp_map.png';
export const delete_Image = './delete-vector.png';
export const modalCross = '/cross.png';
export const medicine = '/medicine.png';
export const roundCross = '/roundCross.png';
export const default_logo = './default_logo.png';
export const maleSign = './maleSign.png';
export const femaleSign = './femaleSign.png';
export const inviteLinksActive = './invitelinksActive.png';
export const inviteLinksInactive = './invitelinksInactive.png';
export const signalIcon = './signal_icon.png';
export const fullscreenIcon = './fullscreen-icon.png';
export const endCall = './endCall.png';
export const audioIcon = './audio.png';
export const muteIcon = './mute.png';
export const videoIcon = './video.png';
export const videoOffIcon = './videoOff.png';
export const snipToolIcon = './snip_tool.png';
export const myPatientsActive = './PatientsActive.png';
export const myPatientsInActive = './PatientsInActive.png';
export const downArrow_blue = './down_arrow.png';
export const sendMessageIcon = './sendMessage.png';
export const attachmentIcon = './attachment-icon.png';
export const pdfChatIcon = './pdfChat.png';
export const downloadChatIcon = './downloadChat.png';
export const whiteCrossIcon = './whiteCross.png';
export const VideoActive = './VideoActive.png';
export const VideoInactive = './VideoInactive.png';
export const Virtual = './doctorVist.png';
export const Doctor = './doctor.jpg';
export const Admin = './Admin.jpg';
export const FrontDesk = './frontDesk.jpg';
export const Patient = './Patient.jpg';
export const LessThan = './less-than.png';
export const MoreThan = './more-than.png';
export const checkbox_selected = './checkbox_selected.png';
export const calender = './calendar.png';
export const Watch = './watch.png';
export const Checkmark = './checkmark.png';
export const chatDownArrowIcon = './chatDownArrow.png';
export const doctorAppointment = '/doctorAppointment.png';
export const videoCall = '/videoCall.png';
export const pdf = '/pdf.png';
export const leftArrowPdf = '/left-arrow.png';
export const rightArrowPdf = '/right-arrow.png';
export const redCloseIcon = './closeRed.png';
export const activeStage = './active-stage.png';
export const backgroundImage1 = './backgroundImage.png';
export const HospitalLOGO = './group-19.png';
export const dropdown = './path.png';
export const searchFilter = './searchFilter.png';
export const analyticsIcon = './analytics.svg';
export const manageWardsIcon = './manageWards.svg';
export const ipdIcon = './ipd.svg';
export const mdmIcon = './mdm.png';
export const analyticsSelected = './analytics_selected.svg';
export const mdmSelected = './mdmSelected.png';
export const ipdSelected = './ipdSelected.png';
export const manageWardsSelected = './manageSelected.svg';
export const breadcrumbSeperator = './breadcrumbSeperator.svg';

// Notification Icons
export const notificationTick = './notificationTick.png';
export const notificationError = './notificationError.png';
export const notificationWarning = './notificationWarning.png';
export const editRecord = './edit.png';
export const userView = './userView.png';
export const arrowUpFilled = './arrow-up-filled.png';
export const arrowDownFilled = './arrow-down-filled.png';
export const bulkUploadIcon = './bulk-upload.svg';
export const downloadIcon = './downloadIcon.svg';
export const uploadIcon = './upload.svg';
export const existingPatient = './existingPatient.svg';
export const addPatient = './AddPatient.svg';
export const personIcon = './personIcon.svg';
export const barcode = './barcode.svg';
export const greenTick = './greenTick.svg';
export const exclamationFilled = './exclamationFilled.png';
export const breadCrumbBack = './breadCrumbBack.png';
export const startSessionButton = './startSessionButton.svg';
export const loginLogo = './mamcLoginLogo.png';
export const loginLogo4x = './hospitalNameLogo.png';
export const IPDUnSelected = './IPDUnSelected.png';
export const IPDSelected = './IPDSelected.png';
export const history = './history.png';
export const noStatus = './noStatus.png';
export const labTestIcon = 'labTest.svg';

export const leftArrowIconNew = './leftArrow.png';
export const rightArrowIconNew = './rightArrow.png';
export const filterActive = './filter_active.png';
export const filterInactive = './filter_inactive.png';
export const login_background_web = './mamcCollege.jpg';
export const hospitalLiveLogo = './mamcLoginLogo.png';
export const mamcLogo = './logo.png';

export const showIcon = './showIcon.png';
export const hideIcon = './hideIcon.png';
export const errorIcon = './error.png';
export const errorFilledIcon = './errorFilled.png';
export const fileUploadIcon = './fileUpload.png';
export const welcomeImage = './welcomeImage.png';
export const inventoryIcon = './inventory.svg';
export const selectedInventoryIcon = './inventorySelected.svg';
export const billingIcon = './billing.svg';
export const selectedBillingIcon = './billingSelected.svg';
export const auctionIcon = './auction.svg';
export const selectedAuctionIcon = './auctionSelected.svg';
export const confirmation = './Confirmation.png';
export const editSmallIcon = './Confirmation.png';
export const editWhiteSmallIcon = './Confirmation.png';
export const greenFileIcon = './Confirmation.png';
export const notificationHolidayIcon = './holidayIcon.png';
export const notificationTickWithBG = './notificationTickWithBG.png';
export const categoryIcon = './categoryIcon.png';
export const transferIcon = './transferIcon.png';
export const batchIcon = 'batchDetails.png';
export const componentTest = './componentTest.png';
export const detailIcon = './details.png';
export const issueToCompanyIcon = './issueToCompanyIcon.png';
export const warningIcon = './warningIcon.png';
export const addBlueSmallIcon = './addBlueSmall.png';
export const salaryHeartIcon = './salaryHeartIcon.png';
export const downloadSalarySlip = './downloadSalarySlip.png';
export const pendingIcon = './pendingIcon.png';
export const approvedIcon = './approved.png';
export const notificationReadIcon = './notificationReadIcon.png';
export const daysLimitApprovalIcon = './error2.png';
export const greenTickIcon = './greenTick.png';
export const redCrossIcon = './redCross.png';
export const playListIcon = './pending.png';
export const vaccineIcon = './vaccineIcon.png';
export const admitPatient = './admitPatient.png';
export const emergencyIcon = './emergencyIcon.png';
export const belongingsImage = './belongingsImage.png';
export const emergencyIconImage = './emergencyIconImage.png';
export const profileNonEditable = './profile_icon_nonEditable.png';
export const technicalIcon = './technicalIcon.png';
export const doctorSearchMaya = './doctorSearchMaya.png';
export const appointments = './appointments.png';
export const dashboardIcon = './dashboard.png';
export const medicalInformation = './medicalInformation.png';
export const lessThanWhite = './lessThanWhite.png';
export const moreThanWhite = './moreThanWhite.png';

