const getComputations = ({computations, path}) => {
  if (!computations) {
    return null;
  } else if (!path || !path.length) {
    return computations.self;
  } else if (!computations.children) {
    return null;
  }
  const [node, ...restPath] = path;
  const {field} = node;
  let childComputations = computations.children[field];
  return getComputations({computations: childComputations, path: restPath});
};

const getChildrenComputations = ({computations, path}) => {
  if (!computations || !computations.children) {
    return null;
  } else if (!path || !path.length) {
    //return its children
    const children = computations.children;
    const childrenSelfComputations = {};
    for (let child in children) {
      childrenSelfComputations[child] = children[child].self;
    }
    return childrenSelfComputations;
  }

  const [node, ...restPath] = path;
  const {field} = node;
  let childComputations = computations.children[field];
  return getChildrenComputations({
    computations: childComputations,
    path: restPath,
  });
};

const getParentComputations = ({computations, path}) => {
  if (!computations) {
    return null;
  } else if (!path || !path.length) {
    return null;
  }
  const lastPath = path[path.length - 1];
  const parentPath = path.slice(0, path.length - 1);
  const parentComputations = getComputations({computations, path: parentPath});
  if (parentComputations) {
    return {
      path: parentPath,
      computations: parentComputations,
      childField: lastPath.field,
    };
  }
};

const getComputationKey = ({key, path, _id}) => {
  let requiredKeys = [];
  if (_id) {
    requiredKeys.push(_id);
  }
  if (path) {
    path.forEach(node => {
      const {field, _id} = node;
      requiredKeys.push(field);
      if (_id) {
        requiredKeys.push(_id);
      }
    });
  }
  requiredKeys.push(key);
  return requiredKeys.join('_');
};

const getComputationsToRunForRemove = ({computations}) => {
  if (!computations) {
    return null;
  }
  const requiredComputations = [];

  for (let key in computations) {
    const computation = computations[key];
    const {remove} = computation;
    if (remove) {
      requiredComputations.push({key, computation});
    }
  }
  return requiredComputations;
};

const getComputationsToRun = ({field, computations}) => {
  if (!computations) {
    return null;
  }
  const requiredComputations = [];
  for (let key in computations) {
    const computation = computations[key];
    const {onChange, remove} = computation;
    if (!remove && onChange && onChange.indexOf(field) >= 0) {
      requiredComputations.push({key, computation});
    }
  }
  return requiredComputations;
};

//required for getteing child field delete case
const getComputationsToRunStartWith = ({field, computations}) => {
  if (!computations) {
    return null;
  }
  const requiredComputations = [];
  for (let key in computations) {
    const computation = computations[key];
    const {onChange = []} = computation;
    for (let changedField of onChange) {
      if (changedField.indexOf(field) === 0) {
        requiredComputations.push({key, computation});
      }
    }
  }
  return requiredComputations;
};

const getIdRecord = (data, _id) => {
  if (Array.isArray(data)) {
    if (!_id) {
      throw new Error(
        `_id must be defined to getIdrecord for data ${JSON.stringify(data)}`,
      );
    }
    const doc = data.find(doc => doc && doc._id === _id);
    if (!doc) {
      throw new Error(
        `Document not found for _id: [${_id}] in array data [${JSON.stringify(
          data,
        )}] `,
      );
    }
    return doc;
  } else if (data && data._id === _id) {
    return data;
  } else {
    throw new Error(
      `Document not found for _id: [${_id}] in document data [${JSON.stringify(
        data,
      )}] `,
    );
  }
};

const resolvePathFromData = ({data, path, _id}) => {
  let doc = getIdRecord(data, _id);
  if (path) {
    for (let node of path) {
      const {field, _id: childId} = node;
      doc = getIdRecord(doc[field], childId);
    }
  }
  return doc;
};

const getDocToRunComputation = ({data, path, _id}) => {
  let doc = getIdRecord(data, _id);
  doc = {...doc};
  if (path) {
    for (let node of path) {
      const {field, _id: childId} = node;
      let childDoc = getIdRecord(doc[field], childId);
      childDoc = {...childDoc};
      let parentDoc = {...doc};
      childDoc['_parent'] = parentDoc;
      doc = childDoc;
    }
  }
  return doc;
};

const getPathForEachChild = ({data, path, childField, _id}) => {
  path || (path = []);
  let doc = resolvePathFromData({data, path, _id});
  const childFieldData = doc[childField];
  if (!childFieldData) {
    return null;
  } else {
    if (Array.isArray(childFieldData)) {
      return childFieldData.map(childDoc => {
        const childPath = [
          ...path,
          {
            _id: childDoc._id,
            field: childField,
          },
        ];
        return childPath;
      });
    } else {
      const childPath = [
        ...path,
        {
          field: childField,
        },
      ];
      return childPath;
    }
  }
};

const addComputationsToQueue = ({
  _id,
  path,
  affectedBy,
  computations,
  queue,
}) => {
  if (!computations || !computations.length) {
    return queue;
  }
  queue || (queue = []);
  //can check if computation already exists in queue or not

  for (let index in computations) {
    const {computation, key} = computations[index];
    const computationKey = getComputationKey({key, path, _id});
    //check if exists in queue
    const exists = queue && queue.find(q => q.key === computationKey);
    if (!exists) {
      queue.push({
        key: computationKey,
        computation,
        affectedBy,
        path,
        _id,
      });
    }
  }
  return queue;
};

const getAffectedComputations = ({
  computations,
  data,
  updates: batchUpdates,
}) => {
  let affectedComputations = [];
  if (!Array.isArray(batchUpdates)) {
    batchUpdates = [batchUpdates];
  }
  batchUpdates.forEach(batchUpdates => {
    const {_id, path, updates = {}} = batchUpdates;
    const {set = {}, remove} = updates;
    //get self computations
    const targetedComputations = getComputations({computations, path});
    for (let field in set) {
      //if change in parent field, then parent and all child (all ids) will affect
      // if change in child field, then parent and only concerned child(only current _id of child) will affect

      //get parent computations
      const targetedParentComputations = getParentComputations({
        computations,
        path,
      });

      //get children computations
      const targetedChildrenComputations =
        !remove && getChildrenComputations({computations, path});

      //add self comutations
      const selfAffectedComputations =
        !remove &&
        getComputationsToRun({field, computations: targetedComputations});
      addComputationsToQueue({
        computations: selfAffectedComputations,
        path,
        affectedBy: field,
        _id,
        queue: affectedComputations,
      });

      //add computations  - parent depends on child
      if (targetedParentComputations) {
        const {
          path: parentPath,
          computations: parentComputations,
          childField,
        } = targetedParentComputations;
        const parentAffectedComputations = getComputationsToRun({
          computations: parentComputations,
          field: `${childField}.${field}`,
        });
        addComputationsToQueue({
          computations: parentAffectedComputations,
          path: parentPath,
          affectedBy: `${childField}.${field}`,
          _id,
          queue: affectedComputations,
        });
      }

      //add computations - child depends on parent
      //here if parent field changed and child depends on parent then its all data will be added
      if (targetedChildrenComputations && data) {
        for (let childField in targetedChildrenComputations) {
          const parentField = `_parent.${field}`;
          const requiredChildComputations = getComputationsToRun({
            computations: targetedChildrenComputations[childField],
            field: parentField,
          });
          if (requiredChildComputations) {
            //add for each path available in data
            const childPaths = getPathForEachChild({
              data,
              path,
              childField,
              _id,
            });
            if (childPaths) {
              for (let childPath of childPaths) {
                addComputationsToQueue({
                  computations: requiredChildComputations,
                  path: childPath,
                  affectedBy: parentField,
                  _id,
                  queue: affectedComputations,
                });
              }
            }
          }
        }
      }
    }

    if (remove) {
      //then we need to add parent computations only matches with "childField.*"
      //get parent computations

      //add self comutations for remove
      const selfRemoveComputations = getComputationsToRunForRemove({
        computations: targetedComputations,
        path,
      });

      addComputationsToQueue({
        computations: selfRemoveComputations,
        path,
        affectedBy: 'remove',
        _id,
        queue: affectedComputations,
      });
      const targetedParentComputations = getParentComputations({
        computations,
        path,
      });
      if (targetedParentComputations) {
        const {
          path: parentPath,
          computations: parentComputations,
          childField,
        } = targetedParentComputations;
        const parentAffectedComputations = getComputationsToRunStartWith({
          computations: parentComputations,
          field: `${childField}.`,
        });
        addComputationsToQueue({
          computations: parentAffectedComputations,
          path: parentPath,
          affectedBy: `${childField}.*`,
          remove: true,
          _id,
          queue: affectedComputations,
        });
      }
    }
  });
  return affectedComputations;
};

const getAffectedUpdates = async ({
  data,
  oldData,
  affectedComputations,
  computeProps = {},
}) => {
  const affectedUpdates = [];
  for (let affectedComputation of affectedComputations) {
    const {computation, path, _id} = affectedComputation;
    let docToRunComputation;
    if (computation.remove) {
      docToRunComputation = getDocToRunComputation({data: oldData, path, _id});
    } else {
      docToRunComputation = getDocToRunComputation({data, path, _id});
    }
    const _compute = computation['compute'];
    if (_compute) {
      const computedUpdates = await _compute(docToRunComputation, computeProps);
      if (computedUpdates) {
        affectedUpdates.push({
          _id,
          path,
          updates: computedUpdates,
        });
      }
    }
  }
  return affectedUpdates;
};
const compute = ({computations, computeProps, data, oldData, updates}) => {
  const affectedComputations = getAffectedComputations({
    computations,
    data,
    updates,
  });
  if (!affectedComputations || !affectedComputations.length) {
    return null;
  }
  return getAffectedUpdates({
    data,
    oldData,
    affectedComputations,
    computeProps,
  });
};

export {compute};
