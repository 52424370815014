import React from 'react';
import { View } from '@applane/react-core-components';
import { isFunction } from 'lodash';
import { getRenderComponent } from '../UtilityFunctions';
import { WithRowSelector } from './selector/Selector';

class RenderRow extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps) {
    // Object.keys(this.props).forEach(key => {
    //   if (this.props[key] !== prevProps[key]) {
    //     console.log('@@@@@@@@key changes', key);
    //   }
    // });
    const { navigation } = this.props;
    if (navigation) {
      const newParms = navigation && navigation.getParams && navigation.getParams();
      if (this.oldParams && newParms && this.oldParams !== newParms) {
        this.setState(this.getInitialState());
      }
      this.oldParams = newParms;
    }
  }

  getInitialState = () => {
    const { expanded = false } = this.props;
    return {
      expanded,
      selected: false,
      swiped: false,
      isHover: false,
      modalVisible: false,
    };
  };

  removeMouseHover = () => {
    this.setState({ isHover: false });
  };

  onMouseLeave = (e) => {
    const { onRowMouseEnter, onMouseLeave } = this.props;
    if (this.state.modalVisible) {
      this.isMouseleave = e;
      return;
    }
    onRowMouseEnter ? onRowMouseEnter() : this.removeMouseHover();
    onMouseLeave && onMouseLeave(e);
  };

  onMouseEnter = (e) => {
    const { onRowMouseEnter, onMouseEnter } = this.props;
    this.setState({ isHover: true });
    onRowMouseEnter && onRowMouseEnter(this.removeMouseHover);
    onMouseEnter && onMouseEnter(e);
  };

  onModalShow = (modalVisible) => {
    this.setState({ modalVisible });
    if (!modalVisible && this.isMouseleave) {
      this.onMouseLeave(this.isMouseleave);
      this.isMouseleave = void 0;
    }
  };

  removeActionSelection = () => {
    this.setState({ selected: false });
  };

  removeExpanded = () => {
    const { disabledToggle } = this.props;
    this.setState({
      expanded: !disabledToggle ? false : this.state.expanded,
    });
  };

  handleExpanded = (value) => {
    const { disabledToggle, onToggleExpanded, onRowToggleExpand } = this.props;
    const expanded = this.state.expanded === value ? void 0 : value;
    const state = { expanded };
    if (this.state.selected) {
      state.selected = void 0;
    }
    this.setState(state);
    this.expandClicked = expanded;
    onToggleExpanded && onToggleExpanded({ expanded });
    !disabledToggle
      && onRowToggleExpand
      && onRowToggleExpand(this.removeExpanded);
  };

  toggleExpanded = () => {
    this.handleExpanded(true);
  };

  onExpandColumn = (column) => () => {
    this.handleExpanded(column);
  };

  onSelect = (props) => {
    const {
      onRowSelect, onSelect, onRowTouch, item,
    } = this.props;
    if (this.expandClicked) {
      this.expandClicked = void 0;
      return;
    }
    if (this.state.swiped) {
      this.setState({ swiped: false });
      onRowSelect && onRowSelect();
    } else {
      const state = { selected: true };
      if (this.state.expanded) {
        state.expanded = void 0;
      }
      this.setState(state);
      onRowSelect && onRowSelect(this.removeActionSelection);
      onRowTouch && onRowTouch({ item });
    }
    onSelect && onSelect(props);
  };

  onSwipe = (callback) => {
    const { onRowSelect } = this.props;
    this.setState({ swiped: true });
    onRowSelect && onRowSelect(callback);
  };

  getSeparator = (separatorStyle) => (separatorStyle ? <View style={separatorStyle} /> : void 0);

  render() {
    let {
      Component,
      onSelect,
      expanded,
      renderRow,
      renderColumn,
      rowWidth,
      card,
      renderExpandedRow,
      toggleExpanded,
      hoverable,
      showSelected,
      cardWrapperStyle,
      rowWrapperStyle,
      evenRowWrapperStyle,
      rowStyle,
      rowSeparatorStyle,
      cardSeparatorStyle,
      rowSeparatorPosition = 'bottom',
      isSelected,
      ...restProps
    } = this.props;

    const { isHover } = this.state;
    let { selected } = this.state;

    if (isFunction(isSelected)) {
      selected = isSelected(this.props);
    }

    const rowProps = {
      isHover,
      selected: showSelected && selected,
      onSelect: this.onSelect,
      onSwipe: this.onSwipe,
      onModalShow: this.onModalShow,
      toggleExpanded: this.toggleExpanded,
      onExpandColumn: this.onExpandColumn,
      expanded: this.state.expanded,
      ...restProps,
    };
    let renderRowComponent = null;
    if (renderRow) {
      renderRowComponent = getRenderComponent(renderRow, rowProps);
      if (!renderRowComponent) {
        return null;
      }
    } else {
      let componentProps = {};
      if (card) {
        card = getRenderComponent(card, rowProps);
        if (!card) {
          return null;
        }
        rowWrapperStyle = cardWrapperStyle;
        rowSeparatorStyle = cardSeparatorStyle;
        if (React.isValidElement(card)) {
          renderRowComponent = card;
        } else {
          componentProps = {
            ...card,
          };
        }
      } else {
        rowWrapperStyle = {
          ...((restProps.index % 2 === 0 && evenRowWrapperStyle)
            || rowWrapperStyle),
        };
        if (rowWidth) {
          rowWrapperStyle.width = rowWidth;
        }
        componentProps = {
          ...rowStyle,
          renderColumn,
        };
      }
      if (!renderRowComponent && Component) {
        renderRowComponent = <Component {...componentProps} {...rowProps} />;
      }
    }
    if (hoverable !== false) {
      renderRowComponent = (
        <View onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          {renderRowComponent}
        </View>
      );
    }
    renderRowComponent = (
      <View style={rowWrapperStyle}>
        {rowSeparatorPosition === 'top'
          ? this.getSeparator(rowSeparatorStyle)
          : void 0}
        {renderRowComponent}
        {this.state.expanded && renderExpandedRow ? (
          <View
            key={`expanded_${this.state.expanded}_${restProps.index}_${restProps._level}`}
          >
            {getRenderComponent(renderExpandedRow, {
              ...rowProps,
              _parentWidth: rowWidth,
            })}
          </View>
        ) : (
          void 0
        )}
        {rowSeparatorPosition === 'bottom'
          ? this.getSeparator(rowSeparatorStyle)
          : void 0}
      </View>
    );
    return renderRowComponent;
  }
}

const WithRenderRowWrapper = (Component) => {
  class RenderRowWrapper extends React.Component {
    render() {
      const {
        renderRowWrapper,
        lastRowBottomStyle,
        ...restProps
      } = this.props;
      let renderRowComponent = <Component {...restProps} />;
      const wrapperComponent = getRenderComponent(renderRowWrapper, restProps);
      if (wrapperComponent) {
        if (!React.isValidElement(wrapperComponent)) {
          const { Container, containerProps } = wrapperComponent;
          if (Container) {
            renderRowComponent = (
              <Container {...containerProps}>{renderRowComponent}</Container>
            );
          }
        } else {
          renderRowComponent = React.cloneElement(wrapperComponent, {
            children: renderRowComponent,
          });
        }
      }
      const { card, isLast, rowWidth } = restProps;
      if (!card && isLast && lastRowBottomStyle) {
        renderRowComponent = (
          <>
            {renderRowComponent}
            <View
              style={{
                ...lastRowBottomStyle,
                ...(rowWidth ? { width: rowWidth } : void 0),
              }}
            />
          </>
        );
      }
      return renderRowComponent;
    }
  }
  return RenderRowWrapper;
};

RenderRow = WithRowSelector(WithRenderRowWrapper(RenderRow));

export default RenderRow;
