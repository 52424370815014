import React from 'react';
import { View } from '../../app-components';

import { ScreenContextProvider } from '../../app-components/screen/ScreenContextProvider';
import { NotificationContextProviderHoc } from '../../AppNotification';
import { getUser } from '../../AppServices';
import { ROUTES } from '../../Lib/constants';
// import Header from './Header';
import HeaderPatient from './Header/HeaderPatient';
import SideBar from './Header/SideBar';

const AppContainer = (props) => {
  const { children, navigation, ...restProps } = props;
  const user = getUser();
  const [selectedMenu, setSelectedMenu] = React.useState(ROUTES.dashboard.name);
  const routeName = navigation?.getRouteName();
  console.log('🚀 ~ file: index.js ~ line 17 ~ AppContainer ~ routeName', routeName);

  React.useEffect(() => {
    if (routeName === 'patient-waiting-room') {
      setSelectedMenu('appointments');
    }
    if (routeName?.toLowerCase() === 'dashboard') {
      setSelectedMenu('dashboard');
    }
    if (routeName === 'provider' || routeName === 'doctor-search') {
      setSelectedMenu('findAProvider');
    }
    if (routeName === 'health-assistant') {
      setSelectedMenu('healthAssistant');
    }
    if (routeName === 'graph-image') {
      setSelectedMenu('graphImage');
    }
  }, [navigation]);

  let wrappedComponent = (
    <View style={{
      flex: 1, overflow: 'hidden',
    }}
    >
      <ScreenContextProvider>
        {routeName !== 'Reset Password'
          ? (
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
              }}
            >
              <View style={{
                overflow: 'hidden',
                background: '#0095C9',
                paddingTop: 20,
              }}
              >
                <SideBar
                  {...props}
                  selectedMenu={selectedMenu}
                  setSelectedMenu={setSelectedMenu}
                  user={user}
                />
              </View>
              <View
                style={{
                  flex: 1,
                  overflow: 'hidden',
                }}
              >
                <HeaderPatient {...props} user={user} />
                {children}
              </View>
            </View>
          )
          : children}
      </ScreenContextProvider>
    </View>
  );

  if (user) {
    wrappedComponent = (
      <NotificationContextProviderHoc
        navigation={restProps.navigation}
        eventDispatcher={restProps.eventDispatcher}
      >
        {wrappedComponent}
      </NotificationContextProviderHoc>
    );
  }
  return wrappedComponent;
};

export default AppContainer;
