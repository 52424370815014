import React from 'react';
import {View, BasicNumberInput} from '@applane/react-core-components';
import {
  WithFloatingLabel,
  WithInputWrapper,
  WithVariant,
  WithInputError,
  getInputProps,
} from '@applane/react-input-wrapper';
import NumberSelector from './NumberSelector';

class NumberInputEditor extends React.Component {
  render() {
    let inputProps = getInputProps(this.props);
    return <BasicNumberInput {...inputProps} />;
  }
}

NumberInputEditor = WithInputError(WithFloatingLabel(NumberInputEditor));

class NumberInput extends React.Component {
  onChangeValue = (value, e) => {
    let {onChangeText, onChangeValue} = this.props;
    onChangeText && onChangeText(value);
    onChangeValue && onChangeValue(value, e);
  };

  incValue = e => {
    let {value} = this.props;
    value = (value || 0) + 1;
    this.onChangeValue(value, e);
  };

  decValue = e => {
    let {value} = this.props;
    value = (value || 0) - 1;
    this.onChangeValue(value, e);
  };

  render() {
    let {disabled, skipSelector} = this.props;
    return (
      <View style={{flexDirection: 'row'}}>
        <View style={{flex: 1, overflow: 'hidden'}}>
          <NumberInputEditor {...this.props} />
        </View>
        {!disabled && !skipSelector ? (
          <NumberSelector
            {...this.props}
            onInc={this.incValue}
            onDec={this.decValue}
          />
        ) : (
          void 0
        )}
      </View>
    );
  }
}

NumberInput = WithInputWrapper(WithVariant(NumberInput));

export default NumberInput;
