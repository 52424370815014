import React from 'react';
import {View} from '@applane/react-core-components';
import {filterBarTheme} from '../../theme/filterBarTheme';

const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === 'function') {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};

export default class FilterBar extends React.Component {
  setScreenState = key => state => {
    const {navigation, setScreenState} = this.props;
    setScreenState && setScreenState(state);
    // if (key === 'tabFilter') {
    //   let {setScreenState: setNavigationScreenState, getScreenState} =
    //     navigation || {};
    //   let navigationScreenState = (getScreenState && getScreenState()) || {};
    //   let tabState = {};
    //   for (let key in state) {
    //     let keyValue = state[key];
    //     if (key === 'dataParams') {
    //       let tabPrevFilters =
    //         navigationScreenState &&
    //         navigationScreenState.dataParams &&
    //         navigationScreenState.dataParams.filters;
    //       if (tabPrevFilters) {
    //         tabState[key] = {
    //           ...keyValue,
    //           filters: {
    //             ...tabPrevFilters,
    //             ...keyValue.filters,
    //           },
    //         };
    //       } else {
    //         tabState[key] = keyValue;
    //       }
    //     } else {
    //       tabState[key] = keyValue;
    //     }
    //   }

    //   setNavigationScreenState && setNavigationScreenState(tabState);
    // }
  };

  render() {
    const {
      navigation,
      eventDispatcher,
      screenState,
      filters,
      position,
      absolutePosition,
    } = this.props;
    if (!filters || !filters.length) {
      return null;
    }
    let containerStyle =
      (position && filterBarTheme[`${position}ContainerStyle`]) ||
      filterBarTheme.containerStyle ||
      {};
    if (position === 'absolute') {
      containerStyle = {
        ...containerStyle,
        ...filterBarTheme[`${absolutePosition}ContainerStyle`],
      };
    }

    return (
      <View
        style={{flexDirection: 'row', alignItems: 'center', ...containerStyle}}>
        {filters.map(filter => {
          if (!filter) {
            return null;
          }
          let filterProps = {
            navigation,
            eventDispatcher,
            screenState,
            setScreenState: this.setScreenState(),
          };
          filter = getRenderComponent(filter, filterProps);
          if (React.isValidElement(filter)) {
            return filter;
          }
          const {type, filter: filterComponent} = filter;
          if (type) {
            filterProps = {
              ...filterProps,
              screenState,
              setScreenState: this.setScreenState(type),
            };
          }
          return getRenderComponent(filterComponent, filterProps);
        })}
      </View>
    );
  }
}
