export default {
  labels: {
    leaveName: 'Leave Name',
    leaveId: 'Leave Id',
    status: 'Status',
    description: 'Description',
    leaveType: 'Leave Type',
    noOfLeaves: 'No. of Leaves Assigned',
    leaveCreditType: 'Leave Credit Type',
    isEncashable: 'Is Encashable',
    isCarryForward: 'Is Carry Forward',
    carryForwardLeaves: 'Carry Forward Leaves',
    minExperience: 'Min Experience',
    maxExperience: 'Max Experience',
    maxCount: 'Maximum Count',
    staffType: 'Staff Type',
    gender: 'Gender',
    leavePolicyName: 'Name',
    accuralExcluding: 'Accural Excluding',
    addRow: 'Add Row',
    createdOn: 'Created On',
    createdBy: 'Created By',
    updatedOn: 'Updated On',
    updatedBy: 'Updated By',
    maximum_leave_accumulated: 'Max Leave Accumulated',
    maximum_leave_allowed: 'Max Leave Allowed',
    accrual_base_id: 'Accural Base',
    selectStaffType: 'Select Staff Type',
  },
  placeholders: {
    selectLeaveType: 'Select LeaveType',
    noOfLeaves: 'No. of Leaves Assigned',
    selectLeaveCredit: 'Select Leave Credit Type',
    selectaccuralExcluding: 'Select Accural Excluding',
    carryForwardLeaves: 'Carry Forward Leaves',
    minExp: 'Exp (in Years)',
    maxExp: 'Exp (in Years)',
    selectGender: 'Gender',
    maximum_leave_accumulated: 'Max Leave Accumulated',
    maximum_leave_allowed: 'Max Leave Allowed',
    accrual_base_id: 'Accural Base',
    selectStaffType: 'Select Staff Type',
  },
  title: {
    leaveTypeManagement: 'Leave Type Management',
    leavePolicyManagement: 'Leave Policy Management',
  },
  buttons: {
    addLeaveType: 'Add Leave Type',
    editLeaveType: 'Edit Leave Type',
    addLeavePolicy: 'Add Leave Policy',
    editLeavePolicy: 'Edit Leave Policy',
  },
  messages: {
    submitMessage: 'Leave Type has been added successfully.',
    updateMessage: 'Leave Type has been updated successfully.',
    leavePolicySubmitMessage: 'Leave Policy has been added successfully.',
    leavePolicyUpdateMessage: 'Leave Policy has been updated successfully.',
    uniqueLeavePolicy: 'Leave Policy already exists.',

  },
};
