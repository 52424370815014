export default {
  headers: {
    date: 'date',
    inTime: 'In Time',
    outTime: 'Out Time',
    status: 'Status',
    totalTime: 'Total Time',
    breakTime: 'Break Time',
  },
};
