import React from 'react';
import {View} from '@applane/react-core-components';

export default Component => {
  class WithFetch extends React.PureComponent {
    constructor(props) {
      super(props);
      let state = {};
      if (props.fetch) {
        state.loading = true;
        this.loadData();
      }
      this.state = state;
    }

    loadData = async () => {
      let {fetch, ...restProps} = this.props;
      let result = await fetch(restProps);
      this.setState({
        loading: false,
        fetchResult: result,
      });
    };

    render() {
      let {fetch, ...restProps} = this.props;
      if (fetch && this.state.loading) {
        return <View style={restProps.style} />;
      }
      return <Component {...restProps} {...this.state?.fetchResult} />;
    }
  }
  return WithFetch;
};
